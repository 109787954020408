import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batchedService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { lotIds: number[], packagedIds: number[], licenseplateIds: number[] }): 
  Promise<{ result: { TotalAvailablePackagedAmount?: number, LotId?: number, PackagedId?: number, LicensePlateId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lotIds)) {
      missingRequiredInParams.push('\'lotIds\'');
    }
    if (isNil(inParams.packagedIds)) {
      missingRequiredInParams.push('\'packagedIds\'');
    }
    if (isNil(inParams.licenseplateIds)) {
      missingRequiredInParams.push('\'licenseplateIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { lotIds: number[], packagedIds: number[], licenseplateIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { TotalAvailablePackagedAmount?: number, LotId?: number, PackagedId?: number, LicensePlateId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lotIds)) {
      missingRequiredInParams.push('\'lotIds\'');
    }
    if (isNil(inParams.packagedIds)) {
      missingRequiredInParams.push('\'packagedIds\'');
    }
    if (isNil(inParams.licenseplateIds)) {
      missingRequiredInParams.push('\'licenseplateIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { lotIds: number[], packagedIds: number[], licenseplateIds: number[], $keys: { LotId?: number, PackagedId?: number, LicensePlateId?: number }[] }): 
  Promise<{ result: { TotalAvailablePackagedAmount?: number, LotId?: number, PackagedId?: number, LicensePlateId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lotIds)) {
      missingRequiredInParams.push('\'lotIds\'');
    }
    if (isNil(inParams.packagedIds)) {
      missingRequiredInParams.push('\'packagedIds\'');
    }
    if (isNil(inParams.licenseplateIds)) {
      missingRequiredInParams.push('\'licenseplateIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
