<div class="card-container">
  <mat-card class="datex-card card"
            data-cy="card" 
            [ngStyle]="styles.style" 
            [ngClass]="styles.classes">
    <ng-container
                  *ngIf="initialized && !$hasMissingRequiredInParams">



      <mat-card-content data-cy="card-content" class="card-content" >
        <div [formGroup]="formGroupContent"
             class="formdata">
                <div data-cy="fieldset-formGroupContent-id-newGroup1"
                     *ngIf="!content.fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!content.fields.lookupcode.hidden" 
                            class="field-container standard {{content.fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.lookupcode.styles.style"
                            [ngClass]="content.fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{content.fields.lookupcode.label}}{{content.fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{content.fields.lookupcode.label}}">{{content.fields.lookupcode.label}}<span *ngIf="content.fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="content.fields.lookupcode.control.styles.style"
                              [ngClass]="content.fields.lookupcode.control.styles.classes">{{content.fields.lookupcode.control.text }}</div>
                        <ng-container *ngIf="content.fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of content.fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-location" *ngIf="!content.fields.location.hidden" 
                            class="field-container standard {{content.fields.location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.location.styles.style"
                            [ngClass]="content.fields.location.styles.classes">
                        <div class="label-container"
                              title="{{content.fields.location.label}}{{content.fields.location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{content.fields.location.label}}">{{content.fields.location.label}}<span *ngIf="content.fields.location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="content.fields.location.control.styles.style"
                              [ngClass]="content.fields.location.control.styles.classes">{{content.fields.location.control.text }}</div>
                        <ng-container *ngIf="content.fields.location.invalid">
                          <ng-container *ngFor="let error of content.fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.licenseplatecontents.hidden" class="tab" data-cy="tab-licenseplatecontents">
              <h2 [className]="tabs.licenseplatecontents.active? 'active': ''" (click)="tabs.licenseplatecontents.activate()">{{tabs.licenseplatecontents.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-licenseplatecontent_list *ngIf="tabs.licenseplatecontents.active"
              #$tabs_licenseplatecontents
              [licenseplateId]="$tabs_licenseplatecontents_licenseplatecontent_list_inParams_licenseplateId"
              ($refreshEvent)="refresh(false, false, '$tabs_licenseplatecontents')">
              </FootPrintManager-licenseplatecontent_list>
        </div>
      </mat-card-content>



    </ng-container>

    <div *ngIf="$hasMissingRequiredInParams"
        class="missing-params">
      <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
    </div>
  </mat-card>
  <div class="card-gripper"></div>
</div>