<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-packaging" *ngIf="!filters.packaging.hidden" 
                                  class="field-container full {{filters.packaging.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.packaging.styles.style"
                                  [ngClass]="filters.packaging.styles.classes">
                              <div class="label-container"
                                    title="{{filters.packaging.label}}{{filters.packaging.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.packaging.label}}">{{filters.packaging.label}}<span *ngIf="filters.packaging.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Materials-material_packagings_dd_single 
                                  data-cy="selector"
                                  [type]="filters.packaging.control.type"
                                  formControlName="packaging"
                                  (displayTextChange)="filters.packaging.control.displayText=$event"
                                  [placeholder]="filters.packaging.control.placeholder"
                                  [styles]="filters.packaging.control.styles"
                                [materialId]="$fields_packaging_selector_inParams_materialId"
                              >
                              </Materials-material_packagings_dd_single>
                              <ng-container *ngIf="filters.packaging.invalid">
                                <ng-container *ngFor="let error of filters.packaging.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.material_attachments.hidden" class="tab" data-cy="tab-material_attachments">
              <h2 [className]="tabs.material_attachments.active? 'active': ''" (click)="tabs.material_attachments.activate()">{{tabs.material_attachments.title}}</h2>
            </div>
            <div *ngIf="!tabs.material_packagings_attachments.hidden" class="tab" data-cy="tab-material_packagings_attachments">
              <h2 [className]="tabs.material_packagings_attachments.active? 'active': ''" (click)="tabs.material_packagings_attachments.activate()">{{tabs.material_packagings_attachments.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-entity_attachments_grid *ngIf="tabs.material_attachments.active"
              #$tabs_material_attachments
              [entityType]="$tabs_material_attachments_entity_attachments_grid_inParams_entityType"
              [entityKeys]="$tabs_material_attachments_entity_attachments_grid_inParams_entityKeys"
              (componentInitialized)="on_material_tab_activated($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_material_attachments')">
              </FootPrintManager-entity_attachments_grid>
              <FootPrintManager-entity_attachments_grid *ngIf="tabs.material_packagings_attachments.active"
              #$tabs_material_packagings_attachments
              [entityType]="$tabs_material_packagings_attachments_entity_attachments_grid_inParams_entityType"
              [entityKeys]="$tabs_material_packagings_attachments_entity_attachments_grid_inParams_entityKeys"
              (componentInitialized)="on_material_packagings_tab_activated($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_material_packagings_attachments')">
              </FootPrintManager-entity_attachments_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>