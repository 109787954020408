import { Injectable } from '@angular/core';
import { isNil, isNumber, isString, isBoolean, isDate } from 'lodash-es';
import { DateService } from './date.service';

@Injectable({ providedIn: 'root'})
export class OdataService {

  constructor(private date: DateService) {
  }

  public formatNumber(value: number): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!isNumber(value)) {
      return 'null'; // or throw
    }
    return value.toString();
  }

  public formatNumberArray(value: number[]): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!Array.isArray(value)) {
      return 'null'; // or throw
    }
    return `(${value.map(v => this.formatNumber(v)).join(', ')})`;
  }

  public formatString(value: string): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!isString(value)) {
      return 'null'; // or throw
    }
    return `'${this.escapeIllegalChars(value)}'`;
  }

  public formatStringArray(value: string[]): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!Array.isArray(value)) {
      return 'null'; // or throw
    }
    return `(${value.map(v => this.formatString(v)).join(', ')})`;
  }

  public formatBoolean(value: boolean): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!isBoolean(value)) {
      return 'null'; // or throw
    }
    return value.toString();
  }

  public formatBooleanArray(value: boolean[]): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!Array.isArray(value)) {
      return 'null'; // or throw
    }
    return `(${value.map(v => this.formatBoolean(v)).join(', ')})`;
  }

  public formatDate(value: Date): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!isDate(value)) {
      return 'null'; // or throw
    }
    return this.date.dateToString(value);

  }

  public formatDateArray(value: Date[]): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!Array.isArray(value)) {
      return 'null'; // or throw
    }
    return `(${value.map(v => this.formatDate(v)).join(', ')})`;
  }

  public formatISODate(value: string): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!isString(value) || !this.date.isISODateString(value)) {
      return 'null'; // or throw
    }
    return value;
  }

  public formatISODateArray(value: string[]): string {
    if (isNil(value)) {
      return 'null';
    }
    if (!Array.isArray(value)) {
      return 'null'; // or throw
    }
    return `(${value.map(v => this.formatISODate(v)).join(', ')})`;
  }

  public formatValue(value: any): string {
    if (isNil(value)) {
      return 'null';
    } else if (typeof value === 'string') {
      return `'${this.escapeIllegalChars(value)}'`;
    } else if (value instanceof Date) {
      return value.toISOString();
    } else if (Array.isArray(value)) {
      return `(${value
        .map(v => this.formatValue(v))
        .join(', ')})`;
    }

    return value.toString();
  }

  private escapeIllegalChars(string: string) {
    if (string) {
      string = string.replace(/%/g, '%25');
      string = string.replace(/\+/g, '%2B');
      string = string.replace(/\//g, '%2F');
      string = string.replace(/\?/g, '%3F');
      string = string.replace(/#/g, '%23');
      string = string.replace(/&/g, '%26');
      string = string.replace(/'/g, '\'\'');
    }
    return string;
  }
}



