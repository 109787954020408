import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { UtilsService } from '../utils.service';

@Pipe({ name: 'formatText', pure: true })
export class FormatTextPipe implements PipeTransform {
  constructor(private utils: UtilsService) { }

  transform(value: any, formatType?: string, format?: string): string
    | null {
    return this.formatText(format, formatType, value);
  }

  public formatText(format: string, formatType: string, value: string): string {
    let formattedText;
    if (isNil(value)) {
      formattedText = '';
    } else {
      if ((formatType === 'datetime' || formatType === 'number') && !isNil(format) && this.utils.isDefinedTrimmed(value)) {
        if (formatType === 'datetime') {
          formattedText = this.utils.date.format(value, format);
        } else if (formatType === 'number') {
          formattedText = this.utils.numberFormat(value, format);
        }
      } else {
        formattedText = String(value);
      }
    }
    return formattedText;
  }
}