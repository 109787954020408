import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_get_address_by_addressIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { addressId: number }): 
  Promise<{ result: { Id?: number, AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string, Contacts?: { AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, FirstName?: string, Greeting?: string, LastName?: string, MiddleName?: string, Notes?: string, OwnerEntity?: string, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, Title?: string, TypeId?: number, Url?: string }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.addressId)) {
      missingRequiredInParams.push('\'addressId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_get_address_by_addressId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
