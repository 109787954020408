<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!fieldsets.header.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.header.collapsible }">
                    <div *ngIf="!fieldsets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.header.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.header.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-use_default_license_plate" *ngIf="!fields.use_default_license_plate.hidden" 
                            class="field-container standard {{fields.use_default_license_plate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.use_default_license_plate.styles.style"
                            [ngClass]="fields.use_default_license_plate.styles.classes">
                        <div class="label-container"
                              title="{{fields.use_default_license_plate.label}}{{fields.use_default_license_plate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.use_default_license_plate.label}}">{{fields.use_default_license_plate.label}}<span *ngIf="fields.use_default_license_plate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="use_default_license_plate"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.use_default_license_plate.control.styles.style"
                                      [ngClass]="fields.use_default_license_plate.control.styles.classes">{{fields.use_default_license_plate.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.use_default_license_plate.invalid">
                          <ng-container *ngFor="let error of fields.use_default_license_plate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-license_plate_lookup" *ngIf="!fields.license_plate_lookup.hidden" 
                            class="field-container standard {{fields.license_plate_lookup.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.license_plate_lookup.styles.style"
                            [ngClass]="fields.license_plate_lookup.styles.classes">
                        <div class="label-container"
                              title="{{fields.license_plate_lookup.label}}{{fields.license_plate_lookup.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.license_plate_lookup.label}}">{{fields.license_plate_lookup.label}}<span *ngIf="fields.license_plate_lookup.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="license_plate_lookup"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.license_plate_lookup.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.license_plate_lookup.control.placeholder}}"
                                [ngStyle]="fields.license_plate_lookup.control.styles.style"
                                [ngClass]="fields.license_plate_lookup.control.styles.classes"> 
                        <ng-container *ngIf="fields.license_plate_lookup.invalid">
                          <ng-container *ngFor="let error of fields.license_plate_lookup.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-load_options"
                     *ngIf="!fieldsets.load_options.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.load_options.collapsible }">
                    <div *ngIf="!fieldsets.load_options.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.load_options.toggle()">
                      <span class="fieldsetsTitle-text">Load options</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.load_options.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.load_options.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.load_options.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.load_options.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-auto_load" *ngIf="!fields.auto_load.hidden" 
                            class="field-container standard {{fields.auto_load.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.auto_load.styles.style"
                            [ngClass]="fields.auto_load.styles.classes">
                        <div class="label-container"
                              title="{{fields.auto_load.label}}{{fields.auto_load.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.auto_load.label}}">{{fields.auto_load.label}}<span *ngIf="fields.auto_load.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="auto_load"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.auto_load.control.styles.style"
                                      [ngClass]="fields.auto_load.control.styles.classes">{{fields.auto_load.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.auto_load.invalid">
                          <ng-container *ngFor="let error of fields.auto_load.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-load_location" *ngIf="!fields.load_location.hidden" 
                            class="field-container standard {{fields.load_location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.load_location.styles.style"
                            [ngClass]="fields.load_location.styles.classes">
                        <div class="label-container"
                              title="{{fields.load_location.label}}{{fields.load_location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.load_location.label}}">{{fields.load_location.label}}<span *ngIf="fields.load_location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.load_location.control.type"
                            formControlName="load_location"
                            (displayTextChange)="fields.load_location.control.displayText=$event"
                            [placeholder]="fields.load_location.control.placeholder"
                            [styles]="fields.load_location.control.styles"
                          [warehouseId]="$fields_load_location_selector_inParams_warehouseId"
                          [typeId]="$fields_load_location_selector_inParams_typeId"
                          [eligibleForAllocation]="$fields_load_location_selector_inParams_eligibleForAllocation"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.load_location.invalid">
                          <ng-container *ngFor="let error of fields.load_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>