import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_return_orders_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, orderClassIds?: number[], dateType?: string, reference?: string, blindShippingContainerId?: number, orderIds?: number[], take?: number, skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierService?: { CarrierId?: number }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string } } }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Returns/datasources/ds_return_orders_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, orderClassIds?: number[], dateType?: string, reference?: string, blindShippingContainerId?: number, orderIds?: number[], take?: number, skip?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierService?: { CarrierId?: number }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string } } }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Returns/datasources/ds_return_orders_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, orderClassIds?: number[], dateType?: string, reference?: string, blindShippingContainerId?: number, orderIds?: number[], take?: number, skip?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierService?: { CarrierId?: number }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string } } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_return_orders_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
