import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Catalogs_create_material_catalog_flowService } from './Catalogs.flow.index';
import { Catalogs_delete_material_catalog_flowService } from './Catalogs.flow.index';
import { Catalogs_is_material_catalog_deletable_flowService } from './Catalogs.flow.index';

import { $frontendTypes } from './Catalogs.frontend.types'

@Injectable({ providedIn: 'root' })
export class Catalogs_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Catalogs: Catalogs_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_material_catalog_flow: Catalogs_create_material_catalog_flowService;
  public async create_material_catalog_flow(inParams: { name: string, description: string }): Promise< { reasons?: string[] }> {
    if(!this._create_material_catalog_flow) {
      this._create_material_catalog_flow = this.injector.get(Catalogs_create_material_catalog_flowService);
    }
    return this._create_material_catalog_flow.run(inParams);
  }
   
   

   
 
  private _delete_material_catalog_flow: Catalogs_delete_material_catalog_flowService;
  public async delete_material_catalog_flow(inParams: { catalog_id: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_material_catalog_flow) {
      this._delete_material_catalog_flow = this.injector.get(Catalogs_delete_material_catalog_flowService);
    }
    return this._delete_material_catalog_flow.run(inParams);
  }
   
   

   
 
  private _is_material_catalog_deletable_flow: Catalogs_is_material_catalog_deletable_flowService;
  public async is_material_catalog_deletable_flow(inParams: { catalog_id: number }): Promise< { reason?: string }> {
    if(!this._is_material_catalog_deletable_flow) {
      this._is_material_catalog_deletable_flow = this.injector.get(Catalogs_is_material_catalog_deletable_flowService);
    }
    return this._is_material_catalog_deletable_flow.run(inParams);
  }
   
   

   
}
