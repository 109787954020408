import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_ds_get_integration_info_by_order_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { order_id: number }): 
  Promise<{ result: { Id?: number, IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourceLookupCode?: string, IntegrationHubSourcePlatform?: string, WavelengthConnectorOrderTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[], ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ShippedDate?: string, StatusId?: number, WavelengthConnectorShipmentTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[] } }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_get_integration_info_by_order_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
