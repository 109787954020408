import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, timeout } from 'rxjs';
// using @tinyhttp/content-disposition instead of content-disposition package
// since the latter has imports to NodeJS modules
import {
  contentDisposition as formatContentDisposition,
  parse as parseContentDisposition
} from '@tinyhttp/content-disposition';
import {
  format as formatContentType,
  parse as parseContentType
} from 'content-type';
import { BlobService } from './blob.service';
import { isNil, isEmpty } from 'lodash-es';

type headersType = {
  [header: string]: string[] | string;
};
type paramsType = {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
};
export type headersOptions = {
  headers?: headersType;
}
export type paramsOptions = {
  params?: paramsType;
}
export type responseTypeOptions = {
  responseType?: any;
}
export type bodyOptions = {
  body?: any | null;
}
export type timeoutOptions = {
  timeout?: number;
}
export type baseOptions = headersOptions & paramsOptions & timeoutOptions & responseTypeOptions;
export type optionsWithBody = baseOptions & bodyOptions;

export const DefaultContentType = 'application/octet-stream';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient, private blob: BlobService) {
  }

  get<T = any>(url: string, options?: baseOptions): Promise<T> {
    return this.responseWithTimeout(this.httpClient.get<T>(url, options), options);
  }

  patch<T = any>(url: string, body: any | null, options?: baseOptions): Promise<T> {
    return this.responseWithTimeout(this.httpClient.patch<T>(url, body, options), options);
  }

  put<T = any>(url: string, body: any | null, options?: baseOptions): Promise<T> {
    return this.responseWithTimeout(this.httpClient.put<T>(url, body, options), options);
  }

  post<T = any>(url: string, body: any | null, options?: baseOptions): Promise<T> {
    return this.responseWithTimeout(this.httpClient.post<T>(url, body, options), options);
  }

  delete<T = any>(url: string, options?: optionsWithBody): Promise<T> {
    return this.responseWithTimeout(this.httpClient.delete<T>(url, options), options);
  }

  public httpClientResponseToBlob(res: HttpResponse<Blob>): Blob {
    return this.blob.processBlob(res.body, this.httpClientResponseToBlobProperties(res));
  }

  public httpClientResponseToBlobProperties(res: HttpResponse<Blob>) {
    return this.headersToBlobProperties(
      {
        contentLength: res.headers.get('Content-Length'),
        contentType: res.headers.get('Content-Type'),
        contentDisposition: res.headers.get('Content-Disposition'),
        lastModified: res.headers.get('Last-Modified')
      });
  }

  public headersToBlobProperties(headers: {
    contentType: string,
    contentLength?: string,
    contentDisposition?: string,
    lastModified?: string,
  }): {
    type: string,
    size: number;
    fileName?: string,
    lastModified?: number,
  } {

    const type = isEmpty(headers.contentType) ? DefaultContentType : parseContentType(headers.contentType).type;
    const size = Number.parseInt(headers.contentLength);

    let fileName = null;
    if (!isEmpty(headers.contentDisposition)) {
      const contentDisposition = parseContentDisposition(headers.contentDisposition);
      if (!isEmpty(contentDisposition.parameters.filename)) {
        fileName = contentDisposition.parameters.filename;
      }
    }

    let lastModified = null;
    if (!isEmpty(headers.lastModified)) {
      lastModified = new Date(headers.lastModified).getTime();
    }

    return {
      type: type,
      size: size,
      fileName: fileName,
      lastModified: lastModified,
    }
  }

  public blobPropertiesToHeaders(blob: Blob): {
    contentType: string,
    contentLength: string,
    contentDisposition?: string,
    lastModified?: string,
  } {

    const contentType = isEmpty(blob.type) ? DefaultContentType : formatContentType({ type: blob.type });
    const contentLength = blob.size.toString();

    let contentDisposition = null;
    let lastModified = null;
    if (this.blob.isFile(blob)) {
      contentDisposition = formatContentDisposition(blob.name);
      lastModified = new Date(blob.lastModified).toUTCString();
    }

    return {
      contentType: contentType,
      contentLength: contentLength,
      contentDisposition: contentDisposition,
      lastModified: lastModified
    }
  }

  public setBlobHeadersToHttpClientOptions(blob: Blob, options: any) {
    const headers = this.blobPropertiesToHeaders(blob);

    options = isNil(options) ? {} : options;

    if (isNil(options.headers)) {
      options.headers = {};
    }

    // NOTE: we don't set 'Content-Type' and 'Content-Length' since HttpClient is doing that internally
    if (!isEmpty(headers.contentDisposition)) {
      options.headers['Content-Disposition'] = headers.contentDisposition;
    }
    if (!isEmpty(headers.lastModified)) {
      options.headers['Last-Modified'] = headers.lastModified;
    }
  }

  private responseWithTimeout<T>(response: Observable<T>, options?: baseOptions): Promise<T> {
    const responseWithTimeout = options?.timeout
      ? response.pipe(timeout(options.timeout))
      : response;

    return firstValueFrom(responseWithTimeout);
  }
}
