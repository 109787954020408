import { Injectable } from '@angular/core';
import { utils as excelUtils } from 'xlsx';
import { isNil, isObject, isEmpty, trim } from 'lodash-es';
import * as numeral from 'numeral';

import { v4 as uuidv4 } from 'uuid';

import { DateService } from './date.service';
import { HttpService } from './http.service';
import { OdataService } from './odata.service';
import { BlobService } from './blob.service';
import { XMLParser, XMLBuilder, X2jOptions, XmlBuilderOptions } from 'fast-xml-parser';

@Injectable({ providedIn: 'root'})
export class UtilsService {
  excel = excelUtils;

  constructor(
    public http: HttpService,
    public date: DateService,
    public odata: OdataService,
    public blob: BlobService
    ) {
  }

  public isDefined(value: any): boolean {
    // works with array and object
    if (isObject(value)) {
      // will return false for empty object and empty array
      return !isEmpty(value);
    } else {
      return !isNil(value) && value !== '';
    }
  }

  public isDefinedTrimmed(value) {
    if (typeof value === 'string') {
      value = trim(value);
    }
    return this.isDefined(value);
  }

  public isAllDefined(...values) {
    return values.every(v => this.isDefined(v));
  }

  public formatText(format: string, formatType: string, value: string): string {
    let formattedText;
    if (this.isDefinedTrimmed(value) && !isNil(format)) {
      if (formatType === 'datetime') {
        formattedText = this.date.format(value, format);
      } else if (formatType === 'number') {
        formattedText = this.numberFormat(value, format);
      }
    } else {
      formattedText = value;
    }
    return formattedText;
  }

  public numberFormat(number, format) {
    return isNil(number) ? null : numeral(number).format(format);
  }

  /**
   * Create a Guid of type version 4
   * @returns Guid Version 4
   */
  public createGuid(): string {
    return uuidv4();
  }

  public parseXml<T = any>(xmlData: string, options?: X2jOptions): T {
    const xmlParser = new XMLParser(options);
    return xmlParser.parse(xmlData) as T;
  }

  public buildXml(jObj: any, options?: XmlBuilderOptions): string {
    const xmlParser = new XMLBuilder(options);
    return xmlParser.build(jObj);
  }
}
