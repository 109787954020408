import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_get_addresses_by_entity_and_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { entity?: string, id?: number, contactId?: number, addressId?: number }): 
  Promise<{ result: { Id?: number, AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, FirstName?: string, Greeting?: string, LastName?: string, MiddleName?: string, Notes?: string, OwnerEntity?: string, OwnerId?: number, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, Title?: string, Url?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, Type?: { Name?: string }, AccountsContactsLookup?: { AccountId?: number, Account?: { LookupCode?: string, Name?: string, Notes?: string, Type?: { Name?: string } } }[] }[] }> 
  {
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_addresses_by_entity_and_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { entity?: string, id?: number, contactId?: number, addressId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, FirstName?: string, Greeting?: string, LastName?: string, MiddleName?: string, Notes?: string, OwnerEntity?: string, OwnerId?: number, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, Title?: string, Url?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, Type?: { Name?: string }, AccountsContactsLookup?: { AccountId?: number, Account?: { LookupCode?: string, Name?: string, Notes?: string, Type?: { Name?: string } } }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_addresses_by_entity_and_id/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { entity?: string, id?: number, contactId?: number, addressId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, FirstName?: string, Greeting?: string, LastName?: string, MiddleName?: string, Notes?: string, OwnerEntity?: string, OwnerId?: number, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, Title?: string, Url?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, Type?: { Name?: string }, AccountsContactsLookup?: { AccountId?: number, Account?: { LookupCode?: string, Name?: string, Notes?: string, Type?: { Name?: string } } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_addresses_by_entity_and_id/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
