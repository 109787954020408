import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { DockAppointments_ShellService, EModalSize, EToasterType, EToasterPosition } from './DockAppointments.shell.service';
import { DockAppointments_OperationService } from './DockAppointments.operation.service';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { DockAppointments_LocalizationService } from './DockAppointments.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './DockAppointments.frontend.types'
import { $frontendTypes as $types} from './DockAppointments.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'DockAppointments-dock_appointment_statuses_single',
  templateUrl: './DockAppointments.dock_appointment_statuses_single.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DockAppointments_dock_appointment_statuses_singleComponent),
      multi: true
    }
  ]
})
export class DockAppointments_dock_appointment_statuses_singleComponent extends BaseComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;
  
  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return false;
  }

  get isSingle() {
    return true;
  }


  inParams: { statusIds?: number[] } = { statusIds: [] };
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }

  fullTextSearch: string;

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: DockAppointments_ShellService,
    private datasources: DockAppointments_DatasourceService,
    private flows: DockAppointments_FlowService,
    private reports: DockAppointments_ReportService,
    private localization: DockAppointments_LocalizationService,
    private operations: DockAppointments_OperationService,
    private logger: CleanupLoggerService,
    private _elementRef: ElementRef
    ) {
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  
  async $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): number | number[] {
    return this._innerValue;
  }

  set value(val: number | number[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }



  displayWithFn(value: any): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {

      const $selector = this;
      const $utils = this.utils;

      this.fullTextSearch = null;
      const $fullTextSearch = null;
      this.datasources.DockAppointments.ds_dock_appointment_statuses_dd.getByKeys({
        $keys: [value],
        statusIds:  $selector.inParams.statusIds ,
        fullTextSearch:  $selector.fullTextSearch ,
      }).then(result => {
        const found = result.result[0];
        const e: { Id?: number, Name?: string } = found ?? {};
        const $option = { entity: e };
         const $entity = e;
        const displayTextResult = found ? ($option.entity.Name) : 'No data to display';
        this.displayTextChange.emit(displayTextResult);
        resolve(displayTextResult);
      });

    });
    return promise;
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {

    const promise = new Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }>((resolve, reject) => {

      const $selector = this;
      const $utils = this.utils;

      this.fullTextSearch = filterText;
      const $fullTextSearch = filterText;
      this.datasources.DockAppointments.ds_dock_appointment_statuses_dd.getList({
        $top: 10,
        $skip: 0,
        statusIds:  $selector.inParams.statusIds ,
        fullTextSearch:  $selector.fullTextSearch ,
      }).then(result => {
        resolve({
          list: result.result.map(item => {
            const $option = { entity: item };
            const $entity = item;
            const $resultOption = { key: null, name: $option.entity.Name, disabled: null };
            $resultOption.key = $option.entity.Id;
            return $resultOption;
          }),
          totalCount: result.totalCount,
          top: 10
        });
      });

    });

    return promise;

  }

}
