import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Instructions_ds_get_executed_instructions_by_instructionIdService } from './Instructions.datasource.index';
import { Instructions_ds_get_instruction_by_instruction_idService } from './Instructions.datasource.index';
import { Instructions_ds_instruction_types_ddService } from './Instructions.datasource.index';
import { Instructions_ds_instructions_gridService } from './Instructions.datasource.index';

@Injectable({ providedIn: 'root' })
export class Instructions_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Instructions: Instructions_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_executed_instructions_by_instructionId: Instructions_ds_get_executed_instructions_by_instructionIdService;
  public get ds_get_executed_instructions_by_instructionId(): Instructions_ds_get_executed_instructions_by_instructionIdService {
    if(!this._ds_get_executed_instructions_by_instructionId) {
      this._ds_get_executed_instructions_by_instructionId = this.injector.get(Instructions_ds_get_executed_instructions_by_instructionIdService);
    }
    return this._ds_get_executed_instructions_by_instructionId;
  }
  private _ds_get_instruction_by_instruction_id: Instructions_ds_get_instruction_by_instruction_idService;
  public get ds_get_instruction_by_instruction_id(): Instructions_ds_get_instruction_by_instruction_idService {
    if(!this._ds_get_instruction_by_instruction_id) {
      this._ds_get_instruction_by_instruction_id = this.injector.get(Instructions_ds_get_instruction_by_instruction_idService);
    }
    return this._ds_get_instruction_by_instruction_id;
  }
  private _ds_instruction_types_dd: Instructions_ds_instruction_types_ddService;
  public get ds_instruction_types_dd(): Instructions_ds_instruction_types_ddService {
    if(!this._ds_instruction_types_dd) {
      this._ds_instruction_types_dd = this.injector.get(Instructions_ds_instruction_types_ddService);
    }
    return this._ds_instruction_types_dd;
  }
  private _ds_instructions_grid: Instructions_ds_instructions_gridService;
  public get ds_instructions_grid(): Instructions_ds_instructions_gridService {
    if(!this._ds_instructions_grid) {
      this._ds_instructions_grid = this.injector.get(Instructions_ds_instructions_gridService);
    }
    return this._ds_instructions_grid;
  }
}

