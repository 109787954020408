import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DateAdapter {
  addDays(date: Date | number, amount: number) {
    return moment(date).add(amount, 'days').toDate();
  }

  addHours(date: Date | number, amount: number) {
    return moment(date).add(amount, 'hours').toDate();
  }

  addMinutes(date: Date | number, amount: number) {
    return moment(date).add(amount, 'minutes').toDate();
  }

  addSeconds(date: Date | number, amount: number): Date {
    return moment(date).add(amount, 'seconds').toDate();
  }

  differenceInDays(
    dateLeft: Date | number,
    dateRight: Date | number
  ): number {
    return moment(dateLeft).diff(moment(dateRight), 'days');
  }

  differenceInMinutes(
    dateLeft: Date | number,
    dateRight: Date | number
  ): number {
    return moment(dateLeft).diff(moment(dateRight), 'minutes');
  }

  differenceInSeconds(
    dateLeft: Date | number,
    dateRight: Date | number
  ): number {
    return moment(dateLeft).diff(moment(dateRight), 'seconds');
  }

  endOfDay(date: Date | number): Date {
    return moment(date).endOf('day').toDate();
  }

  endOfMonth(date: Date | number): Date {
    return moment(date).endOf('month').toDate();
  }

  endOfWeek(date: Date | number, options?: {
    weekStartsOn?: number;
  }): Date {
    return moment(date).endOf('week').toDate();
  }

  getDay(date: Date | number): number {
    return moment(date).day();
  }

  /* istanbul ignore next */
  getMonth(date: Date | number): number {
    return moment(date).month();
  }

  isSameDay(
    dateLeft: Date | number,
    dateRight: Date | number
  ): boolean {
    return moment(dateLeft).isSame(moment(dateRight), 'day');
  }

  isSameMonth(
    dateLeft: Date | number,
    dateRight: Date | number
  ): boolean {
    return moment(dateLeft).isSame(moment(dateRight), 'month');
  }

  isSameSecond(
    dateLeft: Date | number,
    dateRight: Date | number
  ): boolean {
    return moment(dateLeft).isSame(moment(dateRight), 'second');
  }

  max(dates: (Date | number)[]): Date {
    return moment.max(dates.map((date) => moment(date))).toDate();
  }

  setHours(date: Date | number, hours: number): Date {
    return moment(date).set('hours', hours).toDate();
  }

  setMinutes(date: Date | number, minutes: number): Date {
    return moment(date).set('minutes', minutes).toDate();
  }

  startOfDay(date: Date | number): Date {
    return moment(date).startOf('day').toDate();
  }

  startOfMinute(date: Date | number): Date {
    return moment(date).startOf('minute').toDate();
  }

  startOfMonth(date: Date | number): Date {
    return moment(date).startOf('month').toDate();
  }

  startOfWeek(date: Date | number, options?: {
    weekStartsOn?: number;
  }): Date {
    return moment(date).startOf('week').toDate();
  }

  getHours(date: Date | number): number {
    return moment(date).get('hours');
  }

  getMinutes(date: Date | number): number {
    return moment(date).get('minutes');
  }

  getTimezoneOffset(date: Date | number): number {
    return moment(date).utcOffset() * -1;
  }
  //#endregion

  //#region copy from angular-calendar
  addWeeks(date: Date | number, amount: number): Date {
    return moment(date).add(amount, 'weeks').toDate();
  }

  addMonths(date: Date | number, amount: number): Date {
    return moment(date).add(amount, 'months').toDate();
  }

  subDays(date: Date | number, amount: number): Date {
    return moment(date).subtract(amount, 'days').toDate();
  }

  subWeeks(date: Date | number, amount: number): Date {
    return moment(date).subtract(amount, 'weeks').toDate();
  }

  subMonths(date: Date | number, amount: number): Date {
    return moment(date).subtract(amount, 'months').toDate();
  }

  getISOWeek(date: Date | number): number {
    return moment(date).isoWeek();
  }

  setDate(date: Date | number, dayOfMonth: number): Date {
    return moment(date).date(dayOfMonth).toDate();
  }

  setMonth(date: Date | number, month: number): Date {
    return moment(date).month(month).toDate();
  }

  setYear(date: Date | number, year: number): Date {
    return moment(date).year(year).toDate();
  }

  getDate(date: Date | number): number {
    return moment(date).date();
  }

  getYear(date: Date | number): number {
    return moment(date).year();
  }
}