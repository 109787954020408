import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { ShippingContainers_ds_container_types_assignment_by_materialService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_container_types_assignment_by_ownerService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_container_types_assignment_gridService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_container_types_gridService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_get_assigned_shipping_type_containerService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_get_container_types_assignment_ddService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_get_container_types_by_Id_top1Service } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_get_owner_Id_by_project_IdService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_get_shipping_container_by_idService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_get_shipping_containers_tasksService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_order_types_ddService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_shipment_statusesService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_shipping_containers_gridService } from './ShippingContainers.datasource.index';
import { ShippingContainers_ds_shipping_containers_tasks_gridService } from './ShippingContainers.datasource.index';

@Injectable({ providedIn: 'root' })
export class ShippingContainers_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public ShippingContainers: ShippingContainers_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_container_types_assignment_by_material: ShippingContainers_ds_container_types_assignment_by_materialService;
  public get ds_container_types_assignment_by_material(): ShippingContainers_ds_container_types_assignment_by_materialService {
    if(!this._ds_container_types_assignment_by_material) {
      this._ds_container_types_assignment_by_material = this.injector.get(ShippingContainers_ds_container_types_assignment_by_materialService);
    }
    return this._ds_container_types_assignment_by_material;
  }
  private _ds_container_types_assignment_by_owner: ShippingContainers_ds_container_types_assignment_by_ownerService;
  public get ds_container_types_assignment_by_owner(): ShippingContainers_ds_container_types_assignment_by_ownerService {
    if(!this._ds_container_types_assignment_by_owner) {
      this._ds_container_types_assignment_by_owner = this.injector.get(ShippingContainers_ds_container_types_assignment_by_ownerService);
    }
    return this._ds_container_types_assignment_by_owner;
  }
  private _ds_container_types_assignment_grid: ShippingContainers_ds_container_types_assignment_gridService;
  public get ds_container_types_assignment_grid(): ShippingContainers_ds_container_types_assignment_gridService {
    if(!this._ds_container_types_assignment_grid) {
      this._ds_container_types_assignment_grid = this.injector.get(ShippingContainers_ds_container_types_assignment_gridService);
    }
    return this._ds_container_types_assignment_grid;
  }
  private _ds_container_types_grid: ShippingContainers_ds_container_types_gridService;
  public get ds_container_types_grid(): ShippingContainers_ds_container_types_gridService {
    if(!this._ds_container_types_grid) {
      this._ds_container_types_grid = this.injector.get(ShippingContainers_ds_container_types_gridService);
    }
    return this._ds_container_types_grid;
  }
  private _ds_get_assigned_shipping_type_container: ShippingContainers_ds_get_assigned_shipping_type_containerService;
  public get ds_get_assigned_shipping_type_container(): ShippingContainers_ds_get_assigned_shipping_type_containerService {
    if(!this._ds_get_assigned_shipping_type_container) {
      this._ds_get_assigned_shipping_type_container = this.injector.get(ShippingContainers_ds_get_assigned_shipping_type_containerService);
    }
    return this._ds_get_assigned_shipping_type_container;
  }
  private _ds_get_container_types_assignment_dd: ShippingContainers_ds_get_container_types_assignment_ddService;
  public get ds_get_container_types_assignment_dd(): ShippingContainers_ds_get_container_types_assignment_ddService {
    if(!this._ds_get_container_types_assignment_dd) {
      this._ds_get_container_types_assignment_dd = this.injector.get(ShippingContainers_ds_get_container_types_assignment_ddService);
    }
    return this._ds_get_container_types_assignment_dd;
  }
  private _ds_get_container_types_by_Id_top1: ShippingContainers_ds_get_container_types_by_Id_top1Service;
  public get ds_get_container_types_by_Id_top1(): ShippingContainers_ds_get_container_types_by_Id_top1Service {
    if(!this._ds_get_container_types_by_Id_top1) {
      this._ds_get_container_types_by_Id_top1 = this.injector.get(ShippingContainers_ds_get_container_types_by_Id_top1Service);
    }
    return this._ds_get_container_types_by_Id_top1;
  }
  private _ds_get_owner_Id_by_project_Id: ShippingContainers_ds_get_owner_Id_by_project_IdService;
  public get ds_get_owner_Id_by_project_Id(): ShippingContainers_ds_get_owner_Id_by_project_IdService {
    if(!this._ds_get_owner_Id_by_project_Id) {
      this._ds_get_owner_Id_by_project_Id = this.injector.get(ShippingContainers_ds_get_owner_Id_by_project_IdService);
    }
    return this._ds_get_owner_Id_by_project_Id;
  }
  private _ds_get_shipping_container_by_id: ShippingContainers_ds_get_shipping_container_by_idService;
  public get ds_get_shipping_container_by_id(): ShippingContainers_ds_get_shipping_container_by_idService {
    if(!this._ds_get_shipping_container_by_id) {
      this._ds_get_shipping_container_by_id = this.injector.get(ShippingContainers_ds_get_shipping_container_by_idService);
    }
    return this._ds_get_shipping_container_by_id;
  }
  private _ds_get_shipping_containers_tasks: ShippingContainers_ds_get_shipping_containers_tasksService;
  public get ds_get_shipping_containers_tasks(): ShippingContainers_ds_get_shipping_containers_tasksService {
    if(!this._ds_get_shipping_containers_tasks) {
      this._ds_get_shipping_containers_tasks = this.injector.get(ShippingContainers_ds_get_shipping_containers_tasksService);
    }
    return this._ds_get_shipping_containers_tasks;
  }
  private _ds_order_types_dd: ShippingContainers_ds_order_types_ddService;
  public get ds_order_types_dd(): ShippingContainers_ds_order_types_ddService {
    if(!this._ds_order_types_dd) {
      this._ds_order_types_dd = this.injector.get(ShippingContainers_ds_order_types_ddService);
    }
    return this._ds_order_types_dd;
  }
  private _ds_shipment_statuses: ShippingContainers_ds_shipment_statusesService;
  public get ds_shipment_statuses(): ShippingContainers_ds_shipment_statusesService {
    if(!this._ds_shipment_statuses) {
      this._ds_shipment_statuses = this.injector.get(ShippingContainers_ds_shipment_statusesService);
    }
    return this._ds_shipment_statuses;
  }
  private _ds_shipping_containers_grid: ShippingContainers_ds_shipping_containers_gridService;
  public get ds_shipping_containers_grid(): ShippingContainers_ds_shipping_containers_gridService {
    if(!this._ds_shipping_containers_grid) {
      this._ds_shipping_containers_grid = this.injector.get(ShippingContainers_ds_shipping_containers_gridService);
    }
    return this._ds_shipping_containers_grid;
  }
  private _ds_shipping_containers_tasks_grid: ShippingContainers_ds_shipping_containers_tasks_gridService;
  public get ds_shipping_containers_tasks_grid(): ShippingContainers_ds_shipping_containers_tasks_gridService {
    if(!this._ds_shipping_containers_tasks_grid) {
      this._ds_shipping_containers_tasks_grid = this.injector.get(ShippingContainers_ds_shipping_containers_tasks_gridService);
    }
    return this._ds_shipping_containers_tasks_grid;
  }
}

