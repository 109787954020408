import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { FootPrintApiManager_insert_alert_formComponent } from './FootPrintApiManager.insert_alert_form.component';
import { FootPrintApiManager_insert_log_formComponent } from './FootPrintApiManager.insert_log_form.component';
import { FootPrintApiManager_asn_order_editorComponent } from './FootPrintApiManager.asn_order_editor.component';
import { FootPrintApiManager_footprint_api_runnerComponent } from './FootPrintApiManager.footprint_api_runner.component';
import { FootPrintApiManager_purchase_order_editorComponent } from './FootPrintApiManager.purchase_order_editor.component';
import { FootPrintApiManager_sales_order_editorComponent } from './FootPrintApiManager.sales_order_editor.component';
import { FootPrintApiManager_adjustment_tasks_gridComponent } from './FootPrintApiManager.adjustment_tasks_grid.component';
import { FootPrintApiManager_adjustments_gridComponent } from './FootPrintApiManager.adjustments_grid.component';
import { FootPrintApiManager_alerts_filters_gridComponent } from './FootPrintApiManager.alerts_filters_grid.component';
import { FootPrintApiManager_alerts_gridComponent } from './FootPrintApiManager.alerts_grid.component';
import { FootPrintApiManager_base_order_lines_gridComponent } from './FootPrintApiManager.base_order_lines_grid.component';
import { FootPrintApiManager_configurations_gridComponent } from './FootPrintApiManager.configurations_grid.component';
import { FootPrintApiManager_control_gridComponent } from './FootPrintApiManager.control_grid.component';
import { FootPrintApiManager_integrations_gridComponent } from './FootPrintApiManager.integrations_grid.component';
import { FootPrintApiManager_lifecycle_gridComponent } from './FootPrintApiManager.lifecycle_grid.component';
import { FootPrintApiManager_logs_gridComponent } from './FootPrintApiManager.logs_grid.component';
import { FootPrintApiManager_messages_gridComponent } from './FootPrintApiManager.messages_grid.component';
import { FootPrintApiManager_processes_gridComponent } from './FootPrintApiManager.processes_grid.component';
import { FootPrintApiManager_statuses_gridComponent } from './FootPrintApiManager.statuses_grid.component';
import { FootPrintApiManager_transaction_types_gridComponent } from './FootPrintApiManager.transaction_types_grid.component';
import { FootPrintApiManager_footprint_api_hubComponent } from './FootPrintApiManager.footprint_api_hub.component';
import { FootPrintApiManager_transaction_hubComponent } from './FootPrintApiManager.transaction_hub.component';
import { FootPrintApiManager_transaction_reportComponent } from './FootPrintApiManager.transaction_report.component';
import { FootPrintApiManager_messages_json_payloadComponent } from './FootPrintApiManager.messages_json_payload.component';
import { FootPrintApiManager_view_json_payloadComponent } from './FootPrintApiManager.view_json_payload.component';
import { FootPrintApiManager_view_xml_payloadComponent } from './FootPrintApiManager.view_xml_payload.component';
import { FootPrintApiManager_directions_dd_singleComponent } from './FootPrintApiManager.directions_dd_single.component';
import { FootPrintApiManager_integrations_dd_singleComponent } from './FootPrintApiManager.integrations_dd_single.component';
import { FootPrintApiManager_message_properties_singleComponent } from './FootPrintApiManager.message_properties_single.component';
import { FootPrintApiManager_processes_dd_singleComponent } from './FootPrintApiManager.processes_dd_single.component';
import { FootPrintApiManager_statuses_dd_singleComponent } from './FootPrintApiManager.statuses_dd_single.component';
import { FootPrintApiManager_transaction_types_dd_singleComponent } from './FootPrintApiManager.transaction_types_dd_single.component';
import { FootPrintApiManager_directions_dd_multiComponent } from './FootPrintApiManager.directions_dd_multi.component';
import { FootPrintApiManager_integrations_dd_multiComponent } from './FootPrintApiManager.integrations_dd_multi.component';
import { FootPrintApiManager_message_properties_multiComponent } from './FootPrintApiManager.message_properties_multi.component';
import { FootPrintApiManager_processes_dd_multiComponent } from './FootPrintApiManager.processes_dd_multi.component';
import { FootPrintApiManager_statuses_dd_multiComponent } from './FootPrintApiManager.statuses_dd_multi.component';
import { FootPrintApiManager_transaction_types_dd_multiComponent } from './FootPrintApiManager.transaction_types_dd_multi.component';
import { FootPrintApiManager_control_partners_directions_widgetComponent } from './FootPrintApiManager.control_partners_directions_widget.component';
import { FootPrintApiManager_message_statuses_widgetComponent } from './FootPrintApiManager.message_statuses_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Attachments_ShellService } from './Attachments.shell.service';
import { Instructions_ShellService } from './Instructions.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public Attachments: Attachments_ShellService,
    public Instructions: Instructions_ShellService,
    public Materials: Materials_ShellService,
    public Owners: Owners_ShellService,
    public Inventory: Inventory_ShellService,
    public Notifications: Notifications_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public Invoices: Invoices_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
  ) {
    super(dialog, toastr);
  }

  public FootPrintApiManager: FootPrintApiManager_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openinsert_alert_form(inParams:{ alert?: any }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'insert_alert_form',
        referenceName: 'FootPrintApiManager_insert_alert_form',
        component: FootPrintApiManager_insert_alert_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinsert_alert_formDialog(
    inParams:{ alert?: any }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_insert_alert_formComponent,
      'insert_alert_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinsert_log_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'insert_log_form',
        referenceName: 'FootPrintApiManager_insert_log_form',
        component: FootPrintApiManager_insert_log_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinsert_log_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_insert_log_formComponent,
      'insert_log_form',
      mode,
      dialogSize
    )
  }
  public openasn_order_editor(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'asn_order_editor',
        referenceName: 'FootPrintApiManager_asn_order_editor',
        component: FootPrintApiManager_asn_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_order_editorDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_asn_order_editorComponent,
      'asn_order_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openfootprint_api_runner(inParams:{ title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Runner',
        referenceName: 'FootPrintApiManager_footprint_api_runner',
        component: FootPrintApiManager_footprint_api_runnerComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openfootprint_api_runnerDialog(
    inParams:{ title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_footprint_api_runnerComponent,
      'Runner',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'purchase_order_editor',
        referenceName: 'FootPrintApiManager_purchase_order_editor',
        component: FootPrintApiManager_purchase_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_purchase_order_editorComponent,
      'purchase_order_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'sales_order_editor',
        referenceName: 'FootPrintApiManager_sales_order_editor',
        component: FootPrintApiManager_sales_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_sales_order_editorComponent,
      'sales_order_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openadjustment_tasks_grid(inParams:{ hasTransmissions?: boolean, project?: string, warehouse?: string, material?: string, taskIds?: number[], take?: number }, replaceCurrentView?: boolean) {
    this.logger.log('FootPrintApiManager', 'adjustment_tasks_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Adjustment tasks grid',
        referenceName: 'FootPrintApiManager_adjustment_tasks_grid',
        component: FootPrintApiManager_adjustment_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openadjustment_tasks_gridDialog(
    inParams:{ hasTransmissions?: boolean, project?: string, warehouse?: string, material?: string, taskIds?: number[], take?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('FootPrintApiManager', 'adjustment_tasks_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_adjustment_tasks_gridComponent,
      'Adjustment tasks grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openadjustments_grid(replaceCurrentView?: boolean) {
    this.logger.log('FootPrintApiManager', 'adjustments_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Adjustments grid',
        referenceName: 'FootPrintApiManager_adjustments_grid',
        component: FootPrintApiManager_adjustments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openadjustments_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('FootPrintApiManager', 'adjustments_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_adjustments_gridComponent,
      'Adjustments grid',
      mode,
      dialogSize
    )
  }
  public openalerts_filters_grid(inParams:{ integration_name?: string, alert_id?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Alerts filters grid',
        referenceName: 'FootPrintApiManager_alerts_filters_grid',
        component: FootPrintApiManager_alerts_filters_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openalerts_filters_gridDialog(
    inParams:{ integration_name?: string, alert_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_alerts_filters_gridComponent,
      'Alerts filters grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openalerts_grid(inParams:{ integration_name?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Alerts grid',
        referenceName: 'FootPrintApiManager_alerts_grid',
        component: FootPrintApiManager_alerts_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openalerts_gridDialog(
    inParams:{ integration_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_alerts_gridComponent,
      'Alerts grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbase_order_lines_grid(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Base orderlines grid ',
        referenceName: 'FootPrintApiManager_base_order_lines_grid',
        component: FootPrintApiManager_base_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbase_order_lines_gridDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_base_order_lines_gridComponent,
      'Base orderlines grid ',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openconfigurations_grid(inParams:{ integration_name?: string, user_name?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Configurations grid',
        referenceName: 'FootPrintApiManager_configurations_grid',
        component: FootPrintApiManager_configurations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openconfigurations_gridDialog(
    inParams:{ integration_name?: string, user_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_configurations_gridComponent,
      'Configurations grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencontrol_grid(inParams:{ integration_name?: string, user_name?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Control grid',
        referenceName: 'FootPrintApiManager_control_grid',
        component: FootPrintApiManager_control_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencontrol_gridDialog(
    inParams:{ integration_name?: string, user_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_control_gridComponent,
      'Control grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openintegrations_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Integrations grid',
        referenceName: 'FootPrintApiManager_integrations_grid',
        component: FootPrintApiManager_integrations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openintegrations_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_integrations_gridComponent,
      'Integrations grid',
      mode,
      dialogSize
    )
  }
  public openlifecycle_grid(inParams:{ integration_name?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Lifecycle grid',
        referenceName: 'FootPrintApiManager_lifecycle_grid',
        component: FootPrintApiManager_lifecycle_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlifecycle_gridDialog(
    inParams:{ integration_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_lifecycle_gridComponent,
      'Lifecycle grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlogs_grid(inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, egress_id?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Logs grid',
        referenceName: 'FootPrintApiManager_logs_grid',
        component: FootPrintApiManager_logs_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlogs_gridDialog(
    inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, egress_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_logs_gridComponent,
      'Logs grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmessages_grid(inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, eggress_id?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Messages grid',
        referenceName: 'FootPrintApiManager_messages_grid',
        component: FootPrintApiManager_messages_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmessages_gridDialog(
    inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, eggress_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_messages_gridComponent,
      'Messages grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openprocesses_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Processes grid',
        referenceName: 'FootPrintApiManager_processes_grid',
        component: FootPrintApiManager_processes_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openprocesses_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_processes_gridComponent,
      'Processes grid',
      mode,
      dialogSize
    )
  }
  public openstatuses_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Statuses grid',
        referenceName: 'FootPrintApiManager_statuses_grid',
        component: FootPrintApiManager_statuses_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openstatuses_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_statuses_gridComponent,
      'Statuses grid',
      mode,
      dialogSize
    )
  }
  public opentransaction_types_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Transaction types grid',
        referenceName: 'FootPrintApiManager_transaction_types_grid',
        component: FootPrintApiManager_transaction_types_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opentransaction_types_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_transaction_types_gridComponent,
      'Transaction types grid',
      mode,
      dialogSize
    )
  }
  public openfootprint_api_hub(inParams:{ integration_name?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'API hub',
        referenceName: 'FootPrintApiManager_footprint_api_hub',
        component: FootPrintApiManager_footprint_api_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openfootprint_api_hubDialog(
    inParams:{ integration_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_footprint_api_hubComponent,
      'API hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransaction_hub(inParams:{ transaction_id?: string, activate_grid?: string, ingress_id?: string, egress_id?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'transaction_hub',
        referenceName: 'FootPrintApiManager_transaction_hub',
        component: FootPrintApiManager_transaction_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransaction_hubDialog(
    inParams:{ transaction_id?: string, activate_grid?: string, ingress_id?: string, egress_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_transaction_hubComponent,
      'transaction_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransaction_report(inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, replaceCurrentView?: boolean) {
    this.logger.log('FootPrintApiManager', 'transaction_report');
    ShellService.openViewRequest$.next(
      {
        title: 'transaction_report',
        referenceName: 'FootPrintApiManager_transaction_report',
        component: FootPrintApiManager_transaction_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransaction_reportDialog(
    inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('FootPrintApiManager', 'transaction_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintApiManager_transaction_reportComponent,
      'transaction_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmessages_json_payload(inParams:{ payload?: any, title?: string, id?: string, integration_name?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'messages_json_payload',
        referenceName: 'FootPrintApiManager_messages_json_payload',
        component: FootPrintApiManager_messages_json_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmessages_json_payloadDialog(
    inParams:{ payload?: any, title?: string, id?: string, integration_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_messages_json_payloadComponent,
      'messages_json_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_json_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'view_json_payload',
        referenceName: 'FootPrintApiManager_view_json_payload',
        component: FootPrintApiManager_view_json_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_json_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_view_json_payloadComponent,
      'view_json_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_xml_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'view_xml_payload',
        referenceName: 'FootPrintApiManager_view_xml_payload',
        component: FootPrintApiManager_view_xml_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_xml_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_view_xml_payloadComponent,
      'view_xml_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'FootPrintApiManager_insert_alert_form') {
      const title = 'insert_alert_form';
      const component = FootPrintApiManager_insert_alert_formComponent;
      const inParams:{ alert?: any } = { alert: null };
      if (!isNil(params.get('alert'))) {
        const paramValueString = params.get('alert');
        inParams.alert = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_insert_log_form') {
      const title = 'insert_log_form';
      const component = FootPrintApiManager_insert_log_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_asn_order_editor') {
      const title = 'asn_order_editor';
      const component = FootPrintApiManager_asn_order_editorComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_footprint_api_runner') {
      const title = 'Runner';
      const component = FootPrintApiManager_footprint_api_runnerComponent;
      const inParams:{ title?: string } = { title: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_purchase_order_editor') {
      const title = 'purchase_order_editor';
      const component = FootPrintApiManager_purchase_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_sales_order_editor') {
      const title = 'sales_order_editor';
      const component = FootPrintApiManager_sales_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_adjustment_tasks_grid') {
      this.logger.log('FootPrintApiManager', 'adjustment_tasks_grid');
      const title = 'Adjustment tasks grid';
      const component = FootPrintApiManager_adjustment_tasks_gridComponent;
      const inParams:{ hasTransmissions?: boolean, project?: string, warehouse?: string, material?: string, taskIds?: number[], take?: number } = { hasTransmissions: null, project: null, warehouse: null, material: null, taskIds: [], take: null };
      if (!isNil(params.get('hasTransmissions'))) {
        const paramValueString = params.get('hasTransmissions');
        inParams.hasTransmissions = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('project'))) {
        const paramValueString = params.get('project');
        // TODO: date
        inParams.project = paramValueString;
              }
      if (!isNil(params.get('warehouse'))) {
        const paramValueString = params.get('warehouse');
        // TODO: date
        inParams.warehouse = paramValueString;
              }
      if (!isNil(params.get('material'))) {
        const paramValueString = params.get('material');
        // TODO: date
        inParams.material = paramValueString;
              }
      if (!isNil(params.get('taskIds'))) {
        const paramValueString = params.get('taskIds');
        inParams.taskIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('take'))) {
        const paramValueString = params.get('take');
        inParams.take = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_adjustments_grid') {
      this.logger.log('FootPrintApiManager', 'adjustments_grid');
      const title = 'Adjustments grid';
      const component = FootPrintApiManager_adjustments_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_alerts_filters_grid') {
      const title = 'Alerts filters grid';
      const component = FootPrintApiManager_alerts_filters_gridComponent;
      const inParams:{ integration_name?: string, alert_id?: string } = { integration_name: null, alert_id: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }
      if (!isNil(params.get('alert_id'))) {
        const paramValueString = params.get('alert_id');
        // TODO: date
        inParams.alert_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_alerts_grid') {
      const title = 'Alerts grid';
      const component = FootPrintApiManager_alerts_gridComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_base_order_lines_grid') {
      const title = 'Base orderlines grid ';
      const component = FootPrintApiManager_base_order_lines_gridComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_configurations_grid') {
      const title = 'Configurations grid';
      const component = FootPrintApiManager_configurations_gridComponent;
      const inParams:{ integration_name?: string, user_name?: string } = { integration_name: null, user_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_control_grid') {
      const title = 'Control grid';
      const component = FootPrintApiManager_control_gridComponent;
      const inParams:{ integration_name?: string, user_name?: string } = { integration_name: null, user_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_integrations_grid') {
      const title = 'Integrations grid';
      const component = FootPrintApiManager_integrations_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_lifecycle_grid') {
      const title = 'Lifecycle grid';
      const component = FootPrintApiManager_lifecycle_gridComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_logs_grid') {
      const title = 'Logs grid';
      const component = FootPrintApiManager_logs_gridComponent;
      const inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, egress_id?: string } = { transaction_id: null, integration_name: null, ingress_id: null, egress_id: null };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }
      if (!isNil(params.get('ingress_id'))) {
        const paramValueString = params.get('ingress_id');
        // TODO: date
        inParams.ingress_id = paramValueString;
              }
      if (!isNil(params.get('egress_id'))) {
        const paramValueString = params.get('egress_id');
        // TODO: date
        inParams.egress_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_messages_grid') {
      const title = 'Messages grid';
      const component = FootPrintApiManager_messages_gridComponent;
      const inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, eggress_id?: string } = { transaction_id: null, integration_name: null, ingress_id: null, eggress_id: null };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }
      if (!isNil(params.get('ingress_id'))) {
        const paramValueString = params.get('ingress_id');
        // TODO: date
        inParams.ingress_id = paramValueString;
              }
      if (!isNil(params.get('eggress_id'))) {
        const paramValueString = params.get('eggress_id');
        // TODO: date
        inParams.eggress_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_processes_grid') {
      const title = 'Processes grid';
      const component = FootPrintApiManager_processes_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_statuses_grid') {
      const title = 'Statuses grid';
      const component = FootPrintApiManager_statuses_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_transaction_types_grid') {
      const title = 'Transaction types grid';
      const component = FootPrintApiManager_transaction_types_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_footprint_api_hub') {
      const title = 'API hub';
      const component = FootPrintApiManager_footprint_api_hubComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_transaction_hub') {
      const title = 'transaction_hub';
      const component = FootPrintApiManager_transaction_hubComponent;
      const inParams:{ transaction_id?: string, activate_grid?: string, ingress_id?: string, egress_id?: string } = { transaction_id: null, activate_grid: null, ingress_id: null, egress_id: null };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('activate_grid'))) {
        const paramValueString = params.get('activate_grid');
        // TODO: date
        inParams.activate_grid = paramValueString;
              }
      if (!isNil(params.get('ingress_id'))) {
        const paramValueString = params.get('ingress_id');
        // TODO: date
        inParams.ingress_id = paramValueString;
              }
      if (!isNil(params.get('egress_id'))) {
        const paramValueString = params.get('egress_id');
        // TODO: date
        inParams.egress_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_transaction_report') {
      this.logger.log('FootPrintApiManager', 'transaction_report');
      const title = 'transaction_report';
      const component = FootPrintApiManager_transaction_reportComponent;
      const inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] } = { transaction_id: null, log_ids: [], message_ids: [] };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('log_ids'))) {
        const paramValueString = params.get('log_ids');
        inParams.log_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('message_ids'))) {
        const paramValueString = params.get('message_ids');
        inParams.message_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_messages_json_payload') {
      const title = 'messages_json_payload';
      const component = FootPrintApiManager_messages_json_payloadComponent;
      const inParams:{ payload?: any, title?: string, id?: string, integration_name?: string } = { payload: null, title: null, id: null, integration_name: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        // TODO: date
        inParams.id = paramValueString;
              }
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_view_json_payload') {
      const title = 'view_json_payload';
      const component = FootPrintApiManager_view_json_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_view_xml_payload') {
      const title = 'view_xml_payload';
      const component = FootPrintApiManager_view_xml_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_directions_dd_single') {
      const title = 'directions_dd';
      const component = FootPrintApiManager_directions_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_integrations_dd_single') {
      const title = 'integrations_dd';
      const component = FootPrintApiManager_integrations_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_message_properties_single') {
      const title = 'message_properties';
      const component = FootPrintApiManager_message_properties_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_processes_dd_single') {
      const title = 'processes_dd';
      const component = FootPrintApiManager_processes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_statuses_dd_single') {
      const title = 'statuses_dd';
      const component = FootPrintApiManager_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_transaction_types_dd_single') {
      const title = 'transaction_types_dd';
      const component = FootPrintApiManager_transaction_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_directions_dd_multi') {
      const title = 'directions_dd';
      const component = FootPrintApiManager_directions_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_integrations_dd_multi') {
      const title = 'integrations_dd';
      const component = FootPrintApiManager_integrations_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_message_properties_multi') {
      const title = 'message_properties';
      const component = FootPrintApiManager_message_properties_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_processes_dd_multi') {
      const title = 'processes_dd';
      const component = FootPrintApiManager_processes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_statuses_dd_multi') {
      const title = 'statuses_dd';
      const component = FootPrintApiManager_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_transaction_types_dd_multi') {
      const title = 'transaction_types_dd';
      const component = FootPrintApiManager_transaction_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_control_partners_directions_widget') {
      const title = 'Total in/out transactions';
      const component = FootPrintApiManager_control_partners_directions_widgetComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_message_statuses_widget') {
      const title = 'Message statuses';
      const component = FootPrintApiManager_message_statuses_widgetComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Attachments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Instructions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
