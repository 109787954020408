import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DatexFormControl } from './models/datex-form-control';
import { ListItemModel, ELoadingStatus } from './components/list.component';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { DockAppointments_areas_dd_multiComponent } from './DockAppointments.areas_dd_multi.component'


import { FootPrintManager_dock_door_cardComponent } from './FootPrintManager.dock_door_card.component';

interface IFootPrintManager_dock_doors_listComponentEntity {
Id?: number, EligibleForAllocation?: boolean, IsPrimaryPick?: boolean, Name?: string, ParentId?: number, StatusId?: number, ChildLocationContainers?: { IsLoose?: boolean, Name?: string }[]}

class FootPrintManager_dock_doors_listComponentItemModel extends ListItemModel {
  list: FootPrintManager_dock_doors_listComponent;
  entity: IFootPrintManager_dock_doors_listComponentEntity;



  get $content_FootPrintManager_dock_door_card_inParams_dockDoorId(): number {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.Id;
    
    return expr;
  }

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
   ) {
    super();
  }

  private $unsubscribe$ = new Subject();
  destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $initializeExisting(list: FootPrintManager_dock_doors_listComponent, entity: IFootPrintManager_dock_doors_listComponentEntity) {
    this.list = list;
    this.entity = entity;

    this.styles = new Styles(
      ['small-card'], 
      { 
        width: '20%', 
        height: '300px' 
      }
    );
  }
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => DockAppointments_areas_dd_multiComponent),
    forwardRef(() => FootPrintManager_dock_door_cardComponent)
  ],
  selector: 'FootPrintManager-dock_doors_list',
  templateUrl: './FootPrintManager.dock_doors_list.component.html'
})
export class FootPrintManager_dock_doors_listComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseIds?: number[], appointmentType?: string, active?: boolean } = { warehouseIds: [], appointmentType: null, active: null };
  //#region Inputs
  @Input('warehouseIds') set $inParams_warehouseIds(v: number[]) {
    this.inParams.warehouseIds = v;
  }
  get $inParams_warehouseIds(): number[] {
    return this.inParams.warehouseIds;
  }
  @Input('appointmentType') set $inParams_appointmentType(v: string) {
    this.inParams.appointmentType = v;
  }
  get $inParams_appointmentType(): string {
    return this.inParams.appointmentType;
  }
  @Input('active') set $inParams_active(v: boolean) {
    this.inParams.active = v;
  }
  get $inParams_active(): boolean {
    return this.inParams.active;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  topToolbar = {
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, 'Refresh', 'icon-ic_fluent_arrow_clockwise_20_regular')
    )
  };

  formGroup: FormGroup = new FormGroup({
    area: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    area: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['area'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Area', false)
,
  }

  //#region filters inParams
  cacheValueFor_$fields_area_selector_inParams_warehouseIds: number[];
  get $fields_area_selector_inParams_warehouseIds(): number[] {
    const $list = this;
    const $utils = this.utils;
    const expr = $list.inParams.warehouseIds;
    
    if(!isEqual(this.cacheValueFor_$fields_area_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_area_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_area_selector_inParams_warehouseIds;
  }

  //#endregion filters inParams

  entities: IFootPrintManager_dock_doors_listComponentEntity[] = [];
  pageSize = 15;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus: ELoadingStatus = ELoadingStatus.Loading;

  items: FootPrintManager_dock_doors_listComponentItemModel[] = [];
  

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.title = 'Dock doors';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {

    const $list = this;
    const $utils = this.utils;



    this.initialized = true;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.refresh();
  }
 
  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    this.$reset();
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    
  }

  $reset() {
    this.pageSkip = 0;
    this.entities = [];
    this.items.forEach(i => i.destroy());
    this.items = [];
  }


  $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $list = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseIds:  $list.inParams.warehouseIds ,
      appointmentType:  $list.inParams.appointmentType ,
      statusId:  ($list.inParams.active == true) ? 1 : 2 ,
      parentIds:  $list.filters.area.control.value ,
    };
    this.datasources.DockAppointments.ds_dock_doors_by_warehouseIds_list.getList(inParams)
    .then(data => {
      this.entities.push(...data.result);
      this.totalCount = data.totalCount;
      this.$dataLoaded(data.result);
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    })
    .catch(error => {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    });
  }

  async $dataLoaded(data: IFootPrintManager_dock_doors_listComponentEntity[]) {
    for (let entity of data) {
      const item = new FootPrintManager_dock_doors_listComponentItemModel(
        this.utils,
        this.settings,
        this.shell, 
        this.datasources,
        this.flows,
        this.reports,
        this.localization,
        this.operations,
        this.logger);
      item.$initializeExisting(this, entity);
      this.items.push(item);
    } 
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  //#region private flows
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $list: FootPrintManager_dock_doors_listComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $list.refresh();
  }
  //#endregion private flows

  close() {
    this.$finish.emit();
  }
}
