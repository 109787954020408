<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-date_from" *ngIf="!filters.date_from.hidden" 
            class="field-container standard {{filters.date_from.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_from.styles.style"
            [ngClass]="filters.date_from.styles.classes">
        <div class="label-container"
              title="{{filters.date_from.label}}{{filters.date_from.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.date_from.label}}">{{filters.date_from.label}}<span *ngIf="filters.date_from.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_from"
            [format]="filters.date_from.control.format"
            [mode]="filters.date_from.control.mode"
            [ngStyle]="filters.date_from.control.styles.style"
            [ngClass]="filters.date_from.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.date_from.invalid">
          <ng-container *ngFor="let error of filters.date_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_to" *ngIf="!filters.date_to.hidden" 
            class="field-container standard {{filters.date_to.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_to.styles.style"
            [ngClass]="filters.date_to.styles.classes">
        <div class="label-container"
              title="{{filters.date_to.label}}{{filters.date_to.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.date_to.label}}">{{filters.date_to.label}}<span *ngIf="filters.date_to.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_to"
            [format]="filters.date_to.control.format"
            [mode]="filters.date_to.control.mode"
            [ngStyle]="filters.date_to.control.styles.style"
            [ngClass]="filters.date_to.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.date_to.invalid">
          <ng-container *ngFor="let error of filters.date_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.name.displayControl.styles.style"
          [ngClass]="row.cells.name.displayControl.styles.classes">{{row.cells.name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.value.displayControl.styles.style"
          [ngClass]="row.cells.value.displayControl.styles.classes">{{row.cells.value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.date.displayControl.styles.style"
          [ngClass]="row.cells.date.displayControl.styles.classes">{{row.cells.date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
