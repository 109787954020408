<div class="modal-container">
  <div mat-dialog-title
       class="modal-header">
    <!-- TODO Icon-->
    <!-- TODO runtime title -->
    <h1 class="modal-title">{{title}}</h1>
    <div class="modal-close-button"
         (click)="close()">
      <i class="icon icon-ic_fluent_dismiss_20_regular"></i>
    </div>
  </div>
  <div mat-dialog-content
       class="modal-content">
    <ndc-dynamic [ndcDynamicComponent]="componentType"
                 [ndcDynamicInputs]="inputs"
                 [ndcDynamicOutputs]="outputs"
                 (ndcDynamicCreated)="onComponentCreated($event)">
    </ndc-dynamic>
  </div>
  <div mat-dialog-actions
       class="modal-toolbar commands">
    <ng-container *ngTemplateOutlet="commandsTmpRef"></ng-container>
  </div>
</div>