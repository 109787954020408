import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { ExcelOrderImport_ds_get_lots_by_materials_projects_lotsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_order_by_orderIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_orderaddress_by_type_and_orderIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_orders_by_lookupcodes_orderclasses_projects_warehousesService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_orders_by_lookupcodes_types_projects_warehousesService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_packagings_by_materials_projects_owners_packagingsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_packagings_by_materials_projects_packagingsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_project_by_projectIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_shipment_by_orderIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_vendorlots_by_materials_projects_vendorlotsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_warehouse_by_warehouseIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_warehouses_by_namesService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_material_packagings_ddService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_materials_by_project_ddService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_order_import_gridService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_projects_ddService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_warehouses_ddService } from './ExcelOrderImport.datasource.index';

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public ExcelOrderImport: ExcelOrderImport_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_lots_by_materials_projects_lots: ExcelOrderImport_ds_get_lots_by_materials_projects_lotsService;
  public get ds_get_lots_by_materials_projects_lots(): ExcelOrderImport_ds_get_lots_by_materials_projects_lotsService {
    if(!this._ds_get_lots_by_materials_projects_lots) {
      this._ds_get_lots_by_materials_projects_lots = this.injector.get(ExcelOrderImport_ds_get_lots_by_materials_projects_lotsService);
    }
    return this._ds_get_lots_by_materials_projects_lots;
  }
  private _ds_get_order_by_orderId: ExcelOrderImport_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): ExcelOrderImport_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(ExcelOrderImport_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_orderaddress_by_type_and_orderId: ExcelOrderImport_ds_get_orderaddress_by_type_and_orderIdService;
  public get ds_get_orderaddress_by_type_and_orderId(): ExcelOrderImport_ds_get_orderaddress_by_type_and_orderIdService {
    if(!this._ds_get_orderaddress_by_type_and_orderId) {
      this._ds_get_orderaddress_by_type_and_orderId = this.injector.get(ExcelOrderImport_ds_get_orderaddress_by_type_and_orderIdService);
    }
    return this._ds_get_orderaddress_by_type_and_orderId;
  }
  private _ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses: ExcelOrderImport_ds_get_orders_by_lookupcodes_orderclasses_projects_warehousesService;
  public get ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses(): ExcelOrderImport_ds_get_orders_by_lookupcodes_orderclasses_projects_warehousesService {
    if(!this._ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses) {
      this._ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses = this.injector.get(ExcelOrderImport_ds_get_orders_by_lookupcodes_orderclasses_projects_warehousesService);
    }
    return this._ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses;
  }
  private _ds_get_orders_by_lookupcodes_types_projects_warehouses: ExcelOrderImport_ds_get_orders_by_lookupcodes_types_projects_warehousesService;
  public get ds_get_orders_by_lookupcodes_types_projects_warehouses(): ExcelOrderImport_ds_get_orders_by_lookupcodes_types_projects_warehousesService {
    if(!this._ds_get_orders_by_lookupcodes_types_projects_warehouses) {
      this._ds_get_orders_by_lookupcodes_types_projects_warehouses = this.injector.get(ExcelOrderImport_ds_get_orders_by_lookupcodes_types_projects_warehousesService);
    }
    return this._ds_get_orders_by_lookupcodes_types_projects_warehouses;
  }
  private _ds_get_packagings_by_materials_projects_owners_packagings: ExcelOrderImport_ds_get_packagings_by_materials_projects_owners_packagingsService;
  public get ds_get_packagings_by_materials_projects_owners_packagings(): ExcelOrderImport_ds_get_packagings_by_materials_projects_owners_packagingsService {
    if(!this._ds_get_packagings_by_materials_projects_owners_packagings) {
      this._ds_get_packagings_by_materials_projects_owners_packagings = this.injector.get(ExcelOrderImport_ds_get_packagings_by_materials_projects_owners_packagingsService);
    }
    return this._ds_get_packagings_by_materials_projects_owners_packagings;
  }
  private _ds_get_packagings_by_materials_projects_packagings: ExcelOrderImport_ds_get_packagings_by_materials_projects_packagingsService;
  public get ds_get_packagings_by_materials_projects_packagings(): ExcelOrderImport_ds_get_packagings_by_materials_projects_packagingsService {
    if(!this._ds_get_packagings_by_materials_projects_packagings) {
      this._ds_get_packagings_by_materials_projects_packagings = this.injector.get(ExcelOrderImport_ds_get_packagings_by_materials_projects_packagingsService);
    }
    return this._ds_get_packagings_by_materials_projects_packagings;
  }
  private _ds_get_project_by_projectId: ExcelOrderImport_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): ExcelOrderImport_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(ExcelOrderImport_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_shipment_by_orderId: ExcelOrderImport_ds_get_shipment_by_orderIdService;
  public get ds_get_shipment_by_orderId(): ExcelOrderImport_ds_get_shipment_by_orderIdService {
    if(!this._ds_get_shipment_by_orderId) {
      this._ds_get_shipment_by_orderId = this.injector.get(ExcelOrderImport_ds_get_shipment_by_orderIdService);
    }
    return this._ds_get_shipment_by_orderId;
  }
  private _ds_get_vendorlots_by_materials_projects_vendorlots: ExcelOrderImport_ds_get_vendorlots_by_materials_projects_vendorlotsService;
  public get ds_get_vendorlots_by_materials_projects_vendorlots(): ExcelOrderImport_ds_get_vendorlots_by_materials_projects_vendorlotsService {
    if(!this._ds_get_vendorlots_by_materials_projects_vendorlots) {
      this._ds_get_vendorlots_by_materials_projects_vendorlots = this.injector.get(ExcelOrderImport_ds_get_vendorlots_by_materials_projects_vendorlotsService);
    }
    return this._ds_get_vendorlots_by_materials_projects_vendorlots;
  }
  private _ds_get_warehouse_by_warehouseId: ExcelOrderImport_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): ExcelOrderImport_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(ExcelOrderImport_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_get_warehouses_by_names: ExcelOrderImport_ds_get_warehouses_by_namesService;
  public get ds_get_warehouses_by_names(): ExcelOrderImport_ds_get_warehouses_by_namesService {
    if(!this._ds_get_warehouses_by_names) {
      this._ds_get_warehouses_by_names = this.injector.get(ExcelOrderImport_ds_get_warehouses_by_namesService);
    }
    return this._ds_get_warehouses_by_names;
  }
  private _ds_material_packagings_dd: ExcelOrderImport_ds_material_packagings_ddService;
  public get ds_material_packagings_dd(): ExcelOrderImport_ds_material_packagings_ddService {
    if(!this._ds_material_packagings_dd) {
      this._ds_material_packagings_dd = this.injector.get(ExcelOrderImport_ds_material_packagings_ddService);
    }
    return this._ds_material_packagings_dd;
  }
  private _ds_materials_by_project_dd: ExcelOrderImport_ds_materials_by_project_ddService;
  public get ds_materials_by_project_dd(): ExcelOrderImport_ds_materials_by_project_ddService {
    if(!this._ds_materials_by_project_dd) {
      this._ds_materials_by_project_dd = this.injector.get(ExcelOrderImport_ds_materials_by_project_ddService);
    }
    return this._ds_materials_by_project_dd;
  }
  private _ds_order_import_grid: ExcelOrderImport_ds_order_import_gridService;
  public get ds_order_import_grid(): ExcelOrderImport_ds_order_import_gridService {
    if(!this._ds_order_import_grid) {
      this._ds_order_import_grid = this.injector.get(ExcelOrderImport_ds_order_import_gridService);
    }
    return this._ds_order_import_grid;
  }
  private _ds_projects_dd: ExcelOrderImport_ds_projects_ddService;
  public get ds_projects_dd(): ExcelOrderImport_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(ExcelOrderImport_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_warehouses_dd: ExcelOrderImport_ds_warehouses_ddService;
  public get ds_warehouses_dd(): ExcelOrderImport_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(ExcelOrderImport_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
}

