import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';


@Injectable({ providedIn: 'root' })
export class SerialNumbers_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public SerialNumbers: SerialNumbers_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

