import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_received_inventory_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, shipmentId: number, fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string, Vat?: string } }, ActualTargetLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ActualPackagedPack?: { ShortName?: string }, WeightUOM?: { Short_name?: string }, ShipmentLine?: { ExpectedPackagedAmount?: number, OrderLineNumber?: number, ExpectedPackaged?: { ShortName?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_received_inventory_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, shipmentId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string, Vat?: string } }, ActualTargetLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ActualPackagedPack?: { ShortName?: string }, WeightUOM?: { Short_name?: string }, ShipmentLine?: { ExpectedPackagedAmount?: number, OrderLineNumber?: number, ExpectedPackaged?: { ShortName?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_received_inventory_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, shipmentId: number, fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string, Vat?: string } }, ActualTargetLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ActualPackagedPack?: { ShortName?: string }, WeightUOM?: { Short_name?: string }, ShipmentLine?: { ExpectedPackagedAmount?: number, OrderLineNumber?: number, ExpectedPackaged?: { ShortName?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_received_inventory_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
