<div class="modal-container">
     <div mat-dialog-title
          class="modal-header">
          <h1 class="modal-title">{{title}}</h1>
          <div class="modal-close-button"
               (click)="close()">
               <i class="icon icon-ic_fluent_dismiss_20_regular"></i>
          </div>
     </div>
     <div mat-dialog-content
          class="modal-content">
          <ngx-image-viewer [src]="[data.imageSource]"
                            class="min-height-full"></ngx-image-viewer>
     </div>
</div>