import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ds_get_dock_appointment_history_by_appointmentIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { appointmentId: number }): 
  Promise<{ result: { Id?: string, AssignedlLocationContainerIdNew?: number, AssignedlLocationContainerIdPrevious?: number, AssignedOnNew?: string, CancelledOnNew?: string, CheckedInOnNew?: string, CompletedOnNew?: string, DockAppointmentId?: number, DriverLicenseNew?: string, DriverNameNew?: string, InProcessOnNew?: string, OccuredBy?: string, OccuredOn?: string, ScheduledArrivalNew?: string, ScheduledArrivalPrevious?: string, ScheduledDepartureNew?: string, ScheduledDeparturePrevious?: string, ScheduledLocationContainerIdNew?: number, ScheduledLocationContainerIdPrevious?: number, ScheduledOnNew?: string, ScheduledOnPrevious?: string, StatusIdNew?: number, StatusIdPrevious?: number, VehicleLicenseNew?: string, DockAppointment?: { LookupCode?: string, WarehouseId?: number }, StatusNew?: { Name?: string }, StatusPrevious?: { Name?: string }, ScheduledLocationNew?: { Name?: string }, ScheduledLocationPrevious?: { Name?: string }, AssignedLocationNew?: { Name?: string }, AssignedLocationPrevious?: { Name?: string }, convertedScheduledArrivalNew?: { id?: number, convertedDate?: string }, convertedScheduledArrivalPrev?: { id?: number, convertedDate?: string }, convertedScheduledDeptNew?: { id?: number, convertedDate?: string }, convertedScheduledDeptPrev?: { id?: number, convertedDate?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.appointmentId)) {
      missingRequiredInParams.push('\'appointmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_get_dock_appointment_history_by_appointmentId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { appointmentId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: string, AssignedlLocationContainerIdNew?: number, AssignedlLocationContainerIdPrevious?: number, AssignedOnNew?: string, CancelledOnNew?: string, CheckedInOnNew?: string, CompletedOnNew?: string, DockAppointmentId?: number, DriverLicenseNew?: string, DriverNameNew?: string, InProcessOnNew?: string, OccuredBy?: string, OccuredOn?: string, ScheduledArrivalNew?: string, ScheduledArrivalPrevious?: string, ScheduledDepartureNew?: string, ScheduledDeparturePrevious?: string, ScheduledLocationContainerIdNew?: number, ScheduledLocationContainerIdPrevious?: number, ScheduledOnNew?: string, ScheduledOnPrevious?: string, StatusIdNew?: number, StatusIdPrevious?: number, VehicleLicenseNew?: string, DockAppointment?: { LookupCode?: string, WarehouseId?: number }, StatusNew?: { Name?: string }, StatusPrevious?: { Name?: string }, ScheduledLocationNew?: { Name?: string }, ScheduledLocationPrevious?: { Name?: string }, AssignedLocationNew?: { Name?: string }, AssignedLocationPrevious?: { Name?: string }, convertedScheduledArrivalNew?: { id?: number, convertedDate?: string }, convertedScheduledArrivalPrev?: { id?: number, convertedDate?: string }, convertedScheduledDeptNew?: { id?: number, convertedDate?: string }, convertedScheduledDeptPrev?: { id?: number, convertedDate?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.appointmentId)) {
      missingRequiredInParams.push('\'appointmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_get_dock_appointment_history_by_appointmentId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { appointmentId: number, $keys: string[] }): 
  Promise<{ result: { Id?: string, AssignedlLocationContainerIdNew?: number, AssignedlLocationContainerIdPrevious?: number, AssignedOnNew?: string, CancelledOnNew?: string, CheckedInOnNew?: string, CompletedOnNew?: string, DockAppointmentId?: number, DriverLicenseNew?: string, DriverNameNew?: string, InProcessOnNew?: string, OccuredBy?: string, OccuredOn?: string, ScheduledArrivalNew?: string, ScheduledArrivalPrevious?: string, ScheduledDepartureNew?: string, ScheduledDeparturePrevious?: string, ScheduledLocationContainerIdNew?: number, ScheduledLocationContainerIdPrevious?: number, ScheduledOnNew?: string, ScheduledOnPrevious?: string, StatusIdNew?: number, StatusIdPrevious?: number, VehicleLicenseNew?: string, DockAppointment?: { LookupCode?: string, WarehouseId?: number }, StatusNew?: { Name?: string }, StatusPrevious?: { Name?: string }, ScheduledLocationNew?: { Name?: string }, ScheduledLocationPrevious?: { Name?: string }, AssignedLocationNew?: { Name?: string }, AssignedLocationPrevious?: { Name?: string }, convertedScheduledArrivalNew?: { id?: number, convertedDate?: string }, convertedScheduledArrivalPrev?: { id?: number, convertedDate?: string }, convertedScheduledDeptNew?: { id?: number, convertedDate?: string }, convertedScheduledDeptPrev?: { id?: number, convertedDate?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.appointmentId)) {
      missingRequiredInParams.push('\'appointmentId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_get_dock_appointment_history_by_appointmentId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
