<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="save_button" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_save_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator1" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="delete_record" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_delete_record_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-matrix_editor_header_group"
                     *ngIf="!fieldsets.matrix_editor_header_group.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.matrix_editor_header_group.collapsible }">
                    <div *ngIf="!fieldsets.matrix_editor_header_group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.matrix_editor_header_group.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.matrix_editor_header_group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.matrix_editor_header_group.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.matrix_editor_header_group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.matrix_editor_header_group.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookup_code" *ngIf="!fields.lookup_code.hidden" 
                            class="field-container standard {{fields.lookup_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookup_code.styles.style"
                            [ngClass]="fields.lookup_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookup_code.label}}{{fields.lookup_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookup_code.label}}">{{fields.lookup_code.label}}<span *ngIf="fields.lookup_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookup_code"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookup_code.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookup_code.control.placeholder}}"
                                [ngStyle]="fields.lookup_code.control.styles.style"
                                [ngClass]="fields.lookup_code.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookup_code.invalid">
                          <ng-container *ngFor="let error of fields.lookup_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse_id" *ngIf="!fields.warehouse_id.hidden" 
                            class="field-container standard {{fields.warehouse_id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse_id.styles.style"
                            [ngClass]="fields.warehouse_id.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse_id.label}}{{fields.warehouse_id.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse_id.label}}">{{fields.warehouse_id.label}}<span *ngIf="fields.warehouse_id.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-freight_billing_warehouse_selector_single 
                            data-cy="selector"
                            [type]="fields.warehouse_id.control.type"
                            formControlName="warehouse_id"
                            (displayTextChange)="fields.warehouse_id.control.displayText=$event"
                            [placeholder]="fields.warehouse_id.control.placeholder"
                            [styles]="fields.warehouse_id.control.styles"
                        >
                        </Invoices-freight_billing_warehouse_selector_single>
                        <ng-container *ngIf="fields.warehouse_id.invalid">
                          <ng-container *ngFor="let error of fields.warehouse_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner_id" *ngIf="!fields.owner_id.hidden" 
                            class="field-container standard {{fields.owner_id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner_id.styles.style"
                            [ngClass]="fields.owner_id.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner_id.label}}{{fields.owner_id.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner_id.label}}">{{fields.owner_id.label}}<span *ngIf="fields.owner_id.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-freight_billing_owner_selector_single 
                            data-cy="selector"
                            [type]="fields.owner_id.control.type"
                            formControlName="owner_id"
                            (displayTextChange)="fields.owner_id.control.displayText=$event"
                            [placeholder]="fields.owner_id.control.placeholder"
                            [styles]="fields.owner_id.control.styles"
                        >
                        </Invoices-freight_billing_owner_selector_single>
                        <ng-container *ngIf="fields.owner_id.invalid">
                          <ng-container *ngFor="let error of fields.owner_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project_id" *ngIf="!fields.project_id.hidden" 
                            class="field-container standard {{fields.project_id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project_id.styles.style"
                            [ngClass]="fields.project_id.styles.classes">
                        <div class="label-container"
                              title="{{fields.project_id.label}}{{fields.project_id.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project_id.label}}">{{fields.project_id.label}}<span *ngIf="fields.project_id.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-freight_billing_projects_selector_single 
                            data-cy="selector"
                            [type]="fields.project_id.control.type"
                            formControlName="project_id"
                            (displayTextChange)="fields.project_id.control.displayText=$event"
                            [placeholder]="fields.project_id.control.placeholder"
                            [styles]="fields.project_id.control.styles"
                          [owner_id]="$fields_project_id_selector_inParams_owner_id"
                        >
                        </Invoices-freight_billing_projects_selector_single>
                        <ng-container *ngIf="fields.project_id.invalid">
                          <ng-container *ngFor="let error of fields.project_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-account_id" *ngIf="!fields.account_id.hidden" 
                            class="field-container standard {{fields.account_id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.account_id.styles.style"
                            [ngClass]="fields.account_id.styles.classes">
                        <div class="label-container"
                              title="{{fields.account_id.label}}{{fields.account_id.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.account_id.label}}">{{fields.account_id.label}}<span *ngIf="fields.account_id.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-freight_billing_account_selector_single 
                            data-cy="selector"
                            [type]="fields.account_id.control.type"
                            formControlName="account_id"
                            (displayTextChange)="fields.account_id.control.displayText=$event"
                            [placeholder]="fields.account_id.control.placeholder"
                            [styles]="fields.account_id.control.styles"
                          [owner_id]="$fields_account_id_selector_inParams_owner_id"
                        >
                        </Invoices-freight_billing_account_selector_single>
                        <ng-container *ngIf="fields.account_id.invalid">
                          <ng-container *ngFor="let error of fields.account_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-address_id" *ngIf="!fields.address_id.hidden" 
                            class="field-container full {{fields.address_id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.address_id.styles.style"
                            [ngClass]="fields.address_id.styles.classes">
                        <div class="label-container"
                              title="{{fields.address_id.label}}{{fields.address_id.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.address_id.label}}">{{fields.address_id.label}}<span *ngIf="fields.address_id.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-freight_billing_address_selector_single 
                            data-cy="selector"
                            [type]="fields.address_id.control.type"
                            formControlName="address_id"
                            (displayTextChange)="fields.address_id.control.displayText=$event"
                            [placeholder]="fields.address_id.control.placeholder"
                            [styles]="fields.address_id.control.styles"
                          [account_id]="$fields_address_id_selector_inParams_account_id"
                        >
                        </Invoices-freight_billing_address_selector_single>
                        <ng-container *ngIf="fields.address_id.invalid">
                          <ng-container *ngFor="let error of fields.address_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-shipping_days_group"
                     *ngIf="!fieldsets.shipping_days_group.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.shipping_days_group.collapsible }">
                    <div *ngIf="!fieldsets.shipping_days_group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.shipping_days_group.toggle()">
                      <span class="fieldsetsTitle-text">Shipping days</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.shipping_days_group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.shipping_days_group.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.shipping_days_group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.shipping_days_group.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-ship_mon_label" *ngIf="!fields.ship_mon_label.hidden" 
                            class="field-container standard {{fields.ship_mon_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_mon_label.styles.style"
                            [ngClass]="fields.ship_mon_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_mon_label.label}}{{fields.ship_mon_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_mon_label.label}}">{{fields.ship_mon_label.label}}<span *ngIf="fields.ship_mon_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.ship_mon_label.control.styles.style"
                              [ngClass]="fields.ship_mon_label.control.styles.classes">{{fields.ship_mon_label.control.text }}</div>
                        <ng-container *ngIf="fields.ship_mon_label.invalid">
                          <ng-container *ngFor="let error of fields.ship_mon_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipping_days_mon" *ngIf="!fields.shipping_days_mon.hidden" 
                            class="field-container standard {{fields.shipping_days_mon.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipping_days_mon.styles.style"
                            [ngClass]="fields.shipping_days_mon.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipping_days_mon.label}}{{fields.shipping_days_mon.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipping_days_mon.label}}">{{fields.shipping_days_mon.label}}<span *ngIf="fields.shipping_days_mon.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="shipping_days_mon"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.shipping_days_mon.control.styles.style"
                                      [ngClass]="fields.shipping_days_mon.control.styles.classes">{{fields.shipping_days_mon.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.shipping_days_mon.invalid">
                          <ng-container *ngFor="let error of fields.shipping_days_mon.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_tue_label" *ngIf="!fields.ship_tue_label.hidden" 
                            class="field-container standard {{fields.ship_tue_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_tue_label.styles.style"
                            [ngClass]="fields.ship_tue_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_tue_label.label}}{{fields.ship_tue_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_tue_label.label}}">{{fields.ship_tue_label.label}}<span *ngIf="fields.ship_tue_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.ship_tue_label.control.styles.style"
                              [ngClass]="fields.ship_tue_label.control.styles.classes">{{fields.ship_tue_label.control.text }}</div>
                        <ng-container *ngIf="fields.ship_tue_label.invalid">
                          <ng-container *ngFor="let error of fields.ship_tue_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipping_days_tue" *ngIf="!fields.shipping_days_tue.hidden" 
                            class="field-container standard {{fields.shipping_days_tue.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipping_days_tue.styles.style"
                            [ngClass]="fields.shipping_days_tue.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipping_days_tue.label}}{{fields.shipping_days_tue.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipping_days_tue.label}}">{{fields.shipping_days_tue.label}}<span *ngIf="fields.shipping_days_tue.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="shipping_days_tue"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.shipping_days_tue.control.styles.style"
                                      [ngClass]="fields.shipping_days_tue.control.styles.classes">{{fields.shipping_days_tue.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.shipping_days_tue.invalid">
                          <ng-container *ngFor="let error of fields.shipping_days_tue.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_wed_label" *ngIf="!fields.ship_wed_label.hidden" 
                            class="field-container standard {{fields.ship_wed_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_wed_label.styles.style"
                            [ngClass]="fields.ship_wed_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_wed_label.label}}{{fields.ship_wed_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_wed_label.label}}">{{fields.ship_wed_label.label}}<span *ngIf="fields.ship_wed_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.ship_wed_label.control.styles.style"
                              [ngClass]="fields.ship_wed_label.control.styles.classes">{{fields.ship_wed_label.control.text }}</div>
                        <ng-container *ngIf="fields.ship_wed_label.invalid">
                          <ng-container *ngFor="let error of fields.ship_wed_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipping_days_wed" *ngIf="!fields.shipping_days_wed.hidden" 
                            class="field-container standard {{fields.shipping_days_wed.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipping_days_wed.styles.style"
                            [ngClass]="fields.shipping_days_wed.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipping_days_wed.label}}{{fields.shipping_days_wed.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipping_days_wed.label}}">{{fields.shipping_days_wed.label}}<span *ngIf="fields.shipping_days_wed.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="shipping_days_wed"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.shipping_days_wed.control.styles.style"
                                      [ngClass]="fields.shipping_days_wed.control.styles.classes">{{fields.shipping_days_wed.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.shipping_days_wed.invalid">
                          <ng-container *ngFor="let error of fields.shipping_days_wed.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_thu_label" *ngIf="!fields.ship_thu_label.hidden" 
                            class="field-container standard {{fields.ship_thu_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_thu_label.styles.style"
                            [ngClass]="fields.ship_thu_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_thu_label.label}}{{fields.ship_thu_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_thu_label.label}}">{{fields.ship_thu_label.label}}<span *ngIf="fields.ship_thu_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.ship_thu_label.control.styles.style"
                              [ngClass]="fields.ship_thu_label.control.styles.classes">{{fields.ship_thu_label.control.text }}</div>
                        <ng-container *ngIf="fields.ship_thu_label.invalid">
                          <ng-container *ngFor="let error of fields.ship_thu_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipping_days_thu" *ngIf="!fields.shipping_days_thu.hidden" 
                            class="field-container standard {{fields.shipping_days_thu.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipping_days_thu.styles.style"
                            [ngClass]="fields.shipping_days_thu.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipping_days_thu.label}}{{fields.shipping_days_thu.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipping_days_thu.label}}">{{fields.shipping_days_thu.label}}<span *ngIf="fields.shipping_days_thu.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="shipping_days_thu"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.shipping_days_thu.control.styles.style"
                                      [ngClass]="fields.shipping_days_thu.control.styles.classes">{{fields.shipping_days_thu.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.shipping_days_thu.invalid">
                          <ng-container *ngFor="let error of fields.shipping_days_thu.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_fri_label" *ngIf="!fields.ship_fri_label.hidden" 
                            class="field-container standard {{fields.ship_fri_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_fri_label.styles.style"
                            [ngClass]="fields.ship_fri_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_fri_label.label}}{{fields.ship_fri_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_fri_label.label}}">{{fields.ship_fri_label.label}}<span *ngIf="fields.ship_fri_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.ship_fri_label.control.styles.style"
                              [ngClass]="fields.ship_fri_label.control.styles.classes">{{fields.ship_fri_label.control.text }}</div>
                        <ng-container *ngIf="fields.ship_fri_label.invalid">
                          <ng-container *ngFor="let error of fields.ship_fri_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipping_days_fri" *ngIf="!fields.shipping_days_fri.hidden" 
                            class="field-container standard {{fields.shipping_days_fri.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipping_days_fri.styles.style"
                            [ngClass]="fields.shipping_days_fri.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipping_days_fri.label}}{{fields.shipping_days_fri.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipping_days_fri.label}}">{{fields.shipping_days_fri.label}}<span *ngIf="fields.shipping_days_fri.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="shipping_days_fri"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.shipping_days_fri.control.styles.style"
                                      [ngClass]="fields.shipping_days_fri.control.styles.classes">{{fields.shipping_days_fri.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.shipping_days_fri.invalid">
                          <ng-container *ngFor="let error of fields.shipping_days_fri.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_sat_label" *ngIf="!fields.ship_sat_label.hidden" 
                            class="field-container standard {{fields.ship_sat_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_sat_label.styles.style"
                            [ngClass]="fields.ship_sat_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_sat_label.label}}{{fields.ship_sat_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_sat_label.label}}">{{fields.ship_sat_label.label}}<span *ngIf="fields.ship_sat_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.ship_sat_label.control.styles.style"
                              [ngClass]="fields.ship_sat_label.control.styles.classes">{{fields.ship_sat_label.control.text }}</div>
                        <ng-container *ngIf="fields.ship_sat_label.invalid">
                          <ng-container *ngFor="let error of fields.ship_sat_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipping_days_sat" *ngIf="!fields.shipping_days_sat.hidden" 
                            class="field-container standard {{fields.shipping_days_sat.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipping_days_sat.styles.style"
                            [ngClass]="fields.shipping_days_sat.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipping_days_sat.label}}{{fields.shipping_days_sat.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipping_days_sat.label}}">{{fields.shipping_days_sat.label}}<span *ngIf="fields.shipping_days_sat.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="shipping_days_sat"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.shipping_days_sat.control.styles.style"
                                      [ngClass]="fields.shipping_days_sat.control.styles.classes">{{fields.shipping_days_sat.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.shipping_days_sat.invalid">
                          <ng-container *ngFor="let error of fields.shipping_days_sat.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_sun_label" *ngIf="!fields.ship_sun_label.hidden" 
                            class="field-container standard {{fields.ship_sun_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_sun_label.styles.style"
                            [ngClass]="fields.ship_sun_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_sun_label.label}}{{fields.ship_sun_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_sun_label.label}}">{{fields.ship_sun_label.label}}<span *ngIf="fields.ship_sun_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.ship_sun_label.control.styles.style"
                              [ngClass]="fields.ship_sun_label.control.styles.classes">{{fields.ship_sun_label.control.text }}</div>
                        <ng-container *ngIf="fields.ship_sun_label.invalid">
                          <ng-container *ngFor="let error of fields.ship_sun_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipping_days_sun" *ngIf="!fields.shipping_days_sun.hidden" 
                            class="field-container standard {{fields.shipping_days_sun.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipping_days_sun.styles.style"
                            [ngClass]="fields.shipping_days_sun.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipping_days_sun.label}}{{fields.shipping_days_sun.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipping_days_sun.label}}">{{fields.shipping_days_sun.label}}<span *ngIf="fields.shipping_days_sun.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="shipping_days_sun"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.shipping_days_sun.control.styles.style"
                                      [ngClass]="fields.shipping_days_sun.control.styles.classes">{{fields.shipping_days_sun.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.shipping_days_sun.invalid">
                          <ng-container *ngFor="let error of fields.shipping_days_sun.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-delivery_days_group"
                     *ngIf="!fieldsets.delivery_days_group.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.delivery_days_group.collapsible }">
                    <div *ngIf="!fieldsets.delivery_days_group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.delivery_days_group.toggle()">
                      <span class="fieldsetsTitle-text">Delivery days</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.delivery_days_group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.delivery_days_group.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.delivery_days_group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.delivery_days_group.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-delivery_mon_label" *ngIf="!fields.delivery_mon_label.hidden" 
                            class="field-container standard {{fields.delivery_mon_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_mon_label.styles.style"
                            [ngClass]="fields.delivery_mon_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_mon_label.label}}{{fields.delivery_mon_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_mon_label.label}}">{{fields.delivery_mon_label.label}}<span *ngIf="fields.delivery_mon_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.delivery_mon_label.control.styles.style"
                              [ngClass]="fields.delivery_mon_label.control.styles.classes">{{fields.delivery_mon_label.control.text }}</div>
                        <ng-container *ngIf="fields.delivery_mon_label.invalid">
                          <ng-container *ngFor="let error of fields.delivery_mon_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_days_mon" *ngIf="!fields.delivery_days_mon.hidden" 
                            class="field-container standard {{fields.delivery_days_mon.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_days_mon.styles.style"
                            [ngClass]="fields.delivery_days_mon.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_days_mon.label}}{{fields.delivery_days_mon.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_days_mon.label}}">{{fields.delivery_days_mon.label}}<span *ngIf="fields.delivery_days_mon.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="delivery_days_mon"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.delivery_days_mon.control.styles.style"
                                      [ngClass]="fields.delivery_days_mon.control.styles.classes">{{fields.delivery_days_mon.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.delivery_days_mon.invalid">
                          <ng-container *ngFor="let error of fields.delivery_days_mon.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_tue_label" *ngIf="!fields.delivery_tue_label.hidden" 
                            class="field-container standard {{fields.delivery_tue_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_tue_label.styles.style"
                            [ngClass]="fields.delivery_tue_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_tue_label.label}}{{fields.delivery_tue_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_tue_label.label}}">{{fields.delivery_tue_label.label}}<span *ngIf="fields.delivery_tue_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.delivery_tue_label.control.styles.style"
                              [ngClass]="fields.delivery_tue_label.control.styles.classes">{{fields.delivery_tue_label.control.text }}</div>
                        <ng-container *ngIf="fields.delivery_tue_label.invalid">
                          <ng-container *ngFor="let error of fields.delivery_tue_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_days_tue" *ngIf="!fields.delivery_days_tue.hidden" 
                            class="field-container standard {{fields.delivery_days_tue.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_days_tue.styles.style"
                            [ngClass]="fields.delivery_days_tue.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_days_tue.label}}{{fields.delivery_days_tue.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_days_tue.label}}">{{fields.delivery_days_tue.label}}<span *ngIf="fields.delivery_days_tue.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="delivery_days_tue"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.delivery_days_tue.control.styles.style"
                                      [ngClass]="fields.delivery_days_tue.control.styles.classes">{{fields.delivery_days_tue.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.delivery_days_tue.invalid">
                          <ng-container *ngFor="let error of fields.delivery_days_tue.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_wed_label" *ngIf="!fields.delivery_wed_label.hidden" 
                            class="field-container standard {{fields.delivery_wed_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_wed_label.styles.style"
                            [ngClass]="fields.delivery_wed_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_wed_label.label}}{{fields.delivery_wed_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_wed_label.label}}">{{fields.delivery_wed_label.label}}<span *ngIf="fields.delivery_wed_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.delivery_wed_label.control.styles.style"
                              [ngClass]="fields.delivery_wed_label.control.styles.classes">{{fields.delivery_wed_label.control.text }}</div>
                        <ng-container *ngIf="fields.delivery_wed_label.invalid">
                          <ng-container *ngFor="let error of fields.delivery_wed_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_days_wed" *ngIf="!fields.delivery_days_wed.hidden" 
                            class="field-container standard {{fields.delivery_days_wed.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_days_wed.styles.style"
                            [ngClass]="fields.delivery_days_wed.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_days_wed.label}}{{fields.delivery_days_wed.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_days_wed.label}}">{{fields.delivery_days_wed.label}}<span *ngIf="fields.delivery_days_wed.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="delivery_days_wed"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.delivery_days_wed.control.styles.style"
                                      [ngClass]="fields.delivery_days_wed.control.styles.classes">{{fields.delivery_days_wed.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.delivery_days_wed.invalid">
                          <ng-container *ngFor="let error of fields.delivery_days_wed.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_thu_label" *ngIf="!fields.delivery_thu_label.hidden" 
                            class="field-container standard {{fields.delivery_thu_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_thu_label.styles.style"
                            [ngClass]="fields.delivery_thu_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_thu_label.label}}{{fields.delivery_thu_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_thu_label.label}}">{{fields.delivery_thu_label.label}}<span *ngIf="fields.delivery_thu_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.delivery_thu_label.control.styles.style"
                              [ngClass]="fields.delivery_thu_label.control.styles.classes">{{fields.delivery_thu_label.control.text }}</div>
                        <ng-container *ngIf="fields.delivery_thu_label.invalid">
                          <ng-container *ngFor="let error of fields.delivery_thu_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_days_thu" *ngIf="!fields.delivery_days_thu.hidden" 
                            class="field-container standard {{fields.delivery_days_thu.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_days_thu.styles.style"
                            [ngClass]="fields.delivery_days_thu.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_days_thu.label}}{{fields.delivery_days_thu.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_days_thu.label}}">{{fields.delivery_days_thu.label}}<span *ngIf="fields.delivery_days_thu.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="delivery_days_thu"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.delivery_days_thu.control.styles.style"
                                      [ngClass]="fields.delivery_days_thu.control.styles.classes">{{fields.delivery_days_thu.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.delivery_days_thu.invalid">
                          <ng-container *ngFor="let error of fields.delivery_days_thu.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_fri_label" *ngIf="!fields.delivery_fri_label.hidden" 
                            class="field-container standard {{fields.delivery_fri_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_fri_label.styles.style"
                            [ngClass]="fields.delivery_fri_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_fri_label.label}}{{fields.delivery_fri_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_fri_label.label}}">{{fields.delivery_fri_label.label}}<span *ngIf="fields.delivery_fri_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.delivery_fri_label.control.styles.style"
                              [ngClass]="fields.delivery_fri_label.control.styles.classes">{{fields.delivery_fri_label.control.text }}</div>
                        <ng-container *ngIf="fields.delivery_fri_label.invalid">
                          <ng-container *ngFor="let error of fields.delivery_fri_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_days_fri" *ngIf="!fields.delivery_days_fri.hidden" 
                            class="field-container standard {{fields.delivery_days_fri.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_days_fri.styles.style"
                            [ngClass]="fields.delivery_days_fri.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_days_fri.label}}{{fields.delivery_days_fri.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_days_fri.label}}">{{fields.delivery_days_fri.label}}<span *ngIf="fields.delivery_days_fri.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="delivery_days_fri"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.delivery_days_fri.control.styles.style"
                                      [ngClass]="fields.delivery_days_fri.control.styles.classes">{{fields.delivery_days_fri.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.delivery_days_fri.invalid">
                          <ng-container *ngFor="let error of fields.delivery_days_fri.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_sat_label" *ngIf="!fields.delivery_sat_label.hidden" 
                            class="field-container standard {{fields.delivery_sat_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_sat_label.styles.style"
                            [ngClass]="fields.delivery_sat_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_sat_label.label}}{{fields.delivery_sat_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_sat_label.label}}">{{fields.delivery_sat_label.label}}<span *ngIf="fields.delivery_sat_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.delivery_sat_label.control.styles.style"
                              [ngClass]="fields.delivery_sat_label.control.styles.classes">{{fields.delivery_sat_label.control.text }}</div>
                        <ng-container *ngIf="fields.delivery_sat_label.invalid">
                          <ng-container *ngFor="let error of fields.delivery_sat_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_days_sat" *ngIf="!fields.delivery_days_sat.hidden" 
                            class="field-container standard {{fields.delivery_days_sat.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_days_sat.styles.style"
                            [ngClass]="fields.delivery_days_sat.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_days_sat.label}}{{fields.delivery_days_sat.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_days_sat.label}}">{{fields.delivery_days_sat.label}}<span *ngIf="fields.delivery_days_sat.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="delivery_days_sat"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.delivery_days_sat.control.styles.style"
                                      [ngClass]="fields.delivery_days_sat.control.styles.classes">{{fields.delivery_days_sat.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.delivery_days_sat.invalid">
                          <ng-container *ngFor="let error of fields.delivery_days_sat.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_sun_label" *ngIf="!fields.delivery_sun_label.hidden" 
                            class="field-container standard {{fields.delivery_sun_label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_sun_label.styles.style"
                            [ngClass]="fields.delivery_sun_label.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_sun_label.label}}{{fields.delivery_sun_label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_sun_label.label}}">{{fields.delivery_sun_label.label}}<span *ngIf="fields.delivery_sun_label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.delivery_sun_label.control.styles.style"
                              [ngClass]="fields.delivery_sun_label.control.styles.classes">{{fields.delivery_sun_label.control.text }}</div>
                        <ng-container *ngIf="fields.delivery_sun_label.invalid">
                          <ng-container *ngFor="let error of fields.delivery_sun_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-delivery_days_sun" *ngIf="!fields.delivery_days_sun.hidden" 
                            class="field-container standard {{fields.delivery_days_sun.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.delivery_days_sun.styles.style"
                            [ngClass]="fields.delivery_days_sun.styles.classes">
                        <div class="label-container"
                              title="{{fields.delivery_days_sun.label}}{{fields.delivery_days_sun.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.delivery_days_sun.label}}">{{fields.delivery_days_sun.label}}<span *ngIf="fields.delivery_days_sun.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="delivery_days_sun"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.delivery_days_sun.control.styles.style"
                                      [ngClass]="fields.delivery_days_sun.control.styles.classes">{{fields.delivery_days_sun.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.delivery_days_sun.invalid">
                          <ng-container *ngFor="let error of fields.delivery_days_sun.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.freigth_charge.hidden" class="tab" data-cy="tab-freigth_charge">
              <h2 [className]="tabs.freigth_charge.active? 'active': ''" (click)="tabs.freigth_charge.activate()">{{tabs.freigth_charge.title}}</h2>
            </div>
          </div>
        
              <Invoices-freight_billing_details_editor_grid *ngIf="tabs.freigth_charge.active"
              #$tabs_freigth_charge
              [lookupcode]="$tabs_freigth_charge_freight_billing_details_editor_grid_inParams_lookupcode"
              ($refreshEvent)="refresh(false, false, '$tabs_freigth_charge')">
              </Invoices-freight_billing_details_editor_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>