import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  forwardRef,
  ViewChild,
  ElementRef,
  HostBinding,
  HostListener
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ESelectBoxType } from '../models/control';

import { Styles } from '../models/style';
import { UtilsService } from '../utils.service';

import { DropdownMultiComponent } from './dropdown-multi.component';
import { DropdownSingleComponent } from './dropdown-single.component';
import { MatLegacyRadioGroup } from '@angular/material/legacy-radio';

export type ESelectorType = ESelectBoxType;

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorComponent),
      multi: true
    }
  ]
})
export class SelectorComponent implements OnInit, ControlValueAccessor {
  @Input() multiple: boolean;
  @Input() type?: ESelectBoxType;

  @Input() placeholder = '';
  @Input()
  displayWithFn: ((value: any) => Promise<any>) | null

  @Input()
  optionsFn: ((filterText: string) => Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; selected?: boolean; }>, totalCount?: number, top?: number }>) | null

  @Input() styles: Styles;

  @Input()
  newItemLabel: string;;
  @Output()
  newItemClick = new EventEmitter<void>();

  @ViewChild('selector', { read: ElementRef }) selector: ElementRef;
  @ViewChild(DropdownSingleComponent) dropdownSingle: DropdownSingleComponent;
  @ViewChild(DropdownMultiComponent) dropdownMulti: DropdownMultiComponent;
  @ViewChild(MatLegacyRadioGroup) radioGroup: MatLegacyRadioGroup;


  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostBinding('class.selector') selectorClass = true;
  @HostListener('focus')
  focus() {
    if (this.radioGroup) {
      this.radioGroup?._radios?.first?.focus();
    } else {
      this.selector?.nativeElement?.focus();
    }
  }

  selectorTypeEnums = ESelectBoxType;
  _innerValue: any;
  disabled: boolean;
  // used when type is not dropdown
  options: Array<{ key: any; name: string; disabled?: boolean; selected?: boolean; }> = [];
  message: string;

  constructor(private utils: UtilsService) {
  }

  ngOnInit() {
    this.init();
  }

  public init() {
    if (this.type && this.type !== ESelectBoxType.dropdown) {
      this.optionsFn('').then(result => {
        this.options = result.list;
        this.message = '';
        if (this.options.length === 0) {
          this.message = 'No options to display';
        } else if (this.utils.isDefined(result.totalCount)) {
          if (result.totalCount > this.options.length) {
            this.message = `${this.options.length} of ${result.totalCount} options shown.`;
          }
        } else if (this.utils.isDefined(result.top)) {
          if (result.top >= this.options.length) {
            this.message = `More than ${(result.top - 1)} options found.`;
          }
        }
      });
    } else {
      if (this.multiple) {
        this.dropdownMulti?.clearListData();
      } else {
        this.dropdownSingle?.clearListData();
      }
    }
  }

  //ControlValueAccessor
  onChange: any = () => { };
  onTouch: any = () => { };

  get value() {
    return this._innerValue;
  }

  set value(val) {
    if (this.value !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
