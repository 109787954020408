import { Component, Directive, Input, Output, EventEmitter, TemplateRef, ContentChild, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { Styles } from '../models/style';

export class ListItemModel {
  styles: Styles;
}

export enum ELoadingStatus {
    Loading,
    Loaded,
    NoResults,
    Error
}

@Directive({
  selector: '[listItemDef]'
})
export class ListItemDef {
  constructor(public template: TemplateRef<any>) { }
}

@Component({
  selector: 'datex-list',
  templateUrl: './list.component.html'
})
export class ListComponent {

  @ContentChild(ListItemDef) listItemTemplate: ListItemDef;

  @Input() items: ListItemModel[];

  @Input() pageSize: number = 10;
  @Input() totalCount: number = 0;
  @Input() loadingStatus: ELoadingStatus = ELoadingStatus.Loading;

  @Output() loadMore: EventEmitter<void> = new EventEmitter();

  @Input() pageSkip: number = 0;
  @Output() pageSkipChange: EventEmitter<number> = new EventEmitter();

  public ELoadingStatus = ELoadingStatus;

  goToNextPage() {
    this.pageSkip = this.pageSkip + this.pageSize;
    this.pageSkipChange.emit(this.pageSkip);
    this.loadMore.emit();
  }

  getIsThereMoreData() {
    return this.items.length < this.totalCount;
  }
}
