import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class InventoryCounts_ds_get_tasks_by_taskIdsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskIds: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CancelledDateTime?: string, ChainHead?: number, CompletedDateTime?: string, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, ExpectedSourceLicensePlateId?: number, ExpectedSourceLocationId?: number, LotId?: number, MaterialId?: number, ModifiedSysUser?: string, ProjectId?: number, StartDateTime?: string, Undone?: boolean, VendorLotId?: number, WarehouseId?: number, Status?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string }, InventoryCountTaskProperty?: { BatchCreationTime?: string, ContextId?: number, InventoryCountAggregationItems?: number, InventoryCountBlindItems?: number, InventoryCountGroupPackages?: boolean, InventoryCountId?: number, InventoryCountLocationValidation?: boolean, InventoryCountRequestSerialNumbers?: boolean, InventoryCountTryScanOnlyEditor?: boolean, LpInventoryCountRequestLookupCode?: boolean }, InventoryCountDiscrepancies?: { Id?: number, ExpectedAmount?: number, StatusId?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskIds)) {
      missingRequiredInParams.push('\'taskIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_get_tasks_by_taskIds/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { taskIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CancelledDateTime?: string, ChainHead?: number, CompletedDateTime?: string, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, ExpectedSourceLicensePlateId?: number, ExpectedSourceLocationId?: number, LotId?: number, MaterialId?: number, ModifiedSysUser?: string, ProjectId?: number, StartDateTime?: string, Undone?: boolean, VendorLotId?: number, WarehouseId?: number, Status?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string }, InventoryCountTaskProperty?: { BatchCreationTime?: string, ContextId?: number, InventoryCountAggregationItems?: number, InventoryCountBlindItems?: number, InventoryCountGroupPackages?: boolean, InventoryCountId?: number, InventoryCountLocationValidation?: boolean, InventoryCountRequestSerialNumbers?: boolean, InventoryCountTryScanOnlyEditor?: boolean, LpInventoryCountRequestLookupCode?: boolean }, InventoryCountDiscrepancies?: { Id?: number, ExpectedAmount?: number, StatusId?: number }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskIds)) {
      missingRequiredInParams.push('\'taskIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_get_tasks_by_taskIds/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { taskIds: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CancelledDateTime?: string, ChainHead?: number, CompletedDateTime?: string, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, ExpectedSourceLicensePlateId?: number, ExpectedSourceLocationId?: number, LotId?: number, MaterialId?: number, ModifiedSysUser?: string, ProjectId?: number, StartDateTime?: string, Undone?: boolean, VendorLotId?: number, WarehouseId?: number, Status?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string }, InventoryCountTaskProperty?: { BatchCreationTime?: string, ContextId?: number, InventoryCountAggregationItems?: number, InventoryCountBlindItems?: number, InventoryCountGroupPackages?: boolean, InventoryCountId?: number, InventoryCountLocationValidation?: boolean, InventoryCountRequestSerialNumbers?: boolean, InventoryCountTryScanOnlyEditor?: boolean, LpInventoryCountRequestLookupCode?: boolean }, InventoryCountDiscrepancies?: { Id?: number, ExpectedAmount?: number, StatusId?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskIds)) {
      missingRequiredInParams.push('\'taskIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_get_tasks_by_taskIds/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
