import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_ds_get_orders_by_lookupcodes_orderclasses_projects_warehousesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderLookupcodes: string[], orderClasses?: string[], projectLookupcodes?: string[], projectIds?: number[], warehouseNames?: string[], warehouseIds?: number[], ownerReferences?: string[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, ProjectId?: number, VendorReference?: string, OrderClass?: { LookupCode?: string, OrderTypeId?: number, OrderType?: { Name?: string } }, Project?: { LookupCode?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number }[], PreferredWarehouse?: { Id?: number, Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderLookupcodes)) {
      missingRequiredInParams.push('\'orderLookupcodes\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/datasources/ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderLookupcodes: string[], orderClasses?: string[], projectLookupcodes?: string[], projectIds?: number[], warehouseNames?: string[], warehouseIds?: number[], ownerReferences?: string[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, ProjectId?: number, VendorReference?: string, OrderClass?: { LookupCode?: string, OrderTypeId?: number, OrderType?: { Name?: string } }, Project?: { LookupCode?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number }[], PreferredWarehouse?: { Id?: number, Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderLookupcodes)) {
      missingRequiredInParams.push('\'orderLookupcodes\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/datasources/ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderLookupcodes: string[], orderClasses?: string[], projectLookupcodes?: string[], projectIds?: number[], warehouseNames?: string[], warehouseIds?: number[], ownerReferences?: string[], $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, ProjectId?: number, VendorReference?: string, OrderClass?: { LookupCode?: string, OrderTypeId?: number, OrderType?: { Name?: string } }, Project?: { LookupCode?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number }[], PreferredWarehouse?: { Id?: number, Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderLookupcodes)) {
      missingRequiredInParams.push('\'orderLookupcodes\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/datasources/ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
