import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { SerialNumbers_ds_child_serialnumbers_gridService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_find_serialnumbersService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_license_plate_by_licensePlateIdService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_material_by_materialIdService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_materialpackagings_by_materialIdsService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_measurement_unit_by_unitIdService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_serial_numbers_by_materialIdsService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_serialnumber_by_codeService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_serialnumber_by_serialnumberIdService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_serialnumber_by_serialnumberIdsService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_serialnumber_by_various_top1Service } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_serialnumber_location_path_by_serialnumberIdService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_serialnumbers_by_chainHeadService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_serialnumbers_by_serialNumberIdsService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_get_tasks_by_serialnumberIdService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_handle_serialnumbers_lots_ddService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_handle_serialnumbers_packagings_ddService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_measurement_units_ddService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_reason_codes_ddService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serial_number_gross_weight_widgetService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serial_number_total_amount_widgetService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serial_number_total_available_widgetService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serial_numbers_audit_gridService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumber_editorService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumber_statuses_ddService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumbers_creation_gridService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumbers_expandService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumbers_gridService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumbers_grid_check_fixed_weightService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumbers_license_platesService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumbers_lots_ddService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumbers_packagings_ddService } from './SerialNumbers.datasource.index';
import { SerialNumbers_ds_serialnumbers_selection_gridService } from './SerialNumbers.datasource.index';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public SerialNumbers: SerialNumbers_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_child_serialnumbers_grid: SerialNumbers_ds_child_serialnumbers_gridService;
  public get ds_child_serialnumbers_grid(): SerialNumbers_ds_child_serialnumbers_gridService {
    if(!this._ds_child_serialnumbers_grid) {
      this._ds_child_serialnumbers_grid = this.injector.get(SerialNumbers_ds_child_serialnumbers_gridService);
    }
    return this._ds_child_serialnumbers_grid;
  }
  private _ds_find_serialnumbers: SerialNumbers_ds_find_serialnumbersService;
  public get ds_find_serialnumbers(): SerialNumbers_ds_find_serialnumbersService {
    if(!this._ds_find_serialnumbers) {
      this._ds_find_serialnumbers = this.injector.get(SerialNumbers_ds_find_serialnumbersService);
    }
    return this._ds_find_serialnumbers;
  }
  private _ds_get_license_plate_by_licensePlateId: SerialNumbers_ds_get_license_plate_by_licensePlateIdService;
  public get ds_get_license_plate_by_licensePlateId(): SerialNumbers_ds_get_license_plate_by_licensePlateIdService {
    if(!this._ds_get_license_plate_by_licensePlateId) {
      this._ds_get_license_plate_by_licensePlateId = this.injector.get(SerialNumbers_ds_get_license_plate_by_licensePlateIdService);
    }
    return this._ds_get_license_plate_by_licensePlateId;
  }
  private _ds_get_material_by_materialId: SerialNumbers_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): SerialNumbers_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(SerialNumbers_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_materialpackagings_by_materialIds: SerialNumbers_ds_get_materialpackagings_by_materialIdsService;
  public get ds_get_materialpackagings_by_materialIds(): SerialNumbers_ds_get_materialpackagings_by_materialIdsService {
    if(!this._ds_get_materialpackagings_by_materialIds) {
      this._ds_get_materialpackagings_by_materialIds = this.injector.get(SerialNumbers_ds_get_materialpackagings_by_materialIdsService);
    }
    return this._ds_get_materialpackagings_by_materialIds;
  }
  private _ds_get_measurement_unit_by_unitId: SerialNumbers_ds_get_measurement_unit_by_unitIdService;
  public get ds_get_measurement_unit_by_unitId(): SerialNumbers_ds_get_measurement_unit_by_unitIdService {
    if(!this._ds_get_measurement_unit_by_unitId) {
      this._ds_get_measurement_unit_by_unitId = this.injector.get(SerialNumbers_ds_get_measurement_unit_by_unitIdService);
    }
    return this._ds_get_measurement_unit_by_unitId;
  }
  private _ds_get_serial_numbers_by_materialIds: SerialNumbers_ds_get_serial_numbers_by_materialIdsService;
  public get ds_get_serial_numbers_by_materialIds(): SerialNumbers_ds_get_serial_numbers_by_materialIdsService {
    if(!this._ds_get_serial_numbers_by_materialIds) {
      this._ds_get_serial_numbers_by_materialIds = this.injector.get(SerialNumbers_ds_get_serial_numbers_by_materialIdsService);
    }
    return this._ds_get_serial_numbers_by_materialIds;
  }
  private _ds_get_serialnumber_by_code: SerialNumbers_ds_get_serialnumber_by_codeService;
  public get ds_get_serialnumber_by_code(): SerialNumbers_ds_get_serialnumber_by_codeService {
    if(!this._ds_get_serialnumber_by_code) {
      this._ds_get_serialnumber_by_code = this.injector.get(SerialNumbers_ds_get_serialnumber_by_codeService);
    }
    return this._ds_get_serialnumber_by_code;
  }
  private _ds_get_serialnumber_by_serialnumberId: SerialNumbers_ds_get_serialnumber_by_serialnumberIdService;
  public get ds_get_serialnumber_by_serialnumberId(): SerialNumbers_ds_get_serialnumber_by_serialnumberIdService {
    if(!this._ds_get_serialnumber_by_serialnumberId) {
      this._ds_get_serialnumber_by_serialnumberId = this.injector.get(SerialNumbers_ds_get_serialnumber_by_serialnumberIdService);
    }
    return this._ds_get_serialnumber_by_serialnumberId;
  }
  private _ds_get_serialnumber_by_serialnumberIds: SerialNumbers_ds_get_serialnumber_by_serialnumberIdsService;
  public get ds_get_serialnumber_by_serialnumberIds(): SerialNumbers_ds_get_serialnumber_by_serialnumberIdsService {
    if(!this._ds_get_serialnumber_by_serialnumberIds) {
      this._ds_get_serialnumber_by_serialnumberIds = this.injector.get(SerialNumbers_ds_get_serialnumber_by_serialnumberIdsService);
    }
    return this._ds_get_serialnumber_by_serialnumberIds;
  }
  private _ds_get_serialnumber_by_various_top1: SerialNumbers_ds_get_serialnumber_by_various_top1Service;
  public get ds_get_serialnumber_by_various_top1(): SerialNumbers_ds_get_serialnumber_by_various_top1Service {
    if(!this._ds_get_serialnumber_by_various_top1) {
      this._ds_get_serialnumber_by_various_top1 = this.injector.get(SerialNumbers_ds_get_serialnumber_by_various_top1Service);
    }
    return this._ds_get_serialnumber_by_various_top1;
  }
  private _ds_get_serialnumber_location_path_by_serialnumberId: SerialNumbers_ds_get_serialnumber_location_path_by_serialnumberIdService;
  public get ds_get_serialnumber_location_path_by_serialnumberId(): SerialNumbers_ds_get_serialnumber_location_path_by_serialnumberIdService {
    if(!this._ds_get_serialnumber_location_path_by_serialnumberId) {
      this._ds_get_serialnumber_location_path_by_serialnumberId = this.injector.get(SerialNumbers_ds_get_serialnumber_location_path_by_serialnumberIdService);
    }
    return this._ds_get_serialnumber_location_path_by_serialnumberId;
  }
  private _ds_get_serialnumbers_by_chainHead: SerialNumbers_ds_get_serialnumbers_by_chainHeadService;
  public get ds_get_serialnumbers_by_chainHead(): SerialNumbers_ds_get_serialnumbers_by_chainHeadService {
    if(!this._ds_get_serialnumbers_by_chainHead) {
      this._ds_get_serialnumbers_by_chainHead = this.injector.get(SerialNumbers_ds_get_serialnumbers_by_chainHeadService);
    }
    return this._ds_get_serialnumbers_by_chainHead;
  }
  private _ds_get_serialnumbers_by_serialNumberIds: SerialNumbers_ds_get_serialnumbers_by_serialNumberIdsService;
  public get ds_get_serialnumbers_by_serialNumberIds(): SerialNumbers_ds_get_serialnumbers_by_serialNumberIdsService {
    if(!this._ds_get_serialnumbers_by_serialNumberIds) {
      this._ds_get_serialnumbers_by_serialNumberIds = this.injector.get(SerialNumbers_ds_get_serialnumbers_by_serialNumberIdsService);
    }
    return this._ds_get_serialnumbers_by_serialNumberIds;
  }
  private _ds_get_tasks_by_serialnumberId: SerialNumbers_ds_get_tasks_by_serialnumberIdService;
  public get ds_get_tasks_by_serialnumberId(): SerialNumbers_ds_get_tasks_by_serialnumberIdService {
    if(!this._ds_get_tasks_by_serialnumberId) {
      this._ds_get_tasks_by_serialnumberId = this.injector.get(SerialNumbers_ds_get_tasks_by_serialnumberIdService);
    }
    return this._ds_get_tasks_by_serialnumberId;
  }
  private _ds_handle_serialnumbers_lots_dd: SerialNumbers_ds_handle_serialnumbers_lots_ddService;
  public get ds_handle_serialnumbers_lots_dd(): SerialNumbers_ds_handle_serialnumbers_lots_ddService {
    if(!this._ds_handle_serialnumbers_lots_dd) {
      this._ds_handle_serialnumbers_lots_dd = this.injector.get(SerialNumbers_ds_handle_serialnumbers_lots_ddService);
    }
    return this._ds_handle_serialnumbers_lots_dd;
  }
  private _ds_handle_serialnumbers_packagings_dd: SerialNumbers_ds_handle_serialnumbers_packagings_ddService;
  public get ds_handle_serialnumbers_packagings_dd(): SerialNumbers_ds_handle_serialnumbers_packagings_ddService {
    if(!this._ds_handle_serialnumbers_packagings_dd) {
      this._ds_handle_serialnumbers_packagings_dd = this.injector.get(SerialNumbers_ds_handle_serialnumbers_packagings_ddService);
    }
    return this._ds_handle_serialnumbers_packagings_dd;
  }
  private _ds_measurement_units_dd: SerialNumbers_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): SerialNumbers_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(SerialNumbers_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_reason_codes_dd: SerialNumbers_ds_reason_codes_ddService;
  public get ds_reason_codes_dd(): SerialNumbers_ds_reason_codes_ddService {
    if(!this._ds_reason_codes_dd) {
      this._ds_reason_codes_dd = this.injector.get(SerialNumbers_ds_reason_codes_ddService);
    }
    return this._ds_reason_codes_dd;
  }
  private _ds_serial_number_gross_weight_widget: SerialNumbers_ds_serial_number_gross_weight_widgetService;
  public get ds_serial_number_gross_weight_widget(): SerialNumbers_ds_serial_number_gross_weight_widgetService {
    if(!this._ds_serial_number_gross_weight_widget) {
      this._ds_serial_number_gross_weight_widget = this.injector.get(SerialNumbers_ds_serial_number_gross_weight_widgetService);
    }
    return this._ds_serial_number_gross_weight_widget;
  }
  private _ds_serial_number_total_amount_widget: SerialNumbers_ds_serial_number_total_amount_widgetService;
  public get ds_serial_number_total_amount_widget(): SerialNumbers_ds_serial_number_total_amount_widgetService {
    if(!this._ds_serial_number_total_amount_widget) {
      this._ds_serial_number_total_amount_widget = this.injector.get(SerialNumbers_ds_serial_number_total_amount_widgetService);
    }
    return this._ds_serial_number_total_amount_widget;
  }
  private _ds_serial_number_total_available_widget: SerialNumbers_ds_serial_number_total_available_widgetService;
  public get ds_serial_number_total_available_widget(): SerialNumbers_ds_serial_number_total_available_widgetService {
    if(!this._ds_serial_number_total_available_widget) {
      this._ds_serial_number_total_available_widget = this.injector.get(SerialNumbers_ds_serial_number_total_available_widgetService);
    }
    return this._ds_serial_number_total_available_widget;
  }
  private _ds_serial_numbers_audit_grid: SerialNumbers_ds_serial_numbers_audit_gridService;
  public get ds_serial_numbers_audit_grid(): SerialNumbers_ds_serial_numbers_audit_gridService {
    if(!this._ds_serial_numbers_audit_grid) {
      this._ds_serial_numbers_audit_grid = this.injector.get(SerialNumbers_ds_serial_numbers_audit_gridService);
    }
    return this._ds_serial_numbers_audit_grid;
  }
  private _ds_serialnumber_editor: SerialNumbers_ds_serialnumber_editorService;
  public get ds_serialnumber_editor(): SerialNumbers_ds_serialnumber_editorService {
    if(!this._ds_serialnumber_editor) {
      this._ds_serialnumber_editor = this.injector.get(SerialNumbers_ds_serialnumber_editorService);
    }
    return this._ds_serialnumber_editor;
  }
  private _ds_serialnumber_statuses_dd: SerialNumbers_ds_serialnumber_statuses_ddService;
  public get ds_serialnumber_statuses_dd(): SerialNumbers_ds_serialnumber_statuses_ddService {
    if(!this._ds_serialnumber_statuses_dd) {
      this._ds_serialnumber_statuses_dd = this.injector.get(SerialNumbers_ds_serialnumber_statuses_ddService);
    }
    return this._ds_serialnumber_statuses_dd;
  }
  private _ds_serialnumbers_creation_grid: SerialNumbers_ds_serialnumbers_creation_gridService;
  public get ds_serialnumbers_creation_grid(): SerialNumbers_ds_serialnumbers_creation_gridService {
    if(!this._ds_serialnumbers_creation_grid) {
      this._ds_serialnumbers_creation_grid = this.injector.get(SerialNumbers_ds_serialnumbers_creation_gridService);
    }
    return this._ds_serialnumbers_creation_grid;
  }
  private _ds_serialnumbers_expand: SerialNumbers_ds_serialnumbers_expandService;
  public get ds_serialnumbers_expand(): SerialNumbers_ds_serialnumbers_expandService {
    if(!this._ds_serialnumbers_expand) {
      this._ds_serialnumbers_expand = this.injector.get(SerialNumbers_ds_serialnumbers_expandService);
    }
    return this._ds_serialnumbers_expand;
  }
  private _ds_serialnumbers_grid: SerialNumbers_ds_serialnumbers_gridService;
  public get ds_serialnumbers_grid(): SerialNumbers_ds_serialnumbers_gridService {
    if(!this._ds_serialnumbers_grid) {
      this._ds_serialnumbers_grid = this.injector.get(SerialNumbers_ds_serialnumbers_gridService);
    }
    return this._ds_serialnumbers_grid;
  }
  private _ds_serialnumbers_grid_check_fixed_weight: SerialNumbers_ds_serialnumbers_grid_check_fixed_weightService;
  public get ds_serialnumbers_grid_check_fixed_weight(): SerialNumbers_ds_serialnumbers_grid_check_fixed_weightService {
    if(!this._ds_serialnumbers_grid_check_fixed_weight) {
      this._ds_serialnumbers_grid_check_fixed_weight = this.injector.get(SerialNumbers_ds_serialnumbers_grid_check_fixed_weightService);
    }
    return this._ds_serialnumbers_grid_check_fixed_weight;
  }
  private _ds_serialnumbers_license_plates: SerialNumbers_ds_serialnumbers_license_platesService;
  public get ds_serialnumbers_license_plates(): SerialNumbers_ds_serialnumbers_license_platesService {
    if(!this._ds_serialnumbers_license_plates) {
      this._ds_serialnumbers_license_plates = this.injector.get(SerialNumbers_ds_serialnumbers_license_platesService);
    }
    return this._ds_serialnumbers_license_plates;
  }
  private _ds_serialnumbers_lots_dd: SerialNumbers_ds_serialnumbers_lots_ddService;
  public get ds_serialnumbers_lots_dd(): SerialNumbers_ds_serialnumbers_lots_ddService {
    if(!this._ds_serialnumbers_lots_dd) {
      this._ds_serialnumbers_lots_dd = this.injector.get(SerialNumbers_ds_serialnumbers_lots_ddService);
    }
    return this._ds_serialnumbers_lots_dd;
  }
  private _ds_serialnumbers_packagings_dd: SerialNumbers_ds_serialnumbers_packagings_ddService;
  public get ds_serialnumbers_packagings_dd(): SerialNumbers_ds_serialnumbers_packagings_ddService {
    if(!this._ds_serialnumbers_packagings_dd) {
      this._ds_serialnumbers_packagings_dd = this.injector.get(SerialNumbers_ds_serialnumbers_packagings_ddService);
    }
    return this._ds_serialnumbers_packagings_dd;
  }
  private _ds_serialnumbers_selection_grid: SerialNumbers_ds_serialnumbers_selection_gridService;
  public get ds_serialnumbers_selection_grid(): SerialNumbers_ds_serialnumbers_selection_gridService {
    if(!this._ds_serialnumbers_selection_grid) {
      this._ds_serialnumbers_selection_grid = this.injector.get(SerialNumbers_ds_serialnumbers_selection_gridService);
    }
    return this._ds_serialnumbers_selection_grid;
  }
}

