import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inspections_ds_inspection_tasks_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseId: number, operationCodeIds?: number[], fullTextSearch?: string, statusIds?: number[], dateRange?: { type: string, from?: string, to?: string } }): 
  Promise<{ result: { Id?: number, CancelledDateTime?: string, CompletedDateTime?: string, CreatedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ReleaseDateTime?: string, StartDateTime?: string, OperationCode?: { Name?: string }, Status?: { Name?: string }, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, LookupCode?: string }, Order?: { Id?: number, LookupCode?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, LookupCode?: string }, ReasonCode?: { Name?: string }, Project?: { Id?: number, LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inspections/datasources/ds_inspection_tasks_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseId: number, operationCodeIds?: number[], fullTextSearch?: string, statusIds?: number[], dateRange?: { type: string, from?: string, to?: string }, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CancelledDateTime?: string, CompletedDateTime?: string, CreatedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ReleaseDateTime?: string, StartDateTime?: string, OperationCode?: { Name?: string }, Status?: { Name?: string }, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, LookupCode?: string }, Order?: { Id?: number, LookupCode?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, LookupCode?: string }, ReasonCode?: { Name?: string }, Project?: { Id?: number, LookupCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inspections/datasources/ds_inspection_tasks_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseId: number, operationCodeIds?: number[], fullTextSearch?: string, statusIds?: number[], dateRange?: { type: string, from?: string, to?: string }, $keys: number[] }): 
  Promise<{ result: { Id?: number, CancelledDateTime?: string, CompletedDateTime?: string, CreatedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ReleaseDateTime?: string, StartDateTime?: string, OperationCode?: { Name?: string }, Status?: { Name?: string }, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, LookupCode?: string }, Order?: { Id?: number, LookupCode?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, LookupCode?: string }, ReasonCode?: { Name?: string }, Project?: { Id?: number, LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inspections/datasources/ds_inspection_tasks_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
