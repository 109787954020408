import { Inject, Injectable, Injector }from '@angular/core';


import { BarcodeParsing_ds_get_scanned_material_detailsService } from './BarcodeParsing.datasource.index';

@Injectable({ providedIn: 'root' })
export class BarcodeParsing_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public BarcodeParsing: BarcodeParsing_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_scanned_material_details: BarcodeParsing_ds_get_scanned_material_detailsService;
  public get ds_get_scanned_material_details(): BarcodeParsing_ds_get_scanned_material_detailsService {
    if(!this._ds_get_scanned_material_details) {
      this._ds_get_scanned_material_details = this.injector.get(BarcodeParsing_ds_get_scanned_material_detailsService);
    }
    return this._ds_get_scanned_material_details;
  }
}

