<datex-list [items]="items"
            [pageSize]="pageSize"
            [(pageSkip)]="pageSkip"
            [totalCount]="totalCount"
            [loadingStatus]="loadingStatus"
            (loadMore)="$dataLoad()">

  <ng-container topToolbar>
  </ng-container>


  <ng-template listItemDef
               let-item>
    <FootPrintManager-count_task_card
    [taskId]="item.$content_FootPrintManager_count_task_card_inParams_taskId"
    (dataChanged)="on_data_changed($event)"
    ($refreshEvent)="$refreshEvent.emit()"
    >
    </FootPrintManager-count_task_card>
  </ng-template>

</datex-list>