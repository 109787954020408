import {
  Component,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  TemplateRef,
  Inject,
  OnDestroy
} from '@angular/core';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class Step {
  destroyed$ = new Subject();

  config: StepConfig;
  inParams: any;
  outParams: any;

  repeatIndex?: number;
  repeatCount?: number;
  isRepeatable: boolean;

  componentRef?: ComponentRef<any>;

  constructor(
    config: StepConfig,
    repeatIndex?: number,
    repeatCount?: number
  ) {
    this.config = config;
    this.inParams = this.config.inParamsFunc(repeatIndex);
    this.repeatIndex = repeatIndex;
    this.repeatCount = repeatCount;
    this.isRepeatable = !isNil(this.repeatCount);
  }

  destroy() {
    this.componentRef.destroy();
    this.destroyed$.next(null);
  }
}

export interface StepConfig {
  id: string;
  title: string;
  component: any;
  inParamsFunc?: ($index?: number) => any;
  repeatOverFunc?: () => number;
  nextConditionFunc?: ($index?: number) => boolean;
  next?: string;
  nextAlt?: string;
  nextButtonLabel?: string;
  nextButtonDisabledConditionFunc?: ($index?: number) => boolean;
}

@Component({
  selector: 'datex-wizard',
  templateUrl: './wizard.component.html'
})
export class WizardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("host", { read: ViewContainerRef })
  hostRef: ViewContainerRef;

  @ViewChild("buttons")
  buttonsTmpRef: TemplateRef<any>;

  @Input() stepConfigs: StepConfig[];
  @Input() stepsResultFunc: (result: any) => void;

  @Output() finish = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() commandsTmpRef = new EventEmitter<TemplateRef<any>>();

  steps: Step[] = [];
  currentStep: Step;
  currentStepIndex: number;

  progress: { id: string; title: string; }[] = [];
  currentStepProgressIndex: number;

  ngOnInit() {
    if (isNil(this.stepConfigs) || this.stepConfigs.length === 0) {
      throw new Error('Step configs expected');
    }

    this.progress = this.stepConfigs.map(s => { return { id: s.id, title: s.title } });
  }

  ngAfterViewInit(): void {
    if (this.buttonsTmpRef) {
      this.commandsTmpRef.emit(this.buttonsTmpRef);
    }

    const firstStep = this.getStep(this.stepConfigs[0].id);
    Promise.resolve().then(() => { this.attachStep(firstStep); });
  }

  ngOnDestroy(): void {
    this.steps.forEach(c => c.destroy());
    this.steps.splice(0, this.steps.length);
  }

  next() {
    const nextStep = this.getNextStep(this.currentStep);

    // check if there is a previously created next step
    if (this.currentStepIndex < this.steps.length - 1) {
      const existingNextStep = this.steps[this.currentStepIndex + 1];
      // compare the expected next step with the existing next step and if there is a difference
      // wipe out everything on the right
      if (isNil(nextStep) ||
        nextStep.config.id !== existingNextStep.config.id ||
        nextStep.repeatIndex !== existingNextStep.repeatIndex ||
        !isEqual(nextStep.inParams, existingNextStep.inParams)) {
        const deletedSteps = this.steps.splice(this.currentStepIndex + 1);
        if (deletedSteps) {
          deletedSteps.forEach(c => c.destroy());
        }
        this.setCurrentResult();
      } else {
        // if everything but the repeat count was the same, update it
        if (nextStep.repeatCount !== existingNextStep.repeatCount) {
          existingNextStep.repeatCount = nextStep.repeatCount;
        }
        this.reattachStep(existingNextStep);
        return;
      }
    }

    if (isNil(nextStep)) {
      this.finish.emit();
      return;
    }

    this.attachStep(nextStep);
  }

  back() {
    this.reattachStep(this.steps[this.currentStepIndex - 1]);
  }

  cancel() {
    this.close.emit();
  }

  getNextButtonLabel() {
    return this.currentStep.config.nextButtonLabel ?? 'Next';
  }

  isNextButtonDisabled() {
    if (this.currentStep.config.nextButtonDisabledConditionFunc) {
      return this.currentStep.config.nextButtonDisabledConditionFunc(this.currentStep.repeatIndex);
    }

    return false;
  }

  private getNextStepId(stepConfig: StepConfig, repeatIndex?: number): string {
    let nextStepId = null;
    if (stepConfig.nextConditionFunc) {
      const condResult = stepConfig.nextConditionFunc(repeatIndex);
      if (condResult) {
        nextStepId = stepConfig.next;
      } else {
        nextStepId = stepConfig.nextAlt;
      }
    } else {
      nextStepId = stepConfig.next;
    }

    return nextStepId;
  }

  private getStep(id: string): Step {
    if (isNil(id)) {
      return null;
    }

    let stepConfig = this.stepConfigs.find(s => s.id === id);
    if (stepConfig.repeatOverFunc) {
      const repeatCount = stepConfig.repeatOverFunc();
      if (!isNil(repeatCount) && repeatCount > 0) {
        return new Step(stepConfig, 0, repeatCount);
      } else {
        return this.getStep(this.getNextStepId(stepConfig));
      }
    }

    return new Step(stepConfig);
  }

  private getNextStep(step: Step): Step {
    // if the next step is repeatable and the index has not reached the limit, continue with the next iteration
    if (step.isRepeatable && step.repeatIndex + 1 < step.repeatCount) {
      return new Step(step.config, step.repeatIndex + 1, step.repeatCount);
    }

    const nextStepId = this.getNextStepId(step.config, step.repeatIndex);
    return this.getStep(nextStepId);
  }

  private setCurrentResult() {
    const result = {};
    this.steps.forEach(s => {
      if (s.isRepeatable) {
        if (s.repeatIndex === 0) {
          result[s.config.id] = {
            outParams: []
          };
        }
        result[s.config.id].outParams.push(s.outParams);
      } else {
        result[s.config.id] = {
          outParams: null
        };
        result[s.config.id].outParams = s.outParams;
      }
    })

    this.stepsResultFunc(result);
  }

  private setCurrentStep(step: Step) {
    this.currentStep = step;
    this.currentStepIndex = this.steps.findIndex(s => s === this.currentStep);
    this.currentStepProgressIndex = this.progress.findIndex(p => p.id === this.currentStep.config.id);
  }

  private reattachStep(step: Step) {
    if (isNil(step.componentRef)) {
      throw new Error('Step must have been attached first, in order to be reattached');
    }
    this.hostRef.detach();
    this.hostRef.insert(step.componentRef.hostView);
    this.setCurrentStep(step);
  }

  private attachStep(step: Step) {
    if (!isNil(step.componentRef)) {
      throw new Error('Step has already been attached');
    }
    this.hostRef.detach();

    const componentType = step.config.component;
    let componentRef = this.hostRef.createComponent(componentType);
    step.componentRef = componentRef;

    // NOTE: because we are dynamically creating components
    // and setting the inputs programmatically, 
    // ngOnChanges of the component will not be called
    // if we wishes to do so, we can either have the component
    // inputs call detectchanges or call componentRef.hostView.detectChanges here
    // but probably don't need for this use case

    // set component inputs
    if (!isNil(step.inParams)) {
      Object.keys(step.inParams).forEach(k => {
        componentRef.instance['$inParams_' + k] = step.inParams[k];
      });
    }

    if (componentRef.instance['outParamsChange']) {
      (componentRef.instance['outParamsChange'] as EventEmitter<any>)
        .pipe(
          // Just an easy way to unsub from subscription
          takeUntil(step.destroyed$)
        )
        .subscribe(data => {
          step.outParams = data;
          this.setCurrentResult();
        })
    }

    this.steps.push(step);
    this.setCurrentStep(step);
    this.setCurrentResult();
  }
}
