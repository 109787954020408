<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Expected</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-material" *ngIf="!fields.material.hidden" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                              title="{{fields.material.label}}{{fields.material.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material.label}}">{{fields.material.label}}<span *ngIf="fields.material.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="material"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.material.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.material.control.placeholder}}"
                                [ngStyle]="fields.material.control.styles.style"
                                [ngClass]="fields.material.control.styles.classes"> 
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendorlot" *ngIf="!fields.vendorlot.hidden" 
                            class="field-container standard {{fields.vendorlot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vendorlot.styles.style"
                            [ngClass]="fields.vendorlot.styles.classes">
                        <div class="label-container"
                              title="{{fields.vendorlot.label}}{{fields.vendorlot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.vendorlot.label}}">{{fields.vendorlot.label}}<span *ngIf="fields.vendorlot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="vendorlot"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.vendorlot.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendorlot.control.placeholder}}"
                                [ngStyle]="fields.vendorlot.control.styles.style"
                                [ngClass]="fields.vendorlot.control.styles.classes"> 
                        <ng-container *ngIf="fields.vendorlot.invalid">
                          <ng-container *ngFor="let error of fields.vendorlot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                              title="{{fields.lot.label}}{{fields.lot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lot.label}}">{{fields.lot.label}}<span *ngIf="fields.lot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lot"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lot.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lot.control.placeholder}}"
                                [ngStyle]="fields.lot.control.styles.style"
                                [ngClass]="fields.lot.control.styles.classes"> 
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-location" *ngIf="!fields.location.hidden" 
                            class="field-container standard {{fields.location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.location.styles.style"
                            [ngClass]="fields.location.styles.classes">
                        <div class="label-container"
                              title="{{fields.location.label}}{{fields.location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.location.label}}">{{fields.location.label}}<span *ngIf="fields.location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="location"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.location.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.location.control.placeholder}}"
                                [ngStyle]="fields.location.control.styles.style"
                                [ngClass]="fields.location.control.styles.classes"> 
                        <ng-container *ngIf="fields.location.invalid">
                          <ng-container *ngFor="let error of fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-licenseplate" *ngIf="!fields.licenseplate.hidden" 
                            class="field-container standard {{fields.licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.licenseplate.styles.style"
                            [ngClass]="fields.licenseplate.styles.classes">
                        <div class="label-container"
                              title="{{fields.licenseplate.label}}{{fields.licenseplate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.licenseplate.label}}">{{fields.licenseplate.label}}<span *ngIf="fields.licenseplate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="licenseplate"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.licenseplate.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.licenseplate.control.placeholder}}"
                                [ngStyle]="fields.licenseplate.control.styles.style"
                                [ngClass]="fields.licenseplate.control.styles.classes"> 
                        <ng-container *ngIf="fields.licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                              title="{{fields.packaging.label}}{{fields.packaging.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.packaging.label}}">{{fields.packaging.label}}<span *ngIf="fields.packaging.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="packaging"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.packaging.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.packaging.control.placeholder}}"
                                [ngStyle]="fields.packaging.control.styles.style"
                                [ngClass]="fields.packaging.control.styles.classes"> 
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_packaged_amount" *ngIf="!fields.expected_packaged_amount.hidden" 
                            class="field-container standard {{fields.expected_packaged_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.expected_packaged_amount.styles.style"
                            [ngClass]="fields.expected_packaged_amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.expected_packaged_amount.label}}{{fields.expected_packaged_amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.expected_packaged_amount.label}}">{{fields.expected_packaged_amount.label}}<span *ngIf="fields.expected_packaged_amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="expected_packaged_amount"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.expected_packaged_amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.expected_packaged_amount.control.placeholder}}"
                                [ngStyle]="fields.expected_packaged_amount.control.styles.style"
                                [ngClass]="fields.expected_packaged_amount.control.styles.classes"> 
                        <ng-container *ngIf="fields.expected_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.expected_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual_amount" *ngIf="!fields.actual_amount.hidden" 
                            class="field-container standard {{fields.actual_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.actual_amount.styles.style"
                            [ngClass]="fields.actual_amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.actual_amount.label}}{{fields.actual_amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.actual_amount.label}}">{{fields.actual_amount.label}}<span *ngIf="fields.actual_amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="actual_amount"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.actual_amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.actual_amount.control.placeholder}}"
                                [ngStyle]="fields.actual_amount.control.styles.style"
                                [ngClass]="fields.actual_amount.control.styles.classes"> 
                        <ng-container *ngIf="fields.actual_amount.invalid">
                          <ng-container *ngFor="let error of fields.actual_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.count_tasks.hidden" class="tab" data-cy="tab-count_tasks">
              <h2 [className]="tabs.count_tasks.active? 'active': ''" (click)="tabs.count_tasks.activate()">{{tabs.count_tasks.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-count_tasks_list *ngIf="tabs.count_tasks.active"
              #$tabs_count_tasks
              [countTaskId]="$tabs_count_tasks_count_tasks_list_inParams_countTaskId"
              ($refreshEvent)="refresh(false, false, '$tabs_count_tasks')">
              </FootPrintManager-count_tasks_list>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>