import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_find_vendorlotsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { searchProperties: { vendorLotId?: number, vendorLotCode?: string, materialId?: number }[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, MaterialId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.searchProperties)) {
      missingRequiredInParams.push('\'searchProperties\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_find_vendorlots/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { searchProperties: { vendorLotId?: number, vendorLotCode?: string, materialId?: number }[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, MaterialId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.searchProperties)) {
      missingRequiredInParams.push('\'searchProperties\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_find_vendorlots/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { searchProperties: { vendorLotId?: number, vendorLotCode?: string, materialId?: number }[], $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, MaterialId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.searchProperties)) {
      missingRequiredInParams.push('\'searchProperties\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_find_vendorlots/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
