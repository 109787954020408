import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_ds_activity_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { project_ids?: number[], owner_ids?: number[], warehouse_ids?: number[], operation_codes?: number[], status_ids?: number[], from_date?: string, to_date?: string, date_type?: string, fullTextSearch?: string, ids?: number[], location_id?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { LookupCode?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_activity_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { project_ids?: number[], owner_ids?: number[], warehouse_ids?: number[], operation_codes?: number[], status_ids?: number[], from_date?: string, to_date?: string, date_type?: string, fullTextSearch?: string, ids?: number[], location_id?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { LookupCode?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_activity_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { project_ids?: number[], owner_ids?: number[], warehouse_ids?: number[], operation_codes?: number[], status_ids?: number[], from_date?: string, to_date?: string, date_type?: string, fullTextSearch?: string, ids?: number[], location_id?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_activity_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
