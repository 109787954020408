import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_ds_serialnumbers_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, packagingId?: number, orderId?: number, orderLineNumber?: number, ids?: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, Width?: number, Lot?: { Id?: number, LookupCode?: string, Material?: { Id?: number, Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string }, MaterialGroup?: { Name?: string } }, VendorLot?: { LookupCode?: string } }, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string, Warehouse?: { Name?: string } }, Type?: { Id?: number, Name?: string } }, Status?: { Name?: string }, DimensionsUom?: { Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Short_name?: string }, Packaging?: { ShortName?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumbers_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, packagingId?: number, orderId?: number, orderLineNumber?: number, ids?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, Width?: number, Lot?: { Id?: number, LookupCode?: string, Material?: { Id?: number, Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string }, MaterialGroup?: { Name?: string } }, VendorLot?: { LookupCode?: string } }, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string, Warehouse?: { Name?: string } }, Type?: { Id?: number, Name?: string } }, Status?: { Name?: string }, DimensionsUom?: { Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Short_name?: string }, Packaging?: { ShortName?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumbers_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, packagingId?: number, orderId?: number, orderLineNumber?: number, ids?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, Width?: number, Lot?: { Id?: number, LookupCode?: string, Material?: { Id?: number, Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string }, MaterialGroup?: { Name?: string } }, VendorLot?: { LookupCode?: string } }, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string, Warehouse?: { Name?: string } }, Type?: { Id?: number, Name?: string } }, Status?: { Name?: string }, DimensionsUom?: { Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Short_name?: string }, Packaging?: { ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumbers_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
