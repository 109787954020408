import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintApiManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintApiManager.shell.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintApiManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintApiManager.frontend.types' 

import { FootPrintApiManager_statuses_dd_multiComponent } from './FootPrintApiManager.statuses_dd_multi.component'


interface IFootPrintApiManager_lifecycle_gridComponentEntity {
id?: string, ingress_type?: string, ingress_id?: string, transaction_id?: string, translation_status?: string, import_status?: string, order_status?: string, reference?: string, export_status?: string, send_status?: string, egress_type?: string, egress_id?: string, date_time?: string, sender?: string, receiver?: string, environment?: string, integration_name?: string}

interface IFootPrintApiManager_lifecycle_gridComponentInParams {
  integration_name?: string}


class FootPrintApiManager_lifecycle_gridComponentRowModel extends GridRowModel {
  grid: FootPrintApiManager_lifecycle_gridComponent;
  entity: IFootPrintApiManager_lifecycle_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    integration_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    environment: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    sender: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    receiver: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ingress_type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    translation_status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    import_status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    order_status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    reference: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    export_status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    send_status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    egress_type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    date_time: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    transaction_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintApiManager_ShellService,
private datasources: FootPrintApiManager_DatasourceService,
private flows: FootPrintApiManager_FlowService,
private reports: FootPrintApiManager_ReportService,
private localization: FootPrintApiManager_LocalizationService,
private operations: FootPrintApiManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintApiManager_lifecycle_gridComponent, entity: IFootPrintApiManager_lifecycle_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintApiManager_lifecycle_gridComponent, entity?: IFootPrintApiManager_lifecycle_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      environment:  null ,
      sender:  null ,
      receiver:  null ,
      ingress_id:  null ,
      egress_id:  null ,
      transaction_id:  null ,
      reference:  $grid.fullTextSearch ,
      integration_name:  $grid.inParams.integration_name ,
      statuses:  $grid.filters.statuses.control.value ,
    };
    const data = await this.datasources.FootPrintApiManager.ds_storage_lifecycle.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.id;
    (this.cells.integration_name.displayControl as TextModel).text = $row.entity.integration_name;
    (this.cells.environment.displayControl as TextModel).text = $row.entity.environment;
    (this.cells.sender.displayControl as TextModel).text = $row.entity.sender;
    (this.cells.receiver.displayControl as TextModel).text = $row.entity.receiver;
    (this.cells.ingress_type.displayControl as TextModel).text = $row.entity.ingress_type;
    (this.cells.translation_status.displayControl as TextModel).text = $row.entity.translation_status;
    (this.cells.import_status.displayControl as TextModel).text = $row.entity.import_status;
    (this.cells.order_status.displayControl as TextModel).text = $row.entity.order_status;
    (this.cells.reference.displayControl as TextModel).text = $row.entity.reference;
    (this.cells.export_status.displayControl as TextModel).text = $row.entity.export_status;
    (this.cells.send_status.displayControl as TextModel).text = $row.entity.send_status;
    (this.cells.egress_type.displayControl as TextModel).text = $row.entity.egress_type;
    (this.cells.transaction_id.displayControl as TextModel).text = $row.entity.transaction_id;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'reference' : {
        this.on_reference_clicked();
        break;
      }
      case 'transaction_id' : {
        this.on_transaction_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintApiManager_lifecycle_gridComponentRowModel,
  $grid: FootPrintApiManager_lifecycle_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 12/06/2023
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.date_time)) {
          $row.cells.date_time.displayControl.text = $utils.date.format($row.entity.date_time, format);
      };
  };
  }
  on_transaction_clicked(event = null) {
    return this.on_transaction_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_transaction_clickedInternal(
    $row: FootPrintApiManager_lifecycle_gridComponentRowModel,
  $grid: FootPrintApiManager_lifecycle_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/18/2024
  
  let transaction_id: string = $row.entity.transaction_id;
  
  if ($utils.isDefined(transaction_id)) {
      if (transaction_id !== "") {
          $shell.FootPrintApiManager.opentransaction_hubDialog({transaction_id: transaction_id}, "modal", EModalSize.Xlarge)
      }
  }
  }
  on_reference_clicked(event = null) {
    return this.on_reference_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_reference_clickedInternal(
    $row: FootPrintApiManager_lifecycle_gridComponentRowModel,
  $grid: FootPrintApiManager_lifecycle_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  let reference = $row.entity.reference;
  let transaction_id = $row.entity.transaction_id;
  
  if ($utils.isDefined(reference)) {
  
      if ($utils.isDefined(transaction_id)) {
  
          let order_info = (await $datasources.FootPrintApiManager.ds_find_order_by_transaction_id.get({
              transaction_id: transaction_id
          })).result;
  
          if ($utils.isDefined(order_info)) {
  
              let order_class_type_id = order_info.OrderClass.OrderClassTypeId;
              let order_type_id = order_info.OrderClass.OrderTypeId;
              let order_id = order_info.Id;
  
              if (order_type_id === 2) {
                  $shell.FootPrintApiManager.opensales_order_editor({ orderId: order_id })
              } else {
                  if (order_class_type_id === 12) {
                      $shell.FootPrintApiManager.openasn_order_editor({ order_id: order_id })
                  } else {
                      $shell.FootPrintApiManager.openpurchase_order_editor({ orderId: order_id })
                  }
              }
          } else {
  
              await $shell.FootPrintApiManager.openInfoDialog("Info", `An order could not be found for transaction id ${transaction_id}.`)
          }
      } else {
  
          await $shell.FootPrintApiManager.openInfoDialog("Info", "The record does not have a valid transaction id to execute an order search.")
      }
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintApiManager_statuses_dd_multiComponent),
  ],
  selector: 'FootPrintApiManager-lifecycle_grid',
  templateUrl: './FootPrintApiManager.lifecycle_grid.component.html'
})
export class FootPrintApiManager_lifecycle_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintApiManager_lifecycle_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintApiManager_lifecycle_gridComponentInParams = { integration_name: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'ID', false, false, null),       integration_name: new GridHeaderModel(new HeaderStyles(null, null), 'Integration name', false, false, null),       environment: new GridHeaderModel(new HeaderStyles(null, null), 'Environment', false, false, null),       sender: new GridHeaderModel(new HeaderStyles(null, null), 'Sender', false, false, null),       receiver: new GridHeaderModel(new HeaderStyles(null, null), 'Receiver', false, false, null),       ingress_type: new GridHeaderModel(new HeaderStyles(null, null), 'Ingress type', false, false, null),       translation_status: new GridHeaderModel(new HeaderStyles(null, null), 'Translation status', false, false, null),       import_status: new GridHeaderModel(new HeaderStyles(null, null), 'Import status', false, false, null),       order_status: new GridHeaderModel(new HeaderStyles(null, null), 'Order status', false, false, null),       reference: new GridHeaderModel(new HeaderStyles(null, null), 'Reference', false, false, null),       export_status: new GridHeaderModel(new HeaderStyles(null, null), 'Export status', false, false, null),       send_status: new GridHeaderModel(new HeaderStyles(null, null), 'Send status', false, false, null),       egress_type: new GridHeaderModel(new HeaderStyles(null, null), 'Egress type', false, false, null),       date_time: new GridHeaderModel(new HeaderStyles(null, null), 'Date time', false, false, null),       transaction_id: new GridHeaderModel(new HeaderStyles(null, null), 'Transaction ID', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintApiManager_lifecycle_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('integration_name') set $inParams_integration_name(value: any) {
    this.inParams['integration_name'] = value;
  }
  get $inParams_integration_name(): any {
    return this.inParams['integration_name'] ;
  }

  topToolbar = {
      delete_record: new ToolModel(new ButtonModel('delete_record', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    statuses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    statuses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['statuses'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Statuses', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintApiManager_ShellService,
    private datasources: FootPrintApiManager_DatasourceService,
    private flows: FootPrintApiManager_FlowService,
    private reports: FootPrintApiManager_ReportService,
    private localization: FootPrintApiManager_LocalizationService,
    private operations: FootPrintApiManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Lifecycle grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.statuses.control as SelectBoxModel).reset(["Completed","Processing","Created","Submitted","Translated","Exported","Pending","Ready"]);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      environment:  null ,
      sender:  null ,
      receiver:  null ,
      ingress_id:  null ,
      egress_id:  null ,
      transaction_id:  null ,
      reference:  $grid.fullTextSearch ,
      integration_name:  $grid.inParams.integration_name ,
      statuses:  $grid.filters.statuses.control.value ,
    };
    try {
    const data = await this.datasources.FootPrintApiManager.ds_storage_lifecycle.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintApiManager_lifecycle_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintApiManager_lifecycle_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/22/2024
  
  $grid.title = 'FootPrint API Manager Lifecycle Grid'
  $grid.headers.id.hidden = true;
  
  let integration_name: string = $grid.inParams.integration_name ?? "";
  
  if (integration_name.toUpperCase() === "NULL") {
      integration_name = "";
  };
  
  if (integration_name !== "") {
      $grid.headers.integration_name.hidden = true;
  };
  
  $grid.headers.id.hidden = true;
  
  //Set the default allowed statuses
  let ds_storage_statuses = (await $datasources.FootPrintApiManager.ds_storage_statuses.getList({ $top: 1000 })).result;
  
  if ($utils.isDefined(ds_storage_statuses)) {
  
      let default_statuses = ["Completed", "Processing", "Created", "Submitted", "Translated", "Exported", "Pending", "Ready"];
      let storage_statuses = ds_storage_statuses.map(s => s.status)
      let current_statuses = storage_statuses.filter(s => default_statuses.indexOf(s) >= 0)
  
      if ($utils.isDefined(current_statuses)) {
          $grid.filters.statuses.control.value = current_statuses;
      }
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintApiManager_lifecycle_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/13/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { reference: string, id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.FootPrintApiManager.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.FootPrintApiManager.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  await lifecycle_delete({
                      "id": entity.id
                  });
  
                  await log_add({
                      "process": "lifecycle_grid",
                      "level": 1,
                      "transaction_id": row.entity.transaction_id,
                      "ingress_id": row.entity.ingress_id,
                      "egress_id": row.entity.egress_id,
                      "integration_name": row.entity.integration_name,
                      "notes": `Deleted record! Reference: [${row.entity.reference}] Transaction Id: [${row.entity.transaction_id}]`
                  });
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  deleted_records.push(row.entity.id.toString())
              } else {
                  errors.push({
                      reference: row.entity.reference, id: row.entity.id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
              await $shell.FootPrintApiManager.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Reference: ${error.reference}` })))
          };
      };
  };
  
  $grid.refresh();
  
  const log_schema = (await $flows.FootPrintApiManager.storage_logs({ action: "", integration_name: "" })).payload;
  async function log_add(input: typeof log_schema[0]) {
      let integration_name: string = input.integration_name ?? "";
      await $flows.FootPrintApiManager.storage_logs({ action: "Write", payload: input, integration_name: integration_name });
  };
  
  const lifecycle_schema = (await $flows.FootPrintApiManager.storage_lifecycle({ action: "", integration_name: "" })).payload;
  async function lifecycle_delete(input: typeof lifecycle_schema[0]) {
      let integration_name: string = input.integration_name ?? "";
      await $flows.FootPrintApiManager.storage_lifecycle({ action: "Delete", payload: input, integration_name: integration_name });
  };
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintApiManager_lifecycle_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/19/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_record.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
