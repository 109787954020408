import { TranslateService } from '@ngx-translate/core';

export abstract class LocalizationService<T extends { [K in keyof T]: Language }> {
  public languages: T;

  public get selectedLanguage(): Language {
    return this._selectedLanguage;
  }

  public abstract get settings(): LocalizationSettings;

  private readonly _languages: Language[] = [];
  private _selectedLanguage: Language;

  constructor(
    private translate: TranslateService
  ) { }

  public initialize(): void {
    this.translate.addLangs(this.settings.languages.map(l => l.code));
    this._languages.push(...this.settings.languages);
    this.languages = Object.fromEntries(this._languages?.map(i => [i.code, i]) ?? []) as T
    this._selectedLanguage = this.findLang(this.settings.default);
    this.translate.onLangChange
      .subscribe(lang => {
        this._selectedLanguage = this.findLang(lang.lang);
      });
  }

  public setLanguage(lang: Language): void {
    if (!lang?.code) {
      return;
    }

    const languageFound = this.findLang(lang.code)
      ?? this.findLang(this.settings.default)
      ?? this._languages[0];

    if (languageFound) {
      this.translate.use(languageFound.code);
    }
  }

  public getBrowserLangOrDefault(): Language {
    return this.findLang(this.translate.getBrowserLang())
      ?? this.findLang(this.settings.default);
  }

  protected getValue(key: string, param?: any): string {
    return this.translate.instant(key, param);
  }

  private findLang(code: string): Language {
    return this._languages.find(l => l.code === code);
  }
}

export class LocalizationSettings {
  public languages: Language[];
  public default: string;
}

export class Language {
  name: string;
  code: string;
}