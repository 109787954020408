import { Utilities_Types } from './Utilities.frontend.types'

export namespace Attachments_Types {
  /**
   * Represents supported attachment entities
   */
  export enum Entity {
    BillingContract = 'BillingContract',
    DockAppointment = 'DockAppointment',
    Invoice = 'Invoice',
    LicensePlate = 'LicensePlate',
    LoadContainer = 'LoadContainer',
    Lot = 'Lot',
    ManufacturingOrder = 'ManufacturingOrder',
    Material = 'Material',
    MaterialsPackagingsLookup = 'MaterialsPackagingsLookup',
    Order = 'Order',
    Owner = 'Owner',
    ScaleTicket = 'ScaleTicket',
    SerialNumber = 'SerialNumber',
    Shipment = 'Shipment',
    ShippingContainer = 'ShippingContainer',
    WarehouseTransfer = 'WarehouseTransfer',
    WorkOrder = 'WorkOrder'
  }
}

export namespace $frontendTypes {
  export import Utilities =  Utilities_Types;
  export import Attachments = Attachments_Types;
}




