import { Injectable } from '@angular/core';
import {
  FirstFileOpenOptions,
  FirstFileSaveOptions,
  fileOpen,
  fileSave,
  fileSaveLegacy,
} from 'browser-fs-access';
import { isEmpty, isNil, uniq } from 'lodash-es';

// since fileSaveLegacy is not included into the 'browser-fs-access' TS decalarations (d.ts file)
// but does exist in the package dist (see https://github.com/GoogleChromeLabs/browser-fs-access/blob/main/src/index.js)
// we patch the module declaration
declare module "browser-fs-access" {
  function fileSaveLegacy(
    blobOrPromiseBlobOrResponse: Blob | Promise<Blob> | Response,
    options?: [FirstFileSaveOptions, ...CoreFileOptions[]] | FirstFileSaveOptions
  ): Promise<FileSystemFileHandle | null>;
}

@Injectable({ providedIn: 'root' })
export class BlobService {
  // NOTE: taken from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#supported_image_formats
  browserSupportedImgFormats = {
    ".apng": "image/apng",
    ".avif": "image/avif",
    ".gif": "image/gif",
    ".jpe": "image/jpeg",
    ".jpeg": "image/jpeg",
    ".jpg": "image/jpeg",
    ".png": "image/png",
    ".svg": "image/svg+xml",
    ".webp": "image/webp"
  };

  browserSupportedImgExtensions = Object.keys(this.browserSupportedImgFormats);
  browserSupportedImgMIMETypes = uniq(Object.values(this.browserSupportedImgFormats));

  dataURLRegex = /data:([-\w]+\/[-+\w.]+)?(;?\w+=[-\w]+)*(;base64)?,.*/;

  constructor() {
  }

  public isBlob(value: any): value is Blob {
    return typeof Blob !== 'undefined' && value instanceof Blob;
  }

  public isFile(value: any): value is File {
    return typeof File !== 'undefined' && value instanceof File;
  }

  public toBase64(blob: Blob, asDataURL = false): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        if (reader.error) {
          reject(reader.error);
        }
        const dataURL = reader.result as string;
        const result = asDataURL ? dataURL : dataURL.split(',')[1];
        resolve(result);
      };
      reader.readAsDataURL(blob);
    });
  }

  public fromBase64(base64DataUrl: string): Promise<Blob> {
    if (!this.isValidDataUrl(base64DataUrl)) {
      return null;
    }
    return fetch(base64DataUrl).then(t => t.blob());
  }

  public isValidDataUrl(dataUrl: string): boolean {
    if (isEmpty(dataUrl)) {
      return false;
    }
    return this.dataURLRegex.test(dataUrl);
  }

  public openFile<M extends boolean | undefined = false>(options?: FirstFileOpenOptions<M>) {
    // Use a ponyfill until native showOpenFilePicker has larger browser support:
    // https://github.com/WICG/file-system-access#workarounds
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/showOpenFilePicker#browser_compatibility
    // https://github.com/GoogleChromeLabs/browser-fs-access

    return fileOpen(options);
  }

  public saveFile(blob: Blob | Promise<Blob>, options?: FirstFileSaveOptions) {
    // Use a ponyfill until native showSaveFilePicker has larger browser support:
    // https://github.com/WICG/file-system-access#workarounds
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/showSaveFilePicker#browser_compatibility
    // https://github.com/GoogleChromeLabs/browser-fs-access

    // if blob is a file, try and set the fileName if it hasn't been set already
    if (this.isFile(blob)) {
      if (isNil(options)) {
        options = { fileName: blob.name };
      } else {
        options.fileName = isNil(options.fileName) ? blob.name : options.fileName;
      }
    }

    // Sometimes processing the to-be-saved data takes some time after the user clicks the button that opens the 
    // save file picker in an app. A common gotcha is doing this work before the showSaveFilePicker() code has 
    // run, which might result in a SecurityError Failed to execute 'showSaveFilePicker' on 'Window': 
    // Must be handling a user gesture to show a file picker. 
    // Instead, try opening the save file picker, before processing the data. A Promise can be passed as a first
    // parameter like so: saveFile(fetchAttachment(...).then(t => t.attachment), {...});
    // See https://bugs.chromium.org/p/chromium/issues/detail?id=1193489, 
    // https://github.com/excalidraw/excalidraw/issues/5100#issuecomment-1112255629 and
    // https://googlechromelabs.github.io/browser-fs-access/demo/ for more information
    // Since it won't be nice to force users of the current function to pass in a promise as a workaround for the
    // given error, we are forcing the usage of 'fileSaveLegacy' (which mimics a user click on an <a> element).
    // We were using 'fileSave' (which prefers the 'fileSaveModern', if 'showSaveFilePicker' is supported).
    // Hopefully when 'showSaveFilePicker' gets larger support the user gesture issue would be resolved and
    // we can switch to using it and remove the current ponyfill

    return fileSaveLegacy(blob, options);
  }

  // NOTE: rough copy of https://stackoverflow.com/a/14919494
  public humanSize(bytes: number, si: boolean = true, decimalPoints: number = 2) {
    if (isNil(bytes)) {
      return bytes;
    }
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** decimalPoints;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return `${bytes.toFixed(decimalPoints)} ${units[u]}`;
  }

  public isBrowserSupportedImgFormat(extensionOrMIMEType: string) {
    if (isNil(extensionOrMIMEType)) {
      return false;
    }

    const extensionOrMIMETypeToLower = extensionOrMIMEType.toLocaleLowerCase();
    return this.browserSupportedImgExtensions.some(f => extensionOrMIMETypeToLower.includes(f)) ||
      this.browserSupportedImgMIMETypes.some(f => extensionOrMIMETypeToLower.includes(f))
  }

  public processBlob(
    blob: Blob,
    options: {
      type: string,
      size: number;
      fileName?: string,
      lastModified?: number,
    }): Blob {

    if (isNil(options.fileName)) {
      return blob;
    } else {
      const fileOptions: FilePropertyBag = { type: options.type };
      if (!isNil(options.lastModified)) {
        fileOptions.lastModified = new Date(options.lastModified).getTime();
      }
      return new File([blob], options.fileName, fileOptions);
    }
  }
}

