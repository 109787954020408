import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { WarehouseTransfers_ds_find_putaway_task_by_licenseplateIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_find_tasksService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_available_inventory_by_lotId_packagedId_locationIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_hardallocationdetails_by_hardallocationIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_hardallocations_by_taskIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_inbound_order_receiving_taskService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_licenseplate_by_licenseplateIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_licenseplate_by_lookupcode_warehouseId_shipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_licenseplate_by_loose_locationIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_licenseplates_by_locationId_lotId_packagingIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_licenseplates_by_shipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_licenseplates_by_shipmentId_or_idsService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_loading_tasks_by_licenseplateId_shipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_location_by_locationIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_lot_by_lotIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_material_by_materialIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_material_packaging_by_materialId_and_packagingIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_order_account_address_by_orderIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_order_address_by_orderId_top1Service } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_order_by_orderIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_order_classes_by_orderClassIdsService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_order_owner_contact_top1Service } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_order_ship_to_account_vs_order_addressService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_orderline_total_picked_units_by_orderId_linenumberService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_orderlines_by_contentService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_orderlines_by_orderIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_orderlines_by_orderId_bottom1Service } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_orderlines_by_orderId_linenumberService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_outbound_shipment_licenseplatecontentsService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_picking_tasks_by_orderId_shipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_project_by_projectIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_putaway_task_by_licenseplateIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_serialnumber_by_serialnumberIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_serialnumbers_by_outboundShipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_shipment_by_shipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_shipmentline_by_shipmentlineIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_shipmentlines_by_orderId_bottom1Service } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_shipmentlines_by_orderlineService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_target_inbound_order_receiving_tasksService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_tasks_by_chainHeadService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_tasks_by_orderId_shipmentIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_tasks_by_serialnumberIdsService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_warehouse_transfer_by_lookupcodeService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_warehouse_transfer_by_warehouseTransferIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_warehouses_by_warehouseIdsService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_get_wave_by_waveIdService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_orderline_total_amount_widgetService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_orderline_total_gross_received_widgetService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_orderline_total_received_widgetService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_outbound_detail_pick_slip_reportService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_outbound_details_bill_of_lading_reportService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_outbound_header_bill_of_lading_reportService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_outbound_header_pick_slip_reportService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_outbound_order_total_picked_gross_widgetService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_outbound_order_total_picked_units_widgetService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_outbound_order_total_units_widgetService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_editorService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_inbound_order_editorService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_inbound_order_licenseplates_gridService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_inbound_order_receiving_tasks_gridService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_inbound_orderlines_gridService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_outbound_order_editorService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_outbound_order_lines_gridService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_outbound_order_manual_allocations_gridService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_outbound_order_shippingcontents_gridService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfer_picking_gridService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehouse_transfers_gridService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehousetransfer_statuses_ddService } from './WarehouseTransfers.datasource.index';
import { WarehouseTransfers_ds_warehousetransfer_types_ddService } from './WarehouseTransfers.datasource.index';

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public WarehouseTransfers: WarehouseTransfers_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_find_putaway_task_by_licenseplateId: WarehouseTransfers_ds_find_putaway_task_by_licenseplateIdService;
  public get ds_find_putaway_task_by_licenseplateId(): WarehouseTransfers_ds_find_putaway_task_by_licenseplateIdService {
    if(!this._ds_find_putaway_task_by_licenseplateId) {
      this._ds_find_putaway_task_by_licenseplateId = this.injector.get(WarehouseTransfers_ds_find_putaway_task_by_licenseplateIdService);
    }
    return this._ds_find_putaway_task_by_licenseplateId;
  }
  private _ds_find_tasks: WarehouseTransfers_ds_find_tasksService;
  public get ds_find_tasks(): WarehouseTransfers_ds_find_tasksService {
    if(!this._ds_find_tasks) {
      this._ds_find_tasks = this.injector.get(WarehouseTransfers_ds_find_tasksService);
    }
    return this._ds_find_tasks;
  }
  private _ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId: WarehouseTransfers_ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseIdService;
  public get ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId(): WarehouseTransfers_ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId) {
      this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId = this.injector.get(WarehouseTransfers_ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId_warehouseId;
  }
  private _ds_get_available_inventory_by_lotId_packagedId_locationId: WarehouseTransfers_ds_get_available_inventory_by_lotId_packagedId_locationIdService;
  public get ds_get_available_inventory_by_lotId_packagedId_locationId(): WarehouseTransfers_ds_get_available_inventory_by_lotId_packagedId_locationIdService {
    if(!this._ds_get_available_inventory_by_lotId_packagedId_locationId) {
      this._ds_get_available_inventory_by_lotId_packagedId_locationId = this.injector.get(WarehouseTransfers_ds_get_available_inventory_by_lotId_packagedId_locationIdService);
    }
    return this._ds_get_available_inventory_by_lotId_packagedId_locationId;
  }
  private _ds_get_available_inventory_by_lotId_packagedId_warehouseId: WarehouseTransfers_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService;
  public get ds_get_available_inventory_by_lotId_packagedId_warehouseId(): WarehouseTransfers_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_lotId_packagedId_warehouseId) {
      this._ds_get_available_inventory_by_lotId_packagedId_warehouseId = this.injector.get(WarehouseTransfers_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_lotId_packagedId_warehouseId;
  }
  private _ds_get_available_inventory_by_materialId_packagedId_warehouseId: WarehouseTransfers_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService;
  public get ds_get_available_inventory_by_materialId_packagedId_warehouseId(): WarehouseTransfers_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_materialId_packagedId_warehouseId) {
      this._ds_get_available_inventory_by_materialId_packagedId_warehouseId = this.injector.get(WarehouseTransfers_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_materialId_packagedId_warehouseId;
  }
  private _ds_get_hardallocationdetails_by_hardallocationId: WarehouseTransfers_ds_get_hardallocationdetails_by_hardallocationIdService;
  public get ds_get_hardallocationdetails_by_hardallocationId(): WarehouseTransfers_ds_get_hardallocationdetails_by_hardallocationIdService {
    if(!this._ds_get_hardallocationdetails_by_hardallocationId) {
      this._ds_get_hardallocationdetails_by_hardallocationId = this.injector.get(WarehouseTransfers_ds_get_hardallocationdetails_by_hardallocationIdService);
    }
    return this._ds_get_hardallocationdetails_by_hardallocationId;
  }
  private _ds_get_hardallocations_by_taskId: WarehouseTransfers_ds_get_hardallocations_by_taskIdService;
  public get ds_get_hardallocations_by_taskId(): WarehouseTransfers_ds_get_hardallocations_by_taskIdService {
    if(!this._ds_get_hardallocations_by_taskId) {
      this._ds_get_hardallocations_by_taskId = this.injector.get(WarehouseTransfers_ds_get_hardallocations_by_taskIdService);
    }
    return this._ds_get_hardallocations_by_taskId;
  }
  private _ds_get_inbound_order_receiving_task: WarehouseTransfers_ds_get_inbound_order_receiving_taskService;
  public get ds_get_inbound_order_receiving_task(): WarehouseTransfers_ds_get_inbound_order_receiving_taskService {
    if(!this._ds_get_inbound_order_receiving_task) {
      this._ds_get_inbound_order_receiving_task = this.injector.get(WarehouseTransfers_ds_get_inbound_order_receiving_taskService);
    }
    return this._ds_get_inbound_order_receiving_task;
  }
  private _ds_get_licenseplate_by_licenseplateId: WarehouseTransfers_ds_get_licenseplate_by_licenseplateIdService;
  public get ds_get_licenseplate_by_licenseplateId(): WarehouseTransfers_ds_get_licenseplate_by_licenseplateIdService {
    if(!this._ds_get_licenseplate_by_licenseplateId) {
      this._ds_get_licenseplate_by_licenseplateId = this.injector.get(WarehouseTransfers_ds_get_licenseplate_by_licenseplateIdService);
    }
    return this._ds_get_licenseplate_by_licenseplateId;
  }
  private _ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId: WarehouseTransfers_ds_get_licenseplate_by_lookupcode_warehouseId_shipmentIdService;
  public get ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId(): WarehouseTransfers_ds_get_licenseplate_by_lookupcode_warehouseId_shipmentIdService {
    if(!this._ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId) {
      this._ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId = this.injector.get(WarehouseTransfers_ds_get_licenseplate_by_lookupcode_warehouseId_shipmentIdService);
    }
    return this._ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId;
  }
  private _ds_get_licenseplate_by_loose_locationId: WarehouseTransfers_ds_get_licenseplate_by_loose_locationIdService;
  public get ds_get_licenseplate_by_loose_locationId(): WarehouseTransfers_ds_get_licenseplate_by_loose_locationIdService {
    if(!this._ds_get_licenseplate_by_loose_locationId) {
      this._ds_get_licenseplate_by_loose_locationId = this.injector.get(WarehouseTransfers_ds_get_licenseplate_by_loose_locationIdService);
    }
    return this._ds_get_licenseplate_by_loose_locationId;
  }
  private _ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId: WarehouseTransfers_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService;
  public get ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId(): WarehouseTransfers_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService {
    if(!this._ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId) {
      this._ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId = this.injector.get(WarehouseTransfers_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService);
    }
    return this._ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId;
  }
  private _ds_get_licenseplates_by_locationId_lotId_packagingId: WarehouseTransfers_ds_get_licenseplates_by_locationId_lotId_packagingIdService;
  public get ds_get_licenseplates_by_locationId_lotId_packagingId(): WarehouseTransfers_ds_get_licenseplates_by_locationId_lotId_packagingIdService {
    if(!this._ds_get_licenseplates_by_locationId_lotId_packagingId) {
      this._ds_get_licenseplates_by_locationId_lotId_packagingId = this.injector.get(WarehouseTransfers_ds_get_licenseplates_by_locationId_lotId_packagingIdService);
    }
    return this._ds_get_licenseplates_by_locationId_lotId_packagingId;
  }
  private _ds_get_licenseplates_by_shipmentId: WarehouseTransfers_ds_get_licenseplates_by_shipmentIdService;
  public get ds_get_licenseplates_by_shipmentId(): WarehouseTransfers_ds_get_licenseplates_by_shipmentIdService {
    if(!this._ds_get_licenseplates_by_shipmentId) {
      this._ds_get_licenseplates_by_shipmentId = this.injector.get(WarehouseTransfers_ds_get_licenseplates_by_shipmentIdService);
    }
    return this._ds_get_licenseplates_by_shipmentId;
  }
  private _ds_get_licenseplates_by_shipmentId_or_ids: WarehouseTransfers_ds_get_licenseplates_by_shipmentId_or_idsService;
  public get ds_get_licenseplates_by_shipmentId_or_ids(): WarehouseTransfers_ds_get_licenseplates_by_shipmentId_or_idsService {
    if(!this._ds_get_licenseplates_by_shipmentId_or_ids) {
      this._ds_get_licenseplates_by_shipmentId_or_ids = this.injector.get(WarehouseTransfers_ds_get_licenseplates_by_shipmentId_or_idsService);
    }
    return this._ds_get_licenseplates_by_shipmentId_or_ids;
  }
  private _ds_get_loading_tasks_by_licenseplateId_shipmentId: WarehouseTransfers_ds_get_loading_tasks_by_licenseplateId_shipmentIdService;
  public get ds_get_loading_tasks_by_licenseplateId_shipmentId(): WarehouseTransfers_ds_get_loading_tasks_by_licenseplateId_shipmentIdService {
    if(!this._ds_get_loading_tasks_by_licenseplateId_shipmentId) {
      this._ds_get_loading_tasks_by_licenseplateId_shipmentId = this.injector.get(WarehouseTransfers_ds_get_loading_tasks_by_licenseplateId_shipmentIdService);
    }
    return this._ds_get_loading_tasks_by_licenseplateId_shipmentId;
  }
  private _ds_get_location_by_locationId: WarehouseTransfers_ds_get_location_by_locationIdService;
  public get ds_get_location_by_locationId(): WarehouseTransfers_ds_get_location_by_locationIdService {
    if(!this._ds_get_location_by_locationId) {
      this._ds_get_location_by_locationId = this.injector.get(WarehouseTransfers_ds_get_location_by_locationIdService);
    }
    return this._ds_get_location_by_locationId;
  }
  private _ds_get_lot_by_lotId: WarehouseTransfers_ds_get_lot_by_lotIdService;
  public get ds_get_lot_by_lotId(): WarehouseTransfers_ds_get_lot_by_lotIdService {
    if(!this._ds_get_lot_by_lotId) {
      this._ds_get_lot_by_lotId = this.injector.get(WarehouseTransfers_ds_get_lot_by_lotIdService);
    }
    return this._ds_get_lot_by_lotId;
  }
  private _ds_get_material_by_materialId: WarehouseTransfers_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): WarehouseTransfers_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(WarehouseTransfers_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId: WarehouseTransfers_ds_get_material_packaging_by_materialId_and_packagingIdService;
  public get ds_get_material_packaging_by_materialId_and_packagingId(): WarehouseTransfers_ds_get_material_packaging_by_materialId_and_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId) {
      this._ds_get_material_packaging_by_materialId_and_packagingId = this.injector.get(WarehouseTransfers_ds_get_material_packaging_by_materialId_and_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId;
  }
  private _ds_get_order_account_address_by_orderId: WarehouseTransfers_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): WarehouseTransfers_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(WarehouseTransfers_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_address_by_orderId_top1: WarehouseTransfers_ds_get_order_address_by_orderId_top1Service;
  public get ds_get_order_address_by_orderId_top1(): WarehouseTransfers_ds_get_order_address_by_orderId_top1Service {
    if(!this._ds_get_order_address_by_orderId_top1) {
      this._ds_get_order_address_by_orderId_top1 = this.injector.get(WarehouseTransfers_ds_get_order_address_by_orderId_top1Service);
    }
    return this._ds_get_order_address_by_orderId_top1;
  }
  private _ds_get_order_by_orderId: WarehouseTransfers_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): WarehouseTransfers_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(WarehouseTransfers_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_order_classes_by_orderClassIds: WarehouseTransfers_ds_get_order_classes_by_orderClassIdsService;
  public get ds_get_order_classes_by_orderClassIds(): WarehouseTransfers_ds_get_order_classes_by_orderClassIdsService {
    if(!this._ds_get_order_classes_by_orderClassIds) {
      this._ds_get_order_classes_by_orderClassIds = this.injector.get(WarehouseTransfers_ds_get_order_classes_by_orderClassIdsService);
    }
    return this._ds_get_order_classes_by_orderClassIds;
  }
  private _ds_get_order_owner_contact_top1: WarehouseTransfers_ds_get_order_owner_contact_top1Service;
  public get ds_get_order_owner_contact_top1(): WarehouseTransfers_ds_get_order_owner_contact_top1Service {
    if(!this._ds_get_order_owner_contact_top1) {
      this._ds_get_order_owner_contact_top1 = this.injector.get(WarehouseTransfers_ds_get_order_owner_contact_top1Service);
    }
    return this._ds_get_order_owner_contact_top1;
  }
  private _ds_get_order_ship_to_account_vs_order_address: WarehouseTransfers_ds_get_order_ship_to_account_vs_order_addressService;
  public get ds_get_order_ship_to_account_vs_order_address(): WarehouseTransfers_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._ds_get_order_ship_to_account_vs_order_address) {
      this._ds_get_order_ship_to_account_vs_order_address = this.injector.get(WarehouseTransfers_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_to_account_vs_order_address;
  }
  private _ds_get_orderline_total_picked_units_by_orderId_linenumber: WarehouseTransfers_ds_get_orderline_total_picked_units_by_orderId_linenumberService;
  public get ds_get_orderline_total_picked_units_by_orderId_linenumber(): WarehouseTransfers_ds_get_orderline_total_picked_units_by_orderId_linenumberService {
    if(!this._ds_get_orderline_total_picked_units_by_orderId_linenumber) {
      this._ds_get_orderline_total_picked_units_by_orderId_linenumber = this.injector.get(WarehouseTransfers_ds_get_orderline_total_picked_units_by_orderId_linenumberService);
    }
    return this._ds_get_orderline_total_picked_units_by_orderId_linenumber;
  }
  private _ds_get_orderlines_by_content: WarehouseTransfers_ds_get_orderlines_by_contentService;
  public get ds_get_orderlines_by_content(): WarehouseTransfers_ds_get_orderlines_by_contentService {
    if(!this._ds_get_orderlines_by_content) {
      this._ds_get_orderlines_by_content = this.injector.get(WarehouseTransfers_ds_get_orderlines_by_contentService);
    }
    return this._ds_get_orderlines_by_content;
  }
  private _ds_get_orderlines_by_orderId: WarehouseTransfers_ds_get_orderlines_by_orderIdService;
  public get ds_get_orderlines_by_orderId(): WarehouseTransfers_ds_get_orderlines_by_orderIdService {
    if(!this._ds_get_orderlines_by_orderId) {
      this._ds_get_orderlines_by_orderId = this.injector.get(WarehouseTransfers_ds_get_orderlines_by_orderIdService);
    }
    return this._ds_get_orderlines_by_orderId;
  }
  private _ds_get_orderlines_by_orderId_bottom1: WarehouseTransfers_ds_get_orderlines_by_orderId_bottom1Service;
  public get ds_get_orderlines_by_orderId_bottom1(): WarehouseTransfers_ds_get_orderlines_by_orderId_bottom1Service {
    if(!this._ds_get_orderlines_by_orderId_bottom1) {
      this._ds_get_orderlines_by_orderId_bottom1 = this.injector.get(WarehouseTransfers_ds_get_orderlines_by_orderId_bottom1Service);
    }
    return this._ds_get_orderlines_by_orderId_bottom1;
  }
  private _ds_get_orderlines_by_orderId_linenumber: WarehouseTransfers_ds_get_orderlines_by_orderId_linenumberService;
  public get ds_get_orderlines_by_orderId_linenumber(): WarehouseTransfers_ds_get_orderlines_by_orderId_linenumberService {
    if(!this._ds_get_orderlines_by_orderId_linenumber) {
      this._ds_get_orderlines_by_orderId_linenumber = this.injector.get(WarehouseTransfers_ds_get_orderlines_by_orderId_linenumberService);
    }
    return this._ds_get_orderlines_by_orderId_linenumber;
  }
  private _ds_get_outbound_shipment_licenseplatecontents: WarehouseTransfers_ds_get_outbound_shipment_licenseplatecontentsService;
  public get ds_get_outbound_shipment_licenseplatecontents(): WarehouseTransfers_ds_get_outbound_shipment_licenseplatecontentsService {
    if(!this._ds_get_outbound_shipment_licenseplatecontents) {
      this._ds_get_outbound_shipment_licenseplatecontents = this.injector.get(WarehouseTransfers_ds_get_outbound_shipment_licenseplatecontentsService);
    }
    return this._ds_get_outbound_shipment_licenseplatecontents;
  }
  private _ds_get_picking_tasks_by_orderId_shipmentId: WarehouseTransfers_ds_get_picking_tasks_by_orderId_shipmentIdService;
  public get ds_get_picking_tasks_by_orderId_shipmentId(): WarehouseTransfers_ds_get_picking_tasks_by_orderId_shipmentIdService {
    if(!this._ds_get_picking_tasks_by_orderId_shipmentId) {
      this._ds_get_picking_tasks_by_orderId_shipmentId = this.injector.get(WarehouseTransfers_ds_get_picking_tasks_by_orderId_shipmentIdService);
    }
    return this._ds_get_picking_tasks_by_orderId_shipmentId;
  }
  private _ds_get_project_by_projectId: WarehouseTransfers_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): WarehouseTransfers_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(WarehouseTransfers_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_putaway_task_by_licenseplateId: WarehouseTransfers_ds_get_putaway_task_by_licenseplateIdService;
  public get ds_get_putaway_task_by_licenseplateId(): WarehouseTransfers_ds_get_putaway_task_by_licenseplateIdService {
    if(!this._ds_get_putaway_task_by_licenseplateId) {
      this._ds_get_putaway_task_by_licenseplateId = this.injector.get(WarehouseTransfers_ds_get_putaway_task_by_licenseplateIdService);
    }
    return this._ds_get_putaway_task_by_licenseplateId;
  }
  private _ds_get_serialnumber_by_serialnumberId: WarehouseTransfers_ds_get_serialnumber_by_serialnumberIdService;
  public get ds_get_serialnumber_by_serialnumberId(): WarehouseTransfers_ds_get_serialnumber_by_serialnumberIdService {
    if(!this._ds_get_serialnumber_by_serialnumberId) {
      this._ds_get_serialnumber_by_serialnumberId = this.injector.get(WarehouseTransfers_ds_get_serialnumber_by_serialnumberIdService);
    }
    return this._ds_get_serialnumber_by_serialnumberId;
  }
  private _ds_get_serialnumbers_by_licenseplateId_lotId_packagingId: WarehouseTransfers_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService;
  public get ds_get_serialnumbers_by_licenseplateId_lotId_packagingId(): WarehouseTransfers_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService {
    if(!this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId) {
      this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId = this.injector.get(WarehouseTransfers_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService);
    }
    return this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId;
  }
  private _ds_get_serialnumbers_by_outboundShipmentId: WarehouseTransfers_ds_get_serialnumbers_by_outboundShipmentIdService;
  public get ds_get_serialnumbers_by_outboundShipmentId(): WarehouseTransfers_ds_get_serialnumbers_by_outboundShipmentIdService {
    if(!this._ds_get_serialnumbers_by_outboundShipmentId) {
      this._ds_get_serialnumbers_by_outboundShipmentId = this.injector.get(WarehouseTransfers_ds_get_serialnumbers_by_outboundShipmentIdService);
    }
    return this._ds_get_serialnumbers_by_outboundShipmentId;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId: WarehouseTransfers_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId(): WarehouseTransfers_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId = this.injector.get(WarehouseTransfers_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_shipmentId: WarehouseTransfers_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_shipmentId(): WarehouseTransfers_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId = this.injector.get(WarehouseTransfers_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId;
  }
  private _ds_get_shipment_by_shipmentId: WarehouseTransfers_ds_get_shipment_by_shipmentIdService;
  public get ds_get_shipment_by_shipmentId(): WarehouseTransfers_ds_get_shipment_by_shipmentIdService {
    if(!this._ds_get_shipment_by_shipmentId) {
      this._ds_get_shipment_by_shipmentId = this.injector.get(WarehouseTransfers_ds_get_shipment_by_shipmentIdService);
    }
    return this._ds_get_shipment_by_shipmentId;
  }
  private _ds_get_shipmentline_by_shipmentlineId: WarehouseTransfers_ds_get_shipmentline_by_shipmentlineIdService;
  public get ds_get_shipmentline_by_shipmentlineId(): WarehouseTransfers_ds_get_shipmentline_by_shipmentlineIdService {
    if(!this._ds_get_shipmentline_by_shipmentlineId) {
      this._ds_get_shipmentline_by_shipmentlineId = this.injector.get(WarehouseTransfers_ds_get_shipmentline_by_shipmentlineIdService);
    }
    return this._ds_get_shipmentline_by_shipmentlineId;
  }
  private _ds_get_shipmentlines_by_orderId_bottom1: WarehouseTransfers_ds_get_shipmentlines_by_orderId_bottom1Service;
  public get ds_get_shipmentlines_by_orderId_bottom1(): WarehouseTransfers_ds_get_shipmentlines_by_orderId_bottom1Service {
    if(!this._ds_get_shipmentlines_by_orderId_bottom1) {
      this._ds_get_shipmentlines_by_orderId_bottom1 = this.injector.get(WarehouseTransfers_ds_get_shipmentlines_by_orderId_bottom1Service);
    }
    return this._ds_get_shipmentlines_by_orderId_bottom1;
  }
  private _ds_get_shipmentlines_by_orderline: WarehouseTransfers_ds_get_shipmentlines_by_orderlineService;
  public get ds_get_shipmentlines_by_orderline(): WarehouseTransfers_ds_get_shipmentlines_by_orderlineService {
    if(!this._ds_get_shipmentlines_by_orderline) {
      this._ds_get_shipmentlines_by_orderline = this.injector.get(WarehouseTransfers_ds_get_shipmentlines_by_orderlineService);
    }
    return this._ds_get_shipmentlines_by_orderline;
  }
  private _ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId: WarehouseTransfers_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentIdService;
  public get ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId(): WarehouseTransfers_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentIdService {
    if(!this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId) {
      this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId = this.injector.get(WarehouseTransfers_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentIdService);
    }
    return this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId;
  }
  private _ds_get_target_inbound_order_receiving_tasks: WarehouseTransfers_ds_get_target_inbound_order_receiving_tasksService;
  public get ds_get_target_inbound_order_receiving_tasks(): WarehouseTransfers_ds_get_target_inbound_order_receiving_tasksService {
    if(!this._ds_get_target_inbound_order_receiving_tasks) {
      this._ds_get_target_inbound_order_receiving_tasks = this.injector.get(WarehouseTransfers_ds_get_target_inbound_order_receiving_tasksService);
    }
    return this._ds_get_target_inbound_order_receiving_tasks;
  }
  private _ds_get_tasks_by_chainHead: WarehouseTransfers_ds_get_tasks_by_chainHeadService;
  public get ds_get_tasks_by_chainHead(): WarehouseTransfers_ds_get_tasks_by_chainHeadService {
    if(!this._ds_get_tasks_by_chainHead) {
      this._ds_get_tasks_by_chainHead = this.injector.get(WarehouseTransfers_ds_get_tasks_by_chainHeadService);
    }
    return this._ds_get_tasks_by_chainHead;
  }
  private _ds_get_tasks_by_orderId_shipmentId: WarehouseTransfers_ds_get_tasks_by_orderId_shipmentIdService;
  public get ds_get_tasks_by_orderId_shipmentId(): WarehouseTransfers_ds_get_tasks_by_orderId_shipmentIdService {
    if(!this._ds_get_tasks_by_orderId_shipmentId) {
      this._ds_get_tasks_by_orderId_shipmentId = this.injector.get(WarehouseTransfers_ds_get_tasks_by_orderId_shipmentIdService);
    }
    return this._ds_get_tasks_by_orderId_shipmentId;
  }
  private _ds_get_tasks_by_serialnumberIds: WarehouseTransfers_ds_get_tasks_by_serialnumberIdsService;
  public get ds_get_tasks_by_serialnumberIds(): WarehouseTransfers_ds_get_tasks_by_serialnumberIdsService {
    if(!this._ds_get_tasks_by_serialnumberIds) {
      this._ds_get_tasks_by_serialnumberIds = this.injector.get(WarehouseTransfers_ds_get_tasks_by_serialnumberIdsService);
    }
    return this._ds_get_tasks_by_serialnumberIds;
  }
  private _ds_get_warehouse_transfer_by_lookupcode: WarehouseTransfers_ds_get_warehouse_transfer_by_lookupcodeService;
  public get ds_get_warehouse_transfer_by_lookupcode(): WarehouseTransfers_ds_get_warehouse_transfer_by_lookupcodeService {
    if(!this._ds_get_warehouse_transfer_by_lookupcode) {
      this._ds_get_warehouse_transfer_by_lookupcode = this.injector.get(WarehouseTransfers_ds_get_warehouse_transfer_by_lookupcodeService);
    }
    return this._ds_get_warehouse_transfer_by_lookupcode;
  }
  private _ds_get_warehouse_transfer_by_warehouseTransferId: WarehouseTransfers_ds_get_warehouse_transfer_by_warehouseTransferIdService;
  public get ds_get_warehouse_transfer_by_warehouseTransferId(): WarehouseTransfers_ds_get_warehouse_transfer_by_warehouseTransferIdService {
    if(!this._ds_get_warehouse_transfer_by_warehouseTransferId) {
      this._ds_get_warehouse_transfer_by_warehouseTransferId = this.injector.get(WarehouseTransfers_ds_get_warehouse_transfer_by_warehouseTransferIdService);
    }
    return this._ds_get_warehouse_transfer_by_warehouseTransferId;
  }
  private _ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferId: WarehouseTransfers_ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferIdService;
  public get ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferId(): WarehouseTransfers_ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferIdService {
    if(!this._ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferId) {
      this._ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferId = this.injector.get(WarehouseTransfers_ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferIdService);
    }
    return this._ds_get_warehouse_transfer_line_by_lineNumber_warehouseTransferId;
  }
  private _ds_get_warehouses_by_warehouseIds: WarehouseTransfers_ds_get_warehouses_by_warehouseIdsService;
  public get ds_get_warehouses_by_warehouseIds(): WarehouseTransfers_ds_get_warehouses_by_warehouseIdsService {
    if(!this._ds_get_warehouses_by_warehouseIds) {
      this._ds_get_warehouses_by_warehouseIds = this.injector.get(WarehouseTransfers_ds_get_warehouses_by_warehouseIdsService);
    }
    return this._ds_get_warehouses_by_warehouseIds;
  }
  private _ds_get_wave_by_waveId: WarehouseTransfers_ds_get_wave_by_waveIdService;
  public get ds_get_wave_by_waveId(): WarehouseTransfers_ds_get_wave_by_waveIdService {
    if(!this._ds_get_wave_by_waveId) {
      this._ds_get_wave_by_waveId = this.injector.get(WarehouseTransfers_ds_get_wave_by_waveIdService);
    }
    return this._ds_get_wave_by_waveId;
  }
  private _ds_orderline_total_amount_widget: WarehouseTransfers_ds_orderline_total_amount_widgetService;
  public get ds_orderline_total_amount_widget(): WarehouseTransfers_ds_orderline_total_amount_widgetService {
    if(!this._ds_orderline_total_amount_widget) {
      this._ds_orderline_total_amount_widget = this.injector.get(WarehouseTransfers_ds_orderline_total_amount_widgetService);
    }
    return this._ds_orderline_total_amount_widget;
  }
  private _ds_orderline_total_gross_received_widget: WarehouseTransfers_ds_orderline_total_gross_received_widgetService;
  public get ds_orderline_total_gross_received_widget(): WarehouseTransfers_ds_orderline_total_gross_received_widgetService {
    if(!this._ds_orderline_total_gross_received_widget) {
      this._ds_orderline_total_gross_received_widget = this.injector.get(WarehouseTransfers_ds_orderline_total_gross_received_widgetService);
    }
    return this._ds_orderline_total_gross_received_widget;
  }
  private _ds_orderline_total_received_widget: WarehouseTransfers_ds_orderline_total_received_widgetService;
  public get ds_orderline_total_received_widget(): WarehouseTransfers_ds_orderline_total_received_widgetService {
    if(!this._ds_orderline_total_received_widget) {
      this._ds_orderline_total_received_widget = this.injector.get(WarehouseTransfers_ds_orderline_total_received_widgetService);
    }
    return this._ds_orderline_total_received_widget;
  }
  private _ds_outbound_detail_pick_slip_report: WarehouseTransfers_ds_outbound_detail_pick_slip_reportService;
  public get ds_outbound_detail_pick_slip_report(): WarehouseTransfers_ds_outbound_detail_pick_slip_reportService {
    if(!this._ds_outbound_detail_pick_slip_report) {
      this._ds_outbound_detail_pick_slip_report = this.injector.get(WarehouseTransfers_ds_outbound_detail_pick_slip_reportService);
    }
    return this._ds_outbound_detail_pick_slip_report;
  }
  private _ds_outbound_details_bill_of_lading_report: WarehouseTransfers_ds_outbound_details_bill_of_lading_reportService;
  public get ds_outbound_details_bill_of_lading_report(): WarehouseTransfers_ds_outbound_details_bill_of_lading_reportService {
    if(!this._ds_outbound_details_bill_of_lading_report) {
      this._ds_outbound_details_bill_of_lading_report = this.injector.get(WarehouseTransfers_ds_outbound_details_bill_of_lading_reportService);
    }
    return this._ds_outbound_details_bill_of_lading_report;
  }
  private _ds_outbound_header_bill_of_lading_report: WarehouseTransfers_ds_outbound_header_bill_of_lading_reportService;
  public get ds_outbound_header_bill_of_lading_report(): WarehouseTransfers_ds_outbound_header_bill_of_lading_reportService {
    if(!this._ds_outbound_header_bill_of_lading_report) {
      this._ds_outbound_header_bill_of_lading_report = this.injector.get(WarehouseTransfers_ds_outbound_header_bill_of_lading_reportService);
    }
    return this._ds_outbound_header_bill_of_lading_report;
  }
  private _ds_outbound_header_pick_slip_report: WarehouseTransfers_ds_outbound_header_pick_slip_reportService;
  public get ds_outbound_header_pick_slip_report(): WarehouseTransfers_ds_outbound_header_pick_slip_reportService {
    if(!this._ds_outbound_header_pick_slip_report) {
      this._ds_outbound_header_pick_slip_report = this.injector.get(WarehouseTransfers_ds_outbound_header_pick_slip_reportService);
    }
    return this._ds_outbound_header_pick_slip_report;
  }
  private _ds_outbound_order_total_picked_gross_widget: WarehouseTransfers_ds_outbound_order_total_picked_gross_widgetService;
  public get ds_outbound_order_total_picked_gross_widget(): WarehouseTransfers_ds_outbound_order_total_picked_gross_widgetService {
    if(!this._ds_outbound_order_total_picked_gross_widget) {
      this._ds_outbound_order_total_picked_gross_widget = this.injector.get(WarehouseTransfers_ds_outbound_order_total_picked_gross_widgetService);
    }
    return this._ds_outbound_order_total_picked_gross_widget;
  }
  private _ds_outbound_order_total_picked_units_widget: WarehouseTransfers_ds_outbound_order_total_picked_units_widgetService;
  public get ds_outbound_order_total_picked_units_widget(): WarehouseTransfers_ds_outbound_order_total_picked_units_widgetService {
    if(!this._ds_outbound_order_total_picked_units_widget) {
      this._ds_outbound_order_total_picked_units_widget = this.injector.get(WarehouseTransfers_ds_outbound_order_total_picked_units_widgetService);
    }
    return this._ds_outbound_order_total_picked_units_widget;
  }
  private _ds_outbound_order_total_units_widget: WarehouseTransfers_ds_outbound_order_total_units_widgetService;
  public get ds_outbound_order_total_units_widget(): WarehouseTransfers_ds_outbound_order_total_units_widgetService {
    if(!this._ds_outbound_order_total_units_widget) {
      this._ds_outbound_order_total_units_widget = this.injector.get(WarehouseTransfers_ds_outbound_order_total_units_widgetService);
    }
    return this._ds_outbound_order_total_units_widget;
  }
  private _ds_warehouse_transfer_editor: WarehouseTransfers_ds_warehouse_transfer_editorService;
  public get ds_warehouse_transfer_editor(): WarehouseTransfers_ds_warehouse_transfer_editorService {
    if(!this._ds_warehouse_transfer_editor) {
      this._ds_warehouse_transfer_editor = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_editorService);
    }
    return this._ds_warehouse_transfer_editor;
  }
  private _ds_warehouse_transfer_inbound_order_editor: WarehouseTransfers_ds_warehouse_transfer_inbound_order_editorService;
  public get ds_warehouse_transfer_inbound_order_editor(): WarehouseTransfers_ds_warehouse_transfer_inbound_order_editorService {
    if(!this._ds_warehouse_transfer_inbound_order_editor) {
      this._ds_warehouse_transfer_inbound_order_editor = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_inbound_order_editorService);
    }
    return this._ds_warehouse_transfer_inbound_order_editor;
  }
  private _ds_warehouse_transfer_inbound_order_licenseplates_grid: WarehouseTransfers_ds_warehouse_transfer_inbound_order_licenseplates_gridService;
  public get ds_warehouse_transfer_inbound_order_licenseplates_grid(): WarehouseTransfers_ds_warehouse_transfer_inbound_order_licenseplates_gridService {
    if(!this._ds_warehouse_transfer_inbound_order_licenseplates_grid) {
      this._ds_warehouse_transfer_inbound_order_licenseplates_grid = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_inbound_order_licenseplates_gridService);
    }
    return this._ds_warehouse_transfer_inbound_order_licenseplates_grid;
  }
  private _ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid: WarehouseTransfers_ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridService;
  public get ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid(): WarehouseTransfers_ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridService {
    if(!this._ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid) {
      this._ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridService);
    }
    return this._ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid;
  }
  private _ds_warehouse_transfer_inbound_order_receiving_tasks_grid: WarehouseTransfers_ds_warehouse_transfer_inbound_order_receiving_tasks_gridService;
  public get ds_warehouse_transfer_inbound_order_receiving_tasks_grid(): WarehouseTransfers_ds_warehouse_transfer_inbound_order_receiving_tasks_gridService {
    if(!this._ds_warehouse_transfer_inbound_order_receiving_tasks_grid) {
      this._ds_warehouse_transfer_inbound_order_receiving_tasks_grid = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_inbound_order_receiving_tasks_gridService);
    }
    return this._ds_warehouse_transfer_inbound_order_receiving_tasks_grid;
  }
  private _ds_warehouse_transfer_inbound_orderlines_grid: WarehouseTransfers_ds_warehouse_transfer_inbound_orderlines_gridService;
  public get ds_warehouse_transfer_inbound_orderlines_grid(): WarehouseTransfers_ds_warehouse_transfer_inbound_orderlines_gridService {
    if(!this._ds_warehouse_transfer_inbound_orderlines_grid) {
      this._ds_warehouse_transfer_inbound_orderlines_grid = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_inbound_orderlines_gridService);
    }
    return this._ds_warehouse_transfer_inbound_orderlines_grid;
  }
  private _ds_warehouse_transfer_outbound_order_editor: WarehouseTransfers_ds_warehouse_transfer_outbound_order_editorService;
  public get ds_warehouse_transfer_outbound_order_editor(): WarehouseTransfers_ds_warehouse_transfer_outbound_order_editorService {
    if(!this._ds_warehouse_transfer_outbound_order_editor) {
      this._ds_warehouse_transfer_outbound_order_editor = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_outbound_order_editorService);
    }
    return this._ds_warehouse_transfer_outbound_order_editor;
  }
  private _ds_warehouse_transfer_outbound_order_lines_grid: WarehouseTransfers_ds_warehouse_transfer_outbound_order_lines_gridService;
  public get ds_warehouse_transfer_outbound_order_lines_grid(): WarehouseTransfers_ds_warehouse_transfer_outbound_order_lines_gridService {
    if(!this._ds_warehouse_transfer_outbound_order_lines_grid) {
      this._ds_warehouse_transfer_outbound_order_lines_grid = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_outbound_order_lines_gridService);
    }
    return this._ds_warehouse_transfer_outbound_order_lines_grid;
  }
  private _ds_warehouse_transfer_outbound_order_manual_allocations_grid: WarehouseTransfers_ds_warehouse_transfer_outbound_order_manual_allocations_gridService;
  public get ds_warehouse_transfer_outbound_order_manual_allocations_grid(): WarehouseTransfers_ds_warehouse_transfer_outbound_order_manual_allocations_gridService {
    if(!this._ds_warehouse_transfer_outbound_order_manual_allocations_grid) {
      this._ds_warehouse_transfer_outbound_order_manual_allocations_grid = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_outbound_order_manual_allocations_gridService);
    }
    return this._ds_warehouse_transfer_outbound_order_manual_allocations_grid;
  }
  private _ds_warehouse_transfer_outbound_order_shippingcontents_grid: WarehouseTransfers_ds_warehouse_transfer_outbound_order_shippingcontents_gridService;
  public get ds_warehouse_transfer_outbound_order_shippingcontents_grid(): WarehouseTransfers_ds_warehouse_transfer_outbound_order_shippingcontents_gridService {
    if(!this._ds_warehouse_transfer_outbound_order_shippingcontents_grid) {
      this._ds_warehouse_transfer_outbound_order_shippingcontents_grid = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_outbound_order_shippingcontents_gridService);
    }
    return this._ds_warehouse_transfer_outbound_order_shippingcontents_grid;
  }
  private _ds_warehouse_transfer_picking_grid: WarehouseTransfers_ds_warehouse_transfer_picking_gridService;
  public get ds_warehouse_transfer_picking_grid(): WarehouseTransfers_ds_warehouse_transfer_picking_gridService {
    if(!this._ds_warehouse_transfer_picking_grid) {
      this._ds_warehouse_transfer_picking_grid = this.injector.get(WarehouseTransfers_ds_warehouse_transfer_picking_gridService);
    }
    return this._ds_warehouse_transfer_picking_grid;
  }
  private _ds_warehouse_transfers_grid: WarehouseTransfers_ds_warehouse_transfers_gridService;
  public get ds_warehouse_transfers_grid(): WarehouseTransfers_ds_warehouse_transfers_gridService {
    if(!this._ds_warehouse_transfers_grid) {
      this._ds_warehouse_transfers_grid = this.injector.get(WarehouseTransfers_ds_warehouse_transfers_gridService);
    }
    return this._ds_warehouse_transfers_grid;
  }
  private _ds_warehousetransfer_statuses_dd: WarehouseTransfers_ds_warehousetransfer_statuses_ddService;
  public get ds_warehousetransfer_statuses_dd(): WarehouseTransfers_ds_warehousetransfer_statuses_ddService {
    if(!this._ds_warehousetransfer_statuses_dd) {
      this._ds_warehousetransfer_statuses_dd = this.injector.get(WarehouseTransfers_ds_warehousetransfer_statuses_ddService);
    }
    return this._ds_warehousetransfer_statuses_dd;
  }
  private _ds_warehousetransfer_types_dd: WarehouseTransfers_ds_warehousetransfer_types_ddService;
  public get ds_warehousetransfer_types_dd(): WarehouseTransfers_ds_warehousetransfer_types_ddService {
    if(!this._ds_warehousetransfer_types_dd) {
      this._ds_warehousetransfer_types_dd = this.injector.get(WarehouseTransfers_ds_warehousetransfer_types_ddService);
    }
    return this._ds_warehousetransfer_types_dd;
  }
}

