import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ds_dock_doors_by_warehouseIds_listService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseIds?: number[], appointmentType?: string, statusId?: number, parentIds?: number[] }): 
  Promise<{ result: { Id?: number, EligibleForAllocation?: boolean, IsPrimaryPick?: boolean, Name?: string, ParentId?: number, StatusId?: number, ChildLocationContainers?: { IsLoose?: boolean, Name?: string }[] }[] }> 
  {
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_doors_by_warehouseIds_list/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseIds?: number[], appointmentType?: string, statusId?: number, parentIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, EligibleForAllocation?: boolean, IsPrimaryPick?: boolean, Name?: string, ParentId?: number, StatusId?: number, ChildLocationContainers?: { IsLoose?: boolean, Name?: string }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_doors_by_warehouseIds_list/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseIds?: number[], appointmentType?: string, statusId?: number, parentIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, EligibleForAllocation?: boolean, IsPrimaryPick?: boolean, Name?: string, ParentId?: number, StatusId?: number, ChildLocationContainers?: { IsLoose?: boolean, Name?: string }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_doors_by_warehouseIds_list/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
