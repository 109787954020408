import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { TemperatureReadings_ds_find_temperature_capture_reading_typesService } from './TemperatureReadings.datasource.index';
import { TemperatureReadings_ds_find_temperature_reading_pointsService } from './TemperatureReadings.datasource.index';
import { TemperatureReadings_ds_measurement_units_ddService } from './TemperatureReadings.datasource.index';
import { TemperatureReadings_ds_shipment_lines_ddService } from './TemperatureReadings.datasource.index';
import { TemperatureReadings_ds_temperature_reading_points_ddService } from './TemperatureReadings.datasource.index';
import { TemperatureReadings_ds_temperature_reading_points_gridService } from './TemperatureReadings.datasource.index';
import { TemperatureReadings_ds_temperature_reading_types_ddService } from './TemperatureReadings.datasource.index';
import { TemperatureReadings_ds_temperature_reading_types_gridService } from './TemperatureReadings.datasource.index';
import { TemperatureReadings_ds_temperature_readings_gridService } from './TemperatureReadings.datasource.index';

@Injectable({ providedIn: 'root' })
export class TemperatureReadings_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public TemperatureReadings: TemperatureReadings_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_find_temperature_capture_reading_types: TemperatureReadings_ds_find_temperature_capture_reading_typesService;
  public get ds_find_temperature_capture_reading_types(): TemperatureReadings_ds_find_temperature_capture_reading_typesService {
    if(!this._ds_find_temperature_capture_reading_types) {
      this._ds_find_temperature_capture_reading_types = this.injector.get(TemperatureReadings_ds_find_temperature_capture_reading_typesService);
    }
    return this._ds_find_temperature_capture_reading_types;
  }
  private _ds_find_temperature_reading_points: TemperatureReadings_ds_find_temperature_reading_pointsService;
  public get ds_find_temperature_reading_points(): TemperatureReadings_ds_find_temperature_reading_pointsService {
    if(!this._ds_find_temperature_reading_points) {
      this._ds_find_temperature_reading_points = this.injector.get(TemperatureReadings_ds_find_temperature_reading_pointsService);
    }
    return this._ds_find_temperature_reading_points;
  }
  private _ds_measurement_units_dd: TemperatureReadings_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): TemperatureReadings_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(TemperatureReadings_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_shipment_lines_dd: TemperatureReadings_ds_shipment_lines_ddService;
  public get ds_shipment_lines_dd(): TemperatureReadings_ds_shipment_lines_ddService {
    if(!this._ds_shipment_lines_dd) {
      this._ds_shipment_lines_dd = this.injector.get(TemperatureReadings_ds_shipment_lines_ddService);
    }
    return this._ds_shipment_lines_dd;
  }
  private _ds_temperature_reading_points_dd: TemperatureReadings_ds_temperature_reading_points_ddService;
  public get ds_temperature_reading_points_dd(): TemperatureReadings_ds_temperature_reading_points_ddService {
    if(!this._ds_temperature_reading_points_dd) {
      this._ds_temperature_reading_points_dd = this.injector.get(TemperatureReadings_ds_temperature_reading_points_ddService);
    }
    return this._ds_temperature_reading_points_dd;
  }
  private _ds_temperature_reading_points_grid: TemperatureReadings_ds_temperature_reading_points_gridService;
  public get ds_temperature_reading_points_grid(): TemperatureReadings_ds_temperature_reading_points_gridService {
    if(!this._ds_temperature_reading_points_grid) {
      this._ds_temperature_reading_points_grid = this.injector.get(TemperatureReadings_ds_temperature_reading_points_gridService);
    }
    return this._ds_temperature_reading_points_grid;
  }
  private _ds_temperature_reading_types_dd: TemperatureReadings_ds_temperature_reading_types_ddService;
  public get ds_temperature_reading_types_dd(): TemperatureReadings_ds_temperature_reading_types_ddService {
    if(!this._ds_temperature_reading_types_dd) {
      this._ds_temperature_reading_types_dd = this.injector.get(TemperatureReadings_ds_temperature_reading_types_ddService);
    }
    return this._ds_temperature_reading_types_dd;
  }
  private _ds_temperature_reading_types_grid: TemperatureReadings_ds_temperature_reading_types_gridService;
  public get ds_temperature_reading_types_grid(): TemperatureReadings_ds_temperature_reading_types_gridService {
    if(!this._ds_temperature_reading_types_grid) {
      this._ds_temperature_reading_types_grid = this.injector.get(TemperatureReadings_ds_temperature_reading_types_gridService);
    }
    return this._ds_temperature_reading_types_grid;
  }
  private _ds_temperature_readings_grid: TemperatureReadings_ds_temperature_readings_gridService;
  public get ds_temperature_readings_grid(): TemperatureReadings_ds_temperature_readings_gridService {
    if(!this._ds_temperature_readings_grid) {
      this._ds_temperature_readings_grid = this.injector.get(TemperatureReadings_ds_temperature_readings_gridService);
    }
    return this._ds_temperature_readings_grid;
  }
}

