import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manifesting_ds_get_shipment_details_for_manifesting_by_shipmentIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId: number }): 
  Promise<{ result: { Id?: number, CarrierId?: number, ExpectedWarehouseId?: number, Incoterms?: string, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, BillingAddresId?: number, ProjectId?: number, Project?: { OwnerId?: number }, Addresses?: { Id?: number, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manifesting/datasources/ds_get_shipment_details_for_manifesting_by_shipmentId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CarrierId?: number, ExpectedWarehouseId?: number, Incoterms?: string, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, BillingAddresId?: number, ProjectId?: number, Project?: { OwnerId?: number }, Addresses?: { Id?: number, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manifesting/datasources/ds_get_shipment_details_for_manifesting_by_shipmentId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, CarrierId?: number, ExpectedWarehouseId?: number, Incoterms?: string, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, BillingAddresId?: number, ProjectId?: number, Project?: { OwnerId?: number }, Addresses?: { Id?: number, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manifesting/datasources/ds_get_shipment_details_for_manifesting_by_shipmentId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
