import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_find_materialsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { searchProperties: { materialId?: number, materialCode?: string, materialName?: string, projectId?: number, projectLookupCode?: string, projectName?: string }[] }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, ProjectId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.searchProperties)) {
      missingRequiredInParams.push('\'searchProperties\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_find_materials/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { searchProperties: { materialId?: number, materialCode?: string, materialName?: string, projectId?: number, projectLookupCode?: string, projectName?: string }[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, ProjectId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.searchProperties)) {
      missingRequiredInParams.push('\'searchProperties\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_find_materials/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { searchProperties: { materialId?: number, materialCode?: string, materialName?: string, projectId?: number, projectLookupCode?: string, projectName?: string }[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, ProjectId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.searchProperties)) {
      missingRequiredInParams.push('\'searchProperties\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_find_materials/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
