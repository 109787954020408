import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { EntityImport_ds_entity_import_requests_gridService } from './EntityImport.datasource.index';
import { EntityImport_ds_entity_import_total_completed_by_date_widgetService } from './EntityImport.datasource.index';
import { EntityImport_ds_entity_import_total_errors_by_date_widgetService } from './EntityImport.datasource.index';
import { EntityImport_ds_entity_import_total_ready_by_date_widgetService } from './EntityImport.datasource.index';
import { EntityImport_ds_error_message_by_requestId_editorService } from './EntityImport.datasource.index';
import { EntityImport_ds_get_request_xml_by_requestId_editorService } from './EntityImport.datasource.index';
import { EntityImport_ds_inventory_by_licenseplate_selection_gridService } from './EntityImport.datasource.index';

@Injectable({ providedIn: 'root' })
export class EntityImport_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public EntityImport: EntityImport_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_entity_import_requests_grid: EntityImport_ds_entity_import_requests_gridService;
  public get ds_entity_import_requests_grid(): EntityImport_ds_entity_import_requests_gridService {
    if(!this._ds_entity_import_requests_grid) {
      this._ds_entity_import_requests_grid = this.injector.get(EntityImport_ds_entity_import_requests_gridService);
    }
    return this._ds_entity_import_requests_grid;
  }
  private _ds_entity_import_total_completed_by_date_widget: EntityImport_ds_entity_import_total_completed_by_date_widgetService;
  public get ds_entity_import_total_completed_by_date_widget(): EntityImport_ds_entity_import_total_completed_by_date_widgetService {
    if(!this._ds_entity_import_total_completed_by_date_widget) {
      this._ds_entity_import_total_completed_by_date_widget = this.injector.get(EntityImport_ds_entity_import_total_completed_by_date_widgetService);
    }
    return this._ds_entity_import_total_completed_by_date_widget;
  }
  private _ds_entity_import_total_errors_by_date_widget: EntityImport_ds_entity_import_total_errors_by_date_widgetService;
  public get ds_entity_import_total_errors_by_date_widget(): EntityImport_ds_entity_import_total_errors_by_date_widgetService {
    if(!this._ds_entity_import_total_errors_by_date_widget) {
      this._ds_entity_import_total_errors_by_date_widget = this.injector.get(EntityImport_ds_entity_import_total_errors_by_date_widgetService);
    }
    return this._ds_entity_import_total_errors_by_date_widget;
  }
  private _ds_entity_import_total_ready_by_date_widget: EntityImport_ds_entity_import_total_ready_by_date_widgetService;
  public get ds_entity_import_total_ready_by_date_widget(): EntityImport_ds_entity_import_total_ready_by_date_widgetService {
    if(!this._ds_entity_import_total_ready_by_date_widget) {
      this._ds_entity_import_total_ready_by_date_widget = this.injector.get(EntityImport_ds_entity_import_total_ready_by_date_widgetService);
    }
    return this._ds_entity_import_total_ready_by_date_widget;
  }
  private _ds_error_message_by_requestId_editor: EntityImport_ds_error_message_by_requestId_editorService;
  public get ds_error_message_by_requestId_editor(): EntityImport_ds_error_message_by_requestId_editorService {
    if(!this._ds_error_message_by_requestId_editor) {
      this._ds_error_message_by_requestId_editor = this.injector.get(EntityImport_ds_error_message_by_requestId_editorService);
    }
    return this._ds_error_message_by_requestId_editor;
  }
  private _ds_get_request_xml_by_requestId_editor: EntityImport_ds_get_request_xml_by_requestId_editorService;
  public get ds_get_request_xml_by_requestId_editor(): EntityImport_ds_get_request_xml_by_requestId_editorService {
    if(!this._ds_get_request_xml_by_requestId_editor) {
      this._ds_get_request_xml_by_requestId_editor = this.injector.get(EntityImport_ds_get_request_xml_by_requestId_editorService);
    }
    return this._ds_get_request_xml_by_requestId_editor;
  }
  private _ds_inventory_by_licenseplate_selection_grid: EntityImport_ds_inventory_by_licenseplate_selection_gridService;
  public get ds_inventory_by_licenseplate_selection_grid(): EntityImport_ds_inventory_by_licenseplate_selection_gridService {
    if(!this._ds_inventory_by_licenseplate_selection_grid) {
      this._ds_inventory_by_licenseplate_selection_grid = this.injector.get(EntityImport_ds_inventory_by_licenseplate_selection_gridService);
    }
    return this._ds_inventory_by_licenseplate_selection_grid;
  }
}

