import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Owners_ds_container_types_assignment_gridService } from './Owners.datasource.index';
import { Owners_ds_container_types_gridService } from './Owners.datasource.index';
import { Owners_ds_get_assigned_containerService } from './Owners.datasource.index';
import { Owners_ds_get_billingcontracts_by_projectId_top1Service } from './Owners.datasource.index';
import { Owners_ds_get_container_types_assignment_ddService } from './Owners.datasource.index';
import { Owners_ds_get_container_types_by_Id_top1Service } from './Owners.datasource.index';
import { Owners_ds_get_licenseplate_count_ownerIdService } from './Owners.datasource.index';
import { Owners_ds_get_licenseplate_count_projectIdService } from './Owners.datasource.index';
import { Owners_ds_get_licenseplatecontents_by_projectId_top1Service } from './Owners.datasource.index';
import { Owners_ds_get_material_count_by_owner_idService } from './Owners.datasource.index';
import { Owners_ds_get_materials_by_projectId_top1Service } from './Owners.datasource.index';
import { Owners_ds_get_orders_by_projectId_top1Service } from './Owners.datasource.index';
import { Owners_ds_get_owner_by_lookupcodeService } from './Owners.datasource.index';
import { Owners_ds_get_owner_by_ownerIdService } from './Owners.datasource.index';
import { Owners_ds_get_owners_by_lookupsService } from './Owners.datasource.index';
import { Owners_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService } from './Owners.datasource.index';
import { Owners_ds_get_project_by_lookupcode_and_ownerIdService } from './Owners.datasource.index';
import { Owners_ds_get_project_by_projectIdService } from './Owners.datasource.index';
import { Owners_ds_get_project_count_by_owner_idService } from './Owners.datasource.index';
import { Owners_ds_get_project_top1Service } from './Owners.datasource.index';
import { Owners_ds_get_projects_by_lookupService } from './Owners.datasource.index';
import { Owners_ds_get_projects_by_lookupcodeService } from './Owners.datasource.index';
import { Owners_ds_get_projects_by_lookupsService } from './Owners.datasource.index';
import { Owners_ds_get_projects_by_ownerIdService } from './Owners.datasource.index';
import { Owners_ds_get_projects_by_projectIdsService } from './Owners.datasource.index';
import { Owners_ds_get_tasks_by_projectId_top1Service } from './Owners.datasource.index';
import { Owners_ds_measurement_units_ddService } from './Owners.datasource.index';
import { Owners_ds_owner_editorService } from './Owners.datasource.index';
import { Owners_ds_owner_project_statuses_ddService } from './Owners.datasource.index';
import { Owners_ds_owners_by_count_widgetService } from './Owners.datasource.index';
import { Owners_ds_owners_by_status_widgetService } from './Owners.datasource.index';
import { Owners_ds_owners_ddService } from './Owners.datasource.index';
import { Owners_ds_owners_navigation_gridService } from './Owners.datasource.index';
import { Owners_ds_project_editorService } from './Owners.datasource.index';
import { Owners_ds_projects_ddService } from './Owners.datasource.index';
import { Owners_ds_projects_gridService } from './Owners.datasource.index';

@Injectable({ providedIn: 'root' })
export class Owners_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Owners: Owners_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_container_types_assignment_grid: Owners_ds_container_types_assignment_gridService;
  public get ds_container_types_assignment_grid(): Owners_ds_container_types_assignment_gridService {
    if(!this._ds_container_types_assignment_grid) {
      this._ds_container_types_assignment_grid = this.injector.get(Owners_ds_container_types_assignment_gridService);
    }
    return this._ds_container_types_assignment_grid;
  }
  private _ds_container_types_grid: Owners_ds_container_types_gridService;
  public get ds_container_types_grid(): Owners_ds_container_types_gridService {
    if(!this._ds_container_types_grid) {
      this._ds_container_types_grid = this.injector.get(Owners_ds_container_types_gridService);
    }
    return this._ds_container_types_grid;
  }
  private _ds_get_assigned_container: Owners_ds_get_assigned_containerService;
  public get ds_get_assigned_container(): Owners_ds_get_assigned_containerService {
    if(!this._ds_get_assigned_container) {
      this._ds_get_assigned_container = this.injector.get(Owners_ds_get_assigned_containerService);
    }
    return this._ds_get_assigned_container;
  }
  private _ds_get_billingcontracts_by_projectId_top1: Owners_ds_get_billingcontracts_by_projectId_top1Service;
  public get ds_get_billingcontracts_by_projectId_top1(): Owners_ds_get_billingcontracts_by_projectId_top1Service {
    if(!this._ds_get_billingcontracts_by_projectId_top1) {
      this._ds_get_billingcontracts_by_projectId_top1 = this.injector.get(Owners_ds_get_billingcontracts_by_projectId_top1Service);
    }
    return this._ds_get_billingcontracts_by_projectId_top1;
  }
  private _ds_get_container_types_assignment_dd: Owners_ds_get_container_types_assignment_ddService;
  public get ds_get_container_types_assignment_dd(): Owners_ds_get_container_types_assignment_ddService {
    if(!this._ds_get_container_types_assignment_dd) {
      this._ds_get_container_types_assignment_dd = this.injector.get(Owners_ds_get_container_types_assignment_ddService);
    }
    return this._ds_get_container_types_assignment_dd;
  }
  private _ds_get_container_types_by_Id_top1: Owners_ds_get_container_types_by_Id_top1Service;
  public get ds_get_container_types_by_Id_top1(): Owners_ds_get_container_types_by_Id_top1Service {
    if(!this._ds_get_container_types_by_Id_top1) {
      this._ds_get_container_types_by_Id_top1 = this.injector.get(Owners_ds_get_container_types_by_Id_top1Service);
    }
    return this._ds_get_container_types_by_Id_top1;
  }
  private _ds_get_licenseplate_count_ownerId: Owners_ds_get_licenseplate_count_ownerIdService;
  public get ds_get_licenseplate_count_ownerId(): Owners_ds_get_licenseplate_count_ownerIdService {
    if(!this._ds_get_licenseplate_count_ownerId) {
      this._ds_get_licenseplate_count_ownerId = this.injector.get(Owners_ds_get_licenseplate_count_ownerIdService);
    }
    return this._ds_get_licenseplate_count_ownerId;
  }
  private _ds_get_licenseplate_count_projectId: Owners_ds_get_licenseplate_count_projectIdService;
  public get ds_get_licenseplate_count_projectId(): Owners_ds_get_licenseplate_count_projectIdService {
    if(!this._ds_get_licenseplate_count_projectId) {
      this._ds_get_licenseplate_count_projectId = this.injector.get(Owners_ds_get_licenseplate_count_projectIdService);
    }
    return this._ds_get_licenseplate_count_projectId;
  }
  private _ds_get_licenseplatecontents_by_projectId_top1: Owners_ds_get_licenseplatecontents_by_projectId_top1Service;
  public get ds_get_licenseplatecontents_by_projectId_top1(): Owners_ds_get_licenseplatecontents_by_projectId_top1Service {
    if(!this._ds_get_licenseplatecontents_by_projectId_top1) {
      this._ds_get_licenseplatecontents_by_projectId_top1 = this.injector.get(Owners_ds_get_licenseplatecontents_by_projectId_top1Service);
    }
    return this._ds_get_licenseplatecontents_by_projectId_top1;
  }
  private _ds_get_material_count_by_owner_id: Owners_ds_get_material_count_by_owner_idService;
  public get ds_get_material_count_by_owner_id(): Owners_ds_get_material_count_by_owner_idService {
    if(!this._ds_get_material_count_by_owner_id) {
      this._ds_get_material_count_by_owner_id = this.injector.get(Owners_ds_get_material_count_by_owner_idService);
    }
    return this._ds_get_material_count_by_owner_id;
  }
  private _ds_get_materials_by_projectId_top1: Owners_ds_get_materials_by_projectId_top1Service;
  public get ds_get_materials_by_projectId_top1(): Owners_ds_get_materials_by_projectId_top1Service {
    if(!this._ds_get_materials_by_projectId_top1) {
      this._ds_get_materials_by_projectId_top1 = this.injector.get(Owners_ds_get_materials_by_projectId_top1Service);
    }
    return this._ds_get_materials_by_projectId_top1;
  }
  private _ds_get_orders_by_projectId_top1: Owners_ds_get_orders_by_projectId_top1Service;
  public get ds_get_orders_by_projectId_top1(): Owners_ds_get_orders_by_projectId_top1Service {
    if(!this._ds_get_orders_by_projectId_top1) {
      this._ds_get_orders_by_projectId_top1 = this.injector.get(Owners_ds_get_orders_by_projectId_top1Service);
    }
    return this._ds_get_orders_by_projectId_top1;
  }
  private _ds_get_owner_by_lookupcode: Owners_ds_get_owner_by_lookupcodeService;
  public get ds_get_owner_by_lookupcode(): Owners_ds_get_owner_by_lookupcodeService {
    if(!this._ds_get_owner_by_lookupcode) {
      this._ds_get_owner_by_lookupcode = this.injector.get(Owners_ds_get_owner_by_lookupcodeService);
    }
    return this._ds_get_owner_by_lookupcode;
  }
  private _ds_get_owner_by_ownerId: Owners_ds_get_owner_by_ownerIdService;
  public get ds_get_owner_by_ownerId(): Owners_ds_get_owner_by_ownerIdService {
    if(!this._ds_get_owner_by_ownerId) {
      this._ds_get_owner_by_ownerId = this.injector.get(Owners_ds_get_owner_by_ownerIdService);
    }
    return this._ds_get_owner_by_ownerId;
  }
  private _ds_get_owners_by_lookups: Owners_ds_get_owners_by_lookupsService;
  public get ds_get_owners_by_lookups(): Owners_ds_get_owners_by_lookupsService {
    if(!this._ds_get_owners_by_lookups) {
      this._ds_get_owners_by_lookups = this.injector.get(Owners_ds_get_owners_by_lookupsService);
    }
    return this._ds_get_owners_by_lookups;
  }
  private _ds_get_ownerscarrierslookup_by_ownerId_carrierId: Owners_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService;
  public get ds_get_ownerscarrierslookup_by_ownerId_carrierId(): Owners_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService {
    if(!this._ds_get_ownerscarrierslookup_by_ownerId_carrierId) {
      this._ds_get_ownerscarrierslookup_by_ownerId_carrierId = this.injector.get(Owners_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService);
    }
    return this._ds_get_ownerscarrierslookup_by_ownerId_carrierId;
  }
  private _ds_get_project_by_lookupcode_and_ownerId: Owners_ds_get_project_by_lookupcode_and_ownerIdService;
  public get ds_get_project_by_lookupcode_and_ownerId(): Owners_ds_get_project_by_lookupcode_and_ownerIdService {
    if(!this._ds_get_project_by_lookupcode_and_ownerId) {
      this._ds_get_project_by_lookupcode_and_ownerId = this.injector.get(Owners_ds_get_project_by_lookupcode_and_ownerIdService);
    }
    return this._ds_get_project_by_lookupcode_and_ownerId;
  }
  private _ds_get_project_by_projectId: Owners_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): Owners_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(Owners_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_project_count_by_owner_id: Owners_ds_get_project_count_by_owner_idService;
  public get ds_get_project_count_by_owner_id(): Owners_ds_get_project_count_by_owner_idService {
    if(!this._ds_get_project_count_by_owner_id) {
      this._ds_get_project_count_by_owner_id = this.injector.get(Owners_ds_get_project_count_by_owner_idService);
    }
    return this._ds_get_project_count_by_owner_id;
  }
  private _ds_get_project_top1: Owners_ds_get_project_top1Service;
  public get ds_get_project_top1(): Owners_ds_get_project_top1Service {
    if(!this._ds_get_project_top1) {
      this._ds_get_project_top1 = this.injector.get(Owners_ds_get_project_top1Service);
    }
    return this._ds_get_project_top1;
  }
  private _ds_get_projects_by_lookup: Owners_ds_get_projects_by_lookupService;
  public get ds_get_projects_by_lookup(): Owners_ds_get_projects_by_lookupService {
    if(!this._ds_get_projects_by_lookup) {
      this._ds_get_projects_by_lookup = this.injector.get(Owners_ds_get_projects_by_lookupService);
    }
    return this._ds_get_projects_by_lookup;
  }
  private _ds_get_projects_by_lookupcode: Owners_ds_get_projects_by_lookupcodeService;
  public get ds_get_projects_by_lookupcode(): Owners_ds_get_projects_by_lookupcodeService {
    if(!this._ds_get_projects_by_lookupcode) {
      this._ds_get_projects_by_lookupcode = this.injector.get(Owners_ds_get_projects_by_lookupcodeService);
    }
    return this._ds_get_projects_by_lookupcode;
  }
  private _ds_get_projects_by_lookups: Owners_ds_get_projects_by_lookupsService;
  public get ds_get_projects_by_lookups(): Owners_ds_get_projects_by_lookupsService {
    if(!this._ds_get_projects_by_lookups) {
      this._ds_get_projects_by_lookups = this.injector.get(Owners_ds_get_projects_by_lookupsService);
    }
    return this._ds_get_projects_by_lookups;
  }
  private _ds_get_projects_by_ownerId: Owners_ds_get_projects_by_ownerIdService;
  public get ds_get_projects_by_ownerId(): Owners_ds_get_projects_by_ownerIdService {
    if(!this._ds_get_projects_by_ownerId) {
      this._ds_get_projects_by_ownerId = this.injector.get(Owners_ds_get_projects_by_ownerIdService);
    }
    return this._ds_get_projects_by_ownerId;
  }
  private _ds_get_projects_by_projectIds: Owners_ds_get_projects_by_projectIdsService;
  public get ds_get_projects_by_projectIds(): Owners_ds_get_projects_by_projectIdsService {
    if(!this._ds_get_projects_by_projectIds) {
      this._ds_get_projects_by_projectIds = this.injector.get(Owners_ds_get_projects_by_projectIdsService);
    }
    return this._ds_get_projects_by_projectIds;
  }
  private _ds_get_tasks_by_projectId_top1: Owners_ds_get_tasks_by_projectId_top1Service;
  public get ds_get_tasks_by_projectId_top1(): Owners_ds_get_tasks_by_projectId_top1Service {
    if(!this._ds_get_tasks_by_projectId_top1) {
      this._ds_get_tasks_by_projectId_top1 = this.injector.get(Owners_ds_get_tasks_by_projectId_top1Service);
    }
    return this._ds_get_tasks_by_projectId_top1;
  }
  private _ds_measurement_units_dd: Owners_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): Owners_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(Owners_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_owner_editor: Owners_ds_owner_editorService;
  public get ds_owner_editor(): Owners_ds_owner_editorService {
    if(!this._ds_owner_editor) {
      this._ds_owner_editor = this.injector.get(Owners_ds_owner_editorService);
    }
    return this._ds_owner_editor;
  }
  private _ds_owner_project_statuses_dd: Owners_ds_owner_project_statuses_ddService;
  public get ds_owner_project_statuses_dd(): Owners_ds_owner_project_statuses_ddService {
    if(!this._ds_owner_project_statuses_dd) {
      this._ds_owner_project_statuses_dd = this.injector.get(Owners_ds_owner_project_statuses_ddService);
    }
    return this._ds_owner_project_statuses_dd;
  }
  private _ds_owners_by_count_widget: Owners_ds_owners_by_count_widgetService;
  public get ds_owners_by_count_widget(): Owners_ds_owners_by_count_widgetService {
    if(!this._ds_owners_by_count_widget) {
      this._ds_owners_by_count_widget = this.injector.get(Owners_ds_owners_by_count_widgetService);
    }
    return this._ds_owners_by_count_widget;
  }
  private _ds_owners_by_status_widget: Owners_ds_owners_by_status_widgetService;
  public get ds_owners_by_status_widget(): Owners_ds_owners_by_status_widgetService {
    if(!this._ds_owners_by_status_widget) {
      this._ds_owners_by_status_widget = this.injector.get(Owners_ds_owners_by_status_widgetService);
    }
    return this._ds_owners_by_status_widget;
  }
  private _ds_owners_dd: Owners_ds_owners_ddService;
  public get ds_owners_dd(): Owners_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(Owners_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_owners_navigation_grid: Owners_ds_owners_navigation_gridService;
  public get ds_owners_navigation_grid(): Owners_ds_owners_navigation_gridService {
    if(!this._ds_owners_navigation_grid) {
      this._ds_owners_navigation_grid = this.injector.get(Owners_ds_owners_navigation_gridService);
    }
    return this._ds_owners_navigation_grid;
  }
  private _ds_project_editor: Owners_ds_project_editorService;
  public get ds_project_editor(): Owners_ds_project_editorService {
    if(!this._ds_project_editor) {
      this._ds_project_editor = this.injector.get(Owners_ds_project_editorService);
    }
    return this._ds_project_editor;
  }
  private _ds_projects_dd: Owners_ds_projects_ddService;
  public get ds_projects_dd(): Owners_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(Owners_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_projects_grid: Owners_ds_projects_gridService;
  public get ds_projects_grid(): Owners_ds_projects_gridService {
    if(!this._ds_projects_grid) {
      this._ds_projects_grid = this.injector.get(Owners_ds_projects_gridService);
    }
    return this._ds_projects_grid;
  }
}

