<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="packaging_short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.packaging_short_name.displayControl.styles.style"
          [ngClass]="row.cells.packaging_short_name.displayControl.styles.classes">{{row.cells.packaging_short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-packages_excl_materials_dd_single 
        data-cy="selector"
        [type]="row.cells.packaging_short_name.editControl.type"
        [formControl]="row.formGroup.controls['packaging_short_name_edit']"
        (displayTextChange)="row.cells.packaging_short_name.editControl.displayText=$event"
        [placeholder]="row.cells.packaging_short_name.editControl.placeholder"
        [styles]="row.cells.packaging_short_name.editControl.styles"
      [materialId]="row.$fields_packaging_short_name_selector_inParams_materialId"
    >
    </Materials-packages_excl_materials_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="sub_packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.sub_packaging.displayControl.styles.style"
          [ngClass]="row.cells.sub_packaging.displayControl.styles.classes">{{row.cells.sub_packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.sub_packaging.editControl.type"
        [formControl]="row.formGroup.controls['sub_packaging_edit']"
        (displayTextChange)="row.cells.sub_packaging.editControl.displayText=$event"
        [placeholder]="row.cells.sub_packaging.editControl.placeholder"
        [styles]="row.cells.sub_packaging.editControl.styles"
      [materialId]="row.$fields_sub_packaging_selector_inParams_materialId"
    >
    </Materials-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="sub_uom_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.sub_uom_quantity.displayControl.styles.style"
          [ngClass]="row.cells.sub_uom_quantity.displayControl.styles.classes">{{row.cells.sub_uom_quantity.displayControl.text | formatText : row.cells.sub_uom_quantity.displayControl.formatType : row.cells.sub_uom_quantity.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['sub_uom_quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.sub_uom_quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.sub_uom_quantity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.sub_uom_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.sub_uom_quantity.editControl.styles.style"
            [ngClass]="row.cells.sub_uom_quantity.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="default_packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['default_packaging_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.default_packaging.displayControl.styles.style"
                  [ngClass]="row.cells.default_packaging.displayControl.styles.classes">{{row.cells.default_packaging.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['default_packaging_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.default_packaging.editControl.styles.style"
                  [ngClass]="row.cells.default_packaging.editControl.styles.classes">{{row.cells.default_packaging.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="decimal_precision">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.decimal_precision.displayControl.styles.style"
          [ngClass]="row.cells.decimal_precision.displayControl.styles.classes">{{row.cells.decimal_precision.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-decimal_precision_single 
        data-cy="selector"
        [type]="row.cells.decimal_precision.editControl.type"
        [formControl]="row.formGroup.controls['decimal_precision_edit']"
        (displayTextChange)="row.cells.decimal_precision.editControl.displayText=$event"
        [placeholder]="row.cells.decimal_precision.editControl.placeholder"
        [styles]="row.cells.decimal_precision.editControl.styles"
    >
    </Materials-decimal_precision_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="upc_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.upc_code.displayControl.styles.style"
          [ngClass]="row.cells.upc_code.displayControl.styles.classes">{{row.cells.upc_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['upc_code_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.upc_code.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.upc_code.editControl.placeholder}}"
            [ngStyle]="row.cells.upc_code.editControl.styles.style"
            [ngClass]="row.cells.upc_code.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes">{{row.cells.length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['length_edit']"
            matInput
            numberBox
            [format]="row.cells.length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.length.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.length.editControl.placeholder}}"
            [ngStyle]="row.cells.length.editControl.styles.style"
            [ngClass]="row.cells.length.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.width.displayControl.styles.style"
          [ngClass]="row.cells.width.displayControl.styles.classes">{{row.cells.width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['width_edit']"
            matInput
            numberBox
            [format]="row.cells.width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.width.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.width.editControl.placeholder}}"
            [ngStyle]="row.cells.width.editControl.styles.style"
            [ngClass]="row.cells.width.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.height.displayControl.styles.style"
          [ngClass]="row.cells.height.displayControl.styles.classes">{{row.cells.height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['height_edit']"
            matInput
            numberBox
            [format]="row.cells.height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.height.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.height.editControl.placeholder}}"
            [ngStyle]="row.cells.height.editControl.styles.style"
            [ngClass]="row.cells.height.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dimension_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.dimension_uom.displayControl.styles.style"
          [ngClass]="row.cells.dimension_uom.displayControl.styles.classes">{{row.cells.dimension_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.dimension_uom.editControl.type"
        [formControl]="row.formGroup.controls['dimension_uom_edit']"
        (displayTextChange)="row.cells.dimension_uom.editControl.displayText=$event"
        [placeholder]="row.cells.dimension_uom.editControl.placeholder"
        [styles]="row.cells.dimension_uom.editControl.styles"
      [typeId]="row.$fields_dimension_uom_selector_inParams_typeId"
    >
    </Materials-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.weight.displayControl.styles.style"
          [ngClass]="row.cells.weight.displayControl.styles.classes">{{row.cells.weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['weight_edit']"
            matInput
            numberBox
            [format]="row.cells.weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.weight.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.weight.editControl.placeholder}}"
            [ngStyle]="row.cells.weight.editControl.styles.style"
            [ngClass]="row.cells.weight.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.shipping_weight.displayControl.styles.style"
          [ngClass]="row.cells.shipping_weight.displayControl.styles.classes">{{row.cells.shipping_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['shipping_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.shipping_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.shipping_weight.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shipping_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.shipping_weight.editControl.styles.style"
            [ngClass]="row.cells.shipping_weight.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.weight_uom.displayControl.styles.style"
          [ngClass]="row.cells.weight_uom.displayControl.styles.classes">{{row.cells.weight_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.weight_uom.editControl.type"
        [formControl]="row.formGroup.controls['weight_uom_edit']"
        (displayTextChange)="row.cells.weight_uom.editControl.displayText=$event"
        [placeholder]="row.cells.weight_uom.editControl.placeholder"
        [styles]="row.cells.weight_uom.editControl.styles"
      [typeId]="row.$fields_weight_uom_selector_inParams_typeId"
    >
    </Materials-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.volume.displayControl.styles.style"
          [ngClass]="row.cells.volume.displayControl.styles.classes">{{row.cells.volume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['volume_edit']"
            matInput
            numberBox
            [format]="row.cells.volume.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.volume.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.volume.editControl.placeholder}}"
            [ngStyle]="row.cells.volume.editControl.styles.style"
            [ngClass]="row.cells.volume.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.shipping_volume.displayControl.styles.style"
          [ngClass]="row.cells.shipping_volume.displayControl.styles.classes">{{row.cells.shipping_volume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['shipping_volume_edit']"
            matInput
            numberBox
            [format]="row.cells.shipping_volume.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.shipping_volume.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shipping_volume.editControl.placeholder}}"
            [ngStyle]="row.cells.shipping_volume.editControl.styles.style"
            [ngClass]="row.cells.shipping_volume.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="volume_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.volume_uom.displayControl.styles.style"
          [ngClass]="row.cells.volume_uom.displayControl.styles.classes">{{row.cells.volume_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.volume_uom.editControl.type"
        [formControl]="row.formGroup.controls['volume_uom_edit']"
        (displayTextChange)="row.cells.volume_uom.editControl.displayText=$event"
        [placeholder]="row.cells.volume_uom.editControl.placeholder"
        [styles]="row.cells.volume_uom.editControl.styles"
      [typeId]="row.$fields_volume_uom_selector_inParams_typeId"
    >
    </Materials-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pallet_high">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.pallet_high.displayControl.styles.style"
          [ngClass]="row.cells.pallet_high.displayControl.styles.classes">{{row.cells.pallet_high.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['pallet_high_edit']"
            matInput
            numberBox
            [format]="row.cells.pallet_high.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.pallet_high.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.pallet_high.editControl.placeholder}}"
            [ngStyle]="row.cells.pallet_high.editControl.styles.style"
            [ngClass]="row.cells.pallet_high.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pallet_tie">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.pallet_tie.displayControl.styles.style"
          [ngClass]="row.cells.pallet_tie.displayControl.styles.classes">{{row.cells.pallet_tie.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['pallet_tie_edit']"
            matInput
            numberBox
            [format]="row.cells.pallet_tie.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.pallet_tie.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.pallet_tie.editControl.placeholder}}"
            [ngStyle]="row.cells.pallet_tie.editControl.styles.style"
            [ngClass]="row.cells.pallet_tie.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ready_to_ship">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['ready_to_ship_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.ready_to_ship.displayControl.styles.style"
                  [ngClass]="row.cells.ready_to_ship.displayControl.styles.classes">{{row.cells.ready_to_ship.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['ready_to_ship_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.ready_to_ship.editControl.styles.style"
                  [ngClass]="row.cells.ready_to_ship.editControl.styles.classes">{{row.cells.ready_to_ship.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="autogenerate_serial_numbers">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['autogenerate_serial_numbers_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.autogenerate_serial_numbers.displayControl.styles.style"
                  [ngClass]="row.cells.autogenerate_serial_numbers.displayControl.styles.classes">{{row.cells.autogenerate_serial_numbers.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['autogenerate_serial_numbers_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.autogenerate_serial_numbers.editControl.styles.style"
                  [ngClass]="row.cells.autogenerate_serial_numbers.editControl.styles.classes">{{row.cells.autogenerate_serial_numbers.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="stackable_group_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.stackable_group_id.displayControl.styles.style"
          [ngClass]="row.cells.stackable_group_id.displayControl.styles.classes">{{row.cells.stackable_group_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['stackable_group_id_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.stackable_group_id.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.stackable_group_id.editControl.placeholder}}"
            [ngStyle]="row.cells.stackable_group_id.editControl.styles.style"
            [ngClass]="row.cells.stackable_group_id.editControl.styles.classes"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
