<div class="card-container">
  <mat-card class="datex-card card"
            data-cy="card" 
            [ngStyle]="styles.style" 
            [ngClass]="styles.classes">
    <ng-container
                  *ngIf="initialized && !$hasMissingRequiredInParams">



      <mat-card-title-group class="card-header">

      </mat-card-title-group>


      <mat-card-content data-cy="card-content" class="card-content" >
        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.licenseplates.hidden" class="tab" data-cy="tab-licenseplates">
              <h2 [className]="tabs.licenseplates.active? 'active': ''" (click)="tabs.licenseplates.activate()">{{tabs.licenseplates.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-licenseplates_by_shipping_container_list *ngIf="tabs.licenseplates.active"
              #$tabs_licenseplates
              [shippingContainerId]="$tabs_licenseplates_licenseplates_by_shipping_container_list_inParams_shippingContainerId"
              ($refreshEvent)="refresh(false, false, '$tabs_licenseplates')">
              </FootPrintManager-licenseplates_by_shipping_container_list>
        </div>
      </mat-card-content>



    </ng-container>

    <div *ngIf="$hasMissingRequiredInParams"
        class="missing-params">
      <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
    </div>
  </mat-card>
  <div class="card-gripper"></div>
</div>