import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Notifications_add_auto_email_rule_attachment_flowService } from './Notifications.flow.index';
import { Notifications_add_auto_email_rule_flowService } from './Notifications.flow.index';
import { Notifications_add_email_request_attachment_flowService } from './Notifications.flow.index';
import { Notifications_add_email_request_flowService } from './Notifications.flow.index';
import { Notifications_alerts_findService } from './Notifications.flow.index';
import { Notifications_auto_email_copy_rule_flowService } from './Notifications.flow.index';
import { Notifications_auto_email_execute_rule_flowService } from './Notifications.flow.index';
import { Notifications_auto_email_schedule_format_flowService } from './Notifications.flow.index';
import { Notifications_auto_email_schedule_parse_flowService } from './Notifications.flow.index';
import { Notifications_create_email_request_flowService } from './Notifications.flow.index';
import { Notifications_delete_auto_email_rule_attachment_flowService } from './Notifications.flow.index';
import { Notifications_delete_auto_email_rule_flowService } from './Notifications.flow.index';
import { Notifications_delete_email_request_flowService } from './Notifications.flow.index';
import { Notifications_email_request_sendService } from './Notifications.flow.index';
import { Notifications_email_sendService } from './Notifications.flow.index';
import { Notifications_email_send_testService } from './Notifications.flow.index';
import { Notifications_get_auto_email_rule_requests_flowService } from './Notifications.flow.index';
import { Notifications_get_blob_by_requestAttachmentIdService } from './Notifications.flow.index';
import { Notifications_get_footprint_attachment_content_flowService } from './Notifications.flow.index';
import { Notifications_get_is_auto_emailing_initialized_flowService } from './Notifications.flow.index';
import { Notifications_initialize_auto_emailing_flowService } from './Notifications.flow.index';
import { Notifications_main_email_requests_sendService } from './Notifications.flow.index';
import { Notifications_parse_emails_flowService } from './Notifications.flow.index';
import { Notifications_storage_alertsService } from './Notifications.flow.index';
import { Notifications_storage_alerts_filtersService } from './Notifications.flow.index';
import { Notifications_update_auto_email_rule_attachment_flowService } from './Notifications.flow.index';
import { Notifications_update_auto_email_rule_flowService } from './Notifications.flow.index';

import { $frontendTypes } from './Notifications.frontend.types'

@Injectable({ providedIn: 'root' })
export class Notifications_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Notifications: Notifications_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _add_auto_email_rule_attachment_flow: Notifications_add_auto_email_rule_attachment_flowService;
  public async add_auto_email_rule_attachment_flow(inParams: { ruleId: string, properties: $frontendTypes.Notifications.AutoEmailRuleAttachment }): Promise< { attachmentId?: string }> {
    if(!this._add_auto_email_rule_attachment_flow) {
      this._add_auto_email_rule_attachment_flow = this.injector.get(Notifications_add_auto_email_rule_attachment_flowService);
    }
    return this._add_auto_email_rule_attachment_flow.run(inParams);
  }
   
   

   
 
  private _add_auto_email_rule_flow: Notifications_add_auto_email_rule_flowService;
  public async add_auto_email_rule_flow(inParams: { ruleProperties: $frontendTypes.Notifications.AutoEmailRule }): Promise< { ruleId?: string }> {
    if(!this._add_auto_email_rule_flow) {
      this._add_auto_email_rule_flow = this.injector.get(Notifications_add_auto_email_rule_flowService);
    }
    return this._add_auto_email_rule_flow.run(inParams);
  }
   
   

   
 
  private _add_email_request_attachment_flow: Notifications_add_email_request_attachment_flowService;
  public async add_email_request_attachment_flow(inParams: { requestId: string, fileContent: Blob, fileName: string, fileType: string, transaction_id?: string }): Promise< { attachmentId?: string }> {
    if(!this._add_email_request_attachment_flow) {
      this._add_email_request_attachment_flow = this.injector.get(Notifications_add_email_request_attachment_flowService);
    }
    return this._add_email_request_attachment_flow.run(inParams);
  }
   
   

   
 
  private _add_email_request_flow: Notifications_add_email_request_flowService;
  public async add_email_request_flow(inParams: { request: $frontendTypes.Notifications.EmailRequest, transaction_id?: string }): Promise< { requestId?: string }> {
    if(!this._add_email_request_flow) {
      this._add_email_request_flow = this.injector.get(Notifications_add_email_request_flowService);
    }
    return this._add_email_request_flow.run(inParams);
  }
   
   

   
 
  private _alerts_find: Notifications_alerts_findService;
  public async alerts_find(inParams: { application_name?: string, level?: number }): Promise< { alerts?: { alert_id?: string, application_name?: string, alert_name?: string, level?: number, to?: string, cc?: string, bcc?: string, subject?: string, pdf?: boolean, filters?: { name?: string, value?: string }[] }[], success?: boolean, messages?: any[] }> {
    if(!this._alerts_find) {
      this._alerts_find = this.injector.get(Notifications_alerts_findService);
    }
    return this._alerts_find.run(inParams);
  }
   
   

   
 
  private _auto_email_copy_rule_flow: Notifications_auto_email_copy_rule_flowService;
  public async auto_email_copy_rule_flow(inParams: { rule_id: string, project_id: number }): Promise< { rule_id?: string, reasons?: string[] }> {
    if(!this._auto_email_copy_rule_flow) {
      this._auto_email_copy_rule_flow = this.injector.get(Notifications_auto_email_copy_rule_flowService);
    }
    return this._auto_email_copy_rule_flow.run(inParams);
  }
   
   

   
 
  private _auto_email_execute_rule_flow: Notifications_auto_email_execute_rule_flowService;
  public async auto_email_execute_rule_flow(inParams: { ruleId: string }): Promise< { ruleId?: string, reasons?: string[] }> {
    if(!this._auto_email_execute_rule_flow) {
      this._auto_email_execute_rule_flow = this.injector.get(Notifications_auto_email_execute_rule_flowService);
    }
    return this._auto_email_execute_rule_flow.run(inParams);
  }
   
   

   
 
  private _auto_email_schedule_format_flow: Notifications_auto_email_schedule_format_flowService;
  public async auto_email_schedule_format_flow(inParams: { schedule: $frontendTypes.Notifications.AutoEmailSchedule }): Promise< { scheduleString?: string }> {
    if(!this._auto_email_schedule_format_flow) {
      this._auto_email_schedule_format_flow = this.injector.get(Notifications_auto_email_schedule_format_flowService);
    }
    return this._auto_email_schedule_format_flow.run(inParams);
  }
   
   

   
 
  private _auto_email_schedule_parse_flow: Notifications_auto_email_schedule_parse_flowService;
  public async auto_email_schedule_parse_flow(inParams: { scheduleString: string }): Promise< { schedule?: $frontendTypes.Notifications.AutoEmailSchedule }> {
    if(!this._auto_email_schedule_parse_flow) {
      this._auto_email_schedule_parse_flow = this.injector.get(Notifications_auto_email_schedule_parse_flowService);
    }
    return this._auto_email_schedule_parse_flow.run(inParams);
  }
   
   

   
 
  private _create_email_request_flow: Notifications_create_email_request_flowService;
  public async create_email_request_flow(inParams: { ruleId?: string, entityType?: string, entityId?: number, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, attachments?: { fileContent?: any, fileName?: string, fileType?: string }[], projectId?: number, transaction_id?: string }): Promise< { reasons?: string[], ruleId?: string }> {
    if(!this._create_email_request_flow) {
      this._create_email_request_flow = this.injector.get(Notifications_create_email_request_flowService);
    }
    return this._create_email_request_flow.run(inParams);
  }
   
   

   
 
  private _delete_auto_email_rule_attachment_flow: Notifications_delete_auto_email_rule_attachment_flowService;
  public async delete_auto_email_rule_attachment_flow(inParams: { attachmentId: string }): Promise< { attachmentId?: string }> {
    if(!this._delete_auto_email_rule_attachment_flow) {
      this._delete_auto_email_rule_attachment_flow = this.injector.get(Notifications_delete_auto_email_rule_attachment_flowService);
    }
    return this._delete_auto_email_rule_attachment_flow.run(inParams);
  }
   
   

   
 
  private _delete_auto_email_rule_flow: Notifications_delete_auto_email_rule_flowService;
  public async delete_auto_email_rule_flow(inParams: { ruleId: string }): Promise<void> {
    if(!this._delete_auto_email_rule_flow) {
      this._delete_auto_email_rule_flow = this.injector.get(Notifications_delete_auto_email_rule_flowService);
    }
    return this._delete_auto_email_rule_flow.run(inParams);
  }
   
   

   
 
  private _delete_email_request_flow: Notifications_delete_email_request_flowService;
  public async delete_email_request_flow(inParams: { requestId: string }): Promise< { attachmentId?: string }> {
    if(!this._delete_email_request_flow) {
      this._delete_email_request_flow = this.injector.get(Notifications_delete_email_request_flowService);
    }
    return this._delete_email_request_flow.run(inParams);
  }
   
   

   
 
  private _email_request_send: Notifications_email_request_sendService;
  public async email_request_send(inParams: { requestId: string, transaction_id?: string, application_name?: string }): Promise<void> {
    if(!this._email_request_send) {
      this._email_request_send = this.injector.get(Notifications_email_request_sendService);
    }
    return this._email_request_send.run(inParams);
  }
   
   

   
 
  private _email_send: Notifications_email_sendService;
  public async email_send(inParams: { to?: string, cc?: string, bcc?: string, subject?: string, html?: string, text?: string, attachments?: { attachment_blob: any, attachment_filename: string, attachment_type: string }[], transaction_id?: string, application_name?: string }): Promise< { success?: boolean, messages?: any[] }> {
    if(!this._email_send) {
      this._email_send = this.injector.get(Notifications_email_sendService);
    }
    return this._email_send.run(inParams);
  }
   
   

   
 
  private _email_send_test: Notifications_email_send_testService;
  public async email_send_test(inParams: { to?: string, cc?: string, bcc?: string, subject?: string, html?: string, text?: string, attachments?: { attachment_blob: any, attachment_filename: string, attachment_type: string }[] }): Promise< { success?: boolean, messages?: any[] }> {
    if(!this._email_send_test) {
      this._email_send_test = this.injector.get(Notifications_email_send_testService);
    }
    return this._email_send_test.run(inParams);
  }
   
   

   
 
  private _get_auto_email_rule_requests_flow: Notifications_get_auto_email_rule_requests_flowService;
  public async get_auto_email_rule_requests_flow(inParams: { ruleId?: string, transaction_id?: string }): Promise< { requests?: { ruleId?: string, entityType?: string, entityId?: number, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, projectId?: number, attachments?: { type?: string, name?: string, options?: any, packageName?: string, reportName?: string, inputs?: any, attachmentId?: number, attachmentName?: string, attachmentExtension?: string }[] }[] }> {
    if(!this._get_auto_email_rule_requests_flow) {
      this._get_auto_email_rule_requests_flow = this.injector.get(Notifications_get_auto_email_rule_requests_flowService);
    }
    return this._get_auto_email_rule_requests_flow.run(inParams);
  }
   
   

   
 
  private _get_blob_by_requestAttachmentId: Notifications_get_blob_by_requestAttachmentIdService;
  public async get_blob_by_requestAttachmentId(inParams: { requestAttachmentId: string }): Promise< { fileContent?: Blob }> {
    if(!this._get_blob_by_requestAttachmentId) {
      this._get_blob_by_requestAttachmentId = this.injector.get(Notifications_get_blob_by_requestAttachmentIdService);
    }
    return this._get_blob_by_requestAttachmentId.run(inParams);
  }
   
   

   
 
  private _get_footprint_attachment_content_flow: Notifications_get_footprint_attachment_content_flowService;
  public async get_footprint_attachment_content_flow(inParams: { entityType: string, attachmentId: number }): Promise< { fileContent?: Blob }> {
    if(!this._get_footprint_attachment_content_flow) {
      this._get_footprint_attachment_content_flow = this.injector.get(Notifications_get_footprint_attachment_content_flowService);
    }
    return this._get_footprint_attachment_content_flow.run(inParams);
  }
   
   

   
 
  private _get_is_auto_emailing_initialized_flow: Notifications_get_is_auto_emailing_initialized_flowService;
  public async get_is_auto_emailing_initialized_flow(inParams: {  }): Promise< { is_initialized: boolean, reasons?: string[], config_id?: string }> {
    if(!this._get_is_auto_emailing_initialized_flow) {
      this._get_is_auto_emailing_initialized_flow = this.injector.get(Notifications_get_is_auto_emailing_initialized_flowService);
    }
    return this._get_is_auto_emailing_initialized_flow.run(inParams);
  }
   
   

   
 
  private _initialize_auto_emailing_flow: Notifications_initialize_auto_emailing_flowService;
  public async initialize_auto_emailing_flow(inParams: {  }): Promise< { reasons?: string[] }> {
    if(!this._initialize_auto_emailing_flow) {
      this._initialize_auto_emailing_flow = this.injector.get(Notifications_initialize_auto_emailing_flowService);
    }
    return this._initialize_auto_emailing_flow.run(inParams);
  }
   
   

   
 
  private _main_email_requests_send: Notifications_main_email_requests_sendService;
  public async main_email_requests_send(inParams: { transaction_id?: string, application_name?: string }): Promise<void> {
    if(!this._main_email_requests_send) {
      this._main_email_requests_send = this.injector.get(Notifications_main_email_requests_sendService);
    }
    return this._main_email_requests_send.run(inParams);
  }
   
   

   
 
  private _parse_emails_flow: Notifications_parse_emails_flowService;
  public async parse_emails_flow(inParams: { email_string: string }): Promise< { emails?: string[] }> {
    if(!this._parse_emails_flow) {
      this._parse_emails_flow = this.injector.get(Notifications_parse_emails_flowService);
    }
    return this._parse_emails_flow.run(inParams);
  }
   
   

   
 
  private _storage_alerts: Notifications_storage_alertsService;
  public async storage_alerts(inParams: { action: string, payload?: $frontendTypes.Notifications.Alert, skip?: number, take?: number, application_name: string, search?: string }): Promise< { payload?: $frontendTypes.Notifications.Alert[], success?: boolean, error?: any[] }> {
    if(!this._storage_alerts) {
      this._storage_alerts = this.injector.get(Notifications_storage_alertsService);
    }
    return this._storage_alerts.run(inParams);
  }
   
   

   
 
  private _storage_alerts_filters: Notifications_storage_alerts_filtersService;
  public async storage_alerts_filters(inParams: { action: string, payload?: { id?: string, alert_id?: string, filter_name?: string, filter_value?: string }, skip?: number, take?: number, search?: string, application_name?: string, alert_ids?: string[] }): Promise< { payload?: { id?: string, application_name?: string, alert_id?: string, filter_name?: string, filter_value?: string }[], success?: boolean, error?: any[] }> {
    if(!this._storage_alerts_filters) {
      this._storage_alerts_filters = this.injector.get(Notifications_storage_alerts_filtersService);
    }
    return this._storage_alerts_filters.run(inParams);
  }
   
   

   
 
  private _update_auto_email_rule_attachment_flow: Notifications_update_auto_email_rule_attachment_flowService;
  public async update_auto_email_rule_attachment_flow(inParams: { attachmentId: string, properties?: $frontendTypes.Notifications.AutoEmailRuleAttachment }): Promise< { attachmentId?: string }> {
    if(!this._update_auto_email_rule_attachment_flow) {
      this._update_auto_email_rule_attachment_flow = this.injector.get(Notifications_update_auto_email_rule_attachment_flowService);
    }
    return this._update_auto_email_rule_attachment_flow.run(inParams);
  }
   
   

   
 
  private _update_auto_email_rule_flow: Notifications_update_auto_email_rule_flowService;
  public async update_auto_email_rule_flow(inParams: { ruleId: string, ruleProperties?: $frontendTypes.Notifications.AutoEmailRule }): Promise<void> {
    if(!this._update_auto_email_rule_flow) {
      this._update_auto_email_rule_flow = this.injector.get(Notifications_update_auto_email_rule_flowService);
    }
    return this._update_auto_email_rule_flow.run(inParams);
  }
   
   

   
}
