import { Injectable } from '@angular/core';
import { Core, PdfExport, HtmlExport, XlsxExport, TabularDataExport } from "@grapecity/activereports";
import { Rdl } from '@grapecity/activereports/core';
import { UtilsService } from './utils.service';
import { cloneDeep } from 'lodash-es';


@Injectable()
export abstract class ReportBaseService<TInParams, TData> {
  protected abstract reportReferenceName: string;
  protected abstract appReferenceName: string;
  protected abstract getData(inParams: TInParams): Promise<TData>;
  protected abstract throwIfMissingRequiredInParams(parameters: TInParams);
  private _report: Rdl.Report;

  constructor(protected utils: UtilsService) {
  }

  private async downloadReport(): Promise<Rdl.Report> {
    const fileName = `reports/${this.appReferenceName}/${this.reportReferenceName}.rdlx-json`;
    if (!this._report) {
      this._report = await this.utils.http.get(fileName);
    }
    return cloneDeep(this._report);
  }

  public async getReport(inParams: TInParams): Promise<Rdl.Report> {
    const report = await this.downloadReport();
    if (report.DataSources && report.DataSources.length) {
      const data = await this.getData(inParams);
      this.utils.date.transformUTCDateDeep(data);
      report.DataSources[0].ConnectionProperties.ConnectString = "jsondata=" + JSON.stringify(data);
    }
    return report;
  }

  public getReportParameters(inParams: TInParams): { Name: string; Value: Core.ParameterVariant[] }[] {
    const result = [];
    this.utils.date.transformUTCDateDeep(inParams, true);
    if (inParams) {
      Object.keys(inParams).forEach(key => {
        let parameterValue = inParams[key];
        if (!Array.isArray(parameterValue)) {
          parameterValue = [parameterValue];
        }
        result.push({
          Name: key,
          Value: parameterValue
        });
      });
    }
    return result;
  }

  protected async getPageDocument(inParams: TInParams) {
    this.throwIfMissingRequiredInParams(inParams);

    const pageReport = new Core.PageReport();
    const report = await this.getReport(inParams);
    await pageReport.load(report);

    const reportParameters = this.getReportParameters(inParams);
    if (reportParameters && reportParameters.length) {
      reportParameters.forEach(p => {
        if (pageReport.parameters[p.Name]) {
          pageReport.parameters[p.Name].values = p.Value;
        } else {
          throw (new Error(`The report definition has missing parameter ${p.Name}`));
        }
      });
    }
    // if report parameters have default values, the following method should be invoked
    // await pageReport.resolveParametersValues();
    return pageReport.run();
  }

  public async print(inParams: TInParams) {
    const pageDocument = await this.getPageDocument(inParams);
    return pageDocument.print()
  }

  public async exportAsPdf(inParams: TInParams, settings?: PdfExport.PdfSettings) {
    const pageDocument = await this.getPageDocument(inParams);
    return PdfExport.exportDocument(pageDocument, settings);
  }

  public async exportAsXlsx(inParams: TInParams, settings?: XlsxExport.XlsxSettings) {
    const pageDocument = await this.getPageDocument(inParams);
    return XlsxExport.exportDocument(pageDocument, settings);
  }

  public async exportAsTabularData(inParams: TInParams, settings?: TabularDataExport.TabularDataSettings) {
    const pageDocument = await this.getPageDocument(inParams);
    return TabularDataExport.exportDocument(pageDocument, settings);
  }

  public async exportAsHtml(inParams: TInParams, settings?: HtmlExport.HtmlSettings) {
    const pageDocument = await this.getPageDocument(inParams);
    return HtmlExport.exportDocument(pageDocument, settings);
  }
}
