import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { ExcelProjectImport_ds_get_owner_by_lookupcodeService } from './ExcelProjectImport.datasource.index';
import { ExcelProjectImport_ds_get_owners_by_lookupcodeService } from './ExcelProjectImport.datasource.index';
import { ExcelProjectImport_ds_get_project_by_lookupcode_and_ownerIdService } from './ExcelProjectImport.datasource.index';
import { ExcelProjectImport_ds_get_project_by_lookupcode_ownerLookupcodeService } from './ExcelProjectImport.datasource.index';
import { ExcelProjectImport_ds_get_projects_by_lookupcode_ownerLookupcodeService } from './ExcelProjectImport.datasource.index';
import { ExcelProjectImport_ds_owners_ddService } from './ExcelProjectImport.datasource.index';
import { ExcelProjectImport_ds_project_import_gridService } from './ExcelProjectImport.datasource.index';

@Injectable({ providedIn: 'root' })
export class ExcelProjectImport_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public ExcelProjectImport: ExcelProjectImport_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_owner_by_lookupcode: ExcelProjectImport_ds_get_owner_by_lookupcodeService;
  public get ds_get_owner_by_lookupcode(): ExcelProjectImport_ds_get_owner_by_lookupcodeService {
    if(!this._ds_get_owner_by_lookupcode) {
      this._ds_get_owner_by_lookupcode = this.injector.get(ExcelProjectImport_ds_get_owner_by_lookupcodeService);
    }
    return this._ds_get_owner_by_lookupcode;
  }
  private _ds_get_owners_by_lookupcode: ExcelProjectImport_ds_get_owners_by_lookupcodeService;
  public get ds_get_owners_by_lookupcode(): ExcelProjectImport_ds_get_owners_by_lookupcodeService {
    if(!this._ds_get_owners_by_lookupcode) {
      this._ds_get_owners_by_lookupcode = this.injector.get(ExcelProjectImport_ds_get_owners_by_lookupcodeService);
    }
    return this._ds_get_owners_by_lookupcode;
  }
  private _ds_get_project_by_lookupcode_and_ownerId: ExcelProjectImport_ds_get_project_by_lookupcode_and_ownerIdService;
  public get ds_get_project_by_lookupcode_and_ownerId(): ExcelProjectImport_ds_get_project_by_lookupcode_and_ownerIdService {
    if(!this._ds_get_project_by_lookupcode_and_ownerId) {
      this._ds_get_project_by_lookupcode_and_ownerId = this.injector.get(ExcelProjectImport_ds_get_project_by_lookupcode_and_ownerIdService);
    }
    return this._ds_get_project_by_lookupcode_and_ownerId;
  }
  private _ds_get_project_by_lookupcode_ownerLookupcode: ExcelProjectImport_ds_get_project_by_lookupcode_ownerLookupcodeService;
  public get ds_get_project_by_lookupcode_ownerLookupcode(): ExcelProjectImport_ds_get_project_by_lookupcode_ownerLookupcodeService {
    if(!this._ds_get_project_by_lookupcode_ownerLookupcode) {
      this._ds_get_project_by_lookupcode_ownerLookupcode = this.injector.get(ExcelProjectImport_ds_get_project_by_lookupcode_ownerLookupcodeService);
    }
    return this._ds_get_project_by_lookupcode_ownerLookupcode;
  }
  private _ds_get_projects_by_lookupcode_ownerLookupcode: ExcelProjectImport_ds_get_projects_by_lookupcode_ownerLookupcodeService;
  public get ds_get_projects_by_lookupcode_ownerLookupcode(): ExcelProjectImport_ds_get_projects_by_lookupcode_ownerLookupcodeService {
    if(!this._ds_get_projects_by_lookupcode_ownerLookupcode) {
      this._ds_get_projects_by_lookupcode_ownerLookupcode = this.injector.get(ExcelProjectImport_ds_get_projects_by_lookupcode_ownerLookupcodeService);
    }
    return this._ds_get_projects_by_lookupcode_ownerLookupcode;
  }
  private _ds_owners_dd: ExcelProjectImport_ds_owners_ddService;
  public get ds_owners_dd(): ExcelProjectImport_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(ExcelProjectImport_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_project_import_grid: ExcelProjectImport_ds_project_import_gridService;
  public get ds_project_import_grid(): ExcelProjectImport_ds_project_import_gridService {
    if(!this._ds_project_import_grid) {
      this._ds_project_import_grid = this.injector.get(ExcelProjectImport_ds_project_import_gridService);
    }
    return this._ds_project_import_grid;
  }
}

