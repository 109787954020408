import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_billing_contract_lines_editorService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { lineId: number }): 
  Promise<{ result: { Id?: number, BasePackageConversion?: boolean, BillingAggregationStrategyId?: number, BillingContractId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomBillingWorkflowId?: string, EndDate?: string, LineNumber?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ResultingBillingCodeId?: number, ResultingUomId?: number, StartDate?: string, WarehouseId?: number, BillingAggregationStrategy?: { Name?: string }, ResultingUom?: { Name?: string }, ResultingBillingCode?: { Name?: string }, Warehouse?: { Name?: string }, BillingRates?: { Rate?: number }[], Details?: { Value?: string }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lineId)) {
      missingRequiredInParams.push('\'lineId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_billing_contract_lines_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
