import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { ExcelInventoryImport_add_inventory_on_existing_licenseplate_flowService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_add_serials_inventory_on_existing_licenseplate_flowService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_create_inventory_on_existing_licenseplate_flowService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_create_inventory_on_new_licenseplate_flowService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_create_lotService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_create_serials_inventory_on_existing_licenseplate_flowService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_create_serials_inventory_on_new_licenseplate_flowService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_create_vendor_lotService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_format_date_stringService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_inventory_import_stagingService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_inventory_import_staging_mainService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_inventory_import_staging_runService } from './ExcelInventoryImport.flow.index';
import { ExcelInventoryImport_parse_date_stringService } from './ExcelInventoryImport.flow.index';

import { $frontendTypes } from './ExcelInventoryImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public ExcelInventoryImport: ExcelInventoryImport_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _add_inventory_on_existing_licenseplate_flow: ExcelInventoryImport_add_inventory_on_existing_licenseplate_flowService;
  public async add_inventory_on_existing_licenseplate_flow(inParams: { licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, reasoncodeId: number, operationcodeId: number }): Promise< { reasons?: string[], confirm?: boolean }> {
    if(!this._add_inventory_on_existing_licenseplate_flow) {
      this._add_inventory_on_existing_licenseplate_flow = this.injector.get(ExcelInventoryImport_add_inventory_on_existing_licenseplate_flowService);
    }
    return this._add_inventory_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _add_serials_inventory_on_existing_licenseplate_flow: ExcelInventoryImport_add_serials_inventory_on_existing_licenseplate_flowService;
  public async add_serials_inventory_on_existing_licenseplate_flow(inParams: { lotId: number, packagingId: number, reasoncodeId: number, operationcodeId: number, licenseplateId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[] }): Promise< { reasons?: string[], confirm?: boolean, licenseplateId?: number }> {
    if(!this._add_serials_inventory_on_existing_licenseplate_flow) {
      this._add_serials_inventory_on_existing_licenseplate_flow = this.injector.get(ExcelInventoryImport_add_serials_inventory_on_existing_licenseplate_flowService);
    }
    return this._add_serials_inventory_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_on_existing_licenseplate_flow: ExcelInventoryImport_create_inventory_on_existing_licenseplate_flowService;
  public async create_inventory_on_existing_licenseplate_flow(inParams: { licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, reasoncodeId: number, operationcodeId: number }): Promise< { reasons?: string[], confirm?: boolean }> {
    if(!this._create_inventory_on_existing_licenseplate_flow) {
      this._create_inventory_on_existing_licenseplate_flow = this.injector.get(ExcelInventoryImport_create_inventory_on_existing_licenseplate_flowService);
    }
    return this._create_inventory_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_on_new_licenseplate_flow: ExcelInventoryImport_create_inventory_on_new_licenseplate_flowService;
  public async create_inventory_on_new_licenseplate_flow(inParams: { licenseplateLookupcode: string, lotId: number, packagingId: number, packagedAmount: number, reasoncodeId: number, operationcodeId: number, locationId: number }): Promise< { reasons?: string[], confirm?: boolean, licenseplateId?: number }> {
    if(!this._create_inventory_on_new_licenseplate_flow) {
      this._create_inventory_on_new_licenseplate_flow = this.injector.get(ExcelInventoryImport_create_inventory_on_new_licenseplate_flowService);
    }
    return this._create_inventory_on_new_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_lot: ExcelInventoryImport_create_lotService;
  public async create_lot(inParams: { vendorLotId: number, lookupCode: string, statusId: number }): Promise< { lotId?: number }> {
    if(!this._create_lot) {
      this._create_lot = this.injector.get(ExcelInventoryImport_create_lotService);
    }
    return this._create_lot.run(inParams);
  }
   
   

   
 
  private _create_serials_inventory_on_existing_licenseplate_flow: ExcelInventoryImport_create_serials_inventory_on_existing_licenseplate_flowService;
  public async create_serials_inventory_on_existing_licenseplate_flow(inParams: { lotId: number, packagingId: number, reasoncodeId: number, operationcodeId: number, licenseplateId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number, Children?: any[] }[] }): Promise< { reasons?: string[], confirm?: boolean, licenseplateId?: number }> {
    if(!this._create_serials_inventory_on_existing_licenseplate_flow) {
      this._create_serials_inventory_on_existing_licenseplate_flow = this.injector.get(ExcelInventoryImport_create_serials_inventory_on_existing_licenseplate_flowService);
    }
    return this._create_serials_inventory_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_serials_inventory_on_new_licenseplate_flow: ExcelInventoryImport_create_serials_inventory_on_new_licenseplate_flowService;
  public async create_serials_inventory_on_new_licenseplate_flow(inParams: { licenseplateLookupcode: string, lotId: number, packagingId: number, reasoncodeId: number, operationcodeId: number, locationId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[] }): Promise< { reasons?: string[], confirm?: boolean, licenseplateId?: number }> {
    if(!this._create_serials_inventory_on_new_licenseplate_flow) {
      this._create_serials_inventory_on_new_licenseplate_flow = this.injector.get(ExcelInventoryImport_create_serials_inventory_on_new_licenseplate_flowService);
    }
    return this._create_serials_inventory_on_new_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_vendor_lot: ExcelInventoryImport_create_vendor_lotService;
  public async create_vendor_lot(inParams: { materialId: number, lookupCode: string, manufactureDate?: string, expirationDate?: string }): Promise< { vendorLotId?: number }> {
    if(!this._create_vendor_lot) {
      this._create_vendor_lot = this.injector.get(ExcelInventoryImport_create_vendor_lotService);
    }
    return this._create_vendor_lot.run(inParams);
  }
   
   

   
 
  private _format_date_string: ExcelInventoryImport_format_date_stringService;
  public async format_date_string(inParams: { dateString: string, fromFormat: string, toFormat: string }): Promise< { dateString?: string }> {
    if(!this._format_date_string) {
      this._format_date_string = this.injector.get(ExcelInventoryImport_format_date_stringService);
    }
    return this._format_date_string.run(inParams);
  }
   
   

   
 
  private _inventory_import_staging: ExcelInventoryImport_inventory_import_stagingService;
  public async inventory_import_staging(inParams: { payload?: $frontendTypes.ExcelInventoryImport.InventoryImportRecord[], action?: string, skip?: number, take?: number, full_text_search?: string, import_status?: string, owner?: string, project?: string, warehouse?: string, location?: string, material?: string, lot?: string, vendor_lot?: string, license_plate?: string, packaging?: string }): Promise< { payload?: $frontendTypes.ExcelInventoryImport.InventoryImportRecord[], success?: boolean, messages?: any[], count?: number }> {
    if(!this._inventory_import_staging) {
      this._inventory_import_staging = this.injector.get(ExcelInventoryImport_inventory_import_stagingService);
    }
    return this._inventory_import_staging.run(inParams);
  }
   
   

   
 
  private _inventory_import_staging_main: ExcelInventoryImport_inventory_import_staging_mainService;
  public async inventory_import_staging_main(inParams: { validation_only?: boolean }): Promise< { success?: boolean, messages?: any[], errors?: number, time?: number, runners?: number }> {
    if(!this._inventory_import_staging_main) {
      this._inventory_import_staging_main = this.injector.get(ExcelInventoryImport_inventory_import_staging_mainService);
    }
    return this._inventory_import_staging_main.run(inParams);
  }
   
   

   
 
  private _inventory_import_staging_run: ExcelInventoryImport_inventory_import_staging_runService;
  public async inventory_import_staging_run(inParams: { InventoryImportRecords?: $frontendTypes.ExcelInventoryImport.InventoryImportRecord[], TransactionId?: string, Owners?: any[], Projects?: any[], Warehouses?: any[], Locations?: any[], Materials?: any[], ReasonCodes?: any[], Weights?: any[], Statuses?: any[] }): Promise< { messages?: any[] }> {
    if(!this._inventory_import_staging_run) {
      this._inventory_import_staging_run = this.injector.get(ExcelInventoryImport_inventory_import_staging_runService);
    }
    return this._inventory_import_staging_run.run(inParams);
  }
   
   

   
 
  private _parse_date_string: ExcelInventoryImport_parse_date_stringService;
  public async parse_date_string(inParams: { date?: string }): Promise< { date?: string }> {
    if(!this._parse_date_string) {
      this._parse_date_string = this.injector.get(ExcelInventoryImport_parse_date_stringService);
    }
    return this._parse_date_string.run(inParams);
  }
   
   

   
}
