import { Component, ElementRef, Input, ViewChild, forwardRef, OnInit } from '@angular/core';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-draw-box',
  templateUrl: './draw-box.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DrawBoxComponent),
      multi: true
    }
  ]
})
export class DrawBoxComponent implements ControlValueAccessor, OnInit {
  @ViewChild('signaturePad') public signaturePad: SignaturePadComponent;

  private _innerValue: string = '';
  isDisabled: boolean = false;
  signaturePadOptions: NgSignaturePadOptions;

  @Input() options: {} = {
    minWidth: 100,
    canvasWidth: 300,
    canvasHeight: 300,
    backgroundColor: '#ffff'
  };

  constructor(
    private _elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.signaturePadOptions =
    {
      minWidth: this.options['minWidth'] ?? 100,
      canvasWidth: this.options['canvasWidth'] ?? 300,
      canvasHeight: this.options['canvasHeight'] ?? 300,
      backgroundColor: this.options['backgroundColor'] ?? '#fff',
      dotSize: 3
    };
  }

  drawComplete(event: MouseEvent | Touch) {
    this.value = this.signaturePad.toDataURL();
  }

  onChange: any = () => { };
  onTouch: any = () => { };

  get value() {
    return this._innerValue;
  }

  set value(val) {
    this._innerValue = val;
    this.onChange(val);
    this.onTouch(val);
  }

  writeValue(value: string): void {
    this._innerValue = value;
    setTimeout(() => {
      if (value) {
        this.signaturePad.fromDataURL(value);
      } else {
        this.signaturePad.clear();
      }
    }, 0);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    setTimeout(() => {
      if (isDisabled) {
        this.signaturePad.off();
      } else {
        this.signaturePad.on();
      }
    }, 0);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  clear(): void {
    this.value = '';
    this.signaturePad.clear();
  }
}
