import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ds_unscheduled_dock_appointments_calendarService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], fullTextSearch?: string, warehouseIds?: number[], timeZoneDates?: { timeZoneId?: string, viewDate?: string }[] }): 
  Promise<{ result: { Id?: number, AssignedOn?: string, CheckedInOn?: string, CompletedOn?: string, InProcessOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, ScheduledProject?: { Id?: number, LookupCode?: string }, Status?: { Id?: number, Name?: string }, AssignedLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string }, convertedAssignedOn?: { id?: number, convertedDate?: string }, convertedInProcessOn?: { id?: number, convertedDate?: string }, convertedCompletedOn?: { id?: number, convertedDate?: string }, order?: { Id?: number, ItemEntityId?: number }, LocalScheduledArrival?: string, LocalScheduledDeparture?: string }[] }> 
  {
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_unscheduled_dock_appointments_calendar/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], fullTextSearch?: string, warehouseIds?: number[], timeZoneDates?: { timeZoneId?: string, viewDate?: string }[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AssignedOn?: string, CheckedInOn?: string, CompletedOn?: string, InProcessOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, ScheduledProject?: { Id?: number, LookupCode?: string }, Status?: { Id?: number, Name?: string }, AssignedLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string }, convertedAssignedOn?: { id?: number, convertedDate?: string }, convertedInProcessOn?: { id?: number, convertedDate?: string }, convertedCompletedOn?: { id?: number, convertedDate?: string }, order?: { Id?: number, ItemEntityId?: number }, LocalScheduledArrival?: string, LocalScheduledDeparture?: string }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_unscheduled_dock_appointments_calendar/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], fullTextSearch?: string, warehouseIds?: number[], timeZoneDates?: { timeZoneId?: string, viewDate?: string }[], $keys: number[] }): 
  Promise<{ result: { Id?: number, AssignedOn?: string, CheckedInOn?: string, CompletedOn?: string, InProcessOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, ScheduledProject?: { Id?: number, LookupCode?: string }, Status?: { Id?: number, Name?: string }, AssignedLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string }, convertedAssignedOn?: { id?: number, convertedDate?: string }, convertedInProcessOn?: { id?: number, convertedDate?: string }, convertedCompletedOn?: { id?: number, convertedDate?: string }, order?: { Id?: number, ItemEntityId?: number }, LocalScheduledArrival?: string, LocalScheduledDeparture?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_unscheduled_dock_appointments_calendar/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
