import { NgModule } from '@angular/core';

import { 
  Routes, 
  RouterModule
} from '@angular/router';

import { 
  MsalGuard, 
  MsalRedirectComponent 
} from '@azure/msal-angular';

import { ShellComponent } from './shell.component';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'error', component: ErrorComponent },
  {
    path: ':view',
    component: ShellComponent,
  },
  {
    /**
     * Needed for login on page load for PathLocationStrategy.
     * See FAQ for details:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/FAQ.md
     */
    path: 'auth',
    component: MsalRedirectComponent
  }
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // enableTracing: !environment.production,
    useHash: false,
    // Don't perform initial navigation in iframes
    // initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled'
    initialNavigation:'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
