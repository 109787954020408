import { Utilities_Types } from './Utilities.frontend.types'

export namespace BarTender_Types {
  /**
   * Contains BarTender configuration names.
   */
  export enum ConfigurationsEnum {
    AzureBlobConnectionString = 'AzureBlobConnectionString',
    AzureBlobRootPath = 'AzureBlobRootPath',
    Printers = 'Printers',
    AzureBlobSasToken = 'AzureBlobSasToken'
  }
}

export namespace $frontendTypes {
  export import Utilities =  Utilities_Types;
  export import BarTender = BarTender_Types;
}




