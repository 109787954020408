import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { LaborManagement_ds_assigned_task_operation_codes_ddService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_assigned_task_operation_codes_flow_ddService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_assigned_user_list_ddService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_equipment_types_ddService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_equipmentService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_task_by_taskIdService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_tasks_by_taskIdsService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_tasks_for_task_assignmentService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_user_assigned_to_tasks_ddService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_users_infoService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_work_class_assignment_by_workClassId_userIdService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_work_class_assignmentsService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_work_class_by_lookupcodeService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_work_class_by_workClassIdService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_get_work_classes_by_operationCodeIdsService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_operation_codes_ddService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_task_assignment_editorService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_task_assignment_gridService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_task_assignment_grid_baseService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_task_assignments_gridService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_user_list_ddService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_warehouses_ddService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_work_class_editorService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_work_class_operation_codes_gridService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_work_class_users_gridService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_work_classes_ddService } from './LaborManagement.datasource.index';
import { LaborManagement_ds_work_classes_gridService } from './LaborManagement.datasource.index';

@Injectable({ providedIn: 'root' })
export class LaborManagement_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public LaborManagement: LaborManagement_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_assigned_task_operation_codes_dd: LaborManagement_ds_assigned_task_operation_codes_ddService;
  public get ds_assigned_task_operation_codes_dd(): LaborManagement_ds_assigned_task_operation_codes_ddService {
    if(!this._ds_assigned_task_operation_codes_dd) {
      this._ds_assigned_task_operation_codes_dd = this.injector.get(LaborManagement_ds_assigned_task_operation_codes_ddService);
    }
    return this._ds_assigned_task_operation_codes_dd;
  }
  private _ds_assigned_task_operation_codes_flow_dd: LaborManagement_ds_assigned_task_operation_codes_flow_ddService;
  public get ds_assigned_task_operation_codes_flow_dd(): LaborManagement_ds_assigned_task_operation_codes_flow_ddService {
    if(!this._ds_assigned_task_operation_codes_flow_dd) {
      this._ds_assigned_task_operation_codes_flow_dd = this.injector.get(LaborManagement_ds_assigned_task_operation_codes_flow_ddService);
    }
    return this._ds_assigned_task_operation_codes_flow_dd;
  }
  private _ds_assigned_user_list_dd: LaborManagement_ds_assigned_user_list_ddService;
  public get ds_assigned_user_list_dd(): LaborManagement_ds_assigned_user_list_ddService {
    if(!this._ds_assigned_user_list_dd) {
      this._ds_assigned_user_list_dd = this.injector.get(LaborManagement_ds_assigned_user_list_ddService);
    }
    return this._ds_assigned_user_list_dd;
  }
  private _ds_equipment_types_dd: LaborManagement_ds_equipment_types_ddService;
  public get ds_equipment_types_dd(): LaborManagement_ds_equipment_types_ddService {
    if(!this._ds_equipment_types_dd) {
      this._ds_equipment_types_dd = this.injector.get(LaborManagement_ds_equipment_types_ddService);
    }
    return this._ds_equipment_types_dd;
  }
  private _ds_get_equipment: LaborManagement_ds_get_equipmentService;
  public get ds_get_equipment(): LaborManagement_ds_get_equipmentService {
    if(!this._ds_get_equipment) {
      this._ds_get_equipment = this.injector.get(LaborManagement_ds_get_equipmentService);
    }
    return this._ds_get_equipment;
  }
  private _ds_get_task_by_taskId: LaborManagement_ds_get_task_by_taskIdService;
  public get ds_get_task_by_taskId(): LaborManagement_ds_get_task_by_taskIdService {
    if(!this._ds_get_task_by_taskId) {
      this._ds_get_task_by_taskId = this.injector.get(LaborManagement_ds_get_task_by_taskIdService);
    }
    return this._ds_get_task_by_taskId;
  }
  private _ds_get_tasks_by_taskIds: LaborManagement_ds_get_tasks_by_taskIdsService;
  public get ds_get_tasks_by_taskIds(): LaborManagement_ds_get_tasks_by_taskIdsService {
    if(!this._ds_get_tasks_by_taskIds) {
      this._ds_get_tasks_by_taskIds = this.injector.get(LaborManagement_ds_get_tasks_by_taskIdsService);
    }
    return this._ds_get_tasks_by_taskIds;
  }
  private _ds_get_tasks_for_task_assignment: LaborManagement_ds_get_tasks_for_task_assignmentService;
  public get ds_get_tasks_for_task_assignment(): LaborManagement_ds_get_tasks_for_task_assignmentService {
    if(!this._ds_get_tasks_for_task_assignment) {
      this._ds_get_tasks_for_task_assignment = this.injector.get(LaborManagement_ds_get_tasks_for_task_assignmentService);
    }
    return this._ds_get_tasks_for_task_assignment;
  }
  private _ds_get_user_assigned_to_tasks_dd: LaborManagement_ds_get_user_assigned_to_tasks_ddService;
  public get ds_get_user_assigned_to_tasks_dd(): LaborManagement_ds_get_user_assigned_to_tasks_ddService {
    if(!this._ds_get_user_assigned_to_tasks_dd) {
      this._ds_get_user_assigned_to_tasks_dd = this.injector.get(LaborManagement_ds_get_user_assigned_to_tasks_ddService);
    }
    return this._ds_get_user_assigned_to_tasks_dd;
  }
  private _ds_get_users_info: LaborManagement_ds_get_users_infoService;
  public get ds_get_users_info(): LaborManagement_ds_get_users_infoService {
    if(!this._ds_get_users_info) {
      this._ds_get_users_info = this.injector.get(LaborManagement_ds_get_users_infoService);
    }
    return this._ds_get_users_info;
  }
  private _ds_get_work_class_assignment_by_workClassId_userId: LaborManagement_ds_get_work_class_assignment_by_workClassId_userIdService;
  public get ds_get_work_class_assignment_by_workClassId_userId(): LaborManagement_ds_get_work_class_assignment_by_workClassId_userIdService {
    if(!this._ds_get_work_class_assignment_by_workClassId_userId) {
      this._ds_get_work_class_assignment_by_workClassId_userId = this.injector.get(LaborManagement_ds_get_work_class_assignment_by_workClassId_userIdService);
    }
    return this._ds_get_work_class_assignment_by_workClassId_userId;
  }
  private _ds_get_work_class_assignments: LaborManagement_ds_get_work_class_assignmentsService;
  public get ds_get_work_class_assignments(): LaborManagement_ds_get_work_class_assignmentsService {
    if(!this._ds_get_work_class_assignments) {
      this._ds_get_work_class_assignments = this.injector.get(LaborManagement_ds_get_work_class_assignmentsService);
    }
    return this._ds_get_work_class_assignments;
  }
  private _ds_get_work_class_by_lookupcode: LaborManagement_ds_get_work_class_by_lookupcodeService;
  public get ds_get_work_class_by_lookupcode(): LaborManagement_ds_get_work_class_by_lookupcodeService {
    if(!this._ds_get_work_class_by_lookupcode) {
      this._ds_get_work_class_by_lookupcode = this.injector.get(LaborManagement_ds_get_work_class_by_lookupcodeService);
    }
    return this._ds_get_work_class_by_lookupcode;
  }
  private _ds_get_work_class_by_workClassId: LaborManagement_ds_get_work_class_by_workClassIdService;
  public get ds_get_work_class_by_workClassId(): LaborManagement_ds_get_work_class_by_workClassIdService {
    if(!this._ds_get_work_class_by_workClassId) {
      this._ds_get_work_class_by_workClassId = this.injector.get(LaborManagement_ds_get_work_class_by_workClassIdService);
    }
    return this._ds_get_work_class_by_workClassId;
  }
  private _ds_get_work_classes_by_operationCodeIds: LaborManagement_ds_get_work_classes_by_operationCodeIdsService;
  public get ds_get_work_classes_by_operationCodeIds(): LaborManagement_ds_get_work_classes_by_operationCodeIdsService {
    if(!this._ds_get_work_classes_by_operationCodeIds) {
      this._ds_get_work_classes_by_operationCodeIds = this.injector.get(LaborManagement_ds_get_work_classes_by_operationCodeIdsService);
    }
    return this._ds_get_work_classes_by_operationCodeIds;
  }
  private _ds_operation_codes_dd: LaborManagement_ds_operation_codes_ddService;
  public get ds_operation_codes_dd(): LaborManagement_ds_operation_codes_ddService {
    if(!this._ds_operation_codes_dd) {
      this._ds_operation_codes_dd = this.injector.get(LaborManagement_ds_operation_codes_ddService);
    }
    return this._ds_operation_codes_dd;
  }
  private _ds_task_assignment_editor: LaborManagement_ds_task_assignment_editorService;
  public get ds_task_assignment_editor(): LaborManagement_ds_task_assignment_editorService {
    if(!this._ds_task_assignment_editor) {
      this._ds_task_assignment_editor = this.injector.get(LaborManagement_ds_task_assignment_editorService);
    }
    return this._ds_task_assignment_editor;
  }
  private _ds_task_assignment_grid: LaborManagement_ds_task_assignment_gridService;
  public get ds_task_assignment_grid(): LaborManagement_ds_task_assignment_gridService {
    if(!this._ds_task_assignment_grid) {
      this._ds_task_assignment_grid = this.injector.get(LaborManagement_ds_task_assignment_gridService);
    }
    return this._ds_task_assignment_grid;
  }
  private _ds_task_assignment_grid_base: LaborManagement_ds_task_assignment_grid_baseService;
  public get ds_task_assignment_grid_base(): LaborManagement_ds_task_assignment_grid_baseService {
    if(!this._ds_task_assignment_grid_base) {
      this._ds_task_assignment_grid_base = this.injector.get(LaborManagement_ds_task_assignment_grid_baseService);
    }
    return this._ds_task_assignment_grid_base;
  }
  private _ds_task_assignments_grid: LaborManagement_ds_task_assignments_gridService;
  public get ds_task_assignments_grid(): LaborManagement_ds_task_assignments_gridService {
    if(!this._ds_task_assignments_grid) {
      this._ds_task_assignments_grid = this.injector.get(LaborManagement_ds_task_assignments_gridService);
    }
    return this._ds_task_assignments_grid;
  }
  private _ds_user_list_dd: LaborManagement_ds_user_list_ddService;
  public get ds_user_list_dd(): LaborManagement_ds_user_list_ddService {
    if(!this._ds_user_list_dd) {
      this._ds_user_list_dd = this.injector.get(LaborManagement_ds_user_list_ddService);
    }
    return this._ds_user_list_dd;
  }
  private _ds_warehouses_dd: LaborManagement_ds_warehouses_ddService;
  public get ds_warehouses_dd(): LaborManagement_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(LaborManagement_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
  private _ds_work_class_editor: LaborManagement_ds_work_class_editorService;
  public get ds_work_class_editor(): LaborManagement_ds_work_class_editorService {
    if(!this._ds_work_class_editor) {
      this._ds_work_class_editor = this.injector.get(LaborManagement_ds_work_class_editorService);
    }
    return this._ds_work_class_editor;
  }
  private _ds_work_class_operation_codes_grid: LaborManagement_ds_work_class_operation_codes_gridService;
  public get ds_work_class_operation_codes_grid(): LaborManagement_ds_work_class_operation_codes_gridService {
    if(!this._ds_work_class_operation_codes_grid) {
      this._ds_work_class_operation_codes_grid = this.injector.get(LaborManagement_ds_work_class_operation_codes_gridService);
    }
    return this._ds_work_class_operation_codes_grid;
  }
  private _ds_work_class_users_grid: LaborManagement_ds_work_class_users_gridService;
  public get ds_work_class_users_grid(): LaborManagement_ds_work_class_users_gridService {
    if(!this._ds_work_class_users_grid) {
      this._ds_work_class_users_grid = this.injector.get(LaborManagement_ds_work_class_users_gridService);
    }
    return this._ds_work_class_users_grid;
  }
  private _ds_work_classes_dd: LaborManagement_ds_work_classes_ddService;
  public get ds_work_classes_dd(): LaborManagement_ds_work_classes_ddService {
    if(!this._ds_work_classes_dd) {
      this._ds_work_classes_dd = this.injector.get(LaborManagement_ds_work_classes_ddService);
    }
    return this._ds_work_classes_dd;
  }
  private _ds_work_classes_grid: LaborManagement_ds_work_classes_gridService;
  public get ds_work_classes_grid(): LaborManagement_ds_work_classes_gridService {
    if(!this._ds_work_classes_grid) {
      this._ds_work_classes_grid = this.injector.get(LaborManagement_ds_work_classes_gridService);
    }
    return this._ds_work_classes_grid;
  }
}

