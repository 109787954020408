import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Inspections_ds_get_inspection_operation_codeService } from './Inspections.datasource.index';
import { Inspections_ds_inspection_operation_codes_ddService } from './Inspections.datasource.index';
import { Inspections_ds_inspection_operation_codes_gridService } from './Inspections.datasource.index';
import { Inspections_ds_inspection_task_editorService } from './Inspections.datasource.index';
import { Inspections_ds_inspection_tasks_gridService } from './Inspections.datasource.index';
import { Inspections_ds_reason_codes_ddService } from './Inspections.datasource.index';
import { Inspections_ds_task_statuses_ddService } from './Inspections.datasource.index';

@Injectable({ providedIn: 'root' })
export class Inspections_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Inspections: Inspections_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_inspection_operation_code: Inspections_ds_get_inspection_operation_codeService;
  public get ds_get_inspection_operation_code(): Inspections_ds_get_inspection_operation_codeService {
    if(!this._ds_get_inspection_operation_code) {
      this._ds_get_inspection_operation_code = this.injector.get(Inspections_ds_get_inspection_operation_codeService);
    }
    return this._ds_get_inspection_operation_code;
  }
  private _ds_inspection_operation_codes_dd: Inspections_ds_inspection_operation_codes_ddService;
  public get ds_inspection_operation_codes_dd(): Inspections_ds_inspection_operation_codes_ddService {
    if(!this._ds_inspection_operation_codes_dd) {
      this._ds_inspection_operation_codes_dd = this.injector.get(Inspections_ds_inspection_operation_codes_ddService);
    }
    return this._ds_inspection_operation_codes_dd;
  }
  private _ds_inspection_operation_codes_grid: Inspections_ds_inspection_operation_codes_gridService;
  public get ds_inspection_operation_codes_grid(): Inspections_ds_inspection_operation_codes_gridService {
    if(!this._ds_inspection_operation_codes_grid) {
      this._ds_inspection_operation_codes_grid = this.injector.get(Inspections_ds_inspection_operation_codes_gridService);
    }
    return this._ds_inspection_operation_codes_grid;
  }
  private _ds_inspection_task_editor: Inspections_ds_inspection_task_editorService;
  public get ds_inspection_task_editor(): Inspections_ds_inspection_task_editorService {
    if(!this._ds_inspection_task_editor) {
      this._ds_inspection_task_editor = this.injector.get(Inspections_ds_inspection_task_editorService);
    }
    return this._ds_inspection_task_editor;
  }
  private _ds_inspection_tasks_grid: Inspections_ds_inspection_tasks_gridService;
  public get ds_inspection_tasks_grid(): Inspections_ds_inspection_tasks_gridService {
    if(!this._ds_inspection_tasks_grid) {
      this._ds_inspection_tasks_grid = this.injector.get(Inspections_ds_inspection_tasks_gridService);
    }
    return this._ds_inspection_tasks_grid;
  }
  private _ds_reason_codes_dd: Inspections_ds_reason_codes_ddService;
  public get ds_reason_codes_dd(): Inspections_ds_reason_codes_ddService {
    if(!this._ds_reason_codes_dd) {
      this._ds_reason_codes_dd = this.injector.get(Inspections_ds_reason_codes_ddService);
    }
    return this._ds_reason_codes_dd;
  }
  private _ds_task_statuses_dd: Inspections_ds_task_statuses_ddService;
  public get ds_task_statuses_dd(): Inspections_ds_task_statuses_ddService {
    if(!this._ds_task_statuses_dd) {
      this._ds_task_statuses_dd = this.injector.get(Inspections_ds_task_statuses_ddService);
    }
    return this._ds_task_statuses_dd;
  }
}

