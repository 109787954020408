import { Injectable } from '@angular/core';
import { DatexErrorInfo } from './datex-error-info';

@Injectable({
  providedIn: 'root',
})
export class DatexErrorService {
  public errors: DatexErrorInfo[];
  public hasErrors = false;

  constructor() {
    this.errors = [];
  }

  public add(error: any) {
    this.hasErrors = true;
    this.errors.push(new DatexErrorInfo(error));
  }

  public clear() {
    this.hasErrors = false;
    this.errors = [];
  }
}
