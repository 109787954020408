import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { PurchaseOrders_purchase_order_date_types_dd_singleComponent } from './PurchaseOrders.purchase_order_date_types_dd_single.component'


interface IFootPrintManager_return_orders_gridComponentEntity {
Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierService?: { CarrierId?: number }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string } } }[]}

interface IFootPrintManager_return_orders_gridComponentInParams {
  ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, blindShippingContainerId?: number}


class FootPrintManager_return_orders_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_return_orders_gridComponent;
  entity: IFootPrintManager_return_orders_gridComponentEntity;

  vars: { hasCompletedReceivingTasks?: boolean, hasRemainingReceivingTasks?: boolean, dockAppointmentId?: number, failActionReasons?: { cancelReasons?: string[], completeReasons?: string[], deleteReasons?: string[], revertReasons?: string[], processReasons?: string[], unreceiveReasons?: string[], autoReceiveReasons?: string[], validateReasons?: string[] } } = { };

  options: { on_row_edit_order: ButtonModel, print_receiving_report: ButtonModel } = {
    on_row_edit_order: new ButtonModel('on_row_edit_order', new ButtonStyles(null, null), false, 'Edit order', '')
,print_receiving_report: new ButtonModel('print_receiving_report', new ButtonStyles(null, null), false, 'Print receiving report', '')

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    orderclass: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    total_items: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    appointment: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    created_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'datetime', 'l, LT')
,
null
      ),
    expected_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'datetime', 'l, LT')
,
null
      ),
    vendor_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    carrier: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ship_from: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    validation_result: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    validation_notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_return_orders_gridComponent, entity: IFootPrintManager_return_orders_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_return_orders_gridComponent, entity?: IFootPrintManager_return_orders_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      fromDate:  $grid.filters.from_date.control.value ,
      toDate:  $grid.filters.to_date.control.value ,
      orderClassIds:  $grid.inParams.orderClassIds ,
      dateType:  $grid.filters.date_type.control.value ,
      reference:  $grid.inParams.reference ,
      blindShippingContainerId:  $grid.inParams.blindShippingContainerId ,
      orderIds:  null ,
      take:  null ,
      skip:  null ,
    };
    const data = await this.datasources.Returns.ds_return_orders_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.orderclass.displayControl as TextModel).text = $row.entity.OrderClass?.LookupCode;
    (this.cells.vendor_reference.displayControl as TextModel).text = $row.entity.VendorReference;
    (this.cells.reference.displayControl as TextModel).text = $row.entity.OwnerReference;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.ShipmentOrderLookups[0]?.Shipment?.ActualWarehouse?.Name ?? $row.entity.ShipmentOrderLookups[0]?.Shipment?.ExpectedWarehouse?.Name ?? $row.entity.PreferredWarehouse?.Name;
    (this.cells.carrier.displayControl as TextModel).text = $row.entity.PreferredCarrier?.Name;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
      case 'appointment' : {
        this.on_appointment_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: FootPrintManager_return_orders_gridComponentRowModel,
  $grid: FootPrintManager_return_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  if ($row.entity.OrderClass.LookupCode.toUpperCase().trim() === 'INRMA') {
      $shell.FootPrintManager.openreturn_order_editor({ orderId: $row.entity.Id });
  } else {
      $shell.FootPrintManager.openpurchase_order_editor({ orderId: $row.entity.Id });
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_return_orders_gridComponentRowModel,
  $grid: FootPrintManager_return_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Apply date format
  if ($utils.isDefined($row.entity.RequestedDeliveryDate)) {
      var expectedDate = $row.entity.RequestedDeliveryDate;
      $row.cells.expected_date.displayControl.text = expectedDate;
  }
  
  if ($utils.isDefined($row.entity.CreatedSysDateTime)) {
      var createdDate = $row.entity.CreatedSysDateTime;
      $row.cells.created_date.displayControl.text = createdDate;
  }
  
  // Apply custom project string logic
  if ($row.entity.Project?.LookupCode === $row.entity.Project?.Name) {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode
  } else {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode + ' ' + $row.entity.Project.Name
  }
  
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $row: FootPrintManager_return_orders_gridComponentRowModel,
  $grid: FootPrintManager_return_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  if ($utils.isDefined($row.vars.dockAppointmentId)) {
  
      if ($utils.isDefined($row.entity.PreferredWarehouseId)) {
          const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
              dockAppointmentId: $row.vars.dockAppointmentId
          });
          if ($utils.isDefined(dialogResult)) {
              const userConfirm = dialogResult.confirm;
              if (userConfirm) {
                  $grid.refresh();
              }
          }
  
      }
      else {
          throw new Error('Unable to determined preferred warehouse from the selected order.')
      }
  
  }
  else {
      
      // Check if the selected order is in a rejected status and throw an error.
      if ($row.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      if ($utils.isDefined($row.entity.PreferredWarehouseId)) {
          const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
          const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
  
              warehouseId: [$row.entity.PreferredWarehouseId],
              lookupcode: nextAppointmentId.toString(),
              scheduledArrival: $utils.isDefined($row.entity.RequestedDeliveryDate) ? $row.entity.RequestedDeliveryDate : $utils.date.now(),
              scheduledDeparture: $utils.isDefined($row.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $row.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
              typeId: 1,
              ownerId: $row.entity.Project.OwnerId,
              projectId: $row.entity.ProjectId,
              carrierId: $row.entity.PreferredCarrierId,
              orderId: $row.entity.Id
          });
  
          if ($utils.isDefined(dialogResult)) {
              const userConfirm = dialogResult.confirm;
              if (userConfirm) {
                  $grid.refresh();
              }
          }
      }
  
      else {
          throw new Error('Unable to determined preferred warehouse from the selected order.')
      }
  
  }
  }
  on_row_edit_order(event = null) {
    return this.on_row_edit_orderInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_edit_orderInternal(
    $row: FootPrintManager_return_orders_gridComponentRowModel,
  $grid: FootPrintManager_return_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $shell.FootPrintManager.openreturn_order_editor({ orderId: $row.entity.Id });
  
  
  
  }
  on_row_print_receiving_report(event = null) {
    return this.on_row_print_receiving_reportInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_print_receiving_reportInternal(
    $row: FootPrintManager_return_orders_gridComponentRowModel,
  $grid: FootPrintManager_return_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
   await $shell.Returns.openErrorDialog('Not yet implemented', 'The print functionality for this grid is not currently supported.');
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => PurchaseOrders_purchase_order_date_types_dd_singleComponent),
  ],
  selector: 'FootPrintManager-return_orders_grid',
  templateUrl: './FootPrintManager.return_orders_grid.component.html'
})
export class FootPrintManager_return_orders_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_return_orders_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_return_orders_gridComponentInParams = { ownerId: null, projectId: null, warehouseIds: [], statusIds: [], orderClassIds: [], reference: null, blindShippingContainerId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       orderclass: new GridHeaderModel(new HeaderStyles(null, null), 'Order class', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       total_items: new GridHeaderModel(new HeaderStyles(null, null), 'Total items', false, false, null),       appointment: new GridHeaderModel(new HeaderStyles(null, null), 'Appointment', false, false, null),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'Created', false, false, null),       expected_date: new GridHeaderModel(new HeaderStyles(null, null), 'Expected', false, false, null),       vendor_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor reference', false, false, null),       reference: new GridHeaderModel(new HeaderStyles(null, null), 'Owner reference', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       carrier: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier', false, false, null),       ship_from: new GridHeaderModel(new HeaderStyles(null, null), 'Ship from', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       validation_result: new GridHeaderModel(new HeaderStyles(null, null), 'Validation result', false, false, null),       validation_notes: new GridHeaderModel(new HeaderStyles(null, null), 'Validation notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_return_orders_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('orderClassIds') set $inParams_orderClassIds(value: any) {
    this.inParams['orderClassIds'] = value;
  }
  get $inParams_orderClassIds(): any {
    return this.inParams['orderClassIds'] ;
  }
  @Input('reference') set $inParams_reference(value: any) {
    this.inParams['reference'] = value;
  }
  get $inParams_reference(): any {
    return this.inParams['reference'] ;
  }
  @Input('blindShippingContainerId') set $inParams_blindShippingContainerId(value: any) {
    this.inParams['blindShippingContainerId'] = value;
  }
  get $inParams_blindShippingContainerId(): any {
    return this.inParams['blindShippingContainerId'] ;
  }

  topToolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, 'Process', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular')
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_20_regular')
    ),
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, 'Update', 'icon-ic_fluent_edit_20_filled')
    ),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      validate: new ToolModel(new ButtonModel('validate', new ButtonStyles(null, null), false, 'Validate', 'icon-ic_fluent_clipboard_checkmark_20_regular')
    ),
      auto_receive: new ToolModel(new ButtonModel('auto_receive', new ButtonStyles(null, null), false, 'Auto receive', 'icon-datex-Receive')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular')
    ),
      revert: new ToolModel(new ButtonModel('revert', new ButtonStyles(null, null), false, 'Revert', 'icon-ic_fluent_square_hint_arrow_back_20_regular')
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_print_20_regular')
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, 'l, LT', 'datetime')
, new ControlContainerStyles(null, null), 'From date', false)
,
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, 'l, LT', 'datetime')
, new ControlContainerStyles(null, null), 'To date', false)
,
    date_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Date type', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Return orders';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.date_type.control as SelectBoxModel).reset('CreatedSysDateTime');

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      fromDate:  $grid.filters.from_date.control.value ,
      toDate:  $grid.filters.to_date.control.value ,
      orderClassIds:  $grid.inParams.orderClassIds ,
      dateType:  $grid.filters.date_type.control.value ,
      reference:  $grid.inParams.reference ,
      blindShippingContainerId:  $grid.inParams.blindShippingContainerId ,
      orderIds:  null ,
      take:  null ,
      skip:  null ,
    };
    try {
    const data = await this.datasources.Returns.ds_return_orders_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_return_orders_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.process.control.readOnly = true;
  $grid.topToolbar.cancel.control.readOnly = true;
  $grid.topToolbar.on_delete.control.readOnly = true;
  $grid.topToolbar.complete.control.readOnly = true;
  $grid.topToolbar.update.control.readOnly = true;
  $grid.topToolbar.auto_receive.control.readOnly = true;
  $grid.topToolbar.validate.control.readOnly = true;
  $grid.topToolbar.revert.control.readOnly = true;
  
  // Only one row selected
  if ($grid.selectedRows.length === 1) {
      $grid.topToolbar.attachments.control.readOnly = false;
  }
  
  // One or more rows selected
  if ($grid.selectedRows.length > 0) {
      //let orderStates = (await $flows.Returns.get_return_orders_state_flow({ orderIds: $grid.selectedRows.map(row => row.entity.Id) })).orderStates;
  
      // Process
      if (!$grid.selectedRows.find(row => $utils.isDefined(row.vars.failActionReasons.processReasons))) { $grid.topToolbar.process.control.readOnly = false; }
  
      // Cancel
      if (!$grid.selectedRows.find(row => $utils.isDefined(row.vars.failActionReasons.cancelReasons))) { $grid.topToolbar.cancel.control.readOnly = false; }
  
      // Delete
      if (!$grid.selectedRows.find(row => $utils.isDefined(row.vars.failActionReasons.deleteReasons))) { $grid.topToolbar.on_delete.control.readOnly = false; }
  
      // Complete
      if (!$grid.selectedRows.find(row => $utils.isDefined(row.vars.failActionReasons.completeReasons))) { $grid.topToolbar.complete.control.readOnly = false; }
  
      // Update
      $grid.topToolbar.update.control.readOnly = false;
  
      // AutoReceive
      if (!$grid.selectedRows.find(row => $utils.isDefined(row.vars.failActionReasons.autoReceiveReasons))) { $grid.topToolbar.auto_receive.control.readOnly = false; }
  
      // Validate
      if (!$grid.selectedRows.find(row => $utils.isDefined(row.vars.failActionReasons.validateReasons))) { $grid.topToolbar.validate.control.readOnly = false; }
  
      // Revert
      if (!$grid.selectedRows.find(row => $utils.isDefined(row.vars.failActionReasons.revertReasons))) { $grid.topToolbar.revert.control.readOnly = false; }
  }
  
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let actionName = 'Process';
  
  let contextualActionName = actionName.replace(/(e)$/, '');
  contextualActionName = contextualActionName.charAt(0).toLowerCase() + contextualActionName.slice(1);
  
  let pluralChar = '';
  let failedOrders: { orderId: number, orderCode: string, reasons: string[] }[] = [];
  let successfulOrders: { orderId: number, orderCode: string }[] = [];
  
  try {
      if ($grid.selectedRows.length === 0) {
          throw new Error('No orders selected.');
      }
  
      if ($grid.selectedRows.length > 1) { pluralChar = 's'; }
  
      var orderStates = (await $flows.Returns.get_return_orders_state_flow({ orderIds: $grid.selectedRows.map(row => row.entity.Id) })).orderStates;
  
      failedOrders = orderStates.filter(os => $utils.isDefined(os.failActionReasons.processReasons)).map(os => ({ orderId: os.orderId, orderCode: $grid.rows.find(row => row.entity.Id === os.orderId).entity.LookupCode, reasons: os.failActionReasons.processReasons }));
  
      // Throw error if no orders can continue
      if (failedOrders.length === $grid.selectedRows.length) {
          throw new Error(`No orders can be ${contextualActionName}ed`);
      }
  
      let message = failedOrders.length > 0 ? `Not all orders can be ${contextualActionName}ed. Continue?` : 
                          $grid.selectedRows.length > 1 ? `${actionName} selected orders?` : `Are you sure you want to ${actionName.toLowerCase()} order ${$grid.selectedRows[0].entity.LookupCode}?`;
  
      let isConfirm = (await $shell.FootPrintManager.openConfirmationDialog(`${actionName} order${pluralChar}`, message, 'Yes', 'No'))
  
      if (isConfirm) {
          for (let row of $grid.selectedRows) {
              if (!failedOrders.find(o => o.orderId === row.entity.Id)) {
                  // Get promises
                  if ($utils.isDefined(promises)) {
                      promises.push(await $flows.Returns.process_return_order_flow({ orderId: row.entity.Id }))
                  }
                  else {
                      var promises = [await $flows.Returns.process_return_order_flow({ orderId: row.entity.Id })]
                  }
              }
          }
  
          // Check results
          await Promise.all(promises)
              .then(results => {
                  results.forEach(result => {
                      if ($utils.isDefined(result.reasons)) {
                          failedOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode, reasons: result.reasons });
                      }
                      else {
                          successfulOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode });
                      }
                  })
              });
  
          // Return error message
          await showResults(successfulOrders, failedOrders, contextualActionName);
  
          $grid.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog(`Error ${contextualActionName}ing order${pluralChar}`, targetError.message, null, failedOrders.length > 0 ? 'Errors' : null, failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') })));
  }
  
  
  /***************************************
   * FUNCTIONS
  ****************************************/
  async function showResults(successfulOrders: any[], failedOrders: any[], actionName: string) {
      let title: string;
      let message: string;
  
      if (successfulOrders.length + failedOrders.length === 1) {
          if (successfulOrders.length > 0) {
              title = `Successfully ${actionName}ed order ${successfulOrders[0].orderCode}`;
          }
          else {
              title = `Error ${actionName}ing order ${failedOrders[0].orderCode}`;
              message = (await $flows.Utilities.grammar_format_string_array_flow({ values: failedOrders[0].reasons })).formattedValue;
          }
      }
      else {
          if (successfulOrders.length > 0) {
              if (failedOrders.length > 0) {
                  title = `Error ${actionName}ing some orders`;
              }
              else {
                  title = `Successfully ${actionName}ed all orders`;
              }
  
              message = `Successfully ${actionName}ed ${successfulOrders.length} orders`;
          }
          else {
              title = `Error ${actionName}ing all orders`;
              message = `No orders could be ${actionName}ed`
          }
      }
  
      if (failedOrders.length > 0) {
          await $shell.FootPrintManager.openErrorDialog(
              title,
              message,
              successfulOrders.length > 7 ? null : successfulOrders.map(o => o.orderCode),
              'Errors',
              failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') }))
          );
      }
      else {
          await $shell.FootPrintManager.openInfoDialog(title, message);
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let actionName = 'Cancel';
  
  let contextualActionName = actionName.replace(/(e)$/, '');
  contextualActionName = contextualActionName.charAt(0).toLowerCase() + contextualActionName.slice(1);
  
  let pluralChar = '';
  let failedOrders: { orderId: number, orderCode: string, reasons: string[] }[] = [];
  let successfulOrders: { orderId: number, orderCode: string }[] = [];
  
  try {
      if ($grid.selectedRows.length === 0) {
          throw new Error('No orders selected.');
      }
  
      if ($grid.selectedRows.length > 1) { pluralChar = 's'; }
  
      var orderStates = (await $flows.Returns.get_return_orders_state_flow({ orderIds: $grid.selectedRows.map(row => row.entity.Id) })).orderStates;
  
      failedOrders = orderStates.filter(os => $utils.isDefined(os.failActionReasons.cancelReasons)).map(os => ({ orderId: os.orderId, orderCode: $grid.rows.find(row => row.entity.Id === os.orderId).entity.LookupCode, reasons: os.failActionReasons.cancelReasons }));
  
      // Throw error if no orders can continue
      if (failedOrders.length === $grid.selectedRows.length) {
          throw new Error(`No orders can be ${contextualActionName}ed`);
      }
  
      let message = failedOrders.length > 0 ? `Not all orders can be ${contextualActionName}ed. Continue?` : 
                          $grid.selectedRows.length > 1 ? `${actionName} selected orders?` : `Are you sure you want to ${actionName.toLowerCase()} order ${$grid.selectedRows[0].entity.LookupCode}?`;
  
      let isConfirm = (await $shell.FootPrintManager.openConfirmationDialog(`${actionName} order${pluralChar}`, message, 'Yes', 'No'))
  
      if (isConfirm) {
          for (let row of $grid.selectedRows) {
              if (!failedOrders.find(o => o.orderId === row.entity.Id)) {
                  // Get promises
                  if ($utils.isDefined(promises)) {
                      promises.push(await $flows.Returns.cancel_return_order_flow({ orderId: row.entity.Id }))
                  }
                  else {
                      var promises = [await $flows.Returns.cancel_return_order_flow({ orderId: row.entity.Id })]
                  }
              }
          }
  
          // Check results
          await Promise.all(promises)
              .then(results => {
                  results.forEach(result => {
                      if ($utils.isDefined(result.reasons)) {
                          failedOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode, reasons: result.reasons });
                      }
                      else {
                          successfulOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode });
                      }
                  })
              });
  
          // Return error message
          await showResults(successfulOrders, failedOrders, contextualActionName);
  
          $grid.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog(`Error ${contextualActionName}ing order${pluralChar}`, targetError.message, null, failedOrders.length > 0 ? 'Errors' : null, failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') })));
  }
  
  
  /***************************************
   * FUNCTIONS
  ****************************************/
  async function showResults(successfulOrders: any[], failedOrders: any[], actionName: string) {
      let title: string;
      let message: string;
  
      if (successfulOrders.length + failedOrders.length === 1) {
          if (successfulOrders.length > 0) {
              title = `Successfully ${actionName}ed order ${successfulOrders[0].orderCode}`;
          }
          else {
              title = `Error ${actionName}ing order ${failedOrders[0].orderCode}`;
              message = (await $flows.Utilities.grammar_format_string_array_flow({ values: failedOrders[0].reasons })).formattedValue;
          }
      }
      else {
          if (successfulOrders.length > 0) {
              if (failedOrders.length > 0) {
                  title = `Error ${actionName}ing some orders`;
              }
              else {
                  title = `Successfully ${actionName}ed all orders`;
              }
  
              message = `Successfully ${actionName}ed ${successfulOrders.length} orders`;
          }
          else {
              title = `Error ${actionName}ing all orders`;
              message = `No orders could be ${actionName}ed`
          }
      }
  
      if (failedOrders.length > 0) {
          await $shell.FootPrintManager.openErrorDialog(
              title,
              message,
              successfulOrders.length > 7 ? null : successfulOrders.map(o => o.orderCode),
              'Errors',
              failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') }))
          );
      }
      else {
          await $shell.FootPrintManager.openInfoDialog(title, message);
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let actionName = 'Complete';
  
  let contextualActionName = actionName.replace(/(e)$/, '');
  contextualActionName = contextualActionName.charAt(0).toLowerCase() + contextualActionName.slice(1);
  
  let pluralChar = '';
  let failedOrders: { orderId: number, orderCode: string, reasons: string[] }[] = [];
  let successfulOrders: { orderId: number, orderCode: string }[] = [];
  
  try {
      if ($grid.selectedRows.length === 0) {
          throw new Error('No orders selected.');
      }
  
      if ($grid.selectedRows.length > 1) { pluralChar = 's'; }
  
      var orderStates = (await $flows.Returns.get_return_orders_state_flow({ orderIds: $grid.selectedRows.map(row => row.entity.Id) })).orderStates;
  
      failedOrders = orderStates.filter(os => $utils.isDefined(os.failActionReasons.completeReasons)).map(os => ({ orderId: os.orderId, orderCode: $grid.rows.find(row => row.entity.Id === os.orderId).entity.LookupCode, reasons: os.failActionReasons.completeReasons }));
  
      // Throw error if no orders can continue
      if (failedOrders.length === $grid.selectedRows.length) {
          throw new Error(`No orders can be ${contextualActionName}ed`);
      }
  
      let message = failedOrders.length > 0 ? `Not all orders can be ${contextualActionName}ed. Continue?` : 
                          $grid.selectedRows.length > 1 ? `${actionName} selected orders?` : `Are you sure you want to ${actionName.toLowerCase()} order ${$grid.selectedRows[0].entity.LookupCode}?`;
  
      let isConfirm = (await $shell.FootPrintManager.openConfirmationDialog(`${actionName} order${pluralChar}`, message, 'Yes', 'No'))
  
      if (isConfirm) {
          for (let row of $grid.selectedRows) {
              if (!failedOrders.find(o => o.orderId === row.entity.Id)) {
                  // Get promises
                  if ($utils.isDefined(promises)) {
                      promises.push(await $flows.Returns.complete_return_order_flow({ orderId: row.entity.Id }))
                  }
                  else {
                      var promises = [await $flows.Returns.complete_return_order_flow({ orderId: row.entity.Id })]
                  }
              }
          }
  
          // Check results
          await Promise.all(promises)
              .then(results => {
                  results.forEach(result => {
                      if ($utils.isDefined(result.reasons)) {
                          failedOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode, reasons: result.reasons });
                      }
                      else {
                          successfulOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode });
                      }
                  })
              });
  
          // Return error message
          await showResults(successfulOrders, failedOrders, contextualActionName);
  
          $grid.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog(`Error ${contextualActionName}ing order${pluralChar}`, targetError.message, null, failedOrders.length > 0 ? 'Errors' : null, failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') })));
  }
  
  
  /***************************************
   * FUNCTIONS
  ****************************************/
  async function showResults(successfulOrders: any[], failedOrders: any[], actionName: string) {
      let title: string;
      let message: string;
  
      if (successfulOrders.length + failedOrders.length === 1) {
          if (successfulOrders.length > 0) {
              title = `Successfully ${actionName}ed order ${successfulOrders[0].orderCode}`;
          }
          else {
              title = `Error ${actionName}ing order ${failedOrders[0].orderCode}`;
              message = (await $flows.Utilities.grammar_format_string_array_flow({ values: failedOrders[0].reasons })).formattedValue;
          }
      }
      else {
          if (successfulOrders.length > 0) {
              if (failedOrders.length > 0) {
                  title = `Error ${actionName}ing some orders`;
              }
              else {
                  title = `Successfully ${actionName}ed all orders`;
              }
  
              message = `Successfully ${actionName}ed ${successfulOrders.length} orders`;
          }
          else {
              title = `Error ${actionName}ing all orders`;
              message = `No orders could be ${actionName}ed`
          }
      }
  
      if (failedOrders.length > 0) {
          await $shell.FootPrintManager.openErrorDialog(
              title,
              message,
              successfulOrders.length > 7 ? null : successfulOrders.map(o => o.orderCode),
              'Errors',
              failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') }))
          );
      }
      else {
          await $shell.FootPrintManager.openInfoDialog(title, message);
      }
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let actionName = 'Delete';
  
  let contextualActionName = actionName.replace(/(e)$/, '');
  contextualActionName = contextualActionName.charAt(0).toLowerCase() + contextualActionName.slice(1);
  
  let pluralChar = '';
  let failedOrders: { orderId: number, orderCode: string, reasons: string[] }[] = [];
  let successfulOrders: { orderId: number, orderCode: string }[] = [];
  
  try {
      if ($grid.selectedRows.length === 0) {
          throw new Error('No orders selected.');
      }
  
      if ($grid.selectedRows.length > 1) { pluralChar = 's'; }
  
      var orderStates = (await $flows.Returns.get_return_orders_state_flow({ orderIds: $grid.selectedRows.map(row => row.entity.Id) })).orderStates;
  
      failedOrders = orderStates.filter(os => $utils.isDefined(os.failActionReasons.deleteReasons)).map(os => ({ orderId: os.orderId, orderCode: $grid.rows.find(row => row.entity.Id === os.orderId).entity.LookupCode, reasons: os.failActionReasons.deleteReasons }));
  
      // Throw error if no orders can continue
      if (failedOrders.length === $grid.selectedRows.length) {
          throw new Error(`No orders can be ${contextualActionName}ed`);
      }
  
      let message = failedOrders.length > 0 ? `Not all orders can be ${contextualActionName}ed. Continue?` : 
                          $grid.selectedRows.length > 1 ? `${actionName} selected orders?` : `Are you sure you want to ${actionName.toLowerCase()} order ${$grid.selectedRows[0].entity.LookupCode}?`;
  
      let isConfirm = (await $shell.FootPrintManager.openConfirmationDialog(`${actionName} order${pluralChar}`, message, 'Yes', 'No'))
  
      if (isConfirm) {
          for (let row of $grid.selectedRows) {
              if (!failedOrders.find(o => o.orderId === row.entity.Id)) {
                  // Get promises
                  if ($utils.isDefined(promises)) {
                      promises.push(await $flows.Returns.delete_return_order_flow({ orderId: row.entity.Id }))
                  }
                  else {
                      var promises = [await $flows.Returns.delete_return_order_flow({ orderId: row.entity.Id })]
                  }
              }
          }
  
          // Check results
          await Promise.all(promises)
              .then(results => {
                  results.forEach(result => {
                      if ($utils.isDefined(result.reasons)) {
                          failedOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode, reasons: result.reasons });
                      }
                      else {
                          successfulOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode });
                      }
                  })
              });
  
          // Return error message
          await showResults(successfulOrders, failedOrders, contextualActionName);
  
          $grid.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog(`Error ${contextualActionName}ing order${pluralChar}`, targetError.message, null, failedOrders.length > 0 ? 'Errors' : null, failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') })));
  }
  
  
  /***************************************
   * FUNCTIONS
  ****************************************/
  async function showResults(successfulOrders: any[], failedOrders: any[], actionName: string) {
      let title: string;
      let message: string;
  
      if (successfulOrders.length + failedOrders.length === 1) {
          if (successfulOrders.length > 0) {
              title = `Successfully ${actionName}ed order ${successfulOrders[0].orderCode}`;
          }
          else {
              title = `Error ${actionName}ing order ${failedOrders[0].orderCode}`;
              message = (await $flows.Utilities.grammar_format_string_array_flow({ values: failedOrders[0].reasons })).formattedValue;
          }
      }
      else {
          if (successfulOrders.length > 0) {
              if (failedOrders.length > 0) {
                  title = `Error ${actionName}ing some orders`;
              }
              else {
                  title = `Successfully ${actionName}ed all orders`;
              }
  
              message = `Successfully ${actionName}ed ${successfulOrders.length} orders`;
          }
          else {
              title = `Error ${actionName}ing all orders`;
              message = `No orders could be ${actionName}ed`
          }
      }
  
      if (failedOrders.length > 0) {
          await $shell.FootPrintManager.openErrorDialog(
              title,
              message,
              successfulOrders.length > 7 ? null : successfulOrders.map(o => o.orderCode),
              'Errors',
              failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') }))
          );
      }
      else {
          await $shell.FootPrintManager.openInfoDialog(title, message);
      }
  }
  }
  on_update_clicked(event = null) {
    return this.on_update_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_update_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Returns.openErrorDialog('Order Update Error', 'No orders selected.');
      return;
  } else {
  
      const dialogResult = await $shell.FootPrintManager.openinbound_orders_update_formDialog();
      const userConfirmed = dialogResult.confirm;
      const carrierId = dialogResult.carrierId;
      const reference = dialogResult.reference;
      const expectedDate = dialogResult.expectedDate;
  
      let payload: any = {};
      let shipmentpayload: any = {};
  
      if (userConfirmed) {
          for (const row of selectedRows) {
  
              if ($utils.isDefined(carrierId)) {
                  payload.PreferredCarrierId = carrierId;
                  shipmentpayload.CarrierId = carrierId;
              }
              if (expectedDate) {
                  payload.RequestedDeliveryDate = expectedDate;
              }
               if (reference) {
                  payload.OwnerReference = reference;
              }
  
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: row.entity.Id, entity: payload });
              await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: row.entity.ShipmentOrderLookups[0].ShipmentId, entity: shipmentpayload });
          }
          await $grid.refresh();
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // // Hide Validation Components
  // if (!(await $operations.Returns.RequireInboundAsnOrderValidation.isAuthorized())) {
      $grid.topToolbar.validate.hidden = true;
      $grid.topToolbar.validate.control.readOnly = true;
  
  //     $grid.headers.validation_notes.name = null;
  //     $grid.headers.validation_result.name = null;
  // }
  
  
  }
  on_auto_receive_clicked(event = null) {
    return this.on_auto_receive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_receive_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let actionName = 'Auto receive';
  
  let contextualActionName = actionName.replace(/(e)$/, '');
  contextualActionName = contextualActionName.charAt(0).toLowerCase() + contextualActionName.slice(1);
  
  let pluralChar = '';
  let failedOrders: { orderId: number, orderCode: string, reasons: string[] }[] = [];
  let successfulOrders: { orderId: number, orderCode: string }[] = [];
  
  try {
      if ($grid.selectedRows.length === 0) {
          throw new Error('No orders selected.');
      }
  
      if ($grid.selectedRows.length > 1) { pluralChar = 's'; }
  
      var orderStates = (await $flows.Returns.get_return_orders_state_flow({ orderIds: $grid.selectedRows.map(row => row.entity.Id) })).orderStates;
  
      failedOrders = orderStates.filter(os => $utils.isDefined(os.failActionReasons.autoReceiveReasons)).map(os => ({ orderId: os.orderId, orderCode: $grid.rows.find(row => row.entity.Id === os.orderId).entity.LookupCode, reasons: os.failActionReasons.autoReceiveReasons }));
  
      // Throw error if no orders can continue
      if (failedOrders.length === $grid.selectedRows.length) {
          throw new Error(`No orders can be ${contextualActionName}ed`);
      }
  
      let message = failedOrders.length > 0 ? `Not all orders can be ${contextualActionName}ed. Continue?` : 
                          $grid.selectedRows.length > 1 ? `${actionName} selected orders` : `Are you sure you want to ${actionName.toLowerCase()} order ${$grid.selectedRows[0].entity.LookupCode}?`;
  
      let isConfirm = (await $shell.FootPrintManager.openConfirmationDialog(`${actionName} order${pluralChar}?`, message, 'Yes', 'No'))
  
      if (isConfirm) {
          for (let row of $grid.selectedRows) {
              if (!failedOrders.find(o => o.orderId === row.entity.Id)) {
                  // Get promises
                  if ($utils.isDefined(promises)) {
                      promises.push(await $flows.Returns.auto_receive_return_order_flow({ orderId: row.entity.Id }))
                  }
                  else {
                      var promises = [await $flows.Returns.auto_receive_return_order_flow({ orderId: row.entity.Id })]
                  }
              }
          }
  
          // Check results
          await Promise.all(promises)
              .then(results => {
                  results.forEach(result => {
                      if ($utils.isDefined(result.reasons)) {
                          failedOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode, reasons: result.reasons });
                      }
                      else {
                          successfulOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode });
                      }
                  })
              });
  
          // Return error message
          await showResults(successfulOrders, failedOrders, contextualActionName);
  
          $grid.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog(`Error ${contextualActionName}ing order${pluralChar}`, targetError.message, null, failedOrders.length > 0 ? 'Errors' : null, failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') })));
  }
  
  
  /***************************************
   * FUNCTIONS
  ****************************************/
  async function showResults(successfulOrders: any[], failedOrders: any[], actionName: string) {
      let title: string;
      let message: string;
  
      if (successfulOrders.length + failedOrders.length === 1) {
          if (successfulOrders.length > 0) {
              title = `Successfully ${actionName}ed order ${successfulOrders[0].orderCode}`;
          }
          else {
              title = `Error ${actionName}ing order ${failedOrders[0].orderCode}`;
              message = (await $flows.Utilities.grammar_format_string_array_flow({ values: failedOrders[0].reasons })).formattedValue;
          }
      }
      else {
          if (successfulOrders.length > 0) {
              if (failedOrders.length > 0) {
                  title = `Error ${actionName}ing some orders`;
              }
              else {
                  title = `Successfully ${actionName}ed all orders`;
              }
  
              message = `Successfully ${actionName}ed ${successfulOrders.length} orders`;
          }
          else {
              title = `Error ${actionName}ing all orders`;
              message = `No orders could be ${actionName}ed`
          }
      }
  
      if (failedOrders.length > 0) {
          await $shell.FootPrintManager.openErrorDialog(
              title,
              message,
              successfulOrders.length > 7 ? null : successfulOrders.map(o => o.orderCode),
              'Errors',
              failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') }))
          );
      }
      else {
          await $shell.FootPrintManager.openInfoDialog(title, message);
      }
  }
  }
  on_validate_clicked(event = null) {
    return this.on_validate_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_validate_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let actionName = 'Validate';
  
  let contextualActionName = actionName.replace(/(e)$/, '');
  contextualActionName = contextualActionName.charAt(0).toLowerCase() + contextualActionName.slice(1);
  
  let pluralChar = '';
  let failedOrders: { orderId: number, orderCode: string, reasons: string[] }[] = [];
  let successfulOrders: { orderId: number, orderCode: string }[] = [];
  
  try {
      if ($grid.selectedRows.length === 0) {
          throw new Error('No orders selected.');
      }
  
      if ($grid.selectedRows.length > 1) { pluralChar = 's'; }
  
      var orderStates = (await $flows.Returns.get_return_orders_state_flow({ orderIds: $grid.selectedRows.map(row => row.entity.Id) })).orderStates;
  
      failedOrders = orderStates.filter(os => $utils.isDefined(os.failActionReasons.validateReasons)).map(os => ({ orderId: os.orderId, orderCode: $grid.rows.find(row => row.entity.Id === os.orderId).entity.LookupCode, reasons: os.failActionReasons.validateReasons }));
  
      // Throw error if no orders can continue
      if (failedOrders.length === $grid.selectedRows.length) {
          throw new Error(`No orders can be ${contextualActionName}ed`);
      }
  
      let message = failedOrders.length > 0 ? `Not all orders can be ${contextualActionName}ed. Continue?` : 
                          $grid.selectedRows.length > 1 ? `${actionName} selected orders?` : `Are you sure you want to ${actionName.toLowerCase()} order ${$grid.selectedRows[0].entity.LookupCode}?`;
  
      let isConfirm = (await $shell.FootPrintManager.openConfirmationDialog(`${actionName} order${pluralChar}`, message, 'Yes', 'No'))
  
      if (isConfirm) {
          for (let row of $grid.selectedRows) {
              if (!failedOrders.find(o => o.orderId === row.entity.Id)) {
                  // Get promises
                  if ($utils.isDefined(promises)) {
                      promises.push(await $flows.Returns.validate_return_order_flow({ orderId: row.entity.Id }))
                  }
                  else {
                      var promises = [await $flows.Returns.validate_return_order_flow({ orderId: row.entity.Id })]
                  }
              }
          }
  
          // Check results
          await Promise.all(promises)
              .then(results => {
                  results.forEach(result => {
                      if ($utils.isDefined(result.reasons)) {
                          failedOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode, reasons: result.reasons });
                      }
                      else {
                          successfulOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode });
                      }
                  })
              });
  
          // Return error message
          await showResults(successfulOrders, failedOrders, contextualActionName);
  
          $grid.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog(`Error ${contextualActionName}ing order${pluralChar}`, targetError.message, null, failedOrders.length > 0 ? 'Errors' : null, failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') })));
  }
  
  
  /***************************************
   * FUNCTIONS
  ****************************************/
  async function showResults(successfulOrders: any[], failedOrders: any[], actionName: string) {
      let title: string;
      let message: string;
  
      if (successfulOrders.length + failedOrders.length === 1) {
          if (successfulOrders.length > 0) {
              title = `Successfully ${actionName}ed order ${successfulOrders[0].orderCode}`;
          }
          else {
              title = `Error ${actionName}ing order ${failedOrders[0].orderCode}`;
              message = (await $flows.Utilities.grammar_format_string_array_flow({ values: failedOrders[0].reasons })).formattedValue;
          }
      }
      else {
          if (successfulOrders.length > 0) {
              if (failedOrders.length > 0) {
                  title = `Error ${actionName}ing some orders`;
              }
              else {
                  title = `Successfully ${actionName}ed all orders`;
              }
  
              message = `Successfully ${actionName}ed ${successfulOrders.length} orders`;
          }
          else {
              title = `Error ${actionName}ing all orders`;
              message = `No orders could be ${actionName}ed`
          }
      }
  
      if (failedOrders.length > 0) {
          await $shell.FootPrintManager.openErrorDialog(
              title,
              message,
              successfulOrders.length > 7 ? null : successfulOrders.map(o => o.orderCode),
              'Errors',
              failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') }))
          );
      }
      else {
          await $shell.FootPrintManager.openInfoDialog(title, message);
      }
  }
  }
  on_revert_clicked(event = null) {
    return this.on_revert_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let actionName = 'Revert';
  
  let contextualActionName = actionName.replace(/(e)$/, '');
  contextualActionName = contextualActionName.charAt(0).toLowerCase() + contextualActionName.slice(1);
  
  let pluralChar = '';
  let failedOrders: { orderId: number, orderCode: string, reasons: string[] }[] = [];
  let successfulOrders: { orderId: number, orderCode: string }[] = [];
  
  try {
      if ($grid.selectedRows.length === 0) {
          throw new Error('No orders selected.');
      }
  
      if ($grid.selectedRows.length > 1) { pluralChar = 's'; }
  
      var orderStates = (await $flows.Returns.get_return_orders_state_flow({ orderIds: $grid.selectedRows.map(row => row.entity.Id) })).orderStates;
  
      failedOrders = orderStates.filter(os => $utils.isDefined(os.failActionReasons.revertReasons)).map(os => ({ orderId: os.orderId, orderCode: $grid.rows.find(row => row.entity.Id === os.orderId).entity.LookupCode, reasons: os.failActionReasons.revertReasons }));
  
      // Throw error if no orders can continue
      if (failedOrders.length === $grid.selectedRows.length) {
          throw new Error(`No orders can be ${contextualActionName}ed`);
      }
  
      let message = failedOrders.length > 0 ? `Not all orders can be ${contextualActionName}ed. Continue?` : 
                          $grid.selectedRows.length > 1 ? `${actionName} selected orders?` : `Are you sure you want to ${actionName.toLowerCase()} order ${$grid.selectedRows[0].entity.LookupCode}?`;
  
      let isConfirm = (await $shell.FootPrintManager.openConfirmationDialog(`${actionName} order${pluralChar}`, message, 'Yes', 'No'))
  
      if (isConfirm) {
          for (let row of $grid.selectedRows) {
              if (!failedOrders.find(o => o.orderId === row.entity.Id)) {
                  // Get promises
                  if ($utils.isDefined(promises)) {
                      promises.push(await $flows.Returns.revert_return_order_flow({ orderId: row.entity.Id }))
                  }
                  else {
                      var promises = [await $flows.Returns.revert_return_order_flow({ orderId: row.entity.Id })]
                  }
              }
          }
  
          // Check results
          await Promise.all(promises)
              .then(results => {
                  results.forEach(result => {
                      if ($utils.isDefined(result.reasons)) {
                          failedOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode, reasons: result.reasons });
                      }
                      else {
                          successfulOrders.push({ orderId: result.reflectedOrderId, orderCode: $grid.rows.find(row => row.entity.Id === result.reflectedOrderId).entity.LookupCode });
                      }
                  })
              });
  
          // Return error message
          await showResults(successfulOrders, failedOrders, contextualActionName);
  
          $grid.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog(`Error ${contextualActionName}ing order${pluralChar}`, targetError.message, null, failedOrders.length > 0 ? 'Errors' : null, failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') })));
  }
  
  
  /***************************************
   * FUNCTIONS
  ****************************************/
  async function showResults(successfulOrders: any[], failedOrders: any[], actionName: string) {
      let title: string;
      let message: string;
  
      if (successfulOrders.length + failedOrders.length === 1) {
          if (successfulOrders.length > 0) {
              title = `Successfully ${actionName}ed order ${successfulOrders[0].orderCode}`;
          }
          else {
              title = `Error ${actionName}ing order ${failedOrders[0].orderCode}`;
              message = (await $flows.Utilities.grammar_format_string_array_flow({ values: failedOrders[0].reasons })).formattedValue;
          }
      }
      else {
          if (successfulOrders.length > 0) {
              if (failedOrders.length > 0) {
                  title = `Error ${actionName}ing some orders`;
              }
              else {
                  title = `Successfully ${actionName}ed all orders`;
              }
  
              message = `Successfully ${actionName}ed ${successfulOrders.length} orders`;
          }
          else {
              title = `Error ${actionName}ing all orders`;
              message = `No orders could be ${actionName}ed`
          }
      }
  
      if (failedOrders.length > 0) {
          await $shell.FootPrintManager.openErrorDialog(
              title,
              message,
              successfulOrders.length > 7 ? null : successfulOrders.map(o => o.orderCode),
              'Errors',
              failedOrders.map(o => ({ message: `Order ${o.orderCode}`, detail: o.reasons.join(', ') }))
          );
      }
      else {
          await $shell.FootPrintManager.openInfoDialog(title, message);
      }
  }
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const exportLimit = $settings.FootPrintManager.ExcelExportLimit
  
  let exportAsDetails = await $shell.FootPrintManager.openConfirmationDialog('Choose Export', 'Export Details or Summary?', 'Details', 'Summary');
  if (!$utils.isDefined(exportAsDetails)) { return; }
  
  //Initialize
  var wb = $event;
  var transactions = [];
  var totalRecords: number;
  var exportType: string = "Summary";
  
  let dateFormat = $settings.FootPrintManager.DateFormat ?? "MM/dd/yyyy";
  
  // Get Selected Rows only. 
  if ($grid.selectedRows.length > 0) {
      var orderIds = [...new Set($grid.selectedRows.map(o => o.entity.Id))]
      totalRecords = $grid.selectedRows.length;
  } else {
      if (!exportAsDetails) {
          totalRecords = (await $datasources.Returns.ds_return_orders_grid.get({
              ownerId: $grid.inParams.ownerId,
              projectId: $grid.inParams.projectId,
              warehouseIds: $grid.inParams.warehouseIds,
              statusIds: $grid.inParams.statusIds,
              fromDate: $grid.filters.from_date.control.value,
              toDate: $grid.filters.to_date.control.value,
              dateType: $grid.filters.date_type.control.value,
              fullTextSearch: $grid.fullTextSearch,
              reference: $grid.inParams.reference
          })).totalCount;
      } else {
          exportType = "Details"
          totalRecords = (await $datasources.Returns.ds_return_orders_details_grid.get({
              ownerId: $grid.inParams.ownerId,
              projectId: $grid.inParams.projectId,
              warehouseId: $grid.inParams.warehouseIds,
              statusIds: $grid.inParams.statusIds,
              fromDate: $grid.filters.from_date.control.value,
              toDate: $grid.filters.to_date.control.value,
              dateType: $grid.filters.date_type.control.value,
              fullTextSearch: $grid.fullTextSearch,
              reference: $grid.inParams.reference
          })).totalCount;
      }
  }
  if (totalRecords > exportLimit) {
      (await $shell.Inventory.openInfoDialog(`Export limitation of ${exportLimit} exceeded`, `Excel will only export the first ${exportLimit} records`));
  }
  transactions = (await $flows.Returns.excel_export_flow({
      ownerId: $grid.inParams.ownerId,
      projectId: $grid.inParams.projectId,
      warehouseIds: $grid.inParams.warehouseIds,
      statusIds: $grid.inParams.statusIds,
      fromDate: $grid.filters.from_date.control.value,
      toDate: $grid.filters.to_date.control.value,
      dateType: $grid.filters.date_type.control.value,
      fullTextSearch: $grid.fullTextSearch,
      orderIds: orderIds,
      exportLimit: exportLimit,
      reference: $grid.inParams.reference,
      exportType: exportType,
      totalRecords: totalRecords,
      dateFormat: dateFormat
  })).transactions;
  
  
  var ws = $utils.excel.json_to_sheet(transactions);
  
  $utils.excel.book_append_sheet(wb, ws, "ReturnsOrdersExport");
  
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const orderIds = $grid.rows.map(row => row.entity.Id);
  
  let orderStates = (await $flows.Returns.get_return_orders_state_flow({ orderIds: orderIds })).orderStates;
  
  $grid.rows.forEach(row => {
      row.vars.failActionReasons = orderStates.find(os => os.orderId === row.entity.Id).failActionReasons;
  });
  
  if (orderIds.length > 0) {
  
      const order_tasks = (await $datasources.PurchaseOrders.ds_get_orders_aggregated_tasks.get({ orderIds: orderIds, operationCodeIds: [2] })).result;
      const order_properties = (await $datasources.PurchaseOrders.ds_inbound_orders_grid_properties.get({ orderIds: orderIds })).result;
      const expected_inbound = (await $datasources.PurchaseOrders.ds_get_orders_aggregated_expected.get({ orderIds: orderIds })).result;
      const order_addresses = (await $flows.Addresses.get_addresses_single_string_by_orderIds({ orderIds: orderIds }));
  
      // Get formatted lookupcode
  
      const receiving_tasks = order_tasks.filter(t => (t.StatusId === 2 && t.ActualAmount > 0));
      const pending_tasks = order_tasks.filter(t => ((t.StatusId === 4 || t.StatusId === 1) && t.ExpectedAmount > 0));
  
      const properties_array = order_properties.map(row => row.Id);
      const receiving_array = receiving_tasks.map(row => row.OrderId);
      const pending_array = pending_tasks.map(row => row.OrderId);
      const expected_array = expected_inbound.map(row => row.OrderId);
      const address_array = order_addresses.Addresses.map(row => row.OrderId);
  
      for (let row of $grid.rows) {
  
          let received_total = 0;
          let expected_total = 0;
          let orderIndex = -1;
  
          let orderId = row.entity.Id;
          let order_status_id = row.entity.OrderStatusId;
  
          //Get the total received inventory
          orderIndex = receiving_array.indexOf(orderId)
          if (orderIndex >= 0) {
              received_total = receiving_tasks[orderIndex].ActualAmount;
          };
  
          //Get the total expected inventory
          orderIndex = expected_array.indexOf(orderId)
          if (orderIndex >= 0) {
              expected_total = expected_inbound[orderIndex].Amount;
          };
  
          //Created
          if (row.entity.Status.Id === 1 && expected_total > 0) {
              row.cells.total_items.displayControl.text = `${expected_total}`
          };
  
          //Canceled
          if (row.entity.Status.Id === 8 && received_total > 0) {
              row.cells.total_items.displayControl.text = `${received_total}`
          };
  
          //Processing or Completed
          if ((row.entity.Status.Id === 2 || row.entity.Status.Id === 4) && received_total >= 0 && expected_total > 0) {
              row.cells.total_items.displayControl.text = `${received_total} of ${expected_total}`
          };
  
          // Check tasks
  
          let has_receiving = false;
          let has_pending = false;
  
          orderIndex = receiving_array.indexOf(orderId)
          if (orderIndex >= 0) {
              has_receiving = true;
          };
  
          orderIndex = pending_array.indexOf(orderId)
          if (orderIndex >= 0) {
              has_pending = true;
          };
  
          row.vars.hasCompletedReceivingTasks = has_receiving;
          row.vars.hasRemainingReceivingTasks = has_pending;
  
          if (order_status_id === 2) {
  
              let order_state = 0;
  
              // All receiving tasks in a planned status
              if (has_pending && !has_receiving) {
                  order_state = 2;
              }
              // One or more receiving tasks completed and one or more receiving tasks released
              else if (has_pending && has_receiving) {
                  order_state = 3;
              }
  
              // No more released receiving tasks where the expected packaged amount gt 0 and at least one completed receiving task
              else if (!has_pending && has_receiving) {
                  order_state = 4;
              };
  
              if ($utils.isDefined(order_state)) {
                  switch (order_state) {
                      case 2: {
                          row.cells.status.displayControl.text = 'Ready to receive'
                          break;
                      }
                      case 3: {
                          row.cells.status.displayControl.text = 'Receiving in progress'
                          break;
                      }
                      case 4: {
                          row.cells.status.displayControl.text = 'Ready to complete'
                          break;
                      };
                  };
              };
          };
  
          //Get additional properties
          orderIndex = properties_array.indexOf(orderId)
          if (orderIndex >= 0) {
  
              if ($utils.isDefined(order_properties[orderIndex].ValidationResults)) {
                  row.cells.validation_notes.displayControl.text = `${order_properties[orderIndex].ValidationResults[0]?.Notes}`;
                  row.cells.validation_result.displayControl.text = `${order_properties[orderIndex].ValidationResults[0]?.ValidationExecutionResult?.DisplayName}`;
              };
  
              // Apply appointment text logic
              if ($utils.isDefined(order_properties[orderIndex].DockAppointmentsOrdersLookup)) {
  
                  if ($utils.isDefined(order_properties[orderIndex].DockAppointmentsOrdersLookup[0]?.DockAppointmentId)) {
  
                      let dock_appointment_id = order_properties[orderIndex].DockAppointmentsOrdersLookup[0]?.DockAppointmentId;
                      let dock_appointment = order_properties[orderIndex].DockAppointmentsOrdersLookup[0]?.DockAppointment;
  
                      let scheduled_arrival = dock_appointment?.ScheduledArrival;
                      let warehouseId = $utils.isDefinedTrimmed(dock_appointment?.WarehouseId) ? dock_appointment?.WarehouseId : order_properties[orderIndex].PreferredWarehouseId;
                      let scheduled_location = $utils.isDefinedTrimmed(dock_appointment?.ScheduledLocation?.ShortName) ? dock_appointment?.ScheduledLocation?.ShortName : dock_appointment?.ScheduledLocation?.Name;
                      let assigned_location = $utils.isDefinedTrimmed(dock_appointment?.AssignedLocation?.ShortName) ? dock_appointment?.AssignedLocation?.ShortName : dock_appointment?.AssignedLocation?.Name;
                      let dock_door = $utils.isDefinedTrimmed(assigned_location) ? assigned_location : ($utils.isDefinedTrimmed(scheduled_location) ? scheduled_location : 'Unassigned');
  
                      if ($utils.isDefined(scheduled_arrival) && $utils.isDefined(warehouseId)) {
  
                          scheduled_arrival = (await $datasources.DockAppointments.ds_get_current_date_from_utc.get({ utcDate: scheduled_arrival, warehouseId: warehouseId })).result.convertedDate;
  
                      };
  
                      row.vars.dockAppointmentId = dock_appointment_id;
  
                      row.cells.appointment.displayControl.text = (await $flows.DockAppointments.get_formatted_dock_appointment({
                          dockAppointmentId: dock_appointment_id,
                          optimalInputs: {
                              scheduledLocationName: dock_door,
                              assignedLocationName: dock_door,
                              statusId: dock_appointment?.StatusId,
                              lookupCode: dock_appointment?.LookupCode,
                              scheduledArrival: scheduled_arrival
                          }
                      })).formattedDockAppointment;
                  };
              }
              else {
                  row.cells.appointment.displayControl.text = '  +'
              };
          };
  
          //Set the Ship_From
          orderIndex = address_array.indexOf(orderId)
          if (orderIndex >= 0) {
  
              let address = order_addresses.Addresses[orderIndex]
  
              if ($utils.isDefined(address)) {
  
                  let ship_from = address.Address;
  
                  if (!ship_from.includes('ord-')) {
                      row.cells.ship_from.displayControl.text = ship_from
                  }
                  else {
                      row.cells.ship_from.displayControl.text = '';
                  };
              };
          };
      };
  
      // Hide Validation Columns
  
      $grid.headers.validation_result.hidden = true;
      $grid.headers.validation_notes.hidden = true;
  
  };
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $grid: FootPrintManager_return_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length === 1) {
      await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'Order', entityKeys: [{ name: 'Id', value: $grid.selectedRows[0].entity.Id }]});
  }
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootPrintManager_return_orders_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
