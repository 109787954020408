import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { app_footprint_wms_homeComponent } from './app.footprint_wms_home.component';
import { app_labor_management_powerbiComponent } from './app.labor_management_powerbi.component';
import { app_lot_management_powerbiComponent } from './app.lot_management_powerbi.component';
import { app_openai_documentation_chatComponent } from './app.openai_documentation_chat.component';
import { app_revenue_powerbiComponent } from './app.revenue_powerbi.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Addresses_ShellService } from './Addresses.shell.service';
import { Carriers_ShellService } from './Carriers.shell.service';
import { Instructions_ShellService } from './Instructions.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { DockAppointments_ShellService } from './DockAppointments.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Discussions_ShellService } from './Discussions.shell.service';
import { Document360_ShellService } from './Document360.shell.service';
import { Usersnap_ShellService } from './Usersnap.shell.service';
import { Locations_ShellService } from './Locations.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintApiManager_ShellService } from './FootPrintApiManager.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public Addresses: Addresses_ShellService,
    public Carriers: Carriers_ShellService,
    public Instructions: Instructions_ShellService,
    public Materials: Materials_ShellService,
    public DockAppointments: DockAppointments_ShellService,
    public Owners: Owners_ShellService,
    public Discussions: Discussions_ShellService,
    public Document360: Document360_ShellService,
    public Usersnap: Usersnap_ShellService,
    public Locations: Locations_ShellService,
    public Notifications: Notifications_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public Invoices: Invoices_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public FootPrintManager: FootPrintManager_ShellService,
    public FootPrintApiManager: FootPrintApiManager_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openfootprint_wms_home(replaceCurrentView?: boolean) {
    this.logger.log('app', 'footprint_wms_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'footprint_wms_home',
        component: app_footprint_wms_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openfootprint_wms_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'footprint_wms_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_footprint_wms_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openlabor_management_powerbi(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Labor Management Power BI',
        referenceName: 'labor_management_powerbi',
        component: app_labor_management_powerbiComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openlabor_management_powerbiDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_labor_management_powerbiComponent,
      'Labor Management Power BI',
      mode,
      dialogSize
    )
  }
  public openlot_management_powerbi(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Lot Management Power BI',
        referenceName: 'lot_management_powerbi',
        component: app_lot_management_powerbiComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openlot_management_powerbiDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_lot_management_powerbiComponent,
      'Lot Management Power BI',
      mode,
      dialogSize
    )
  }
  public openopenai_documentation_chat(replaceCurrentView?: boolean) {
    this.logger.log('app', 'openai_documentation_chat');
    ShellService.openViewRequest$.next(
      {
        title: 'Documentation Chatbot',
        referenceName: 'openai_documentation_chat',
        component: app_openai_documentation_chatComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openopenai_documentation_chatDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'openai_documentation_chat');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_openai_documentation_chatComponent,
      'Documentation Chatbot',
      mode,
      dialogSize
    )
  }
  public openrevenue_powerbi(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Revenue Power BI',
        referenceName: 'revenue_powerbi',
        component: app_revenue_powerbiComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openrevenue_powerbiDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_revenue_powerbiComponent,
      'Revenue Power BI',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'footprint_wms_home') {
      this.logger.log('app', 'footprint_wms_home');
      const title = 'Home';
      const component = app_footprint_wms_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'labor_management_powerbi') {
      const title = 'Labor Management Power BI';
      const component = app_labor_management_powerbiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'lot_management_powerbi') {
      const title = 'Lot Management Power BI';
      const component = app_lot_management_powerbiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'openai_documentation_chat') {
      this.logger.log('app', 'openai_documentation_chat');
      const title = 'Documentation Chatbot';
      const component = app_openai_documentation_chatComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'revenue_powerbi') {
      const title = 'Revenue Power BI';
      const component = app_revenue_powerbiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Addresses.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Carriers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Instructions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.DockAppointments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Discussions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Document360.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Usersnap.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Locations.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintApiManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
