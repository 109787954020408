import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Inventory_ShellService, EModalSize, EToasterType, EToasterPosition } from './Inventory.shell.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { Inventory_LocalizationService } from './Inventory.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Inventory.frontend.types'
import { $frontendTypes as $types} from './Inventory.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Inventory-serialnumbers_by_inventory_dd_multi',
  templateUrl: './Inventory.serialnumbers_by_inventory_dd_multi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Inventory_serialnumbers_by_inventory_dd_multiComponent),
      multi: true
    }
  ]
})
export class Inventory_serialnumbers_by_inventory_dd_multiComponent extends BaseComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      || this.$hasMissingRequiredInParams
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;

  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return true;
  }

  get isSingle() {
    return false;
  }


  inParams: { lotId?: number, packagingId: number, warehouseId: number, licenseplateId?: number, materialId?: number } = { lotId: null, packagingId: null, warehouseId: null, licenseplateId: null, materialId: null };
  @Input('lotId') set $inParams_lotId(value: any) {
    this.inParams['lotId'] = value;
  }
  get $inParams_lotId(): any {
    return this.inParams['lotId'] ;
  }
  @Input('packagingId') set $inParams_packagingId(value: any) {
    this.inParams['packagingId'] = value;
  }
  get $inParams_packagingId(): any {
    return this.inParams['packagingId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('licenseplateId') set $inParams_licenseplateId(value: any) {
    this.inParams['licenseplateId'] = value;
  }
  get $inParams_licenseplateId(): any {
    return this.inParams['licenseplateId'] ;
  }
  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }

  fullTextSearch: string;

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Inventory_ShellService,
    private datasources: Inventory_DatasourceService,
    private flows: Inventory_FlowService,
    private reports: Inventory_ReportService,
    private localization: Inventory_LocalizationService,
    private operations: Inventory_OperationService,
    private logger: CleanupLoggerService,
    private _elementRef: ElementRef
    ) {
      super();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.packagingId)) {
        this.$missingRequiredInParams.push('packagingId');
      }
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  async $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): number | number[] {
    return this._innerValue;
  }

  set value(val:  number | number[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  displayWithFn(value: any): Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}> {
    const promise = new Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}>((resolve, reject) => {

      const $selector = this;
      const $utils = this.utils;

      this.fullTextSearch = null;
      const $fullTextSearch = null;
      this.datasources.Inventory.ds_serialnumbers_by_inventory_dd.getByKeys({
        $keys: value,
        lotId:  $selector.inParams.lotId ,
        packagingId:  $selector.inParams.packagingId ,
        warehouseId:  $selector.inParams.warehouseId ,
        fullTextSearch:  $selector.fullTextSearch ,
        licenseplateId:  $selector.inParams.licenseplateId ,
        materialId:  $selector.inParams.materialId ,
      }).then(result => {
        const selected = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          const e: { Id?: number, LookupCode?: string } = found 
            ?? { 
              Id : k
            };

          const $option = { entity: e };
          const $entity = e;
          const $resultOption = { key: null, name: found ? $option.entity.LookupCode : 'No data to display', disabled: false, selected:true };
          $resultOption.key = $option.entity.Id;
          return $resultOption;
        });
        const displayText = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          if (found) {
            const $option = { entity: found };
            const $entity = found;
            return $option.entity.LookupCode;
          } else {
            return 'No data to display';
          }
        })
        .join(
           ', '
        );
        this.displayTextChange.emit(displayText);
        resolve({displayText, selected});
      });
    });
    return promise;
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {

    const promise = new Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }>((resolve, reject) => {

      const $selector = this;
      const $utils = this.utils;

      this.fullTextSearch = filterText;
      const $fullTextSearch = filterText;
      this.datasources.Inventory.ds_serialnumbers_by_inventory_dd.getList({
        $top: 15,
        $skip: 0,
        lotId:  $selector.inParams.lotId ,
        packagingId:  $selector.inParams.packagingId ,
        warehouseId:  $selector.inParams.warehouseId ,
        fullTextSearch:  $selector.fullTextSearch ,
        licenseplateId:  $selector.inParams.licenseplateId ,
        materialId:  $selector.inParams.materialId ,
      }).then(result => {
        resolve({
          list: result.result.map(item => {
            const $option = { entity: item };
            const $entity = item;
            const $resultOption = { key: null, name: $option.entity.LookupCode, disabled: null };
            $resultOption.key = $option.entity.Id;
            return $resultOption;
          }),
          totalCount: result.totalCount,
          top: 15
        });
      });

    });

    return promise;

  }

}
