import { isNil, remove, clone } from 'lodash-es';

export class Styles {
  classes: string[];
  style: { [klass: string]: any };

  private defaultClasses: string[];
  private defaultStyle: { [klass: string]: any };

  constructor(classes?: string[], style?: { [klass: string]: any }) {
    if (isNil(classes)) {
      this.classes = [];
      this.defaultClasses = [];
    } else {
      this.classes = classes;
      this.defaultClasses = clone(classes);
    }

    if (isNil(style)) {
      this.style = {};
      this.defaultStyle = {};
    } else {
      this.style = style;
      this.defaultStyle = clone(style);
    }
  }

  addClass(name: string | string[]) {
    if (!isNil(name)) {
      if (typeof name === 'string') {
        this._addClass(name);
      } else {
        name.forEach(n => this._addClass(n));
      }
    }
  }

  private _addClass(name: string) {
    const index = this.classes.indexOf(name);
    if (index < 0) {
      this.classes.push(name);
    }
  }

  removeClass(name: string | string[]) {
    if (!isNil(name)) {
      if (typeof name === 'string') {
        this._removeClass(name);
      } else {
        name.forEach(n => this._removeClass(n));
      }
    }
  }

  private _removeClass(name: string) {
    remove(this.classes, c => c === name)
  }

  setClass(name: string | string[]) {
    this.clearClasses();
    this.addClass(name);
  }

  clearClasses() {
    this.classes = [];
  }

  resetClasses() {
    this.classes = clone(this.defaultClasses);
  }

  setStyle(nameAndUnit: string, value: string | number | null | undefined) {
    const [name, unit] = nameAndUnit.split('.');
    value = value != null && unit ? `${value}${unit}` : value;

    this.style[name] = value;
  }

  removeStyle(name: string) {
    if (this.style.hasOwnProperty(name)) {
      delete this.style[name];
    }
  }

  clearStyle() {
    this.style = {};
  }

  resetStyle() {
    this.style = clone(this.defaultStyle);
  }

  clear() {
    this.clearClasses();
    this.clearStyle();
  }

  reset() {
    this.resetClasses();
    this.resetStyle();
  }
}

export class ControlContainerStyles extends Styles {
  private setStateClass(klass: string) {
    this.removeClass([
      'creation',
      'attention',
      'important',
      'status-created',
      'status-a',
      'status-b',
      'status-c',
      'status-d',
      'status-complete',
      'status-canceled',
      'planned',]);
    this.addClass(klass);
  }

  setCreationClass() { this.setStateClass('creation'); }
  setAttentionClass() { this.setStateClass('attention'); }
  setImportantClass() { this.setStateClass('important'); }
  setStatusCreatedClass() { this.setStateClass('status-created'); }
  setStatusAClass() { this.setStateClass('status-a'); }
  setStatusBClass() { this.setStateClass('status-b'); }
  setStatusCClass() { this.setStateClass('status-c'); }
  setStatusDClass() { this.setStateClass('status-d'); }
  setStatusCompleteClass() { this.setStateClass('status-complete'); }
  setStatusCanceledClass() { this.setStateClass('status-canceled'); }
  setPlannedClass() { this.setStateClass('planned'); }
}

// TODO: Rusty to define the class
export class CardStyles extends Styles {
  private setStateClass(klass: string) {
    this.removeClass([
      'creation',
      'attention']);
    this.addClass(klass);
  }

  setCreationClass() { this.setStateClass('creation'); }
  setAttentionClass() { this.setStateClass('attention'); }
}
