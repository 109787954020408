import { Inject, Injectable, Injector }from '@angular/core';


import { Discussions_ds_storage_discussionsService } from './Discussions.datasource.index';

@Injectable({ providedIn: 'root' })
export class Discussions_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Discussions: Discussions_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_storage_discussions: Discussions_ds_storage_discussionsService;
  public get ds_storage_discussions(): Discussions_ds_storage_discussionsService {
    if(!this._ds_storage_discussions) {
      this._ds_storage_discussions = this.injector.get(Discussions_ds_storage_discussionsService);
    }
    return this._ds_storage_discussions;
  }
}

