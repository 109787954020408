import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { ShippingContainers_packing_slip_reportService } from './ShippingContainers.report.index';
import { ShippingContainers_shipping_label_reportService } from './ShippingContainers.report.index';

@Injectable({ providedIn: 'root' })
export class ShippingContainers_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public ShippingContainers: ShippingContainers_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _packing_slip_report: ShippingContainers_packing_slip_reportService;
  public get packing_slip_report(): ShippingContainers_packing_slip_reportService {
    if(!this._packing_slip_report) {
      this._packing_slip_report = this.injector.get(ShippingContainers_packing_slip_reportService);
    }
    return this._packing_slip_report;
  }
  private _shipping_label_report: ShippingContainers_shipping_label_reportService;
  public get shipping_label_report(): ShippingContainers_shipping_label_reportService {
    if(!this._shipping_label_report) {
      this._shipping_label_report = this.injector.get(ShippingContainers_shipping_label_reportService);
    }
    return this._shipping_label_report;
  }
}

