import { Component, Directive, OnInit, Input, Output, SimpleChanges, OnChanges, EventEmitter, ContentChild, ContentChildren, TemplateRef, QueryList } from '@angular/core';
import { GridCellModel, GridHeaderModel, GridRowModel, GridContainerStyle } from '../models/grid'
import { isNil } from 'lodash-es';
import { ButtonModel, ControlModel } from '../models/control';
import { ToolModel } from '../models/tool';

export enum ELoadingStatus {
  Loading,
  Loaded,
  NoResults,
  Error
}

@Directive({
  selector: '[gridCellDisplayControlDef]'
})
export class GridCellDisplayControlDef {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({
  selector: '[gridCellEditControlDef]'
})
export class GridCellEditControlDef {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({
  selector: '[expandableRowDef]'
})
export class ExpandableRowDef {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({
  selector: '[gridColumnDef]'
})
export class GridColumnDef {
  @Input('gridColumnDef')
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  protected _id: string;

  @ContentChild(GridCellDisplayControlDef) displayControlDef: GridCellDisplayControlDef;
  @ContentChild(GridCellEditControlDef) editControlDef: GridCellEditControlDef;

  constructor() {
  }
}

@Component({
  selector: 'datex-grid',
  templateUrl: './grid.component.html'
})
export class GridComponent implements OnInit, OnChanges {
  @Input() pageSize: number = 10;
  @Input() pageSkip: number = 0;
  @Input() totalCount: number = 0;
  @Input() selection: boolean = false;
  @Input() headers: { [id: string]: GridHeaderModel };
  @Input() rows: GridRowModel[];
  @Input() loadingStatus: ELoadingStatus = ELoadingStatus.Loading;
  @Input() canEdit: boolean = false;
  @Input() canAdd: boolean = false;
  @Input() addNewRowFn: (() => Promise<GridRowModel>) | null
  @Input() addLineModel: ToolModel<ButtonModel>;
  @Input() rowCanExpand: boolean = false;
  @Input() rowCommandTemplateRef: TemplateRef<any>;

  @Input() containerStyles: GridContainerStyle;

  @Output() pageChange: EventEmitter<void> = new EventEmitter();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();
  @Output() pageSkipChange: EventEmitter<number> = new EventEmitter();
  @Output() selectionChange: EventEmitter<any[]> = new EventEmitter();

  @ContentChildren(GridColumnDef, { descendants: true }) _contentColumnDefs: QueryList<GridColumnDef>;
  _columnDefsByName = new Map<string, GridColumnDef>();

  @ContentChild(ExpandableRowDef) expandableRowDef: ExpandableRowDef;

  public ELoadingStatus = ELoadingStatus;
  pagesCount = 0;
  currentPage = 1;
  currentPageFrom = 0;
  currentPageTo = 0;
  lastClickedIndex: number = null;

  headersOrder: string[] = [];

  // @Output() exitFromLastCell: EventEmitter<void> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rows']) {
      if (this.selection) {
        this.allSelected = false;
        this.allSelectedChanged();
      }
    }
    if (changes['headers']) {
      this.headersOrder = [];
      if (this.headers) {
        Object.keys(this.headers).forEach(hk => { this.headersOrder.push(hk); });
      }
    }

    if (changes['totalCount'] || changes['pageSize'] || changes['pageSkip']) {
      this.updatePagination();
    }
  }

  ngAfterViewInit() {
    this._cacheColumnDefs();
  }

  ngOnDestroy() {
    this._columnDefsByName.clear();
  }

  private _cacheColumnDefs() {
    this._columnDefsByName.clear();

    this._contentColumnDefs.forEach(columnDef => {
      if (this._columnDefsByName.has(columnDef.id)) {
        throw new Error(`Duplicate column definition for ${columnDef.id}`);
      }
      this._columnDefsByName.set(columnDef.id, columnDef);
    });
  }

  // Selection
  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }
  get selectedRowsCount() {
    return this.selectedRows.length;
  }
  getSelectedRows() {
    return [...this.selectedRows];
  }
  allSelected = false;
  allSelectedChanged() {
    if (this.allSelected === true) {
      this.rows.forEach(row => {
        row.selected = true;
      });
    } else if (this.allSelected === false) {
      this.rows.forEach(row => {
        row.selected = false;
      });
    }

    this.updateSelection();
  }

  updateAllSelected() {
    if (this.rows.length) {
      const allSelected = this.rows.every(r => r.selected === true);
      const allUnSelected = this.rows.every(r => r.selected !== true);
      if (allSelected) {
        this.allSelected = true;
      } else if (allUnSelected) {
        this.allSelected = false;
      } else {
        this.allSelected = undefined;
      }
    } else {
      this.allSelected = false;
    }

    this.updateSelection();
  }

  updateSelection() {
    const selectedRows = this.rows.filter(r => r.selected === true);
    this.selectedRows = selectedRows;
    this.selectionChange.emit(this.selectedRows);
  }

  // Pagination
  goToPage(page: number) {
    if (page >= 1 && page <= this.pagesCount && page !== this.currentPage) {
      this.currentPage = page;
      this.updateCurrentPageFromTo();

      const pageSkip = (this.currentPage - 1) * this.pageSize;
      this.pageSkipChange.emit(pageSkip);
      this.pageChange.emit();
    }
  }

  updatePagination() {
    if (!isNil(this.pageSize) &&
      !isNil(this.totalCount) &&
      !isNil(this.pageSkip) &&
      this.pageSize > 0) {
      this.pagesCount = Math.ceil(this.totalCount / this.pageSize);
      this.currentPage = (this.pageSkip / this.pageSize) + 1;
    } else {
      this.pagesCount = 0;
      this.currentPage = 1;
    }
    this.updateCurrentPageFromTo();
  }

  updateCurrentPageFromTo() {
    if (this.pagesCount > 0) {
      this.currentPageFrom = (this.currentPage * this.pageSize) - this.pageSize + 1;
      if (this.currentPage !== this.pagesCount) {
        this.currentPageTo = this.currentPage * this.pageSize;
      } else {
        this.currentPageTo = this.totalCount;
      }
    } else {
      this.currentPageFrom = 0;
      this.currentPageTo = 0;
    }
  }

  isPositive(x: number) {
    return x !== null && x !== undefined && x > 0;
  }

  // Editing
  get editable(): boolean {
    return this.canEdit || this.canAdd;
  }

  removeRow(row: GridRowModel) {
    const deletedRows = this.rows.splice(this.rows.indexOf(row), 1);
    const deletedRow = deletedRows[0];
    deletedRow.destroy();
  }

  onCellClick(cellId: string, row: GridRowModel, rowIndex: number) {
    row.$cellClicked(cellId);

    if (this.canEdit && this.canSwitchRow && !row.isEdit) {
      this.setDisplayMode();
      // open clicked cell and try focus
      const cell = row.cells[cellId];
      if (cell.isEditable) {
        row.setEditMode();
        cell.focus();
      }
    }
  }

  onCellKeyDown(event, cellId: string, row: GridRowModel, rowIndex: number) {
    // console.log(event);
    if (event.repeat !== true && event.key === 'Tab') {
      const cell = row.cells[cellId];
      if (event.shiftKey === false && row.getLastEditableCell() === cell) {
        setTimeout(() => {
          if (row.formGroup.valid && !row.$isInAction) {
            this.confirmAndMoveNext(row, rowIndex);
          } else {
            cell.focus();
          }
        }, 0);
      } else if (event.shiftKey === true && row.getFirstEditableCell() === cell) {
        setTimeout(() => {
          if (row.formGroup.valid && !row.$isInAction) {
            this.confirmAndMovePrev(row, rowIndex);
          } else {
            cell.focus();
          }
        }, 0);
      }
    }
  }

  async confirmAndMoveNext(row: GridRowModel, rowIndex: number) {
    await row.confirm();

    if ((rowIndex + 1) == (this.rows.length)) {
      // exitFromLastCell
      // this.exitFromLastCell.emit();
      this.onCommandAddLineClicked();
    } else if (this.canEdit && this.canSwitchRow) {
      const nextRow = this.rows[rowIndex + 1];
      nextRow.setEditMode();
      nextRow.focusFirstEditableCell();
    }
  }

  async confirmAndMovePrev(row: GridRowModel, rowIndex: number) {
    await row.confirm();

    if (rowIndex === 0) {
      // noop
    } else if (this.canEdit && this.canSwitchRow) {
      const prevRow = this.rows[rowIndex - 1];
      prevRow.setEditMode();
      prevRow.focusLastEditableCell();
    }
  }

  setDisplayMode() {
    this.rows.filter(t => t.isEdit === true).forEach((r) => { r.setDisplayMode(); });
  }

  get canSwitchRow(): boolean {
    return !(this.rows.filter(r => r.isEdit === true && r.$isBusy === true).length > 0);
  }

  async onCommandAddLineClicked() {
    if (this.canAdd && this.canSwitchRow && !this.addLineModel.control.readOnly) {
      this.setDisplayMode();
      const row = await this.addNewRowFn();
      row.focusFirstEditableCell();
    }
  }

  commandConfirmClicked(row: GridRowModel) {
    row.confirm();
  }

  commandCancelNewRowClicked(row: GridRowModel) {
    this.removeRow(row);
  }
  commandCancelExistingRowClicked(row: GridRowModel) {
    row.setDisplayMode();
    row.refresh();
  }

  onExpandRowClicked(row: GridRowModel) {
    row.expand = !row.expand;
  }

  getHeaderStyle(column: GridHeaderModel) {
    let dynamicStyles;

    if (this.containerStyles.columnSizingType === 'headersWidth' && column.width) {
      const widthInRem = this.getWidthInRem(column);
      dynamicStyles = { 'min-width': `${widthInRem}rem` };
    } else if (this.containerStyles.columnSizingType === 'fixedWidth') {
      const widthInRem = this.getWidthInRem(column);
      dynamicStyles = { 'width': `${widthInRem}rem` };
    } else {
      dynamicStyles = {};
    }

    return Object.assign(dynamicStyles, column.styles.style);
  }

  getCellStyle(column: GridHeaderModel, cell: GridCellModel<ControlModel, ControlModel | void>) {
    let dynamicStyles;

    if (this.containerStyles.columnSizingType === 'cellsWidth' && column.width) {
      const widthInRem = this.getWidthInRem(column);
      dynamicStyles = { 'max-width': `${widthInRem}rem` };
    } else {
      dynamicStyles = {};
    }

    return Object.assign(dynamicStyles, cell.styles.style);
  }

  get tableStyleComputed() {
    let dynamicStyles;
    if (this.containerStyles.columnSizingType === 'fixedWidth') {
      let totalColumnWidth = 0;
      const columns = Object.keys(this.headers).map(i => this.headers[i]);
      for (let column of columns) {
        const widthInRem = this.getWidthInRem(column);
        totalColumnWidth += widthInRem;
      }
      if (this.selection) {
        totalColumnWidth += 2.5; // 2.5rem for checkbox
      }
      if (this.canEdit) { // TODO
        totalColumnWidth += 5;// 5rem for cell-command
      }

      dynamicStyles = { 'width': `${totalColumnWidth}rem` };
    } else {
      dynamicStyles = {};
    }

    return Object.assign(dynamicStyles, {});
  }

  getWidthInRem(column) {
    return column.hidden
      ? 0
      : (column.width ?? 100) / 16;
  }

  onCheckboxClick(event: MouseEvent, rowIndex: number): void {
    if (event.shiftKey && this.lastClickedIndex !== null) {
      const start = Math.min(this.lastClickedIndex, rowIndex);
      const end = Math.max(this.lastClickedIndex, rowIndex);
      const selectedState = this.rows[this.lastClickedIndex].selected;

      for (let i = start; i <= end; i++) {
        this.rows[i].selected = selectedState;
      }
    } else {
      this.rows[rowIndex].selected = !this.rows[rowIndex].selected;
      this.lastClickedIndex = rowIndex;
    }

    event.preventDefault();
    event.stopPropagation();

    this.updateSelection();
  }
}
