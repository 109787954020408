import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Attachments_ds_get_attachments_by_entityService } from './Attachments.datasource.index';

@Injectable({ providedIn: 'root' })
export class Attachments_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Attachments: Attachments_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_attachments_by_entity: Attachments_ds_get_attachments_by_entityService;
  public get ds_get_attachments_by_entity(): Attachments_ds_get_attachments_by_entityService {
    if(!this._ds_get_attachments_by_entity) {
      this._ds_get_attachments_by_entity = this.injector.get(Attachments_ds_get_attachments_by_entityService);
    }
    return this._ds_get_attachments_by_entity;
  }
}

