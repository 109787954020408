import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LaborManagement_ds_get_work_class_by_workClassIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { workClassId: number }): 
  Promise<{ result: { Id?: number, Description?: string, LookupCode?: string, Name?: string, OperationCodes?: { Id?: number, Priority?: number, OperationCode?: { Id?: number, Name?: string } }[], EquipmentTypes?: { Id?: number, EquipmentType?: { Id?: number, LookupCode?: string } }[], Zones?: { Id?: number }[], AssignmentListForWorkClass?: { UserId?: string }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.workClassId)) {
      missingRequiredInParams.push('\'workClassId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_get_work_class_by_workClassId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
