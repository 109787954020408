import {
  Component,
  Inject,
  ComponentRef,
  TemplateRef,
  Type
} from '@angular/core';

import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';

import { isNil, has } from 'lodash-es';

export interface DialogComponentData {
  component: any;
  title: string;
  inParams: any;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {

  title: string;
  commandsTmpRef: TemplateRef<any>;

  // ndc inputs/outputs
  inputs: any;
  outputs = {
    $titleChange: (t) => { this.title = t; },
    $finish: () => { this.close(); },
    // TODO: ExpressionChangedAfterItHasBeenCheckedError
    $commandsTmpRef: (c) => { Promise.resolve().then(() => { this.commandsTmpRef = c; }); }
  }

  // the component instance;
  componentType: Type<any>;
  component?: any;

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogComponentData
  ) {
    this.title = dialogData.title;
    this.componentType = dialogData.component;
    if (!isNil(dialogData.inParams)) {
      this.inputs = dialogData.inParams;
    }
  }

  onComponentCreated(compRef: ComponentRef<any>) {
    this.component = compRef.instance;
    if (this.component.hasOwnProperty('showInDialog')) {
      this.component.showInDialog = true;
    }
  }

  close() {
    if (has(this.component, 'outParams')) {
      this.dialogRef.close(this.component.outParams);
    } else {
      this.dialogRef.close();
    }
  }
}
