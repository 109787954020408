<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inventory_statuses.hidden" class="tab" data-cy="tab-inventory_statuses">
              <h2 [className]="tabs.inventory_statuses.active? 'active': ''" (click)="tabs.inventory_statuses.activate()">{{tabs.inventory_statuses.title}}</h2>
            </div>
            <div *ngIf="!tabs.container_types.hidden" class="tab" data-cy="tab-container_types">
              <h2 [className]="tabs.container_types.active? 'active': ''" (click)="tabs.container_types.activate()">{{tabs.container_types.title}}</h2>
            </div>
            <div *ngIf="!tabs.pallet_classes.hidden" class="tab" data-cy="tab-pallet_classes">
              <h2 [className]="tabs.pallet_classes.active? 'active': ''" (click)="tabs.pallet_classes.activate()">{{tabs.pallet_classes.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inventory_statuses_grid *ngIf="tabs.inventory_statuses.active"
              #$tabs_inventory_statuses
              ($refreshEvent)="refresh(false, false, '$tabs_inventory_statuses')">
              </FootPrintManager-inventory_statuses_grid>
              <FootPrintManager-container_types_grid *ngIf="tabs.container_types.active"
              #$tabs_container_types
              ($refreshEvent)="refresh(false, false, '$tabs_container_types')">
              </FootPrintManager-container_types_grid>
              <FootPrintManager-pallet_classes_grid *ngIf="tabs.pallet_classes.active"
              #$tabs_pallet_classes
              ($refreshEvent)="refresh(false, false, '$tabs_pallet_classes')">
              </FootPrintManager-pallet_classes_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>