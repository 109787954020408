import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Subject, firstValueFrom } from 'rxjs';
import { isNil, cloneDeep } from 'lodash-es';

import {
  ConfirmationDialogComponent,
  IConfirmationDialogComponentData
} from './components/confirmation-dialog.component';

import {
  ErrorDialogComponent,
  IErrorDialogComponentData
} from './error/error-dialog.component';
import { DialogComponent } from './components/dialog.component';
import { ImageViewerDialogComponent } from './components/image-viewer-dialog.component';
import { ToastrService } from 'ngx-toastr';

export enum EModalSize {
  Small = 1,
  Standard,
  Large,
  Xlarge
}

export enum EToasterType {
  Error,
  Warning,
  Info,
  Success
}

export enum EToasterPosition {
  topRight = 'toast-top-right',
  topLeft = 'toast-top-left',
  bottomRight = 'toast-bottom-right',
  bottomLeft = 'toast-bottom-left',
}

export interface IShellService {
  openConfirmationDialog(title: string, description: string, continueText?: string, cancelText?: string);
  openInfoDialog(title: string, message: string);
  openToaster(title: string, message: string, type: EToasterType, options?: any): void;
  openErrorDialog(
    title: string,
    message: string,
    messageList?: string[],
    messageListDetailsSectionTitle?: string,
    messageListDetails?: { message: string, detail: string }[]
  );
  showErrorDetails(
    title: string,
    message: string,
    error: any
  );
}

export abstract class ShellService implements IShellService {
  public static openViewRequest$ = new Subject<{ title: string, referenceName: string, component: any, replaceCurrentView?: boolean, inParams?: any }>();

  constructor(
    protected dialog: MatDialog,
    private toastr: ToastrService
  ) {
  }

  public openConfirmationDialog(title: string, description: string, continueText = 'Continue', cancelText = 'Cancel') {
    const modalConfig: MatDialogConfig = {
      panelClass: 'popup-message',
      backdropClass: 'datex-backdrop',
      data: {
        title: title,
        message: description,
        confirmText: continueText,
        cancelText: cancelText
      } as IConfirmationDialogComponentData
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, modalConfig);
    return firstValueFrom(dialogRef.afterClosed());
  }

  public openInfoDialog(title: string, message: string) {
    const modalConfig: MatDialogConfig = {
      panelClass: 'popup-message',
      backdropClass: 'datex-backdrop',
      data: {
        title: title,
        message: message,
        confirmText: 'OK'
      } as IConfirmationDialogComponentData
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, modalConfig);
    return firstValueFrom(dialogRef.afterClosed());
  }

  public openToaster(title: string, message: string, type: EToasterType, options?: any): void {

    if (type === EToasterType.Error) {
      this.toastr.error(message, title, options);
    } else if (type === EToasterType.Warning) {
      this.toastr.warning(message, title, options);
    } else if (type === EToasterType.Info) {
      this.toastr.info(message, title, options);
    } else if (type === EToasterType.Success) {
      this.toastr.success(message, title, options);
    }
  }

  public openImageViewerDialog(imageSource: string | Blob) {
    const modalConfig: MatDialogConfig = {
      panelClass: 'full-screen-modal',
      backdropClass: 'datex-backdrop',
      data: {
        imageSource: imageSource
      }
    };
    const dialogRef = this.dialog.open(ImageViewerDialogComponent, modalConfig);
    return firstValueFrom(dialogRef.afterClosed());
  }

  public openErrorDialog(
    title: string,
    message: string,
    messageList?: string[],
    messageListDetailsSectionTitle?: string,
    messageListDetails?: { message: string, detail: string }[]
  ) {
    const modalConfig: MatDialogConfig = {
      panelClass: 'popup-message',
      backdropClass: 'datex-backdrop',
      data: {
        title: title,
        message: message,
        messageList: messageList,
        messageListDetailsSectionTitle: messageListDetailsSectionTitle,
        messageListDetails: messageListDetails
      } as IErrorDialogComponentData
    };
    const dialogRef = this.dialog.open(ErrorDialogComponent, modalConfig);
    return firstValueFrom(dialogRef.afterClosed());
  }

  public showErrorDetails(
    title: string,
    message: string,
    error: any
  ) {
    const errorMessage = !isNil(error?.error?.error) ? error?.error?.error.message : error;
    const errorDetail = !isNil(error?.error?.error) ? error?.error?.error : error;
    const errorDescription = `${errorMessage}`;
    return this.openErrorDialog(title, message, [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }

  protected openDialog(component: any, title: string, mode?: string, size?: EModalSize, inParams?: any): Promise<any> {
    let panelClass = ['datex-modal', 'large'];
    if (size) {
      if (size === EModalSize.Xlarge) {
        panelClass = ['full-screen-modal'];
      } else if (size === EModalSize.Large) {
        panelClass = ['datex-modal', 'large'];
      } else if (size === EModalSize.Standard) {
        panelClass = ['datex-modal', 'standard'];
      } else if (size === EModalSize.Small) {
        panelClass = ['datex-modal', 'small'];
      }
    }
    const modalConfig: MatDialogConfig = {
      panelClass: panelClass,
      backdropClass: 'datex-backdrop',
      disableClose: true,
      data: {
        component: component,
        title: title,
        inParams: cloneDeep(inParams)
      }
    };

    if (mode === 'flyout') {
      let flyoutPanelClass = ['flyout-panel'];
      if (size) {
        if (size === EModalSize.Large) {
          flyoutPanelClass.push('large');
        } else if (size === EModalSize.Xlarge) {
          flyoutPanelClass.push('xlarge');
        }
      }
      modalConfig.position = { right: '0' };
      modalConfig.panelClass = flyoutPanelClass;
      modalConfig.backdropClass = 'flyout-backdrop';
    }

    const dialogRef = this.dialog.open(DialogComponent, modalConfig);
    return firstValueFrom(dialogRef.afterClosed());
  }

  protected convertToNumber(val: string): number {
    const convertedValue = Number(val);
    const isNumber = isNaN(convertedValue) === false;
    if (isNumber) {
      return convertedValue;
    }
    return null;
  }

  protected convertToBoolean(val: string): boolean {
    if (val === 'true' || val === 'True') {
      return true;
    } else if (val === 'false' || val === 'False') {
      return false;
    }
    return null;
  }

  protected convertToJSON(val: string): any {
    try {
      return JSON.parse(val);
    }
    catch (error) {
      console.error(`error parsing string [${val}] to JSON`, error);
      return null;
    }
  }
}
