import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Replenishments_ds_get_available_inventory_by_licenseplateId_lotId_packagedIdService } from './Replenishments.datasource.index';
import { Replenishments_ds_get_hard_allocations_by_task_idService } from './Replenishments.datasource.index';
import { Replenishments_ds_get_inventorymeasurementunit_by_packagedIdService } from './Replenishments.datasource.index';
import { Replenishments_ds_get_last_pick_date_by_locationIdService } from './Replenishments.datasource.index';
import { Replenishments_ds_get_replenishment_tasks_by_idService } from './Replenishments.datasource.index';
import { Replenishments_ds_get_total_released_pick_quantity_by_locationId_lotIdService } from './Replenishments.datasource.index';
import { Replenishments_ds_location_replenishment_editorService } from './Replenishments.datasource.index';
import { Replenishments_ds_locations_replenishments_gridService } from './Replenishments.datasource.index';
import { Replenishments_ds_replenishment_comparison_ddService } from './Replenishments.datasource.index';
import { Replenishments_ds_replenishment_inventory_licenseplate_gridService } from './Replenishments.datasource.index';
import { Replenishments_ds_replenishment_tasks_gridService } from './Replenishments.datasource.index';

@Injectable({ providedIn: 'root' })
export class Replenishments_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Replenishments: Replenishments_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_available_inventory_by_licenseplateId_lotId_packagedId: Replenishments_ds_get_available_inventory_by_licenseplateId_lotId_packagedIdService;
  public get ds_get_available_inventory_by_licenseplateId_lotId_packagedId(): Replenishments_ds_get_available_inventory_by_licenseplateId_lotId_packagedIdService {
    if(!this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId) {
      this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId = this.injector.get(Replenishments_ds_get_available_inventory_by_licenseplateId_lotId_packagedIdService);
    }
    return this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId;
  }
  private _ds_get_hard_allocations_by_task_id: Replenishments_ds_get_hard_allocations_by_task_idService;
  public get ds_get_hard_allocations_by_task_id(): Replenishments_ds_get_hard_allocations_by_task_idService {
    if(!this._ds_get_hard_allocations_by_task_id) {
      this._ds_get_hard_allocations_by_task_id = this.injector.get(Replenishments_ds_get_hard_allocations_by_task_idService);
    }
    return this._ds_get_hard_allocations_by_task_id;
  }
  private _ds_get_inventorymeasurementunit_by_packagedId: Replenishments_ds_get_inventorymeasurementunit_by_packagedIdService;
  public get ds_get_inventorymeasurementunit_by_packagedId(): Replenishments_ds_get_inventorymeasurementunit_by_packagedIdService {
    if(!this._ds_get_inventorymeasurementunit_by_packagedId) {
      this._ds_get_inventorymeasurementunit_by_packagedId = this.injector.get(Replenishments_ds_get_inventorymeasurementunit_by_packagedIdService);
    }
    return this._ds_get_inventorymeasurementunit_by_packagedId;
  }
  private _ds_get_last_pick_date_by_locationId: Replenishments_ds_get_last_pick_date_by_locationIdService;
  public get ds_get_last_pick_date_by_locationId(): Replenishments_ds_get_last_pick_date_by_locationIdService {
    if(!this._ds_get_last_pick_date_by_locationId) {
      this._ds_get_last_pick_date_by_locationId = this.injector.get(Replenishments_ds_get_last_pick_date_by_locationIdService);
    }
    return this._ds_get_last_pick_date_by_locationId;
  }
  private _ds_get_replenishment_tasks_by_id: Replenishments_ds_get_replenishment_tasks_by_idService;
  public get ds_get_replenishment_tasks_by_id(): Replenishments_ds_get_replenishment_tasks_by_idService {
    if(!this._ds_get_replenishment_tasks_by_id) {
      this._ds_get_replenishment_tasks_by_id = this.injector.get(Replenishments_ds_get_replenishment_tasks_by_idService);
    }
    return this._ds_get_replenishment_tasks_by_id;
  }
  private _ds_get_total_released_pick_quantity_by_locationId_lotId: Replenishments_ds_get_total_released_pick_quantity_by_locationId_lotIdService;
  public get ds_get_total_released_pick_quantity_by_locationId_lotId(): Replenishments_ds_get_total_released_pick_quantity_by_locationId_lotIdService {
    if(!this._ds_get_total_released_pick_quantity_by_locationId_lotId) {
      this._ds_get_total_released_pick_quantity_by_locationId_lotId = this.injector.get(Replenishments_ds_get_total_released_pick_quantity_by_locationId_lotIdService);
    }
    return this._ds_get_total_released_pick_quantity_by_locationId_lotId;
  }
  private _ds_location_replenishment_editor: Replenishments_ds_location_replenishment_editorService;
  public get ds_location_replenishment_editor(): Replenishments_ds_location_replenishment_editorService {
    if(!this._ds_location_replenishment_editor) {
      this._ds_location_replenishment_editor = this.injector.get(Replenishments_ds_location_replenishment_editorService);
    }
    return this._ds_location_replenishment_editor;
  }
  private _ds_locations_replenishments_grid: Replenishments_ds_locations_replenishments_gridService;
  public get ds_locations_replenishments_grid(): Replenishments_ds_locations_replenishments_gridService {
    if(!this._ds_locations_replenishments_grid) {
      this._ds_locations_replenishments_grid = this.injector.get(Replenishments_ds_locations_replenishments_gridService);
    }
    return this._ds_locations_replenishments_grid;
  }
  private _ds_replenishment_comparison_dd: Replenishments_ds_replenishment_comparison_ddService;
  public get ds_replenishment_comparison_dd(): Replenishments_ds_replenishment_comparison_ddService {
    if(!this._ds_replenishment_comparison_dd) {
      this._ds_replenishment_comparison_dd = this.injector.get(Replenishments_ds_replenishment_comparison_ddService);
    }
    return this._ds_replenishment_comparison_dd;
  }
  private _ds_replenishment_inventory_licenseplate_grid: Replenishments_ds_replenishment_inventory_licenseplate_gridService;
  public get ds_replenishment_inventory_licenseplate_grid(): Replenishments_ds_replenishment_inventory_licenseplate_gridService {
    if(!this._ds_replenishment_inventory_licenseplate_grid) {
      this._ds_replenishment_inventory_licenseplate_grid = this.injector.get(Replenishments_ds_replenishment_inventory_licenseplate_gridService);
    }
    return this._ds_replenishment_inventory_licenseplate_grid;
  }
  private _ds_replenishment_tasks_grid: Replenishments_ds_replenishment_tasks_gridService;
  public get ds_replenishment_tasks_grid(): Replenishments_ds_replenishment_tasks_gridService {
    if(!this._ds_replenishment_tasks_grid) {
      this._ds_replenishment_tasks_grid = this.injector.get(Replenishments_ds_replenishment_tasks_gridService);
    }
    return this._ds_replenishment_tasks_grid;
  }
}

