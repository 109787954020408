import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manifesting_ds_get_container_type_by_containerTypeIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { containerTypeId: number }): 
  Promise<{ result: { Id?: number, Height?: number, Length?: number, Name?: string, Weight?: number, WeightCapacity?: number, Width?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.containerTypeId)) {
      missingRequiredInParams.push('\'containerTypeId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manifesting/datasources/ds_get_container_type_by_containerTypeId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { containerTypeId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Height?: number, Length?: number, Name?: string, Weight?: number, WeightCapacity?: number, Width?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.containerTypeId)) {
      missingRequiredInParams.push('\'containerTypeId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manifesting/datasources/ds_get_container_type_by_containerTypeId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { containerTypeId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, Height?: number, Length?: number, Name?: string, Weight?: number, WeightCapacity?: number, Width?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.containerTypeId)) {
      missingRequiredInParams.push('\'containerTypeId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manifesting/datasources/ds_get_container_type_by_containerTypeId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
