import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { XMLValidator } from 'fast-xml-parser';
import { isEmpty } from 'lodash-es';

@Directive({
  selector: '[CodeValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CodeValidatorDirective,
    multi: true
  }]
})
export class CodeValidatorDirective implements Validator {
  @Input('CodeValidator') mode: string;

  validate(control: AbstractControl): ValidationErrors | null {
    let code = control.value;

    if (isEmpty(code)) {
      return null; // so we don't validate empty values
    }

    const validationResult = this.mode == 'xml' ? this.validateXmlCode(code) : this.validateJsonCode(code);
    return validationResult ? { codeError: validationResult } : null;
  }

  private validateXmlCode(xmlCode: string): string {
    const options = {
      ignoreAttributes: false, // Set to true if you want to ignore attributes
      ignoreNameSpace: true, // Set to true if you want to ignore XML namespaces
      allowBooleanAttributes: false, // Set to true if you want to allow boolean attributes
      parseNodeValue: true, // Set to true if you want to parse the text value of elements
      parseAttributeValue: false, // Set to true if you want to parse attribute values
      trimValues: true, // Set to true if you want to trim whitespace around values
    };

    const validationResult = XMLValidator.validate(xmlCode, options);

    if (validationResult !== true) {
      return validationResult.err.msg;
    }

    return null; // No error
  }

  private validateJsonCode(jsonCode: string): string {
    try {
      JSON.parse(jsonCode);
    } catch (error) {
      return error.message;
    }

    return null; // No error
  }
}