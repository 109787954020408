import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 



@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-task_details_editor',
  templateUrl: './FootPrintManager.task_details_editor.component.html'
})
export class FootPrintManager_task_details_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { taskId: number, is_non_editable?: boolean } = { taskId: null, is_non_editable: null };
  //#region Inputs
  @Input('taskId') set $inParams_taskId(v: number) {
    this.inParams.taskId = v;
  }
  get $inParams_taskId(): number {
    return this.inParams.taskId;
  }
  @Input('is_non_editable') set $inParams_is_non_editable(v: boolean) {
    this.inParams.is_non_editable = v;
  }
  get $inParams_is_non_editable(): boolean {
    return this.inParams.is_non_editable;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { isConfirmed?: boolean } = { isConfirmed: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, ExpectedPackagedAmount?: number, ModifiedSysDateTime?: string, Notes?: string, Warehouse?: { Name?: string, Notes?: string }, Status?: { Name?: string }, OperationCode?: { Name?: string }, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, Project?: { LookupCode?: string }, SerialNumber?: { LookupCode?: string }, ActualPackagedPack?: { Name?: string }, ExpectedPackagedPack?: { Name?: string }, ActualSourceLicensePlate?: { LookupCode?: string }, ActualSourceLocation?: { Name?: string }, ActualTargetLicensePlate?: { LookupCode?: string }, ActualTargetLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, ExpectedSourceLocation?: { Name?: string }, ExpectedTargetLicensePlate?: { LookupCode?: string }, ExpectedTargetLocation?: { Name?: string }, Order?: { LookupCode?: string, Notes?: string, OwnerReference?: string, VendorReference?: string }, PickSlip?: { Id?: number, Wave?: { Id?: number } } };

  formGroup: FormGroup = new FormGroup({
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    operation_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_sys_user: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    compleyted_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    serial_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual_packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual_source_lp: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual_source_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual_target_lp: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual_target_loc: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_source_lp: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_source_loc: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_target_lp: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_target_loc: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    wave: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_po: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
  };

  fields = {
    warehouse: new FieldModel(new TextBoxModel(this.formGroup.controls['warehouse'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
    status: new FieldModel(new TextBoxModel(this.formGroup.controls['status'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    operation_code: new FieldModel(new TextBoxModel(this.formGroup.controls['operation_code'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Operation code', false)
,
    created_on: new FieldModel(new TextBoxModel(this.formGroup.controls['created_on'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Created on', false)
,
    created_sys_user: new FieldModel(new TextBoxModel(this.formGroup.controls['created_sys_user'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Created by', false)
,
    compleyted_on: new FieldModel(new TextBoxModel(this.formGroup.controls['compleyted_on'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Completed on', false)
,
    project: new FieldModel(new TextBoxModel(this.formGroup.controls['project'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    material: new FieldModel(new TextBoxModel(this.formGroup.controls['material'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Material', false)
,
    lot: new FieldModel(new TextBoxModel(this.formGroup.controls['lot'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Lot', false)
,
    vendor_lot: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_lot'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Vendor lot', false)
,
    serial_number: new FieldModel(new TextBoxModel(this.formGroup.controls['serial_number'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Serial number', false)
,
    actual: new FieldModel(new TextBoxModel(this.formGroup.controls['actual'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Actual', false)
,
    actual_packaging: new FieldModel(new TextBoxModel(this.formGroup.controls['actual_packaging'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Actual packaging', false)
,
    expected: new FieldModel(new TextBoxModel(this.formGroup.controls['expected'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Expected', false)
,
    expected_packaging: new FieldModel(new TextBoxModel(this.formGroup.controls['expected_packaging'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Expected packaging', false)
,
    actual_source_lp: new FieldModel(new TextBoxModel(this.formGroup.controls['actual_source_lp'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Actual source LP', false)
,
    actual_source_location: new FieldModel(new TextBoxModel(this.formGroup.controls['actual_source_location'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Actual source loc', false)
,
    actual_target_lp: new FieldModel(new TextBoxModel(this.formGroup.controls['actual_target_lp'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Actual target LP', false)
,
    actual_target_loc: new FieldModel(new TextBoxModel(this.formGroup.controls['actual_target_loc'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Actual target loc', false)
,
    expected_source_lp: new FieldModel(new TextBoxModel(this.formGroup.controls['expected_source_lp'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Expected source LP', false)
,
    expected_source_loc: new FieldModel(new TextBoxModel(this.formGroup.controls['expected_source_loc'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Expected source loc', false)
,
    expected_target_lp: new FieldModel(new TextBoxModel(this.formGroup.controls['expected_target_lp'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Expected target LP', false)
,
    expected_target_loc: new FieldModel(new TextBoxModel(this.formGroup.controls['expected_target_loc'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Expected target loc', false)
,
    order: new FieldModel(new TextBoxModel(this.formGroup.controls['order'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Order', false)
,
    wave: new FieldModel(new TextBoxModel(this.formGroup.controls['wave'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Wave', false)
,
    order_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['order_reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Order reference', false)
,
    order_po: new FieldModel(new TextBoxModel(this.formGroup.controls['order_po'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Order PO', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
  };

  fieldsets = {
  general: new FieldsetModel('General', false, true, true),
  details: new FieldsetModel('Details', false, false, true),
};


  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    operation_code: this.fields.operation_code.control.valueChanges
    ,
    created_on: this.fields.created_on.control.valueChanges
    ,
    created_sys_user: this.fields.created_sys_user.control.valueChanges
    ,
    compleyted_on: this.fields.compleyted_on.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    lot: this.fields.lot.control.valueChanges
    ,
    vendor_lot: this.fields.vendor_lot.control.valueChanges
    ,
    serial_number: this.fields.serial_number.control.valueChanges
    ,
    actual: this.fields.actual.control.valueChanges
    ,
    actual_packaging: this.fields.actual_packaging.control.valueChanges
    ,
    expected: this.fields.expected.control.valueChanges
    ,
    expected_packaging: this.fields.expected_packaging.control.valueChanges
    ,
    actual_source_lp: this.fields.actual_source_lp.control.valueChanges
    ,
    actual_source_location: this.fields.actual_source_location.control.valueChanges
    ,
    actual_target_lp: this.fields.actual_target_lp.control.valueChanges
    ,
    actual_target_loc: this.fields.actual_target_loc.control.valueChanges
    ,
    expected_source_lp: this.fields.expected_source_lp.control.valueChanges
    ,
    expected_source_loc: this.fields.expected_source_loc.control.valueChanges
    ,
    expected_target_lp: this.fields.expected_target_lp.control.valueChanges
    ,
    expected_target_loc: this.fields.expected_target_loc.control.valueChanges
    ,
    order: this.fields.order.control.valueChanges
    ,
    wave: this.fields.wave.control.valueChanges
    ,
    order_reference: this.fields.order_reference.control.valueChanges
    ,
    order_po: this.fields.order_po.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.taskId)) {
        this.$missingRequiredInParams.push('taskId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Task Detail';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      task_id:  $editor.inParams.taskId 
    };

    const data = await this.datasources.FootPrintManager.ds_get_task_details.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.warehouse.control as TextBoxModel).reset($editor.entity?.Warehouse?.Name);
    (this.fields.status.control as TextBoxModel).reset($editor.entity.Status.Name);
    (this.fields.operation_code.control as TextBoxModel).reset($editor.entity.OperationCode.Name);
    
    (this.fields.created_sys_user.control as TextBoxModel).reset($editor.entity?.CreatedSysUser);
    
    (this.fields.project.control as TextBoxModel).reset($editor?.entity?.Project?.LookupCode);
    (this.fields.material.control as TextBoxModel).reset($editor.entity.Material?.LookupCode);
    (this.fields.lot.control as TextBoxModel).reset($editor.entity.Lot?.LookupCode);
    (this.fields.vendor_lot.control as TextBoxModel).reset($editor.entity.VendorLot?.LookupCode);
    (this.fields.serial_number.control as TextBoxModel).reset($editor?.entity?.SerialNumber?.LookupCode);
    (this.fields.actual.control as TextBoxModel).reset($editor?.entity?.ActualPackagedAmount?.toString());
    (this.fields.actual_packaging.control as TextBoxModel).reset($editor.entity.ActualPackagedPack?.Name);
    (this.fields.expected.control as TextBoxModel).reset($editor.entity?.ExpectedPackagedAmount?.toString());
    (this.fields.expected_packaging.control as TextBoxModel).reset($editor.entity?.ExpectedPackagedPack?.Name);
    (this.fields.actual_source_lp.control as TextBoxModel).reset($editor.entity?.ActualSourceLicensePlate?.LookupCode);
    (this.fields.actual_source_location.control as TextBoxModel).reset($editor.entity?.ActualSourceLocation?.Name);
    (this.fields.actual_target_lp.control as TextBoxModel).reset($editor.entity?.ActualTargetLicensePlate?.LookupCode);
    (this.fields.actual_target_loc.control as TextBoxModel).reset($editor.entity?.ActualTargetLocation?.Name);
    (this.fields.expected_source_lp.control as TextBoxModel).reset($editor.entity?.ExpectedSourceLicensePlate?.LookupCode);
    (this.fields.expected_source_loc.control as TextBoxModel).reset($editor.entity?.ExpectedSourceLocation?.Name);
    (this.fields.expected_target_lp.control as TextBoxModel).reset($editor.entity?.ExpectedTargetLicensePlate?.LookupCode);
    (this.fields.expected_target_loc.control as TextBoxModel).reset($editor.entity?.ExpectedTargetLocation?.Name);
    (this.fields.order.control as TextBoxModel).reset($editor.entity.Order?.LookupCode);
    (this.fields.wave.control as TextBoxModel).reset($editor.entity.PickSlip?.Wave?.Id?.toString());
    (this.fields.order_reference.control as TextBoxModel).reset($editor.entity?.Order?.OwnerReference);
    (this.fields.order_po.control as TextBoxModel).reset($editor.entity?.Order?.OwnerReference);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity?.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .operation_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .created_on
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .created_sys_user
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .compleyted_on
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .project
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vendor_lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .serial_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .actual
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .actual_packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expected
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expected_packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .actual_source_lp
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .actual_source_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .actual_target_lp
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .actual_target_loc
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expected_source_lp
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expected_source_loc
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expected_target_lp
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expected_target_loc
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .wave
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order_reference
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order_po
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_task_details_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  for (let key in $editor.fields)
  {
  $editor.fields[key].hidden = true
  }
  
  /*
      $editor.fields.warehouse.hidden = false;
      $editor.fields.status.hidden = false;
      $editor.fields.operation_code.hidden = false;
      $editor.fields.created_on.hidden = false;
      $editor.fields.created_sys_user.hidden = false;
      $editor.fields.compleyted_on.hidden = false;
      $editor.fields.project.hidden = false;
      $editor.fields.material.hidden = false;
      $editor.fields.lot.hidden = false;
      $editor.fields.vendor_lot.hidden = false;
      $editor.fields.serial_number.hidden = false;
      $editor.fields.actual.hidden = false;
      $editor.fields.actual_packaging.hidden = false;
      $editor.fields.expected.hidden = false;
      $editor.fields.expected_packaging.hidden = false;
      $editor.fields.actual_source_lp.hidden = false;
      $editor.fields.actual_source_location.hidden = false;
      $editor.fields.actual_target_lp.hidden = false;
      $editor.fields.actual_target_loc.hidden = false;
      $editor.fields.expected_source_lp.hidden = false;
      $editor.fields.expected_source_loc.hidden = false;
      $editor.fields.expected_target_lp.hidden = false;
      $editor.fields.expected_target_loc.hidden = false;
      $editor.fields.order.hidden = false;
      $editor.fields.wave.hidden = false;
      $editor.fields.order_reference.hidden = false;
      $editor.fields.order_po.hidden = false;
      $editor.fields.notes.hidden = false;
  
  */
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_task_details_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // General
  if ($utils.isDefined($editor.entity.Warehouse?.Name)) {
      $editor.fields.warehouse.hidden = false;
      $editor.fields.warehouse.control.readOnly = true;
  }
  if ($utils.isDefined($editor.entity?.Status)) {
      $editor.fields.status.hidden = false;
      $editor.fields.status.control.readOnly = true;
  }
  
  if ($utils.isDefined($editor.entity?.OperationCode?.Name)) {
      $editor.fields.operation_code.hidden = false;
      $editor.fields.operation_code.control.readOnly = true;
  }
  
  if ($utils.isDefined($editor.entity?.CreatedSysDateTime)) {
      $editor.fields.created_on.hidden = false;
      $editor.fields.created_on.control.readOnly = true;
  }
  if ($utils.isDefined($editor.entity?.CreatedSysUser)) {
      $editor.fields.created_sys_user.hidden = false;
      $editor.fields.created_sys_user.control.readOnly = true;
  }
  if ($utils.isDefined($editor.entity?.CompletedDateTime)) {
      $editor.fields.compleyted_on.hidden = false;
      $editor.fields.compleyted_on.control.readOnly = true;
  }
  
  
  
  // Details
  let hasDetails = false;
  if ($utils.isDefined($editor.entity?.Project?.LookupCode)) {
      $editor.fields.project.hidden = false;
      $editor.fields.project.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.Material)) {
      $editor.fields.material.hidden = false;
      $editor.fields.material.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.Lot)) {
      $editor.fields.lot.hidden = false;
      $editor.fields.lot.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.VendorLot)) {
      $editor.fields.vendor_lot.hidden = false;
      $editor.fields.vendor_lot.control.readOnly = true;
      hasDetails = true;
  }
  ///
  if ($utils.isDefined($editor.entity?.SerialNumber?.LookupCode)) {
      $editor.fields.serial_number.hidden = false;
      $editor.fields.serial_number.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ActualPackagedAmount)) {
      $editor.fields.actual.hidden = false;
      $editor.fields.actual.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ActualPackagedPack?.Name)) {
      $editor.fields.actual_packaging.hidden = false;
      $editor.fields.actual_packaging.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ExpectedPackagedAmount)) {
      $editor.fields.expected.hidden = false;
      $editor.fields.expected.control.readOnly = true;
  }
  if ($utils.isDefined($editor.entity?.ExpectedPackagedPack?.Name)) {
      $editor.fields.expected_packaging.hidden = false;
      $editor.fields.expected_packaging.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ActualSourceLicensePlate?.LookupCode)) {
      $editor.fields.actual_source_lp.hidden = false;
      $editor.fields.actual_source_lp.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ActualSourceLocation?.Name)) {
      $editor.fields.actual_source_location.hidden = false;
      $editor.fields.actual_source_location.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ActualTargetLicensePlate?.LookupCode)) {
      $editor.fields.actual_target_lp.hidden = false;
      $editor.fields.actual_target_lp.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ActualTargetLocation?.Name)) {
      $editor.fields.actual_target_loc.hidden = false;
      $editor.fields.actual_target_loc.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ExpectedSourceLicensePlate?.LookupCode)) {
      $editor.fields.expected_source_lp.hidden = false;
      $editor.fields.expected_source_lp.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ExpectedSourceLocation?.Name)) {
      $editor.fields.expected_source_loc.hidden = false;
      $editor.fields.expected_source_loc.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ExpectedTargetLicensePlate?.LookupCode)) {
      $editor.fields.expected_target_lp.hidden = false;
      $editor.fields.expected_target_lp.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.ExpectedTargetLocation?.Name)) {
      $editor.fields.expected_target_loc.hidden = false;
      $editor.fields.expected_target_loc.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.Order)) {
      $editor.fields.order.hidden = false;
      $editor.fields.order.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.PickSlip?.Wave)) {
      $editor.fields.wave.hidden = false;
      $editor.fields.wave.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.Order?.OwnerReference)) {
      $editor.fields.order_reference.hidden = false;
      $editor.fields.order_reference.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.Order?.VendorReference)) {
      $editor.fields.order_po.hidden = false;
      $editor.fields.order_po.control.readOnly = true;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity?.Notes)) {
      $editor.fields.notes.hidden = false;
      $editor.fields.notes.control.readOnly = true;
      hasDetails = true;
  }
  
  
  if (!hasDetails) { $editor.fieldsets.details.hidden = true; }
  
  // Format datetime
  $editor.fields.created_on.control.value = $utils.date.format($editor?.entity?.CreatedSysDateTime, `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.trim().toUpperCase() === '12 HOUR' ? 'LT' : 'H:MM'}`);
  if ($utils.isDefined($editor.entity?.CompletedDateTime)) {
  $editor.fields.compleyted_on.control.value = $utils.date.format($editor?.entity?.CompletedDateTime, `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.trim().toUpperCase() === '12 HOUR' ? 'LT' : 'H:MM'}`);
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_task_details_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'task_details_editor.on_cancel_clicked');
  $editor.close();
  }
  //#endregion private flows
}
