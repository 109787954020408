import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Surveys_ds_get_survey_by_surveyIdService } from './Surveys.datasource.index';
import { Surveys_ds_get_surveys_by_surveyIdsService } from './Surveys.datasource.index';
import { Surveys_ds_operation_context_types_ddService } from './Surveys.datasource.index';
import { Surveys_ds_submitted_survey_inputs_gridService } from './Surveys.datasource.index';
import { Surveys_ds_submitted_surveys_gridService } from './Surveys.datasource.index';
import { Surveys_ds_submitted_surveys_grid_tempService } from './Surveys.datasource.index';
import { Surveys_ds_survey_grid_input_values_gridService } from './Surveys.datasource.index';

@Injectable({ providedIn: 'root' })
export class Surveys_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Surveys: Surveys_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_survey_by_surveyId: Surveys_ds_get_survey_by_surveyIdService;
  public get ds_get_survey_by_surveyId(): Surveys_ds_get_survey_by_surveyIdService {
    if(!this._ds_get_survey_by_surveyId) {
      this._ds_get_survey_by_surveyId = this.injector.get(Surveys_ds_get_survey_by_surveyIdService);
    }
    return this._ds_get_survey_by_surveyId;
  }
  private _ds_get_surveys_by_surveyIds: Surveys_ds_get_surveys_by_surveyIdsService;
  public get ds_get_surveys_by_surveyIds(): Surveys_ds_get_surveys_by_surveyIdsService {
    if(!this._ds_get_surveys_by_surveyIds) {
      this._ds_get_surveys_by_surveyIds = this.injector.get(Surveys_ds_get_surveys_by_surveyIdsService);
    }
    return this._ds_get_surveys_by_surveyIds;
  }
  private _ds_operation_context_types_dd: Surveys_ds_operation_context_types_ddService;
  public get ds_operation_context_types_dd(): Surveys_ds_operation_context_types_ddService {
    if(!this._ds_operation_context_types_dd) {
      this._ds_operation_context_types_dd = this.injector.get(Surveys_ds_operation_context_types_ddService);
    }
    return this._ds_operation_context_types_dd;
  }
  private _ds_submitted_survey_inputs_grid: Surveys_ds_submitted_survey_inputs_gridService;
  public get ds_submitted_survey_inputs_grid(): Surveys_ds_submitted_survey_inputs_gridService {
    if(!this._ds_submitted_survey_inputs_grid) {
      this._ds_submitted_survey_inputs_grid = this.injector.get(Surveys_ds_submitted_survey_inputs_gridService);
    }
    return this._ds_submitted_survey_inputs_grid;
  }
  private _ds_submitted_surveys_grid: Surveys_ds_submitted_surveys_gridService;
  public get ds_submitted_surveys_grid(): Surveys_ds_submitted_surveys_gridService {
    if(!this._ds_submitted_surveys_grid) {
      this._ds_submitted_surveys_grid = this.injector.get(Surveys_ds_submitted_surveys_gridService);
    }
    return this._ds_submitted_surveys_grid;
  }
  private _ds_submitted_surveys_grid_temp: Surveys_ds_submitted_surveys_grid_tempService;
  public get ds_submitted_surveys_grid_temp(): Surveys_ds_submitted_surveys_grid_tempService {
    if(!this._ds_submitted_surveys_grid_temp) {
      this._ds_submitted_surveys_grid_temp = this.injector.get(Surveys_ds_submitted_surveys_grid_tempService);
    }
    return this._ds_submitted_surveys_grid_temp;
  }
  private _ds_survey_grid_input_values_grid: Surveys_ds_survey_grid_input_values_gridService;
  public get ds_survey_grid_input_values_grid(): Surveys_ds_survey_grid_input_values_gridService {
    if(!this._ds_survey_grid_input_values_grid) {
      this._ds_survey_grid_input_values_grid = this.injector.get(Surveys_ds_survey_grid_input_values_gridService);
    }
    return this._ds_survey_grid_input_values_grid;
  }
}

