import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_ds_inventory_import_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseId: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, ReceiveDate?: string, Material?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } } }, Status?: { Id?: number, Name?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string } }, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } } }, Packaged?: { Id?: number, ShortName?: string, WeightUom?: { Id?: number, Short_name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/datasources/ds_inventory_import_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, ReceiveDate?: string, Material?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } } }, Status?: { Id?: number, Name?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string } }, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } } }, Packaged?: { Id?: number, ShortName?: string, WeightUom?: { Id?: number, Short_name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/datasources/ds_inventory_import_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseId: number, $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, ReceiveDate?: string, Material?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } } }, Status?: { Id?: number, Name?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string } }, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } } }, Packaged?: { Id?: number, ShortName?: string, WeightUom?: { Id?: number, Short_name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/datasources/ds_inventory_import_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
