import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { ExcelOrderImport_create_purchase_order_line_flowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_create_sales_order_line_flowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_create_transload_order_line_flowService } from './ExcelOrderImport.flow.index';

import { $frontendTypes } from './ExcelOrderImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public ExcelOrderImport: ExcelOrderImport_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_purchase_order_line_flow: ExcelOrderImport_create_purchase_order_line_flowService;
  public async create_purchase_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId: number, packagedAmount: number, materialId?: number, lotId?: number, vendorlotId?: number, notes?: string }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._create_purchase_order_line_flow) {
      this._create_purchase_order_line_flow = this.injector.get(ExcelOrderImport_create_purchase_order_line_flowService);
    }
    return this._create_purchase_order_line_flow.run(inParams);
  }
   
   

   
 
  private _create_sales_order_line_flow: ExcelOrderImport_create_sales_order_line_flowService;
  public async create_sales_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId?: number, packagedAmount?: number, materialId?: number, lotId?: number, vendorlotId?: number, serialnumberId?: number, notes?: string }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._create_sales_order_line_flow) {
      this._create_sales_order_line_flow = this.injector.get(ExcelOrderImport_create_sales_order_line_flowService);
    }
    return this._create_sales_order_line_flow.run(inParams);
  }
   
   

   
 
  private _create_transload_order_line_flow: ExcelOrderImport_create_transload_order_line_flowService;
  public async create_transload_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId: number, packagedAmount: number, materialId?: number, lotId?: number, vendorlotId?: number, notes?: string }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._create_transload_order_line_flow) {
      this._create_transload_order_line_flow = this.injector.get(ExcelOrderImport_create_transload_order_line_flowService);
    }
    return this._create_transload_order_line_flow.run(inParams);
  }
   
   

   
}
