<div class="modal-container">
  <div mat-dialog-title
       class="modal-header">
    <h1 class="modal-title">{{data.title}}</h1>
  </div>
  <div mat-dialog-content
       class="modal-content">
    <div class="message-container">
      <div class="main-message">{{data.message}}</div>
    </div>
  </div>
  <div mat-dialog-actions
       class="modal-toolbar commands">
    <button mat-button
            *ngIf="data.confirmText"
            class="datex-button primary"
            (click)="onConfirm()">{{data.confirmText}}</button>
    <button mat-button
            *ngIf="data.cancelText"
            class="datex-button secondary"
            (click)="onDismiss()">{{data.cancelText}}</button>
  </div>
</div>