import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_get_serialnumber_by_serialNumberIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { serialNumberId: number }): 
  Promise<{ result: { Id?: number, Archived?: boolean, ChainHead?: number, OriginalChainHead?: number, PackagingId?: number, TasksForSerialNumber?: { Id?: number, ActualSourceLicensePlateId?: number, ShipmentId?: number }[], Lot?: { Id?: number, Material?: { Id?: number, PackagingLookups?: { PackagingId?: number, IsBasePackaging?: boolean }[] } } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serialNumberId)) {
      missingRequiredInParams.push('\'serialNumberId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_get_serialnumber_by_serialNumberId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
