import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { Inventory_inventory_by_project_reportService } from './Inventory.report.index';

@Injectable({ providedIn: 'root' })
export class Inventory_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public Inventory: Inventory_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _inventory_by_project_report: Inventory_inventory_by_project_reportService;
  public get inventory_by_project_report(): Inventory_inventory_by_project_reportService {
    if(!this._inventory_by_project_report) {
      this._inventory_by_project_report = this.injector.get(Inventory_inventory_by_project_reportService);
    }
    return this._inventory_by_project_report;
  }
}

