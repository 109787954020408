import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Catalogs_ds_get_material_by_catalog_id_top1Service } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_catalog_by_catalog_idService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_catalog_by_nameService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_catalog_project_by_catalog_id_top1Service } from './Catalogs.datasource.index';

@Injectable({ providedIn: 'root' })
export class Catalogs_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Catalogs: Catalogs_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_material_by_catalog_id_top1: Catalogs_ds_get_material_by_catalog_id_top1Service;
  public get ds_get_material_by_catalog_id_top1(): Catalogs_ds_get_material_by_catalog_id_top1Service {
    if(!this._ds_get_material_by_catalog_id_top1) {
      this._ds_get_material_by_catalog_id_top1 = this.injector.get(Catalogs_ds_get_material_by_catalog_id_top1Service);
    }
    return this._ds_get_material_by_catalog_id_top1;
  }
  private _ds_get_material_catalog_by_catalog_id: Catalogs_ds_get_material_catalog_by_catalog_idService;
  public get ds_get_material_catalog_by_catalog_id(): Catalogs_ds_get_material_catalog_by_catalog_idService {
    if(!this._ds_get_material_catalog_by_catalog_id) {
      this._ds_get_material_catalog_by_catalog_id = this.injector.get(Catalogs_ds_get_material_catalog_by_catalog_idService);
    }
    return this._ds_get_material_catalog_by_catalog_id;
  }
  private _ds_get_material_catalog_by_name: Catalogs_ds_get_material_catalog_by_nameService;
  public get ds_get_material_catalog_by_name(): Catalogs_ds_get_material_catalog_by_nameService {
    if(!this._ds_get_material_catalog_by_name) {
      this._ds_get_material_catalog_by_name = this.injector.get(Catalogs_ds_get_material_catalog_by_nameService);
    }
    return this._ds_get_material_catalog_by_name;
  }
  private _ds_get_material_catalog_project_by_catalog_id_top1: Catalogs_ds_get_material_catalog_project_by_catalog_id_top1Service;
  public get ds_get_material_catalog_project_by_catalog_id_top1(): Catalogs_ds_get_material_catalog_project_by_catalog_id_top1Service {
    if(!this._ds_get_material_catalog_project_by_catalog_id_top1) {
      this._ds_get_material_catalog_project_by_catalog_id_top1 = this.injector.get(Catalogs_ds_get_material_catalog_project_by_catalog_id_top1Service);
    }
    return this._ds_get_material_catalog_project_by_catalog_id_top1;
  }
}

