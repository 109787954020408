import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PalletTransactions_ds_pallet_transactions_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { pallet_class_ids?: number[], project_ids?: number[], account_ids?: number[], carrier_ids?: number[], shipment_ids?: number[], full_text_search?: string, date_from?: string, date_to?: string }): 
  Promise<{ result: { Id?: number, AccountId?: number, Amount?: number, CarrierId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Date?: string, Employee?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, PalletClassId?: number, ProjectId?: number, Reference?: string, ReferenceCarrierId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OrderClass?: { Id?: number, OrderClassTypeId?: number, OrderTypeId?: number } } }[] }, Account?: { Name?: string }, Carrier?: { Name?: string }, PalletClass?: { Name?: string }, Project?: { LookupCode?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/PalletTransactions/datasources/ds_pallet_transactions_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { pallet_class_ids?: number[], project_ids?: number[], account_ids?: number[], carrier_ids?: number[], shipment_ids?: number[], full_text_search?: string, date_from?: string, date_to?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, Amount?: number, CarrierId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Date?: string, Employee?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, PalletClassId?: number, ProjectId?: number, Reference?: string, ReferenceCarrierId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OrderClass?: { Id?: number, OrderClassTypeId?: number, OrderTypeId?: number } } }[] }, Account?: { Name?: string }, Carrier?: { Name?: string }, PalletClass?: { Name?: string }, Project?: { LookupCode?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/PalletTransactions/datasources/ds_pallet_transactions_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { pallet_class_ids?: number[], project_ids?: number[], account_ids?: number[], carrier_ids?: number[], shipment_ids?: number[], full_text_search?: string, date_from?: string, date_to?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, AccountId?: number, Amount?: number, CarrierId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Date?: string, Employee?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, PalletClassId?: number, ProjectId?: number, Reference?: string, ReferenceCarrierId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OrderClass?: { Id?: number, OrderClassTypeId?: number, OrderTypeId?: number } } }[] }, Account?: { Name?: string }, Carrier?: { Name?: string }, PalletClass?: { Name?: string }, Project?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PalletTransactions/datasources/ds_pallet_transactions_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
