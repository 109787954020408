import {
  Component,
  Inject
} from '@angular/core';

import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';

export interface IImageViewerDialogComponentData {
  imageSource?: string;
}

@Component({
  selector: 'app-image-viewer-dialog',
  templateUrl: './image-viewer-dialog.component.html'
})
export class ImageViewerDialogComponent {
  title: string = 'Image Viewer';

  constructor(
    private dialogRef: MatDialogRef<ImageViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IImageViewerDialogComponentData) {
  }

  close(): void {
    this.dialogRef.close(true);
  }
}
