import {
  Directive,
  Input,
  ElementRef,
  OnInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import JSONFormatter from 'json-formatter-js';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'json-formatter'
})
export class JsonformatterDirective implements OnInit, OnChanges {

  @Input() json: any;
  @Input() expand: boolean;

  constructor(private elRef: ElementRef) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['json']) {
      while (this.elRef.nativeElement.hasChildNodes()) {
        this.elRef.nativeElement.removeChild(this.elRef.nativeElement.lastChild);
      }
      if (this.json) {
        const formatter = new JSONFormatter(this.json);
        this.elRef.nativeElement.appendChild(formatter.render());
        if (this.expand) {
          formatter.openAtDepth(Infinity);
        } else {
          formatter.openAtDepth(0);
        }
      }
    }
  }

}
