import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { ExcelMaterialImport_ds_allocation_strategy_ddService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_entity_import_requestsService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_contoller_typesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_currencies_by_shortlabelService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_global_materials_by_namesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_material_allocation_strategies_by_nameService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_material_by_lookup_and_projectService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_material_by_lookupcode_and_projectIdService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_material_storage_category_rules_by_namesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_materialgroups_by_namesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_materialpackagings_by_shortnamesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_materials_by_lookupcodesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_materialstatuses_by_namesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_measurementunits_by_shortnamesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_owners_by_lookupcodesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_projects_by_lookupcodesService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_get_tagsService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_material_base_packagingService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_material_groups_ddService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_material_import_gridService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_material_import_staging_gridService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_material_packagings_ddService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_material_packagings_import_gridService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_material_statuses_ddService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_measurement_units_ddService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_owners_ddService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_projects_ddService } from './ExcelMaterialImport.datasource.index';
import { ExcelMaterialImport_ds_storage_category_rules_ddService } from './ExcelMaterialImport.datasource.index';

@Injectable({ providedIn: 'root' })
export class ExcelMaterialImport_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public ExcelMaterialImport: ExcelMaterialImport_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_allocation_strategy_dd: ExcelMaterialImport_ds_allocation_strategy_ddService;
  public get ds_allocation_strategy_dd(): ExcelMaterialImport_ds_allocation_strategy_ddService {
    if(!this._ds_allocation_strategy_dd) {
      this._ds_allocation_strategy_dd = this.injector.get(ExcelMaterialImport_ds_allocation_strategy_ddService);
    }
    return this._ds_allocation_strategy_dd;
  }
  private _ds_entity_import_requests: ExcelMaterialImport_ds_entity_import_requestsService;
  public get ds_entity_import_requests(): ExcelMaterialImport_ds_entity_import_requestsService {
    if(!this._ds_entity_import_requests) {
      this._ds_entity_import_requests = this.injector.get(ExcelMaterialImport_ds_entity_import_requestsService);
    }
    return this._ds_entity_import_requests;
  }
  private _ds_get_contoller_types: ExcelMaterialImport_ds_get_contoller_typesService;
  public get ds_get_contoller_types(): ExcelMaterialImport_ds_get_contoller_typesService {
    if(!this._ds_get_contoller_types) {
      this._ds_get_contoller_types = this.injector.get(ExcelMaterialImport_ds_get_contoller_typesService);
    }
    return this._ds_get_contoller_types;
  }
  private _ds_get_currencies_by_shortlabel: ExcelMaterialImport_ds_get_currencies_by_shortlabelService;
  public get ds_get_currencies_by_shortlabel(): ExcelMaterialImport_ds_get_currencies_by_shortlabelService {
    if(!this._ds_get_currencies_by_shortlabel) {
      this._ds_get_currencies_by_shortlabel = this.injector.get(ExcelMaterialImport_ds_get_currencies_by_shortlabelService);
    }
    return this._ds_get_currencies_by_shortlabel;
  }
  private _ds_get_global_materials_by_names: ExcelMaterialImport_ds_get_global_materials_by_namesService;
  public get ds_get_global_materials_by_names(): ExcelMaterialImport_ds_get_global_materials_by_namesService {
    if(!this._ds_get_global_materials_by_names) {
      this._ds_get_global_materials_by_names = this.injector.get(ExcelMaterialImport_ds_get_global_materials_by_namesService);
    }
    return this._ds_get_global_materials_by_names;
  }
  private _ds_get_material_allocation_strategies_by_name: ExcelMaterialImport_ds_get_material_allocation_strategies_by_nameService;
  public get ds_get_material_allocation_strategies_by_name(): ExcelMaterialImport_ds_get_material_allocation_strategies_by_nameService {
    if(!this._ds_get_material_allocation_strategies_by_name) {
      this._ds_get_material_allocation_strategies_by_name = this.injector.get(ExcelMaterialImport_ds_get_material_allocation_strategies_by_nameService);
    }
    return this._ds_get_material_allocation_strategies_by_name;
  }
  private _ds_get_material_by_lookup_and_project: ExcelMaterialImport_ds_get_material_by_lookup_and_projectService;
  public get ds_get_material_by_lookup_and_project(): ExcelMaterialImport_ds_get_material_by_lookup_and_projectService {
    if(!this._ds_get_material_by_lookup_and_project) {
      this._ds_get_material_by_lookup_and_project = this.injector.get(ExcelMaterialImport_ds_get_material_by_lookup_and_projectService);
    }
    return this._ds_get_material_by_lookup_and_project;
  }
  private _ds_get_material_by_lookupcode_and_projectId: ExcelMaterialImport_ds_get_material_by_lookupcode_and_projectIdService;
  public get ds_get_material_by_lookupcode_and_projectId(): ExcelMaterialImport_ds_get_material_by_lookupcode_and_projectIdService {
    if(!this._ds_get_material_by_lookupcode_and_projectId) {
      this._ds_get_material_by_lookupcode_and_projectId = this.injector.get(ExcelMaterialImport_ds_get_material_by_lookupcode_and_projectIdService);
    }
    return this._ds_get_material_by_lookupcode_and_projectId;
  }
  private _ds_get_material_storage_category_rules_by_names: ExcelMaterialImport_ds_get_material_storage_category_rules_by_namesService;
  public get ds_get_material_storage_category_rules_by_names(): ExcelMaterialImport_ds_get_material_storage_category_rules_by_namesService {
    if(!this._ds_get_material_storage_category_rules_by_names) {
      this._ds_get_material_storage_category_rules_by_names = this.injector.get(ExcelMaterialImport_ds_get_material_storage_category_rules_by_namesService);
    }
    return this._ds_get_material_storage_category_rules_by_names;
  }
  private _ds_get_materialgroups_by_names: ExcelMaterialImport_ds_get_materialgroups_by_namesService;
  public get ds_get_materialgroups_by_names(): ExcelMaterialImport_ds_get_materialgroups_by_namesService {
    if(!this._ds_get_materialgroups_by_names) {
      this._ds_get_materialgroups_by_names = this.injector.get(ExcelMaterialImport_ds_get_materialgroups_by_namesService);
    }
    return this._ds_get_materialgroups_by_names;
  }
  private _ds_get_materialpackagings_by_shortnames: ExcelMaterialImport_ds_get_materialpackagings_by_shortnamesService;
  public get ds_get_materialpackagings_by_shortnames(): ExcelMaterialImport_ds_get_materialpackagings_by_shortnamesService {
    if(!this._ds_get_materialpackagings_by_shortnames) {
      this._ds_get_materialpackagings_by_shortnames = this.injector.get(ExcelMaterialImport_ds_get_materialpackagings_by_shortnamesService);
    }
    return this._ds_get_materialpackagings_by_shortnames;
  }
  private _ds_get_materials_by_lookupcodes: ExcelMaterialImport_ds_get_materials_by_lookupcodesService;
  public get ds_get_materials_by_lookupcodes(): ExcelMaterialImport_ds_get_materials_by_lookupcodesService {
    if(!this._ds_get_materials_by_lookupcodes) {
      this._ds_get_materials_by_lookupcodes = this.injector.get(ExcelMaterialImport_ds_get_materials_by_lookupcodesService);
    }
    return this._ds_get_materials_by_lookupcodes;
  }
  private _ds_get_materialstatuses_by_names: ExcelMaterialImport_ds_get_materialstatuses_by_namesService;
  public get ds_get_materialstatuses_by_names(): ExcelMaterialImport_ds_get_materialstatuses_by_namesService {
    if(!this._ds_get_materialstatuses_by_names) {
      this._ds_get_materialstatuses_by_names = this.injector.get(ExcelMaterialImport_ds_get_materialstatuses_by_namesService);
    }
    return this._ds_get_materialstatuses_by_names;
  }
  private _ds_get_measurementunits_by_shortnames: ExcelMaterialImport_ds_get_measurementunits_by_shortnamesService;
  public get ds_get_measurementunits_by_shortnames(): ExcelMaterialImport_ds_get_measurementunits_by_shortnamesService {
    if(!this._ds_get_measurementunits_by_shortnames) {
      this._ds_get_measurementunits_by_shortnames = this.injector.get(ExcelMaterialImport_ds_get_measurementunits_by_shortnamesService);
    }
    return this._ds_get_measurementunits_by_shortnames;
  }
  private _ds_get_owners_by_lookupcodes: ExcelMaterialImport_ds_get_owners_by_lookupcodesService;
  public get ds_get_owners_by_lookupcodes(): ExcelMaterialImport_ds_get_owners_by_lookupcodesService {
    if(!this._ds_get_owners_by_lookupcodes) {
      this._ds_get_owners_by_lookupcodes = this.injector.get(ExcelMaterialImport_ds_get_owners_by_lookupcodesService);
    }
    return this._ds_get_owners_by_lookupcodes;
  }
  private _ds_get_projects_by_lookupcodes: ExcelMaterialImport_ds_get_projects_by_lookupcodesService;
  public get ds_get_projects_by_lookupcodes(): ExcelMaterialImport_ds_get_projects_by_lookupcodesService {
    if(!this._ds_get_projects_by_lookupcodes) {
      this._ds_get_projects_by_lookupcodes = this.injector.get(ExcelMaterialImport_ds_get_projects_by_lookupcodesService);
    }
    return this._ds_get_projects_by_lookupcodes;
  }
  private _ds_get_tags: ExcelMaterialImport_ds_get_tagsService;
  public get ds_get_tags(): ExcelMaterialImport_ds_get_tagsService {
    if(!this._ds_get_tags) {
      this._ds_get_tags = this.injector.get(ExcelMaterialImport_ds_get_tagsService);
    }
    return this._ds_get_tags;
  }
  private _ds_material_base_packaging: ExcelMaterialImport_ds_material_base_packagingService;
  public get ds_material_base_packaging(): ExcelMaterialImport_ds_material_base_packagingService {
    if(!this._ds_material_base_packaging) {
      this._ds_material_base_packaging = this.injector.get(ExcelMaterialImport_ds_material_base_packagingService);
    }
    return this._ds_material_base_packaging;
  }
  private _ds_material_groups_dd: ExcelMaterialImport_ds_material_groups_ddService;
  public get ds_material_groups_dd(): ExcelMaterialImport_ds_material_groups_ddService {
    if(!this._ds_material_groups_dd) {
      this._ds_material_groups_dd = this.injector.get(ExcelMaterialImport_ds_material_groups_ddService);
    }
    return this._ds_material_groups_dd;
  }
  private _ds_material_import_grid: ExcelMaterialImport_ds_material_import_gridService;
  public get ds_material_import_grid(): ExcelMaterialImport_ds_material_import_gridService {
    if(!this._ds_material_import_grid) {
      this._ds_material_import_grid = this.injector.get(ExcelMaterialImport_ds_material_import_gridService);
    }
    return this._ds_material_import_grid;
  }
  private _ds_material_import_staging_grid: ExcelMaterialImport_ds_material_import_staging_gridService;
  public get ds_material_import_staging_grid(): ExcelMaterialImport_ds_material_import_staging_gridService {
    if(!this._ds_material_import_staging_grid) {
      this._ds_material_import_staging_grid = this.injector.get(ExcelMaterialImport_ds_material_import_staging_gridService);
    }
    return this._ds_material_import_staging_grid;
  }
  private _ds_material_packagings_dd: ExcelMaterialImport_ds_material_packagings_ddService;
  public get ds_material_packagings_dd(): ExcelMaterialImport_ds_material_packagings_ddService {
    if(!this._ds_material_packagings_dd) {
      this._ds_material_packagings_dd = this.injector.get(ExcelMaterialImport_ds_material_packagings_ddService);
    }
    return this._ds_material_packagings_dd;
  }
  private _ds_material_packagings_import_grid: ExcelMaterialImport_ds_material_packagings_import_gridService;
  public get ds_material_packagings_import_grid(): ExcelMaterialImport_ds_material_packagings_import_gridService {
    if(!this._ds_material_packagings_import_grid) {
      this._ds_material_packagings_import_grid = this.injector.get(ExcelMaterialImport_ds_material_packagings_import_gridService);
    }
    return this._ds_material_packagings_import_grid;
  }
  private _ds_material_statuses_dd: ExcelMaterialImport_ds_material_statuses_ddService;
  public get ds_material_statuses_dd(): ExcelMaterialImport_ds_material_statuses_ddService {
    if(!this._ds_material_statuses_dd) {
      this._ds_material_statuses_dd = this.injector.get(ExcelMaterialImport_ds_material_statuses_ddService);
    }
    return this._ds_material_statuses_dd;
  }
  private _ds_measurement_units_dd: ExcelMaterialImport_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): ExcelMaterialImport_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(ExcelMaterialImport_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_owners_dd: ExcelMaterialImport_ds_owners_ddService;
  public get ds_owners_dd(): ExcelMaterialImport_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(ExcelMaterialImport_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_projects_dd: ExcelMaterialImport_ds_projects_ddService;
  public get ds_projects_dd(): ExcelMaterialImport_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(ExcelMaterialImport_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_storage_category_rules_dd: ExcelMaterialImport_ds_storage_category_rules_ddService;
  public get ds_storage_category_rules_dd(): ExcelMaterialImport_ds_storage_category_rules_ddService {
    if(!this._ds_storage_category_rules_dd) {
      this._ds_storage_category_rules_dd = this.injector.get(ExcelMaterialImport_ds_storage_category_rules_ddService);
    }
    return this._ds_storage_category_rules_dd;
  }
}

