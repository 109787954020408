import { 
  Component,
  HostBinding,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';

import { Subject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-labor_management_powerbi',
  templateUrl: './app.labor_management_powerbi.component.html'
})
export class app_labor_management_powerbiComponent extends BaseComponent implements OnInit, OnDestroy {
  @HostBinding('class.embedded-content') embeddedContentClass: boolean = true;

  @Input() 

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region Variables
  //#endregion
  get href(): SafeResourceUrl {
    const $embed = this;
    const $utils = this.utils;
    return 'https://app.powerbi.com/view?r=eyJrIjoiNzUxMzA3MWEtYWZkNS00NTFjLTg1NzgtYjgwZTk4MTFlNmY1IiwidCI6IjBjZDQ5NzhjLWRiODMtNGZmZS04ODIxLWYwMWE1ZjgxMTBhYyIsImMiOjF9';
  }

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.initialized = true;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
  }

}
