import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Replenishments_ds_replenishment_inventory_licenseplate_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds?: number[], materialIds?: number[], licenseplateStatusId?: number, lotStatusId?: number, isPrimaryPick: boolean, locationIds?: number[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Status?: { Name?: string } }, Packaging?: { ShortName?: string }, LicensePlate?: { LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, IsPrimaryPick?: boolean, Name?: string, Warehouse?: { Id?: number, Name?: string }, ReplenishmentThresholds?: { Id?: number }[] }, Status?: { Name?: string } }, lot_availability?: { TotalAvailablePackagedAmount?: number }, last_pick_date?: { Id?: number, CompletedDateTime?: string }, total_released_picked_amount?: { TotalReleasedPickBaseAmount?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.isPrimaryPick)) {
      missingRequiredInParams.push('\'isPrimaryPick\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Replenishments/datasources/ds_replenishment_inventory_licenseplate_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds?: number[], materialIds?: number[], licenseplateStatusId?: number, lotStatusId?: number, isPrimaryPick: boolean, locationIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Status?: { Name?: string } }, Packaging?: { ShortName?: string }, LicensePlate?: { LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, IsPrimaryPick?: boolean, Name?: string, Warehouse?: { Id?: number, Name?: string }, ReplenishmentThresholds?: { Id?: number }[] }, Status?: { Name?: string } }, lot_availability?: { TotalAvailablePackagedAmount?: number }, last_pick_date?: { Id?: number, CompletedDateTime?: string }, total_released_picked_amount?: { TotalReleasedPickBaseAmount?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.isPrimaryPick)) {
      missingRequiredInParams.push('\'isPrimaryPick\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Replenishments/datasources/ds_replenishment_inventory_licenseplate_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds?: number[], materialIds?: number[], licenseplateStatusId?: number, lotStatusId?: number, isPrimaryPick: boolean, locationIds?: number[], $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Status?: { Name?: string } }, Packaging?: { ShortName?: string }, LicensePlate?: { LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, IsPrimaryPick?: boolean, Name?: string, Warehouse?: { Id?: number, Name?: string }, ReplenishmentThresholds?: { Id?: number }[] }, Status?: { Name?: string } }, lot_availability?: { TotalAvailablePackagedAmount?: number }, last_pick_date?: { Id?: number, CompletedDateTime?: string }, total_released_picked_amount?: { TotalReleasedPickBaseAmount?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.isPrimaryPick)) {
      missingRequiredInParams.push('\'isPrimaryPick\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Replenishments/datasources/ds_replenishment_inventory_licenseplate_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
