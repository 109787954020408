import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { Waves_pick_slip_by_wave_id_consolidated_reportService } from './Waves.report.index';
import { Waves_pick_slip_by_wave_id_reportService } from './Waves.report.index';

@Injectable({ providedIn: 'root' })
export class Waves_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public Waves: Waves_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _pick_slip_by_wave_id_consolidated_report: Waves_pick_slip_by_wave_id_consolidated_reportService;
  public get pick_slip_by_wave_id_consolidated_report(): Waves_pick_slip_by_wave_id_consolidated_reportService {
    if(!this._pick_slip_by_wave_id_consolidated_report) {
      this._pick_slip_by_wave_id_consolidated_report = this.injector.get(Waves_pick_slip_by_wave_id_consolidated_reportService);
    }
    return this._pick_slip_by_wave_id_consolidated_report;
  }
  private _pick_slip_by_wave_id_report: Waves_pick_slip_by_wave_id_reportService;
  public get pick_slip_by_wave_id_report(): Waves_pick_slip_by_wave_id_reportService {
    if(!this._pick_slip_by_wave_id_report) {
      this._pick_slip_by_wave_id_report = this.injector.get(Waves_pick_slip_by_wave_id_reportService);
    }
    return this._pick_slip_by_wave_id_report;
  }
}

