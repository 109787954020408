import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Waves_ds_check_shipments_on_wave_top1Service } from './Waves.datasource.index';
import { Waves_ds_consolidated_outbound_pick_slip_by_wave_id_reportService } from './Waves.datasource.index';
import { Waves_ds_get_material_packaging_by_materialId_and_packagingId_top1Service } from './Waves.datasource.index';
import { Waves_ds_get_order_account_address_by_orderIdService } from './Waves.datasource.index';
import { Waves_ds_get_order_address_by_orderId_top1Service } from './Waves.datasource.index';
import { Waves_ds_get_order_owner_contact_top1Service } from './Waves.datasource.index';
import { Waves_ds_get_order_ship_to_account_vs_order_addressService } from './Waves.datasource.index';
import { Waves_ds_get_orders_by_orderIdsService } from './Waves.datasource.index';
import { Waves_ds_get_orders_ready_to_waveService } from './Waves.datasource.index';
import { Waves_ds_get_shipments_by_shipmentIdsService } from './Waves.datasource.index';
import { Waves_ds_get_shipments_by_waveIdService } from './Waves.datasource.index';
import { Waves_ds_get_wave_by_wave_idService } from './Waves.datasource.index';
import { Waves_ds_get_wave_status_by_waveIdService } from './Waves.datasource.index';
import { Waves_ds_open_waves_widgetService } from './Waves.datasource.index';
import { Waves_ds_order_count_by_wave_widgetService } from './Waves.datasource.index';
import { Waves_ds_outbound_detail_pick_slip_reportService } from './Waves.datasource.index';
import { Waves_ds_outbound_header_pick_slip_reportService } from './Waves.datasource.index';
import { Waves_ds_outbound_pick_slip_by_wave_id_reportService } from './Waves.datasource.index';
import { Waves_ds_pick_slips_gridService } from './Waves.datasource.index';
import { Waves_ds_pick_tasks_count_widgetService } from './Waves.datasource.index';
import { Waves_ds_pick_waves_detailsService } from './Waves.datasource.index';
import { Waves_ds_pick_waves_gridService } from './Waves.datasource.index';
import { Waves_ds_warehouse_ddService } from './Waves.datasource.index';
import { Waves_ds_wave_priority_ddService } from './Waves.datasource.index';
import { Waves_ds_wave_statuses_ddService } from './Waves.datasource.index';
import { Waves_get_manual_allocationsService } from './Waves.datasource.index';

@Injectable({ providedIn: 'root' })
export class Waves_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Waves: Waves_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_check_shipments_on_wave_top1: Waves_ds_check_shipments_on_wave_top1Service;
  public get ds_check_shipments_on_wave_top1(): Waves_ds_check_shipments_on_wave_top1Service {
    if(!this._ds_check_shipments_on_wave_top1) {
      this._ds_check_shipments_on_wave_top1 = this.injector.get(Waves_ds_check_shipments_on_wave_top1Service);
    }
    return this._ds_check_shipments_on_wave_top1;
  }
  private _ds_consolidated_outbound_pick_slip_by_wave_id_report: Waves_ds_consolidated_outbound_pick_slip_by_wave_id_reportService;
  public get ds_consolidated_outbound_pick_slip_by_wave_id_report(): Waves_ds_consolidated_outbound_pick_slip_by_wave_id_reportService {
    if(!this._ds_consolidated_outbound_pick_slip_by_wave_id_report) {
      this._ds_consolidated_outbound_pick_slip_by_wave_id_report = this.injector.get(Waves_ds_consolidated_outbound_pick_slip_by_wave_id_reportService);
    }
    return this._ds_consolidated_outbound_pick_slip_by_wave_id_report;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId_top1: Waves_ds_get_material_packaging_by_materialId_and_packagingId_top1Service;
  public get ds_get_material_packaging_by_materialId_and_packagingId_top1(): Waves_ds_get_material_packaging_by_materialId_and_packagingId_top1Service {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId_top1) {
      this._ds_get_material_packaging_by_materialId_and_packagingId_top1 = this.injector.get(Waves_ds_get_material_packaging_by_materialId_and_packagingId_top1Service);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId_top1;
  }
  private _ds_get_order_account_address_by_orderId: Waves_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): Waves_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(Waves_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_address_by_orderId_top1: Waves_ds_get_order_address_by_orderId_top1Service;
  public get ds_get_order_address_by_orderId_top1(): Waves_ds_get_order_address_by_orderId_top1Service {
    if(!this._ds_get_order_address_by_orderId_top1) {
      this._ds_get_order_address_by_orderId_top1 = this.injector.get(Waves_ds_get_order_address_by_orderId_top1Service);
    }
    return this._ds_get_order_address_by_orderId_top1;
  }
  private _ds_get_order_owner_contact_top1: Waves_ds_get_order_owner_contact_top1Service;
  public get ds_get_order_owner_contact_top1(): Waves_ds_get_order_owner_contact_top1Service {
    if(!this._ds_get_order_owner_contact_top1) {
      this._ds_get_order_owner_contact_top1 = this.injector.get(Waves_ds_get_order_owner_contact_top1Service);
    }
    return this._ds_get_order_owner_contact_top1;
  }
  private _ds_get_order_ship_to_account_vs_order_address: Waves_ds_get_order_ship_to_account_vs_order_addressService;
  public get ds_get_order_ship_to_account_vs_order_address(): Waves_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._ds_get_order_ship_to_account_vs_order_address) {
      this._ds_get_order_ship_to_account_vs_order_address = this.injector.get(Waves_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_to_account_vs_order_address;
  }
  private _ds_get_orders_by_orderIds: Waves_ds_get_orders_by_orderIdsService;
  public get ds_get_orders_by_orderIds(): Waves_ds_get_orders_by_orderIdsService {
    if(!this._ds_get_orders_by_orderIds) {
      this._ds_get_orders_by_orderIds = this.injector.get(Waves_ds_get_orders_by_orderIdsService);
    }
    return this._ds_get_orders_by_orderIds;
  }
  private _ds_get_orders_ready_to_wave: Waves_ds_get_orders_ready_to_waveService;
  public get ds_get_orders_ready_to_wave(): Waves_ds_get_orders_ready_to_waveService {
    if(!this._ds_get_orders_ready_to_wave) {
      this._ds_get_orders_ready_to_wave = this.injector.get(Waves_ds_get_orders_ready_to_waveService);
    }
    return this._ds_get_orders_ready_to_wave;
  }
  private _ds_get_shipments_by_shipmentIds: Waves_ds_get_shipments_by_shipmentIdsService;
  public get ds_get_shipments_by_shipmentIds(): Waves_ds_get_shipments_by_shipmentIdsService {
    if(!this._ds_get_shipments_by_shipmentIds) {
      this._ds_get_shipments_by_shipmentIds = this.injector.get(Waves_ds_get_shipments_by_shipmentIdsService);
    }
    return this._ds_get_shipments_by_shipmentIds;
  }
  private _ds_get_shipments_by_waveId: Waves_ds_get_shipments_by_waveIdService;
  public get ds_get_shipments_by_waveId(): Waves_ds_get_shipments_by_waveIdService {
    if(!this._ds_get_shipments_by_waveId) {
      this._ds_get_shipments_by_waveId = this.injector.get(Waves_ds_get_shipments_by_waveIdService);
    }
    return this._ds_get_shipments_by_waveId;
  }
  private _ds_get_wave_by_wave_id: Waves_ds_get_wave_by_wave_idService;
  public get ds_get_wave_by_wave_id(): Waves_ds_get_wave_by_wave_idService {
    if(!this._ds_get_wave_by_wave_id) {
      this._ds_get_wave_by_wave_id = this.injector.get(Waves_ds_get_wave_by_wave_idService);
    }
    return this._ds_get_wave_by_wave_id;
  }
  private _ds_get_wave_status_by_waveId: Waves_ds_get_wave_status_by_waveIdService;
  public get ds_get_wave_status_by_waveId(): Waves_ds_get_wave_status_by_waveIdService {
    if(!this._ds_get_wave_status_by_waveId) {
      this._ds_get_wave_status_by_waveId = this.injector.get(Waves_ds_get_wave_status_by_waveIdService);
    }
    return this._ds_get_wave_status_by_waveId;
  }
  private _ds_open_waves_widget: Waves_ds_open_waves_widgetService;
  public get ds_open_waves_widget(): Waves_ds_open_waves_widgetService {
    if(!this._ds_open_waves_widget) {
      this._ds_open_waves_widget = this.injector.get(Waves_ds_open_waves_widgetService);
    }
    return this._ds_open_waves_widget;
  }
  private _ds_order_count_by_wave_widget: Waves_ds_order_count_by_wave_widgetService;
  public get ds_order_count_by_wave_widget(): Waves_ds_order_count_by_wave_widgetService {
    if(!this._ds_order_count_by_wave_widget) {
      this._ds_order_count_by_wave_widget = this.injector.get(Waves_ds_order_count_by_wave_widgetService);
    }
    return this._ds_order_count_by_wave_widget;
  }
  private _ds_outbound_detail_pick_slip_report: Waves_ds_outbound_detail_pick_slip_reportService;
  public get ds_outbound_detail_pick_slip_report(): Waves_ds_outbound_detail_pick_slip_reportService {
    if(!this._ds_outbound_detail_pick_slip_report) {
      this._ds_outbound_detail_pick_slip_report = this.injector.get(Waves_ds_outbound_detail_pick_slip_reportService);
    }
    return this._ds_outbound_detail_pick_slip_report;
  }
  private _ds_outbound_header_pick_slip_report: Waves_ds_outbound_header_pick_slip_reportService;
  public get ds_outbound_header_pick_slip_report(): Waves_ds_outbound_header_pick_slip_reportService {
    if(!this._ds_outbound_header_pick_slip_report) {
      this._ds_outbound_header_pick_slip_report = this.injector.get(Waves_ds_outbound_header_pick_slip_reportService);
    }
    return this._ds_outbound_header_pick_slip_report;
  }
  private _ds_outbound_pick_slip_by_wave_id_report: Waves_ds_outbound_pick_slip_by_wave_id_reportService;
  public get ds_outbound_pick_slip_by_wave_id_report(): Waves_ds_outbound_pick_slip_by_wave_id_reportService {
    if(!this._ds_outbound_pick_slip_by_wave_id_report) {
      this._ds_outbound_pick_slip_by_wave_id_report = this.injector.get(Waves_ds_outbound_pick_slip_by_wave_id_reportService);
    }
    return this._ds_outbound_pick_slip_by_wave_id_report;
  }
  private _ds_pick_slips_grid: Waves_ds_pick_slips_gridService;
  public get ds_pick_slips_grid(): Waves_ds_pick_slips_gridService {
    if(!this._ds_pick_slips_grid) {
      this._ds_pick_slips_grid = this.injector.get(Waves_ds_pick_slips_gridService);
    }
    return this._ds_pick_slips_grid;
  }
  private _ds_pick_tasks_count_widget: Waves_ds_pick_tasks_count_widgetService;
  public get ds_pick_tasks_count_widget(): Waves_ds_pick_tasks_count_widgetService {
    if(!this._ds_pick_tasks_count_widget) {
      this._ds_pick_tasks_count_widget = this.injector.get(Waves_ds_pick_tasks_count_widgetService);
    }
    return this._ds_pick_tasks_count_widget;
  }
  private _ds_pick_waves_details: Waves_ds_pick_waves_detailsService;
  public get ds_pick_waves_details(): Waves_ds_pick_waves_detailsService {
    if(!this._ds_pick_waves_details) {
      this._ds_pick_waves_details = this.injector.get(Waves_ds_pick_waves_detailsService);
    }
    return this._ds_pick_waves_details;
  }
  private _ds_pick_waves_grid: Waves_ds_pick_waves_gridService;
  public get ds_pick_waves_grid(): Waves_ds_pick_waves_gridService {
    if(!this._ds_pick_waves_grid) {
      this._ds_pick_waves_grid = this.injector.get(Waves_ds_pick_waves_gridService);
    }
    return this._ds_pick_waves_grid;
  }
  private _ds_warehouse_dd: Waves_ds_warehouse_ddService;
  public get ds_warehouse_dd(): Waves_ds_warehouse_ddService {
    if(!this._ds_warehouse_dd) {
      this._ds_warehouse_dd = this.injector.get(Waves_ds_warehouse_ddService);
    }
    return this._ds_warehouse_dd;
  }
  private _ds_wave_priority_dd: Waves_ds_wave_priority_ddService;
  public get ds_wave_priority_dd(): Waves_ds_wave_priority_ddService {
    if(!this._ds_wave_priority_dd) {
      this._ds_wave_priority_dd = this.injector.get(Waves_ds_wave_priority_ddService);
    }
    return this._ds_wave_priority_dd;
  }
  private _ds_wave_statuses_dd: Waves_ds_wave_statuses_ddService;
  public get ds_wave_statuses_dd(): Waves_ds_wave_statuses_ddService {
    if(!this._ds_wave_statuses_dd) {
      this._ds_wave_statuses_dd = this.injector.get(Waves_ds_wave_statuses_ddService);
    }
    return this._ds_wave_statuses_dd;
  }
  private _get_manual_allocations: Waves_get_manual_allocationsService;
  public get get_manual_allocations(): Waves_get_manual_allocationsService {
    if(!this._get_manual_allocations) {
      this._get_manual_allocations = this.injector.get(Waves_get_manual_allocationsService);
    }
    return this._get_manual_allocations;
  }
}

