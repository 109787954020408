<datex-list [items]="items"
            [pageSize]="pageSize"
            [(pageSkip)]="pageSkip"
            [totalCount]="totalCount"
            [loadingStatus]="loadingStatus"
            (loadMore)="$dataLoad()">

  <ng-container topToolbar>
  </ng-container>


  <ng-template listItemDef
               let-item>
    <FootPrintManager-licenseplate_card
    [licenseplate]="item.$content_FootPrintManager_licenseplate_card_inParams_licenseplate"
    ($refreshEvent)="$refreshEvent.emit()"
    >
    </FootPrintManager-licenseplate_card>
  </ng-template>

</datex-list>