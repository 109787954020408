import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LoadContainers_ds_load_containers_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, ownerId?: number, dateType?: string, orderTypeIds?: number[], take?: number, skip?: number, lookupCode?: string }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, CallOutDate?: string, CheckOutDate?: string, ContainerSize?: string, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, InYardDate?: string, LastOnsiteDate?: string, LastPierDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ReasonCodeId?: number, SealIdentifier?: string, TrailerNumber?: string, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string }, ContainerType?: { Id?: number, Name?: string }, OrderType?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, YardLocation?: { Id?: number, Name?: string }, DockAppointmentsLoadContainersLookup?: { DockAppointmentId?: number, DockAppointment?: { LookupCode?: string, ScheduledLocation?: { Name?: string } } }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_load_containers_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, ownerId?: number, dateType?: string, orderTypeIds?: number[], take?: number, skip?: number, lookupCode?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, CallOutDate?: string, CheckOutDate?: string, ContainerSize?: string, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, InYardDate?: string, LastOnsiteDate?: string, LastPierDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ReasonCodeId?: number, SealIdentifier?: string, TrailerNumber?: string, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string }, ContainerType?: { Id?: number, Name?: string }, OrderType?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, YardLocation?: { Id?: number, Name?: string }, DockAppointmentsLoadContainersLookup?: { DockAppointmentId?: number, DockAppointment?: { LookupCode?: string, ScheduledLocation?: { Name?: string } } }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_load_containers_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, ownerId?: number, dateType?: string, orderTypeIds?: number[], take?: number, skip?: number, lookupCode?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, CallOutDate?: string, CheckOutDate?: string, ContainerSize?: string, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, InYardDate?: string, LastOnsiteDate?: string, LastPierDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ReasonCodeId?: number, SealIdentifier?: string, TrailerNumber?: string, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string }, ContainerType?: { Id?: number, Name?: string }, OrderType?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, YardLocation?: { Id?: number, Name?: string }, DockAppointmentsLoadContainersLookup?: { DockAppointmentId?: number, DockAppointment?: { LookupCode?: string, ScheduledLocation?: { Name?: string } } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_load_containers_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
