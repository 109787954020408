import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_get_receiving_tasks_by_orderIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, ActualTargetLicensePlateId?: number, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, GrossWeight?: number, LotId?: number, NetWeight?: number, ProjectId?: number, SerialNumberId?: number, StatusId?: number, WarehouseId?: number, ExpectedTargetLicensePlate?: { Id?: number, LocationId?: number, TypeId?: number, LicensePlateContents?: { LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[] }, Shipment?: { Id?: number, StatusId?: number }, ShipmentLine?: { Id?: number, ActualAmount?: number, ActualPackagedAmount?: number, GrossWeight?: number, NetWeight?: number }, Order?: { Id?: number, OrderStatusId?: number }, ActualTargetLicensePlate?: { Id?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_get_receiving_tasks_by_orderId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualTargetLicensePlateId?: number, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, GrossWeight?: number, LotId?: number, NetWeight?: number, ProjectId?: number, SerialNumberId?: number, StatusId?: number, WarehouseId?: number, ExpectedTargetLicensePlate?: { Id?: number, LocationId?: number, TypeId?: number, LicensePlateContents?: { LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[] }, Shipment?: { Id?: number, StatusId?: number }, ShipmentLine?: { Id?: number, ActualAmount?: number, ActualPackagedAmount?: number, GrossWeight?: number, NetWeight?: number }, Order?: { Id?: number, OrderStatusId?: number }, ActualTargetLicensePlate?: { Id?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_get_receiving_tasks_by_orderId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualTargetLicensePlateId?: number, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, GrossWeight?: number, LotId?: number, NetWeight?: number, ProjectId?: number, SerialNumberId?: number, StatusId?: number, WarehouseId?: number, ExpectedTargetLicensePlate?: { Id?: number, LocationId?: number, TypeId?: number, LicensePlateContents?: { LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[] }, Shipment?: { Id?: number, StatusId?: number }, ShipmentLine?: { Id?: number, ActualAmount?: number, ActualPackagedAmount?: number, GrossWeight?: number, NetWeight?: number }, Order?: { Id?: number, OrderStatusId?: number }, ActualTargetLicensePlate?: { Id?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_get_receiving_tasks_by_orderId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
