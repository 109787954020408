import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent } from './FootPrintManager.warehouse_transfer_outbound_order_lines_grid.component';
import { FootPrintManager_warehouse_transfer_picking_gridComponent } from './FootPrintManager.warehouse_transfer_picking_grid.component';
import { FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_gridComponent } from './FootPrintManager.warehouse_transfer_outbound_order_manual_allocations_grid.component';
import { FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_gridComponent } from './FootPrintManager.warehouse_transfer_outbound_order_shippingcontents_grid.component';
import { WarehouseTransfers_outbound_bill_of_lading_reportComponent } from './WarehouseTransfers.outbound_bill_of_lading_report.component';
import { WarehouseTransfers_outbound_pick_slip_reportComponent } from './WarehouseTransfers.outbound_pick_slip_report.component';
import { FootPrintManager_temperature_readings_gridComponent } from './FootPrintManager.temperature_readings_grid.component';
import { WarehouseTransfers_outbound_order_total_units_widgetComponent } from './WarehouseTransfers.outbound_order_total_units_widget.component';
import { WarehouseTransfers_outbound_order_total_picked_units_widgetComponent } from './WarehouseTransfers.outbound_order_total_picked_units_widget.component';
import { WarehouseTransfers_outbound_order_total_picked_gross_widgetComponent } from './WarehouseTransfers.outbound_order_total_picked_gross_widget.component';
import { SalesOrders_orderclasses_dd_singleComponent } from './SalesOrders.orderclasses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Carriers_carriers_ownerscarriers_dd_singleComponent } from './Carriers.carriers_ownerscarriers_dd_single.component'
import { Carriers_carrierservicetype_dd_singleComponent } from './Carriers.carrierservicetype_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent),
    forwardRef(() => FootPrintManager_warehouse_transfer_picking_gridComponent),
    forwardRef(() => FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_gridComponent),
    forwardRef(() => FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_gridComponent),
    forwardRef(() => WarehouseTransfers_outbound_bill_of_lading_reportComponent),
    forwardRef(() => WarehouseTransfers_outbound_pick_slip_reportComponent),
    forwardRef(() => FootPrintManager_temperature_readings_gridComponent),
    forwardRef(() => WarehouseTransfers_outbound_order_total_units_widgetComponent),
    forwardRef(() => WarehouseTransfers_outbound_order_total_picked_units_widgetComponent),
    forwardRef(() => WarehouseTransfers_outbound_order_total_picked_gross_widgetComponent),
    forwardRef(() => SalesOrders_orderclasses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Carriers_carriers_ownerscarriers_dd_singleComponent),
    forwardRef(() => Carriers_carrierservicetype_dd_singleComponent),
  ],
  selector: 'FootPrintManager-warehouse_transfer_outbound_order_editor',
  templateUrl: './FootPrintManager.warehouse_transfer_outbound_order_editor.component.html'
})
export class FootPrintManager_warehouse_transfer_outbound_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { orderId: number, warehouseTransferId: number } = { orderId: null, warehouseTransferId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('warehouseTransferId') set $inParams_warehouseTransferId(v: number) {
    this.inParams.warehouseTransferId = v;
  }
  get $inParams_warehouseTransferId(): number {
    return this.inParams.warehouseTransferId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, BillOfLading?: string, BrokerReference?: string, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, PickupDate?: string, ReferenceNumber?: string, SealId?: string, TrackingIdentifier?: string, TrailerId?: string } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    po_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier_service: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    container_identifier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, 'Process', 'ms-Icon ms-Icon--Process')
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular')
    ),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, 'Appointment', 'icon-ic_fluent_calendar_ltr_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      auto_pick: new ToolModel(new ButtonModel('auto_pick', new ButtonStyles(null, null), false, 'Auto pick', 'icon-datex-Pick')
    ),
      auto_load: new ToolModel(new ButtonModel('auto_load', new ButtonStyles(null, null), false, 'Auto load', 'icon-datex-Load')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_print_20_regular')
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular')
    ),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_clipboard_task_list_ltr_20_regular')
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Order code', true)
,
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Order class', true)
,
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Owner', true)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Project', true)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', true)
,
    order_status: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Order status', false)
,
    po_number: new FieldModel(new TextBoxModel(this.formGroup.controls['po_number'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'PO', false)
,
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Reference', false)
,
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Carrier', false)
,
    carrier_service: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier_service'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Service', false)
,
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'Expected date', false)
,
    container_identifier: new FieldModel(new TextBoxModel(this.formGroup.controls['container_identifier'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Container Identifier', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      reports: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      sales_order_lines: new TabItemModel(
        this.rootTabGroup, 
        'Lines', 
        ),
      picking_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Pick tasks', 
        ),
      manual_allocation_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Manual tasks', 
        ),
      shipping_contents: new TabItemModel(
        this.rootTabGroup, 
        'Picked', 
        ),
      reports: new TabItemModel(
        this.rootTabGroup, 
        'Reports', 
        true,
        {
          bill_of_lading: new TabItemModel(
          this.subTabGroups.reports, 
          'Bill of Lading', 
          ),
          pick_slip: new TabItemModel(
          this.subTabGroups.reports, 
          'Pick Slip', 
          ),
        }
        ),
      temperatures: new TabItemModel(
        this.rootTabGroup, 
        'Temperatures', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_sales_order_lines_warehouse_transfer_outbound_order_lines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_warehouse_transfer_outbound_order_lines_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_warehouse_transfer_outbound_order_lines_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_warehouse_transfer_outbound_order_lines_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_warehouse_transfer_outbound_order_lines_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.warehouse.control.value;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_warehouse_transfer_outbound_order_lines_grid_inParams_warehouseTransferId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.warehouseTransferId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_warehouse_transfer_outbound_order_lines_grid_inParams_readOnly(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    get $tabs_picking_tasks_warehouse_transfer_picking_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_picking_tasks_warehouse_transfer_picking_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_picking_tasks_warehouse_transfer_picking_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_manual_allocation_tasks_warehouse_transfer_outbound_order_manual_allocations_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_shipping_contents_warehouse_transfer_outbound_order_shippingcontents_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_shipping_contents_warehouse_transfer_outbound_order_shippingcontents_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_shipping_contents_warehouse_transfer_outbound_order_shippingcontents_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_reports_bill_of_lading_outbound_bill_of_lading_report_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_reports_pick_slip_outbound_pick_slip_report_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_temperatures_temperature_readings_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_sales_order_lines', { read: FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent }) $tabs_sales_order_lines: FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent;
      @ViewChild('$tabs_picking_tasks', { read: FootPrintManager_warehouse_transfer_picking_gridComponent }) $tabs_picking_tasks: FootPrintManager_warehouse_transfer_picking_gridComponent;
      @ViewChild('$tabs_manual_allocation_tasks', { read: FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_gridComponent }) $tabs_manual_allocation_tasks: FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_gridComponent;
      @ViewChild('$tabs_shipping_contents', { read: FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_gridComponent }) $tabs_shipping_contents: FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_gridComponent;
        @ViewChild('$tabs_reports_bill_of_lading', { read: WarehouseTransfers_outbound_bill_of_lading_reportComponent }) $tabs_reports_bill_of_lading: WarehouseTransfers_outbound_bill_of_lading_reportComponent;
        @ViewChild('$tabs_reports_pick_slip', { read: WarehouseTransfers_outbound_pick_slip_reportComponent }) $tabs_reports_pick_slip: WarehouseTransfers_outbound_pick_slip_reportComponent;
      @ViewChild('$tabs_temperatures', { read: FootPrintManager_temperature_readings_gridComponent }) $tabs_temperatures: FootPrintManager_temperature_readings_gridComponent;
    //#endregion tabs children
    widgets = {
      order_total_units_widget: new WidgetModel(),
      order_total_picked_units_widget: new WidgetModel(),
      order_total_picked_gross_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_order_total_units_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_widget_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_gross_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_gross_widget_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_order_total_units_widget', { read:  WarehouseTransfers_outbound_order_total_units_widgetComponent }) $widgets_order_total_units_widget: WarehouseTransfers_outbound_order_total_units_widgetComponent;
      @ViewChild('$widgets_order_total_picked_units_widget', { read:  WarehouseTransfers_outbound_order_total_picked_units_widgetComponent }) $widgets_order_total_picked_units_widget: WarehouseTransfers_outbound_order_total_picked_units_widgetComponent;
      @ViewChild('$widgets_order_total_picked_gross_widget', { read:  WarehouseTransfers_outbound_order_total_picked_gross_widgetComponent }) $widgets_order_total_picked_gross_widget: WarehouseTransfers_outbound_order_total_picked_gross_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  get $fields_carrier_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.Project?.OwnerId;
    
    return expr;
  }

  get $fields_carrier_service_selector_inParams_carrierId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.carrier.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    po_number: this.fields.po_number.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    carrier_service: this.fields.carrier_service.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    container_identifier: this.fields.container_identifier.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.sales_order_lines,
      this.tabs.picking_tasks,
      this.tabs.manual_allocation_tasks,
      this.tabs.shipping_contents,
      this.tabs.reports,
      this.tabs.temperatures,
    ]; 
    this.subTabGroups.reports.tabs = [
        this.tabs.reports.tabs.bill_of_lading,
        this.tabs.reports.tabs.pick_slip,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.warehouseTransferId)) {
        this.$missingRequiredInParams.push('warehouseTransferId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit outbound order';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.orderId 
    };

    const data = await this.datasources.SalesOrders.ds_sales_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClassId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.po_number.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.reference.control as TextBoxModel).reset($editor.entity.OwnerReference);
    (this.fields.carrier.control as SelectBoxModel).reset($editor.entity.PreferredCarrierId);
    (this.fields.carrier_service.control as SelectBoxModel).reset($editor.entity.PreferredCarrierServiceTypeId);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.RequestedDeliveryDate);
    (this.fields.container_identifier.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.ContainerIdentifier);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_order_total_units_widget') {
      if (!isNil(this.$widgets_order_total_units_widget) && !this.widgets.order_total_units_widget.hidden) {
        this.$widgets_order_total_units_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_picked_units_widget') {
      if (!isNil(this.$widgets_order_total_picked_units_widget) && !this.widgets.order_total_picked_units_widget.hidden) {
        this.$widgets_order_total_picked_units_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_picked_gross_widget') {
      if (!isNil(this.$widgets_order_total_picked_gross_widget) && !this.widgets.order_total_picked_gross_widget.hidden) {
        this.$widgets_order_total_picked_gross_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_sales_order_lines') {
        if (!isNil(this.$tabs_sales_order_lines) && !this.tabs.sales_order_lines.hidden) {
          this.$tabs_sales_order_lines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_picking_tasks') {
        if (!isNil(this.$tabs_picking_tasks) && !this.tabs.picking_tasks.hidden) {
          this.$tabs_picking_tasks.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_manual_allocation_tasks') {
        if (!isNil(this.$tabs_manual_allocation_tasks) && !this.tabs.manual_allocation_tasks.hidden) {
          this.$tabs_manual_allocation_tasks.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_shipping_contents') {
        if (!isNil(this.$tabs_shipping_contents) && !this.tabs.shipping_contents.hidden) {
          this.$tabs_shipping_contents.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_reports_bill_of_lading') {
        if (!isNil(this.$tabs_reports_bill_of_lading) && !this.tabs.reports.tabs.bill_of_lading.hidden) {
          this.$tabs_reports_bill_of_lading.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_reports_pick_slip') {
        if (!isNil(this.$tabs_reports_pick_slip) && !this.tabs.reports.tabs.pick_slip.hidden) {
          this.$tabs_reports_pick_slip.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_temperatures') {
        if (!isNil(this.$tabs_temperatures) && !this.tabs.temperatures.hidden) {
          this.$tabs_temperatures.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .po_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .carrier_service
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .container_identifier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const isConfirm = await $shell.FootPrintManager.openConfirmationDialog('Complete Order', `Are you sure you want to complete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  if (isConfirm) {
      try {
          const result = await $flows.WarehouseTransfers.complete_source_outbound_order_flow({ order_id: $editor.entity.Id });
          const reasons = result.reasons;
  
          if ($utils.isDefined(reasons)) {
              await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
              return;
          }
          else {
  
              const title = 'Complete Order';
              const message = `Order ${$editor.entity.LookupCode} completed.`;
              await $shell.FootPrintManager.openInfoDialog(title, message);
              await $editor.refresh();
              await $editor.tabs.shipping_contents.activate();
          }
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Order complete error', 'An error occurred during the completion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set editor title
  $editor.title = `Outbound order: ${$editor.entity.LookupCode}`;
  
  // Set custom status
  const p_order_state = $flows.SalesOrders.get_sales_order_state({
      orders: [{
          orderId: $editor.entity.Id,
          shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
      }]
  });
  
  // Fetch shipments
  let warehouseTransfer = (await $datasources.WarehouseTransfers.ds_get_warehouse_transfer_by_warehouseTransferId.get({ warehouseTransferId: $editor.inParams.warehouseTransferId })).result;
  let order = warehouseTransfer.SourceOutboundOrder;
  let shipments = order.ShipmentOrderLookups.map(sol => sol.Shipment);
  
  $editor.fields.warehouse.control.value = warehouseTransfer.SourceWarehouseId;
  
  // Apply appointment text logic
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dockAppointmentId: dockAppointments[0]?.DockAppointmentId,
      })).formattedDockAppointment;
  
  } else {
      if (order.OrderStatusId !== 1 && order.OrderStatusId !== 2 && order.OrderStatusId !== 1024) {
          $editor.toolbar.appointment.hidden = true;
      }
      else {
          // Reset the appointment label back to original
          $editor.toolbar.appointment.hidden = false;
          $editor.toolbar.appointment.control.styles.resetStyle();
          $editor.toolbar.appointment.control.label = 'Appointment'
      }
  }
  
  
  if (shipments.length > 1) {
      // close
      await $shell.FootPrintManager.openErrorDialog('Invalid order', 'This order has more than one shipment. This editor cannot display this kind of order. Editor will close');
      $editor.close();
      return;
  }
  
  else {
  
  
      if (shipments.length === 1) {
          $editor.fields.container_identifier.control.readOnly = false;
          $editor.fields.warehouse.required = true;
      }
  
      // created status
      if ($editor.entity.OrderStatusId === 1) {
          // Reset process button in case the editor is refreshed back to a created status
          $editor.toolbar.process.hidden = false;
          $editor.toolbar.process.control.styles.resetStyle();
          $editor.toolbar.process.control.label = 'Process';
          $editor.toolbar.process.control.icon = "icon-ic_fluent_arrow_clockwise_dashes_20_regular";
  
          $editor.toolbar.auto_load.hidden = true;
          $editor.toolbar.auto_pick.hidden = true;
          $editor.toolbar.complete.hidden = true;
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
  
          $editor.toolbar.process.control.readOnly = !hasOrderLines;
  
          $editor.fields.po_number.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.order_total_picked_gross_widget.hidden = true;
          $editor.widgets.order_total_picked_units_widget.hidden = true;
          $editor.widgets.order_total_units_widget.hidden = true;
  
          if ($editor.tabs.picking_tasks.active) {
              $editor.tabs.sales_order_lines.activate();
          }
  
          $editor.tabs.picking_tasks.hidden = true;
          $editor.tabs.manual_allocation_tasks.hidden = true;
          $editor.tabs.shipping_contents.hidden = true;
  
  
      } else if ($editor.entity.OrderStatusId === 2) {
          // processing status
  
  
          $editor.fields.po_number.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          let warehouseTransferState = await $flows.WarehouseTransfers.get_warehouse_transfer_status_flow({
              warehouse_transfer_id: $editor.inParams.warehouseTransferId
          });
  
          // Set manual allocations
          var manualAllocations;
          if ($utils.isDefined(warehouseTransferState.details.has_manual_allocations)) {
  
              if (warehouseTransferState.details.has_manual_allocations) {
                  $editor.tabs.manual_allocation_tasks.hidden = false;
                  manualAllocations = true;
              } else {
                  manualAllocations = false;
                  $editor.tabs.manual_allocation_tasks.hidden = true;
              }
          } else {
              manualAllocations = false;
              $editor.tabs.manual_allocation_tasks.hidden = true;
          }
  
  
          if ($utils.isDefined(warehouseTransferState.state)) {
              switch (warehouseTransferState.state) {
  
                  case 2: {
                      $editor.fields.order_status.control.text = 'Ready to create wave'
  
                      $editor.toolbar.process.hidden = false;
                      $editor.toolbar.process.control.readOnly = false;
  
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
  
                      break;
                  }
                  case 3: {
                      $editor.fields.order_status.control.text = 'Ready to process wave'
  
                      $editor.toolbar.process.hidden = false;
                      $editor.toolbar.process.control.readOnly = false;
  
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
  
                      break;
                  }
                  case 4: {
                      if (warehouseTransferState.details.has_manual_allocations) {
                          $editor.fields.order_status.control.text = 'Has manual allocations'
                      }
                      else {
                          $editor.fields.order_status.control.text = 'Ready to release wave'
                      }
  
                      $editor.toolbar.process.hidden = false;
                      $editor.toolbar.process.control.readOnly = false;
  
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
  
                      break;
                  }
                  // All pick tasks in a released status
                  case 5: {
                      $editor.fields.order_status.control.text = 'Ready to pick'
  
                      $editor.toolbar.auto_pick.hidden = false;
  
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = false;
  
                      break;
                  } // One or more pick tasks completed and one or more pick tasks released
                  case 6: {
                      $editor.fields.order_status.control.text = 'Picking in progress'
                      $editor.toolbar.auto_pick.hidden = false;
  
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = false;
  
                      break;
                  } // No released pick tasks exist and one or more shipping licenseplates require a loading task
                  case 7: {
                      $editor.fields.order_status.control.text = 'Ready to load'
                      $editor.toolbar.auto_load.hidden = false;
  
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
                      break;
                  } // No released pick tasks or manual allocations exist and all shipping licenseplates loaded
                  case 8: {
                      if (manualAllocations) {
                          $editor.fields.order_status.control.text = 'Contains manual allocations'
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = true;
                      } else {
                          $editor.fields.order_status.control.text = 'Loading in progress'
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = false;
  
                      }
  
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
  
  
                      break;
                  }
                  // No released pick tasks or manual allocations exist and all shipping contents unpicked
                  case 9: {
                      $editor.fields.order_status.control.text = 'Ready to complete'
                      $editor.toolbar.complete.hidden = false;
                      $editor.toolbar.complete.control.readOnly = false;
  
                      $editor.toolbar.process.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
                      break;
                  }
  
  
              }
          } else {
              if (manualAllocations) {
                  $editor.fields.order_status.control.text = 'Manual Allocations'
                  $editor.toolbar.complete.hidden = false;
                  $editor.toolbar.complete.control.readOnly = true;
              }
              $editor.toolbar.process.hidden = true;
              $editor.toolbar.auto_load.hidden = true;
              $editor.toolbar.auto_pick.hidden = true;
  
          }
          // Widgets
          $editor.widgets.order_total_picked_gross_widget.hidden = false;
          $editor.widgets.order_total_picked_units_widget.hidden = false;
          $editor.widgets.order_total_units_widget.hidden = false;
  
  
      } else {
          $editor.toolbar.process.hidden = true;
          $editor.toolbar.complete.hidden = true;
          $editor.toolbar.auto_load.hidden = true;
          $editor.toolbar.auto_pick.hidden = true;
  
          $editor.fields.po_number.control.readOnly = true;
          $editor.fields.expected_date.control.readOnly = true;
          $editor.fields.notes.control.readOnly = true;
          $editor.fields.container_identifier.control.readOnly = true;
          $editor.fields.reference.control.readOnly = true;
  
  
          // Widgets
          $editor.widgets.order_total_picked_gross_widget.hidden = false;
          $editor.widgets.order_total_picked_units_widget.hidden = false;
          $editor.widgets.order_total_units_widget.hidden = false;
  
          $editor.tabs.picking_tasks.hidden = true;
  
          $editor.tabs.shipping_contents.hidden = false;
  
      }
  }
  
  $editor.fields.order_status.control.text = (await p_order_state).states[0]?.stateName ?? $editor.fields.order_status.control.text;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.tabs.picking_tasks.hidden = true;
  $editor.tabs.manual_allocation_tasks.hidden = true;
  $editor.tabs.shipping_contents.hidden = true;
  
  
  
  
  // Set Date/Time Formats
  $editor.fields.expected_date.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Create shipment if none found
  const warehouseTransfer = (await $datasources.WarehouseTransfers.ds_get_warehouse_transfer_by_warehouseTransferId.get({ warehouseTransferId: $editor.inParams.warehouseTransferId })).result;
  const order = warehouseTransfer.SourceOutboundOrder;
  const shipments = order.ShipmentOrderLookups.map(sol => sol.Shipment);
  if (!$utils.isDefined(shipments)) {
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      if ($utils.isAllDefined(order, warehouseTransfer.SourceWarehouseId)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "ExpectedWarehouseId": warehouseTransfer.SourceWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 2
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
  
      }
  
  
  
  }
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const isConfirm = await $shell.FootPrintManager.openConfirmationDialog('Process Order', `Are you sure you want to process order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  if (isConfirm) {
      let section: string = 'Processing order';
  
      try {
  
          $editor.toolbar.process.control.readOnly = true;
          $editor.toolbar.process.control.icon = "icon datex-default-spinner";
  
          for (let i = 0; i < 5; i++) {
              let warehouseTransferState = await $flows.WarehouseTransfers.get_warehouse_transfer_status_flow({
                  warehouse_transfer_id: $editor.inParams.warehouseTransferId
              });
  
              switch (warehouseTransferState.state) {
                  case 1: {
                      section = 'Processing order';
  
                      if (warehouseTransferState.eligibilities.is_outbound_processable) {
                          $editor.toolbar.process.control.label = section + '...';
  
                          const result = await $flows.WarehouseTransfers.process_source_outbound_order_flow({
                              order_id: $editor.entity.Id
                          });
  
                          const reasons = result.reasons;
  
                          if ($utils.isDefined(reasons)) {
                              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
                          }
                      }
                      else {
                          throw new Error('Order is not able to be processed.');
                      }
  
                      break;
                  }
                  case 2: {
                      section = 'Creating wave';
  
                      if (warehouseTransferState.eligibilities.is_outbound_processable) {
                          $editor.toolbar.process.control.label = section + '...';
  
                          const result = await $flows.WarehouseTransfers.create_wave_source_outbound_order_flow({
                              order_id: $editor.entity.Id
                          });
  
                          const reasons = result.reasons;
  
                          if ($utils.isDefined(reasons)) {
                              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
                          }
                      }
                      else {
                          throw new Error('Order is not able to be processed.');
                      }
  
                      break;
                  }
                  case 3: {
                      section = 'Processing wave';
  
                      if (warehouseTransferState.eligibilities.is_outbound_processable) {
                          $editor.toolbar.process.control.label = section + '...';
  
                          const result = await $flows.WarehouseTransfers.process_wave_source_outbound_order_flow({
                              order_id: $editor.entity.Id
                          });
  
                          const reasons = result.reasons;
  
                          if ($utils.isDefined(reasons)) {
                              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
                          }
                      }
                      else {
                          throw new Error('Order is not able to be processed.');
                      }
  
                      break;
                  }
                  case 4: {
                      section = 'Releasing wave';
  
                      if (warehouseTransferState.eligibilities.is_outbound_processable) {
                          $editor.toolbar.process.control.label = section + '...';
  
                          const result = await $flows.WarehouseTransfers.release_wave_source_outbound_order_flow({
                              order_id: $editor.entity.Id
                          });
  
                          const reasons = result.reasons;
  
                          if ($utils.isDefined(reasons)) {
                              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
                          }
                      }
                      else {
                          throw new Error('Order is not able to be processed.');
                      }
  
                      break;
                  }
              }
          }
  
          await $shell.FootPrintManager.openInfoDialog('Successfully processed order', `Order ${$editor.entity.LookupCode} has processed successfully.`);
          await $editor.tabs.picking_tasks.activate();
  
  
      }
      catch (error) {
          const title = 'Error ' + section.charAt(0).toLowerCase() + section.slice(1);
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog(title, 'An error occurred while processing the order.', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  
      $editor.toolbar.process.control.readOnly = false;
  
      $editor.refresh();
  }
  
  
  
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  var shipmentId = $editor.entity.ShipmentOrderLookups[0].ShipmentId;
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
  }
  if ($editor.fields.reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.reference.control.value;
  }
  if ($editor.fields.po_number.control.isChanged) {
      payload.VendorReference = $editor.fields.po_number.control.value;
  }
  if ($editor.fields.carrier.control.isChanged) {
      payload.PreferredCarrierId = $editor.fields.carrier.control.value;
      // Clear the carrier service type as it could conflict with the newly selected carrier
      payload.PreferredCarrierServiceTypeId = null;
      $editor.fields.carrier_service.control.value = null;
      $editor.fields.carrier_service.control.displayText = null;
  
  }
  if ($editor.fields.carrier_service.control.isChanged) {
      payload.PreferredCarrierServiceTypeId = $editor.fields.carrier_service.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
          throw new Error('Please select a warehouse.')
      }
      else {
          payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
      }
  }
  if ($editor.fields.expected_date.control.isChanged) {
      payload.RequestedDeliveryDate = $editor.fields.expected_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  // Create the shipment if it does not exist only if the order has a preferred warehouse of if the preferred warehouse is selected in the editor
  if (!$utils.isDefined(shipmentId)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const warehouseTransfer = (await $datasources.WarehouseTransfers.ds_get_warehouse_transfer_by_warehouseTransferId.get({
          warehouseTransferId: $editor.inParams.warehouseTransferId
      })).result;
      const order = warehouseTransfer.SourceOutboundOrder;
  
  
  
      if ($utils.isAllDefined(order, warehouseTransfer.SourceWarehouseId) || $utils.isAllDefined(order, $editor.fields.warehouse.control.value)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": $utils.isDefined($editor.entity.PreferredCarrierId) ? $editor.entity.PreferredCarrierId : null,
                  "CarrierServiceTypeId": $utils.isDefined($editor.entity.PreferredCarrierServiceTypeId) ? $editor.entity.PreferredCarrierServiceTypeId : null,
                  "ExpectedWarehouseId": warehouseTransfer.SourceWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 2
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
          shipmentId = nextShipmentId;
  
  
      }
  
  
  }
  
  if ($utils.isDefined(shipmentId)) {
  
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
      if ($editor.fields.warehouse.control.isChanged) {
          if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
              throw new Error('Please select a warehouse.')
          }
          else {
              payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
          }
      }
      if ($editor.fields.expected_date.control.isChanged) {
          payload.ExpectedDate = $editor.fields.expected_date.control.value;
      }
      if ($editor.fields.container_identifier.control.isChanged) {
          payload.ContainerIdentifier = $editor.fields.container_identifier.control.value;
      }
      if ($editor.fields.carrier.control.isChanged) {
          payload.CarrierId = $editor.fields.carrier.control.value;
          // Clear the carrier service type as it could conflict with the newly selected carrier
          payload.CarrierServiceTypeId = null;
          $editor.fields.carrier_service.control.value = null;
          $editor.fields.carrier_service.control.displayText = null;
      }
      if ($editor.fields.carrier_service.control.isChanged) {
          payload.CarrierServiceTypeId = $editor.fields.carrier_service.control.value;
      }
  
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payload });
  
  
  }
  
  await $editor.refresh();
  }
  on_auto_pick_clicked(event = null) {
    return this.on_auto_pick_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_pick_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var warehouseId = $editor.fields.warehouse.control.value;
  if (!$utils.isDefined(warehouseId)) {
  
      $shell.FootPrintManager.openErrorDialog('Order Auto Pick Error', 'Unable to determine warehouse from first order selected');
      return;
  }
  
  const dialogResult = await $shell.FootPrintManager.openauto_pick_options_formDialog({ warehouse_id: warehouseId });
  var userConfirmed = dialogResult.confirm;
  var autoLoadConfirmed = dialogResult.auto_load;
  var loadLocation = dialogResult.load_location;
  let license_plate_lookup = dialogResult.license_plate_lookup;
  
  if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
      return;
  }
  
  if (userConfirmed) {
      try {
  
          const result = await $flows.WarehouseTransfers.auto_pick_source_outbound_order_flow({
              order_id: $editor.entity.Id
          });
          let reasons = result.reasons;
  
          if ($utils.isAllDefined(userConfirmed, autoLoadConfirmed) && autoLoadConfirmed === true) {
  
              const resultLoad = await $flows.WarehouseTransfers.auto_load_source_outbound_order_flow({
                  order_id: $editor.entity.Id,
                  load_location_id: $utils.isDefined(loadLocation) ? loadLocation : null
              });
              var reasonsLoad = resultLoad.reasons;
          }
  
          if ($utils.isDefined(reasons)) {
              await $shell.FootPrintManager.openErrorDialog('Order cannot be fully auto picked.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
              await $editor.refresh();
              await $editor.tabs.picking_tasks.activate();
              return;
          }
          else if ($utils.isDefined(reasonsLoad)) {
              await $shell.FootPrintManager.openErrorDialog('Order cannot be fully auto loaded.', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasonsLoad })).formattedValue);
              await $editor.refresh();
              await $editor.tabs.shipping_contents.activate();
              return;
          }
          else {
  
              const title = 'Auto Pick Order';
              const loadmessage = (autoLoadConfirmed === true) ? ' and fully auto loaded.' : '.'
              const message = `Order ${$editor.entity.LookupCode} has been fully auto picked${loadmessage}`;
              await $shell.FootPrintManager.openInfoDialog(title, message);
              await $editor.refresh();
              await $editor.tabs.shipping_contents.activate();
          }
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Auto pick order error', 'An error occurred during the auto picking of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          await $editor.refresh();
          await $editor.tabs.picking_tasks.activate();
      }
  }
  
  }
  on_auto_load_clicked(event = null) {
    return this.on_auto_load_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_load_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let isConfirm = await $shell.FootPrintManager.openConfirmationDialog('Auto load order', `Are you sure you want to auto load the entire order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  if (isConfirm) {
      try {
  
          const result = await $flows.WarehouseTransfers.auto_load_source_outbound_order_flow({
              order_id: $editor.entity.Id
          });
          const reasons = result.reasons;
  
          if ($utils.isDefined(reasons)) {
              await $shell.FootPrintManager.openErrorDialog('Order cannot be fully auto loaded.', (await $flows.Utilities.grammar_format_string_array_flow({values: reasons})).formattedValue);
              return;
          }
          else {
  
              const title = 'Auto Load Order';
              const message = `Order ${$editor.entity.LookupCode} has been fully auto loaded.`;
              await $shell.FootPrintManager.openInfoDialog(title, message);
              await $editor.refresh();
              await $editor.tabs.shipping_contents.activate();
          }
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Auto load order error', 'An error occurred during the auto loading of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId
      });
  
  } else {
        // Check if the selected order is in a rejected status and throw an error.
      if ($editor.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
         
          warehouseId: [$editor.fields.warehouse.control.value],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $editor.entity.RequestedDeliveryDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $editor.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 2,
          ownerId:  $editor.entity.Project.OwnerId,
          projectId: $editor.entity.ProjectId,
          carrierId: $editor.entity.PreferredCarrierId,
          orderId: $editor.inParams.orderId
      });
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.openInfoDialog('Not yet implemented', 'The print functionality for this screen has not yet been implemented.');
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'Order', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
      await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$editor.entity.ShipmentOrderLookups[0].ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'modal');
  }
  
  
  }
  //#endregion private flows
}
