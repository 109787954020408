<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-fields"
                     *ngIf="!filtersets.fields.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.fields.collapsible }">
                    <div *ngIf="!filtersets.fields.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.fields.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.fields.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.fields.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.fields.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.fields.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-operation_context_type" *ngIf="!filters.operation_context_type.hidden" 
                                  class="field-container double {{filters.operation_context_type.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.operation_context_type.styles.style"
                                  [ngClass]="filters.operation_context_type.styles.classes">
                              <div class="label-container"
                                    title="{{filters.operation_context_type.label}}{{filters.operation_context_type.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.operation_context_type.label}}">{{filters.operation_context_type.label}}<span *ngIf="filters.operation_context_type.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="operation_context_type"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.operation_context_type.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.operation_context_type.control.placeholder}}"
                                      [ngStyle]="filters.operation_context_type.control.styles.style"
                                      [ngClass]="filters.operation_context_type.control.styles.classes"> 
                              <ng-container *ngIf="filters.operation_context_type.invalid">
                                <ng-container *ngFor="let error of filters.operation_context_type.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-equipment" *ngIf="!filters.equipment.hidden" 
                                  class="field-container standard {{filters.equipment.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.equipment.styles.style"
                                  [ngClass]="filters.equipment.styles.classes">
                              <div class="label-container"
                                    title="{{filters.equipment.label}}{{filters.equipment.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.equipment.label}}">{{filters.equipment.label}}<span *ngIf="filters.equipment.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="equipment"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.equipment.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.equipment.control.placeholder}}"
                                      [ngStyle]="filters.equipment.control.styles.style"
                                      [ngClass]="filters.equipment.control.styles.classes"> 
                              <ng-container *ngIf="filters.equipment.invalid">
                                <ng-container *ngFor="let error of filters.equipment.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-created_on" *ngIf="!filters.created_on.hidden" 
                                  class="field-container standard {{filters.created_on.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.created_on.styles.style"
                                  [ngClass]="filters.created_on.styles.classes">
                              <div class="label-container"
                                    title="{{filters.created_on.label}}{{filters.created_on.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.created_on.label}}">{{filters.created_on.label}}<span *ngIf="filters.created_on.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="created_on"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.created_on.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.created_on.control.placeholder}}"
                                      [ngStyle]="filters.created_on.control.styles.style"
                                      [ngClass]="filters.created_on.control.styles.classes"> 
                              <ng-container *ngIf="filters.created_on.invalid">
                                <ng-container *ngFor="let error of filters.created_on.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-created_by" *ngIf="!filters.created_by.hidden" 
                                  class="field-container standard {{filters.created_by.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.created_by.styles.style"
                                  [ngClass]="filters.created_by.styles.classes">
                              <div class="label-container"
                                    title="{{filters.created_by.label}}{{filters.created_by.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.created_by.label}}">{{filters.created_by.label}}<span *ngIf="filters.created_by.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="created_by"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.created_by.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.created_by.control.placeholder}}"
                                      [ngStyle]="filters.created_by.control.styles.style"
                                      [ngClass]="filters.created_by.control.styles.classes"> 
                              <ng-container *ngIf="filters.created_by.invalid">
                                <ng-container *ngFor="let error of filters.created_by.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-completed_on" *ngIf="!filters.completed_on.hidden" 
                                  class="field-container standard {{filters.completed_on.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.completed_on.styles.style"
                                  [ngClass]="filters.completed_on.styles.classes">
                              <div class="label-container"
                                    title="{{filters.completed_on.label}}{{filters.completed_on.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.completed_on.label}}">{{filters.completed_on.label}}<span *ngIf="filters.completed_on.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="completed_on"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.completed_on.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.completed_on.control.placeholder}}"
                                      [ngStyle]="filters.completed_on.control.styles.style"
                                      [ngClass]="filters.completed_on.control.styles.classes"> 
                              <ng-container *ngIf="filters.completed_on.invalid">
                                <ng-container *ngFor="let error of filters.completed_on.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-completed_by" *ngIf="!filters.completed_by.hidden" 
                                  class="field-container standard {{filters.completed_by.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.completed_by.styles.style"
                                  [ngClass]="filters.completed_by.styles.classes">
                              <div class="label-container"
                                    title="{{filters.completed_by.label}}{{filters.completed_by.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.completed_by.label}}">{{filters.completed_by.label}}<span *ngIf="filters.completed_by.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="completed_by"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.completed_by.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.completed_by.control.placeholder}}"
                                      [ngStyle]="filters.completed_by.control.styles.style"
                                      [ngClass]="filters.completed_by.control.styles.classes"> 
                              <ng-container *ngIf="filters.completed_by.invalid">
                                <ng-container *ngFor="let error of filters.completed_by.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inputs.hidden" class="tab" data-cy="tab-inputs">
              <h2 [className]="tabs.inputs.active? 'active': ''" (click)="tabs.inputs.activate()">{{tabs.inputs.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-submitted_survey_inputs_grid *ngIf="tabs.inputs.active"
              #$tabs_inputs
              [surveyId]="$tabs_inputs_submitted_survey_inputs_grid_inParams_surveyId"
              ($refreshEvent)="refresh(false, false, '$tabs_inputs')">
              </FootPrintManager-submitted_survey_inputs_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>