import { HttpErrorResponse } from '@angular/common/http';
import { has } from 'lodash-es';

export class DatexErrorInfo {
  rawError: any;

  code: string;
  message: string;
  innerError: any;
  detail: string;

  constructor(error: Error | HttpErrorResponse) {
    this.rawError = error;

    if (error instanceof HttpErrorResponse) {
      this.initFromHttpError(error);
    } else if (error instanceof Error) {
      this.initFromError(error);
    }
  }

  private initFromError(error: Error) {
    this.code = error.name;
    this.message = error.message;
    if (error.stack) {
      this.detail = error.stack;
    }
  }

  private initFromHttpError(httpErrorResponse: HttpErrorResponse) {
    this.code = httpErrorResponse.name;
    this.message = httpErrorResponse.message;
    const error = httpErrorResponse.error;
    this.innerError = error;

    if (error) {
      // Is the error object an error object coming from the API?
      if (has(error, 'error') && has(error.error, 'code') && has(error.error, 'message')) {
        const errorPayload = error.error;
        this.code = errorPayload.code;
        this.message = errorPayload.message;

        // the details comes from the innererror
        if (has(errorPayload, 'innererror')) {
          this.innerError = errorPayload.innererror;
          try {
            this.detail = JSON.stringify(errorPayload.innererror);
          } catch {
            console.warn('unable to json stringify innererror object inside HttpErrorResponse.error');
          }
        }
      } else {
        if (has(error, 'message')) {
          this.message = error.message;
        } else if (has(error, 'title')) {
          // assume it is a Problem Details JSON https://datatracker.ietf.org/doc/html/rfc7807
          this.message = error.title;
        }
        try {
          this.detail = JSON.stringify(error);
        } catch {
          console.warn('unable to json stringify error object inside HttpErrorResponse');
        }
      }
    }
  }
}
