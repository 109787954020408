import {
  FormControlName,
  FormControlDirective,
  FormControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  ValidatorFn,
  FormGroup,
  ControlValueAccessor
} from '@angular/forms';
import { Observable, from, map, merge, mergeMap } from 'rxjs';
import { ValueControlModel } from './control';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';

export class DatexFormControl extends FormControl {
  nativeElement?: HTMLElement;
  // use controlValueAccessor to get access to MatCheckbox and MatSlideToggle instance
  controlValueAccessor?: ControlValueAccessor;

  constructor(formState?: any, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super(formState, validatorOrOpts, asyncValidator);
  }

  focus(options?: FocusOptions) {
    setTimeout(() => {
      if (this.controlValueAccessor instanceof MatCheckbox) {
        // https://github.com/angular/components/blob/df60e733c60e572ba538f6ad0ceff3e63e527b53/src/material/legacy-checkbox/checkbox.ts#L160
        this.controlValueAccessor.focus();
      } else if (this.controlValueAccessor instanceof MatSlideToggle) {
        // https://github.com/angular/components/blob/df60e733c60e572ba538f6ad0ceff3e63e527b53/src/material/legacy-slide-toggle/slide-toggle.ts#L148
        this.controlValueAccessor.focus();
      }
      else if (this.nativeElement) {
        this.nativeElement.focus(options);
      }
    }, 0);
  }

  blur() {
    setTimeout(() => {
      if (this.nativeElement) {
        this.nativeElement.blur();
      }
    }, 0);
  }
}

const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
  if (this.valueAccessor._elementRef) {
    this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
    this.form.controlValueAccessor = this.valueAccessor;
  }
  return originFormControlNgOnChanges.apply(this, arguments);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);
  if (this.valueAccessor._elementRef) {
    this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
    this.control.controlValueAccessor = this.valueAccessor;
  }
  return result;
};

export const validateControlOnChange = (control: ValueControlModel, validator: () => Promise<string[]>): Observable<void> => {
  return merge(control.valueChanges, control.validationTriggered)
    .pipe(mergeMap(() => validator()))
    .pipe(map(errors => {
      const entries = Object.entries(control.errors ?? {}).filter(i => !i[0].includes('fieldValidation') && !i[0].includes('formValidation'));
      const angularErrors = entries.length ? Object.fromEntries(entries) : null;
      const customErrors = toErrors('fieldValidation', errors);
      const allErrors = angularErrors || customErrors
        ? Object.assign({}, customErrors, angularErrors)
        : null;
      control.setErrors(allErrors);
    }));
}

export const validateFormOnControlChange = (form: FormGroup, validator: () => Promise<{ [key: string]: string[] }>) => {
  return from(validator()).pipe(map(validationResult => {
    for (let key in validationResult) {
      const result = {};
      const currentErrors = form.controls[key].errors ?? {};
      if (currentErrors) {
        const entries = Object.entries(currentErrors).filter(i => !i[0].includes('formValidation'));
        const fieldErrors = entries.length ? Object.fromEntries(entries) : null;
        Object.assign(result, fieldErrors);
      }
      if (validationResult[key].length) {
        Object.assign(result, toErrors('formValidation', validationResult[key]))
      }
      form.controls[key].setErrors(Object.keys(result).length ? result : null);
    }
  }));
}

const toErrors = (key: string, arr: string[]) => {
  return arr.length
    ? arr.reduce((a, v, i) => ({ ...a, [`${key}_${i}`]: v }), {})
    : null;
}