import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';
import { Addresses_FlowService } from './Addresses.flow.index';
import { Carriers_FlowService } from './Carriers.flow.index';
import { Instructions_FlowService } from './Instructions.flow.index';
import { Materials_FlowService } from './Materials.flow.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { Owners_FlowService } from './Owners.flow.index';
import { Discussions_FlowService } from './Discussions.flow.index';
import { Document360_FlowService } from './Document360.flow.index';
import { Usersnap_FlowService } from './Usersnap.flow.index';
import { Locations_FlowService } from './Locations.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { SalesOrders_FlowService } from './SalesOrders.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { PurchaseOrders_FlowService } from './PurchaseOrders.flow.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';

import { app_custom_toBeDeletedMethodService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Addresses = this.injector.get(Addresses_FlowService);
    this.Carriers = this.injector.get(Carriers_FlowService);
    this.Instructions = this.injector.get(Instructions_FlowService);
    this.Materials = this.injector.get(Materials_FlowService);
    this.DockAppointments = this.injector.get(DockAppointments_FlowService);
    this.Owners = this.injector.get(Owners_FlowService);
    this.Discussions = this.injector.get(Discussions_FlowService);
    this.Document360 = this.injector.get(Document360_FlowService);
    this.Usersnap = this.injector.get(Usersnap_FlowService);
    this.Locations = this.injector.get(Locations_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.SalesOrders = this.injector.get(SalesOrders_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_FlowService);
    this.FootPrintManager = this.injector.get(FootPrintManager_FlowService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_FlowService);
  }

    public Utilities: Utilities_FlowService;
    public Addresses: Addresses_FlowService;
    public Carriers: Carriers_FlowService;
    public Instructions: Instructions_FlowService;
    public Materials: Materials_FlowService;
    public DockAppointments: DockAppointments_FlowService;
    public Owners: Owners_FlowService;
    public Discussions: Discussions_FlowService;
    public Document360: Document360_FlowService;
    public Usersnap: Usersnap_FlowService;
    public Locations: Locations_FlowService;
    public Notifications: Notifications_FlowService;
    public SalesOrders: SalesOrders_FlowService;
    public Invoices: Invoices_FlowService;
    public PurchaseOrders: PurchaseOrders_FlowService;
    public FootPrintManager: FootPrintManager_FlowService;
    public FootPrintApiManager: FootPrintApiManager_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _custom_toBeDeletedMethod: app_custom_toBeDeletedMethodService;
  public async custom_toBeDeletedMethod(inParams: {  }): Promise<void> {
    if(!this._custom_toBeDeletedMethod) {
      this._custom_toBeDeletedMethod = this.injector.get(app_custom_toBeDeletedMethodService);
    }
    return this._custom_toBeDeletedMethod.run(inParams);
  }
   
   

   
}
