<div [ngxToggleFullscreen]="fullscreen"
     class="img-container"
     (wheel)="scrollZoom($event)"
     (dragover)="onDragOver($event)">
  <img [src]="src[index]"
       [ngStyle]="style"
       alt="Image not found..."
       (dragstart)="onDragStart($event)"
       (load)="onLoad()"
       (loadstart)="onLoadStart()" />
  <!-- Div below will be used to hide the 'ghost' image when dragging -->
  <div></div>
  <div class="spinner-container"
       *ngIf="loading">
    <div class="spinner"></div>
  </div>
  <button type="button"
          [class]="config.btnClass"
          *ngIf="config.btnShow.rotateCounterClockwise"
          (click)="rotateCounterClockwise()">
    <i [class]="config.btnIcons.rotateCounterClockwise"></i>
  </button>
  <button type="button"
          [class]="config.btnClass"
          *ngIf="config.btnShow.rotateClockwise"
          (click)="rotateClockwise()">
    <i [class]="config.btnIcons.rotateClockwise"></i>
  </button>

  <button type="button"
          [class]="config.btnClass"
          *ngIf="config.btnShow.zoomOut"
          (click)="zoomOut()">
    <i [class]="config.btnIcons.zoomOut"></i>
  </button>
  <button type="button"
          [class]="config.btnClass"
          *ngIf="config.btnShow.zoomIn"
          (click)="zoomIn()">
    <i [class]="config.btnIcons.zoomIn"></i>
  </button>

  <button type="button"
          [class]="config.btnClass"
          *ngFor="let cBtn of config.customBtns"
          (click)="fireCustomEvent(cBtn.name, index)">
    <i [class]="cBtn.icon"></i>
  </button>

  <button type="button"
          id="ngx-fs-btn"
          [class]="config.btnClass"
          (click)="toggleFullscreen()"
          *ngIf="config.allowFullscreen">
          <i *ngIf="!fullscreen" [class]="config.btnIcons.fullscreen"></i>
          <i *ngIf="fullscreen" [class]="config.btnIcons.modalScreen"></i>
  </button>

  <div class="nav-button-container"
       *ngIf="src.length > 1">
    <button type="button"
            [class]="config.btnClass"
            (click)="prevImage($event)"
            [disabled]="index === 0">
      <i [class]="config.btnIcons.prev"></i>
    </button>
    <button type="button"
            [class]="config.btnClass"
            (click)="nextImage($event)"
            [disabled]="index === src.length - 1">
      <i [class]="config.btnIcons.next"></i>
    </button>
  </div>
</div>