<app-selector
  #selector
  [multiple]="true"
  [type]="type"
  [(ngModel)]="value"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [styles]="styles"
  [displayWithFn]="displayWithFn.bind(this)"
  [optionsFn]="optionsFn.bind(this)"
  newItemLabel="Create license plate"
  (newItemClick)="on_action_clicked()"

>
</app-selector>