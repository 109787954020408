<div class="pie-container">
  <div data-cy="widget-title" class="pie-title">Total in/out transactions</div>
  <div *ngIf="initialized " 
    class="pie-content">
    <apx-chart #pieChart
               [chart]="chart"
               [series]="series"
               [labels]="labels"
               [dataLabels]="dataLabels"
               [theme]="theme"
               [legend]="legend"
               [tooltip]="tooltip"
               [stroke]="stroke">
    </apx-chart>
  </div>
</div>