import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { Surveys_date_filter_dd_singleComponent } from './Surveys.date_filter_dd_single.component';
import { Surveys_operation_context_types_dd_singleComponent } from './Surveys.operation_context_types_dd_single.component';
import { Surveys_survey_contexts_dd_singleComponent } from './Surveys.survey_contexts_dd_single.component';
import { Surveys_date_filter_dd_multiComponent } from './Surveys.date_filter_dd_multi.component';
import { Surveys_operation_context_types_dd_multiComponent } from './Surveys.operation_context_types_dd_multi.component';
import { Surveys_survey_contexts_dd_multiComponent } from './Surveys.survey_contexts_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Surveys_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Surveys: Surveys_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Surveys_date_filter_dd_single') {
      const title = 'date_filter_dd';
      const component = Surveys_date_filter_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Surveys_operation_context_types_dd_single') {
      const title = 'operation_context_types_dd';
      const component = Surveys_operation_context_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Surveys_survey_contexts_dd_single') {
      const title = 'survey_contexts_dd';
      const component = Surveys_survey_contexts_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Surveys_date_filter_dd_multi') {
      const title = 'date_filter_dd';
      const component = Surveys_date_filter_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Surveys_operation_context_types_dd_multi') {
      const title = 'operation_context_types_dd';
      const component = Surveys_operation_context_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Surveys_survey_contexts_dd_multi') {
      const title = 'survey_contexts_dd';
      const component = Surveys_survey_contexts_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
