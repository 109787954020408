<ng-container *ngIf="currentStep">
  <div class="stepper">
    <div
      *ngFor="let p of progress; index as i"
      class="step"
      [ngClass]="{
        completed: i < currentStepProgressIndex,
        current: i === currentStepProgressIndex
      }"
    >
      <div class="step-title">
        <span>{{ p.title }}</span>
        <span
          *ngIf="currentStep.isRepeatable && i === currentStepProgressIndex"
          >({{ currentStep.repeatIndex + 1 }} of
          {{ currentStep.repeatCount }})</span
        >
      </div>
      <div class="step-counter">
        <i
          *ngIf="i < currentStepProgressIndex"
          class="icon icon-ic_fluent_checkmark_20_regular"
        ></i>
        <span *ngIf="i >= currentStepProgressIndex">{{ i + 1 }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container #host></ng-container>

<ng-template #buttons>
  <button
    mat-button
    class="datex-button secondary"
    *ngIf="steps && currentStepIndex > 0"
    (click)="back()"
  >
    Back
  </button>
  <button
    mat-button
    class="datex-button primary"
    [disabled]="isNextButtonDisabled()"
    (click)="next()"
  >
    {{ getNextButtonLabel() }}
  </button>
  <button mat-button class="datex-button secondary" (click)="cancel()">
    Cancel
  </button>
</ng-template>
