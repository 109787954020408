import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PalletTransactions_ds_pallet_inventory_totalsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { pallet_class_ids?: number[], project_ids?: number[], account_ids?: number[], carrier_ids?: number[], shipment_ids?: number[], full_text_search?: string }): 
  Promise<{ result: { Amount?: number, Id?: number, Count?: number, PalletClassId?: number, PalletClass?: { Name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/PalletTransactions/datasources/ds_pallet_inventory_totals/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { pallet_class_ids?: number[], project_ids?: number[], account_ids?: number[], carrier_ids?: number[], shipment_ids?: number[], full_text_search?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Amount?: number, Id?: number, Count?: number, PalletClassId?: number, PalletClass?: { Name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/PalletTransactions/datasources/ds_pallet_inventory_totals/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { pallet_class_ids?: number[], project_ids?: number[], account_ids?: number[], carrier_ids?: number[], shipment_ids?: number[], full_text_search?: string, $keys: { PalletClassId?: number, PalletClass?: { Name?: string } }[] }): 
  Promise<{ result: { Amount?: number, Id?: number, Count?: number, PalletClassId?: number, PalletClass?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PalletTransactions/datasources/ds_pallet_inventory_totals/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
