import { Directive, Input, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { isNil } from 'lodash-es';
import * as numeral from 'numeral';
import { ENTER } from '@angular/cdk/keycodes';
import { UtilsService } from '../utils.service';

@Directive({
  selector: 'input[numberBox]',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: NumberBoxDirective, multi: true },
  ],
  host: {
    '[disabled]': 'disabled',
    '[format]': 'format',
    '(input)': '_onInput($event.target.value)',
    '(blur)': '_onBlur()',
    '(keydown)': '_onKeydown($event)',
  }
})
export class NumberBoxDirective implements ControlValueAccessor {

  @Input() format: string;

  _innerValue: number;
  formattedValue = null;
  disabled: boolean;

  constructor(
    private utils: UtilsService,
    protected _elementRef: ElementRef<HTMLInputElement>
  ) {
  }

  _onInput(value) {
    const newValue = this.parseValue(value);
    const hasChanged = newValue !== this._innerValue;

    if (hasChanged) {
      this.onChange(newValue);
      this._innerValue = newValue;
    }
  }

  _onBlur() {
    this._formatValue(this._innerValue);
    this.onTouch();
  }

  _onKeydown(event: KeyboardEvent) {
    const isEnter = event.altKey === false &&
      event.ctrlKey === false &&
      event.ctrlKey === false && event.keyCode === ENTER;

    if (isEnter && !this._elementRef.nativeElement.disabled) {
      this._formatValue(this._innerValue);
    }
  }

  onChange: any = () => { };
  onTouch: any = () => { };

  protected _formatValue(value: number | null) {
    this.formattedValue = this.formatValue(value);
    this._elementRef.nativeElement.value = this.formattedValue;
  }

  formatValue(value: number): string {
    let formattedValue;
    if (!isNil(value)) {
      formattedValue = this.format ?
        this.utils.numberFormat(value, this.format) :
        value.toString();
    } else {
      formattedValue = value;
    }
    return formattedValue;
  }

  parseValue(value: string): number {
    let parsedValue = null;
    if (this.utils.isDefinedTrimmed(value)) {
      parsedValue = this.format ?
        // the format is also a parse format
        numeral(numeral(value).format(this.format)).value() :
        numeral(value).value();
    }

    return parsedValue;
  }

  get value() {
    return this._innerValue;
  }

  set value(val) {
    this._innerValue = val;
    this.onChange(val);
    this.onTouch(val);
  }

  writeValue(value: number): void {
    if (this._innerValue !== value) {
      this._innerValue = value;
    }
    this._formatValue(this._innerValue);
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}