import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Equipment_ds_equipment_editorService } from './Equipment.datasource.index';
import { Equipment_ds_equipment_get_by_lookupcodeService } from './Equipment.datasource.index';
import { Equipment_ds_equipment_gridService } from './Equipment.datasource.index';
import { Equipment_ds_equipment_type_ddService } from './Equipment.datasource.index';
import { Equipment_ds_equipment_type_get_by_idService } from './Equipment.datasource.index';
import { Equipment_ds_equipment_warehouse_get_by_idsService } from './Equipment.datasource.index';
import { Equipment_ds_equipment_warehouses_gridService } from './Equipment.datasource.index';
import { Equipment_ds_location_get_by_nameService } from './Equipment.datasource.index';
import { Equipment_ds_warehouse_ddService } from './Equipment.datasource.index';
import { Equipment_ds_warehouse_location_container_get_by_idService } from './Equipment.datasource.index';

@Injectable({ providedIn: 'root' })
export class Equipment_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Equipment: Equipment_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_equipment_editor: Equipment_ds_equipment_editorService;
  public get ds_equipment_editor(): Equipment_ds_equipment_editorService {
    if(!this._ds_equipment_editor) {
      this._ds_equipment_editor = this.injector.get(Equipment_ds_equipment_editorService);
    }
    return this._ds_equipment_editor;
  }
  private _ds_equipment_get_by_lookupcode: Equipment_ds_equipment_get_by_lookupcodeService;
  public get ds_equipment_get_by_lookupcode(): Equipment_ds_equipment_get_by_lookupcodeService {
    if(!this._ds_equipment_get_by_lookupcode) {
      this._ds_equipment_get_by_lookupcode = this.injector.get(Equipment_ds_equipment_get_by_lookupcodeService);
    }
    return this._ds_equipment_get_by_lookupcode;
  }
  private _ds_equipment_grid: Equipment_ds_equipment_gridService;
  public get ds_equipment_grid(): Equipment_ds_equipment_gridService {
    if(!this._ds_equipment_grid) {
      this._ds_equipment_grid = this.injector.get(Equipment_ds_equipment_gridService);
    }
    return this._ds_equipment_grid;
  }
  private _ds_equipment_type_dd: Equipment_ds_equipment_type_ddService;
  public get ds_equipment_type_dd(): Equipment_ds_equipment_type_ddService {
    if(!this._ds_equipment_type_dd) {
      this._ds_equipment_type_dd = this.injector.get(Equipment_ds_equipment_type_ddService);
    }
    return this._ds_equipment_type_dd;
  }
  private _ds_equipment_type_get_by_id: Equipment_ds_equipment_type_get_by_idService;
  public get ds_equipment_type_get_by_id(): Equipment_ds_equipment_type_get_by_idService {
    if(!this._ds_equipment_type_get_by_id) {
      this._ds_equipment_type_get_by_id = this.injector.get(Equipment_ds_equipment_type_get_by_idService);
    }
    return this._ds_equipment_type_get_by_id;
  }
  private _ds_equipment_warehouse_get_by_ids: Equipment_ds_equipment_warehouse_get_by_idsService;
  public get ds_equipment_warehouse_get_by_ids(): Equipment_ds_equipment_warehouse_get_by_idsService {
    if(!this._ds_equipment_warehouse_get_by_ids) {
      this._ds_equipment_warehouse_get_by_ids = this.injector.get(Equipment_ds_equipment_warehouse_get_by_idsService);
    }
    return this._ds_equipment_warehouse_get_by_ids;
  }
  private _ds_equipment_warehouses_grid: Equipment_ds_equipment_warehouses_gridService;
  public get ds_equipment_warehouses_grid(): Equipment_ds_equipment_warehouses_gridService {
    if(!this._ds_equipment_warehouses_grid) {
      this._ds_equipment_warehouses_grid = this.injector.get(Equipment_ds_equipment_warehouses_gridService);
    }
    return this._ds_equipment_warehouses_grid;
  }
  private _ds_location_get_by_name: Equipment_ds_location_get_by_nameService;
  public get ds_location_get_by_name(): Equipment_ds_location_get_by_nameService {
    if(!this._ds_location_get_by_name) {
      this._ds_location_get_by_name = this.injector.get(Equipment_ds_location_get_by_nameService);
    }
    return this._ds_location_get_by_name;
  }
  private _ds_warehouse_dd: Equipment_ds_warehouse_ddService;
  public get ds_warehouse_dd(): Equipment_ds_warehouse_ddService {
    if(!this._ds_warehouse_dd) {
      this._ds_warehouse_dd = this.injector.get(Equipment_ds_warehouse_ddService);
    }
    return this._ds_warehouse_dd;
  }
  private _ds_warehouse_location_container_get_by_id: Equipment_ds_warehouse_location_container_get_by_idService;
  public get ds_warehouse_location_container_get_by_id(): Equipment_ds_warehouse_location_container_get_by_idService {
    if(!this._ds_warehouse_location_container_get_by_id) {
      this._ds_warehouse_location_container_get_by_id = this.injector.get(Equipment_ds_warehouse_location_container_get_by_idService);
    }
    return this._ds_warehouse_location_container_get_by_id;
  }
}

