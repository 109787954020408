import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Carriers_ds_carrier_editorService } from './Carriers.datasource.index';
import { Carriers_ds_carriers_ddService } from './Carriers.datasource.index';
import { Carriers_ds_carriers_excl_ownerscarriers_ddService } from './Carriers.datasource.index';
import { Carriers_ds_carriers_gridService } from './Carriers.datasource.index';
import { Carriers_ds_carriers_ownerscarriers_ddService } from './Carriers.datasource.index';
import { Carriers_ds_carrierservices_gridService } from './Carriers.datasource.index';
import { Carriers_ds_carrierservicetype_ddService } from './Carriers.datasource.index';
import { Carriers_ds_carrierservicetype_editorService } from './Carriers.datasource.index';
import { Carriers_ds_carrierservicetypes_excl_carrier_ddService } from './Carriers.datasource.index';
import { Carriers_ds_carrierservicetypes_gridService } from './Carriers.datasource.index';
import { Carriers_ds_get_carrier_by_carrierIdService } from './Carriers.datasource.index';
import { Carriers_ds_get_carrier_by_nameService } from './Carriers.datasource.index';
import { Carriers_ds_get_carrier_by_owner_top1Service } from './Carriers.datasource.index';
import { Carriers_ds_get_carrierservices_by_carrierId_top1Service } from './Carriers.datasource.index';
import { Carriers_ds_get_carrierservices_by_carrierservicetypeId_top1Service } from './Carriers.datasource.index';
import { Carriers_ds_get_carrierservicetype_by_nameService } from './Carriers.datasource.index';
import { Carriers_ds_get_orders_by_carrierId_serviceTypeId_top1Service } from './Carriers.datasource.index';
import { Carriers_ds_get_orders_by_carrierId_top1Service } from './Carriers.datasource.index';
import { Carriers_ds_get_orders_by_carrierservicetypeId_top1Service } from './Carriers.datasource.index';
import { Carriers_ds_get_shipments_by_carrierId_top1Service } from './Carriers.datasource.index';
import { Carriers_ds_ownerscarrierslookup_gridService } from './Carriers.datasource.index';

@Injectable({ providedIn: 'root' })
export class Carriers_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Carriers: Carriers_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_carrier_editor: Carriers_ds_carrier_editorService;
  public get ds_carrier_editor(): Carriers_ds_carrier_editorService {
    if(!this._ds_carrier_editor) {
      this._ds_carrier_editor = this.injector.get(Carriers_ds_carrier_editorService);
    }
    return this._ds_carrier_editor;
  }
  private _ds_carriers_dd: Carriers_ds_carriers_ddService;
  public get ds_carriers_dd(): Carriers_ds_carriers_ddService {
    if(!this._ds_carriers_dd) {
      this._ds_carriers_dd = this.injector.get(Carriers_ds_carriers_ddService);
    }
    return this._ds_carriers_dd;
  }
  private _ds_carriers_excl_ownerscarriers_dd: Carriers_ds_carriers_excl_ownerscarriers_ddService;
  public get ds_carriers_excl_ownerscarriers_dd(): Carriers_ds_carriers_excl_ownerscarriers_ddService {
    if(!this._ds_carriers_excl_ownerscarriers_dd) {
      this._ds_carriers_excl_ownerscarriers_dd = this.injector.get(Carriers_ds_carriers_excl_ownerscarriers_ddService);
    }
    return this._ds_carriers_excl_ownerscarriers_dd;
  }
  private _ds_carriers_grid: Carriers_ds_carriers_gridService;
  public get ds_carriers_grid(): Carriers_ds_carriers_gridService {
    if(!this._ds_carriers_grid) {
      this._ds_carriers_grid = this.injector.get(Carriers_ds_carriers_gridService);
    }
    return this._ds_carriers_grid;
  }
  private _ds_carriers_ownerscarriers_dd: Carriers_ds_carriers_ownerscarriers_ddService;
  public get ds_carriers_ownerscarriers_dd(): Carriers_ds_carriers_ownerscarriers_ddService {
    if(!this._ds_carriers_ownerscarriers_dd) {
      this._ds_carriers_ownerscarriers_dd = this.injector.get(Carriers_ds_carriers_ownerscarriers_ddService);
    }
    return this._ds_carriers_ownerscarriers_dd;
  }
  private _ds_carrierservices_grid: Carriers_ds_carrierservices_gridService;
  public get ds_carrierservices_grid(): Carriers_ds_carrierservices_gridService {
    if(!this._ds_carrierservices_grid) {
      this._ds_carrierservices_grid = this.injector.get(Carriers_ds_carrierservices_gridService);
    }
    return this._ds_carrierservices_grid;
  }
  private _ds_carrierservicetype_dd: Carriers_ds_carrierservicetype_ddService;
  public get ds_carrierservicetype_dd(): Carriers_ds_carrierservicetype_ddService {
    if(!this._ds_carrierservicetype_dd) {
      this._ds_carrierservicetype_dd = this.injector.get(Carriers_ds_carrierservicetype_ddService);
    }
    return this._ds_carrierservicetype_dd;
  }
  private _ds_carrierservicetype_editor: Carriers_ds_carrierservicetype_editorService;
  public get ds_carrierservicetype_editor(): Carriers_ds_carrierservicetype_editorService {
    if(!this._ds_carrierservicetype_editor) {
      this._ds_carrierservicetype_editor = this.injector.get(Carriers_ds_carrierservicetype_editorService);
    }
    return this._ds_carrierservicetype_editor;
  }
  private _ds_carrierservicetypes_excl_carrier_dd: Carriers_ds_carrierservicetypes_excl_carrier_ddService;
  public get ds_carrierservicetypes_excl_carrier_dd(): Carriers_ds_carrierservicetypes_excl_carrier_ddService {
    if(!this._ds_carrierservicetypes_excl_carrier_dd) {
      this._ds_carrierservicetypes_excl_carrier_dd = this.injector.get(Carriers_ds_carrierservicetypes_excl_carrier_ddService);
    }
    return this._ds_carrierservicetypes_excl_carrier_dd;
  }
  private _ds_carrierservicetypes_grid: Carriers_ds_carrierservicetypes_gridService;
  public get ds_carrierservicetypes_grid(): Carriers_ds_carrierservicetypes_gridService {
    if(!this._ds_carrierservicetypes_grid) {
      this._ds_carrierservicetypes_grid = this.injector.get(Carriers_ds_carrierservicetypes_gridService);
    }
    return this._ds_carrierservicetypes_grid;
  }
  private _ds_get_carrier_by_carrierId: Carriers_ds_get_carrier_by_carrierIdService;
  public get ds_get_carrier_by_carrierId(): Carriers_ds_get_carrier_by_carrierIdService {
    if(!this._ds_get_carrier_by_carrierId) {
      this._ds_get_carrier_by_carrierId = this.injector.get(Carriers_ds_get_carrier_by_carrierIdService);
    }
    return this._ds_get_carrier_by_carrierId;
  }
  private _ds_get_carrier_by_name: Carriers_ds_get_carrier_by_nameService;
  public get ds_get_carrier_by_name(): Carriers_ds_get_carrier_by_nameService {
    if(!this._ds_get_carrier_by_name) {
      this._ds_get_carrier_by_name = this.injector.get(Carriers_ds_get_carrier_by_nameService);
    }
    return this._ds_get_carrier_by_name;
  }
  private _ds_get_carrier_by_owner_top1: Carriers_ds_get_carrier_by_owner_top1Service;
  public get ds_get_carrier_by_owner_top1(): Carriers_ds_get_carrier_by_owner_top1Service {
    if(!this._ds_get_carrier_by_owner_top1) {
      this._ds_get_carrier_by_owner_top1 = this.injector.get(Carriers_ds_get_carrier_by_owner_top1Service);
    }
    return this._ds_get_carrier_by_owner_top1;
  }
  private _ds_get_carrierservices_by_carrierId_top1: Carriers_ds_get_carrierservices_by_carrierId_top1Service;
  public get ds_get_carrierservices_by_carrierId_top1(): Carriers_ds_get_carrierservices_by_carrierId_top1Service {
    if(!this._ds_get_carrierservices_by_carrierId_top1) {
      this._ds_get_carrierservices_by_carrierId_top1 = this.injector.get(Carriers_ds_get_carrierservices_by_carrierId_top1Service);
    }
    return this._ds_get_carrierservices_by_carrierId_top1;
  }
  private _ds_get_carrierservices_by_carrierservicetypeId_top1: Carriers_ds_get_carrierservices_by_carrierservicetypeId_top1Service;
  public get ds_get_carrierservices_by_carrierservicetypeId_top1(): Carriers_ds_get_carrierservices_by_carrierservicetypeId_top1Service {
    if(!this._ds_get_carrierservices_by_carrierservicetypeId_top1) {
      this._ds_get_carrierservices_by_carrierservicetypeId_top1 = this.injector.get(Carriers_ds_get_carrierservices_by_carrierservicetypeId_top1Service);
    }
    return this._ds_get_carrierservices_by_carrierservicetypeId_top1;
  }
  private _ds_get_carrierservicetype_by_name: Carriers_ds_get_carrierservicetype_by_nameService;
  public get ds_get_carrierservicetype_by_name(): Carriers_ds_get_carrierservicetype_by_nameService {
    if(!this._ds_get_carrierservicetype_by_name) {
      this._ds_get_carrierservicetype_by_name = this.injector.get(Carriers_ds_get_carrierservicetype_by_nameService);
    }
    return this._ds_get_carrierservicetype_by_name;
  }
  private _ds_get_orders_by_carrierId_serviceTypeId_top1: Carriers_ds_get_orders_by_carrierId_serviceTypeId_top1Service;
  public get ds_get_orders_by_carrierId_serviceTypeId_top1(): Carriers_ds_get_orders_by_carrierId_serviceTypeId_top1Service {
    if(!this._ds_get_orders_by_carrierId_serviceTypeId_top1) {
      this._ds_get_orders_by_carrierId_serviceTypeId_top1 = this.injector.get(Carriers_ds_get_orders_by_carrierId_serviceTypeId_top1Service);
    }
    return this._ds_get_orders_by_carrierId_serviceTypeId_top1;
  }
  private _ds_get_orders_by_carrierId_top1: Carriers_ds_get_orders_by_carrierId_top1Service;
  public get ds_get_orders_by_carrierId_top1(): Carriers_ds_get_orders_by_carrierId_top1Service {
    if(!this._ds_get_orders_by_carrierId_top1) {
      this._ds_get_orders_by_carrierId_top1 = this.injector.get(Carriers_ds_get_orders_by_carrierId_top1Service);
    }
    return this._ds_get_orders_by_carrierId_top1;
  }
  private _ds_get_orders_by_carrierservicetypeId_top1: Carriers_ds_get_orders_by_carrierservicetypeId_top1Service;
  public get ds_get_orders_by_carrierservicetypeId_top1(): Carriers_ds_get_orders_by_carrierservicetypeId_top1Service {
    if(!this._ds_get_orders_by_carrierservicetypeId_top1) {
      this._ds_get_orders_by_carrierservicetypeId_top1 = this.injector.get(Carriers_ds_get_orders_by_carrierservicetypeId_top1Service);
    }
    return this._ds_get_orders_by_carrierservicetypeId_top1;
  }
  private _ds_get_shipments_by_carrierId_top1: Carriers_ds_get_shipments_by_carrierId_top1Service;
  public get ds_get_shipments_by_carrierId_top1(): Carriers_ds_get_shipments_by_carrierId_top1Service {
    if(!this._ds_get_shipments_by_carrierId_top1) {
      this._ds_get_shipments_by_carrierId_top1 = this.injector.get(Carriers_ds_get_shipments_by_carrierId_top1Service);
    }
    return this._ds_get_shipments_by_carrierId_top1;
  }
  private _ds_ownerscarrierslookup_grid: Carriers_ds_ownerscarrierslookup_gridService;
  public get ds_ownerscarrierslookup_grid(): Carriers_ds_ownerscarrierslookup_gridService {
    if(!this._ds_ownerscarrierslookup_grid) {
      this._ds_ownerscarrierslookup_grid = this.injector.get(Carriers_ds_ownerscarrierslookup_gridService);
    }
    return this._ds_ownerscarrierslookup_grid;
  }
}

