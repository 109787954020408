import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_materials_by_base_packaging_gridComponent } from './FootPrintManager.materials_by_base_packaging_grid.component';
import { FootPrintManager_inventory_location_gridComponent } from './FootPrintManager.inventory_location_grid.component';
import { FootPrintManager_licenseplate_listComponent } from './FootPrintManager.licenseplate_list.component';
import { FootPrintManager_serialnumbers_gridComponent } from './FootPrintManager.serialnumbers_grid.component';
import { FootPrintManager_contact_addresses_gridComponent } from './FootPrintManager.contact_addresses_grid.component';
import { FootPrintManager_inventory_tasks_gridComponent } from './FootPrintManager.inventory_tasks_grid.component';
import { FootPrintManager_instructions_gridComponent } from './FootPrintManager.instructions_grid.component';
import { FootPrintManager_billing_contracts_gridComponent } from './FootPrintManager.billing_contracts_grid.component';
import { FootPrintManager_invoices_gridComponent } from './FootPrintManager.invoices_grid.component';
import { Materials_materials_by_count_widgetComponent } from './Materials.materials_by_count_widget.component';
import { Inventory_total_packaged_amount_widgetComponent } from './Inventory.total_packaged_amount_widget.component';
import { Inventory_total_gross_weight_widgetComponent } from './Inventory.total_gross_weight_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_materials_by_base_packaging_gridComponent),
    forwardRef(() => FootPrintManager_inventory_location_gridComponent),
    forwardRef(() => FootPrintManager_licenseplate_listComponent),
    forwardRef(() => FootPrintManager_serialnumbers_gridComponent),
    forwardRef(() => FootPrintManager_contact_addresses_gridComponent),
    forwardRef(() => FootPrintManager_inventory_tasks_gridComponent),
    forwardRef(() => FootPrintManager_instructions_gridComponent),
    forwardRef(() => FootPrintManager_billing_contracts_gridComponent),
    forwardRef(() => FootPrintManager_invoices_gridComponent),
    forwardRef(() => Materials_materials_by_count_widgetComponent),
    forwardRef(() => Inventory_total_packaged_amount_widgetComponent),
    forwardRef(() => Inventory_total_gross_weight_widgetComponent),
  ],
  selector: 'FootPrintManager-single_project_hub',
  templateUrl: './FootPrintManager.single_project_hub.component.html'
})
export class FootPrintManager_single_project_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { projectId: number, ownerId?: number } = { projectId: null, ownerId: null };
  //#region Inputs
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, 'Edit', 'icon-ic_fluent_edit_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      create_material: new ToolModel(new ButtonModel('create_material', new ButtonStyles(null, null), false, 'Create material', 'icon-ic_fluent_add_20_regular')
    ),
      create_inventory: new ToolModel(new ButtonModel('create_inventory', new ButtonStyles(null, null), false, 'Create inventory', 'icon-ic_fluent_add_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new SplitButtonModel<{ print_inventory_by_project_report: ButtonModel }>(
        'print',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        [
          new ButtonModel('print_inventory_by_project_report', new ButtonStyles(null, null), false, 'Inventory report', '')
        ])
    ),
      options: new ToolModel(new SplitButtonModel<{ auto_email_rules: ButtonModel, surveys: ButtonModel, auto_invoicing_rules: ButtonModel }>(
        'options',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_more_horizontal_20_regular',
        [
          new ButtonModel('auto_email_rules', new ButtonStyles(null, null), false, 'Auto-email rules', 'icon-ic_fluent_mail_alert_20_regular'),
          new ButtonModel('surveys', new ButtonStyles(null, null), false, 'Surveys', 'icon-ic_fluent_clipboard_bullet_list_ltr_20_regular'),
          new ButtonModel('auto_invoicing_rules', new ButtonStyles(null, null), false, 'Auto-invoicing rules', 'icon-ic_fluent_receipt_money_20_regular')
        ])
    )
  };

  actionbar = {
      ship_from_addresses: new ToolModel(new ButtonModel('ship_from_addresses', new ButtonStyles(null, null), false, 'Ship from addresses', 'icon-ic_fluent_video_person_20_regular')
    ),
      ship_to_addresses: new ToolModel(new ButtonModel('ship_to_addresses', new ButtonStyles(null, null), false, 'Ship to addresses', 'icon-ic_fluent_share_screen_person_overlay_inside_20_regular')
    ),
      assign_containers_button: new ToolModel(new ButtonModel('assign_containers_button', new ButtonStyles(null, null), false, 'Assign containers', 'icon-ic_fluent_text_bullet_list_20_regular')
    ),
      custom_fields: new ToolModel(new ButtonModel('custom_fields', new ButtonStyles(null, null), false, 'Custom fields', 'icon-ic_fluent_edit_20_regular')
    )
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      inventory: new TabGroupModel(),
      contacts: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      materials: new TabItemModel(
        this.rootTabGroup, 
        'Materials', 
        ),
      inventory: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        true,
        {
          inventory_by_location: new TabItemModel(
          this.subTabGroups.inventory, 
          'By location', 
          ),
          licenseplates: new TabItemModel(
          this.subTabGroups.inventory, 
          'License plates', 
          ),
          serialnumbers: new TabItemModel(
          this.subTabGroups.inventory, 
          'Serial numbers', 
          ),
        }
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        true,
        {
          addresses: new TabItemModel(
          this.subTabGroups.contacts, 
          'Addresses', 
          ),
          importer: new TabItemModel(
          this.subTabGroups.contacts, 
          'Importer of Record', 
          ),
        }
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
      instructions: new TabItemModel(
        this.rootTabGroup, 
        'Instructions', 
        ),
      billing: new TabItemModel(
        this.rootTabGroup, 
        'Billing', 
        ),
      invoices: new TabItemModel(
        this.rootTabGroup, 
        'Invoices', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_materials_materials_by_base_packaging_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    get $tabs_inventory_inventory_by_location_inventory_location_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    get $tabs_inventory_licenseplates_licenseplate_list_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    get $tabs_inventory_serialnumbers_serialnumbers_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    get $tabs_inventory_serialnumbers_serialnumbers_grid_inParams_archived(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    get $tabs_contacts_addresses_contact_addresses_grid_inParams_entity(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Project';
      
      return expr;
    }
  
    get $tabs_contacts_addresses_contact_addresses_grid_inParams_entityId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    get $tabs_contacts_importer_contact_addresses_grid_inParams_entity(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Project';
      
      return expr;
    }
  
    get $tabs_contacts_importer_contact_addresses_grid_inParams_entityId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_contacts_importer_contact_addresses_grid_inParams_typeIds: number[];
    get $tabs_contacts_importer_contact_addresses_grid_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [5];
      
      if(!isEqual(this.cacheValueFor_$tabs_contacts_importer_contact_addresses_grid_inParams_typeIds, expr)) {
        this.cacheValueFor_$tabs_contacts_importer_contact_addresses_grid_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$tabs_contacts_importer_contact_addresses_grid_inParams_typeIds;
    }
  
    get $tabs_tasks_inventory_tasks_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityName(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Project';
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_enabled(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityKey1(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    get $tabs_billing_billing_contracts_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    get $tabs_billing_billing_contracts_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_contracts_grid_inParams_statusIds: number[];
    get $tabs_billing_billing_contracts_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [1,2];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_contracts_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_contracts_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_contracts_grid_inParams_statusIds;
    }
  
    get $tabs_invoices_invoices_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    get $tabs_invoices_invoices_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_materials', { read: FootPrintManager_materials_by_base_packaging_gridComponent }) $tabs_materials: FootPrintManager_materials_by_base_packaging_gridComponent;
        @ViewChild('$tabs_inventory_inventory_by_location', { read: FootPrintManager_inventory_location_gridComponent }) $tabs_inventory_inventory_by_location: FootPrintManager_inventory_location_gridComponent;
        @ViewChild('$tabs_inventory_licenseplates', { read: FootPrintManager_licenseplate_listComponent }) $tabs_inventory_licenseplates: FootPrintManager_licenseplate_listComponent;
        @ViewChild('$tabs_inventory_serialnumbers', { read: FootPrintManager_serialnumbers_gridComponent }) $tabs_inventory_serialnumbers: FootPrintManager_serialnumbers_gridComponent;
        @ViewChild('$tabs_contacts_addresses', { read: FootPrintManager_contact_addresses_gridComponent }) $tabs_contacts_addresses: FootPrintManager_contact_addresses_gridComponent;
        @ViewChild('$tabs_contacts_importer', { read: FootPrintManager_contact_addresses_gridComponent }) $tabs_contacts_importer: FootPrintManager_contact_addresses_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_inventory_tasks_gridComponent }) $tabs_tasks: FootPrintManager_inventory_tasks_gridComponent;
      @ViewChild('$tabs_instructions', { read: FootPrintManager_instructions_gridComponent }) $tabs_instructions: FootPrintManager_instructions_gridComponent;
      @ViewChild('$tabs_billing', { read: FootPrintManager_billing_contracts_gridComponent }) $tabs_billing: FootPrintManager_billing_contracts_gridComponent;
      @ViewChild('$tabs_invoices', { read: FootPrintManager_invoices_gridComponent }) $tabs_invoices: FootPrintManager_invoices_gridComponent;
    //#endregion tabs children
    widgets = {
      materials_by_count_widget: new WidgetModel(),
      total_packaged_amount_widget: new WidgetModel(),
      total_gross_weight_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_materials_by_count_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    get $widgets_total_packaged_amount_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    get $widgets_total_gross_weight_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.projectId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_materials_by_count_widget', { read:  Materials_materials_by_count_widgetComponent }) $widgets_materials_by_count_widget: Materials_materials_by_count_widgetComponent;
      @ViewChild('$widgets_total_packaged_amount_widget', { read:  Inventory_total_packaged_amount_widgetComponent }) $widgets_total_packaged_amount_widget: Inventory_total_packaged_amount_widgetComponent;
      @ViewChild('$widgets_total_gross_weight_widget', { read:  Inventory_total_gross_weight_widgetComponent }) $widgets_total_gross_weight_widget: Inventory_total_gross_weight_widgetComponent;
    //#endregion widgets children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.materials,
      this.tabs.inventory,
      this.tabs.contacts,
      this.tabs.tasks,
      this.tabs.instructions,
      this.tabs.billing,
      this.tabs.invoices,
    ]; 
    this.subTabGroups.inventory.tabs = [
        this.tabs.inventory.tabs.inventory_by_location,
        this.tabs.inventory.tabs.licenseplates,
        this.tabs.inventory.tabs.serialnumbers,
    ];    
    this.subTabGroups.contacts.tabs = [
        this.tabs.contacts.tabs.addresses,
        this.tabs.contacts.tabs.importer,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single Project Hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_materials_by_count_widget') {
      if (!isNil(this.$widgets_materials_by_count_widget) && !this.widgets.materials_by_count_widget.hidden) {
        this.$widgets_materials_by_count_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_packaged_amount_widget') {
      if (!isNil(this.$widgets_total_packaged_amount_widget) && !this.widgets.total_packaged_amount_widget.hidden) {
        this.$widgets_total_packaged_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_gross_weight_widget') {
      if (!isNil(this.$widgets_total_gross_weight_widget) && !this.widgets.total_gross_weight_widget.hidden) {
        this.$widgets_total_gross_weight_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_materials') {
      if (!isNil(this.$tabs_materials) && !this.tabs.materials.hidden) {
        this.$tabs_materials.refresh(true, false, null);
      }
    }
      if (childToSkip !== '$tabs_inventory_inventory_by_location') {
        if (!isNil(this.$tabs_inventory_inventory_by_location) && !this.tabs.inventory.tabs.inventory_by_location.hidden) {
          this.$tabs_inventory_inventory_by_location.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_inventory_licenseplates') {
        if (!isNil(this.$tabs_inventory_licenseplates) && !this.tabs.inventory.tabs.licenseplates.hidden) {
          this.$tabs_inventory_licenseplates.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_inventory_serialnumbers') {
        if (!isNil(this.$tabs_inventory_serialnumbers) && !this.tabs.inventory.tabs.serialnumbers.hidden) {
          this.$tabs_inventory_serialnumbers.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_contacts_addresses') {
        if (!isNil(this.$tabs_contacts_addresses) && !this.tabs.contacts.tabs.addresses.hidden) {
          this.$tabs_contacts_addresses.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_contacts_importer') {
        if (!isNil(this.$tabs_contacts_importer) && !this.tabs.contacts.tabs.importer.hidden) {
          this.$tabs_contacts_importer.refresh(true, false, null);
        }
      }   
    if (childToSkip !== '$tabs_tasks') {
      if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
        this.$tabs_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_instructions') {
      if (!isNil(this.$tabs_instructions) && !this.tabs.instructions.hidden) {
        this.$tabs_instructions.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_billing') {
      if (!isNil(this.$tabs_billing) && !this.tabs.billing.hidden) {
        this.$tabs_billing.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_invoices') {
      if (!isNil(this.$tabs_invoices) && !this.tabs.invoices.hidden) {
        this.$tabs_invoices.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Hide Importer of Record Tab
  var shippingKey = $settings.FootPrintManager.EasyPostKey;
  
  if ($utils.isDefined(shippingKey)) {
      if (shippingKey == 'N/A') {
          $hub.tabs.contacts.tabs.importer.hidden = true;
      }
      else {
         $hub.tabs.contacts.tabs.importer.hidden = false;
      }
  } else {
      $hub.tabs.contacts.tabs.importer.hidden = true;
  }
  
  
  const project = (await $datasources.Owners.ds_get_project_by_projectId.get({ projectId: $hub.inParams.projectId })).result;
  if ($utils.isDefined(project)) {
  
      $hub.title = `Project ${project[0].LookupCode}`;
  
      const title = `Project ${project[0].LookupCode} - Status ${project[0].Status.Label}`;
  
      if ($utils.isDefined(project[0].Notes)) {
          const description = `${project[0].Notes}  `;
          $hub.vars.hubDescription = description;
      }
  
      $hub.vars.hubTitle = title;
  
  }
  
  }
  on_create_inventory_clicked(event = null) {
    return this.on_create_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openinventory_creation_formDialog({
      projectId: $hub.inParams.projectId
  });
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  
  
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.openproject_editorDialog({projectId: $hub.inParams.projectId},'flyout');
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
  
      await $hub.on_init();
      await $hub.refresh();
  }
  
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.toolbar.on_delete.control.readOnly = true;
  
  const errors = [];
  const flowParams = {
      projectId: $hub.inParams.projectId
  };
  const result = await $flows.Owners.is_project_deletable_flow(flowParams);
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      errors.push(`${reason}`);
  
      const title = 'Delete project errors';
      const errorMessage = `Project cannot be deleted.`;
      const errorList = errors;
      await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
      return;
  
  }
  
  
  let confirmClicked = false;
  const title = 'Are you sure you want to delete the project?';
  const message = '';
  confirmClicked = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
  
  if (confirmClicked) {
      var errorMsgList = [];
      var errorMsgListDetails = [];
      try {
          const flowParams = {
              projectId: $hub.inParams.projectId
          }
          const delete_project = (await $flows.Owners.delete_project_flow(flowParams));
          let reason = delete_project.reason;
          if ($utils.isDefined(reason)) {
  
  
              $hub.toolbar.on_delete.control.readOnly = false;
              errorMessage = reason;
              errorMsgList.push(errorMessage);
  
  
          }
  
      } catch (error) {
  
          var errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          var errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          var errorDescription = `${errorMessage}`;
          errorMsgList.push(errorDescription);
          errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
          $hub.toolbar.on_delete.control.readOnly = false;
      }
  
  }
  
  if (!$utils.isDefined(errorMessage)) {
      const title = 'Delete project request';
      const message = `Project has been successfully deleted.`;
      await $shell.FootPrintManager.openInfoDialog(title, message);
      await $hub.close();
  
  }
  else {
      const title = 'Delete project errors';
      const message = `Project cannot be deleted.`;
      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
      $hub.toolbar.on_delete.control.readOnly = false;
  
  }
  }
  on_create_material_clicked(event = null) {
    return this.on_create_material_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_material_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = (await $shell.FootPrintManager.openmaterial_creation_formDialog({
      projectId: $hub.inParams.projectId
      }));
  const userConfirm = dialogResult.confirm;
  if (userConfirm){
      await $hub.refresh();
  }
  }
  on_ship_from_addresses_clicked(event = null) {
    return this.on_ship_from_addresses_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_addresses_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const project = (await $datasources.Owners.ds_get_project_by_projectId.get({projectId: $hub.inParams.projectId})).result;
  if ($utils.isDefined(project)){
  
      const ownerId = project[0].OwnerId
  
  $shell.FootPrintManager.openownersaccounts_gridDialog({
      ownerId:  ownerId,
      typeIds: [1,3,4,5],
      accountTypeId: 1,
      allowSelection:  false
  })
  
  }
  else {
      throw new Error ('Unable to determine owner from given project.')
  }
  }
  on_ship_to_addresses_clicked(event = null) {
    return this.on_ship_to_addresses_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_to_addresses_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const project = (await $datasources.Owners.ds_get_project_by_projectId.get({projectId: $hub.inParams.projectId})).result;
  if ($utils.isDefined(project)){
  
      const ownerId = project[0].OwnerId
  
  $shell.FootPrintManager.openownersaccounts_gridDialog({
      ownerId:  ownerId,
      typeIds: [2,4,5],
      accountTypeId: 2,
      allowSelection:  false
  })
  
  }
  else {
      throw new Error ('Unable to determine owner from given project.')
  }
  
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'Project', entityKeys: [{name: 'Id', value: $hub.inParams.projectId}]}, 'modal');
  }
  on_assignment_clicked(event = null) {
    return this.on_assignment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assignment_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  let ownerId = (await $datasources.Owners.ds_get_project_by_projectId.get({projectId: $hub.inParams.projectId})).result
  
  $shell.FootPrintManager.opencontainer_type_assignment_gridDialog({ownerId: ownerId[0].OwnerId, projectId: $hub.inParams.projectId, enabled: true, assignmentLevel: 'PROJECT'})
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
      entities: [{ type: 'Project', ids: [$hub.inParams.projectId] }],
      operationContextTypes: null,
      warehouseIds: null
  }, 'flyout', EModalSize.Xlarge);
  
  
  }
  on_email_rules_clicked(event = null) {
    return this.on_email_rules_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_email_rules_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.Notifications.openauto_email_rules_gridDialog({
      projectId: $hub.inParams.projectId
  }, 'flyout', EModalSize.Xlarge);
  }
  on_print_inventory_by_project_report_clicked(event = null) {
    return this.on_print_inventory_by_project_report_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_inventory_by_project_report_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $reports.Inventory.inventory_by_project_report.print({ projectId: $hub.inParams.projectId });
  }
  on_auto_invoicing_rules_clicked(event = null) {
    return this.on_auto_invoicing_rules_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_invoicing_rules_clickedInternal(
    $hub: FootPrintManager_single_project_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.Invoices.openinvoicing_rules_gridDialog({
      projectId: $hub.inParams.projectId
  }, 'flyout', EModalSize.Xlarge);
  }
  //#endregion private flows
}
