import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { BarTender_ds_get_azure_blob_file_contentService } from './BarTender.datasource.index';
import { BarTender_ds_get_azure_blob_folder_contentService } from './BarTender.datasource.index';
import { BarTender_ds_get_configurationsService } from './BarTender.datasource.index';
import { BarTender_ds_get_receiving_tasks_by_orderIdService } from './BarTender.datasource.index';
import { BarTender_ds_printers_ddService } from './BarTender.datasource.index';
import { BarTender_ds_reflect_strings_ddService } from './BarTender.datasource.index';

@Injectable({ providedIn: 'root' })
export class BarTender_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public BarTender: BarTender_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_azure_blob_file_content: BarTender_ds_get_azure_blob_file_contentService;
  public get ds_get_azure_blob_file_content(): BarTender_ds_get_azure_blob_file_contentService {
    if(!this._ds_get_azure_blob_file_content) {
      this._ds_get_azure_blob_file_content = this.injector.get(BarTender_ds_get_azure_blob_file_contentService);
    }
    return this._ds_get_azure_blob_file_content;
  }
  private _ds_get_azure_blob_folder_content: BarTender_ds_get_azure_blob_folder_contentService;
  public get ds_get_azure_blob_folder_content(): BarTender_ds_get_azure_blob_folder_contentService {
    if(!this._ds_get_azure_blob_folder_content) {
      this._ds_get_azure_blob_folder_content = this.injector.get(BarTender_ds_get_azure_blob_folder_contentService);
    }
    return this._ds_get_azure_blob_folder_content;
  }
  private _ds_get_configurations: BarTender_ds_get_configurationsService;
  public get ds_get_configurations(): BarTender_ds_get_configurationsService {
    if(!this._ds_get_configurations) {
      this._ds_get_configurations = this.injector.get(BarTender_ds_get_configurationsService);
    }
    return this._ds_get_configurations;
  }
  private _ds_get_receiving_tasks_by_orderId: BarTender_ds_get_receiving_tasks_by_orderIdService;
  public get ds_get_receiving_tasks_by_orderId(): BarTender_ds_get_receiving_tasks_by_orderIdService {
    if(!this._ds_get_receiving_tasks_by_orderId) {
      this._ds_get_receiving_tasks_by_orderId = this.injector.get(BarTender_ds_get_receiving_tasks_by_orderIdService);
    }
    return this._ds_get_receiving_tasks_by_orderId;
  }
  private _ds_printers_dd: BarTender_ds_printers_ddService;
  public get ds_printers_dd(): BarTender_ds_printers_ddService {
    if(!this._ds_printers_dd) {
      this._ds_printers_dd = this.injector.get(BarTender_ds_printers_ddService);
    }
    return this._ds_printers_dd;
  }
  private _ds_reflect_strings_dd: BarTender_ds_reflect_strings_ddService;
  public get ds_reflect_strings_dd(): BarTender_ds_reflect_strings_ddService {
    if(!this._ds_reflect_strings_dd) {
      this._ds_reflect_strings_dd = this.injector.get(BarTender_ds_reflect_strings_ddService);
    }
    return this._ds_reflect_strings_dd;
  }
}

