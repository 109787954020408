<div class="dataview">
  <div class="summary-container">
    <div class="data-results-summary">
      <span class="results-count">{{totalCount}} {{totalCount === 1 ? 'item' : 'items'}}</span>
    </div>
  </div>
  <div data-cy="list-topToolbar"
       class="dataview-tools">
    <ng-content select="[topToolbar]"></ng-content>
  </div>
  <div data-cy="list-filters"
       class="dataview-filters">
    <ng-content select="[filters]"></ng-content>
  </div>

  <div class="card-list">

    <div *ngIf="loadingStatus === ELoadingStatus.Loading"
         class="no-results"
         data-cy="list-loading">
      <h3>Loading...</h3>
    </div>
    <div *ngIf="loadingStatus === ELoadingStatus.Error"
         class="no-results">
      <h3>Error occured while loading...</h3>
    </div>
    <div *ngIf="loadingStatus === ELoadingStatus.NoResults"
         class="no-results"
         data-cy="list-no-results">
      <h3>No results found</h3>
    </div>

    <div *ngFor="let item of items"
         [ngStyle]="item.styles.style"
         [ngClass]="item.styles.classes">
      <ng-container *ngTemplateOutlet="listItemTemplate.template; context: {$implicit: item}">
      </ng-container>
    </div>
    <div *ngIf="getIsThereMoreData()"
         class="full-width">
      <button (click)="goToNextPage()"
              class="datex-button secondary load-more">
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_arrow_clockwise_20_regular"></i></div>
          <div class="button-text">Load more</div>
        </div>
      </button>
    </div>
  </div>

</div>
