import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { InventoryCounts_count_task_statuses_dd_multiComponent } from './InventoryCounts.count_task_statuses_dd_multi.component'
import { FootPrintManager_reason_codes_dd_multiComponent } from './FootPrintManager.reason_codes_dd_multi.component'


interface IFootPrintManager_count_tasks_gridComponentEntity {
Id?: number, ActualPackagedAmount?: number, Employee?: string, ExpectedPackagedAmount?: number, Notes?: string, ReleaseDateTime?: string, Material?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string, StorageCategory?: { Id?: number, LookupCode?: string } }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, Status?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string }, Project?: { Id?: number, LookupCode?: string, Owner?: { LookupCode?: string } }, InventoryCountDiscrepancies?: { Id?: number, ActualAmount?: number, ExpectedAmount?: number, ResolvedDatetime?: string, StatusId?: number }[], InventoryCountTaskProperty?: { TaskId?: number, InventoryCountId?: number }, ReasonCode?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }}

interface IFootPrintManager_count_tasks_gridComponentInParams {
  inventoryCountId?: number, allowEdit?: boolean, statusIds?: number[], warehouseIds?: number[]}


class FootPrintManager_count_tasks_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_count_tasks_gridComponent;
  entity: IFootPrintManager_count_tasks_gridComponentEntity;

  vars: { hasReleasedTasks?: boolean } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    status_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    reason_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    task_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    project_lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    material_lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    expected_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    last_recorded_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    actual_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    vendorlot_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    lot_lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    expected_source_licenseplate_lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    expected_source_location_name: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    storage_category: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    release_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    employee: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_count_tasks_gridComponent, entity: IFootPrintManager_count_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_count_tasks_gridComponent, entity?: IFootPrintManager_count_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      inventoryCountId:  $grid.inParams.inventoryCountId ,
      statusIds:  $grid.filters.statuses.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      onlyCountsWithDiscrepancies:  $grid.filters.only_discrepancies.control.value ,
      operationCodeIds:  null ,
      warehouseId:  $grid.inParams.warehouseIds ,
      toDate:  $grid.filters.released_to.control.value ,
      fromDate:  $grid.filters.released_from.control.value ,
      reasonCodeIds:  $grid.filters.reason_codes.control.value ,
    };
    const data = await this.datasources.InventoryCounts.ds_count_tasks_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.status_name.displayControl as TextModel).text = $row.entity?.Status?.Name;
    (this.cells.reason_code.displayControl as TextModel).text = $row.entity.ReasonCode?.Name;
    (this.cells.task_id.displayControl as TextModel).text = $row.entity.Id.toString();
    (this.cells.project_lookupcode.displayControl as TextModel).text = $row.entity?.Project?.LookupCode?.trim().toLowerCase() === $row.entity?.Project?.Owner?.LookupCode?.trim().toLowerCase() ? $row.entity?.Project?.LookupCode : `${$row.entity?.Project?.Owner?.LookupCode} - ${$row.entity?.Project?.LookupCode}`;
    (this.cells.material_lookupcode.displayControl as TextModel).text = $row.entity?.Material?.LookupCode;
    (this.cells.expected_packaged_amount.displayControl as TextModel).text = $utils.isDefined($row.entity?.ExpectedPackagedAmount) ? `${$row.entity?.ExpectedPackagedAmount} ${$row.entity.ExpectedPackagedPack?.ShortName}` : '';
    (this.cells.last_recorded_amount.displayControl as TextModel).text = $utils.isDefined($row.entity.InventoryCountDiscrepancies) ? $row.entity.InventoryCountDiscrepancies[0]?.ExpectedAmount?.toString() + $row.entity.ExpectedPackagedPack?.ShortName : '';
    (this.cells.vendorlot_lookupcode.displayControl as TextModel).text = $row.entity?.VendorLot?.LookupCode;
    (this.cells.lot_lookupcode.displayControl as TextModel).text = $row.entity?.Lot?.LookupCode;
    (this.cells.expected_source_licenseplate_lookupcode.displayControl as TextModel).text = $row.entity?.ExpectedSourceLicensePlate?.LookupCode;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.Warehouse.Name;
    (this.cells.expected_source_location_name.displayControl as TextModel).text = $row.entity?.ExpectedSourceLocation?.Name;
    (this.cells.storage_category.displayControl as TextModel).text = $row.entity?.ExpectedSourceLocation?.StorageCategory?.LookupCode;
    (this.cells.release_date.displayControl as TextModel).text = $row.entity?.ReleaseDateTime?.toString();
    (this.cells.employee.displayControl as TextModel).text = $row.entity.Employee;
    (this.cells.notes.displayControl as TextModel).text = $row.entity?.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'task_id' : {
        this.on_id_clicked();
        break;
      }
      case 'project_lookupcode' : {
        this.on_project_clicked();
        break;
      }
      case 'material_lookupcode' : {
        this.on_material_clicked();
        break;
      }
      case 'lot_lookupcode' : {
        this.on_lot_clicked();
        break;
      }
      case 'expected_source_licenseplate_lookupcode' : {
        this.on_license_plate_clicked();
        break;
      }
      case 'expected_source_location_name' : {
        this.on_location_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_count_tasks_gridComponentRowModel,
  $grid: FootPrintManager_count_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Apply date format
  $row.cells.release_date.displayControl.text = $utils.date.format($row.cells.release_date.displayControl.text, $settings.InventoryCounts.DateFormat);
  
  
  
  
  }
  on_id_clicked(event = null) {
    return this.on_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_id_clickedInternal(
    $row: FootPrintManager_count_tasks_gridComponentRowModel,
  $grid: FootPrintManager_count_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Open count task editor
  await $shell.FootPrintManager.opencount_task_editorDialog({ taskId: $row.entity.Id}, 'flyout');
  await $grid.refresh();
  }
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootPrintManager_count_tasks_gridComponentRowModel,
  $grid: FootPrintManager_count_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.entity.Material?.Id)) {
      $shell.FootPrintManager.opensingle_material_hub({ materialId: $row.entity.Material.Id });
  }
  
  }
  on_location_clicked(event = null) {
    return this.on_location_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_clickedInternal(
    $row: FootPrintManager_count_tasks_gridComponentRowModel,
  $grid: FootPrintManager_count_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.entity.ExpectedSourceLocation?.Id)) {
      $shell.FootPrintManager.opensingle_location_hub({ locationId: $row.entity.ExpectedSourceLocation.Id });
  }
  }
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: FootPrintManager_count_tasks_gridComponentRowModel,
  $grid: FootPrintManager_count_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.entity.Lot?.Id)) {
      $shell.FootPrintManager.opensingle_lot_hub({ lotId: $row.entity.Lot.Id });
  }
  
  }
  on_project_clicked(event = null) {
    return this.on_project_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_clickedInternal(
    $row: FootPrintManager_count_tasks_gridComponentRowModel,
  $grid: FootPrintManager_count_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.entity.Project?.Id)) {
      $shell.FootPrintManager.opensingle_project_hub({ projectId: $row.entity.Project.Id });
  }
  
  }
  on_license_plate_clicked(event = null) {
    return this.on_license_plate_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_license_plate_clickedInternal(
    $row: FootPrintManager_count_tasks_gridComponentRowModel,
  $grid: FootPrintManager_count_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.entity.ExpectedSourceLicensePlate?.Id)) {
      $shell.FootPrintManager.opensingle_licenseplate_hub({ licenseplateId: $row.entity.ExpectedSourceLicensePlate.Id });
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => InventoryCounts_count_task_statuses_dd_multiComponent),
    forwardRef(() => FootPrintManager_reason_codes_dd_multiComponent),
  ],
  selector: 'FootPrintManager-count_tasks_grid',
  templateUrl: './FootPrintManager.count_tasks_grid.component.html'
})
export class FootPrintManager_count_tasks_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_count_tasks_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_count_tasks_gridComponentInParams = { inventoryCountId: null, allowEdit: null, statusIds: [], warehouseIds: [] };


  //#region Variables
  vars: { suppressConfirmationPrompts?: boolean } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     status_name: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       reason_code: new GridHeaderModel(new HeaderStyles(null, null), 'Reason', false, false, null),       task_id: new GridHeaderModel(new HeaderStyles(null, null), 'Count task', false, false, null),       project_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       material_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       expected_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Expected quantity', false, false, null),       last_recorded_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Counted quantity', false, false, null),       actual_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Actual quantity', false, false, null),       vendorlot_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', false, false, null),       lot_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       expected_source_licenseplate_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'License plate', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       expected_source_location_name: new GridHeaderModel(new HeaderStyles(null, null), 'Location', false, false, null),       storage_category: new GridHeaderModel(new HeaderStyles(null, null), 'Storage category', false, false, null),       release_date: new GridHeaderModel(new HeaderStyles(null, null), 'Released on', false, false, null),       employee: new GridHeaderModel(new HeaderStyles(null, null), 'Counted by', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_count_tasks_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('inventoryCountId') set $inParams_inventoryCountId(value: any) {
    this.inParams['inventoryCountId'] = value;
  }
  get $inParams_inventoryCountId(): any {
    return this.inParams['inventoryCountId'] ;
  }
  @Input('allowEdit') set $inParams_allowEdit(value: any) {
    this.inParams['allowEdit'] = value;
  }
  get $inParams_allowEdit(): any {
    return this.inParams['allowEdit'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }

  topToolbar = {
      resolve_tasks: new ToolModel(new ButtonModel('resolve_tasks', new ButtonStyles(null, null), false, 'Resolve', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular')
    ),
      recount_tasks: new ToolModel(new ButtonModel('recount_tasks', new ButtonStyles(null, null), false, 'Recount', 'icon-ic_fluent_data_scatter_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cancel_tasks: new ToolModel(new ButtonModel('cancel_tasks', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      override_tasks: new ToolModel(new ButtonModel('override_tasks', new ButtonStyles(null, null), false, 'Override', 'icon-ic_fluent_key_20_regular')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    only_discrepancies: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    statuses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    released_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    released_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reason_codes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    only_discrepancies: new FieldModel(new CheckBoxModel(this.formGroup.controls['only_discrepancies'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Only tasks with discrepancies', false)
,
    statuses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['statuses'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    released_from: new FieldModel(new DateBoxModel(this.formGroup.controls['released_from'] as DatexFormControl, null, false, 'l, LT', 'datetime')
, new ControlContainerStyles(null, null), 'Released from', false)
,
    released_to: new FieldModel(new DateBoxModel(this.formGroup.controls['released_to'] as DatexFormControl, null, false, 'l, LT', 'datetime')
, new ControlContainerStyles(null, null), 'Released to', false)
,
    reason_codes: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reason_codes'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Reason codes', false)
,
  }

  //#region filters inParams
  get $fields_reason_codes_selector_inParams_parentEntity(): string {
    const $grid = this;
    const $utils = this.utils;
    const expr = 'OperationType';
    
    return expr;
  }

  get $fields_reason_codes_selector_inParams_parentId(): number {
    const $grid = this;
    const $utils = this.utils;
    const expr = 32;
    
    return expr;
  }

  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Count tasks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.only_discrepancies.control as CheckBoxModel).reset(false);
    (this.filters.statuses.control as SelectBoxModel).reset([1,4]);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      inventoryCountId:  $grid.inParams.inventoryCountId ,
      statusIds:  $grid.filters.statuses.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      onlyCountsWithDiscrepancies:  $grid.filters.only_discrepancies.control.value ,
      operationCodeIds:  null ,
      warehouseId:  $grid.inParams.warehouseIds ,
      toDate:  $grid.filters.released_to.control.value ,
      fromDate:  $grid.filters.released_from.control.value ,
      reasonCodeIds:  $grid.filters.reason_codes.control.value ,
    };
    try {
    const data = await this.datasources.InventoryCounts.ds_count_tasks_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_count_tasks_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_resolve_tasks_clicked(event = null) {
    return this.on_resolve_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_resolve_tasks_clickedInternal(
    $grid: FootPrintManager_count_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let title: string;
      let message: string;
  
      if ($grid.selectedRows.length <= 0) {
          throw new Error('No rows have been selected.');
      }
      else if ($grid.selectedRows.length === 1) {
          title = 'Resolve count task';
          message = `Are you sure you want to resolve the selected count task?`
      }
      else {
          title = 'Resolve count tasks';
          message = `Are you sure you want to resolve the selected count tasks?`;
      }
  
      let row = $grid.selectedRows[0];
  
  
      if (!$grid.vars.suppressConfirmationPrompts) {
          let fullMessage = message + (!row.vars.hasReleasedTasks && row.entity.Status.Id === 2 ? ` The system expected a quantity of ${row.cells.expected_packaged_amount.displayControl.text}, but ${row.cells.last_recorded_amount.displayControl.text} were reported.` : '');
  
          var isConfirm = await $shell.InventoryCounts.openConfirmationDialog(
              title,
              fullMessage,
              'Resolve', 'Cancel');
      }
      else {
          isConfirm = true;
      }
  
      if (isConfirm) {
          let actualPackagedAmount: number;
  
          // Resolve Count Task
          let canComplete = false;
          if (row.vars.hasReleasedTasks || row.entity.Status.Id === 1 || row.entity.Status.Id === 4) {
              actualPackagedAmount = (await $shell.FootPrintManager.opengeneric_formDialog({ numberField1: 'Actual packaged amount', title: 'Resolve count task' })).numberField1;
  
              if (!$utils.isDefined(actualPackagedAmount)) {
                  return;
              }
  
              let result = (await $flows.InventoryCounts.resolve_count_tasks_flow({ countTaskId: row.entity.Id, packagedAmount: actualPackagedAmount, isOverride: !row.vars.hasReleasedTasks }));
  
              // Notify user of outcome
              if ($utils.isDefined(result.errors)) {
                  throw new Error(result.errors.join(', '));
              }
  
              if (result.hasDiscrepancy) {
                  if (await $shell.InventoryCounts.openConfirmationDialog('Count task completed', 'Successfully completed count task, but there was a discrepancy. Would you like to create a recount task or resolve now?', 'Create Recount', 'Resolve Now')) {
                      // Create recount task
                      await $grid.on_recount_tasks_clicked();
                  }
                  else {
                      await row.refresh();
                      if ($utils.isDefined(row.entity?.InventoryCountDiscrepancies[0])) {
                          // Resolve discrepancy
                          await $flows.Utilities.crud_update_flow({
                              entitySet: "InventoryCountDiscrepancies", id: row.entity?.InventoryCountDiscrepancies[0].Id, entity: {
                                  ActualAmount: actualPackagedAmount,
                                  ResolvedDatetime: $utils.date.now(),
                                  StatusId: 3
                              }
                          });
                      }
  
                      canComplete = true;
                  }
              }
              else {
                  canComplete = true;
              }
  
          } else {
              actualPackagedAmount = row.entity.InventoryCountDiscrepancies[0]?.ExpectedAmount;
  
              if ($utils.isDefined(row.entity?.InventoryCountDiscrepancies[0])) {
                  // Resolve discrepancy
                  await $flows.Utilities.crud_update_flow({
                      entitySet: "InventoryCountDiscrepancies", id: row.entity?.InventoryCountDiscrepancies[0].Id, entity: {
                          ActualAmount: actualPackagedAmount,
                          ResolvedDatetime: $utils.date.now(),
                          StatusId: 3
                      }
                  });
              }
  
              canComplete = true;
          }
  
  
  
          if (canComplete) {
              // Check if all tasks are resolved
              let remainingTasks = (await $datasources.InventoryCounts.ds_get_inventory_count_tasks.get({
                  inventoryCountIds: [$grid.inParams.inventoryCountId],
                  statusIds: [1, 4],
                  operationCodeIds: [58, 59]
              })).result;
  
              if (!$utils.isDefined(remainingTasks)) {
                  // Complete InventoryCount
                  (await $flows.InventoryCounts.set_inventory_count_completed_flow({
                      inventoryCountId: $grid.inParams.inventoryCountId,
                      isCancelOutstanding: false
                  }));
              }
          }
  
          if (!$grid.vars.suppressConfirmationPrompts) {
              row.selected = false;
              $grid.selectedRows = [];
              await row.refresh();
          }
  
          $grid.refresh();
      }
  }
  catch (exception) {
      await $shell.InventoryCounts.openErrorDialog('Error Resolving Count Task', $utils.isDefined(exception?.error?.error) ? exception.error.error.message : exception.message);
  }
  }
  on_recount_tasks_clicked(event = null) {
    return this.on_recount_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_recount_tasks_clickedInternal(
    $grid: FootPrintManager_count_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let title: string;
      let message: string;
  
      if ($grid.selectedRows.length <= 0) {
          throw new Error('No rows have been selected.');
      }
      else if ($grid.selectedRows.length === 1) {
          title = 'Recount Task';
          message = `Are you sure you want to recount this task?`
      }
      else {
          title = 'Recount Tasks';
          message = `Are you sure you want to recount these tasks?`;
      }
  
      if (!$grid.vars.suppressConfirmationPrompts) {
          var isConfirm = await $shell.InventoryCounts.openConfirmationDialog(title, message, 'Confirm', 'Cancel');
      }
      else {
          isConfirm = true;
      }
  
      $grid.vars.suppressConfirmationPrompts = true;
  
      if (isConfirm) {
          let newTaskIds: number[] = [];
  
          for (let row of $grid.selectedRows) {
              // Recount Count Task
              let result = (await $flows.InventoryCounts.create_recount_task_flow({ countTaskId: row.entity.Id }));
  
              if ($utils.isDefined(result.errors)) {
                  throw new Error(result.errors.join(', '));
              }
              else {
                  newTaskIds.push(result.recountTaskId);
                  row.vars.hasReleasedTasks = true;
              }
          }
          // Notify user
          if (await $shell.InventoryCounts.openConfirmationDialog('Success', `Successfully created recount task. Would you like to resolve now?`, 'Yes', 'No')) {
              await $grid.on_resolve_tasks_clicked();
          }
  
          for (let row of $grid.selectedRows) {
              row.selected = false;
          }
          $grid.selectedRows = [];
      }
  }
  catch (exception) {
      await $shell.InventoryCounts.openErrorDialog('Error recounting task', $utils.isDefined(exception?.error?.error) ? exception.error.error.message : exception.message);
  }
  
  $grid.refresh();
  
  $grid.vars.suppressConfirmationPrompts = false;
  }
  on_cancel_tasks_clicked(event = null) {
    return this.on_cancel_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_tasks_clickedInternal(
    $grid: FootPrintManager_count_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let title: string;
      let message: string;
  
      if ($grid.selectedRows.length <= 0) {
          throw new Error('No rows have been selected.');
      }
      else if ($grid.selectedRows.length === 1) {
          title = 'Cancel Count Task';
          message = `Are you sure you want to cancel the selected count task?`
      }
      else {
          title = 'Cancel Count Tasks';
          message = `Are you sure you want to cancel the selected count tasks?`;
      }
  
      if (!$grid.vars.suppressConfirmationPrompts) {
          var isConfirm = await $shell.InventoryCounts.openConfirmationDialog(title, message, 'Confirm', 'Cancel');
      }
      else {
          isConfirm = true;
      }
  
      $grid.vars.suppressConfirmationPrompts = true;
  
      if (isConfirm) {
          // Cancel Count Tasks
          let result = (await $flows.InventoryCounts.cancel_count_task_flow({ countTaskIds: $grid.selectedRows.map(r => r.entity.Id) }));
  
          // Notify user
          if ($utils.isDefined(result.errors)) {
              throw new Error(result.errors.join(', '));
          }
          else {
              await $shell.InventoryCounts.openInfoDialog('Success', 'Successfully Cancelled Count Tasks.');
          }
          for (let row of $grid.selectedRows) {
              row.selected = false;
              //row.refresh();
          }
  
          await $grid.refresh();
          $grid.selectedRows = [];
      }
  }
  catch (exception) {
      await $shell.InventoryCounts.openErrorDialog('Error Cancelling Count Task', $utils.isDefined(exception?.error?.error) ? exception.error.error.message : exception.message);
  }
  
  $grid.vars.suppressConfirmationPrompts = false;
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_count_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.resolve_tasks.control.readOnly = true;
  $grid.topToolbar.recount_tasks.control.readOnly = true;
  $grid.topToolbar.cancel_tasks.control.readOnly = true;
  $grid.topToolbar.override_tasks.control.readOnly = true;
  
  
  for (let row of $grid.selectedRows) {
      switch (row.entity.Status.Id) {
          case 1: { // Created
              $grid.topToolbar.resolve_tasks.control.readOnly = false;
              $grid.topToolbar.cancel_tasks.control.readOnly = false;
              break;
          }
          case 2: { // Completed
              let has_open_discrepancy = row.entity?.InventoryCountDiscrepancies.some(d => d.StatusId === 2);
  
              if (has_open_discrepancy) {
                  $grid.topToolbar.resolve_tasks.control.readOnly = false;
                  $grid.topToolbar.cancel_tasks.control.readOnly = false;
  
                  if (!row.vars.hasReleasedTasks) {
                      $grid.topToolbar.recount_tasks.control.readOnly = false;
                  }
              } else {
                  $grid.topToolbar.recount_tasks.control.readOnly = true;
                  $grid.topToolbar.override_tasks.control.readOnly = false;
              }
  
              break;
          }
          case 3: { // Cancelled
  
              break;
          }
          case 4: { // Planned
              $grid.topToolbar.cancel_tasks.control.readOnly = false;
              break;
          }
      }
  }
  
  if ($grid.selectedRows.length === 0) {
      $grid.topToolbar.resolve_tasks.control.readOnly = true;
      $grid.topToolbar.recount_tasks.control.readOnly = true;
      $grid.topToolbar.cancel_tasks.control.readOnly = true;
      $grid.topToolbar.override_tasks.control.readOnly = true;
  }
  
  if ($grid.selectedRows.length > 1) {
      $grid.topToolbar.resolve_tasks.control.readOnly = true;
      $grid.topToolbar.recount_tasks.control.readOnly = true;
      $grid.topToolbar.override_tasks.control.readOnly = true;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_count_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Default to Released and Planned Tasks
  //$grid.filters.statuses.control.value = [1,4];
  
  if ($utils.isDefined($utils.isDefined($grid.inParams.allowEdit)) && !$grid.inParams.allowEdit) {
      $grid.topToolbar.cancel_tasks.hidden = true;
      $grid.topToolbar.recount_tasks.hidden = true;
      $grid.topToolbar.resolve_tasks.hidden = true;
      $grid.topToolbar.override_tasks.hidden = false;
  }
  else {
      $grid.topToolbar.cancel_tasks.hidden = false;
      $grid.topToolbar.recount_tasks.hidden = false;
      $grid.topToolbar.resolve_tasks.hidden = false;
      $grid.topToolbar.override_tasks.hidden = false;
  }
  
  }
  on_override_tasks_clicked(event = null) {
    return this.on_override_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_override_tasks_clickedInternal(
    $grid: FootPrintManager_count_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let title: string;
      let message: string;
  
      if ($grid.selectedRows.length <= 0) {
          throw new Error('No rows have been selected.');
      }
      else if ($grid.selectedRows.length === 1) {
          title = 'Override Task';
          message = `Are you sure you want to override this task?`
      }
      else {
          title = 'Override Tasks';
          message = `Are you sure you want to override these tasks?`;
      }
  
      if (!$grid.vars.suppressConfirmationPrompts) {
          var isConfirm = await $shell.InventoryCounts.openConfirmationDialog(title, message, 'Confirm', 'Cancel');
      }
      else {
          isConfirm = true;
      }
  
      $grid.vars.suppressConfirmationPrompts = true;
  
      if (isConfirm) {
          let newTaskIds: number[] = [];
  
          let actualPackagedAmount = (await $shell.FootPrintManager.opengeneric_formDialog({ title: 'Please enter the packaged amount', numberField1: 'Actual Packaged Amount' })).numberField1;
          if (!$utils.isDefined(actualPackagedAmount)) {
              throw new Error('Invalid value entered for the actual packaged amount.');
          }
          if (actualPackagedAmount < 0) {
              throw new Error(`Packaged amount must be greater than zero`);
          }
  
          for (let row of $grid.selectedRows) {
              // Override Count Task
              let result = (await $flows.InventoryCounts.create_recount_task_flow({ countTaskId: row.entity.Id, isOverride: true }));
  
              if ($utils.isDefined(result.errors)) {
                  throw new Error(result.errors.join(', '));
              }
              else {
                  newTaskIds.push(result.recountTaskId);
              }
  
              // Resolve Count Task
              result = (await $flows.InventoryCounts.resolve_count_tasks_flow({ countTaskId: row.entity.Id, packagedAmount: actualPackagedAmount, autoResolveDiscrepancy: true, isOverride: true }));
  
              // Notify user of outcome
              if ($utils.isDefined(result.errors)) {
                  throw new Error(result.errors.join(', '));
              }
          }
  
          for (let row of $grid.selectedRows) {
              row.selected = false;
          }
  
          $grid.refresh();
          $grid.selectedRows = [];
      }
  }
  catch (exception) {
      await $shell.InventoryCounts.openErrorDialog('Error Overriding Task', $utils.isDefined(exception?.error?.error) ? exception.error.error.message : exception.message);
  }
  
  $grid.vars.suppressConfirmationPrompts = false;
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_count_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let childTasks = (await $datasources.InventoryCounts.ds_get_count_tasks_by_chainHeads.get({ chainHeads: $grid.rows.map(r => r.entity.Id) })).result;
  
  // Check for reason codes
  let has_reason_codes = $utils.isDefined($grid.rows.some(row => $utils.isDefinedTrimmed(row.cells.reason_code.displayControl.text)));
  if (!has_reason_codes) {
      $grid.headers.reason_code.hidden = true;
      $grid.filters.reason_codes.control.value = null;
      $grid.filters.reason_codes.hidden = true;
  } else {
      $grid.headers.reason_code.hidden = false;
      $grid.filters.reason_codes.hidden = false;
  }
  
  for (let row of $grid.rows) {
      row.vars.hasReleasedTasks = $utils.isDefined(childTasks.find(ct => ct.ChainHead === row.entity.Id && (ct.StatusId === 1 || ct.StatusId === 4)));
  
      // Apply custom status and actual amount
      let statusName = row.entity.Status.Name;
      let actualAmount = row.entity?.ActualPackagedAmount;
      let discrepancy = row.entity?.InventoryCountDiscrepancies.sort((a, b) => b.Id - a.Id)[0];
      if ($utils.isDefined(discrepancy)) {
          if (row.entity.Status.Id !== 3) {
              if (discrepancy.StatusId === 1) { // Unresolved
                  statusName = 'Cancelled';
                  actualAmount = null;
              }
              else if (discrepancy.StatusId === 2) { // Resolving
                  actualAmount = null
                  // Check if any released tasks
                  statusName = row.vars.hasReleasedTasks ? 'Pending Discrepancies' : 'Awaiting resolution';
              }
              else if (discrepancy.StatusId === 3) { // Resolved
                  actualAmount = discrepancy.ActualAmount;
              }
          }
          else {
              actualAmount = null;
          }
      }
  
      row.cells.actual_packaged_amount.displayControl.text = $utils.isDefined(actualAmount) ? actualAmount.toString() + row.entity.ExpectedPackagedPack?.ShortName : '';
      row.cells.status_name.displayControl.text = statusName;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
