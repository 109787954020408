import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Cartonization_ds_get_completed_picking_tasks_by_shipmentIdService } from './Cartonization.datasource.index';
import { Cartonization_ds_get_container_typesService } from './Cartonization.datasource.index';
import { Cartonization_ds_get_material_packaging_by_materialIdsService } from './Cartonization.datasource.index';
import { Cartonization_ds_get_max_shipment_line_number_by_shipmentIdService } from './Cartonization.datasource.index';
import { Cartonization_ds_get_picking_tasks_by_shipmentIdService } from './Cartonization.datasource.index';
import { Cartonization_ds_get_shipment_lines_by_shipmentIdService } from './Cartonization.datasource.index';
import { Cartonization_ds_get_shipping_containers_by_shipmentIdService } from './Cartonization.datasource.index';
import { Cartonization_ds_get_shipping_containers_to_removeService } from './Cartonization.datasource.index';

@Injectable({ providedIn: 'root' })
export class Cartonization_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Cartonization: Cartonization_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_completed_picking_tasks_by_shipmentId: Cartonization_ds_get_completed_picking_tasks_by_shipmentIdService;
  public get ds_get_completed_picking_tasks_by_shipmentId(): Cartonization_ds_get_completed_picking_tasks_by_shipmentIdService {
    if(!this._ds_get_completed_picking_tasks_by_shipmentId) {
      this._ds_get_completed_picking_tasks_by_shipmentId = this.injector.get(Cartonization_ds_get_completed_picking_tasks_by_shipmentIdService);
    }
    return this._ds_get_completed_picking_tasks_by_shipmentId;
  }
  private _ds_get_container_types: Cartonization_ds_get_container_typesService;
  public get ds_get_container_types(): Cartonization_ds_get_container_typesService {
    if(!this._ds_get_container_types) {
      this._ds_get_container_types = this.injector.get(Cartonization_ds_get_container_typesService);
    }
    return this._ds_get_container_types;
  }
  private _ds_get_material_packaging_by_materialIds: Cartonization_ds_get_material_packaging_by_materialIdsService;
  public get ds_get_material_packaging_by_materialIds(): Cartonization_ds_get_material_packaging_by_materialIdsService {
    if(!this._ds_get_material_packaging_by_materialIds) {
      this._ds_get_material_packaging_by_materialIds = this.injector.get(Cartonization_ds_get_material_packaging_by_materialIdsService);
    }
    return this._ds_get_material_packaging_by_materialIds;
  }
  private _ds_get_max_shipment_line_number_by_shipmentId: Cartonization_ds_get_max_shipment_line_number_by_shipmentIdService;
  public get ds_get_max_shipment_line_number_by_shipmentId(): Cartonization_ds_get_max_shipment_line_number_by_shipmentIdService {
    if(!this._ds_get_max_shipment_line_number_by_shipmentId) {
      this._ds_get_max_shipment_line_number_by_shipmentId = this.injector.get(Cartonization_ds_get_max_shipment_line_number_by_shipmentIdService);
    }
    return this._ds_get_max_shipment_line_number_by_shipmentId;
  }
  private _ds_get_picking_tasks_by_shipmentId: Cartonization_ds_get_picking_tasks_by_shipmentIdService;
  public get ds_get_picking_tasks_by_shipmentId(): Cartonization_ds_get_picking_tasks_by_shipmentIdService {
    if(!this._ds_get_picking_tasks_by_shipmentId) {
      this._ds_get_picking_tasks_by_shipmentId = this.injector.get(Cartonization_ds_get_picking_tasks_by_shipmentIdService);
    }
    return this._ds_get_picking_tasks_by_shipmentId;
  }
  private _ds_get_shipment_lines_by_shipmentId: Cartonization_ds_get_shipment_lines_by_shipmentIdService;
  public get ds_get_shipment_lines_by_shipmentId(): Cartonization_ds_get_shipment_lines_by_shipmentIdService {
    if(!this._ds_get_shipment_lines_by_shipmentId) {
      this._ds_get_shipment_lines_by_shipmentId = this.injector.get(Cartonization_ds_get_shipment_lines_by_shipmentIdService);
    }
    return this._ds_get_shipment_lines_by_shipmentId;
  }
  private _ds_get_shipping_containers_by_shipmentId: Cartonization_ds_get_shipping_containers_by_shipmentIdService;
  public get ds_get_shipping_containers_by_shipmentId(): Cartonization_ds_get_shipping_containers_by_shipmentIdService {
    if(!this._ds_get_shipping_containers_by_shipmentId) {
      this._ds_get_shipping_containers_by_shipmentId = this.injector.get(Cartonization_ds_get_shipping_containers_by_shipmentIdService);
    }
    return this._ds_get_shipping_containers_by_shipmentId;
  }
  private _ds_get_shipping_containers_to_remove: Cartonization_ds_get_shipping_containers_to_removeService;
  public get ds_get_shipping_containers_to_remove(): Cartonization_ds_get_shipping_containers_to_removeService {
    if(!this._ds_get_shipping_containers_to_remove) {
      this._ds_get_shipping_containers_to_remove = this.injector.get(Cartonization_ds_get_shipping_containers_to_removeService);
    }
    return this._ds_get_shipping_containers_to_remove;
  }
}

