import { isEmpty, isNil } from 'lodash-es';
import { DatexFormControl } from './datex-form-control';
import { Styles } from './style';
import { ValidationErrors, Validators } from '@angular/forms';
import * as vkbeautify from 'vkbeautify';
import { EventEmitter } from '@angular/core';

export class ControlModel {
  styles: Styles;

  constructor(styles?: any) {
    this.styles = isNil(styles) ? new Styles() : styles;
  }
}

export class ValueControlModel extends ControlModel {
  protected formControl: DatexFormControl;
  public validationTriggered: EventEmitter<void> = new EventEmitter<void>;

  constructor(formControl: DatexFormControl, styles?: Styles, readOnly?: boolean) {
    super(styles);
    this.formControl = formControl;
    this.readOnly = readOnly
  }

  get defaultValue(): any {
    return this.formControl.defaultValue;
  }

  get value() {
    return this.formControl.value;
  }

  get errors() {
    return this.formControl.errors;
  }

  get touched() {
    return this.formControl.touched;
  }

  get errorMessages() {
    const builtInValidators = Object.getOwnPropertyNames(Validators);
    const errors = Object.entries(this.errors ?? {});
    const customErrors = errors?.filter(i => !builtInValidators.includes(i[0])).map(i => i[1]);
    const angularErrors = errors?.filter(i => builtInValidators.includes(i[0])).map(i => {
      switch (i[0]) {
        case 'required':
          return 'This field is required.';
        default:
          return i[1];
      }
    });

    return (customErrors ?? []).concat(angularErrors ?? []);
  }

  set value(v) {
    this.formControl.setValue(v, { emitEvent: false });
    this.formControl.markAsDirty();
  }

  get valid() {
    return this.formControl.valid;
  }

  get valueChanges() {
    return this.formControl.valueChanges
  }

  setErrors(errors: ValidationErrors) {
    this.formControl.setErrors(errors);
  }

  validate() {
    this.validationTriggered.emit();
  }

  // TODO: To discuss if we want to use reset as the initial value
  reset(v: any) {
    this.formControl.reset(v, { emitEvent: false });
  }

  get readOnly() {
    return this.formControl.disabled;
  }

  set readOnly(val: any) {
    if (val) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  /**
   * @description
   * A flag indicated whether the control value has changed. 
   * IMPORTANT to note that it will be marked as changed only on UI changes.
   * It relies on the FormControl dirty flag
   *
   */
  get isChanged(): boolean {
    return this.formControl.dirty;
  }

  focus() {
    this.formControl.focus();
  }

  blur() {
    this.formControl.blur();
  }
}

export class TextBoxModel extends ValueControlModel {
  placeholder = '';

  constructor(formControl: DatexFormControl, styles?: Styles, readOnly?: boolean, placeholder?: string) {
    super(formControl, styles, readOnly);
    this.placeholder = placeholder;
  }
}

export class NumberBoxModel extends ValueControlModel {
  format: string;
  placeholder = '';

  constructor(formControl: DatexFormControl, styles?: Styles, readOnly?: boolean, format?: string, placeholder?: string) {
    super(formControl, styles, readOnly);
    this.format = format;
    this.placeholder = placeholder;
  }
}

export enum ESelectBoxType {
  dropdown = 'dropdown',
  chips = 'chips',
  radioButtonsCheckboxes = 'radioButtonsCheckboxes'
}

export class SelectBoxModel extends ValueControlModel {
  type?: ESelectBoxType;
  placeholder = '';
  displayText = '';

  constructor(formControl: DatexFormControl, type?: ESelectBoxType, styles?: Styles, readOnly?: boolean, placeholder?: string) {
    super(formControl, styles, readOnly);
    this.type = type;
    this.placeholder = placeholder;
  }
}

export class DateBoxModel extends ValueControlModel {
  format: string;
  mode: string;

  constructor(formControl: DatexFormControl, styles?: Styles, readOnly?: boolean, format?: string, mode?: string) {
    super(formControl, styles, readOnly);
    this.format = format;
    this.mode = mode;
  }
}

export class CheckBoxModel extends ValueControlModel {
  label: string;
  constructor(formControl: DatexFormControl, styles?: Styles, readOnly?: boolean, label?: string) {
    super(formControl, styles, readOnly);
    this.label = label;
  }

  override get readOnly() {
    return this.formControl.disabled;
  }

  override set readOnly(val: any) {
    if (val) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
}

export class TextModel extends ControlModel {
  text: string = null;
  formatType: string = null;
  format: string = null;

  constructor(styles?: Styles, text?: string, formatType?: string, format?: string) {
    super(styles);
    this.text = text;
    this.formatType = formatType;
    this.format = format;
  }
}

export class LabelModel extends ControlModel {
  label: string = null;

  constructor(styles?: Styles, label?: string) {
    super(styles);
    this.label = label;
  }
}

export class ButtonStyles extends Styles {
  private setStateClass(klass: string) {
    this.removeClass([
      'primary',
      'secondary',
      'tertiary',
      'destructive',
      'creation',
      'link']);
    this.addClass(klass);
  }

  setPrimaryClass() { this.setStateClass('primary'); }
  setSecondaryClass() { this.setStateClass('secondary'); }
  setTertiaryClass() { this.setStateClass('tertiary'); }
  setDestructiveClass() { this.setStateClass('destructive'); }
  setCreationClass() { this.setStateClass('creation'); }
  setLinkClass() { this.setStateClass('link'); }
}

export class ButtonModel extends ControlModel {
  override styles: ButtonStyles;
  private _readOnly = false;
  id?: string;
  label?: string;
  icon?: string;

  constructor(id?: string, styles?: ButtonStyles, readOnly?: boolean, label?: string, icon?: string) {
    super();
    this.styles = isNil(styles) ? new ButtonStyles() : styles;
    this.readOnly = readOnly
    this.id = id;
    this.label = label;
    this.icon = icon;
  }

  get readOnly() {
    return this._readOnly;
  }

  set readOnly(val: any) {
    this._readOnly = val;
  }
}

export class SplitButtonModel<T extends { [K in keyof T]: ButtonModel }> extends ButtonModel {
  buttons: T;

  constructor(
    id?: string,
    styles?: ButtonStyles,
    readOnly?: boolean,
    label?: string,
    icon?: string,
    buttons?: ButtonModel[]) {
    super(id, styles, readOnly, label, icon);
    this.buttons = Object.fromEntries(buttons?.map(i => [i.id, i]) ?? []) as T;
  }
}

export class ImageModel extends ControlModel {
  src: string = null;

  constructor(styles?: Styles, src?: string) {
    super(styles);
    this.src = src;
  }
}

export class SeparatorModel extends ControlModel {
  constructor(styles?: Styles) {
    super(styles);
  }
}
export class DrawModel extends ValueControlModel {
  options: {};

  constructor(formControl: DatexFormControl, styles?: Styles, readOnly?: boolean, options?: {}) {
    super(formControl, styles, readOnly);
    this.options = options;
  }
}

export class CodeBoxModel extends ValueControlModel {
  codeMirrorMode?: string;
  mode?: string;

  constructor(formControl: DatexFormControl, styles?: Styles, readOnly?: boolean, mode?: string) {
    super(formControl, styles, readOnly);
    this.mode = mode;
    if (mode === 'json') {
      this.codeMirrorMode = 'application/json';
    } else if (mode === 'xml') {
      this.codeMirrorMode = 'application/xml';
    }
  }

  beautify() {
    this.value = super.value;
  }

  minify() {
    super.value = this.value;
  }

  override get value() {
    if (isEmpty(super.value)) {
      return super.value;
    }

    return this.mode === 'xml' ? vkbeautify.xmlmin(super.value, [, true]) : vkbeautify.jsonmin(super.value);
  }

  override set value(v) {
    if (!isEmpty(v)) {
      v = this.mode === 'xml' ? vkbeautify.xml(v) : vkbeautify.json(v);
    }

    super.value = v;
  }
}