import { Injectable } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateParser, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Language, LocalizationService, LocalizationSettings } from './localization.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Utilities_LocalizationService } from './Utilities.localization.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingContainers_LocalizationService
  extends LocalizationService<{  }> {

  public ShippingContainers: ShippingContainers_LocalizationService = this;

  public override get settings(): LocalizationSettings {
    return {"default":"","languages":[]}
  }
  
  private _referencedServices: LocalizationService<any>[] = [];

  constructor(
    httpClient: HttpClient,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingHandler: MissingTranslationHandler,
    public Utilities: Utilities_LocalizationService
    ) {
    super(
      new TranslateService(
        new TranslateStore(),
        new TranslateHttpLoader(httpClient, '../assets/i18n/ShippingContainers/', '.json'),
        compiler,
        parser,
        missingHandler,
        true,
        true,
        false,
        ''));

    this._referencedServices.push(this.Utilities);
    
    this.initialize();

  }

  public override setLanguage(lang: Language): void{
    super.setLanguage(lang);
  
    for (let i of this._referencedServices){
      i.setLanguage(lang);
    }
  }
}