import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Addresses_ds_account_types_ddService } from './Addresses.datasource.index';
import { Addresses_ds_contact_accounts_gridService } from './Addresses.datasource.index';
import { Addresses_ds_contact_addresses_gridService } from './Addresses.datasource.index';
import { Addresses_ds_contact_types_ddService } from './Addresses.datasource.index';
import { Addresses_ds_country_codesService } from './Addresses.datasource.index';
import { Addresses_ds_find_owner_account_lookupService } from './Addresses.datasource.index';
import { Addresses_ds_get_contact_by_addressIdService } from './Addresses.datasource.index';
import { Addresses_ds_get_contact_types_by_nameService } from './Addresses.datasource.index';
import { Addresses_ds_get_contacts_by_accountId_top1Service } from './Addresses.datasource.index';
import { Addresses_ds_get_order_account_address_by_orderIdService } from './Addresses.datasource.index';
import { Addresses_ds_get_order_account_addresses_by_orderIdsService } from './Addresses.datasource.index';
import { Addresses_ds_get_order_account_vs_order_address_by_orderIdsService } from './Addresses.datasource.index';
import { Addresses_ds_get_order_address_by_orderId_top1Service } from './Addresses.datasource.index';
import { Addresses_ds_get_order_addresses_by_orderIdsService } from './Addresses.datasource.index';
import { Addresses_ds_get_order_ship_account_vs_order_addressService } from './Addresses.datasource.index';
import { Addresses_ds_get_orders_by_addressId_top1Service } from './Addresses.datasource.index';
import { Addresses_ds_get_orders_by_orderaddressid_top1Service } from './Addresses.datasource.index';
import { Addresses_ds_get_shipments_by_contactId_top1Service } from './Addresses.datasource.index';
import { Addresses_ds_orderaddresses_gridService } from './Addresses.datasource.index';
import { Addresses_ds_orderaddresses_types_ddService } from './Addresses.datasource.index';
import { Addresses_ds_ownersaccounts_ddService } from './Addresses.datasource.index';
import { Addresses_ds_ownersaccounts_gridService } from './Addresses.datasource.index';

@Injectable({ providedIn: 'root' })
export class Addresses_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Addresses: Addresses_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_account_types_dd: Addresses_ds_account_types_ddService;
  public get ds_account_types_dd(): Addresses_ds_account_types_ddService {
    if(!this._ds_account_types_dd) {
      this._ds_account_types_dd = this.injector.get(Addresses_ds_account_types_ddService);
    }
    return this._ds_account_types_dd;
  }
  private _ds_contact_accounts_grid: Addresses_ds_contact_accounts_gridService;
  public get ds_contact_accounts_grid(): Addresses_ds_contact_accounts_gridService {
    if(!this._ds_contact_accounts_grid) {
      this._ds_contact_accounts_grid = this.injector.get(Addresses_ds_contact_accounts_gridService);
    }
    return this._ds_contact_accounts_grid;
  }
  private _ds_contact_addresses_grid: Addresses_ds_contact_addresses_gridService;
  public get ds_contact_addresses_grid(): Addresses_ds_contact_addresses_gridService {
    if(!this._ds_contact_addresses_grid) {
      this._ds_contact_addresses_grid = this.injector.get(Addresses_ds_contact_addresses_gridService);
    }
    return this._ds_contact_addresses_grid;
  }
  private _ds_contact_types_dd: Addresses_ds_contact_types_ddService;
  public get ds_contact_types_dd(): Addresses_ds_contact_types_ddService {
    if(!this._ds_contact_types_dd) {
      this._ds_contact_types_dd = this.injector.get(Addresses_ds_contact_types_ddService);
    }
    return this._ds_contact_types_dd;
  }
  private _ds_country_codes: Addresses_ds_country_codesService;
  public get ds_country_codes(): Addresses_ds_country_codesService {
    if(!this._ds_country_codes) {
      this._ds_country_codes = this.injector.get(Addresses_ds_country_codesService);
    }
    return this._ds_country_codes;
  }
  private _ds_find_owner_account_lookup: Addresses_ds_find_owner_account_lookupService;
  public get ds_find_owner_account_lookup(): Addresses_ds_find_owner_account_lookupService {
    if(!this._ds_find_owner_account_lookup) {
      this._ds_find_owner_account_lookup = this.injector.get(Addresses_ds_find_owner_account_lookupService);
    }
    return this._ds_find_owner_account_lookup;
  }
  private _ds_get_contact_by_addressId: Addresses_ds_get_contact_by_addressIdService;
  public get ds_get_contact_by_addressId(): Addresses_ds_get_contact_by_addressIdService {
    if(!this._ds_get_contact_by_addressId) {
      this._ds_get_contact_by_addressId = this.injector.get(Addresses_ds_get_contact_by_addressIdService);
    }
    return this._ds_get_contact_by_addressId;
  }
  private _ds_get_contact_types_by_name: Addresses_ds_get_contact_types_by_nameService;
  public get ds_get_contact_types_by_name(): Addresses_ds_get_contact_types_by_nameService {
    if(!this._ds_get_contact_types_by_name) {
      this._ds_get_contact_types_by_name = this.injector.get(Addresses_ds_get_contact_types_by_nameService);
    }
    return this._ds_get_contact_types_by_name;
  }
  private _ds_get_contacts_by_accountId_top1: Addresses_ds_get_contacts_by_accountId_top1Service;
  public get ds_get_contacts_by_accountId_top1(): Addresses_ds_get_contacts_by_accountId_top1Service {
    if(!this._ds_get_contacts_by_accountId_top1) {
      this._ds_get_contacts_by_accountId_top1 = this.injector.get(Addresses_ds_get_contacts_by_accountId_top1Service);
    }
    return this._ds_get_contacts_by_accountId_top1;
  }
  private _ds_get_order_account_address_by_orderId: Addresses_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): Addresses_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(Addresses_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_account_addresses_by_orderIds: Addresses_ds_get_order_account_addresses_by_orderIdsService;
  public get ds_get_order_account_addresses_by_orderIds(): Addresses_ds_get_order_account_addresses_by_orderIdsService {
    if(!this._ds_get_order_account_addresses_by_orderIds) {
      this._ds_get_order_account_addresses_by_orderIds = this.injector.get(Addresses_ds_get_order_account_addresses_by_orderIdsService);
    }
    return this._ds_get_order_account_addresses_by_orderIds;
  }
  private _ds_get_order_account_vs_order_address_by_orderIds: Addresses_ds_get_order_account_vs_order_address_by_orderIdsService;
  public get ds_get_order_account_vs_order_address_by_orderIds(): Addresses_ds_get_order_account_vs_order_address_by_orderIdsService {
    if(!this._ds_get_order_account_vs_order_address_by_orderIds) {
      this._ds_get_order_account_vs_order_address_by_orderIds = this.injector.get(Addresses_ds_get_order_account_vs_order_address_by_orderIdsService);
    }
    return this._ds_get_order_account_vs_order_address_by_orderIds;
  }
  private _ds_get_order_address_by_orderId_top1: Addresses_ds_get_order_address_by_orderId_top1Service;
  public get ds_get_order_address_by_orderId_top1(): Addresses_ds_get_order_address_by_orderId_top1Service {
    if(!this._ds_get_order_address_by_orderId_top1) {
      this._ds_get_order_address_by_orderId_top1 = this.injector.get(Addresses_ds_get_order_address_by_orderId_top1Service);
    }
    return this._ds_get_order_address_by_orderId_top1;
  }
  private _ds_get_order_addresses_by_orderIds: Addresses_ds_get_order_addresses_by_orderIdsService;
  public get ds_get_order_addresses_by_orderIds(): Addresses_ds_get_order_addresses_by_orderIdsService {
    if(!this._ds_get_order_addresses_by_orderIds) {
      this._ds_get_order_addresses_by_orderIds = this.injector.get(Addresses_ds_get_order_addresses_by_orderIdsService);
    }
    return this._ds_get_order_addresses_by_orderIds;
  }
  private _ds_get_order_ship_account_vs_order_address: Addresses_ds_get_order_ship_account_vs_order_addressService;
  public get ds_get_order_ship_account_vs_order_address(): Addresses_ds_get_order_ship_account_vs_order_addressService {
    if(!this._ds_get_order_ship_account_vs_order_address) {
      this._ds_get_order_ship_account_vs_order_address = this.injector.get(Addresses_ds_get_order_ship_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_account_vs_order_address;
  }
  private _ds_get_orders_by_addressId_top1: Addresses_ds_get_orders_by_addressId_top1Service;
  public get ds_get_orders_by_addressId_top1(): Addresses_ds_get_orders_by_addressId_top1Service {
    if(!this._ds_get_orders_by_addressId_top1) {
      this._ds_get_orders_by_addressId_top1 = this.injector.get(Addresses_ds_get_orders_by_addressId_top1Service);
    }
    return this._ds_get_orders_by_addressId_top1;
  }
  private _ds_get_orders_by_orderaddressid_top1: Addresses_ds_get_orders_by_orderaddressid_top1Service;
  public get ds_get_orders_by_orderaddressid_top1(): Addresses_ds_get_orders_by_orderaddressid_top1Service {
    if(!this._ds_get_orders_by_orderaddressid_top1) {
      this._ds_get_orders_by_orderaddressid_top1 = this.injector.get(Addresses_ds_get_orders_by_orderaddressid_top1Service);
    }
    return this._ds_get_orders_by_orderaddressid_top1;
  }
  private _ds_get_shipments_by_contactId_top1: Addresses_ds_get_shipments_by_contactId_top1Service;
  public get ds_get_shipments_by_contactId_top1(): Addresses_ds_get_shipments_by_contactId_top1Service {
    if(!this._ds_get_shipments_by_contactId_top1) {
      this._ds_get_shipments_by_contactId_top1 = this.injector.get(Addresses_ds_get_shipments_by_contactId_top1Service);
    }
    return this._ds_get_shipments_by_contactId_top1;
  }
  private _ds_orderaddresses_grid: Addresses_ds_orderaddresses_gridService;
  public get ds_orderaddresses_grid(): Addresses_ds_orderaddresses_gridService {
    if(!this._ds_orderaddresses_grid) {
      this._ds_orderaddresses_grid = this.injector.get(Addresses_ds_orderaddresses_gridService);
    }
    return this._ds_orderaddresses_grid;
  }
  private _ds_orderaddresses_types_dd: Addresses_ds_orderaddresses_types_ddService;
  public get ds_orderaddresses_types_dd(): Addresses_ds_orderaddresses_types_ddService {
    if(!this._ds_orderaddresses_types_dd) {
      this._ds_orderaddresses_types_dd = this.injector.get(Addresses_ds_orderaddresses_types_ddService);
    }
    return this._ds_orderaddresses_types_dd;
  }
  private _ds_ownersaccounts_dd: Addresses_ds_ownersaccounts_ddService;
  public get ds_ownersaccounts_dd(): Addresses_ds_ownersaccounts_ddService {
    if(!this._ds_ownersaccounts_dd) {
      this._ds_ownersaccounts_dd = this.injector.get(Addresses_ds_ownersaccounts_ddService);
    }
    return this._ds_ownersaccounts_dd;
  }
  private _ds_ownersaccounts_grid: Addresses_ds_ownersaccounts_gridService;
  public get ds_ownersaccounts_grid(): Addresses_ds_ownersaccounts_gridService {
    if(!this._ds_ownersaccounts_grid) {
      this._ds_ownersaccounts_grid = this.injector.get(Addresses_ds_ownersaccounts_gridService);
    }
    return this._ds_ownersaccounts_grid;
  }
}

