import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_ds_serialnumbers_creation_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { materialId: number, packagingId: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, DimensionUomId?: number, Height?: number, Length?: number, ShippingVolume?: number, ShippingWeight?: number, SubPackagingId?: number, SubPackagingQuantity?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, Packaging?: { ShortName?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumbers_creation_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
