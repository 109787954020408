import {
  WeekViewTimeEvent,
  WeekViewHourSegment,
  WeekViewHour,
  CalendarEvent
} from './calendar-utils';

import { DateAdapter } from './date-adapter';

// #region copy 'angular-calendar/modules/common/util'
  //copy 'angular-calendar/modules/common/util'
  export const trackByHourSegment = (
    index: number,
    segment: WeekViewHourSegment
  ) => segment.date.toISOString();
  //copy 'angular-calendar/modules/common/util'
  export const trackByHour = (index: number, hour: WeekViewHour) =>
    hour.segments[0].date.toISOString();
  //copy 'angular-calendar/modules/common/util'
    export const trackByWeekTimeEvent = (
      index: number,
      weekEvent: WeekViewTimeEvent
    ) => (weekEvent.event.id ? weekEvent.event.id : weekEvent.event);
  
  //copy 'angular-calendar/modules/common/util'
  export function getWeekViewPeriod(
    dateAdapter: DateAdapter,
    viewDate: Date,
    weekStartsOn: number,
    excluded: number[] = [],
    daysInWeek?: number
  ): { viewStart: Date; viewEnd: Date } {
    let viewStart = daysInWeek
      ? dateAdapter.startOfDay(viewDate)
      : dateAdapter.startOfWeek(viewDate, { weekStartsOn });
    const endOfWeek = dateAdapter.endOfWeek(viewDate, { weekStartsOn });
    while (
      excluded.indexOf(dateAdapter.getDay(viewStart)) > -1 &&
      viewStart < endOfWeek
    ) {
      viewStart = dateAdapter.addDays(viewStart, 1);
    }
    if (daysInWeek) {
      const viewEnd = dateAdapter.endOfDay(
        addDaysWithExclusions(dateAdapter, viewStart, daysInWeek - 1, excluded)
      );
      return { viewStart, viewEnd };
    } else {
      let viewEnd = endOfWeek;
      while (
        excluded.indexOf(dateAdapter.getDay(viewEnd)) > -1 &&
        viewEnd > viewStart
      ) {
        viewEnd = dateAdapter.subDays(viewEnd, 1);
      }
      return { viewStart, viewEnd };
    }
  }
  //copy 'angular-calendar/modules/common/util'  
  export function addDaysWithExclusions(
    dateAdapter: DateAdapter,
    date: Date,
    days: number,
    excluded: number[]
  ): Date {
    let daysCounter = 0;
    let daysToAdd = 0;
    const changeDays = days < 0 ? dateAdapter.subDays : dateAdapter.addDays;
    let result = date;
    while (daysToAdd <= Math.abs(days)) {
      result = changeDays(date, daysCounter);
      const day = dateAdapter.getDay(result);
      if (excluded.indexOf(day) === -1) {
        daysToAdd++;
      }
      daysCounter++;
    }
    return result;
  }

  //copy 'angular-calendar/modules/common/util'  
  export function getDefaultEventEnd(
    dateAdapter: DateAdapter,
    event: CalendarEvent,
    minimumMinutes: number
  ): Date {
    if (event.end) {
      return event.end;
    } else {
      return dateAdapter.addMinutes(event.start, minimumMinutes);
    }
  }
// #endregion copy 'angular-calendar/modules/common/util'
