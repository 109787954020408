import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateService {
  private readonly iso8601Regex = new RegExp(/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)(\.\d+)?([+-][0-2]\d:[0-5]\d|Z)/);

  add(amount?: moment.DurationInputArg1, unit?: moment.DurationInputArg2, date?: string): string {
    return this.getDate(date).add(amount, unit).toISOString();
  }

  subtract(amount?: moment.DurationInputArg1, unit?: moment.DurationInputArg2, date?: string): string {
    return this.getDate(date).subtract(amount, unit).toISOString();
  }

  startOf(unitOfTime: moment.unitOfTime.StartOf, date?: string): string {
    return this.getDate(date).startOf(unitOfTime).toISOString();
  }

  endOf(unitOfTime: moment.unitOfTime.StartOf, date?: string): string {
    return this.getDate(date).endOf(unitOfTime).toISOString();
  }

  now(): string {
    return this.getDate().toISOString();
  }

  format(date: string, format?: string): string {
    return this.getDate(date).format(format);
  }

  private getDate(date?: string) {
    if (date) {
      return moment.utc(date);
    } else {
      // get the current local date as if it was utc
      // mimicking how we setup the date controls
      return moment().utcOffset(0, true).utc();
    }
  }

  private stringToDate(date?: string): Date {
    if (!date) {
      return null;
    }
    const parsedDateParts = this.getDate(date).parsingFlags().parsedDateParts;
    return new Date(parsedDateParts[0],
      parsedDateParts[1],
      parsedDateParts[2],
      parsedDateParts[3],
      parsedDateParts[4],
      parsedDateParts[5],
      parsedDateParts[6]);
  }

  public transformUTCDateDeep(objOrArr?: { [key: string]: any } | any[], parse = false) {
    if (!objOrArr) {
      return;
    }

    Object.keys(objOrArr).forEach(key => {
      const value = objOrArr[key];
      if (typeof value === 'string' && value.endsWith('Z') && this.iso8601Regex.test(value)) {
        // objOrArr[key] = parse ? this.stringToDate(value) : value.substring(0, value.length - 1);
        if (parse) {
          objOrArr[key] = this.stringToDate(value);
        } else {
          // Function to replace missing milliseconds or adjust to 7 digits
          const replaceWithMilliseconds = (match: string, datetimePart: string, millisecondsPart: string, timezonePart: string) => {
            let filledMilliseconds;
            if (millisecondsPart) {
              // If milliseconds are present, pad or trim to 7 digits
              filledMilliseconds = millisecondsPart.padEnd(8, '0').substring(0, 8); // Ensure 7 digits
            } else {
              // If milliseconds are missing, add .0000000
              filledMilliseconds = '.0000000';
            }
            return `${datetimePart}${filledMilliseconds}${timezonePart}`;
          };

          // Substitute the datetime string with the corrected milliseconds
          const temp = value.replace(this.iso8601Regex, replaceWithMilliseconds);
          objOrArr[key] = temp.substring(0, temp.length - 1);
        }
      } else if (typeof value === 'object') {
        this.transformUTCDateDeep(value);
      }
    })
  }

  public isISODateString(value: string): boolean {
    return value.endsWith('Z') && this.iso8601Regex.test(value) && moment(value, moment.ISO_8601, true).isValid();
  }

  public dateToString(date?: Date): string {
    if (date) {
      return moment(date).utcOffset(0, true).toISOString();
    } else {
      return null;
    }
  }
}
