import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Waves_ds_pick_waves_detailsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { WaveId: number }): 
  Promise<{ result: { Id?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }[], Tasks?: { Id?: number, ChainHead?: number, OperationCodeId?: number, StatusId?: number, PickSlip?: { Id?: number } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.WaveId)) {
      missingRequiredInParams.push('\'WaveId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_pick_waves_details/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { WaveId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }[], Tasks?: { Id?: number, ChainHead?: number, OperationCodeId?: number, StatusId?: number, PickSlip?: { Id?: number } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.WaveId)) {
      missingRequiredInParams.push('\'WaveId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_pick_waves_details/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { WaveId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }[], Tasks?: { Id?: number, ChainHead?: number, OperationCodeId?: number, StatusId?: number, PickSlip?: { Id?: number } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.WaveId)) {
      missingRequiredInParams.push('\'WaveId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_pick_waves_details/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
