import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { ExcelLocationImport_ds_get_locationsService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_get_locations_by_name_and_warehouseIdService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_get_locationstatuses_by_namesService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_get_locationtypes_by_namesService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_get_measurementunits_by_shortnamesService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_get_mixingrestrictions_by_namesService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_get_storagecategories_by_lookupcodeService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_get_warehouses_by_namesService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_location_import_gridService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_materialstatuses_ddService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_measurement_units_ddService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_storage_rules_ddService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_warehouses_ddService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_ds_zones_ddService } from './ExcelLocationImport.datasource.index';
import { ExcelLocationImport_mixing_rule_selectorService } from './ExcelLocationImport.datasource.index';

@Injectable({ providedIn: 'root' })
export class ExcelLocationImport_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public ExcelLocationImport: ExcelLocationImport_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_locations: ExcelLocationImport_ds_get_locationsService;
  public get ds_get_locations(): ExcelLocationImport_ds_get_locationsService {
    if(!this._ds_get_locations) {
      this._ds_get_locations = this.injector.get(ExcelLocationImport_ds_get_locationsService);
    }
    return this._ds_get_locations;
  }
  private _ds_get_locations_by_name_and_warehouseId: ExcelLocationImport_ds_get_locations_by_name_and_warehouseIdService;
  public get ds_get_locations_by_name_and_warehouseId(): ExcelLocationImport_ds_get_locations_by_name_and_warehouseIdService {
    if(!this._ds_get_locations_by_name_and_warehouseId) {
      this._ds_get_locations_by_name_and_warehouseId = this.injector.get(ExcelLocationImport_ds_get_locations_by_name_and_warehouseIdService);
    }
    return this._ds_get_locations_by_name_and_warehouseId;
  }
  private _ds_get_locationstatuses_by_names: ExcelLocationImport_ds_get_locationstatuses_by_namesService;
  public get ds_get_locationstatuses_by_names(): ExcelLocationImport_ds_get_locationstatuses_by_namesService {
    if(!this._ds_get_locationstatuses_by_names) {
      this._ds_get_locationstatuses_by_names = this.injector.get(ExcelLocationImport_ds_get_locationstatuses_by_namesService);
    }
    return this._ds_get_locationstatuses_by_names;
  }
  private _ds_get_locationtypes_by_names: ExcelLocationImport_ds_get_locationtypes_by_namesService;
  public get ds_get_locationtypes_by_names(): ExcelLocationImport_ds_get_locationtypes_by_namesService {
    if(!this._ds_get_locationtypes_by_names) {
      this._ds_get_locationtypes_by_names = this.injector.get(ExcelLocationImport_ds_get_locationtypes_by_namesService);
    }
    return this._ds_get_locationtypes_by_names;
  }
  private _ds_get_measurementunits_by_shortnames: ExcelLocationImport_ds_get_measurementunits_by_shortnamesService;
  public get ds_get_measurementunits_by_shortnames(): ExcelLocationImport_ds_get_measurementunits_by_shortnamesService {
    if(!this._ds_get_measurementunits_by_shortnames) {
      this._ds_get_measurementunits_by_shortnames = this.injector.get(ExcelLocationImport_ds_get_measurementunits_by_shortnamesService);
    }
    return this._ds_get_measurementunits_by_shortnames;
  }
  private _ds_get_mixingrestrictions_by_names: ExcelLocationImport_ds_get_mixingrestrictions_by_namesService;
  public get ds_get_mixingrestrictions_by_names(): ExcelLocationImport_ds_get_mixingrestrictions_by_namesService {
    if(!this._ds_get_mixingrestrictions_by_names) {
      this._ds_get_mixingrestrictions_by_names = this.injector.get(ExcelLocationImport_ds_get_mixingrestrictions_by_namesService);
    }
    return this._ds_get_mixingrestrictions_by_names;
  }
  private _ds_get_storagecategories_by_lookupcode: ExcelLocationImport_ds_get_storagecategories_by_lookupcodeService;
  public get ds_get_storagecategories_by_lookupcode(): ExcelLocationImport_ds_get_storagecategories_by_lookupcodeService {
    if(!this._ds_get_storagecategories_by_lookupcode) {
      this._ds_get_storagecategories_by_lookupcode = this.injector.get(ExcelLocationImport_ds_get_storagecategories_by_lookupcodeService);
    }
    return this._ds_get_storagecategories_by_lookupcode;
  }
  private _ds_get_warehouses_by_names: ExcelLocationImport_ds_get_warehouses_by_namesService;
  public get ds_get_warehouses_by_names(): ExcelLocationImport_ds_get_warehouses_by_namesService {
    if(!this._ds_get_warehouses_by_names) {
      this._ds_get_warehouses_by_names = this.injector.get(ExcelLocationImport_ds_get_warehouses_by_namesService);
    }
    return this._ds_get_warehouses_by_names;
  }
  private _ds_location_import_grid: ExcelLocationImport_ds_location_import_gridService;
  public get ds_location_import_grid(): ExcelLocationImport_ds_location_import_gridService {
    if(!this._ds_location_import_grid) {
      this._ds_location_import_grid = this.injector.get(ExcelLocationImport_ds_location_import_gridService);
    }
    return this._ds_location_import_grid;
  }
  private _ds_materialstatuses_dd: ExcelLocationImport_ds_materialstatuses_ddService;
  public get ds_materialstatuses_dd(): ExcelLocationImport_ds_materialstatuses_ddService {
    if(!this._ds_materialstatuses_dd) {
      this._ds_materialstatuses_dd = this.injector.get(ExcelLocationImport_ds_materialstatuses_ddService);
    }
    return this._ds_materialstatuses_dd;
  }
  private _ds_measurement_units_dd: ExcelLocationImport_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): ExcelLocationImport_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(ExcelLocationImport_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_storage_rules_dd: ExcelLocationImport_ds_storage_rules_ddService;
  public get ds_storage_rules_dd(): ExcelLocationImport_ds_storage_rules_ddService {
    if(!this._ds_storage_rules_dd) {
      this._ds_storage_rules_dd = this.injector.get(ExcelLocationImport_ds_storage_rules_ddService);
    }
    return this._ds_storage_rules_dd;
  }
  private _ds_warehouses_dd: ExcelLocationImport_ds_warehouses_ddService;
  public get ds_warehouses_dd(): ExcelLocationImport_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(ExcelLocationImport_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
  private _ds_zones_dd: ExcelLocationImport_ds_zones_ddService;
  public get ds_zones_dd(): ExcelLocationImport_ds_zones_ddService {
    if(!this._ds_zones_dd) {
      this._ds_zones_dd = this.injector.get(ExcelLocationImport_ds_zones_ddService);
    }
    return this._ds_zones_dd;
  }
  private _mixing_rule_selector: ExcelLocationImport_mixing_rule_selectorService;
  public get mixing_rule_selector(): ExcelLocationImport_mixing_rule_selectorService {
    if(!this._mixing_rule_selector) {
      this._mixing_rule_selector = this.injector.get(ExcelLocationImport_mixing_rule_selectorService);
    }
    return this._mixing_rule_selector;
  }
}

