import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_billing_rates_gridComponent } from './FootPrintManager.billing_rates_grid.component';
import { FootPrintManager_billing_projects_gridComponent } from './FootPrintManager.billing_projects_grid.component';
import { FootPrintManager_billing_supplemental_fees_gridComponent } from './FootPrintManager.billing_supplemental_fees_grid.component';
import { Invoices_billing_accessorial_charges_templates_dd_singleComponent } from './Invoices.billing_accessorial_charges_templates_dd_single.component'
import { Invoices_operation_codes_dd_singleComponent } from './Invoices.operation_codes_dd_single.component'
import { Invoices_billingcodes_dd_singleComponent } from './Invoices.billingcodes_dd_single.component'
import { Invoices_measurement_units_dd_singleComponent } from './Invoices.measurement_units_dd_single.component'
import { Invoices_warehouses_dd_singleComponent } from './Invoices.warehouses_dd_single.component'
import { Invoices_billing_contract_line_date_range_singleComponent } from './Invoices.billing_contract_line_date_range_single.component'
import { Invoices_recurring_storage_billing_objects_dd_singleComponent } from './Invoices.recurring_storage_billing_objects_dd_single.component'
import { Invoices_recurring_storage_schedule_types_dd_singleComponent } from './Invoices.recurring_storage_schedule_types_dd_single.component'
import { Invoices_billing_frequency_dd_singleComponent } from './Invoices.billing_frequency_dd_single.component'
import { Invoices_billing_weekdays_dd_multiComponent } from './Invoices.billing_weekdays_dd_multi.component'
import { FootPrintManager_hourly_options_dd_singleComponent } from './FootPrintManager.hourly_options_dd_single.component'
import { Invoices_billing_recurring_grace_periods_dd_singleComponent } from './Invoices.billing_recurring_grace_periods_dd_single.component'
import { Invoices_billing_objects_dd_singleComponent } from './Invoices.billing_objects_dd_single.component'
import { Invoices_billing_methods_dd_singleComponent } from './Invoices.billing_methods_dd_single.component'
import { Invoices_billing_days_remaining_dd_singleComponent } from './Invoices.billing_days_remaining_dd_single.component'
import { Invoices_billing_initiating_date_dd_singleComponent } from './Invoices.billing_initiating_date_dd_single.component'
import { Invoices_billing_grace_periods_dd_singleComponent } from './Invoices.billing_grace_periods_dd_single.component'
import { Materials_material_groups_dd_singleComponent } from './Materials.material_groups_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Invoices_tags_dd_multiComponent } from './Invoices.tags_dd_multi.component'
import { Invoices_location_storage_categories_dd_singleComponent } from './Invoices.location_storage_categories_dd_single.component'
import { Invoices_billing_outbound_shipment_service_type_methods_dd_singleComponent } from './Invoices.billing_outbound_shipment_service_type_methods_dd_single.component'
import { Invoices_carrier_service_types_dd_singleComponent } from './Invoices.carrier_service_types_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_billing_rates_gridComponent),
    forwardRef(() => FootPrintManager_billing_projects_gridComponent),
    forwardRef(() => FootPrintManager_billing_supplemental_fees_gridComponent),
    forwardRef(() => Invoices_billing_accessorial_charges_templates_dd_singleComponent),
    forwardRef(() => Invoices_operation_codes_dd_singleComponent),
    forwardRef(() => Invoices_billingcodes_dd_singleComponent),
    forwardRef(() => Invoices_measurement_units_dd_singleComponent),
    forwardRef(() => Invoices_warehouses_dd_singleComponent),
    forwardRef(() => Invoices_billing_contract_line_date_range_singleComponent),
    forwardRef(() => Invoices_recurring_storage_billing_objects_dd_singleComponent),
    forwardRef(() => Invoices_recurring_storage_schedule_types_dd_singleComponent),
    forwardRef(() => Invoices_billing_frequency_dd_singleComponent),
    forwardRef(() => Invoices_billing_weekdays_dd_multiComponent),
    forwardRef(() => FootPrintManager_hourly_options_dd_singleComponent),
    forwardRef(() => Invoices_billing_recurring_grace_periods_dd_singleComponent),
    forwardRef(() => Invoices_billing_objects_dd_singleComponent),
    forwardRef(() => Invoices_billing_methods_dd_singleComponent),
    forwardRef(() => Invoices_billing_days_remaining_dd_singleComponent),
    forwardRef(() => Invoices_billing_initiating_date_dd_singleComponent),
    forwardRef(() => Invoices_billing_grace_periods_dd_singleComponent),
    forwardRef(() => Materials_material_groups_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Invoices_tags_dd_multiComponent),
    forwardRef(() => Invoices_location_storage_categories_dd_singleComponent),
    forwardRef(() => Invoices_billing_outbound_shipment_service_type_methods_dd_singleComponent),
    forwardRef(() => Invoices_carrier_service_types_dd_singleComponent),
  ],
  selector: 'FootPrintManager-billing_contract_line_editor',
  templateUrl: './FootPrintManager.billing_contract_line_editor.component.html'
})
export class FootPrintManager_billing_contract_line_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { billingContractLineId: number, edit: boolean, operationCodeId?: number, billingAggregationStrategy?: string, statusId?: number } = { billingContractLineId: null, edit: null, operationCodeId: null, billingAggregationStrategy: null, statusId: null };
  //#region Inputs
  @Input('billingContractLineId') set $inParams_billingContractLineId(v: number) {
    this.inParams.billingContractLineId = v;
  }
  get $inParams_billingContractLineId(): number {
    return this.inParams.billingContractLineId;
  }
  @Input('edit') set $inParams_edit(v: boolean) {
    this.inParams.edit = v;
  }
  get $inParams_edit(): boolean {
    return this.inParams.edit;
  }
  @Input('operationCodeId') set $inParams_operationCodeId(v: number) {
    this.inParams.operationCodeId = v;
  }
  get $inParams_operationCodeId(): number {
    return this.inParams.operationCodeId;
  }
  @Input('billingAggregationStrategy') set $inParams_billingAggregationStrategy(v: string) {
    this.inParams.billingAggregationStrategy = v;
  }
  get $inParams_billingAggregationStrategy(): string {
    return this.inParams.billingAggregationStrategy;
  }
  @Input('statusId') set $inParams_statusId(v: number) {
    this.inParams.statusId = v;
  }
  get $inParams_statusId(): number {
    return this.inParams.statusId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { updated?: boolean } = { updated: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { operationCodeId?: number, operationTypeId?: number[], details?: { BillingContractLineId?: number, StrategyFieldName?: string, SerializedValueEntity?: string, Value?: string }[], error?: boolean, reason?: string[], uom_type_id?: number[], uom_id?: number[], loading?: boolean, weight_measure?: boolean, billingMethodsToInclude?: number[], recurringSchedulesToInclude?: number[], line_updated?: boolean, line_changed?: boolean } = { };
  //#endregion
  entity: { Id?: number, BasePackageConversion?: boolean, BillingAggregationStrategyId?: number, BillingContractId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomBillingWorkflowId?: string, EndDate?: string, LineNumber?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ResultingBillingCodeId?: number, ResultingUomId?: number, StartDate?: string, WarehouseId?: number, BillingAggregationStrategy?: { Name?: string }, ResultingUom?: { Name?: string }, ResultingBillingCode?: { Name?: string }, Warehouse?: { Name?: string }, BillingRates?: { Rate?: number }[], Details?: { Value?: string }[] };

  formGroup: FormGroup = new FormGroup({
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    line_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    line_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    billing_aggregation_strategy: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    accessorial_template: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    operation_code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    billing_code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    resulting_uom: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    start_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    end_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    base_package_conversion: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    date_range: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_billing_object: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_schedule: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_frequency: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_day_interval: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_week_interval: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_days: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_month_day: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_month_interval: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_time: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    recurring_anniversary_grace_period: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    recurring_anniversary_grace_period_value: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_anniversary_grace_period_option: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_advanced_billing: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    recurring_advanced_grace_period: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    recurring_advanced_grace_period_value: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    recurring_advanced_grace_period_option: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    billing_object: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    billing_method: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    resulting_uom_bm: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    enable_divide_by: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    divide_by: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    static_quantity: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    enable_weight_measure: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    dim_factor: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    volume_uom: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    days_remaining: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    initiating_date: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    grace_period: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    grace_period_value: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material_group: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material_package: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material_tags: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    location_tags: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    recurring_location_storage: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    service_type_method: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    carrier_service_type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    carrier_package: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    retainer_amount: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    retainer_rate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    retainer_frequency: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    retainer_day_interval: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    retainer_week_interval: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    retainer_days: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    retainer_month_day: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    retainer_month_interval: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    retainer_time: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      save_line: new ToolModel(new ButtonModel('save_line', new ButtonStyles(['primary'], null), false, 'Save and close', 'icon-ic_fluent_save_20_regular')
    ),
      close: new ToolModel(new ButtonModel('close', new ButtonStyles(['primary'], null), false, 'Close', 'icon-ic_fluent_dismiss_circle_20_regular')
    )
  };

  fields = {
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), '', false)
,
    line_id: new FieldModel(new NumberBoxModel(this.formGroup.controls['line_id'] as DatexFormControl, null, true, '', '')
, new ControlContainerStyles(null, null), 'Line id', false)
,
    line_number: new FieldModel(new NumberBoxModel(this.formGroup.controls['line_number'] as DatexFormControl, null, true, '', '')
, new ControlContainerStyles(null, null), 'Line number', false)
,
    billing_aggregation_strategy: new FieldModel(new TextBoxModel(this.formGroup.controls['billing_aggregation_strategy'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Billing aggregation strategy', false)
,
    accessorial_template: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['accessorial_template'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Accessorial charges template', true)
,
    operation_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['operation_code'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Operation code', true)
,
    billing_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['billing_code'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Billing code', true)
,
    resulting_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['resulting_uom'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Unit of measure', true)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
    start_date: new FieldModel(new DateBoxModel(this.formGroup.controls['start_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Start date', false)
,
    end_date: new FieldModel(new DateBoxModel(this.formGroup.controls['end_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'End date', false)
,
    base_package_conversion: new FieldModel(new CheckBoxModel(this.formGroup.controls['base_package_conversion'] as DatexFormControl, null, false, 'Base package conversion')
, new ControlContainerStyles(null, null), '', false)
,
    date_range: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_range'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Date range', true)
,
    recurring_billing_object: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['recurring_billing_object'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Billing object', true)
,
    recurring_schedule: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['recurring_schedule'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Schedule', true)
,
    recurring_frequency: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['recurring_frequency'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Frequency', true)
,
    recurring_day_interval: new FieldModel(new NumberBoxModel(this.formGroup.controls['recurring_day_interval'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Daily interval', true)
,
    recurring_week_interval: new FieldModel(new NumberBoxModel(this.formGroup.controls['recurring_week_interval'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Weekly interval', true)
,
    recurring_days: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['recurring_days'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Occur on', true)
,
    recurring_month_day: new FieldModel(new NumberBoxModel(this.formGroup.controls['recurring_month_day'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Day of the month', true)
,
    recurring_month_interval: new FieldModel(new NumberBoxModel(this.formGroup.controls['recurring_month_interval'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Monthly interval', true)
,
    recurring_time: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['recurring_time'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Execute time', false)
,
    recurring_anniversary_grace_period: new FieldModel(new CheckBoxModel(this.formGroup.controls['recurring_anniversary_grace_period'] as DatexFormControl, null, false, 'Anniversary grace period')
, new ControlContainerStyles(null, null), '', false)
,
    recurring_anniversary_grace_period_value: new FieldModel(new NumberBoxModel(this.formGroup.controls['recurring_anniversary_grace_period_value'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Grace period value', true)
,
    recurring_anniversary_grace_period_option: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['recurring_anniversary_grace_period_option'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Grace period option', true)
,
    recurring_advanced_billing: new FieldModel(new CheckBoxModel(this.formGroup.controls['recurring_advanced_billing'] as DatexFormControl, null, false, 'Advance billing (executes at 23:59:59)')
, new ControlContainerStyles(null, null), '', false)
,
    recurring_advanced_grace_period: new FieldModel(new CheckBoxModel(this.formGroup.controls['recurring_advanced_grace_period'] as DatexFormControl, null, false, 'Grace period')
, new ControlContainerStyles(null, null), '', false)
,
    recurring_advanced_grace_period_value: new FieldModel(new NumberBoxModel(this.formGroup.controls['recurring_advanced_grace_period_value'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Grace period value', true)
,
    recurring_advanced_grace_period_option: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['recurring_advanced_grace_period_option'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Grace period option', true)
,
    billing_object: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['billing_object'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Billing object', true)
,
    billing_method: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['billing_method'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Billing method', true)
,
    resulting_uom_bm: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['resulting_uom_bm'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Unit of measure', true)
,
    enable_divide_by: new FieldModel(new CheckBoxModel(this.formGroup.controls['enable_divide_by'] as DatexFormControl, null, false, 'Enable divide by calculation')
, new ControlContainerStyles(null, null), '', false)
,
    divide_by: new FieldModel(new NumberBoxModel(this.formGroup.controls['divide_by'] as DatexFormControl, null, false, '0.0', '')
, new ControlContainerStyles(null, null), 'Divide by', true)
,
    static_quantity: new FieldModel(new NumberBoxModel(this.formGroup.controls['static_quantity'] as DatexFormControl, null, false, '0.0', '')
, new ControlContainerStyles(null, null), 'Static quantity', true)
,
    enable_weight_measure: new FieldModel(new CheckBoxModel(this.formGroup.controls['enable_weight_measure'] as DatexFormControl, null, false, 'Enable weight measure')
, new ControlContainerStyles(null, null), '', false)
,
    dim_factor: new FieldModel(new NumberBoxModel(this.formGroup.controls['dim_factor'] as DatexFormControl, null, false, '0.0', '')
, new ControlContainerStyles(null, null), 'Dim factor', true)
,
    volume_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['volume_uom'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Volume unit of measure', true)
,
    days_remaining: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['days_remaining'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Days remaining', true)
,
    initiating_date: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['initiating_date'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Initiating date', true)
,
    grace_period: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['grace_period'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Grace period option', true)
,
    grace_period_value: new FieldModel(new NumberBoxModel(this.formGroup.controls['grace_period_value'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Grace period value', false)
,
    material_group: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material_group'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Material group', false)
,
    material_package: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material_package'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Material package', false)
,
    material_tags: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material_tags'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Material tags', false)
,
    location_tags: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location_tags'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Location tags', false)
,
    recurring_location_storage: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['recurring_location_storage'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Location storage category', false)
,
    service_type_method: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['service_type_method'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Service type method', true)
,
    carrier_service_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier_service_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Carrier service type', true)
,
    carrier_package: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier_package'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Material package', false)
,
    retainer_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['retainer_amount'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Billing amount', true)
,
    retainer_rate: new FieldModel(new NumberBoxModel(this.formGroup.controls['retainer_rate'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Billing rate', true)
,
    retainer_frequency: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['retainer_frequency'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Frequency', true)
,
    retainer_day_interval: new FieldModel(new NumberBoxModel(this.formGroup.controls['retainer_day_interval'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Daily interval', true)
,
    retainer_week_interval: new FieldModel(new NumberBoxModel(this.formGroup.controls['retainer_week_interval'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Weekly interval', true)
,
    retainer_days: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['retainer_days'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Occur on', true)
,
    retainer_month_day: new FieldModel(new NumberBoxModel(this.formGroup.controls['retainer_month_day'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Day of the month', true)
,
    retainer_month_interval: new FieldModel(new NumberBoxModel(this.formGroup.controls['retainer_month_interval'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Monthly interval', true)
,
    retainer_time: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['retainer_time'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Execute time', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), '', false)
,
  };

  fieldsets = {
  description: new FieldsetModel('Strategy description', false, true, false),
  basic_information: new FieldsetModel('Basic Information', false, true, true),
  recurring_storage: new FieldsetModel('Recurring storage', false, true, true),
  billing_methods: new FieldsetModel('Billing methods', false, true, true),
  grace_period_billing: new FieldsetModel('Grace period billing', false, true, true),
  material_information: new FieldsetModel('Material information', false, true, false),
  location_information: new FieldsetModel('Location information', false, true, false),
  carrier_billing_methods: new FieldsetModel('Carrier billing methods', false, true, true),
  retainer_billing: new FieldsetModel('Retainer billing', false, true, true),
  notes: new FieldsetModel('Notes', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      billing_rates: new TabItemModel(
        this.rootTabGroup, 
        'Billing rates', 
        ),
      weight_rates: new TabItemModel(
        this.rootTabGroup, 
        'Weight rates', 
        ),
      volume_rates: new TabItemModel(
        this.rootTabGroup, 
        'Volume rates', 
        ),
      billing_projects: new TabItemModel(
        this.rootTabGroup, 
        'Bill to projects', 
        ),
      supplemental_fees: new TabItemModel(
        this.rootTabGroup, 
        'Supplemental fees', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_billing_rates_billing_rates_grid_inParams_billingContractLineId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.billingContractLineId;
      
      return expr;
    }
  
    get $tabs_billing_rates_billing_rates_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.edit;
      
      return expr;
    }
  
    get $tabs_billing_rates_billing_rates_grid_inParams_typeId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = 1;
      
      return expr;
    }
  
    get $tabs_weight_rates_billing_rates_grid_inParams_billingContractLineId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.billingContractLineId;
      
      return expr;
    }
  
    get $tabs_weight_rates_billing_rates_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.edit;
      
      return expr;
    }
  
    get $tabs_weight_rates_billing_rates_grid_inParams_typeId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = 2;
      
      return expr;
    }
  
    get $tabs_volume_rates_billing_rates_grid_inParams_billingContractLineId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.billingContractLineId;
      
      return expr;
    }
  
    get $tabs_volume_rates_billing_rates_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.edit;
      
      return expr;
    }
  
    get $tabs_volume_rates_billing_rates_grid_inParams_typeId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = 3;
      
      return expr;
    }
  
    get $tabs_billing_projects_billing_projects_grid_inParams_billingContractLineId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.billingContractLineId;
      
      return expr;
    }
  
    get $tabs_billing_projects_billing_projects_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.edit;
      
      return expr;
    }
  
    get $tabs_supplemental_fees_billing_supplemental_fees_grid_inParams_billingContractLineId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.billingContractLineId;
      
      return expr;
    }
  
    get $tabs_supplemental_fees_billing_supplemental_fees_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.edit;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_billing_rates', { read: FootPrintManager_billing_rates_gridComponent }) $tabs_billing_rates: FootPrintManager_billing_rates_gridComponent;
      @ViewChild('$tabs_weight_rates', { read: FootPrintManager_billing_rates_gridComponent }) $tabs_weight_rates: FootPrintManager_billing_rates_gridComponent;
      @ViewChild('$tabs_volume_rates', { read: FootPrintManager_billing_rates_gridComponent }) $tabs_volume_rates: FootPrintManager_billing_rates_gridComponent;
      @ViewChild('$tabs_billing_projects', { read: FootPrintManager_billing_projects_gridComponent }) $tabs_billing_projects: FootPrintManager_billing_projects_gridComponent;
      @ViewChild('$tabs_supplemental_fees', { read: FootPrintManager_billing_supplemental_fees_gridComponent }) $tabs_supplemental_fees: FootPrintManager_billing_supplemental_fees_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_accessorial_template_selector_inParams_enabled(): boolean {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = true;
    
    return expr;
  }

  cacheValueFor_$fields_operation_code_selector_inParams_operationTypeId: number[];
  get $fields_operation_code_selector_inParams_operationTypeId(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.operationTypeId;
    
    if(!isEqual(this.cacheValueFor_$fields_operation_code_selector_inParams_operationTypeId, expr)) {
      this.cacheValueFor_$fields_operation_code_selector_inParams_operationTypeId = expr;
    }
    return this.cacheValueFor_$fields_operation_code_selector_inParams_operationTypeId;
  }

  cacheValueFor_$fields_resulting_uom_selector_inParams_id: number[];
  get $fields_resulting_uom_selector_inParams_id(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.uom_id;
    
    if(!isEqual(this.cacheValueFor_$fields_resulting_uom_selector_inParams_id, expr)) {
      this.cacheValueFor_$fields_resulting_uom_selector_inParams_id = expr;
    }
    return this.cacheValueFor_$fields_resulting_uom_selector_inParams_id;
  }

  cacheValueFor_$fields_resulting_uom_selector_inParams_typeId: number[];
  get $fields_resulting_uom_selector_inParams_typeId(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.uom_type_id;
    
    if(!isEqual(this.cacheValueFor_$fields_resulting_uom_selector_inParams_typeId, expr)) {
      this.cacheValueFor_$fields_resulting_uom_selector_inParams_typeId = expr;
    }
    return this.cacheValueFor_$fields_resulting_uom_selector_inParams_typeId;
  }

  cacheValueFor_$fields_recurring_schedule_selector_inParams_ids: number[];
  get $fields_recurring_schedule_selector_inParams_ids(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.recurringSchedulesToInclude;
    
    if(!isEqual(this.cacheValueFor_$fields_recurring_schedule_selector_inParams_ids, expr)) {
      this.cacheValueFor_$fields_recurring_schedule_selector_inParams_ids = expr;
    }
    return this.cacheValueFor_$fields_recurring_schedule_selector_inParams_ids;
  }

  cacheValueFor_$fields_billing_method_selector_inParams_idsToInclude: number[];
  get $fields_billing_method_selector_inParams_idsToInclude(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.billingMethodsToInclude;
    
    if(!isEqual(this.cacheValueFor_$fields_billing_method_selector_inParams_idsToInclude, expr)) {
      this.cacheValueFor_$fields_billing_method_selector_inParams_idsToInclude = expr;
    }
    return this.cacheValueFor_$fields_billing_method_selector_inParams_idsToInclude;
  }

  cacheValueFor_$fields_resulting_uom_bm_selector_inParams_id: number[];
  get $fields_resulting_uom_bm_selector_inParams_id(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.uom_id;
    
    if(!isEqual(this.cacheValueFor_$fields_resulting_uom_bm_selector_inParams_id, expr)) {
      this.cacheValueFor_$fields_resulting_uom_bm_selector_inParams_id = expr;
    }
    return this.cacheValueFor_$fields_resulting_uom_bm_selector_inParams_id;
  }

  cacheValueFor_$fields_resulting_uom_bm_selector_inParams_typeId: number[];
  get $fields_resulting_uom_bm_selector_inParams_typeId(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.uom_type_id;
    
    if(!isEqual(this.cacheValueFor_$fields_resulting_uom_bm_selector_inParams_typeId, expr)) {
      this.cacheValueFor_$fields_resulting_uom_bm_selector_inParams_typeId = expr;
    }
    return this.cacheValueFor_$fields_resulting_uom_bm_selector_inParams_typeId;
  }

  cacheValueFor_$fields_volume_uom_selector_inParams_typeId: number[];
  get $fields_volume_uom_selector_inParams_typeId(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = [2];
    
    if(!isEqual(this.cacheValueFor_$fields_volume_uom_selector_inParams_typeId, expr)) {
      this.cacheValueFor_$fields_volume_uom_selector_inParams_typeId = expr;
    }
    return this.cacheValueFor_$fields_volume_uom_selector_inParams_typeId;
  }

  get $fields_material_tags_selector_inParams_entityTypeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 2;
    
    return expr;
  }

  get $fields_location_tags_selector_inParams_entityTypeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    description: this.fields.description.control.valueChanges
    ,
    line_id: this.fields.line_id.control.valueChanges
    ,
    line_number: this.fields.line_number.control.valueChanges
    ,
    billing_aggregation_strategy: this.fields.billing_aggregation_strategy.control.valueChanges
    ,
    accessorial_template: this.fields.accessorial_template.control.valueChanges
    ,
    operation_code: this.fields.operation_code.control.valueChanges
    ,
    billing_code: this.fields.billing_code.control.valueChanges
    ,
    resulting_uom: this.fields.resulting_uom.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    start_date: this.fields.start_date.control.valueChanges
    ,
    end_date: this.fields.end_date.control.valueChanges
    ,
    base_package_conversion: this.fields.base_package_conversion.control.valueChanges
    ,
    date_range: this.fields.date_range.control.valueChanges
    ,
    recurring_billing_object: this.fields.recurring_billing_object.control.valueChanges
    ,
    recurring_schedule: this.fields.recurring_schedule.control.valueChanges
    ,
    recurring_frequency: this.fields.recurring_frequency.control.valueChanges
    ,
    recurring_day_interval: this.fields.recurring_day_interval.control.valueChanges
    ,
    recurring_week_interval: this.fields.recurring_week_interval.control.valueChanges
    ,
    recurring_days: this.fields.recurring_days.control.valueChanges
    ,
    recurring_month_day: this.fields.recurring_month_day.control.valueChanges
    ,
    recurring_month_interval: this.fields.recurring_month_interval.control.valueChanges
    ,
    recurring_time: this.fields.recurring_time.control.valueChanges
    ,
    recurring_anniversary_grace_period: this.fields.recurring_anniversary_grace_period.control.valueChanges
    ,
    recurring_anniversary_grace_period_value: this.fields.recurring_anniversary_grace_period_value.control.valueChanges
    ,
    recurring_anniversary_grace_period_option: this.fields.recurring_anniversary_grace_period_option.control.valueChanges
    ,
    recurring_advanced_billing: this.fields.recurring_advanced_billing.control.valueChanges
    ,
    recurring_advanced_grace_period: this.fields.recurring_advanced_grace_period.control.valueChanges
    ,
    recurring_advanced_grace_period_value: this.fields.recurring_advanced_grace_period_value.control.valueChanges
    ,
    recurring_advanced_grace_period_option: this.fields.recurring_advanced_grace_period_option.control.valueChanges
    ,
    billing_object: this.fields.billing_object.control.valueChanges
    ,
    billing_method: this.fields.billing_method.control.valueChanges
    ,
    resulting_uom_bm: this.fields.resulting_uom_bm.control.valueChanges
    ,
    enable_divide_by: this.fields.enable_divide_by.control.valueChanges
    ,
    divide_by: this.fields.divide_by.control.valueChanges
    ,
    static_quantity: this.fields.static_quantity.control.valueChanges
    ,
    enable_weight_measure: this.fields.enable_weight_measure.control.valueChanges
    ,
    dim_factor: this.fields.dim_factor.control.valueChanges
    ,
    volume_uom: this.fields.volume_uom.control.valueChanges
    ,
    days_remaining: this.fields.days_remaining.control.valueChanges
    ,
    initiating_date: this.fields.initiating_date.control.valueChanges
    ,
    grace_period: this.fields.grace_period.control.valueChanges
    ,
    grace_period_value: this.fields.grace_period_value.control.valueChanges
    ,
    material_group: this.fields.material_group.control.valueChanges
    ,
    material_package: this.fields.material_package.control.valueChanges
    ,
    material_tags: this.fields.material_tags.control.valueChanges
    ,
    location_tags: this.fields.location_tags.control.valueChanges
    ,
    recurring_location_storage: this.fields.recurring_location_storage.control.valueChanges
    ,
    service_type_method: this.fields.service_type_method.control.valueChanges
    ,
    carrier_service_type: this.fields.carrier_service_type.control.valueChanges
    ,
    carrier_package: this.fields.carrier_package.control.valueChanges
    ,
    retainer_amount: this.fields.retainer_amount.control.valueChanges
    ,
    retainer_rate: this.fields.retainer_rate.control.valueChanges
    ,
    retainer_frequency: this.fields.retainer_frequency.control.valueChanges
    ,
    retainer_day_interval: this.fields.retainer_day_interval.control.valueChanges
    ,
    retainer_week_interval: this.fields.retainer_week_interval.control.valueChanges
    ,
    retainer_days: this.fields.retainer_days.control.valueChanges
    ,
    retainer_month_day: this.fields.retainer_month_day.control.valueChanges
    ,
    retainer_month_interval: this.fields.retainer_month_interval.control.valueChanges
    ,
    retainer_time: this.fields.retainer_time.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.billing_rates,
      this.tabs.weight_rates,
      this.tabs.volume_rates,
      this.tabs.billing_projects,
      this.tabs.supplemental_fees,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.billingContractLineId)) {
        this.$missingRequiredInParams.push('billingContractLineId');
      }
      if(isNil(this.inParams.edit)) {
        this.$missingRequiredInParams.push('edit');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Billing Contract Line Editor';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      lineId:  $editor.inParams.billingContractLineId 
    };

    const data = await this.datasources.Invoices.ds_billing_contract_lines_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    
    (this.fields.line_id.control as NumberBoxModel).reset($editor.entity.Id);
    (this.fields.line_number.control as NumberBoxModel).reset($editor.entity.LineNumber);
    (this.fields.billing_aggregation_strategy.control as TextBoxModel).reset($editor.entity.BillingAggregationStrategy?.Name);
    (this.fields.billing_code.control as SelectBoxModel).reset($editor.entity.ResultingBillingCodeId);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.WarehouseId);
    (this.fields.start_date.control as DateBoxModel).reset($editor.entity.StartDate);
    (this.fields.end_date.control as DateBoxModel).reset($editor.entity.EndDate);
    (this.fields.base_package_conversion.control as CheckBoxModel).reset($editor.entity.BasePackageConversion);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_billing_rates') {
        if (!isNil(this.$tabs_billing_rates) && !this.tabs.billing_rates.hidden) {
          this.$tabs_billing_rates.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_weight_rates') {
        if (!isNil(this.$tabs_weight_rates) && !this.tabs.weight_rates.hidden) {
          this.$tabs_weight_rates.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_volume_rates') {
        if (!isNil(this.$tabs_volume_rates) && !this.tabs.volume_rates.hidden) {
          this.$tabs_volume_rates.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_billing_projects') {
        if (!isNil(this.$tabs_billing_projects) && !this.tabs.billing_projects.hidden) {
          this.$tabs_billing_projects.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_supplemental_fees') {
        if (!isNil(this.$tabs_supplemental_fees) && !this.tabs.supplemental_fees.hidden) {
          this.$tabs_supplemental_fees.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .description
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .line_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .line_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .billing_aggregation_strategy
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .accessorial_template
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .operation_code
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_operation_code_change();
      });
    this.$formGroupFieldValidationObservables
      .billing_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .resulting_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .start_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .end_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .base_package_conversion
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .date_range
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_billing_object
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_recurring_billing_object_change();
      });
    this.$formGroupFieldValidationObservables
      .recurring_schedule
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_recurring_schedule_type_change();
      });
    this.$formGroupFieldValidationObservables
      .recurring_frequency
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_recurring_frequency_change();
      });
    this.$formGroupFieldValidationObservables
      .recurring_day_interval
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_week_interval
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_days
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_month_day
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_month_interval
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_time
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_anniversary_grace_period
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_recurring_anniversary_change();
      });
    this.$formGroupFieldValidationObservables
      .recurring_anniversary_grace_period_value
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_anniversary_grace_period_option
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_advanced_billing
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_recurring_advanced_change();
      });
    this.$formGroupFieldValidationObservables
      .recurring_advanced_grace_period
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_recurring_grace_change();
      });
    this.$formGroupFieldValidationObservables
      .recurring_advanced_grace_period_value
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_advanced_grace_period_option
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .billing_object
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .billing_method
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_billing_method_change();
      });
    this.$formGroupFieldValidationObservables
      .resulting_uom_bm
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .enable_divide_by
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_billing_method_slide_change();
      });
    this.$formGroupFieldValidationObservables
      .divide_by
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .static_quantity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .enable_weight_measure
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_billing_method_slide_change();
      });
    this.$formGroupFieldValidationObservables
      .dim_factor
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .volume_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .days_remaining
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .initiating_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .grace_period
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_grace_period_change();
      });
    this.$formGroupFieldValidationObservables
      .grace_period_value
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material_group
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material_package
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material_tags
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .location_tags
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .recurring_location_storage
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .service_type_method
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_service_type_method_change();
      });
    this.$formGroupFieldValidationObservables
      .carrier_service_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .carrier_package
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .retainer_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .retainer_rate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .retainer_frequency
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_retainer_frequency_change();
      });
    this.$formGroupFieldValidationObservables
      .retainer_day_interval
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .retainer_week_interval
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .retainer_days
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .retainer_month_day
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .retainer_month_interval
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .retainer_time
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/02/2024
  
  let billing_aggregation_strategy_name = $utils.isDefined($editor.inParams.billingAggregationStrategy) ? $editor.inParams.billingAggregationStrategy : '';
  
  $editor.title = `Line ${$editor.entity.LineNumber} - ${billing_aggregation_strategy_name}`;
  
  let date_range = 0;
  let start_index = -1;
  let end_index = -1;
  let material_group_index = -1;
  let material_package_index = -1;
  let tags_index = -1;
  let billing_method_index = -1;
  let recurring_schedule_type_index = -1;
  let recurring_storage_object_index = -1;
  let recurring_schedule_index = -1;
  let recurring_location_storage_index = -1;
  let static_index = -1;
  let apply_weight_index = -1;
  let apply_divide_index = -1;
  let dim_value_index = -1;
  let dim_volume_index = -1;
  let divide_value_index = -1;
  let operation_index = -1;
  let grace_index = -1;
  let grace_days_index = -1;
  let days_index = -1;
  let billing_object_index = -1;
  let initiating_index = -1;
  let carrier_method_index = -1;
  let carrier_service_index = -1;
  let retainer_schedule_index = -1;
  let retainer_rate_index = -1;
  let weight_method_index = -1;
  let volume_method_index = -1;
  let accessorial_template_index = -1;
  
  //Get the billing contract line details
  const details = (await $datasources.Invoices.ds_billing_contract_line_details_grid.get({ billingContractLineId: $editor.inParams.billingContractLineId })).result;
  
  if ($utils.isDefined(details)) {
  
      $editor.vars.loading = true;
      $editor.vars.details = details;
  
      //Get the indexes for the fields we need to insert/update for the contract line
      const details_array = details.map(d => d.StrategyFieldName);
  
      operation_index = details_array.indexOf('OperationCodeId');
      start_index = details_array.indexOf('StartDayOfMonth');
      end_index = details_array.indexOf('EndDayOfMonth');
      material_group_index = details_array.indexOf('MaterialGroupId');
      material_package_index = details_array.indexOf('MaterialPackageId');
      billing_method_index = details_array.indexOf('BillingMethodId');
      recurring_schedule_type_index = details_array.indexOf('RecurringScheduleType');
      recurring_storage_object_index = details_array.indexOf('RecurringStorageBillingObjectId');
      recurring_schedule_index = details_array.indexOf('Schedule');
      recurring_location_storage_index = details_array.indexOf('LocationTemperatureCategoryId');
      static_index = details_array.indexOf('StaticQuantity');
      tags_index = details_array.indexOf('Tags');
      apply_weight_index = details_array.indexOf('ApplayWeightMeasureOption');
      dim_value_index = details_array.indexOf('DimFactorValue');
      dim_volume_index = details_array.indexOf('DimFactorVolumeUOM');
      apply_divide_index = details_array.indexOf('ApplyDivideByCalculation');
      divide_value_index = details_array.indexOf('DivideByCalculationValue');
      grace_index = details_array.indexOf('GracePeriod');
      grace_days_index = details_array.indexOf('GracePeriodDays');
      days_index = details_array.indexOf('DaysRemainingId');
      billing_object_index = details_array.indexOf('BillingObjectId');
      initiating_index = details_array.indexOf('InitiatingDateId');
      carrier_method_index = details_array.indexOf('BillingOutboundShipmentByCarrierServiceTypeMethodId');
      carrier_service_index = details_array.indexOf('CarrierServiceTypeId');
      retainer_schedule_index = details_array.indexOf('RetainerSchedule');
      retainer_rate_index = details_array.indexOf('RetainerRate');
      weight_method_index = details_array.indexOf('WeightMethodId');
      volume_method_index = details_array.indexOf('VolumeMethodId');
      accessorial_template_index = details_array.indexOf('BillingAccessorialChargesTemplateId');
  
      if (start_index >= 0 && end_index >= 0) {
  
          let start_day = details[start_index].Value;
          let end_day = details[end_index].Value;
  
          if (start_day === '1' && end_day === '15') {
              date_range = 1;
          } else if (start_day === '16' && end_day === '31') {
              date_range = 16;
          }
      }
  
      if (material_group_index >= 0) {
          $editor.fields.material_group.control.value = Number(details[material_group_index].Value);
      }
  
      if (material_package_index >= 0 && carrier_method_index === -1) {
          $editor.fields.material_package.control.value = Number(details[material_package_index].Value);
      }
  
      if (material_package_index >= 0 && carrier_method_index >= 0) {
          $editor.fields.carrier_package.control.value = Number(details[material_package_index].Value);
      }
  
      if (billing_object_index >= 0) {
          $editor.fields.billing_object.control.value = Number(details[billing_object_index].Value);
      }
  
      if (days_index >= 0) {
          $editor.fields.days_remaining.control.value = Number(details[days_index].Value);
      }
  
      if (carrier_method_index >= 0) {
          $editor.fields.service_type_method.control.value = Number(details[carrier_method_index].Value);
      }
  
      if (carrier_service_index >= 0) {
          $editor.fields.carrier_service_type.control.value = Number(details[carrier_service_index].Value);
      }
  
      if (grace_index >= 0) {
          $editor.fields.grace_period.control.value = Number(details[grace_index].Value);
          await $editor.on_grace_period_change();
  
          if (grace_days_index >= 0) {
  
              let grace_days = Number(details[grace_days_index].Value);
  
              let grace_period = 3;
  
              if ($utils.isDefined($editor.fields.grace_period)) {
                  if ($editor.fields.grace_period.control.value > 0) {
                      grace_period = $editor.fields.grace_period.control.value;
                  }
              }
  
              if (grace_period === 3) {
                  grace_days = null;
              } else if (grace_period === 2) {
                  grace_days = grace_days / 7;
              }
  
              $editor.fields.grace_period_value.control.value = grace_days;
          }
          if (initiating_index >= 0) {
              $editor.fields.initiating_date.control.value = Number(details[initiating_index].Value);
          }
      }
  
      if (billing_method_index >= 0 ||
          weight_method_index >= 0 ||
          volume_method_index >= 0) {
  
          let billing_method_id: number = null;
  
          if (billing_method_index >= 0) {
              billing_method_id = Number(details[billing_method_index].Value);
          }
  
          if (weight_method_index >= 0) {
              billing_method_id = Number(details[weight_method_index].Value);
              if (billing_method_id === 1) { //Gross
                  billing_method_id = 2;
              } else if (billing_method_id === 2) { //Net
                  billing_method_id = 1;
              }
          }
  
          if (volume_method_index >= 0) {
              billing_method_id = Number(details[volume_method_index].Value);
              if (billing_method_id === 1) { //Gross
                  billing_method_id = 3;
              } else if (billing_method_id === 2) { //Net
                  billing_method_id = 7;
              }
          }
  
          $editor.fields.billing_method.control.value = billing_method_id;
          $editor.vars.uom_id = null;
          await $editor.on_billing_method_change()
  
          if (static_index >= 0) {
              $editor.fields.static_quantity.hidden = false;
              $editor.fields.static_quantity.control.value = Number(details[static_index].Value);
          }
  
          if (apply_divide_index >= 0) {
              if (details[apply_divide_index].Value.toUpperCase() === 'TRUE') {
                  $editor.fields.divide_by.hidden = false;
                  $editor.fields.enable_divide_by.hidden = false;
                  $editor.fields.enable_divide_by.control.value = true;
                  if (divide_value_index >= 0) {
                      $editor.fields.divide_by.control.value = Number(details[divide_value_index].Value);
                  }
              } else {
                  $editor.fields.enable_divide_by.control.value = false;
              }
          }
  
          if (apply_weight_index >= 0) {
              if (details[apply_weight_index].Value.toUpperCase() === 'TRUE') {
                  $editor.fields.dim_factor.hidden = false;
                  $editor.fields.volume_uom.hidden = false;
                  $editor.fields.enable_weight_measure.hidden = false;
                  $editor.fields.enable_weight_measure.control.value = true;
                  $editor.tabs.volume_rates.hidden = false;
                  $editor.tabs.weight_rates.hidden = false;
                  $editor.tabs.billing_rates.hidden = true;
                  $editor.vars.weight_measure = true;
                  if (dim_value_index >= 0) {
                      $editor.fields.dim_factor.control.value = Number(details[dim_value_index].Value);
                  }
                  if (dim_volume_index >= 0) {
                      $editor.fields.volume_uom.control.value = Number(details[dim_volume_index].Value);
                  }
              } else {
                  $editor.fields.enable_weight_measure.control.value = false;
              }
          }
  
      } else {
          $editor.vars.uom_id = null;
          $editor.vars.uom_type_id = null;
      }
  
      if (retainer_rate_index >= 0) {
  
          $editor.fields.retainer_rate.hidden = false;
          $editor.fields.retainer_rate.control.value = Number(details[retainer_rate_index].Value);
  
          if (static_index >= 0) {
              $editor.fields.retainer_amount.hidden = false;
              $editor.fields.retainer_amount.control.value = Number(details[static_index].Value);
          }
  
          if (retainer_schedule_index >= 0) {
  
              let serializedValueEntity = details[retainer_schedule_index].SerializedValueEntity
  
  
              if ($utils.isDefined(serializedValueEntity)) {
  
                  let schedule_xml = $utils.parseXml(serializedValueEntity, { ignoreAttributes: true })
  
                  let ScheduleType = schedule_xml.RetainerSchedule?.ScheduleType;
                  let Frequency = schedule_xml.RetainerSchedule?.Frequency;
                  let DailyInterval = schedule_xml.RetainerSchedule?.DailyInterval;
                  let WeeklyInterval = schedule_xml.RetainerSchedule?.WeeklyInterval;
                  let Monday = schedule_xml.RetainerSchedule?.Monday;
                  let Tuesday = schedule_xml.RetainerSchedule?.Tuesday;
                  let Wednesday = schedule_xml.RetainerSchedule?.Wednesday;
                  let Thursday = schedule_xml.RetainerSchedule?.Thursday;
                  let Friday = schedule_xml.RetainerSchedule?.Friday;
                  let Saturday = schedule_xml.RetainerSchedule?.Saturday;
                  let Sunday = schedule_xml.RetainerSchedule?.Sunday;
                  let MonthlyInterval = schedule_xml.RetainerSchedule?.MonthlyInterval;
                  let MonthlyDayNumber = schedule_xml.RetainerSchedule?.MonthlyDayNumber;
                  let OccursAt = schedule_xml.RetainerSchedule?.OccursAt;
  
                  if ($utils.isDefined(DailyInterval)) {
                      $editor.fields.retainer_day_interval.hidden = false;
                      $editor.fields.retainer_day_interval.control.value = DailyInterval;
                  }
                  if ($utils.isDefined(WeeklyInterval)) {
                      $editor.fields.retainer_week_interval.hidden = false;
                      $editor.fields.retainer_week_interval.control.value = WeeklyInterval;
                  }
                  if ($utils.isDefined(MonthlyInterval)) {
                      $editor.fields.retainer_month_interval.hidden = false;
                      $editor.fields.retainer_month_interval.control.value = MonthlyInterval;
                  }
                  if ($utils.isDefined(MonthlyDayNumber)) {
                      $editor.fields.retainer_month_day.hidden = false;
                      $editor.fields.retainer_month_day.control.value = MonthlyDayNumber;
                  }
                  if ($utils.isDefined(OccursAt)) {
                      $editor.fields.retainer_time.hidden = false;
                      $editor.fields.retainer_time.control.value = get_time(OccursAt);
                  }
                  if ($utils.isDefined(Frequency)) {
                      $editor.fields.retainer_frequency.hidden = false;
                      $editor.fields.retainer_frequency.control.value = Frequency;
                      await $editor.on_retainer_frequency_change();
  
                      if (Frequency === "Weekly") {
                          $editor.fields.retainer_days.hidden = false;
  
                          let days = [];
  
                          if ($utils.isDefined(Sunday)) {
                              if (Sunday) {
                                  days.push(1);
                              }
                          }
                          if ($utils.isDefined(Monday)) {
                              if (Monday) {
                                  days.push(2);
                              }
                          }
                          if ($utils.isDefined(Tuesday)) {
                              if (Tuesday) {
                                  days.push(3);
                              }
                          }
                          if ($utils.isDefined(Wednesday)) {
                              if (Wednesday) {
                                  days.push(4);
                              }
                          }
                          if ($utils.isDefined(Thursday)) {
                              if (Thursday) {
                                  days.push(5);
                              }
                          }
                          if ($utils.isDefined(Friday)) {
                              if (Friday) {
                                  days.push(6);
                              }
                          }
                          if ($utils.isDefined(Saturday)) {
                              if (Saturday) {
                                  days.push(7);
                              }
                          }
                          if (days.length > 0) {
                              $editor.fields.retainer_days.control.value = days;
                          }
                      }
                  }
              }
          }
      }
  
      if (recurring_schedule_index >= 0) {
          if (recurring_location_storage_index >= 0) {
              $editor.fields.recurring_location_storage.hidden = false;
              $editor.fields.recurring_location_storage.control.value = Number(details[recurring_location_storage_index].Value);
          }
          if (recurring_schedule_type_index >= 0) {
  
              let schedule_type = 1; //Predefined
              let schedule = details[recurring_schedule_type_index].Value
  
              if (schedule === "AnniversaryCycle") {
                  schedule_type = 2;
              }
              $editor.fields.recurring_schedule.hidden = false;
              $editor.fields.recurring_schedule.control.value = schedule_type;
          }
          if (recurring_storage_object_index >= 0) {
              $editor.fields.recurring_billing_object.hidden = false;
              $editor.fields.recurring_billing_object.control.value = Number(details[recurring_storage_object_index].Value);
          }
  
          let serializedValueEntity = details[recurring_schedule_index].SerializedValueEntity
  
          if ($utils.isDefined(serializedValueEntity)) {
  
              let schedule_xml = $utils.parseXml(serializedValueEntity, { ignoreAttributes: true })
  
              let ScheduleType = schedule_xml.RecurringStorageSchedule?.ScheduleType;
              let Frequency = schedule_xml.RecurringStorageSchedule?.Frequency;
              let DailyInterval = schedule_xml.RecurringStorageSchedule?.DailyInterval;
              let WeeklyInterval = schedule_xml.RecurringStorageSchedule?.WeeklyInterval;
              let Monday = schedule_xml.RecurringStorageSchedule?.Monday;
              let Tuesday = schedule_xml.RecurringStorageSchedule?.Tuesday;
              let Wednesday = schedule_xml.RecurringStorageSchedule?.Wednesday;
              let Thursday = schedule_xml.RecurringStorageSchedule?.Thursday;
              let Friday = schedule_xml.RecurringStorageSchedule?.Friday;
              let Saturday = schedule_xml.RecurringStorageSchedule?.Saturday;
              let Sunday = schedule_xml.RecurringStorageSchedule?.Sunday;
              let MonthlyInterval = schedule_xml.RecurringStorageSchedule?.MonthlyInterval;
              let MonthlyDayNumber = schedule_xml.RecurringStorageSchedule?.MonthlyDayNumber;
              let OccursAt = schedule_xml.RecurringStorageSchedule?.OccursAt;
              let AdvancedBilling = schedule_xml.RecurringStorageSchedule?.AdvancedBilling;
              let AdvanceBillingGracePeriod = schedule_xml.RecurringStorageSchedule?.AdvanceBillingGracePeriod;
              let AdvanceBillingGraceFrequency = schedule_xml.RecurringStorageSchedule?.AdvanceBillingGraceFrequency;
              let AnniversaryBillingGracePeriod = schedule_xml.RecurringStorageSchedule?.AnniversaryBillingGracePeriod;
              let AnniversaryBillingGraceFrequency = schedule_xml.RecurringStorageSchedule?.AnniversaryBillingGraceFrequency;
  
              if ($utils.isDefined(DailyInterval)) {
                  $editor.fields.recurring_day_interval.hidden = false;
                  $editor.fields.recurring_day_interval.control.value = DailyInterval;
              }
              if ($utils.isDefined(WeeklyInterval)) {
                  $editor.fields.recurring_week_interval.hidden = false;
                  $editor.fields.recurring_week_interval.control.value = WeeklyInterval;
              }
              if ($utils.isDefined(MonthlyInterval)) {
                  $editor.fields.recurring_month_interval.hidden = false;
                  $editor.fields.recurring_month_interval.control.value = MonthlyInterval;
              }
              if ($utils.isDefined(MonthlyDayNumber)) {
                  $editor.fields.recurring_month_day.hidden = false;
                  $editor.fields.recurring_month_day.control.value = MonthlyDayNumber;
              }
              if ($utils.isDefined(OccursAt)) {
                  $editor.fields.recurring_time.hidden = false;
                  $editor.fields.recurring_time.control.value = get_time(OccursAt);
              }
              if ($utils.isDefined(AnniversaryBillingGracePeriod)) {
                  $editor.fields.recurring_anniversary_grace_period.hidden = false;
                  $editor.fields.recurring_anniversary_grace_period.control.value = true;
                  $editor.fields.recurring_anniversary_grace_period_option.hidden = false;
                  $editor.fields.recurring_anniversary_grace_period_value.hidden = false;
  
                  if ($utils.isDefined(AnniversaryBillingGraceFrequency)) {
                      $editor.fields.recurring_anniversary_grace_period_option.control.value = AdvanceBillingGraceFrequency;
                  }
                  if ($utils.isDefined(AnniversaryBillingGracePeriod)) {
                      $editor.fields.recurring_anniversary_grace_period_value.control.value = AnniversaryBillingGracePeriod;
                  }
              }
              if ($utils.isDefined(AdvancedBilling)) {
                  $editor.fields.recurring_advanced_billing.hidden = false;
                  $editor.fields.recurring_advanced_billing.control.value = AdvancedBilling;
                  await $editor.on_recurring_advanced_change()
  
                  if ($utils.isDefined(AdvanceBillingGracePeriod)) {
                      $editor.fields.recurring_advanced_grace_period.hidden = false;
                      $editor.fields.recurring_advanced_grace_period.control.value = true;
                      $editor.fields.recurring_advanced_grace_period_option.hidden = false;
                      $editor.fields.recurring_advanced_grace_period_value.hidden = false;
  
                      if ($utils.isDefined(AdvanceBillingGraceFrequency)) {
                          $editor.fields.recurring_advanced_grace_period_option.control.value = AdvanceBillingGraceFrequency;
                      }
                      if ($utils.isDefined(AdvanceBillingGracePeriod)) {
                          $editor.fields.recurring_advanced_grace_period_value.control.value = AdvanceBillingGracePeriod;
                      }
                  }
              }
  
              if ($utils.isDefined(Frequency)) {
                  $editor.fields.recurring_frequency.hidden = false;
                  $editor.fields.recurring_frequency.control.value = Frequency;
                  await $editor.on_recurring_frequency_change();
  
                  if (Frequency === "Weekly") {
                      $editor.fields.recurring_days.hidden = false;
  
                      let days = [];
  
                      if ($utils.isDefined(Sunday)) {
                          if (Sunday) {
                              days.push(1);
                          }
                      }
                      if ($utils.isDefined(Monday)) {
                          if (Monday) {
                              days.push(2);
                          }
                      }
                      if ($utils.isDefined(Tuesday)) {
                          if (Tuesday) {
                              days.push(3);
                          }
                      }
                      if ($utils.isDefined(Wednesday)) {
                          if (Wednesday) {
                              days.push(4);
                          }
                      }
                      if ($utils.isDefined(Thursday)) {
                          if (Thursday) {
                              days.push(5);
                          }
                      }
                      if ($utils.isDefined(Friday)) {
                          if (Friday) {
                              days.push(6);
                          }
                      }
                      if ($utils.isDefined(Saturday)) {
                          if (Saturday) {
                              days.push(7);
                          }
                      }
                      if (days.length > 0) {
                          $editor.fields.recurring_days.control.value = days;
                      }
                  }
              }
          }
          await $editor.on_recurring_schedule_type_change()
          await $editor.on_recurring_billing_object_change()
      }
  
      if (tags_index >= 0) {
  
          let serializedValueEntity = details[tags_index].SerializedValueEntity
  
          if ($utils.isDefined(serializedValueEntity)) {
  
              let tag_xml = $utils.parseXml(serializedValueEntity, { ignoreAttributes: true })
              let tag_ids = tag_xml.TagSelectionInfo?.TagIds?.int;
              let tag_groups = tag_xml.TagSelectionInfo?.TagGroupIds?.int;
  
              //$editor.fields.notes.control.value = JSON.stringify(tag_xml); //{"TagSelectionInfo":{"TagIds":{"int":[1,2,6,13,22,7,10,49]},"TagGroupIds":{"int":15}}}
              //$editor.fields.notes.control.value = JSON.stringify(tag_ids);   //{"int":[1,2,6,13,22,7,10,49]}
  
              if ($utils.isDefined(tag_ids)) {
                  if (!Array.isArray(tag_ids)) {
                      tag_ids = [tag_ids];
                  }
              }
  
              if ($utils.isDefined(tag_groups)) {
                  if (!Array.isArray(tag_groups)) {
                      tag_groups = [tag_groups];
                  }
              }
  
              //If there are groups, look for the ids, there is not a good way to handle groups at the moment.
              if ($utils.isDefined(tag_groups)) {
                  let group_tags = (await $datasources.Invoices.ds_tags_dd.get({ entityTypeId: 2, groupIds: tag_groups })).result;
                  if ($utils.isDefined(group_tags)) {
                      if ($utils.isDefined(tag_ids)) {
                          if (tag_ids.length > 0) {
                              tag_ids = tag_ids.concat(group_tags.map(g => g.Id));
                          } else {
                              tag_ids = group_tags.map(g => g.Id);
                          }
                      } else {
                          tag_ids = group_tags.map(g => g.Id);
                      }
                  }
              }
  
              if ($utils.isDefined(tag_ids)) {
                  //$editor.fields.notes.control.value = tag_ids.join(',');
  
                  //To verify the tags
                  let tags = (await $datasources.Invoices.ds_tags_dd.get({ tagIds: tag_ids })).result;
  
                  if ($utils.isDefined(tags)) {
                      tag_ids = tags.map(t => t.Id);
                  } else {
                      tag_ids = {}
                  }
  
                  if ($utils.isDefined(tag_ids)) {
  
                      if (billing_aggregation_strategy_name === 'Initial Storage with Grace Period' ||
                          billing_aggregation_strategy_name === 'Recurring Storage') {
                          $editor.fields.location_tags.control.value = tag_ids;
                      } else {
                          $editor.fields.material_tags.control.value = tag_ids;
                      }
                  }
              }
          }
      }
  
      if (accessorial_template_index >= 0) {
          $editor.fields.accessorial_template.hidden = false;
          $editor.fields.accessorial_template.control.value = Number(details[accessorial_template_index].Value);
      }
  
      $editor.vars.loading = false;
  }
  
  if (!$utils.isDefined($editor.inParams.operationCodeId)) {
      if (operation_index >= 0) {
          $editor.vars.operationCodeId = Number(details[operation_index].Value);
      }
  } else {
      $editor.vars.operationCodeId = $editor.inParams.operationCodeId;
  }
  
  $editor.fields.resulting_uom.control.value = $editor.entity.ResultingUomId;
  $editor.fields.resulting_uom_bm.control.value = $editor.entity.ResultingUomId;
  $editor.fields.operation_code.control.value = $editor.vars.operationCodeId;
  $editor.fields.date_range.control.value = date_range;
  $editor.vars.operationTypeId = [15]; //AccessorialCharges
  
  if (!$utils.isDefined($editor.entity.ResultingUomId)) {
      $editor.fields.resulting_uom.control.value = 1;
  }
  
  function get_time(candidate: string) {
      try {
          let last = Number(candidate.slice(candidate.length - 1, candidate.length))
          if (!isNaN(last) && !candidate.endsWith("Z") && !candidate.includes(".")) { candidate = `${candidate}.000Z` }
          let date = new Date(candidate);
          if ($utils.isDefined(date?.toISOString())) {
              let hour = date.getUTCHours();
              let minute = date.getUTCMinutes();
              if (minute >= 30) { minute = 30 } else { minute = 0 }
              candidate = [(`00${hour}`).slice(-2), (`00${minute}`).slice(-2)].join(":");
          } else {
              candidate = "00:00";
          }
      } catch (e) {
          candidate = "00:00";
      }
      return candidate
  }
  }
  on_save_line(event = null) {
    return this.on_save_lineInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_lineInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/01/2024
  
  try {
  
      //Build the contract payload
      let payload_line: any = {}
      let payload_details: any = {}
  
      if ($editor.fields.notes.control.isChanged) {
          payload_line.Notes = $editor.fields.notes.control.value;
      }
      if ($editor.fields.resulting_uom.control.isChanged || $editor.fields.resulting_uom_bm.control.isChanged) {
  
          let resulting_uom = null;
  
          if ($utils.isDefined($editor.fields.billing_method.control.value) && $utils.isDefined($editor.fields.resulting_uom_bm.control.value)) {
              resulting_uom = $editor.fields.resulting_uom_bm.control.value;
          } else {
              resulting_uom = $editor.fields.resulting_uom.control.value;
          }
          payload_line.ResultingUomId = resulting_uom;
  
      }
      if ($editor.fields.billing_code.control.isChanged && $utils.isDefined($editor.fields.billing_code.control.value)) {
          payload_line.ResultingBillingCodeId = $editor.fields.billing_code.control.value; //Cannot be null
      }
      if ($editor.fields.start_date.control.isChanged) {
          payload_line.StartDate = $editor.fields.start_date.control.value;
      }
      if ($editor.fields.end_date.control.isChanged) {
          payload_line.EndDate = $editor.fields.end_date.control.value;
      }
      if ($editor.fields.warehouse.control.isChanged) {
          payload_line.WarehouseId = $editor.fields.warehouse.control.value;
      }
      if ($editor.fields.base_package_conversion.control.isChanged) {
          payload_line.BasePackageConversion = $editor.fields.base_package_conversion.control.value;
      }
      if ($utils.isDefined(payload_line)) {
          $editor.vars.line_updated = true;
          $editor.vars.line_changed = true;
          payload_line.ModifiedSysDateTime = $utils.date.now();
          await $flows.Utilities.crud_update_flow({ entitySet: 'BillingContractLines', id: $editor.entity.Id, entity: payload_line });
      }
  
      if ($editor.fields.operation_code.control.isChanged) {
  
          $editor.vars.line_changed = true;
  
          if ($utils.isDefined($editor.fields.operation_code.control.value)) {
  
              if ($utils.isDefined($editor.entity.Details)) {
  
                  payload_details = {}
                  payload_details.Value = $editor.fields.operation_code.control.value.toString();
  
                  if ($utils.isDefined(payload_details)) {
                      await $flows.Utilities.crud_update_dual_pk_flow_string({
                          entitySet: 'BillingContractLineDetails',
                          pk1String: "BillingContractLineId", pk1: $editor.entity.Id,
                          pk2String: "StrategyFieldName", pk2: "OperationCodeId",
                          entity: payload_details
                      });
                  }
  
                  payload_details = {}
                  payload_details.Value = $editor.fields.operation_code.control.displayText;
  
                  if ($utils.isDefined(payload_details)) {
                      await $flows.Utilities.crud_update_dual_pk_flow_string({
                          entitySet: 'BillingContractLineDetails',
                          pk1String: "BillingContractLineId", pk1: $editor.entity.Id,
                          pk2String: "StrategyFieldName", pk2: "OpName",
                          entity: payload_details
                      });
                  }
              } else {
  
                  payload_details = {}
                  payload_details.BillingContractLineId = $editor.entity.Id;
                  payload_details.StrategyFieldName = 'OperationCodeId';
                  payload_details.Value = $editor.fields.operation_code.control.value.toString();
  
                  if ($utils.isDefined(payload_details)) {
                      await $flows.Utilities.crud_create_flow({
                          entitySet: 'BillingContractLineDetails',
                          entity: payload_details
                      });
                  }
  
                  payload_details = {}
                  payload_details.BillingContractLineId = $editor.entity.Id;
                  payload_details.StrategyFieldName = 'OpName';
                  payload_details.Value = $editor.fields.operation_code.control.displayText;
  
                  if ($utils.isDefined(payload_details)) {
                      await $flows.Utilities.crud_create_flow({
                          entitySet: 'BillingContractLineDetails',
                          entity: payload_details
                      });
                  }
              }
          }
      }
  } catch (error) {
      $editor.vars.error = true;
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Billing Contract Line ${$editor.entity.LineNumber.toString} - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Update billing contract error', 'An error occurred during saving of the contract line', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  }
  on_close(event = null) {
    return this.on_closeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_closeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/19/2023
  $editor.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/13/2024
  
  //Initial settings
  $editor.vars.loading = false;
  $editor.vars.line_changed = false;
  $editor.vars.line_updated = false;
  $editor.vars.weight_measure = false;
  $editor.tabs.weight_rates.hidden = true;
  $editor.tabs.volume_rates.hidden = true;
  $editor.fields.billing_object.hidden = true;
  $editor.fields.operation_code.hidden = true;
  $editor.fields.recurring_location_storage.hidden = true;
  $editor.fields.billing_aggregation_strategy.control.readOnly = true;
  $editor.fields.accessorial_template.hidden = true;
  $editor.fieldsets.description.hidden = true;
  
  let statusId: number = $editor.inParams.statusId ?? 0;
  
  //Bug FootPrint Cloud 176562: Recurring Storage Billing Scheduler not updated when modifying active contract line
  
  let do_not_allow_edit_read_only: boolean = true;
  let allow_edit_read_only: boolean = true;
  
  //If we get the edit parameter
  if ($editor.inParams.edit) {
  
      allow_edit_read_only = false;
  
      //Only edit when in created status
      if (statusId === 2) {
          do_not_allow_edit_read_only = false;
      }
  
      //Buttons
      enableComponent($editor.toolbar.save_line);
      disableComponent($editor.toolbar.close);
  } else {
  
      //Buttons
      disableComponent($editor.toolbar.save_line);
      enableComponent($editor.toolbar.close);
  }
  
  $editor.fields.notes.control.readOnly = allow_edit_read_only;
  
  $editor.fields.operation_code.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.resulting_uom.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.warehouse.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.base_package_conversion.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.billing_code.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.accessorial_template.control.readOnly = do_not_allow_edit_read_only;
  
  $editor.fields.end_date.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.start_date.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.date_range.control.readOnly = do_not_allow_edit_read_only;
  
  //Recurring storage section
  $editor.fields.recurring_billing_object.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_location_storage.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_day_interval.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_days.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_frequency.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_month_day.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_month_interval.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_schedule.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_time.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_week_interval.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_advanced_billing.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_advanced_grace_period.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_advanced_grace_period_option.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_advanced_grace_period_value.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_anniversary_grace_period.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_anniversary_grace_period_option.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.recurring_anniversary_grace_period_value.control.readOnly = do_not_allow_edit_read_only;
  
  //Grace billing section
  $editor.fields.grace_period.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.grace_period_value.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.initiating_date.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.days_remaining.control.readOnly = do_not_allow_edit_read_only;
  
  //Retainer section
  $editor.fields.retainer_day_interval.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.retainer_days.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.retainer_frequency.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.retainer_month_day.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.retainer_month_interval.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.retainer_time.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.retainer_week_interval.control.readOnly = do_not_allow_edit_read_only;
  
  //Materials section
  $editor.fields.material_group.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.material_package.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.material_tags.control.readOnly = do_not_allow_edit_read_only;
  
  //Billing methods section
  $editor.fields.billing_object.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.billing_method.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.resulting_uom_bm.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.enable_divide_by.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.enable_weight_measure.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.divide_by.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.dim_factor.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.volume_uom.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.static_quantity.control.readOnly = do_not_allow_edit_read_only;
  
  //Retainer section
  $editor.fields.retainer_amount.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.retainer_rate.control.readOnly = do_not_allow_edit_read_only;
  
  //Location section
  $editor.fields.location_tags.control.readOnly = do_not_allow_edit_read_only;
  
  //Carrier method section
  $editor.fields.carrier_package.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.carrier_service_type.control.readOnly = do_not_allow_edit_read_only;
  $editor.fields.service_type_method.control.readOnly = do_not_allow_edit_read_only;
  
  let billing_aggregation_strategy_name = $utils.isDefined($editor.inParams.billingAggregationStrategy) ? $editor.inParams.billingAggregationStrategy : '';
  
  if (billing_aggregation_strategy_name === 'Inbound Handling by Unit') {
  
      $editor.fieldsets.billing_methods.hidden = true;
      $editor.fieldsets.location_information.hidden = true;
      $editor.fieldsets.grace_period_billing.hidden = true;
      $editor.fieldsets.carrier_billing_methods.hidden = true;
      $editor.fieldsets.retainer_billing.hidden = true;
      $editor.fieldsets.recurring_storage.hidden = true;
  
      $editor.fieldsets.description.hidden = false;
      $editor.fields.description.control.value = 'Inbound handling fee is created upon shipment completion.\nOne Billing Record will be created per unique material using the total quantity received per material. Select the appropriate Billing Code and Rounding method. Select the Material Package if filtering is required. If convert to base package option is used then quantity received for each material will be converted to the lowest package "base".'
  
  } else if (billing_aggregation_strategy_name === 'Inbound Handling by Shipment' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Weight' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Volume' ||
      billing_aggregation_strategy_name === 'Inbound Handling by License Plate' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Shipment' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Weight' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Volume' ||
      billing_aggregation_strategy_name === 'Inbound Storage by License Plate' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Shipment' ||
      billing_aggregation_strategy_name === 'Outbound Handling By Shipment' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Weight' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Volume' ||
      billing_aggregation_strategy_name === 'Outbound Handling by License Plate') {
  
      $editor.fieldsets.carrier_billing_methods.hidden = true;
      $editor.fieldsets.billing_methods.expanded = true;
      $editor.fieldsets.billing_methods.hidden = false;
      $editor.fieldsets.location_information.hidden = true;
      $editor.fieldsets.grace_period_billing.hidden = true;
      $editor.fieldsets.retainer_billing.hidden = true;
      $editor.fieldsets.recurring_storage.hidden = true;
  
      disableComponent($editor.fields.resulting_uom);
  
      if (billing_aggregation_strategy_name === 'Inbound Handling by Shipment' ||
          billing_aggregation_strategy_name === 'Inbound Storage by Shipment' ||
          billing_aggregation_strategy_name === 'Outbound Handling by Shipment' ||
          billing_aggregation_strategy_name === 'Outbound Handling By Shipment') {
          $editor.vars.billingMethodsToInclude = [1, 2, 3, 5, 7];
          $editor.fields.enable_weight_measure.hidden = true;
          $editor.fields.enable_weight_measure.control.readOnly = true;
      }
  
      if (billing_aggregation_strategy_name === 'Inbound Handling by License Plate' ||
          billing_aggregation_strategy_name === 'Inbound Storage by License Plate') {
          $editor.vars.billingMethodsToInclude = [1, 2, 3, 4, 5, 7];
      }
  
      if (billing_aggregation_strategy_name === 'Outbound Handling by License Plate') {
          $editor.vars.billingMethodsToInclude = [1, 2, 3, 5, 7];
      }
  
      if (billing_aggregation_strategy_name === 'Inbound Handling by Weight' ||
          billing_aggregation_strategy_name === 'Inbound Storage by Weight' ||
          billing_aggregation_strategy_name === 'Outbound Handling by Weight') {
          $editor.vars.billingMethodsToInclude = [1, 2];
      }
  
      if (billing_aggregation_strategy_name === 'Inbound Handling by Volume' ||
          billing_aggregation_strategy_name === 'Inbound Storage by Volume' ||
          billing_aggregation_strategy_name === 'Outbound Handling by Volume') {
          $editor.vars.billingMethodsToInclude = [3, 7];
          $editor.fields.enable_weight_measure.hidden = true;
          $editor.fields.enable_weight_measure.control.readOnly = true;
      }
  
      if (!$utils.isDefined($editor.vars.billingMethodsToInclude)) {
          $editor.vars.billingMethodsToInclude = [1, 2, 3, 4, 5, 7];
      }
  
      if (billing_aggregation_strategy_name === 'Inbound Handling by License Plate') {
          $editor.fieldsets.description.hidden = false;
          $editor.fields.description.control.value = 'Inbound handling fee is created upon shipment completion.n\One Billing Record will be created per unique License Plate. Select the appropriate Billing Code.'
      }
  
      if (billing_aggregation_strategy_name === 'Inbound Storage by License Plate') {
          $editor.fieldsets.description.hidden = false;
          $editor.fields.description.control.value = 'Inbound storage fee is created upon shipment completion.n\One Billing Record will be created per unique License Plate.Select the appropriate Billing Code and Rounding method.Select the appropriate Range.Range date based on date of shipment completion.'
      }
  
      if (billing_aggregation_strategy_name === 'Outbound Handling by License Plate') {
          $editor.fieldsets.description.hidden = false;
          $editor.fields.description.control.value = 'Outbound handling fee is created upon shipment completion.n\One Billing Record will be created per unique Shipping License Plate. Select the appropriate Billing Code and Rounding method.'
      }
  
      if (billing_aggregation_strategy_name === 'Outbound Handling by Volume') {
          $editor.fieldsets.description.hidden = false;
          $editor.fields.description.control.value = 'Outbound handling fee is created upon shipment completion.n\One Billing Record will be created per unique material using the total quantity received per material times the material volume. Select the appropriate Billing Code and Rounding method. Select the appropriate Unit Of Measure. If the UOM selected does not match with how the material received is configured the volume will be converted.'
      }
  
  } else if (billing_aggregation_strategy_name === 'Initial Storage with Grace Period') {
      $editor.fieldsets.carrier_billing_methods.hidden = true;
      $editor.fieldsets.billing_methods.expanded = true;
      $editor.fieldsets.billing_methods.hidden = false;
      $editor.fieldsets.location_information.hidden = false;
      $editor.fieldsets.grace_period_billing.expanded = true;
      $editor.fieldsets.grace_period_billing.hidden = false;
      $editor.fieldsets.retainer_billing.hidden = true;
      $editor.fieldsets.recurring_storage.hidden = true;
      $editor.fields.billing_object.hidden = false;
      $editor.fields.material_tags.hidden = true;
      $editor.fields.enable_weight_measure.hidden = true;
      $editor.fields.enable_weight_measure.control.readOnly = true;
      $editor.vars.billingMethodsToInclude = [1, 2, 3, 4, 5, 7];
  
  } else if (billing_aggregation_strategy_name === 'Accessorial Charges') {
      $editor.fields.date_range.hidden = true;
      $editor.fields.operation_code.hidden = false;
      $editor.fields.base_package_conversion.hidden = true;
      $editor.fieldsets.carrier_billing_methods.hidden = true;
      $editor.fieldsets.billing_methods.hidden = true;
      $editor.fieldsets.location_information.hidden = true;
      $editor.fieldsets.material_information.hidden = true;
      $editor.fieldsets.grace_period_billing.hidden = true;
      $editor.fieldsets.retainer_billing.hidden = true;
      $editor.fieldsets.recurring_storage.hidden = true;
      $editor.vars.operationTypeId = [15]; //AccessorialCharges
  
      $editor.fieldsets.description.hidden = false;
      $editor.fields.description.control.value = 'Accessorial Charges are created upon completion of accessorial tasks.\nOne Billing Record will be created per completed accessonal task. Select the Operation Code for the tasks to be billed, leave blank if you would like all tasks to be billed regardless of Operation Code, make sure contract line is saved after all other accessorial charge operations are specified.'
  
  } else if (billing_aggregation_strategy_name === 'Accessorial Charge Template') {
      $editor.fields.accessorial_template.hidden = false;
      $editor.fields.billing_code.control.value = 0;
  
      $editor.fields.date_range.hidden = true;
      $editor.fields.operation_code.hidden = true;
      $editor.fields.billing_code.hidden = true;
      $editor.fields.resulting_uom.hidden = true;
      $editor.fields.start_date.hidden = true;
      $editor.fields.end_date.hidden = true;
      $editor.fields.base_package_conversion.hidden = true;
  
      $editor.fieldsets.carrier_billing_methods.hidden = true;
      $editor.fieldsets.billing_methods.hidden = true;
      $editor.fieldsets.location_information.hidden = true;
      $editor.fieldsets.material_information.hidden = true;
      $editor.fieldsets.grace_period_billing.hidden = true;
      $editor.fieldsets.retainer_billing.hidden = true;
      $editor.fieldsets.recurring_storage.hidden = true;
  
      $editor.tabs.billing_rates.hidden = true;
      $editor.tabs.supplemental_fees.hidden = true;
      $editor.tabs.billing_projects.hidden = true;
  
  } else if (billing_aggregation_strategy_name === 'Outbound Shipment by Carrier Service Type') {
      $editor.fieldsets.carrier_billing_methods.hidden = false;
      $editor.fieldsets.carrier_billing_methods.expanded = true;
      $editor.fieldsets.material_information.hidden = true;
      $editor.fieldsets.location_information.hidden = true;
      $editor.fieldsets.grace_period_billing.hidden = true;
      $editor.fieldsets.billing_methods.hidden = true;
      $editor.fieldsets.retainer_billing.hidden = true;
      $editor.fieldsets.recurring_storage.hidden = true;
      $editor.fields.resulting_uom.hidden = true;
  
      $editor.fieldsets.description.hidden = false;
      $editor.fields.description.control.value = 'Outbound handling fee is created upon shipment completion.\nSelect the appropriate Carrier Service Type. Select the Strategy Method. Full Truck Load will create one biling record per shipment. Less Then Truck Load will create one billing record per unique shipping license plate. Package will create one billing record per unique material on the shipment, options to specify package allows for further filtering. If convert to base package option is used then quantity received for each material will be converted to the lowest package "base".'
  
  } else if (billing_aggregation_strategy_name === 'Retainer') {
  
      $editor.fieldsets.retainer_billing.hidden = false;
      $editor.fieldsets.retainer_billing.expanded = true;
  
      $editor.fieldsets.carrier_billing_methods.hidden = true;
      $editor.fieldsets.material_information.hidden = true;
      $editor.fieldsets.location_information.hidden = true;
      $editor.fieldsets.grace_period_billing.hidden = true;
      $editor.fieldsets.recurring_storage.hidden = true;
      $editor.fieldsets.billing_methods.hidden = true;
  
      $editor.fields.resulting_uom.hidden = true;
      $editor.fields.date_range.hidden = true;
      $editor.fields.base_package_conversion.hidden = true;
  
      $editor.fields.retainer_day_interval.hidden = true;
      $editor.fields.retainer_week_interval.hidden = true;
      $editor.fields.retainer_month_day.hidden = true;
      $editor.fields.retainer_month_interval.hidden = true;
      $editor.fields.retainer_days.hidden = true;
      $editor.fields.retainer_days.control.value = null;
  
      $editor.tabs.billing_rates.hidden = true;
      $editor.tabs.supplemental_fees.hidden = true;
      $editor.tabs.billing_projects.hidden = true;
  
  } else if (billing_aggregation_strategy_name === 'Recurring Storage') {
  
      $editor.fieldsets.recurring_storage.hidden = false;
      $editor.fieldsets.recurring_storage.expanded = true;
      $editor.fieldsets.billing_methods.hidden = false;
      $editor.fieldsets.billing_methods.expanded = true;
      $editor.fieldsets.material_information.hidden = false;
      $editor.fieldsets.location_information.hidden = false;
  
      $editor.fieldsets.carrier_billing_methods.hidden = true;
      $editor.fieldsets.grace_period_billing.hidden = true;
      $editor.fieldsets.retainer_billing.hidden = true;
  
      $editor.fields.resulting_uom.hidden = true;
      $editor.fields.date_range.hidden = true;
      $editor.fields.base_package_conversion.hidden = true;
      $editor.fields.material_tags.hidden = true;
  
      $editor.vars.recurringSchedulesToInclude = [1, 2];
  
      $editor.fields.recurring_location_storage.hidden = false;
      $editor.fields.recurring_day_interval.hidden = true;
      $editor.fields.recurring_week_interval.hidden = true;
      $editor.fields.recurring_month_day.hidden = true;
      $editor.fields.recurring_month_interval.hidden = true;
      $editor.fields.recurring_days.hidden = true;
      $editor.fields.recurring_days.control.value = null;
  
      $editor.tabs.supplemental_fees.hidden = true;
  
      $editor.tabs.billing_rates.hidden = false;
      $editor.tabs.billing_projects.hidden = false;
  
  } else {
  
      $editor.fieldsets.billing_methods.hidden = true;
      $editor.fieldsets.location_information.hidden = true;
      $editor.fieldsets.grace_period_billing.hidden = true;
      $editor.fieldsets.carrier_billing_methods.hidden = true;
      $editor.fieldsets.retainer_billing.hidden = true;
      $editor.fieldsets.recurring_storage.hidden = true;
  }
  
  //Initialize the error variable
  $editor.vars.error = false;
  
  $editor.on_billing_method_change();
  
  function disableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = true;
      }
      component.hidden = true;
  }
  function enableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = false;
      }
      component.hidden = false;
  }
  }
  on_save_details(event = null) {
    return this.on_save_detailsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_detailsInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/02/2024
  
  try {
  
      await $editor.on_save_line()
  
      let billing_aggregation_strategy_name = $utils.isDefined($editor.inParams.billingAggregationStrategy) ? $editor.inParams.billingAggregationStrategy : '';
  
      if (!$editor.fieldsets.billing_methods.hidden) { //Only run if the billing methods are visible
          if ($editor.fields.dim_factor.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "DimFactorValue",
                  value: $utils.isDefined($editor.fields.dim_factor.control.value) ? $editor.fields.dim_factor.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.volume_uom.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "DimFactorVolumeUOM",
                  value: $utils.isDefined($editor.fields.volume_uom.control.value) ? $editor.fields.volume_uom.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.divide_by.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "DivideByCalculationValue",
                  value: $utils.isDefined($editor.fields.divide_by.control.value) ? $editor.fields.divide_by.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.enable_divide_by.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "ApplyDivideByCalculation",
                  value: $utils.isDefined($editor.fields.enable_divide_by.control.value) ? $editor.fields.enable_divide_by.control.value.toString() : "false",
                  data: null
              })
          }
          if ($editor.fields.enable_weight_measure.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "ApplayWeightMeasureOption",
                  value: $utils.isDefined($editor.fields.enable_weight_measure.control.value) ? $editor.fields.enable_weight_measure.control.value.toString() : "false",
                  data: null
              })
          }
          if ($editor.fields.billing_method.control.isChanged) {
              $editor.vars.line_changed = true;
              if (billing_aggregation_strategy_name === "Inbound Handling by Volume" ||
                  billing_aggregation_strategy_name === "Inbound Storage by Volume" ||
                  billing_aggregation_strategy_name === "Outbound Handling by Volume") {
  
                  let volume_method: number = null;
                  let billing_method = $editor.fields.billing_method.control.value;
  
                  if ($utils.isDefined(billing_method)) {
                      if (billing_method === 3) { //Gross(Shipping) Volume
                          volume_method = 1;
                      } else if (billing_method === 7) { //Net Volume
                          volume_method = 2;
                      }
                  }
                  await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                      billingContractLineId: $editor.entity.Id,
                      strategyName: "VolumeMethodId",
                      value: $utils.isDefined(volume_method) ? volume_method.toString() : null,
                      data: null
                  })
              } else if (billing_aggregation_strategy_name === "Inbound Handling by Weight" ||
                  billing_aggregation_strategy_name === "Inbound Storage by Weight" ||
                  billing_aggregation_strategy_name === "Outbound Handling by Weight") {
  
                  let weight_method: number = null;
                  let billing_method = $editor.fields.billing_method.control.value;
  
                  if ($utils.isDefined(billing_method)) {
                      if (billing_method === 2) { //Gross(Shipping) Weight
                          weight_method = 1;
                      } else if (billing_method === 1) { //Net Weight
                          weight_method = 2;
                      }
                  }
                  await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                      billingContractLineId: $editor.entity.Id,
                      strategyName: "WeightMethodId",
                      value: $utils.isDefined(weight_method) ? weight_method.toString() : null,
                      data: null
                  })
              } else {
                  await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                      billingContractLineId: $editor.entity.Id,
                      strategyName: "BillingMethodId",
                      value: $utils.isDefined($editor.fields.billing_method.control.value) ? $editor.fields.billing_method.control.value.toString() : null,
                      data: null
                  })
              }
          }
          if (billing_aggregation_strategy_name !== "Retainer") {
              if ($editor.fields.static_quantity.control.isChanged) {
                  $editor.vars.line_changed = true;
                  await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                      billingContractLineId: $editor.entity.Id,
                      strategyName: "StaticQuantity",
                      value: $utils.isDefined($editor.fields.static_quantity.control.value) ? $editor.fields.static_quantity.control.value.toString() : null,
                      data: null
                  })
              }
          }
      }
  
      if (!$editor.fieldsets.material_information.hidden) { //Only run if the material information is visible
          if ($editor.fields.material_group.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "MaterialGroupId",
                  value: $utils.isDefined($editor.fields.material_group.control.value) ? $editor.fields.material_group.control.value.toString() : null,
                  data: null
              })
          }
          if (billing_aggregation_strategy_name !== "Outbound Shipment by Carrier Service Type") {
              if ($editor.fields.material_package.control.isChanged) {
                  $editor.vars.line_changed = true;
                  await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                      billingContractLineId: $editor.entity.Id,
                      strategyName: "MaterialPackageId",
                      value: $utils.isDefined($editor.fields.material_package.control.value) ? $editor.fields.material_package.control.value.toString() : null,
                      data: null
                  })
              }
          }
      }
  
      if (!$editor.fieldsets.grace_period_billing.hidden) { //Only run if the grace period fieldset is visible
  
          let grace_days = 0;
  
          if ($utils.isDefined($editor.fields.grace_period_value)) {
              if ($editor.fields.grace_period_value.control.value > 0) {
                  grace_days = $editor.fields.grace_period_value.control.value;
              }
          }
  
          let grace_period = 3;
  
          if ($utils.isDefined($editor.fields.grace_period)) {
              if ($editor.fields.grace_period.control.value > 0) {
                  grace_period = $editor.fields.grace_period.control.value;
              }
          }
  
          if (grace_period === 3) {//None
              grace_days = null;
              $editor.fields.grace_period_value.control.value = null;
          } else if (grace_period === 2) {//Weeks
              grace_days = grace_days * 7;
          }
  
          if ($editor.fields.grace_period.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "GracePeriod",
                  value: $utils.isDefined($editor.fields.grace_period.control.value) ? $editor.fields.grace_period.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.days_remaining.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "DaysRemainingId",
                  value: $utils.isDefined($editor.fields.days_remaining.control.value) ? $editor.fields.days_remaining.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.billing_object.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "BillingObjectId",
                  value: $utils.isDefined($editor.fields.billing_object.control.value) ? $editor.fields.billing_object.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.initiating_date.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "InitiatingDateId",
                  value: $utils.isDefined($editor.fields.initiating_date.control.value) ? $editor.fields.initiating_date.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.grace_period_value.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "GracePeriodDays",
                  value: grace_days ? grace_days.toString() : null,
                  data: null
              })
          }
      }
  
      if (!$editor.fieldsets.carrier_billing_methods.hidden) { //Only run if the carrier billing fieldset is visible
          if ($editor.fields.service_type_method.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "BillingOutboundShipmentByCarrierServiceTypeMethodId",
                  value: $utils.isDefined($editor.fields.service_type_method.control.value) ? $editor.fields.service_type_method.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.carrier_service_type.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "CarrierServiceTypeId",
                  value: $utils.isDefined($editor.fields.carrier_service_type.control.value) ? $editor.fields.carrier_service_type.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.carrier_package.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "MaterialPackageId",
                  value: $utils.isDefined($editor.fields.carrier_package.control.value) ? $editor.fields.carrier_package.control.value.toString() : null,
                  data: null
              })
          }
      }
  
      if (!$editor.fieldsets.retainer_billing.hidden) { //Only run if the retainer billing fieldset is visible
          if ($editor.fields.retainer_amount.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "StaticQuantity",
                  value: $utils.isDefined($editor.fields.retainer_amount.control.value) ? $editor.fields.retainer_amount.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.retainer_rate.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "RetainerRate",
                  value: $utils.isDefined($editor.fields.retainer_rate.control.value) ? $editor.fields.retainer_rate.control.value.toString() : null,
                  data: null
              })
          }
  
          let days = [0];
  
          if ($utils.isDefined($editor.fields.retainer_days)) {
              let retainer_days = $editor.fields.retainer_days.control.value;
              if ($utils.isDefined(retainer_days)) {
                  if (retainer_days.length > 0) {
                      days = retainer_days;
                  }
              }
          }
  
          let data_retainer_schedule: {
              "ScheduleType"?: string,
              "Frequency"?: string,
              "DailyInterval"?: number,
              "WeeklyInterval"?: number,
              "Monday"?: boolean,
              "Tuesday"?: boolean,
              "Wednesday"?: boolean,
              "Thursday"?: boolean,
              "Friday"?: boolean,
              "Saturday"?: boolean,
              "Sunday"?: boolean,
              "MonthlyInterval"?: number,
              "MonthlyDayNumber"?: number,
              "OccursAt"?: string,
              "TagIds"?: [number]
          }
  
          data_retainer_schedule = {
              "ScheduleType": "Predefined",
              "Frequency": $utils.isDefined($editor.fields.retainer_frequency) ? $editor.fields.retainer_frequency.control.value : null,
              "DailyInterval": $utils.isDefined($editor.fields.retainer_day_interval) ? $editor.fields.retainer_day_interval.control.value : null,
              "WeeklyInterval": $utils.isDefined($editor.fields.retainer_week_interval) ? $editor.fields.retainer_week_interval.control.value : null,
              "Monday": (days.indexOf(2) >= 0) ? true : false,
              "Tuesday": (days.indexOf(3) >= 0) ? true : false,
              "Wednesday": (days.indexOf(4) >= 0) ? true : false,
              "Thursday": (days.indexOf(5) >= 0) ? true : false,
              "Friday": (days.indexOf(6) >= 0) ? true : false,
              "Saturday": (days.indexOf(7) >= 0) ? true : false,
              "Sunday": (days.indexOf(1) >= 0) ? true : false,
              "MonthlyInterval": $utils.isDefined($editor.fields.retainer_month_interval) ? $editor.fields.retainer_month_interval.control.value : null,
              "MonthlyDayNumber": $utils.isDefined($editor.fields.retainer_month_day) ? $editor.fields.retainer_month_day.control.value : null,
              "OccursAt": $utils.isDefined($editor.fields.retainer_time) ? add_date($editor.fields.retainer_time.control.value) : null
          }
  
          await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
              billingContractLineId: $editor.entity.Id,
              strategyName: "RetainerSchedule",
              value: null,
              data: data_retainer_schedule
          });
      }
  
      if (!$editor.fieldsets.recurring_storage.hidden) { //Only run if the recurring storage fieldset is visible
          if ($editor.fields.recurring_billing_object.control.isChanged) {
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "RecurringStorageBillingObjectId",
                  value: $utils.isDefined($editor.fields.recurring_billing_object.control.value) ? $editor.fields.recurring_billing_object.control.value.toString() : null,
                  data: null
              })
          }
          if ($editor.fields.recurring_location_storage.control.isChanged) {
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "LocationTemperatureCategoryId",
                  value: $utils.isDefined($editor.fields.recurring_location_storage.control.value) ? $editor.fields.recurring_location_storage.control.value.toString() : null,
                  data: null
              })
          }
  
          let schedule = "Predefined";
  
          if ($editor.fields.recurring_schedule.control.isChanged) {
  
              if ($editor.fields.recurring_schedule.control.value === 2) {
                  schedule = "AnniversaryCycle";
              }
  
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "RecurringScheduleType",
                  value: $utils.isDefined(schedule) ? schedule : null,
                  data: null
              })
          }
  
          let days = [0];
  
          if ($utils.isDefined($editor.fields.recurring_days)) {
              let recurring_days = $editor.fields.recurring_days.control.value;
              if ($utils.isDefined(recurring_days)) {
                  if (recurring_days.length > 0) {
                      days = recurring_days;
                  }
              }
          }
  
          let data_recurring_schedule: {
              "ScheduleType"?: string,
              "Frequency"?: string,
              "DailyInterval"?: number,
              "WeeklyInterval"?: number,
              "Monday"?: boolean,
              "Tuesday"?: boolean,
              "Wednesday"?: boolean,
              "Thursday"?: boolean,
              "Friday"?: boolean,
              "Saturday"?: boolean,
              "Sunday"?: boolean,
              "MonthlyInterval"?: number,
              "MonthlyDayNumber"?: number,
              "OccursAt"?: string,
              "AdvancedBilling"?: boolean,
              "AdvanceBillingGracePeriod"?: number,
              "AdvanceBillingGraceFrequency"?: string,
              "AnniversaryBillingGracePeriod"?: number,
              "AnniversaryBillingGraceFrequency"?: string,
              "TagIds"?: [number]
          }
  
          data_recurring_schedule = {
              "ScheduleType": $utils.isDefined(schedule) ? schedule : null,
              "Frequency": $utils.isDefined($editor.fields.recurring_frequency) ? $editor.fields.recurring_frequency.control.value : null,
              "DailyInterval": $utils.isDefined($editor.fields.recurring_day_interval) ? $editor.fields.recurring_day_interval.control.value : null,
              "WeeklyInterval": $utils.isDefined($editor.fields.recurring_week_interval) ? $editor.fields.recurring_week_interval.control.value : null,
              "Monday": (days.indexOf(2) >= 0) ? true : false,
              "Tuesday": (days.indexOf(3) >= 0) ? true : false,
              "Wednesday": (days.indexOf(4) >= 0) ? true : false,
              "Thursday": (days.indexOf(5) >= 0) ? true : false,
              "Friday": (days.indexOf(6) >= 0) ? true : false,
              "Saturday": (days.indexOf(7) >= 0) ? true : false,
              "Sunday": (days.indexOf(1) >= 0) ? true : false,
              "MonthlyInterval": $utils.isDefined($editor.fields.recurring_month_interval) ? $editor.fields.recurring_month_interval.control.value : null,
              "MonthlyDayNumber": $utils.isDefined($editor.fields.recurring_month_day) ? $editor.fields.recurring_month_day.control.value : null,
              "OccursAt": $utils.isDefined($editor.fields.recurring_time) ? add_date($editor.fields.recurring_time.control.value) : null,
              "AdvancedBilling": $utils.isDefined($editor.fields.recurring_advanced_billing.control.value) ? $editor.fields.recurring_advanced_billing.control.value : false,
              "AdvanceBillingGracePeriod": $utils.isDefined($editor.fields.recurring_advanced_grace_period_value) ? $editor.fields.recurring_advanced_grace_period_value.control.value : null,
              "AdvanceBillingGraceFrequency": $utils.isDefined($editor.fields.recurring_advanced_grace_period_option) ? $editor.fields.recurring_advanced_grace_period_option.control.value : null,
              "AnniversaryBillingGracePeriod": $utils.isDefined($editor.fields.recurring_anniversary_grace_period_value) ? $editor.fields.recurring_anniversary_grace_period_value.control.value : null,
              "AnniversaryBillingGraceFrequency": $utils.isDefined($editor.fields.recurring_anniversary_grace_period_option) ? $editor.fields.recurring_anniversary_grace_period_option.control.value : null
          }
  
          await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
              billingContractLineId: $editor.entity.Id,
              strategyName: "Schedule",
              value: null,
              data: data_recurring_schedule
          });
      }
  
      if (!$editor.fields.operation_code.hidden) {//Only run if the operation code is shown (Accessorial Charges)
  
          if ($editor.fields.operation_code.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "OperationCodeId",
                  value: $utils.isDefined($editor.fields.operation_code.control.value) ? $editor.fields.operation_code.control.value.toString() : null,
                  data: null
              })
          }
      }
  
      if (!$editor.fields.accessorial_template.hidden) {//Only run if shown (Accessorial Charge Template)
  
          if ($editor.fields.accessorial_template.control.isChanged) {
              $editor.vars.line_changed = true;
              await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
                  billingContractLineId: $editor.entity.Id,
                  strategyName: "BillingAccessorialChargesTemplateId",
                  value: $utils.isDefined($editor.fields.accessorial_template.control.value) ? $editor.fields.accessorial_template.control.value.toString() : null,
                  data: null
              })
          }
      }
  
      //These don't use the date range details
      if (billing_aggregation_strategy_name !== 'Retainer' &&
          billing_aggregation_strategy_name !== 'Recurring Storage' &&
          billing_aggregation_strategy_name !== 'Accessorial Charges' &&
          billing_aggregation_strategy_name !== 'Accessorial Charge Template') {
  
          let start_day = 1;
          let end_day = 31;
  
          if ($utils.isDefined($editor.fields.date_range.control)) {
              if ($editor.fields.date_range.control.value === 1) {
                  end_day = 15;
              } else if ($editor.fields.date_range.control.value === 16) {
                  start_day = 16;
              }
          }
  
          await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
              billingContractLineId: $editor.entity.Id,
              strategyName: "StartDayOfMonth",
              value: start_day.toString(),
              data: null
          });
          await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
              billingContractLineId: $editor.entity.Id,
              strategyName: "EndDayOfMonth",
              value: end_day.toString(),
              data: null
          });
      }
  
      //These don't use tags
      if (billing_aggregation_strategy_name !== 'Retainer' &&
          billing_aggregation_strategy_name !== 'Outbound Shipment by Carrier Service Type' &&
          billing_aggregation_strategy_name !== 'Accessorial Charges') {
          let tag_ids = null;
  
          if ($editor.fields.material_tags.control.isChanged) {
              $editor.vars.line_changed = true;
              if ($utils.isDefined($editor.fields.material_tags.control.value)) {
                  tag_ids = $editor.fields.material_tags.control.value;
              }
          }
  
          if ($editor.fields.location_tags.control.isChanged) {
              $editor.vars.line_changed = true;
              if ($utils.isDefined($editor.fields.location_tags.control.value)) {
                  tag_ids = $editor.fields.location_tags.control.value;
              }
          }
  
          let data_tags = { "TagIds": tag_ids }
  
          await $flows.Invoices.create_or_update_billing_contract_line_detail_flow({
              billingContractLineId: $editor.entity.Id,
              strategyName: "Tags",
              value: null,
              data: data_tags
          });
      }
  
      await $editor.on_validate_fields();
      await $editor.on_check_and_close();
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error.message;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Billing Contract Line ${$editor.entity.LineNumber.toString} - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Update billing contract error', 'An error occurred during saving of the contract line', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  
  function add_date(candidate: string) {
      try {
          let hours: number = Number(candidate.split(":")[0]);
          let minutes: number = Number(candidate.split(":")[1]);
          let date = new Date();
          date.setUTCHours(hours, minutes, 0, 0);
          candidate = date.toISOString();
          candidate = candidate.split(".")[0];
      } catch (error) { }
      return candidate
  }
  }
  on_check_and_close(event = null) {
    return this.on_check_and_closeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_check_and_closeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/01/2024
  
  if ($editor.vars.line_changed && !$editor.vars.line_updated) {
      let payload_line: any = { Id: $editor.entity.Id, ModifiedSysDateTime: $utils.date.now() };
      await $flows.Utilities.crud_update_flow({ entitySet: 'BillingContractLines', id: $editor.entity.Id, entity: payload_line });
  }
  
  if ($editor.vars.line_changed) {
      let payload_contract: any = { Id: $editor.entity.BillingContractId, ModifiedSysDateTime: $utils.date.now() };
      await $flows.Utilities.crud_update_flow({ entitySet: 'BillingContracts', id: $editor.entity.BillingContractId, entity: payload_contract });
  }
  
  $editor.outParams.updated = $editor.vars.line_changed;
  
  let error = $editor.vars.error;
  let reason = $editor.vars.reason;
  
  if (error && reason?.length > 0) {
      await $shell.FootPrintManager.openInfoDialog('Cannot save billing contract line yet!', reason.join(' '));
      $editor.vars.reason = [];
      $editor.vars.error = false;
  } else {
      await $editor.refresh()
      $editor.close()
  };
  }
  on_validate_fields(event = null) {
    return this.on_validate_fieldsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_validate_fieldsInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/10/2023
  
  if (!$utils.isDefined($editor.vars.reason)) {
      $editor.vars.reason = [];
  };
  
  let reasons = (await $flows.Invoices.is_billing_contract_line_valid_flow({
      billingContractId: $editor.entity.BillingContractId,
      billingContractLineIds: [$editor.entity.Id]
  }));
  
  if ($utils.isDefined(reasons)) {
      if ($utils.isDefined(reasons.valid)) {
          if (!reasons.valid) {
              $editor.vars.error = true;
          };
      };
      if ($utils.isDefined(reasons.reasons)) {
          if (reasons.reasons.length > 0 && $editor.vars.reason.length > 0) {
              $editor.vars.reason.concat(reasons.reasons);
          } else if (reasons.reasons.length > 0 && $editor.vars.reason.length === 0) {
              $editor.vars.reason = reasons.reasons;
          };
      };
  };
  }
  on_billing_method_change(event = null) {
    return this.on_billing_method_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_billing_method_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/09/2023
  
  let billing_method_id = $editor.fields.billing_method.control.value;
  let billing_aggregation_strategy_name = $utils.isDefined($editor.inParams.billingAggregationStrategy) ? $editor.inParams.billingAggregationStrategy : '';
  
  if ($utils.isDefined(billing_method_id)) {
  
      if (billing_method_id === 1 || billing_method_id === 2) { //Weight
          $editor.vars.uom_id = null;
          $editor.vars.uom_type_id = [3];
  
          if (!$editor.vars.loading) {
              $editor.fields.resulting_uom.control.value = null;
              $editor.fields.resulting_uom_bm.control.value = null;
              $editor.fields.enable_divide_by.control.value = false;
              $editor.fields.divide_by.hidden = true;
              $editor.fields.divide_by.control.value = null;
              $editor.fields.enable_weight_measure.control.value = false;
              $editor.fields.dim_factor.hidden = true;
              $editor.fields.dim_factor.control.value = null;
              $editor.fields.volume_uom.hidden = true;
              $editor.fields.volume_uom.control.value = null;
              $editor.fields.static_quantity.hidden = true;
              $editor.fields.static_quantity.control.value = null;
          };
  
          $editor.fields.enable_divide_by.hidden = false;
  
          if (billing_aggregation_strategy_name === 'Inbound Handling by Shipment' ||
              billing_aggregation_strategy_name === 'Inbound Handling by Volume' ||
              billing_aggregation_strategy_name === 'Inbound Storage by Shipment' ||
              billing_aggregation_strategy_name === 'Inbound Storage by Volume' ||
              billing_aggregation_strategy_name === 'Outbound Handling by Shipment' ||
              billing_aggregation_strategy_name === 'Outbound Handling By Shipment' ||
              billing_aggregation_strategy_name === 'Outbound Handling by Volume' ||
              billing_aggregation_strategy_name === 'Initial Storage with Grace Period') {
              $editor.fields.enable_weight_measure.hidden = true;
          } else {
              $editor.fields.enable_weight_measure.hidden = false;
          };
  
      } else if (billing_method_id === 3 || billing_method_id === 7) { //Volume
          $editor.vars.uom_id = null;
          $editor.vars.uom_type_id = [2];
  
          if (!$editor.vars.loading) {
              $editor.fields.resulting_uom.control.value = null;
              $editor.fields.resulting_uom_bm.control.value = null;
              $editor.fields.enable_divide_by.hidden = true;
              $editor.fields.enable_divide_by.control.value = null;
              $editor.fields.divide_by.hidden = true;
              $editor.fields.divide_by.control.value = null;
              $editor.fields.enable_weight_measure.hidden = true;
              $editor.fields.enable_weight_measure.control.value = null;
              $editor.fields.dim_factor.hidden = true;
              $editor.fields.dim_factor.control.value = null;
              $editor.fields.volume_uom.hidden = true;
              $editor.fields.volume_uom.control.value = null;
              $editor.fields.static_quantity.hidden = true;
              $editor.fields.static_quantity.control.value = null;
          };
  
      } else if (billing_method_id === 4 || billing_method_id === 5) { //Unit
          $editor.vars.uom_id = [1];
          $editor.vars.uom_type_id = [4];
          $editor.fields.resulting_uom.control.value = 1;
          $editor.fields.resulting_uom_bm.control.value = 1;
  
          if (!$editor.vars.loading) {
              $editor.fields.enable_divide_by.hidden = true;
              $editor.fields.enable_divide_by.control.value = null;
              $editor.fields.divide_by.hidden = true;
              $editor.fields.divide_by.control.value = null;
              $editor.fields.enable_weight_measure.hidden = true;
              $editor.fields.enable_weight_measure.control.value = null;
              $editor.fields.dim_factor.hidden = true;
              $editor.fields.dim_factor.control.value = null;
              $editor.fields.volume_uom.hidden = true;
              $editor.fields.volume_uom.control.value = null;
  
              if (billing_method_id === 4) {
                  $editor.fields.static_quantity.hidden = true;
                  $editor.fields.static_quantity.control.value = null;
              } else {
                  $editor.fields.static_quantity.hidden = false;
                  $editor.fields.static_quantity.control.value = null;
              };
          };
      } else {
          $editor.vars.uom_id = [1];
          $editor.vars.uom_type_id = [4];
          $editor.fields.resulting_uom.control.value = 1;
          $editor.fields.resulting_uom_bm.control.value = 1;
  
          if (!$editor.vars.loading) {
              $editor.fields.enable_divide_by.hidden = true;
              $editor.fields.enable_divide_by.control.value = null;
              $editor.fields.divide_by.hidden = true;
              $editor.fields.divide_by.control.value = null;
              $editor.fields.enable_weight_measure.hidden = true;
              $editor.fields.enable_weight_measure.control.value = null;
              $editor.fields.dim_factor.hidden = true;
              $editor.fields.dim_factor.control.value = null;
              $editor.fields.volume_uom.hidden = true;
              $editor.fields.volume_uom.control.value = null;
              $editor.fields.static_quantity.hidden = true;
              $editor.fields.static_quantity.control.value = null;
          };
      };
  
  } else {
      $editor.vars.uom_id = null;
      $editor.vars.uom_type_id = null;
      $editor.fields.resulting_uom.control.value = null;
      $editor.fields.resulting_uom_bm.control.value = null;
  
      $editor.fields.enable_divide_by.hidden = true;
      $editor.fields.enable_divide_by.control.value = null;
      $editor.fields.divide_by.hidden = true;
      $editor.fields.divide_by.control.value = null;
      $editor.fields.enable_weight_measure.hidden = true;
      $editor.fields.enable_weight_measure.control.value = null;
      $editor.fields.dim_factor.hidden = true;
      $editor.fields.dim_factor.control.value = null;
      $editor.fields.volume_uom.hidden = true;
      $editor.fields.volume_uom.control.value = null;
      $editor.fields.static_quantity.hidden = true;
      $editor.fields.static_quantity.control.value = null;
  };
  }
  on_billing_method_slide_change(event = null) {
    return this.on_billing_method_slide_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_billing_method_slide_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/28/2023
  
  if ($utils.isDefined($editor.fields.enable_divide_by.control.value)) {
      if ($editor.fields.enable_divide_by.control.value) {
          $editor.fields.divide_by.hidden = false;
      } else {
          $editor.fields.divide_by.hidden = true;
          $editor.fields.divide_by.control.value = null;
      }
  } else {
      $editor.fields.divide_by.hidden = true;
      $editor.fields.divide_by.control.value = null;
  };
  
  if ($utils.isDefined($editor.fields.enable_weight_measure.control.value)) {
      if ($editor.fields.enable_weight_measure.control.value) {
          $editor.fields.dim_factor.hidden = false;
          $editor.fields.volume_uom.hidden = false;
          $editor.tabs.weight_rates.hidden = false;
          $editor.tabs.volume_rates.hidden = false;
          $editor.tabs.billing_rates.hidden = true;
          $editor.vars.weight_measure = true;
      } else {
          $editor.fields.dim_factor.hidden = true;
          $editor.fields.dim_factor.control.value = null;
          $editor.fields.volume_uom.hidden = true;
          $editor.fields.volume_uom.control.value = null;
          $editor.tabs.weight_rates.hidden = true;
          $editor.tabs.volume_rates.hidden = true;
          $editor.tabs.billing_rates.hidden = false;
          $editor.vars.weight_measure = false;
      }
  } else {
      $editor.fields.dim_factor.hidden = true;
      $editor.fields.dim_factor.control.value = null;
      $editor.fields.volume_uom.hidden = true;
      $editor.fields.volume_uom.control.value = null;
      $editor.tabs.weight_rates.hidden = true;
      $editor.tabs.volume_rates.hidden = true;
      $editor.tabs.billing_rates.hidden = false;
      $editor.vars.weight_measure = false;
  };
  }
  on_grace_period_change(event = null) {
    return this.on_grace_period_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grace_period_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/09/2023
  
  let grace_period = 3;
  
  if ($utils.isDefined($editor.fields.grace_period)) {
      if ($editor.fields.grace_period.control.value > 0) {
          grace_period = $editor.fields.grace_period.control.value;
      };
  };
  
  $editor.fields.grace_period_value.hidden = false;
  
  if (grace_period === 3) {
      $editor.fields.grace_period_value.hidden = true;
  } else if (grace_period === 2) {
      $editor.fields.grace_period_value.label = 'Grace period in weeks';
      $editor.fields.grace_period_value.required = true;
  } else {
      $editor.fields.grace_period_value.label = 'Grace period in days';
      $editor.fields.grace_period_value.required = true;
  };
  
  }
  on_service_type_method_change(event = null) {
    return this.on_service_type_method_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_service_type_method_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/12/2023
  
  if ($utils.isDefined($editor.fields.service_type_method)) {
      if ($utils.isDefined($editor.fields.service_type_method.control.value)) {
  
          let service_type_method = $editor.fields.service_type_method.control.value;
  
          if (service_type_method === 1) {//FullTruckLoad
              $editor.fields.base_package_conversion.control.value = true;
              $editor.fields.base_package_conversion.control.readOnly = true;
              $editor.fields.carrier_package.control.value = null;
              $editor.fields.carrier_package.hidden = true;
          };
  
          if (service_type_method === 2) {//LessThenTruckLoad
              $editor.fields.base_package_conversion.control.value = true;
              $editor.fields.base_package_conversion.control.readOnly = true;
              $editor.fields.carrier_package.control.value = null;
              $editor.fields.carrier_package.hidden = true;
          };
  
          if (service_type_method === 3) {//Package
              $editor.fields.base_package_conversion.control.value = false;
              $editor.fields.base_package_conversion.control.readOnly = false;
              $editor.fields.carrier_package.hidden = false;
          };
  
      };
  };
  }
  on_retainer_frequency_change(event = null) {
    return this.on_retainer_frequency_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_retainer_frequency_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/12/2023
  
  if ($utils.isDefined($editor.fields.retainer_frequency)) {
      if ($utils.isDefined($editor.fields.retainer_frequency.control.value)) {
  
          let frequency = $editor.fields.retainer_frequency.control.value;
  
          if (frequency === "Daily") {
              $editor.fields.retainer_day_interval.hidden = false;
              $editor.fields.retainer_week_interval.hidden = true;
              $editor.fields.retainer_month_day.hidden = true;
              $editor.fields.retainer_month_interval.hidden = true;
              $editor.fields.retainer_days.hidden = true;
  
              $editor.fields.retainer_week_interval.control.value = null;
              $editor.fields.retainer_month_day.control.value = null;
              $editor.fields.retainer_month_interval.control.value = null;
              $editor.fields.retainer_days.control.value = null;
          };
  
          if (frequency === "Weekly") {
              $editor.fields.retainer_day_interval.hidden = true;
              $editor.fields.retainer_week_interval.hidden = false;
              $editor.fields.retainer_month_day.hidden = true;
              $editor.fields.retainer_month_interval.hidden = true;
              $editor.fields.retainer_days.hidden = false;
  
              $editor.fields.retainer_day_interval.control.value = null;
              $editor.fields.retainer_month_day.control.value = null;
              $editor.fields.retainer_month_interval.control.value = null;
              $editor.fields.retainer_days.control.value = null;
          };
  
          if (frequency === "Monthly") {
              $editor.fields.retainer_day_interval.hidden = true;
              $editor.fields.retainer_week_interval.hidden = true;
              $editor.fields.retainer_month_day.hidden = false;
              $editor.fields.retainer_month_interval.hidden = false;
              $editor.fields.retainer_days.hidden = true;
            
              $editor.fields.retainer_day_interval.control.value = null;
              $editor.fields.retainer_week_interval.control.value = null;
              $editor.fields.retainer_days.control.value = null;
          };
      };
  };
  }
  on_operation_code_change(event = null) {
    return this.on_operation_code_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_operation_code_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/16/2023
  
  let operation_code_id = $editor.fields.operation_code.control.value;
  
  if ($utils.isDefined(operation_code_id)) {
  
      let operation_code = (await $datasources.Invoices.ds_operation_codes_dd.get({ id: [operation_code_id] })).result;
  
      if ($utils.isDefined(operation_code)) {
  
          let operation_uom = operation_code[0].Uom;
          let operation_billing_code = operation_code[0].BillingCodeId;
  
          if ($utils.isDefined(operation_uom)) {
              $editor.fields.resulting_uom.control.value = operation_uom;
          };
          if ($utils.isDefined(operation_billing_code)) {
              $editor.fields.billing_code.control.value = operation_billing_code;
          };
      };
  };
  
  }
  on_recurring_frequency_change(event = null) {
    return this.on_recurring_frequency_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_recurring_frequency_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/02/2024
  
  let schedule = 1;
  let loading = false;
  
  if ($utils.isDefined($editor.fields.recurring_schedule.control.value)) {
      schedule = $editor.fields.recurring_schedule.control.value;
  }
  
  if ($utils.isDefined($editor.vars.loading)) {
      loading = $editor.vars.loading;
  }
  
  if ($utils.isDefined($editor.fields.recurring_frequency)) {
      if ($utils.isDefined($editor.fields.recurring_frequency.control.value)) {
  
          let frequency = $editor.fields.recurring_frequency.control.value;
  
          if (frequency === "Daily") {
              $editor.fields.recurring_day_interval.hidden = false;
              $editor.fields.recurring_week_interval.hidden = true;
              $editor.fields.recurring_month_day.hidden = true;
              $editor.fields.recurring_month_interval.hidden = true;
              $editor.fields.recurring_days.hidden = true;
  
              if (!loading) {
                  $editor.fields.recurring_week_interval.control.value = null;
                  $editor.fields.recurring_month_day.control.value = null;
                  $editor.fields.recurring_month_interval.control.value = null;
                  $editor.fields.recurring_days.control.value = null;
              }
          }
  
          if (frequency === "Weekly") {
  
              if (schedule === 2) {//AnniversaryCycle
                  $editor.fields.recurring_days.hidden = true;
                  if (!loading) { $editor.fields.recurring_days.control.value = null }
              } else {
                  $editor.fields.recurring_days.hidden = false;
              }
  
              $editor.fields.recurring_day_interval.hidden = true;
              $editor.fields.recurring_week_interval.hidden = false;
              $editor.fields.recurring_month_day.hidden = true;
              $editor.fields.recurring_month_interval.hidden = true;
  
              if (!loading) {
                  $editor.fields.recurring_day_interval.control.value = null;
                  $editor.fields.recurring_month_day.control.value = null;
                  $editor.fields.recurring_month_interval.control.value = null;
                  $editor.fields.recurring_days.control.value = null;
              }
          }
  
          if (frequency === "Monthly") {
  
              if (schedule === 2) {//AnniversaryCycle
                  $editor.fields.recurring_month_day.hidden = true;
                  if (!loading) { $editor.fields.recurring_month_day.control.value = null }
              } else {
                  $editor.fields.recurring_month_day.hidden = false;
              }
  
              $editor.fields.recurring_day_interval.hidden = true;
              $editor.fields.recurring_week_interval.hidden = true;
              $editor.fields.recurring_month_interval.hidden = false;
              $editor.fields.recurring_days.hidden = true;
  
              if (!loading) {
                  $editor.fields.recurring_day_interval.control.value = null;
                  $editor.fields.recurring_week_interval.control.value = null;
                  $editor.fields.recurring_days.control.value = null;
              }
          }
      }
  }
  }
  on_recurring_billing_object_change(event = null) {
    return this.on_recurring_billing_object_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_recurring_billing_object_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/16/2023
  
  let recurring_object = $editor.fields.recurring_billing_object.control.value;
  
  if ($utils.isDefined(recurring_object)) {
      //With Lots
      if (recurring_object === 2 ||
          recurring_object === 4 ||
          recurring_object === 5 ||
          recurring_object === 7 ||
          recurring_object === 9 ||
          recurring_object === 11) {
          $editor.vars.recurringSchedulesToInclude = [1, 2];
          $editor.fields.recurring_schedule.hidden = false;
      } else {
          $editor.vars.recurringSchedulesToInclude = [1];
          $editor.fields.recurring_schedule.control.value = 1;
          $editor.fields.recurring_schedule.hidden = true;
          await $editor.on_recurring_schedule_type_change();
      };
  } else {
      $editor.vars.recurringSchedulesToInclude = [1, 2];
      $editor.fields.recurring_schedule.hidden = false;
  };
  
  }
  on_recurring_schedule_type_change(event = null) {
    return this.on_recurring_schedule_type_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_recurring_schedule_type_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias  - 10/16/2023
  
  let only_predefined = true;
  let recurring_object = $editor.fields.recurring_billing_object.control.value;
  
  if ($utils.isDefined(recurring_object)) {
      //With Lots
      if (recurring_object === 2 ||
          recurring_object === 4 ||
          recurring_object === 5 ||
          recurring_object === 7 ||
          recurring_object === 9 ||
          recurring_object === 11) {
          only_predefined = false;
      };
  };
  
  let schedule = 1;
  
  if (!only_predefined) {
      if ($utils.isDefined($editor.fields.recurring_schedule.control.value)) {
          schedule = $editor.fields.recurring_schedule.control.value;
      };
  } else {
      schedule = 1;
      $editor.fields.recurring_schedule.control.value = schedule;
  };
  
  if (schedule === 2) {
  
      $editor.fields.recurring_advanced_billing.hidden = false;
      $editor.fields.recurring_anniversary_grace_period.hidden = false;
      $editor.fields.recurring_days.control.value = null;
  
  } else {
  
      $editor.fields.recurring_advanced_billing.hidden = true;
      $editor.fields.recurring_advanced_billing.control.value = false;
      $editor.fields.recurring_advanced_grace_period.hidden = true;
      $editor.fields.recurring_advanced_grace_period_option.hidden = true;
      $editor.fields.recurring_advanced_grace_period_value.hidden = true;
  
      $editor.fields.recurring_anniversary_grace_period.hidden = true;
      $editor.fields.recurring_anniversary_grace_period_option.hidden = true;
      $editor.fields.recurring_anniversary_grace_period_value.hidden = true;
  
  };
  
  await $editor.on_recurring_frequency_change();
  }
  on_recurring_anniversary_change(event = null) {
    return this.on_recurring_anniversary_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_recurring_anniversary_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/17/2023
  
  let anniversary = false;
  
  if ($utils.isDefined($editor.fields.recurring_anniversary_grace_period.control.value)) {
      anniversary = $editor.fields.recurring_anniversary_grace_period.control.value;
  };
  
  if (anniversary) {
  
      $editor.fields.recurring_anniversary_grace_period_option.hidden = false;
      $editor.fields.recurring_anniversary_grace_period_value.hidden = false;
  
  } else {
  
      $editor.fields.recurring_anniversary_grace_period_option.hidden = true;
      $editor.fields.recurring_anniversary_grace_period_option.control.value = null;
      $editor.fields.recurring_anniversary_grace_period_value.hidden = true;
      $editor.fields.recurring_anniversary_grace_period_value.control.value = null;
  
  };
  }
  on_recurring_grace_change(event = null) {
    return this.on_recurring_grace_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_recurring_grace_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/17/2023
  
  let grace = false;
  
  if ($utils.isDefined($editor.fields.recurring_advanced_grace_period.control.value)) {
      grace = $editor.fields.recurring_advanced_grace_period.control.value;
  };
  
  if (grace) {
  
      $editor.fields.recurring_advanced_grace_period_option.hidden = false;
      $editor.fields.recurring_advanced_grace_period_value.hidden = false;
  
  } else {
  
      $editor.fields.recurring_advanced_grace_period_option.hidden = true;
      $editor.fields.recurring_advanced_grace_period_option.control.value = null;
      $editor.fields.recurring_advanced_grace_period_value.hidden = true;
      $editor.fields.recurring_advanced_grace_period_value.control.value = null;
  
  };
  }
  on_recurring_advanced_change(event = null) {
    return this.on_recurring_advanced_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_recurring_advanced_changeInternal(
    $editor: FootPrintManager_billing_contract_line_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/17/2023
  
  let advanced = false;
  
  if ($utils.isDefined($editor.fields.recurring_advanced_billing.control.value)) {
      advanced = $editor.fields.recurring_advanced_billing.control.value;
  };
  
  if (advanced) {
  
      $editor.fields.recurring_advanced_grace_period.hidden = false;
  
  } else {
  
      $editor.fields.recurring_advanced_grace_period.hidden = true;
      $editor.fields.recurring_advanced_grace_period.control.value = false;
  
  };
  
  await $editor.on_recurring_grace_change();
  }
  //#endregion private flows
}
