import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { AsnOrders_ds_asn_order_editorService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_asn_order_licenseplates_gridService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_asn_order_licenseplates_receiving_tasks_gridService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_asn_orderlines_gridService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_asn_orders_children_tasks_gridService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_asn_orders_tasks_gridService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_find_children_serialService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_find_licenseplatesService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_find_putaway_task_by_licenseplateIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_find_serialnumbersService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_find_tasksService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_asn_licenseplate_count_by_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_asn_orders_receiving_tasksService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_asn_receiving_taskService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_asn_serial_numbers_to_deleteService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_completed_inventorymove_tasks_by_licenseplateIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_completed_receiving_tasks_by_orderId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_completed_tasks_by_orderId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_inbound_totals_by_linenumber_orderIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_inventory_count_tasks_by_licenseplateId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_inventoryadjustment_tasks_by_chainheadService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_license_plates_by_idsService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_licenseplate_by_licenseplateIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_licenseplatecontents_by_licenseplateIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_licenseplates_by_lookupcode_and_warehouseIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_licenseplates_by_orderIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_licenseplates_by_shipmentId_or_idsService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_location_by_locationIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_material_by_materialIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_material_packaging_by_materialId_and_packagingIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_materialpackagings_by_materialIdsService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_account_address_by_orderIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_address_by_orderId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_by_orderIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_by_orderId_detailedService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_details_by_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_licenseplatesService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_owner_contact_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_ship_from_account_vs_order_addressService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_validation_result_most_recentService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_order_warehouse_contact_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_orderaddresses_by_orderIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_orderlines_by_contentService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_orderlines_by_orderIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_orderlines_by_orderId_bottom1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_orderlines_by_orderId_linenumberService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_ordervalidationresults_by_orderIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_planned_asn_licenseplates_by_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_planned_receiving_tasks_by_licenseplateId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_planned_receiving_tasks_by_orderId_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_putaway_tasks_by_licenseplateId_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_putaway_tasks_by_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_received_licenseplates_by_orderId_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_receiving_tasks_by_licenseplateId_lotId_packagingIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_receiving_tasks_by_orderIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_released_receiving_tasks_by_licenseplateId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_released_receiving_tasks_by_orderId_shipmentId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_serialcreation_task_by_serialnumberIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_serialcreation_tasks_by_chainheadService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookupService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_serialnumbers_by_serialNumberIdsService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_serialpurge_tasks_by_chainheadService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_shipmentLines_by_orderId_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_shipmentLines_by_orderId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_shipmentLines_by_shipmentId_top1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_shipmentline_by_shipmentlineIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_shipmentlines_by_orderId_bottom1Service } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_shipmentlines_by_orderlineService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_shipments_by_orderIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_shipments_by_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_task_by_taskIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_tasks_by_chainHeadService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_tasks_by_orderId_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_tasks_by_serialnumberIdsService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_validation_tasks_by_licenseplateId_shipmentIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_get_warehouse_by_warehouseIdService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_license_plate_label_reportService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_license_plate_labels_by_order_reportService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_licenseplates_by_order_ddService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_orderclasses_ddService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_orderline_total_amountService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_orderline_total_amount_widgetService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_orderline_total_gross_received_widgetService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_orderline_total_receivedService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_orderline_total_received_widgetService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_orders_gridService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_receiving_details_reportService } from './AsnOrders.datasource.index';
import { AsnOrders_ds_receiving_header_reportService } from './AsnOrders.datasource.index';

@Injectable({ providedIn: 'root' })
export class AsnOrders_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public AsnOrders: AsnOrders_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_asn_order_editor: AsnOrders_ds_asn_order_editorService;
  public get ds_asn_order_editor(): AsnOrders_ds_asn_order_editorService {
    if(!this._ds_asn_order_editor) {
      this._ds_asn_order_editor = this.injector.get(AsnOrders_ds_asn_order_editorService);
    }
    return this._ds_asn_order_editor;
  }
  private _ds_asn_order_licenseplates_grid: AsnOrders_ds_asn_order_licenseplates_gridService;
  public get ds_asn_order_licenseplates_grid(): AsnOrders_ds_asn_order_licenseplates_gridService {
    if(!this._ds_asn_order_licenseplates_grid) {
      this._ds_asn_order_licenseplates_grid = this.injector.get(AsnOrders_ds_asn_order_licenseplates_gridService);
    }
    return this._ds_asn_order_licenseplates_grid;
  }
  private _ds_asn_order_licenseplates_receiving_tasks_grid: AsnOrders_ds_asn_order_licenseplates_receiving_tasks_gridService;
  public get ds_asn_order_licenseplates_receiving_tasks_grid(): AsnOrders_ds_asn_order_licenseplates_receiving_tasks_gridService {
    if(!this._ds_asn_order_licenseplates_receiving_tasks_grid) {
      this._ds_asn_order_licenseplates_receiving_tasks_grid = this.injector.get(AsnOrders_ds_asn_order_licenseplates_receiving_tasks_gridService);
    }
    return this._ds_asn_order_licenseplates_receiving_tasks_grid;
  }
  private _ds_asn_orderlines_grid: AsnOrders_ds_asn_orderlines_gridService;
  public get ds_asn_orderlines_grid(): AsnOrders_ds_asn_orderlines_gridService {
    if(!this._ds_asn_orderlines_grid) {
      this._ds_asn_orderlines_grid = this.injector.get(AsnOrders_ds_asn_orderlines_gridService);
    }
    return this._ds_asn_orderlines_grid;
  }
  private _ds_asn_orders_children_tasks_grid: AsnOrders_ds_asn_orders_children_tasks_gridService;
  public get ds_asn_orders_children_tasks_grid(): AsnOrders_ds_asn_orders_children_tasks_gridService {
    if(!this._ds_asn_orders_children_tasks_grid) {
      this._ds_asn_orders_children_tasks_grid = this.injector.get(AsnOrders_ds_asn_orders_children_tasks_gridService);
    }
    return this._ds_asn_orders_children_tasks_grid;
  }
  private _ds_asn_orders_tasks_grid: AsnOrders_ds_asn_orders_tasks_gridService;
  public get ds_asn_orders_tasks_grid(): AsnOrders_ds_asn_orders_tasks_gridService {
    if(!this._ds_asn_orders_tasks_grid) {
      this._ds_asn_orders_tasks_grid = this.injector.get(AsnOrders_ds_asn_orders_tasks_gridService);
    }
    return this._ds_asn_orders_tasks_grid;
  }
  private _ds_find_children_serial: AsnOrders_ds_find_children_serialService;
  public get ds_find_children_serial(): AsnOrders_ds_find_children_serialService {
    if(!this._ds_find_children_serial) {
      this._ds_find_children_serial = this.injector.get(AsnOrders_ds_find_children_serialService);
    }
    return this._ds_find_children_serial;
  }
  private _ds_find_licenseplates: AsnOrders_ds_find_licenseplatesService;
  public get ds_find_licenseplates(): AsnOrders_ds_find_licenseplatesService {
    if(!this._ds_find_licenseplates) {
      this._ds_find_licenseplates = this.injector.get(AsnOrders_ds_find_licenseplatesService);
    }
    return this._ds_find_licenseplates;
  }
  private _ds_find_putaway_task_by_licenseplateId: AsnOrders_ds_find_putaway_task_by_licenseplateIdService;
  public get ds_find_putaway_task_by_licenseplateId(): AsnOrders_ds_find_putaway_task_by_licenseplateIdService {
    if(!this._ds_find_putaway_task_by_licenseplateId) {
      this._ds_find_putaway_task_by_licenseplateId = this.injector.get(AsnOrders_ds_find_putaway_task_by_licenseplateIdService);
    }
    return this._ds_find_putaway_task_by_licenseplateId;
  }
  private _ds_find_serialnumbers: AsnOrders_ds_find_serialnumbersService;
  public get ds_find_serialnumbers(): AsnOrders_ds_find_serialnumbersService {
    if(!this._ds_find_serialnumbers) {
      this._ds_find_serialnumbers = this.injector.get(AsnOrders_ds_find_serialnumbersService);
    }
    return this._ds_find_serialnumbers;
  }
  private _ds_find_tasks: AsnOrders_ds_find_tasksService;
  public get ds_find_tasks(): AsnOrders_ds_find_tasksService {
    if(!this._ds_find_tasks) {
      this._ds_find_tasks = this.injector.get(AsnOrders_ds_find_tasksService);
    }
    return this._ds_find_tasks;
  }
  private _ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeId: AsnOrders_ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeIdService;
  public get ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeId(): AsnOrders_ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeIdService {
    if(!this._ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeId) {
      this._ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeId = this.injector.get(AsnOrders_ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeIdService);
    }
    return this._ds_get_adjustment_tasks_by_licenseplateId_lotId_packagingId_reasoncodeId;
  }
  private _ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingId: AsnOrders_ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingIdService;
  public get ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingId(): AsnOrders_ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingIdService {
    if(!this._ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingId) {
      this._ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingId = this.injector.get(AsnOrders_ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingIdService);
    }
    return this._ds_get_archived_serialnumbers_by_licenseplateId_lotId_packagingId;
  }
  private _ds_get_asn_licenseplate_count_by_shipmentId: AsnOrders_ds_get_asn_licenseplate_count_by_shipmentIdService;
  public get ds_get_asn_licenseplate_count_by_shipmentId(): AsnOrders_ds_get_asn_licenseplate_count_by_shipmentIdService {
    if(!this._ds_get_asn_licenseplate_count_by_shipmentId) {
      this._ds_get_asn_licenseplate_count_by_shipmentId = this.injector.get(AsnOrders_ds_get_asn_licenseplate_count_by_shipmentIdService);
    }
    return this._ds_get_asn_licenseplate_count_by_shipmentId;
  }
  private _ds_get_asn_orders_receiving_tasks: AsnOrders_ds_get_asn_orders_receiving_tasksService;
  public get ds_get_asn_orders_receiving_tasks(): AsnOrders_ds_get_asn_orders_receiving_tasksService {
    if(!this._ds_get_asn_orders_receiving_tasks) {
      this._ds_get_asn_orders_receiving_tasks = this.injector.get(AsnOrders_ds_get_asn_orders_receiving_tasksService);
    }
    return this._ds_get_asn_orders_receiving_tasks;
  }
  private _ds_get_asn_receiving_task: AsnOrders_ds_get_asn_receiving_taskService;
  public get ds_get_asn_receiving_task(): AsnOrders_ds_get_asn_receiving_taskService {
    if(!this._ds_get_asn_receiving_task) {
      this._ds_get_asn_receiving_task = this.injector.get(AsnOrders_ds_get_asn_receiving_taskService);
    }
    return this._ds_get_asn_receiving_task;
  }
  private _ds_get_asn_serial_numbers_to_delete: AsnOrders_ds_get_asn_serial_numbers_to_deleteService;
  public get ds_get_asn_serial_numbers_to_delete(): AsnOrders_ds_get_asn_serial_numbers_to_deleteService {
    if(!this._ds_get_asn_serial_numbers_to_delete) {
      this._ds_get_asn_serial_numbers_to_delete = this.injector.get(AsnOrders_ds_get_asn_serial_numbers_to_deleteService);
    }
    return this._ds_get_asn_serial_numbers_to_delete;
  }
  private _ds_get_completed_inventorymove_tasks_by_licenseplateId: AsnOrders_ds_get_completed_inventorymove_tasks_by_licenseplateIdService;
  public get ds_get_completed_inventorymove_tasks_by_licenseplateId(): AsnOrders_ds_get_completed_inventorymove_tasks_by_licenseplateIdService {
    if(!this._ds_get_completed_inventorymove_tasks_by_licenseplateId) {
      this._ds_get_completed_inventorymove_tasks_by_licenseplateId = this.injector.get(AsnOrders_ds_get_completed_inventorymove_tasks_by_licenseplateIdService);
    }
    return this._ds_get_completed_inventorymove_tasks_by_licenseplateId;
  }
  private _ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentId: AsnOrders_ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentIdService;
  public get ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentId(): AsnOrders_ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentIdService {
    if(!this._ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentId) {
      this._ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentId = this.injector.get(AsnOrders_ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentIdService);
    }
    return this._ds_get_completed_receiving_tasks_by_licenseplateId_orderId_shipmentId;
  }
  private _ds_get_completed_receiving_tasks_by_orderId_shipmentId: AsnOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentIdService;
  public get ds_get_completed_receiving_tasks_by_orderId_shipmentId(): AsnOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentIdService {
    if(!this._ds_get_completed_receiving_tasks_by_orderId_shipmentId) {
      this._ds_get_completed_receiving_tasks_by_orderId_shipmentId = this.injector.get(AsnOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentIdService);
    }
    return this._ds_get_completed_receiving_tasks_by_orderId_shipmentId;
  }
  private _ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1: AsnOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1Service;
  public get ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1(): AsnOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1Service {
    if(!this._ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1) {
      this._ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1 = this.injector.get(AsnOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1Service);
    }
    return this._ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1;
  }
  private _ds_get_completed_receiving_tasks_by_orderId_top1: AsnOrders_ds_get_completed_receiving_tasks_by_orderId_top1Service;
  public get ds_get_completed_receiving_tasks_by_orderId_top1(): AsnOrders_ds_get_completed_receiving_tasks_by_orderId_top1Service {
    if(!this._ds_get_completed_receiving_tasks_by_orderId_top1) {
      this._ds_get_completed_receiving_tasks_by_orderId_top1 = this.injector.get(AsnOrders_ds_get_completed_receiving_tasks_by_orderId_top1Service);
    }
    return this._ds_get_completed_receiving_tasks_by_orderId_top1;
  }
  private _ds_get_completed_tasks_by_orderId_top1: AsnOrders_ds_get_completed_tasks_by_orderId_top1Service;
  public get ds_get_completed_tasks_by_orderId_top1(): AsnOrders_ds_get_completed_tasks_by_orderId_top1Service {
    if(!this._ds_get_completed_tasks_by_orderId_top1) {
      this._ds_get_completed_tasks_by_orderId_top1 = this.injector.get(AsnOrders_ds_get_completed_tasks_by_orderId_top1Service);
    }
    return this._ds_get_completed_tasks_by_orderId_top1;
  }
  private _ds_get_inbound_totals_by_linenumber_orderId: AsnOrders_ds_get_inbound_totals_by_linenumber_orderIdService;
  public get ds_get_inbound_totals_by_linenumber_orderId(): AsnOrders_ds_get_inbound_totals_by_linenumber_orderIdService {
    if(!this._ds_get_inbound_totals_by_linenumber_orderId) {
      this._ds_get_inbound_totals_by_linenumber_orderId = this.injector.get(AsnOrders_ds_get_inbound_totals_by_linenumber_orderIdService);
    }
    return this._ds_get_inbound_totals_by_linenumber_orderId;
  }
  private _ds_get_inventory_count_tasks_by_licenseplateId_top1: AsnOrders_ds_get_inventory_count_tasks_by_licenseplateId_top1Service;
  public get ds_get_inventory_count_tasks_by_licenseplateId_top1(): AsnOrders_ds_get_inventory_count_tasks_by_licenseplateId_top1Service {
    if(!this._ds_get_inventory_count_tasks_by_licenseplateId_top1) {
      this._ds_get_inventory_count_tasks_by_licenseplateId_top1 = this.injector.get(AsnOrders_ds_get_inventory_count_tasks_by_licenseplateId_top1Service);
    }
    return this._ds_get_inventory_count_tasks_by_licenseplateId_top1;
  }
  private _ds_get_inventoryadjustment_tasks_by_chainhead: AsnOrders_ds_get_inventoryadjustment_tasks_by_chainheadService;
  public get ds_get_inventoryadjustment_tasks_by_chainhead(): AsnOrders_ds_get_inventoryadjustment_tasks_by_chainheadService {
    if(!this._ds_get_inventoryadjustment_tasks_by_chainhead) {
      this._ds_get_inventoryadjustment_tasks_by_chainhead = this.injector.get(AsnOrders_ds_get_inventoryadjustment_tasks_by_chainheadService);
    }
    return this._ds_get_inventoryadjustment_tasks_by_chainhead;
  }
  private _ds_get_license_plates_by_ids: AsnOrders_ds_get_license_plates_by_idsService;
  public get ds_get_license_plates_by_ids(): AsnOrders_ds_get_license_plates_by_idsService {
    if(!this._ds_get_license_plates_by_ids) {
      this._ds_get_license_plates_by_ids = this.injector.get(AsnOrders_ds_get_license_plates_by_idsService);
    }
    return this._ds_get_license_plates_by_ids;
  }
  private _ds_get_licenseplate_by_licenseplateId: AsnOrders_ds_get_licenseplate_by_licenseplateIdService;
  public get ds_get_licenseplate_by_licenseplateId(): AsnOrders_ds_get_licenseplate_by_licenseplateIdService {
    if(!this._ds_get_licenseplate_by_licenseplateId) {
      this._ds_get_licenseplate_by_licenseplateId = this.injector.get(AsnOrders_ds_get_licenseplate_by_licenseplateIdService);
    }
    return this._ds_get_licenseplate_by_licenseplateId;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId: AsnOrders_ds_get_licenseplatecontents_by_licenseplateIdService;
  public get ds_get_licenseplatecontents_by_licenseplateId(): AsnOrders_ds_get_licenseplatecontents_by_licenseplateIdService {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId) {
      this._ds_get_licenseplatecontents_by_licenseplateId = this.injector.get(AsnOrders_ds_get_licenseplatecontents_by_licenseplateIdService);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId: AsnOrders_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService;
  public get ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId(): AsnOrders_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId) {
      this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId = this.injector.get(AsnOrders_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId;
  }
  private _ds_get_licenseplates_by_lookupcode_and_warehouseId: AsnOrders_ds_get_licenseplates_by_lookupcode_and_warehouseIdService;
  public get ds_get_licenseplates_by_lookupcode_and_warehouseId(): AsnOrders_ds_get_licenseplates_by_lookupcode_and_warehouseIdService {
    if(!this._ds_get_licenseplates_by_lookupcode_and_warehouseId) {
      this._ds_get_licenseplates_by_lookupcode_and_warehouseId = this.injector.get(AsnOrders_ds_get_licenseplates_by_lookupcode_and_warehouseIdService);
    }
    return this._ds_get_licenseplates_by_lookupcode_and_warehouseId;
  }
  private _ds_get_licenseplates_by_orderId: AsnOrders_ds_get_licenseplates_by_orderIdService;
  public get ds_get_licenseplates_by_orderId(): AsnOrders_ds_get_licenseplates_by_orderIdService {
    if(!this._ds_get_licenseplates_by_orderId) {
      this._ds_get_licenseplates_by_orderId = this.injector.get(AsnOrders_ds_get_licenseplates_by_orderIdService);
    }
    return this._ds_get_licenseplates_by_orderId;
  }
  private _ds_get_licenseplates_by_shipmentId_or_ids: AsnOrders_ds_get_licenseplates_by_shipmentId_or_idsService;
  public get ds_get_licenseplates_by_shipmentId_or_ids(): AsnOrders_ds_get_licenseplates_by_shipmentId_or_idsService {
    if(!this._ds_get_licenseplates_by_shipmentId_or_ids) {
      this._ds_get_licenseplates_by_shipmentId_or_ids = this.injector.get(AsnOrders_ds_get_licenseplates_by_shipmentId_or_idsService);
    }
    return this._ds_get_licenseplates_by_shipmentId_or_ids;
  }
  private _ds_get_location_by_locationId: AsnOrders_ds_get_location_by_locationIdService;
  public get ds_get_location_by_locationId(): AsnOrders_ds_get_location_by_locationIdService {
    if(!this._ds_get_location_by_locationId) {
      this._ds_get_location_by_locationId = this.injector.get(AsnOrders_ds_get_location_by_locationIdService);
    }
    return this._ds_get_location_by_locationId;
  }
  private _ds_get_material_by_materialId: AsnOrders_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): AsnOrders_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(AsnOrders_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId: AsnOrders_ds_get_material_packaging_by_materialId_and_packagingIdService;
  public get ds_get_material_packaging_by_materialId_and_packagingId(): AsnOrders_ds_get_material_packaging_by_materialId_and_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId) {
      this._ds_get_material_packaging_by_materialId_and_packagingId = this.injector.get(AsnOrders_ds_get_material_packaging_by_materialId_and_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId;
  }
  private _ds_get_materialpackagings_by_materialIds: AsnOrders_ds_get_materialpackagings_by_materialIdsService;
  public get ds_get_materialpackagings_by_materialIds(): AsnOrders_ds_get_materialpackagings_by_materialIdsService {
    if(!this._ds_get_materialpackagings_by_materialIds) {
      this._ds_get_materialpackagings_by_materialIds = this.injector.get(AsnOrders_ds_get_materialpackagings_by_materialIdsService);
    }
    return this._ds_get_materialpackagings_by_materialIds;
  }
  private _ds_get_order_account_address_by_orderId: AsnOrders_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): AsnOrders_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(AsnOrders_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_address_by_orderId_top1: AsnOrders_ds_get_order_address_by_orderId_top1Service;
  public get ds_get_order_address_by_orderId_top1(): AsnOrders_ds_get_order_address_by_orderId_top1Service {
    if(!this._ds_get_order_address_by_orderId_top1) {
      this._ds_get_order_address_by_orderId_top1 = this.injector.get(AsnOrders_ds_get_order_address_by_orderId_top1Service);
    }
    return this._ds_get_order_address_by_orderId_top1;
  }
  private _ds_get_order_by_orderId: AsnOrders_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): AsnOrders_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(AsnOrders_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_order_by_orderId_detailed: AsnOrders_ds_get_order_by_orderId_detailedService;
  public get ds_get_order_by_orderId_detailed(): AsnOrders_ds_get_order_by_orderId_detailedService {
    if(!this._ds_get_order_by_orderId_detailed) {
      this._ds_get_order_by_orderId_detailed = this.injector.get(AsnOrders_ds_get_order_by_orderId_detailedService);
    }
    return this._ds_get_order_by_orderId_detailed;
  }
  private _ds_get_order_details_by_shipmentId: AsnOrders_ds_get_order_details_by_shipmentIdService;
  public get ds_get_order_details_by_shipmentId(): AsnOrders_ds_get_order_details_by_shipmentIdService {
    if(!this._ds_get_order_details_by_shipmentId) {
      this._ds_get_order_details_by_shipmentId = this.injector.get(AsnOrders_ds_get_order_details_by_shipmentIdService);
    }
    return this._ds_get_order_details_by_shipmentId;
  }
  private _ds_get_order_licenseplates: AsnOrders_ds_get_order_licenseplatesService;
  public get ds_get_order_licenseplates(): AsnOrders_ds_get_order_licenseplatesService {
    if(!this._ds_get_order_licenseplates) {
      this._ds_get_order_licenseplates = this.injector.get(AsnOrders_ds_get_order_licenseplatesService);
    }
    return this._ds_get_order_licenseplates;
  }
  private _ds_get_order_owner_contact_top1: AsnOrders_ds_get_order_owner_contact_top1Service;
  public get ds_get_order_owner_contact_top1(): AsnOrders_ds_get_order_owner_contact_top1Service {
    if(!this._ds_get_order_owner_contact_top1) {
      this._ds_get_order_owner_contact_top1 = this.injector.get(AsnOrders_ds_get_order_owner_contact_top1Service);
    }
    return this._ds_get_order_owner_contact_top1;
  }
  private _ds_get_order_ship_from_account_vs_order_address: AsnOrders_ds_get_order_ship_from_account_vs_order_addressService;
  public get ds_get_order_ship_from_account_vs_order_address(): AsnOrders_ds_get_order_ship_from_account_vs_order_addressService {
    if(!this._ds_get_order_ship_from_account_vs_order_address) {
      this._ds_get_order_ship_from_account_vs_order_address = this.injector.get(AsnOrders_ds_get_order_ship_from_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_from_account_vs_order_address;
  }
  private _ds_get_order_validation_result_most_recent: AsnOrders_ds_get_order_validation_result_most_recentService;
  public get ds_get_order_validation_result_most_recent(): AsnOrders_ds_get_order_validation_result_most_recentService {
    if(!this._ds_get_order_validation_result_most_recent) {
      this._ds_get_order_validation_result_most_recent = this.injector.get(AsnOrders_ds_get_order_validation_result_most_recentService);
    }
    return this._ds_get_order_validation_result_most_recent;
  }
  private _ds_get_order_warehouse_contact_top1: AsnOrders_ds_get_order_warehouse_contact_top1Service;
  public get ds_get_order_warehouse_contact_top1(): AsnOrders_ds_get_order_warehouse_contact_top1Service {
    if(!this._ds_get_order_warehouse_contact_top1) {
      this._ds_get_order_warehouse_contact_top1 = this.injector.get(AsnOrders_ds_get_order_warehouse_contact_top1Service);
    }
    return this._ds_get_order_warehouse_contact_top1;
  }
  private _ds_get_orderaddresses_by_orderId: AsnOrders_ds_get_orderaddresses_by_orderIdService;
  public get ds_get_orderaddresses_by_orderId(): AsnOrders_ds_get_orderaddresses_by_orderIdService {
    if(!this._ds_get_orderaddresses_by_orderId) {
      this._ds_get_orderaddresses_by_orderId = this.injector.get(AsnOrders_ds_get_orderaddresses_by_orderIdService);
    }
    return this._ds_get_orderaddresses_by_orderId;
  }
  private _ds_get_orderlines_by_content: AsnOrders_ds_get_orderlines_by_contentService;
  public get ds_get_orderlines_by_content(): AsnOrders_ds_get_orderlines_by_contentService {
    if(!this._ds_get_orderlines_by_content) {
      this._ds_get_orderlines_by_content = this.injector.get(AsnOrders_ds_get_orderlines_by_contentService);
    }
    return this._ds_get_orderlines_by_content;
  }
  private _ds_get_orderlines_by_orderId: AsnOrders_ds_get_orderlines_by_orderIdService;
  public get ds_get_orderlines_by_orderId(): AsnOrders_ds_get_orderlines_by_orderIdService {
    if(!this._ds_get_orderlines_by_orderId) {
      this._ds_get_orderlines_by_orderId = this.injector.get(AsnOrders_ds_get_orderlines_by_orderIdService);
    }
    return this._ds_get_orderlines_by_orderId;
  }
  private _ds_get_orderlines_by_orderId_bottom1: AsnOrders_ds_get_orderlines_by_orderId_bottom1Service;
  public get ds_get_orderlines_by_orderId_bottom1(): AsnOrders_ds_get_orderlines_by_orderId_bottom1Service {
    if(!this._ds_get_orderlines_by_orderId_bottom1) {
      this._ds_get_orderlines_by_orderId_bottom1 = this.injector.get(AsnOrders_ds_get_orderlines_by_orderId_bottom1Service);
    }
    return this._ds_get_orderlines_by_orderId_bottom1;
  }
  private _ds_get_orderlines_by_orderId_linenumber: AsnOrders_ds_get_orderlines_by_orderId_linenumberService;
  public get ds_get_orderlines_by_orderId_linenumber(): AsnOrders_ds_get_orderlines_by_orderId_linenumberService {
    if(!this._ds_get_orderlines_by_orderId_linenumber) {
      this._ds_get_orderlines_by_orderId_linenumber = this.injector.get(AsnOrders_ds_get_orderlines_by_orderId_linenumberService);
    }
    return this._ds_get_orderlines_by_orderId_linenumber;
  }
  private _ds_get_ordervalidationresults_by_orderId: AsnOrders_ds_get_ordervalidationresults_by_orderIdService;
  public get ds_get_ordervalidationresults_by_orderId(): AsnOrders_ds_get_ordervalidationresults_by_orderIdService {
    if(!this._ds_get_ordervalidationresults_by_orderId) {
      this._ds_get_ordervalidationresults_by_orderId = this.injector.get(AsnOrders_ds_get_ordervalidationresults_by_orderIdService);
    }
    return this._ds_get_ordervalidationresults_by_orderId;
  }
  private _ds_get_planned_asn_licenseplates_by_shipmentId: AsnOrders_ds_get_planned_asn_licenseplates_by_shipmentIdService;
  public get ds_get_planned_asn_licenseplates_by_shipmentId(): AsnOrders_ds_get_planned_asn_licenseplates_by_shipmentIdService {
    if(!this._ds_get_planned_asn_licenseplates_by_shipmentId) {
      this._ds_get_planned_asn_licenseplates_by_shipmentId = this.injector.get(AsnOrders_ds_get_planned_asn_licenseplates_by_shipmentIdService);
    }
    return this._ds_get_planned_asn_licenseplates_by_shipmentId;
  }
  private _ds_get_planned_receiving_tasks_by_licenseplateId_top1: AsnOrders_ds_get_planned_receiving_tasks_by_licenseplateId_top1Service;
  public get ds_get_planned_receiving_tasks_by_licenseplateId_top1(): AsnOrders_ds_get_planned_receiving_tasks_by_licenseplateId_top1Service {
    if(!this._ds_get_planned_receiving_tasks_by_licenseplateId_top1) {
      this._ds_get_planned_receiving_tasks_by_licenseplateId_top1 = this.injector.get(AsnOrders_ds_get_planned_receiving_tasks_by_licenseplateId_top1Service);
    }
    return this._ds_get_planned_receiving_tasks_by_licenseplateId_top1;
  }
  private _ds_get_planned_receiving_tasks_by_orderId_shipmentId: AsnOrders_ds_get_planned_receiving_tasks_by_orderId_shipmentIdService;
  public get ds_get_planned_receiving_tasks_by_orderId_shipmentId(): AsnOrders_ds_get_planned_receiving_tasks_by_orderId_shipmentIdService {
    if(!this._ds_get_planned_receiving_tasks_by_orderId_shipmentId) {
      this._ds_get_planned_receiving_tasks_by_orderId_shipmentId = this.injector.get(AsnOrders_ds_get_planned_receiving_tasks_by_orderId_shipmentIdService);
    }
    return this._ds_get_planned_receiving_tasks_by_orderId_shipmentId;
  }
  private _ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1: AsnOrders_ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1Service;
  public get ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1(): AsnOrders_ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1Service {
    if(!this._ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1) {
      this._ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1 = this.injector.get(AsnOrders_ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1Service);
    }
    return this._ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1;
  }
  private _ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentId: AsnOrders_ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentIdService;
  public get ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentId(): AsnOrders_ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentIdService {
    if(!this._ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentId) {
      this._ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentId = this.injector.get(AsnOrders_ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentIdService);
    }
    return this._ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentId;
  }
  private _ds_get_putaway_tasks_by_licenseplateId_shipmentId: AsnOrders_ds_get_putaway_tasks_by_licenseplateId_shipmentIdService;
  public get ds_get_putaway_tasks_by_licenseplateId_shipmentId(): AsnOrders_ds_get_putaway_tasks_by_licenseplateId_shipmentIdService {
    if(!this._ds_get_putaway_tasks_by_licenseplateId_shipmentId) {
      this._ds_get_putaway_tasks_by_licenseplateId_shipmentId = this.injector.get(AsnOrders_ds_get_putaway_tasks_by_licenseplateId_shipmentIdService);
    }
    return this._ds_get_putaway_tasks_by_licenseplateId_shipmentId;
  }
  private _ds_get_putaway_tasks_by_shipmentId: AsnOrders_ds_get_putaway_tasks_by_shipmentIdService;
  public get ds_get_putaway_tasks_by_shipmentId(): AsnOrders_ds_get_putaway_tasks_by_shipmentIdService {
    if(!this._ds_get_putaway_tasks_by_shipmentId) {
      this._ds_get_putaway_tasks_by_shipmentId = this.injector.get(AsnOrders_ds_get_putaway_tasks_by_shipmentIdService);
    }
    return this._ds_get_putaway_tasks_by_shipmentId;
  }
  private _ds_get_received_licenseplates_by_orderId_shipmentId: AsnOrders_ds_get_received_licenseplates_by_orderId_shipmentIdService;
  public get ds_get_received_licenseplates_by_orderId_shipmentId(): AsnOrders_ds_get_received_licenseplates_by_orderId_shipmentIdService {
    if(!this._ds_get_received_licenseplates_by_orderId_shipmentId) {
      this._ds_get_received_licenseplates_by_orderId_shipmentId = this.injector.get(AsnOrders_ds_get_received_licenseplates_by_orderId_shipmentIdService);
    }
    return this._ds_get_received_licenseplates_by_orderId_shipmentId;
  }
  private _ds_get_receiving_tasks_by_licenseplateId_lotId_packagingId: AsnOrders_ds_get_receiving_tasks_by_licenseplateId_lotId_packagingIdService;
  public get ds_get_receiving_tasks_by_licenseplateId_lotId_packagingId(): AsnOrders_ds_get_receiving_tasks_by_licenseplateId_lotId_packagingIdService {
    if(!this._ds_get_receiving_tasks_by_licenseplateId_lotId_packagingId) {
      this._ds_get_receiving_tasks_by_licenseplateId_lotId_packagingId = this.injector.get(AsnOrders_ds_get_receiving_tasks_by_licenseplateId_lotId_packagingIdService);
    }
    return this._ds_get_receiving_tasks_by_licenseplateId_lotId_packagingId;
  }
  private _ds_get_receiving_tasks_by_orderId: AsnOrders_ds_get_receiving_tasks_by_orderIdService;
  public get ds_get_receiving_tasks_by_orderId(): AsnOrders_ds_get_receiving_tasks_by_orderIdService {
    if(!this._ds_get_receiving_tasks_by_orderId) {
      this._ds_get_receiving_tasks_by_orderId = this.injector.get(AsnOrders_ds_get_receiving_tasks_by_orderIdService);
    }
    return this._ds_get_receiving_tasks_by_orderId;
  }
  private _ds_get_released_receiving_tasks_by_licenseplateId_top1: AsnOrders_ds_get_released_receiving_tasks_by_licenseplateId_top1Service;
  public get ds_get_released_receiving_tasks_by_licenseplateId_top1(): AsnOrders_ds_get_released_receiving_tasks_by_licenseplateId_top1Service {
    if(!this._ds_get_released_receiving_tasks_by_licenseplateId_top1) {
      this._ds_get_released_receiving_tasks_by_licenseplateId_top1 = this.injector.get(AsnOrders_ds_get_released_receiving_tasks_by_licenseplateId_top1Service);
    }
    return this._ds_get_released_receiving_tasks_by_licenseplateId_top1;
  }
  private _ds_get_released_receiving_tasks_by_orderId_shipmentId_top1: AsnOrders_ds_get_released_receiving_tasks_by_orderId_shipmentId_top1Service;
  public get ds_get_released_receiving_tasks_by_orderId_shipmentId_top1(): AsnOrders_ds_get_released_receiving_tasks_by_orderId_shipmentId_top1Service {
    if(!this._ds_get_released_receiving_tasks_by_orderId_shipmentId_top1) {
      this._ds_get_released_receiving_tasks_by_orderId_shipmentId_top1 = this.injector.get(AsnOrders_ds_get_released_receiving_tasks_by_orderId_shipmentId_top1Service);
    }
    return this._ds_get_released_receiving_tasks_by_orderId_shipmentId_top1;
  }
  private _ds_get_serialcreation_task_by_serialnumberId: AsnOrders_ds_get_serialcreation_task_by_serialnumberIdService;
  public get ds_get_serialcreation_task_by_serialnumberId(): AsnOrders_ds_get_serialcreation_task_by_serialnumberIdService {
    if(!this._ds_get_serialcreation_task_by_serialnumberId) {
      this._ds_get_serialcreation_task_by_serialnumberId = this.injector.get(AsnOrders_ds_get_serialcreation_task_by_serialnumberIdService);
    }
    return this._ds_get_serialcreation_task_by_serialnumberId;
  }
  private _ds_get_serialcreation_tasks_by_chainhead: AsnOrders_ds_get_serialcreation_tasks_by_chainheadService;
  public get ds_get_serialcreation_tasks_by_chainhead(): AsnOrders_ds_get_serialcreation_tasks_by_chainheadService {
    if(!this._ds_get_serialcreation_tasks_by_chainhead) {
      this._ds_get_serialcreation_tasks_by_chainhead = this.injector.get(AsnOrders_ds_get_serialcreation_tasks_by_chainheadService);
    }
    return this._ds_get_serialcreation_tasks_by_chainhead;
  }
  private _ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup: AsnOrders_ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookupService;
  public get ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup(): AsnOrders_ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookupService {
    if(!this._ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup) {
      this._ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup = this.injector.get(AsnOrders_ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookupService);
    }
    return this._ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup;
  }
  private _ds_get_serialnumbers_by_licenseplateId_lotId_packagingId: AsnOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService;
  public get ds_get_serialnumbers_by_licenseplateId_lotId_packagingId(): AsnOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService {
    if(!this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId) {
      this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId = this.injector.get(AsnOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService);
    }
    return this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId;
  }
  private _ds_get_serialnumbers_by_serialNumberIds: AsnOrders_ds_get_serialnumbers_by_serialNumberIdsService;
  public get ds_get_serialnumbers_by_serialNumberIds(): AsnOrders_ds_get_serialnumbers_by_serialNumberIdsService {
    if(!this._ds_get_serialnumbers_by_serialNumberIds) {
      this._ds_get_serialnumbers_by_serialNumberIds = this.injector.get(AsnOrders_ds_get_serialnumbers_by_serialNumberIdsService);
    }
    return this._ds_get_serialnumbers_by_serialNumberIds;
  }
  private _ds_get_serialpurge_tasks_by_chainhead: AsnOrders_ds_get_serialpurge_tasks_by_chainheadService;
  public get ds_get_serialpurge_tasks_by_chainhead(): AsnOrders_ds_get_serialpurge_tasks_by_chainheadService {
    if(!this._ds_get_serialpurge_tasks_by_chainhead) {
      this._ds_get_serialpurge_tasks_by_chainhead = this.injector.get(AsnOrders_ds_get_serialpurge_tasks_by_chainheadService);
    }
    return this._ds_get_serialpurge_tasks_by_chainhead;
  }
  private _ds_get_shipmentLines_by_orderId_shipmentId: AsnOrders_ds_get_shipmentLines_by_orderId_shipmentIdService;
  public get ds_get_shipmentLines_by_orderId_shipmentId(): AsnOrders_ds_get_shipmentLines_by_orderId_shipmentIdService {
    if(!this._ds_get_shipmentLines_by_orderId_shipmentId) {
      this._ds_get_shipmentLines_by_orderId_shipmentId = this.injector.get(AsnOrders_ds_get_shipmentLines_by_orderId_shipmentIdService);
    }
    return this._ds_get_shipmentLines_by_orderId_shipmentId;
  }
  private _ds_get_shipmentLines_by_orderId_top1: AsnOrders_ds_get_shipmentLines_by_orderId_top1Service;
  public get ds_get_shipmentLines_by_orderId_top1(): AsnOrders_ds_get_shipmentLines_by_orderId_top1Service {
    if(!this._ds_get_shipmentLines_by_orderId_top1) {
      this._ds_get_shipmentLines_by_orderId_top1 = this.injector.get(AsnOrders_ds_get_shipmentLines_by_orderId_top1Service);
    }
    return this._ds_get_shipmentLines_by_orderId_top1;
  }
  private _ds_get_shipmentLines_by_shipmentId_top1: AsnOrders_ds_get_shipmentLines_by_shipmentId_top1Service;
  public get ds_get_shipmentLines_by_shipmentId_top1(): AsnOrders_ds_get_shipmentLines_by_shipmentId_top1Service {
    if(!this._ds_get_shipmentLines_by_shipmentId_top1) {
      this._ds_get_shipmentLines_by_shipmentId_top1 = this.injector.get(AsnOrders_ds_get_shipmentLines_by_shipmentId_top1Service);
    }
    return this._ds_get_shipmentLines_by_shipmentId_top1;
  }
  private _ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingId: AsnOrders_ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingIdService;
  public get ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingId(): AsnOrders_ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingIdService {
    if(!this._ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingId) {
      this._ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingId = this.injector.get(AsnOrders_ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingIdService);
    }
    return this._ds_get_shipmentline_by_orderId_shipmentId_materialId_lotId_packagingId;
  }
  private _ds_get_shipmentline_by_shipmentlineId: AsnOrders_ds_get_shipmentline_by_shipmentlineIdService;
  public get ds_get_shipmentline_by_shipmentlineId(): AsnOrders_ds_get_shipmentline_by_shipmentlineIdService {
    if(!this._ds_get_shipmentline_by_shipmentlineId) {
      this._ds_get_shipmentline_by_shipmentlineId = this.injector.get(AsnOrders_ds_get_shipmentline_by_shipmentlineIdService);
    }
    return this._ds_get_shipmentline_by_shipmentlineId;
  }
  private _ds_get_shipmentlines_by_orderId_bottom1: AsnOrders_ds_get_shipmentlines_by_orderId_bottom1Service;
  public get ds_get_shipmentlines_by_orderId_bottom1(): AsnOrders_ds_get_shipmentlines_by_orderId_bottom1Service {
    if(!this._ds_get_shipmentlines_by_orderId_bottom1) {
      this._ds_get_shipmentlines_by_orderId_bottom1 = this.injector.get(AsnOrders_ds_get_shipmentlines_by_orderId_bottom1Service);
    }
    return this._ds_get_shipmentlines_by_orderId_bottom1;
  }
  private _ds_get_shipmentlines_by_orderline: AsnOrders_ds_get_shipmentlines_by_orderlineService;
  public get ds_get_shipmentlines_by_orderline(): AsnOrders_ds_get_shipmentlines_by_orderlineService {
    if(!this._ds_get_shipmentlines_by_orderline) {
      this._ds_get_shipmentlines_by_orderline = this.injector.get(AsnOrders_ds_get_shipmentlines_by_orderlineService);
    }
    return this._ds_get_shipmentlines_by_orderline;
  }
  private _ds_get_shipments_by_orderId: AsnOrders_ds_get_shipments_by_orderIdService;
  public get ds_get_shipments_by_orderId(): AsnOrders_ds_get_shipments_by_orderIdService {
    if(!this._ds_get_shipments_by_orderId) {
      this._ds_get_shipments_by_orderId = this.injector.get(AsnOrders_ds_get_shipments_by_orderIdService);
    }
    return this._ds_get_shipments_by_orderId;
  }
  private _ds_get_shipments_by_shipmentId: AsnOrders_ds_get_shipments_by_shipmentIdService;
  public get ds_get_shipments_by_shipmentId(): AsnOrders_ds_get_shipments_by_shipmentIdService {
    if(!this._ds_get_shipments_by_shipmentId) {
      this._ds_get_shipments_by_shipmentId = this.injector.get(AsnOrders_ds_get_shipments_by_shipmentIdService);
    }
    return this._ds_get_shipments_by_shipmentId;
  }
  private _ds_get_task_by_taskId: AsnOrders_ds_get_task_by_taskIdService;
  public get ds_get_task_by_taskId(): AsnOrders_ds_get_task_by_taskIdService {
    if(!this._ds_get_task_by_taskId) {
      this._ds_get_task_by_taskId = this.injector.get(AsnOrders_ds_get_task_by_taskIdService);
    }
    return this._ds_get_task_by_taskId;
  }
  private _ds_get_tasks_by_chainHead: AsnOrders_ds_get_tasks_by_chainHeadService;
  public get ds_get_tasks_by_chainHead(): AsnOrders_ds_get_tasks_by_chainHeadService {
    if(!this._ds_get_tasks_by_chainHead) {
      this._ds_get_tasks_by_chainHead = this.injector.get(AsnOrders_ds_get_tasks_by_chainHeadService);
    }
    return this._ds_get_tasks_by_chainHead;
  }
  private _ds_get_tasks_by_orderId_shipmentId: AsnOrders_ds_get_tasks_by_orderId_shipmentIdService;
  public get ds_get_tasks_by_orderId_shipmentId(): AsnOrders_ds_get_tasks_by_orderId_shipmentIdService {
    if(!this._ds_get_tasks_by_orderId_shipmentId) {
      this._ds_get_tasks_by_orderId_shipmentId = this.injector.get(AsnOrders_ds_get_tasks_by_orderId_shipmentIdService);
    }
    return this._ds_get_tasks_by_orderId_shipmentId;
  }
  private _ds_get_tasks_by_serialnumberIds: AsnOrders_ds_get_tasks_by_serialnumberIdsService;
  public get ds_get_tasks_by_serialnumberIds(): AsnOrders_ds_get_tasks_by_serialnumberIdsService {
    if(!this._ds_get_tasks_by_serialnumberIds) {
      this._ds_get_tasks_by_serialnumberIds = this.injector.get(AsnOrders_ds_get_tasks_by_serialnumberIdsService);
    }
    return this._ds_get_tasks_by_serialnumberIds;
  }
  private _ds_get_validation_tasks_by_licenseplateId_shipmentId: AsnOrders_ds_get_validation_tasks_by_licenseplateId_shipmentIdService;
  public get ds_get_validation_tasks_by_licenseplateId_shipmentId(): AsnOrders_ds_get_validation_tasks_by_licenseplateId_shipmentIdService {
    if(!this._ds_get_validation_tasks_by_licenseplateId_shipmentId) {
      this._ds_get_validation_tasks_by_licenseplateId_shipmentId = this.injector.get(AsnOrders_ds_get_validation_tasks_by_licenseplateId_shipmentIdService);
    }
    return this._ds_get_validation_tasks_by_licenseplateId_shipmentId;
  }
  private _ds_get_warehouse_by_warehouseId: AsnOrders_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): AsnOrders_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(AsnOrders_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_license_plate_label_report: AsnOrders_ds_license_plate_label_reportService;
  public get ds_license_plate_label_report(): AsnOrders_ds_license_plate_label_reportService {
    if(!this._ds_license_plate_label_report) {
      this._ds_license_plate_label_report = this.injector.get(AsnOrders_ds_license_plate_label_reportService);
    }
    return this._ds_license_plate_label_report;
  }
  private _ds_license_plate_labels_by_order_report: AsnOrders_ds_license_plate_labels_by_order_reportService;
  public get ds_license_plate_labels_by_order_report(): AsnOrders_ds_license_plate_labels_by_order_reportService {
    if(!this._ds_license_plate_labels_by_order_report) {
      this._ds_license_plate_labels_by_order_report = this.injector.get(AsnOrders_ds_license_plate_labels_by_order_reportService);
    }
    return this._ds_license_plate_labels_by_order_report;
  }
  private _ds_licenseplates_by_order_dd: AsnOrders_ds_licenseplates_by_order_ddService;
  public get ds_licenseplates_by_order_dd(): AsnOrders_ds_licenseplates_by_order_ddService {
    if(!this._ds_licenseplates_by_order_dd) {
      this._ds_licenseplates_by_order_dd = this.injector.get(AsnOrders_ds_licenseplates_by_order_ddService);
    }
    return this._ds_licenseplates_by_order_dd;
  }
  private _ds_orderclasses_dd: AsnOrders_ds_orderclasses_ddService;
  public get ds_orderclasses_dd(): AsnOrders_ds_orderclasses_ddService {
    if(!this._ds_orderclasses_dd) {
      this._ds_orderclasses_dd = this.injector.get(AsnOrders_ds_orderclasses_ddService);
    }
    return this._ds_orderclasses_dd;
  }
  private _ds_orderline_total_amount: AsnOrders_ds_orderline_total_amountService;
  public get ds_orderline_total_amount(): AsnOrders_ds_orderline_total_amountService {
    if(!this._ds_orderline_total_amount) {
      this._ds_orderline_total_amount = this.injector.get(AsnOrders_ds_orderline_total_amountService);
    }
    return this._ds_orderline_total_amount;
  }
  private _ds_orderline_total_amount_widget: AsnOrders_ds_orderline_total_amount_widgetService;
  public get ds_orderline_total_amount_widget(): AsnOrders_ds_orderline_total_amount_widgetService {
    if(!this._ds_orderline_total_amount_widget) {
      this._ds_orderline_total_amount_widget = this.injector.get(AsnOrders_ds_orderline_total_amount_widgetService);
    }
    return this._ds_orderline_total_amount_widget;
  }
  private _ds_orderline_total_gross_received_widget: AsnOrders_ds_orderline_total_gross_received_widgetService;
  public get ds_orderline_total_gross_received_widget(): AsnOrders_ds_orderline_total_gross_received_widgetService {
    if(!this._ds_orderline_total_gross_received_widget) {
      this._ds_orderline_total_gross_received_widget = this.injector.get(AsnOrders_ds_orderline_total_gross_received_widgetService);
    }
    return this._ds_orderline_total_gross_received_widget;
  }
  private _ds_orderline_total_received: AsnOrders_ds_orderline_total_receivedService;
  public get ds_orderline_total_received(): AsnOrders_ds_orderline_total_receivedService {
    if(!this._ds_orderline_total_received) {
      this._ds_orderline_total_received = this.injector.get(AsnOrders_ds_orderline_total_receivedService);
    }
    return this._ds_orderline_total_received;
  }
  private _ds_orderline_total_received_widget: AsnOrders_ds_orderline_total_received_widgetService;
  public get ds_orderline_total_received_widget(): AsnOrders_ds_orderline_total_received_widgetService {
    if(!this._ds_orderline_total_received_widget) {
      this._ds_orderline_total_received_widget = this.injector.get(AsnOrders_ds_orderline_total_received_widgetService);
    }
    return this._ds_orderline_total_received_widget;
  }
  private _ds_orders_grid: AsnOrders_ds_orders_gridService;
  public get ds_orders_grid(): AsnOrders_ds_orders_gridService {
    if(!this._ds_orders_grid) {
      this._ds_orders_grid = this.injector.get(AsnOrders_ds_orders_gridService);
    }
    return this._ds_orders_grid;
  }
  private _ds_receiving_details_report: AsnOrders_ds_receiving_details_reportService;
  public get ds_receiving_details_report(): AsnOrders_ds_receiving_details_reportService {
    if(!this._ds_receiving_details_report) {
      this._ds_receiving_details_report = this.injector.get(AsnOrders_ds_receiving_details_reportService);
    }
    return this._ds_receiving_details_report;
  }
  private _ds_receiving_header_report: AsnOrders_ds_receiving_header_reportService;
  public get ds_receiving_header_report(): AsnOrders_ds_receiving_header_reportService {
    if(!this._ds_receiving_header_report) {
      this._ds_receiving_header_report = this.injector.get(AsnOrders_ds_receiving_header_reportService);
    }
    return this._ds_receiving_header_report;
  }
}

