import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LaborManagement_ds_task_assignment_grid_baseService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseIds?: number[], workClassIds?: number[], fromDate?: string, toDate?: string, fullTextSearch?: string, isAssigned?: boolean, projectId?: number, statusIds?: number[], operationCodeIds?: number[], ownerId?: number, userIds?: string[], chainHeads?: number[] }): 
  Promise<{ result: { Id?: number, ChainHead?: number, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, Priority?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, OwnerId?: number }, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, Order?: { Id?: number, LookupCode?: string }, PickSlip?: { Id?: number, Wave?: { Id?: number } } }[] }> 
  {
    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_task_assignment_grid_base/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseIds?: number[], workClassIds?: number[], fromDate?: string, toDate?: string, fullTextSearch?: string, isAssigned?: boolean, projectId?: number, statusIds?: number[], operationCodeIds?: number[], ownerId?: number, userIds?: string[], chainHeads?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ChainHead?: number, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, Priority?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, OwnerId?: number }, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, Order?: { Id?: number, LookupCode?: string }, PickSlip?: { Id?: number, Wave?: { Id?: number } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_task_assignment_grid_base/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseIds?: number[], workClassIds?: number[], fromDate?: string, toDate?: string, fullTextSearch?: string, isAssigned?: boolean, projectId?: number, statusIds?: number[], operationCodeIds?: number[], ownerId?: number, userIds?: string[], chainHeads?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ChainHead?: number, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, Priority?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, OwnerId?: number }, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, Order?: { Id?: number, LookupCode?: string }, PickSlip?: { Id?: number, Wave?: { Id?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_task_assignment_grid_base/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
