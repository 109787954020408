import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_ds_transload_order_receiving_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_transload_order_receiving_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_transload_order_receiving_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_transload_order_receiving_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
