import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_outbound_orders_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, take?: number, skip?: number, materialIds?: number[], dateType?: string, waveId?: number, orderIds?: number[], ship_to_search?: string }): 
  Promise<{ result: { Id?: number, CancelThresholdDate?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Cartonized?: boolean, ContainerSize?: string, ExpectedDate?: string, ExpectedWarehouseId?: number, LookupCode?: string, PickupDate?: string, ShippedDate?: string, StatusId?: number, TrackingIdentifier?: string, WaveId?: number, Wave?: { Description?: string, StatusId?: number, Status?: { Name?: string } }, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string } } }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_outbound_orders_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, take?: number, skip?: number, materialIds?: number[], dateType?: string, waveId?: number, orderIds?: number[], ship_to_search?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CancelThresholdDate?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Cartonized?: boolean, ContainerSize?: string, ExpectedDate?: string, ExpectedWarehouseId?: number, LookupCode?: string, PickupDate?: string, ShippedDate?: string, StatusId?: number, TrackingIdentifier?: string, WaveId?: number, Wave?: { Description?: string, StatusId?: number, Status?: { Name?: string } }, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string } } }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_outbound_orders_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, take?: number, skip?: number, materialIds?: number[], dateType?: string, waveId?: number, orderIds?: number[], ship_to_search?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, CancelThresholdDate?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Cartonized?: boolean, ContainerSize?: string, ExpectedDate?: string, ExpectedWarehouseId?: number, LookupCode?: string, PickupDate?: string, ShippedDate?: string, StatusId?: number, TrackingIdentifier?: string, WaveId?: number, Wave?: { Description?: string, StatusId?: number, Status?: { Name?: string } }, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string } } }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_outbound_orders_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
