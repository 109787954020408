import { Type } from '@angular/core';
import { InputsType, OutputsType } from 'ng-dynamic-component'
import { Styles } from './style';

export class WidgetModel {
  hidden: boolean = false;
}

export class FatNumberStyles extends Styles {

  constructor(classes?: string[], style?: { [klass: string]: any }) {
    super(classes, style);
  }

  setBadClass() {
    this.removeClass([
      'bad',
      'medium',
      'good',
      'key',
    ]);

    this.addClass('bad');
  }

  setMediumClass() {
    this.removeClass([
      'bad',
      'medium',
      'good',
      'key',
    ]);

    this.addClass('medium');
  }

  setGoodClass() {
    this.removeClass([
      'bad',
      'medium',
      'good',
      'key',
    ]);

    this.addClass('good');
  }

  setKeyClass() {
    this.removeClass([
      'bad',
      'medium',
      'good',
      'key',
    ]);

    this.addClass('key');
  }
}