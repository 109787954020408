import { Inject, Injectable, Injector }from '@angular/core';



@Injectable({ providedIn: 'root' })
export class Usersnap_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Usersnap: Usersnap_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
}

