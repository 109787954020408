<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams"  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.quantity.displayControl.styles.style"
          [ngClass]="row.cells.quantity.displayControl.styles.classes">{{row.cells.quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.quantity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.quantity.editControl.styles.style"
            [ngClass]="row.cells.quantity.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.material_lookupcode.displayControl.styles.classes">{{row.cells.material_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="on_hand">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.on_hand.displayControl.styles.style"
          [ngClass]="row.cells.on_hand.displayControl.styles.classes">{{row.cells.on_hand.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.available.displayControl.styles.style"
          [ngClass]="row.cells.available.displayControl.styles.classes">{{row.cells.available.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot.displayControl.styles.classes">{{row.cells.vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.expiration_date.displayControl.styles.style"
          [ngClass]="row.cells.expiration_date.displayControl.styles.classes">{{row.cells.expiration_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="manufacture_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.manufacture_date.displayControl.styles.style"
          [ngClass]="row.cells.manufacture_date.displayControl.styles.classes">{{row.cells.manufacture_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_received_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.last_received_date.displayControl.styles.style"
          [ngClass]="row.cells.last_received_date.displayControl.styles.classes">{{row.cells.last_received_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.name.displayControl.styles.style"
          [ngClass]="row.cells.name.displayControl.styles.classes">{{row.cells.name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material_description.displayControl.styles.style"
          [ngClass]="row.cells.material_description.displayControl.styles.classes">{{row.cells.material_description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vat">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.vat.displayControl.styles.style"
          [ngClass]="row.cells.vat.displayControl.styles.classes">{{row.cells.vat.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
