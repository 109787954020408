import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Addresses_ds_ownersaccounts_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId: number, typeIds: number[], accountTypeId: number, fullTextSearch?: string }): 
  Promise<{ result: { OwnerId?: number, AccountId?: number, Account?: { Id?: number, LookupCode?: string, Name?: string, TypeId?: number, AccountsContactsLookup?: { AccountId?: number, ContactId?: number, Contact?: { AddressId?: number, FirstName?: string, LastName?: string, Notes?: string, PrimaryEmail?: string, PrimaryTelephone?: string, TypeId?: number, Type?: { Name?: string }, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerId)) {
      missingRequiredInParams.push('\'ownerId\'');
    }
    if (isNil(inParams.typeIds)) {
      missingRequiredInParams.push('\'typeIds\'');
    }
    if (isNil(inParams.accountTypeId)) {
      missingRequiredInParams.push('\'accountTypeId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_ownersaccounts_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId: number, typeIds: number[], accountTypeId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { OwnerId?: number, AccountId?: number, Account?: { Id?: number, LookupCode?: string, Name?: string, TypeId?: number, AccountsContactsLookup?: { AccountId?: number, ContactId?: number, Contact?: { AddressId?: number, FirstName?: string, LastName?: string, Notes?: string, PrimaryEmail?: string, PrimaryTelephone?: string, TypeId?: number, Type?: { Name?: string }, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerId)) {
      missingRequiredInParams.push('\'ownerId\'');
    }
    if (isNil(inParams.typeIds)) {
      missingRequiredInParams.push('\'typeIds\'');
    }
    if (isNil(inParams.accountTypeId)) {
      missingRequiredInParams.push('\'accountTypeId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_ownersaccounts_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId: number, typeIds: number[], accountTypeId: number, fullTextSearch?: string, $keys: { OwnerId?: number, AccountId?: number }[] }): 
  Promise<{ result: { OwnerId?: number, AccountId?: number, Account?: { Id?: number, LookupCode?: string, Name?: string, TypeId?: number, AccountsContactsLookup?: { AccountId?: number, ContactId?: number, Contact?: { AddressId?: number, FirstName?: string, LastName?: string, Notes?: string, PrimaryEmail?: string, PrimaryTelephone?: string, TypeId?: number, Type?: { Name?: string }, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerId)) {
      missingRequiredInParams.push('\'ownerId\'');
    }
    if (isNil(inParams.typeIds)) {
      missingRequiredInParams.push('\'typeIds\'');
    }
    if (isNil(inParams.accountTypeId)) {
      missingRequiredInParams.push('\'accountTypeId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_ownersaccounts_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
