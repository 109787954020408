import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_get_shipping_container_by_shippingContainerIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shippingContainerId: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, LicensePlates?: { Id?: number, Archived?: boolean, WarehouseId?: number }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_get_shipping_container_by_shippingContainerId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
