import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { isNil } from 'lodash-es';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  isLoggedIn = false;
  isSettingsLoaded = false;
  authenticating = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private utils: UtilsService,
    private settingsValuesService: SettingsValuesService
  ) { }

  ngOnInit(): void {
    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.authenticating = false;
        this.setIsLoggedIn();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status !== InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.authenticating = true;
      });
  }

  async setIsLoggedIn() {
    // TODO: handle scenario where more than one account logged in
    this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    if (this.isLoggedIn) {

      const settings = await this.utils.http.get(`${environment.backendUrl}api/settings`);
      this.settingsValuesService.SettingsService = settings;
      this.isSettingsLoaded = true;

      // if no current active account, default it to first
      if (isNil(this.authService.instance.getActiveAccount())) {
        this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
      }
    }
  }

  private isEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  onLoginClicked() {
    this.authService.loginRedirect();
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
