import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PackVerification_ds_get_shippingcontainer_by_shippingContainerIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shippingContainerId: number }): 
  Promise<{ result: { Id?: number, Height?: number, Length?: number, LookupCode?: string, TrackingNumber?: string, TrailerNumber?: string, Volume?: number, Weight?: number, Width?: number, LicensePlates?: { Id?: number, LocationId?: number, WarehouseId?: number }[], Shipment?: { Id?: number, ActualWarehouseId?: number, LookupCode?: string, TrailerId?: string, OrderLookups?: { CreatedSysDateTime?: string, Order?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } }, Account?: { Id?: number, LookupCode?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string } } }[], Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string } }, ContainerType?: { Id?: number, Name?: string }, DimensionUom?: { Id?: number, Name?: string }, WeightUom?: { Id?: number, Name?: string }, VolumeUom?: { Id?: number, Name?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PackVerification/datasources/ds_get_shippingcontainer_by_shippingContainerId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
