import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { AsnOrders_license_plate_label_reportService } from './AsnOrders.report.index';
import { AsnOrders_license_plate_labels_by_order_reportService } from './AsnOrders.report.index';
import { AsnOrders_receiving_reportService } from './AsnOrders.report.index';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public AsnOrders: AsnOrders_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _license_plate_label_report: AsnOrders_license_plate_label_reportService;
  public get license_plate_label_report(): AsnOrders_license_plate_label_reportService {
    if(!this._license_plate_label_report) {
      this._license_plate_label_report = this.injector.get(AsnOrders_license_plate_label_reportService);
    }
    return this._license_plate_label_report;
  }
  private _license_plate_labels_by_order_report: AsnOrders_license_plate_labels_by_order_reportService;
  public get license_plate_labels_by_order_report(): AsnOrders_license_plate_labels_by_order_reportService {
    if(!this._license_plate_labels_by_order_report) {
      this._license_plate_labels_by_order_report = this.injector.get(AsnOrders_license_plate_labels_by_order_reportService);
    }
    return this._license_plate_labels_by_order_report;
  }
  private _receiving_report: AsnOrders_receiving_reportService;
  public get receiving_report(): AsnOrders_receiving_reportService {
    if(!this._receiving_report) {
      this._receiving_report = this.injector.get(AsnOrders_receiving_reportService);
    }
    return this._receiving_report;
  }
}

