export class FieldsetModel {
  label: string;
  hideTitle: boolean;
  collapsible: boolean;
  expanded: boolean;
  hidden: boolean;

  constructor(label: string, hideTitle: boolean, collapsible: boolean, expanded: boolean) {
    this.label = label;
    this.hideTitle = hideTitle;
    this.collapsible = collapsible;
    this.expanded = expanded;
    this.hidden = false;
  }

  toggle() {
    if (this.collapsible) this.expanded = !this.expanded;
  }
}