import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { LaborManagement_is_assigned_dd_singleComponent } from './LaborManagement.is_assigned_dd_single.component'
import { LaborManagement_assigned_task_operation_codes_dd_multiComponent } from './LaborManagement.assigned_task_operation_codes_dd_multi.component'
import { Inventory_task_statuses_dd_multiComponent } from './Inventory.task_statuses_dd_multi.component'
import { LaborManagement_assigned_users_dd_multiComponent } from './LaborManagement.assigned_users_dd_multi.component'


interface IFootPrintManager_task_assignment_gridComponentEntity {
Id?: number, ChainHead?: number, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, Priority?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, OwnerId?: number }, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, Order?: { Id?: number, LookupCode?: string }, PickSlip?: { Id?: number, Wave?: { Id?: number } }}

interface IFootPrintManager_task_assignment_gridComponentInParams {
  warehouseIds?: number[], workClassIds?: number[], fromDate?: string, toDate?: string, projectId?: number, ownerId?: number}


class FootPrintManager_task_assignment_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_task_assignment_gridComponent;
  entity: IFootPrintManager_task_assignment_gridComponentEntity;

  vars: { taskAssignments?: { id?: number, userId?: string }[] } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    task_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    operation_code_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    priority: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    status_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    assigned_user: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    task_created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    order_code: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    wave: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    material_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    lot_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    packaging_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'number', '0')
,
null
      ),
    warehouse_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    source_location_name: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    target_location_name: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    target_shipping_container_code: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_task_assignment_gridComponent, entity: IFootPrintManager_task_assignment_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_task_assignment_gridComponent, entity?: IFootPrintManager_task_assignment_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouseIds:  $grid.inParams.warehouseIds ,
      workClassIds:  $grid.inParams.workClassIds ,
      fromDate:  $grid.inParams.fromDate ,
      toDate:  $grid.inParams.toDate ,
      fullTextSearch:  $grid.fullTextSearch ,
      isAssigned:  $grid.filters.task_is_assigned.control.value ,
      projectId:  $grid.inParams.projectId ,
      statusIds:  $grid.filters.status.control.value ,
      operationCodeIds:  $grid.filters.operation_code_ids.control.value ,
      ownerId:  $grid.inParams.ownerId ,
      userIds:  $grid.filters.assigned_user.control.value ,
    };
    const data = await this.datasources.LaborManagement.ds_task_assignment_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.task_id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.operation_code_name.displayControl as TextModel).text = $row.entity.OperationCode?.Name;
    (this.cells.priority.displayControl as TextModel).text = $row.entity.Priority?.toString();
    (this.cells.status_name.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.task_created_on.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.project_code.displayControl as TextModel).text = $row.entity.Project?.LookupCode;
    (this.cells.order_code.displayControl as TextModel).text = $row.entity.Order?.LookupCode;
    (this.cells.wave.displayControl as TextModel).text = $utils.isDefined($row.entity.PickSlip?.Wave?.Id) ? $row.entity.PickSlip?.Wave?.Id.toString() : '';
    (this.cells.material_code.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.lot_code.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.packaging_name.displayControl as TextModel).text = $row.entity.ExpectedPackagedPack?.Name;
    (this.cells.packaged_amount.displayControl as TextModel).text = $row.entity.ExpectedPackagedAmount?.toString();
    (this.cells.warehouse_name.displayControl as TextModel).text = $row.entity.Warehouse?.Name;
    (this.cells.source_location_name.displayControl as TextModel).text = $row.entity.ExpectedSourceLocation?.Name;
    (this.cells.target_location_name.displayControl as TextModel).text = $row.entity.ExpectedTargetLocation?.Name;
    (this.cells.target_shipping_container_code.displayControl as TextModel).text = $row.entity.ExpectedTargetShippingContainer?.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'task_id' : {
        this.on_task_id_clicked();
        break;
      }
      case 'order_code' : {
        this.on_order_clicked();
        break;
      }
      case 'wave' : {
        this.on_wave_clicked();
        break;
      }
      case 'source_location_name' : {
        this.on_source_location_clicked();
        break;
      }
      case 'target_location_name' : {
        this.on_target_location_clicked();
        break;
      }
      case 'target_shipping_container_code' : {
        this.on_container_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_task_id_clicked(event = null) {
    return this.on_task_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_task_id_clickedInternal(
    $row: FootPrintManager_task_assignment_gridComponentRowModel,
  $grid: FootPrintManager_task_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let result = await $shell.FootPrintManager.opentask_assignment_editorDialog({ taskId: $row.entity.Id }, 'flyout');
  
  if (result.isConfirmed) {
      await $row.refresh_row();
  
      if ($row.cells.priority.displayControl.text !== ($row.entity.Priority ?? '').toString()) {
          $grid.refresh();
      }
      else if ($utils.isDefined($row.entity.Priority) && $grid.headers.priority.hidden) {
          $grid.headers.priority.hidden = false;
      }
  }
  }
  refresh_row(event = null) {
    return this.refresh_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async refresh_rowInternal(
    $row: FootPrintManager_task_assignment_gridComponentRowModel,
  $grid: FootPrintManager_task_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.refresh();
  
  let task = (await $datasources.LaborManagement.ds_get_tasks_by_taskIds.get({ taskIds: [$row.entity.Id] })).result[0];
  
  // Currently assigned users
  task.TaskAssignments = task.TaskAssignments?.filter(ta => ta?.StatusId === 1 || ta?.StatusId === 2);
  let users = (await $datasources.LaborManagement.ds_get_users_info.getByKeys({ $keys: task.TaskAssignments.map(ta => ta.UserId) })).result;
  
  /**** SET CELL VALUES ****/
  // Assigned user
  $row.cells.assigned_user.displayControl.text = '';
  if ($utils.isDefined(task.TaskAssignments)) {
      $row.cells.assigned_user.displayControl.text = task.TaskAssignments.map(ta => users.find(u => u.id === ta.UserId)?.displayName ?? ta.UserId).join(', ');
  
      $row.vars.taskAssignments = task.TaskAssignments.map(ta => ({ id: ta.Id, userId: ta.UserId }));
  }
  
  for (let key in $row.cells) {
      $row.cells[key].styles.reset();
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_task_assignment_gridComponentRowModel,
  $grid: FootPrintManager_task_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.cells.task_created_on.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.trim().toUpperCase() === '12 HOUR' ? 'LT' : 'H:MM'}`);
  }
  on_wave_clicked(event = null) {
    return this.on_wave_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_wave_clickedInternal(
    $row: FootPrintManager_task_assignment_gridComponentRowModel,
  $grid: FootPrintManager_task_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let waveid = parseInt($row.cells.wave.displayControl.text)
  
  if (!isNaN(waveid)) { 
      $shell.FootPrintManager.opensingle_wave_hub({
          waveId: waveid
      })
  }
  }
  on_order_clicked(event = null) {
    return this.on_order_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_clickedInternal(
    $row: FootPrintManager_task_assignment_gridComponentRowModel,
  $grid: FootPrintManager_task_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Check order class type
  if ($utils.isDefined($row.entity?.Order?.Id)) {
      if (!isNaN($row.entity?.Order?.Id)) {
          const order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $row.entity.Order.Id })).result;
  
          if ($utils.isDefined(order)) {
              const orderClassTypeId = order.OrderClass.OrderClassTypeId;
              if (orderClassTypeId == 5) {
                  $shell.FootPrintManager.opensales_order_editor({ orderId: order.Id });
              }
              else {
                  throw new Error('Editor for selected order class type under development!')
              }
          }
      }
  }
  
  
  }
  on_container_clicked(event = null) {
    return this.on_container_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_container_clickedInternal(
    $row: FootPrintManager_task_assignment_gridComponentRowModel,
  $grid: FootPrintManager_task_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let shippingContainer: number = $row.entity?.ExpectedTargetShippingContainer?.Id
  
  if ($utils.isDefined(shippingContainer)) {
      if (!isNaN(shippingContainer)) {
          $shell.FootPrintManager.openshipping_container_editor({ shippingContainerId: shippingContainer });
      }
  }
  
  
  
  }
  on_source_location_clicked(event = null) {
    return this.on_source_location_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_source_location_clickedInternal(
    $row: FootPrintManager_task_assignment_gridComponentRowModel,
  $grid: FootPrintManager_task_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let expectedLocation: number = $row.entity?.ExpectedSourceLocation?.Id
  
  if ($utils.isDefined(expectedLocation)) {
      if (!isNaN(expectedLocation)) {
          $shell.FootPrintManager.opensingle_location_hub({ locationId: expectedLocation });
      }
  }
  
  }
  on_target_location_clicked(event = null) {
    return this.on_target_location_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_target_location_clickedInternal(
    $row: FootPrintManager_task_assignment_gridComponentRowModel,
  $grid: FootPrintManager_task_assignment_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let targetLocation: number = $row.entity?.ExpectedTargetLocation?.Id
  
  if ($utils.isDefined(targetLocation)) {
      if (!isNaN(targetLocation)) {
          $shell.FootPrintManager.opensingle_location_hub({ locationId: targetLocation });
      }
  }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LaborManagement_is_assigned_dd_singleComponent),
    forwardRef(() => LaborManagement_assigned_task_operation_codes_dd_multiComponent),
    forwardRef(() => Inventory_task_statuses_dd_multiComponent),
    forwardRef(() => LaborManagement_assigned_users_dd_multiComponent),
  ],
  selector: 'FootPrintManager-task_assignment_grid',
  templateUrl: './FootPrintManager.task_assignment_grid.component.html'
})
export class FootPrintManager_task_assignment_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_task_assignment_gridComponentEntity[];

  pageSize = 100;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_task_assignment_gridComponentInParams = { warehouseIds: [], workClassIds: [], fromDate: null, toDate: null, projectId: null, ownerId: null };


  //#region Variables
  vars: { unavailableOperationCodes?: number[] } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     task_id: new GridHeaderModel(new HeaderStyles(null, null), 'Task', false, false, null),       operation_code_name: new GridHeaderModel(new HeaderStyles(null, null), 'Operation', false, false, null),       priority: new GridHeaderModel(new HeaderStyles(null, null), 'Priority', false, false, null),       status_name: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       assigned_user: new GridHeaderModel(new HeaderStyles(null, null), 'Assigned user', false, false, null),       task_created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       project_code: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       order_code: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       wave: new GridHeaderModel(new HeaderStyles(null, null), 'Wave', false, false, null),       material_code: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       lot_code: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       packaging_name: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', false, false, null),       packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Packaged amount', false, false, null),       warehouse_name: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       source_location_name: new GridHeaderModel(new HeaderStyles(null, null), 'Source location', false, false, null),       target_location_name: new GridHeaderModel(new HeaderStyles(null, null), 'Target location', false, false, null),       target_shipping_container_code: new GridHeaderModel(new HeaderStyles(null, null), 'Target container', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_task_assignment_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('workClassIds') set $inParams_workClassIds(value: any) {
    this.inParams['workClassIds'] = value;
  }
  get $inParams_workClassIds(): any {
    return this.inParams['workClassIds'] ;
  }
  @Input('fromDate') set $inParams_fromDate(value: any) {
    this.inParams['fromDate'] = value;
  }
  get $inParams_fromDate(): any {
    return this.inParams['fromDate'] ;
  }
  @Input('toDate') set $inParams_toDate(value: any) {
    this.inParams['toDate'] = value;
  }
  get $inParams_toDate(): any {
    return this.inParams['toDate'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }

  topToolbar = {
      assign_user: new ToolModel(new ButtonModel('assign_user', new ButtonStyles(null, null), false, 'Assign user', 'icon-ic_fluent_person_add_20_regular')
    ),
      unassign: new ToolModel(new ButtonModel('unassign', new ButtonStyles(null, null), false, 'Unassign', 'icon-ic_fluent_person_prohibited_20_regular')
    ),
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, 'Refresh', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    task_is_assigned: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    operation_code_ids: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    assigned_user: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    task_is_assigned: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['task_is_assigned'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Assignment state', false)
,
    operation_code_ids: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['operation_code_ids'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Operation code', false)
,
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    assigned_user: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['assigned_user'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Assigned user', false)
,
  }

  //#region filters inParams
  cacheValueFor_$fields_operation_code_ids_selector_inParams_warehouseIds: number[];
  get $fields_operation_code_ids_selector_inParams_warehouseIds(): number[] {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.inParams.warehouseIds;
    
    if(!isEqual(this.cacheValueFor_$fields_operation_code_ids_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_operation_code_ids_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_operation_code_ids_selector_inParams_warehouseIds;
  }

  cacheValueFor_$fields_operation_code_ids_selector_inParams_workClassIds: number[];
  get $fields_operation_code_ids_selector_inParams_workClassIds(): number[] {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.inParams.workClassIds;
    
    if(!isEqual(this.cacheValueFor_$fields_operation_code_ids_selector_inParams_workClassIds, expr)) {
      this.cacheValueFor_$fields_operation_code_ids_selector_inParams_workClassIds = expr;
    }
    return this.cacheValueFor_$fields_operation_code_ids_selector_inParams_workClassIds;
  }

  get $fields_operation_code_ids_selector_inParams_ownerId(): number {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.inParams.ownerId;
    
    return expr;
  }

  get $fields_operation_code_ids_selector_inParams_projectId(): number {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.inParams.projectId;
    
    return expr;
  }

  get $fields_operation_code_ids_selector_inParams_fromDate(): string {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.inParams.fromDate;
    
    return expr;
  }

  get $fields_operation_code_ids_selector_inParams_toDate(): string {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.inParams.toDate;
    
    return expr;
  }

  cacheValueFor_$fields_assigned_user_selector_inParams_statusIds: number[];
  get $fields_assigned_user_selector_inParams_statusIds(): number[] {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.filters.status.control.value;
    
    if(!isEqual(this.cacheValueFor_$fields_assigned_user_selector_inParams_statusIds, expr)) {
      this.cacheValueFor_$fields_assigned_user_selector_inParams_statusIds = expr;
    }
    return this.cacheValueFor_$fields_assigned_user_selector_inParams_statusIds;
  }

  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Task assignment';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.status.control as SelectBoxModel).reset([1,4]);

    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseIds:  $grid.inParams.warehouseIds ,
      workClassIds:  $grid.inParams.workClassIds ,
      fromDate:  $grid.inParams.fromDate ,
      toDate:  $grid.inParams.toDate ,
      fullTextSearch:  $grid.fullTextSearch ,
      isAssigned:  $grid.filters.task_is_assigned.control.value ,
      projectId:  $grid.inParams.projectId ,
      statusIds:  $grid.filters.status.control.value ,
      operationCodeIds:  $grid.filters.operation_code_ids.control.value ,
      ownerId:  $grid.inParams.ownerId ,
      userIds:  $grid.filters.assigned_user.control.value ,
    };
    try {
    const data = await this.datasources.LaborManagement.ds_task_assignment_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_task_assignment_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_task_assignment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await setExpandedTaskFields();
  
  hideUnusedColumns();
  
  
  
  /**************************************
   * FUNCTIONS
  ***************************************/
  async function setExpandedTaskFields() {
      let tasks = (await $datasources.LaborManagement.ds_get_tasks_by_taskIds.get({ taskIds: $grid.rows.map(row => row.entity.Id) })).result;
      let userIds = tasks.reduce((output, task) => output = output.concat(task.TaskAssignments.map(ta => ta.UserId)), []);
  
      // Currently assigned users
      tasks.forEach(task => task.TaskAssignments = task.TaskAssignments?.filter(ta => ta?.StatusId === 1 || ta?.StatusId === 2));
      let users = (await $datasources.LaborManagement.ds_get_users_info.getByKeys({ $keys: userIds })).result;
  
      for (let row of $grid.rows) {
          let task = tasks.find(t => t.Id === row.entity.Id);
  
          /**** SET CELL VALUES ****/
          // Assigned user
          if ($utils.isDefined(task.TaskAssignments)) {
              row.cells.assigned_user.displayControl.text = task.TaskAssignments.map(ta => users.find(u => u.id === ta.UserId)?.displayName ?? ta.UserId).join(', ');
  
              row.vars.taskAssignments = task.TaskAssignments.map(ta => ({ id: ta.Id, userId: ta.UserId }));
          }
      }
  }
  
  function hideUnusedColumns() {
      for (let key in $grid.headers) {
          if (key !== 'assigned_user' && !$grid.rows.find(row => $utils.isDefinedTrimmed(row.cells[key].displayControl.text))) {
              $grid.headers[key].hidden = true;
          }
          else {
              $grid.headers[key].hidden = false;
          }
      }
  }
  
  }
  on_assign_user_clicked(event = null) {
    return this.on_assign_user_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assign_user_clickedInternal(
    $grid: FootPrintManager_task_assignment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length > 0) {
      let user = (await $shell.FootPrintManager.openselect_user_formDialog({ operationCodeIds: $grid.selectedRows.map(row => row.entity.OperationCode.Id) }, 'modal')).user;
  
      let failedTasks: { message: string, detail: string }[] = [];
  
      for (let row of $grid.selectedRows) {
          if ($utils.isDefined(user?.id)) {
              for (const key in row.cells) {
                  row.cells[key].styles.setPlannedClass();
              }
  
              let promise = $flows.LaborManagement.assign_user_to_task_flow({ taskId: row.entity.Id, userId: user.id });
              if (!$utils.isDefined(promises)) {
                  var promises = [promise];
              }
              else {
                  promises.push(promise);
              }
          }
  
          row.selected = false;
      }
  
      if ($utils.isDefined(promises)) {
          let rowRefreshThreshold = 10;
  
          if (promises.length > rowRefreshThreshold) {
              let results = (await Promise.all(promises));
  
              for (let result of results) {
                  if ($utils.isDefined(result.reasons)) {
                      failedTasks.push({ message: `Task ${result.reflectedTaskId}`, detail: (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue });
                  }
              };
  
              $grid.refresh();
          }
          else {
              for (let promise of promises) {
                  promise.then(async result => {
                      if ($utils.isDefined(result.reasons)) {
                          failedTasks.push({ message: `Task ${result.reflectedTaskId}`, detail: (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue });
                      }
  
                      let row = $grid.rows.find(row => row.entity.Id === result.reflectedTaskId);
                      row.refresh_row();
                  });
              }
  
  
          }
      }
      // If any tasks failed, inform user
      if ($utils.isDefined(failedTasks)) {
          let distinctReasons = [...new Set(failedTasks.map(t => t.detail))];
  
          if (distinctReasons.length > 1) {
              await $shell.FootPrintManager.openErrorDialog('Failed to assign tasks', `The selected tasks were not able to be assigned to ${user.username}.`, null, 'Details', failedTasks);
          }
          else {
              await $shell.FootPrintManager.openErrorDialog('Failed to assign tasks', `The selected tasks were not able to be assigned to ${user.username}.`, distinctReasons);
          }
      }
  }
  
  /******************************
   * FUNCTION
  *******************************/
  //async function assignTask
  
  
  }
  on_unassign_clicked(event = null) {
    return this.on_unassign_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unassign_clickedInternal(
    $grid: FootPrintManager_task_assignment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length > 0) {
      let failedTasks: { message: string, detail: string }[] = [];
  
      for (let row of $grid.selectedRows) {
          if ($utils.isDefined(row.vars.taskAssignments)) {
              for (let key in row.cells) {
                  row.cells[key].styles.setPlannedClass();
              }
  
              let promise = $flows.LaborManagement.unassign_task_flow({ taskId: row.entity.Id, taskAssignmentIds: row.vars.taskAssignments.map(ta => ta.id) });
  
              if (!$utils.isDefined(promises)) {
                  var promises = [promise];
              }
              else {
                  promises.push(promise);
              }
          }
          row.selected = false;
      }
  
      if ($utils.isDefined(promises)) {
          let results = (await Promise.all(promises));
          let rowRefreshThreshold = 10;
  
          for (let result of results) {
              if ($utils.isDefined(result.reasons)) {
                  failedTasks.push({ message: `Task ${result.taskId}`, detail: (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue });
              }
              else if (results.length <= rowRefreshThreshold) {
                  $grid.rows.find(row => row.entity.Id === result.taskId).refresh_row();
              }
          };
  
          if (results.length > rowRefreshThreshold) {
              $grid.refresh();
          }
      }
  
      // If any tasks failed, inform user
      if ($utils.isDefined(failedTasks)) {
          let distinctReasons = [...new Set(failedTasks.map(t => t.detail))];
  
          if (distinctReasons.length > 1) {
              await $shell.LaborManagement.openErrorDialog('Failed to unassign tasks', `The selected tasks were not able to be unassigned.`, null, 'Details', failedTasks);
          }
          else {
              await $shell.LaborManagement.openErrorDialog('Failed to unassign tasks', `The selected tasks were not able to be unassigned.`, distinctReasons);
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_task_assignment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length > 0) {
      $grid.topToolbar.assign_user.control.readOnly = false;
      $grid.topToolbar.unassign.control.readOnly = false;
  }
  else {
      $grid.topToolbar.assign_user.control.readOnly = true;
      $grid.topToolbar.unassign.control.readOnly = true;
  }
  }
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $grid: FootPrintManager_task_assignment_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
