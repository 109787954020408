import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_get_serial_numbers_by_shipment_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId: number, typeId: number }): 
  Promise<{ result: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, LotId?: number, OperationCodeId?: number, ShipmentId?: number, StatusId?: number, SerialNumber?: { Id?: number, Archived?: boolean, ChainHead?: number, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Packaging?: { Name?: string, ShortName?: string }, DimensionsUom?: { Name?: string, Short_name?: string }, VolumeUom?: { Name?: string, Short_name?: string }, WeightUom?: { Name?: string, Short_name?: string }, Status?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.typeId)) {
      missingRequiredInParams.push('\'typeId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_serial_numbers_by_shipment_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentId: number, typeId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, LotId?: number, OperationCodeId?: number, ShipmentId?: number, StatusId?: number, SerialNumber?: { Id?: number, Archived?: boolean, ChainHead?: number, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Packaging?: { Name?: string, ShortName?: string }, DimensionsUom?: { Name?: string, Short_name?: string }, VolumeUom?: { Name?: string, Short_name?: string }, WeightUom?: { Name?: string, Short_name?: string }, Status?: { Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.typeId)) {
      missingRequiredInParams.push('\'typeId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_serial_numbers_by_shipment_id/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentId: number, typeId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, LotId?: number, OperationCodeId?: number, ShipmentId?: number, StatusId?: number, SerialNumber?: { Id?: number, Archived?: boolean, ChainHead?: number, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Packaging?: { Name?: string, ShortName?: string }, DimensionsUom?: { Name?: string, Short_name?: string }, VolumeUom?: { Name?: string, Short_name?: string }, WeightUom?: { Name?: string, Short_name?: string }, Status?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.typeId)) {
      missingRequiredInParams.push('\'typeId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_serial_numbers_by_shipment_id/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
