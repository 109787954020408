import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_billing_contract_lines_validationService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { contractId?: number, lineIds?: number[] }): 
  Promise<{ result: { Id?: number, BasePackageConversion?: boolean, BillingAggregationStrategyId?: number, BillingContractId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomBillingWorkflowId?: string, EndDate?: string, LineNumber?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ResultingBillingCodeId?: number, ResultingUomId?: number, StartDate?: string, WarehouseId?: number, BillingAggregationStrategy?: { Name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_billing_contract_lines_validation/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { contractId?: number, lineIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, BasePackageConversion?: boolean, BillingAggregationStrategyId?: number, BillingContractId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomBillingWorkflowId?: string, EndDate?: string, LineNumber?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ResultingBillingCodeId?: number, ResultingUomId?: number, StartDate?: string, WarehouseId?: number, BillingAggregationStrategy?: { Name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_billing_contract_lines_validation/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { contractId?: number, lineIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, BasePackageConversion?: boolean, BillingAggregationStrategyId?: number, BillingContractId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomBillingWorkflowId?: string, EndDate?: string, LineNumber?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ResultingBillingCodeId?: number, ResultingUomId?: number, StartDate?: string, WarehouseId?: number, BillingAggregationStrategy?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_billing_contract_lines_validation/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
