import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent } from './FootPrintManager.warehouse_transfer_inbound_licenseplates_grid.component';
import { FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent } from './FootPrintManager.warehouse_transfer_inbound_order_receiving_tasks_grid.component';
import { FootPrintManager_warehouse_transfer_inbound_orderlines_gridComponent } from './FootPrintManager.warehouse_transfer_inbound_orderlines_grid.component';
import { FootPrintManager_temperature_readings_gridComponent } from './FootPrintManager.temperature_readings_grid.component';
import { WarehouseTransfers_orderline_total_amount_widgetComponent } from './WarehouseTransfers.orderline_total_amount_widget.component';
import { WarehouseTransfers_orderline_total_received_widgetComponent } from './WarehouseTransfers.orderline_total_received_widget.component';
import { WarehouseTransfers_orderline_total_gross_received_widgetComponent } from './WarehouseTransfers.orderline_total_gross_received_widget.component';
import { AsnOrders_orderclasses_dd_singleComponent } from './AsnOrders.orderclasses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent),
    forwardRef(() => FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent),
    forwardRef(() => FootPrintManager_warehouse_transfer_inbound_orderlines_gridComponent),
    forwardRef(() => FootPrintManager_temperature_readings_gridComponent),
    forwardRef(() => WarehouseTransfers_orderline_total_amount_widgetComponent),
    forwardRef(() => WarehouseTransfers_orderline_total_received_widgetComponent),
    forwardRef(() => WarehouseTransfers_orderline_total_gross_received_widgetComponent),
    forwardRef(() => AsnOrders_orderclasses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
  ],
  selector: 'FootPrintManager-warehouse_transfer_inbound_order_editor',
  templateUrl: './FootPrintManager.warehouse_transfer_inbound_order_editor.component.html'
})
export class FootPrintManager_warehouse_transfer_inbound_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { orderId: number, warehouseTransferId: number } = { orderId: null, warehouseTransferId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('warehouseTransferId') set $inParams_warehouseTransferId(v: number) {
    this.inParams.warehouseTransferId = v;
  }
  get $inParams_warehouseTransferId(): number {
    return this.inParams.warehouseTransferId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipmentIds?: number[], hasCompletedReceivingTasks?: boolean, hasPlannedReceivingTasks?: boolean, warehouseId?: number, canReceive?: boolean } = { };
  //#endregion
  entity: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, SealId?: string, TrailerId?: string } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    po_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    trailer_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    seal_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, 'Process', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular')
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular')
    ),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, 'Appointment', 'icon-ic_fluent_calendar_20_regular')
    ),
      auto_receive: new ToolModel(new ButtonModel('auto_receive', new ButtonStyles(null, null), false, 'Auto receive', 'icon-ic_fluent_developer_board_lightning_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_print_20_regular')
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular')
    ),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_clipboard_task_list_ltr_20_regular')
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Order code', true)
,
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Order class', true)
,
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Owner', true)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Project', true)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', true)
,
    order_status: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Order status', false)
,
    po_number: new FieldModel(new TextBoxModel(this.formGroup.controls['po_number'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'PO', false)
,
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'Expected date', false)
,
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Reference', false)
,
    trailer_number: new FieldModel(new TextBoxModel(this.formGroup.controls['trailer_number'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Trailer number', false)
,
    seal_number: new FieldModel(new TextBoxModel(this.formGroup.controls['seal_number'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Seal number', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      licenseplates: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      licenseplates: new TabItemModel(
        this.rootTabGroup, 
        'License plates', 
        true,
        {
          grouped: new TabItemModel(
          this.subTabGroups.licenseplates, 
          'Grouped', 
          ),
          ungrouped: new TabItemModel(
          this.subTabGroups.licenseplates, 
          'Ungrouped', 
          ),
        }
        ),
      orderlines: new TabItemModel(
        this.rootTabGroup, 
        'Order lines', 
        ),
      temperatures: new TabItemModel(
        this.rootTabGroup, 
        'Temperatures', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_licenseplates_grouped_warehouse_transfer_inbound_licenseplates_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_licenseplates_grouped_warehouse_transfer_inbound_licenseplates_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_licenseplates_ungrouped_warehouse_transfer_inbound_order_receiving_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_licenseplates_ungrouped_warehouse_transfer_inbound_order_receiving_tasks_grid_inParams_readOnly(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = !$editor.vars.canReceive;
      
      return expr;
    }
  
    get $tabs_orderlines_warehouse_transfer_inbound_orderlines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_temperatures_temperature_readings_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
        @ViewChild('$tabs_licenseplates_grouped', { read: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent }) $tabs_licenseplates_grouped: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent;
        @ViewChild('$tabs_licenseplates_ungrouped', { read: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent }) $tabs_licenseplates_ungrouped: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent;
      @ViewChild('$tabs_orderlines', { read: FootPrintManager_warehouse_transfer_inbound_orderlines_gridComponent }) $tabs_orderlines: FootPrintManager_warehouse_transfer_inbound_orderlines_gridComponent;
      @ViewChild('$tabs_temperatures', { read: FootPrintManager_temperature_readings_gridComponent }) $tabs_temperatures: FootPrintManager_temperature_readings_gridComponent;
    //#endregion tabs children
    widgets = {
      orderline_total_amount_widget: new WidgetModel(),
      orderline_total_received_widget: new WidgetModel(),
      orderline_total_gross_received_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_orderline_total_amount_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_orderline_total_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_orderline_total_gross_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_orderline_total_amount_widget', { read:  WarehouseTransfers_orderline_total_amount_widgetComponent }) $widgets_orderline_total_amount_widget: WarehouseTransfers_orderline_total_amount_widgetComponent;
      @ViewChild('$widgets_orderline_total_received_widget', { read:  WarehouseTransfers_orderline_total_received_widgetComponent }) $widgets_orderline_total_received_widget: WarehouseTransfers_orderline_total_received_widgetComponent;
      @ViewChild('$widgets_orderline_total_gross_received_widget', { read:  WarehouseTransfers_orderline_total_gross_received_widgetComponent }) $widgets_orderline_total_gross_received_widget: WarehouseTransfers_orderline_total_gross_received_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    po_number: this.fields.po_number.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    trailer_number: this.fields.trailer_number.control.valueChanges
    ,
    seal_number: this.fields.seal_number.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.licenseplates,
      this.tabs.orderlines,
      this.tabs.temperatures,
    ]; 
    this.subTabGroups.licenseplates.tabs = [
        this.tabs.licenseplates.tabs.grouped,
        this.tabs.licenseplates.tabs.ungrouped,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.warehouseTransferId)) {
        this.$missingRequiredInParams.push('warehouseTransferId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Inbound Order';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.orderId 
    };

    const data = await this.datasources.AsnOrders.ds_asn_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClassId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.po_number.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.RequestedDeliveryDate);
    (this.fields.reference.control as TextBoxModel).reset($editor.entity.OwnerReference);
    (this.fields.trailer_number.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.TrailerId);
    (this.fields.seal_number.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.SealId);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_orderline_total_amount_widget') {
      if (!isNil(this.$widgets_orderline_total_amount_widget) && !this.widgets.orderline_total_amount_widget.hidden) {
        this.$widgets_orderline_total_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_received_widget') {
      if (!isNil(this.$widgets_orderline_total_received_widget) && !this.widgets.orderline_total_received_widget.hidden) {
        this.$widgets_orderline_total_received_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_gross_received_widget') {
      if (!isNil(this.$widgets_orderline_total_gross_received_widget) && !this.widgets.orderline_total_gross_received_widget.hidden) {
        this.$widgets_orderline_total_gross_received_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_licenseplates_grouped') {
        if (!isNil(this.$tabs_licenseplates_grouped) && !this.tabs.licenseplates.tabs.grouped.hidden) {
          this.$tabs_licenseplates_grouped.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_licenseplates_ungrouped') {
        if (!isNil(this.$tabs_licenseplates_ungrouped) && !this.tabs.licenseplates.tabs.ungrouped.hidden) {
          this.$tabs_licenseplates_ungrouped.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_orderlines') {
        if (!isNil(this.$tabs_orderlines) && !this.tabs.orderlines.hidden) {
          this.$tabs_orderlines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_temperatures') {
        if (!isNil(this.$tabs_temperatures) && !this.tabs.temperatures.hidden) {
          this.$tabs_temperatures.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .po_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .trailer_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .seal_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const isConfirm = await $shell.FootPrintManager.openConfirmationDialog('Complete Order', `Are you sure you want to complete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  if (isConfirm) {
      try {
  
          const result = await $flows.WarehouseTransfers.complete_target_inbound_order_flow({ order_id: $editor.entity.Id });
          const reasons = result.reasons;
  
          if ($utils.isDefined(reasons)) {
              await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
              return;
          }
          else {
  
              const title = 'Complete Order';
              const message = `Order ${$editor.entity.LookupCode} completed.`;
              await $shell.FootPrintManager.openInfoDialog(title, message);
              await $editor.refresh();
  
          }
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Order complete error', 'An error occurred during the completion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let warehouseTransfer = (await $datasources.WarehouseTransfers.ds_get_warehouse_transfer_by_warehouseTransferId.get({ warehouseTransferId: $editor.inParams.warehouseTransferId })).result;
  let order = warehouseTransfer.TargetInboundOrder;
  let shipments = order.ShipmentOrderLookups.map(sol => sol.Shipment);
  
  let stateDetails = (await $flows.WarehouseTransfers.get_warehouse_transfer_status_flow({ warehouse_transfer_id: warehouseTransfer.Id }));
  
  // Set hasCompletedReceivingTasks
  let tasks = (await $datasources.WarehouseTransfers.ds_find_tasks.get({ orderId: $editor.entity.Id, operationCodeId: 2 })).result;
  $editor.vars.hasCompletedReceivingTasks = $utils.isDefined(tasks.find(t => t.StatusId === 2));
  $editor.vars.hasPlannedReceivingTasks = $utils.isDefined(tasks.find(t => t.StatusId === 4));
  
  $editor.fields.warehouse.control.value = warehouseTransfer.TargetWarehouseId;
  
  
  // Set editor title
  $editor.title = `Inbound Order: ${$editor.entity.LookupCode}`;
  
  // Apply appointment text logic
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dockAppointmentId: dockAppointments[0]?.DockAppointmentId,
      })).formattedDockAppointment;
  
  } else {
      if (order.OrderStatusId !== 1 && order.OrderStatusId !== 2 && order.OrderStatusId !== 1024) {
          $editor.toolbar.appointment.hidden = true;
      }
      else {
          // Reset the appointment label back to original
          $editor.toolbar.appointment.hidden = false;
          $editor.toolbar.appointment.control.styles.resetStyle();
          $editor.toolbar.appointment.control.label = 'Appointment'
      }
  
  }
  
  
  // Fetch shipments
  if (shipments.length > 1) {
      // close
      await $shell.PurchaseOrders.openErrorDialog('Invalid order', 'This order has more than one shipment. Editor will close');
      $editor.close();
      return;
  }
  
  else {
  
      if (shipments.length === 1) {
          $editor.vars.shipmentIds.push(shipments[0].Id);
      }
  
      // Enable/Disable Components
      // Process
      if (!stateDetails.eligibilities.is_inbound_processable) {
          disableComponent($editor.toolbar.process);
      }
      else {
          enableComponent($editor.toolbar.process);
      }
  
      // Auto Receive
      if (!stateDetails.eligibilities.is_inbound_receivable) {
          disableComponent($editor.toolbar.auto_receive);
          $editor.vars.canReceive = false;
      }
      else {
          enableComponent($editor.toolbar.auto_receive);
          $editor.vars.canReceive = true;
      }
  
      // Complete
      if (!stateDetails.eligibilities.is_inbound_completable) {
          disableComponent($editor.toolbar.complete);
      }
      else {
          enableComponent($editor.toolbar.complete);
      }
  
      // Check status
      if ($editor.entity.OrderStatusId === 1) { // Created
          // Enable components
          enableComponent($editor.fields.po_number);
          enableComponent($editor.fields.expected_date);
  
          disableComponent($editor.widgets.orderline_total_gross_received_widget);
          disableComponent($editor.widgets.orderline_total_received_widget);
          disableComponent($editor.widgets.orderline_total_amount_widget);
  
          if (stateDetails.state < 10) {
              $editor.fields.order_status.control.text = 'Waiting for outbound';
          }
      }
      else if ($editor.entity.OrderStatusId === 2048) { // ApprovalRequired
          // Enable components
          enableComponent($editor.fields.po_number);
          enableComponent($editor.fields.expected_date);
  
          disableComponent($editor.widgets.orderline_total_gross_received_widget);
          disableComponent($editor.widgets.orderline_total_received_widget);
          disableComponent($editor.widgets.orderline_total_amount_widget);
      }
      else if ($editor.entity.OrderStatusId === 4096) { // Rejected
          // Enable components
          enableComponent($editor.fields.po_number);
          enableComponent($editor.fields.expected_date);
  
  
          disableComponent($editor.widgets.orderline_total_gross_received_widget);
          disableComponent($editor.widgets.orderline_total_received_widget);
          disableComponent($editor.widgets.orderline_total_amount_widget);
      }
      else if ($editor.entity.OrderStatusId === 2) { // Processing
          // processing status
          $editor.fields.po_number.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
          enableComponent($editor.widgets.orderline_total_amount_widget);
  
          // Set custom status
          if ($utils.isDefined(stateDetails.state)) {
  
              switch (stateDetails.state) {
                  case 11: {
                      $editor.fields.order_status.control.text = 'Ready to receive';
                      break;
                  }
                  case 12: {
                      $editor.fields.order_status.control.text = 'Receiving in progress';
                      break;
                  }
                  case 13: {
                      $editor.fields.order_status.control.text = 'Ready to complete';
                      break;
                  }
              }
          }
      }
      else {
          $editor.fields.po_number.control.readOnly = true;
          $editor.fields.expected_date.control.readOnly = true;
          $editor.fields.reference.control.readOnly = true;
          $editor.fields.trailer_number.control.readOnly = true;
          $editor.fields.seal_number.control.readOnly = true;
          $editor.fields.notes.control.readOnly = true;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
          enableComponent($editor.widgets.orderline_total_amount_widget);
      }
  }
  
  /******************************************
   * FUNCTIONS
  *******************************************/
  function disableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = true;
      }
      component.hidden = true;
  }
  function enableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = false;
      }
      component.hidden = false;
  
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $editor.vars.shipmentIds = [];
  
  var dateFormat = $settings.FootPrintManager.DateFormat;
  
  $editor.fields.expected_date.control.format = dateFormat + '  HH:mm';
  
  let warehouseTransfer = (await $datasources.WarehouseTransfers.ds_get_warehouse_transfer_by_warehouseTransferId.get({ warehouseTransferId: $editor.inParams.warehouseTransferId })).result;
  let order = warehouseTransfer.TargetInboundOrder;
  let shipments = order.ShipmentOrderLookups.map(sol => sol.Shipment);
  
  // Create shipment if none found
  if (!$utils.isDefined(shipments)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      if ($utils.isAllDefined(order, warehouseTransfer.TargetWarehouseId)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "ExpectedWarehouseId": warehouseTransfer.TargetWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
      }
  }
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  let warehouseTransfer = (await $datasources.WarehouseTransfers.ds_get_warehouse_transfer_by_warehouseTransferId.get({ warehouseTransferId: $editor.inParams.warehouseTransferId })).result;
  let order = warehouseTransfer.TargetInboundOrder;
  let shipments = order.ShipmentOrderLookups.map(sol => sol.Shipment);
  
  var shipmentId = $editor.vars.shipmentIds[0];
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
  }
  if ($editor.fields.reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.reference.control.value;
  }
  if ($editor.fields.po_number.control.isChanged) {
      payload.VendorReference = $editor.fields.po_number.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
          throw new Error('Please select a warehouse.')
      }
      else {
          payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
      }
  }
  if ($editor.fields.expected_date.control.isChanged) {
      payload.RequestedDeliveryDate = $editor.fields.expected_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  // Create the shipment if it does not exist only if the order has a preferred warehouse of if the preferred warehouse is selected in the editor
  if (!$utils.isDefined(shipmentId)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      if ($utils.isAllDefined(order, warehouseTransfer.TargetWarehouseId) || $utils.isAllDefined(order, $editor.fields.warehouse.control.value)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "ExpectedWarehouseId": warehouseTransfer.TargetWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
          shipmentId = nextShipmentId;
          $editor.vars.shipmentIds.push(nextShipmentId);
  
      }
  
  
  }
  
  if ($utils.isDefined(shipmentId)) {
  
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
     if ($editor.fields.warehouse.control.isChanged) {
          if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
              throw new Error('Please select a warehouse.')
          }
          else {
              payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
          }
      }
      if ($editor.fields.expected_date.control.isChanged) {
          payload.ExpectedDate = $editor.fields.expected_date.control.value;
      }
      if ($editor.fields.trailer_number.control.isChanged) {
          payload.TrailerId = $editor.fields.trailer_number.control.value;
      }
      if ($editor.fields.seal_number.control.isChanged) {
          payload.SealId = $editor.fields.seal_number.control.value;
      }
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payload });
  }
  
  
  
  await $editor.refresh();
  }
  on_auto_receive_clicked(event = null) {
    return this.on_auto_receive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_receive_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Open Confirmation Form
  await $shell.FootPrintManager.openauto_receive_and_putaway_formDialog({ orderId: $editor.entity.Id });
  
  $editor.refresh();
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId
      })
  
  } else {
  
      // Check if the selected order is in a rejected status and throw an error.
      if ($editor.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
         
          warehouseId: [$editor.vars.warehouseId],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $editor.entity.RequestedDeliveryDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $editor.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 1,
          ownerId:  $editor.entity.Project.OwnerId,
          projectId: $editor.entity.ProjectId,
          carrierId: $editor.entity.PreferredCarrierId,
          orderId: $editor.inParams.orderId
      });
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const isConfirm = await $shell.FootPrintManager.openConfirmationDialog('Process Order', `Are you sure you want to process order  ${$editor.entity.LookupCode}?`, 'Confirm', 'Cancel');
  if (isConfirm) {
      try {
  
          const result = await $flows.WarehouseTransfers.process_target_inbound_order_flow({ order_id: $editor.entity.Id });
          const reasons = result.reasons;
  
          if ($utils.isDefined(reasons)) {
              await $shell.FootPrintManager.openErrorDialog('Order cannot be processed', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
              return;
          }
          else {
  
              const title = 'Process order';
              const message = `Order ${$editor.entity.LookupCode} processed.`;
              await $shell.FootPrintManager.openInfoDialog(title, message);
              await $editor.refresh();
  
          }
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Error processing order', 'An error occurred while processing the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.openInfoDialog('Not yet implemented', 'The print functionality for this screen has not yet been implemented.');
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'Order', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
      await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$editor.entity.ShipmentOrderLookups[0].ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'modal');
  }
  
  
  }
  //#endregion private flows
}
