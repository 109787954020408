import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { ExcelInventoryImport_ds_get_licenseplates_by_lookupcodesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_locations_by_namesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_lots_by_lookupcodesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_lots_by_materialIdsService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_material_by_id_top1Service } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_material_packagings_by_shortnamesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_materialpackagings_by_shortnamesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_materials_by_lookupcodesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_measurementunits_by_shortnamesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_owners_by_lookupcodesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_projects_by_lookupcodesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_reasoncodes_by_lookupcodesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_statuses_by_namesService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_vendorlots_by_materialIdsService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_get_warehouses_by_nameService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_inventory_import_gridService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_inventory_import_staging_gridService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_material_statuses_ddService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_measurement_units_ddService } from './ExcelInventoryImport.datasource.index';
import { ExcelInventoryImport_ds_reasoncodes_ddService } from './ExcelInventoryImport.datasource.index';

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public ExcelInventoryImport: ExcelInventoryImport_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_licenseplates_by_lookupcodes: ExcelInventoryImport_ds_get_licenseplates_by_lookupcodesService;
  public get ds_get_licenseplates_by_lookupcodes(): ExcelInventoryImport_ds_get_licenseplates_by_lookupcodesService {
    if(!this._ds_get_licenseplates_by_lookupcodes) {
      this._ds_get_licenseplates_by_lookupcodes = this.injector.get(ExcelInventoryImport_ds_get_licenseplates_by_lookupcodesService);
    }
    return this._ds_get_licenseplates_by_lookupcodes;
  }
  private _ds_get_locations_by_names: ExcelInventoryImport_ds_get_locations_by_namesService;
  public get ds_get_locations_by_names(): ExcelInventoryImport_ds_get_locations_by_namesService {
    if(!this._ds_get_locations_by_names) {
      this._ds_get_locations_by_names = this.injector.get(ExcelInventoryImport_ds_get_locations_by_namesService);
    }
    return this._ds_get_locations_by_names;
  }
  private _ds_get_lots_by_lookupcodes: ExcelInventoryImport_ds_get_lots_by_lookupcodesService;
  public get ds_get_lots_by_lookupcodes(): ExcelInventoryImport_ds_get_lots_by_lookupcodesService {
    if(!this._ds_get_lots_by_lookupcodes) {
      this._ds_get_lots_by_lookupcodes = this.injector.get(ExcelInventoryImport_ds_get_lots_by_lookupcodesService);
    }
    return this._ds_get_lots_by_lookupcodes;
  }
  private _ds_get_lots_by_materialIds: ExcelInventoryImport_ds_get_lots_by_materialIdsService;
  public get ds_get_lots_by_materialIds(): ExcelInventoryImport_ds_get_lots_by_materialIdsService {
    if(!this._ds_get_lots_by_materialIds) {
      this._ds_get_lots_by_materialIds = this.injector.get(ExcelInventoryImport_ds_get_lots_by_materialIdsService);
    }
    return this._ds_get_lots_by_materialIds;
  }
  private _ds_get_material_by_id_top1: ExcelInventoryImport_ds_get_material_by_id_top1Service;
  public get ds_get_material_by_id_top1(): ExcelInventoryImport_ds_get_material_by_id_top1Service {
    if(!this._ds_get_material_by_id_top1) {
      this._ds_get_material_by_id_top1 = this.injector.get(ExcelInventoryImport_ds_get_material_by_id_top1Service);
    }
    return this._ds_get_material_by_id_top1;
  }
  private _ds_get_material_packagings_by_shortnames: ExcelInventoryImport_ds_get_material_packagings_by_shortnamesService;
  public get ds_get_material_packagings_by_shortnames(): ExcelInventoryImport_ds_get_material_packagings_by_shortnamesService {
    if(!this._ds_get_material_packagings_by_shortnames) {
      this._ds_get_material_packagings_by_shortnames = this.injector.get(ExcelInventoryImport_ds_get_material_packagings_by_shortnamesService);
    }
    return this._ds_get_material_packagings_by_shortnames;
  }
  private _ds_get_materialpackagings_by_shortnames: ExcelInventoryImport_ds_get_materialpackagings_by_shortnamesService;
  public get ds_get_materialpackagings_by_shortnames(): ExcelInventoryImport_ds_get_materialpackagings_by_shortnamesService {
    if(!this._ds_get_materialpackagings_by_shortnames) {
      this._ds_get_materialpackagings_by_shortnames = this.injector.get(ExcelInventoryImport_ds_get_materialpackagings_by_shortnamesService);
    }
    return this._ds_get_materialpackagings_by_shortnames;
  }
  private _ds_get_materials_by_lookupcodes: ExcelInventoryImport_ds_get_materials_by_lookupcodesService;
  public get ds_get_materials_by_lookupcodes(): ExcelInventoryImport_ds_get_materials_by_lookupcodesService {
    if(!this._ds_get_materials_by_lookupcodes) {
      this._ds_get_materials_by_lookupcodes = this.injector.get(ExcelInventoryImport_ds_get_materials_by_lookupcodesService);
    }
    return this._ds_get_materials_by_lookupcodes;
  }
  private _ds_get_measurementunits_by_shortnames: ExcelInventoryImport_ds_get_measurementunits_by_shortnamesService;
  public get ds_get_measurementunits_by_shortnames(): ExcelInventoryImport_ds_get_measurementunits_by_shortnamesService {
    if(!this._ds_get_measurementunits_by_shortnames) {
      this._ds_get_measurementunits_by_shortnames = this.injector.get(ExcelInventoryImport_ds_get_measurementunits_by_shortnamesService);
    }
    return this._ds_get_measurementunits_by_shortnames;
  }
  private _ds_get_owners_by_lookupcodes: ExcelInventoryImport_ds_get_owners_by_lookupcodesService;
  public get ds_get_owners_by_lookupcodes(): ExcelInventoryImport_ds_get_owners_by_lookupcodesService {
    if(!this._ds_get_owners_by_lookupcodes) {
      this._ds_get_owners_by_lookupcodes = this.injector.get(ExcelInventoryImport_ds_get_owners_by_lookupcodesService);
    }
    return this._ds_get_owners_by_lookupcodes;
  }
  private _ds_get_projects_by_lookupcodes: ExcelInventoryImport_ds_get_projects_by_lookupcodesService;
  public get ds_get_projects_by_lookupcodes(): ExcelInventoryImport_ds_get_projects_by_lookupcodesService {
    if(!this._ds_get_projects_by_lookupcodes) {
      this._ds_get_projects_by_lookupcodes = this.injector.get(ExcelInventoryImport_ds_get_projects_by_lookupcodesService);
    }
    return this._ds_get_projects_by_lookupcodes;
  }
  private _ds_get_reasoncodes_by_lookupcodes: ExcelInventoryImport_ds_get_reasoncodes_by_lookupcodesService;
  public get ds_get_reasoncodes_by_lookupcodes(): ExcelInventoryImport_ds_get_reasoncodes_by_lookupcodesService {
    if(!this._ds_get_reasoncodes_by_lookupcodes) {
      this._ds_get_reasoncodes_by_lookupcodes = this.injector.get(ExcelInventoryImport_ds_get_reasoncodes_by_lookupcodesService);
    }
    return this._ds_get_reasoncodes_by_lookupcodes;
  }
  private _ds_get_statuses_by_names: ExcelInventoryImport_ds_get_statuses_by_namesService;
  public get ds_get_statuses_by_names(): ExcelInventoryImport_ds_get_statuses_by_namesService {
    if(!this._ds_get_statuses_by_names) {
      this._ds_get_statuses_by_names = this.injector.get(ExcelInventoryImport_ds_get_statuses_by_namesService);
    }
    return this._ds_get_statuses_by_names;
  }
  private _ds_get_vendorlots_by_materialIds: ExcelInventoryImport_ds_get_vendorlots_by_materialIdsService;
  public get ds_get_vendorlots_by_materialIds(): ExcelInventoryImport_ds_get_vendorlots_by_materialIdsService {
    if(!this._ds_get_vendorlots_by_materialIds) {
      this._ds_get_vendorlots_by_materialIds = this.injector.get(ExcelInventoryImport_ds_get_vendorlots_by_materialIdsService);
    }
    return this._ds_get_vendorlots_by_materialIds;
  }
  private _ds_get_warehouses_by_name: ExcelInventoryImport_ds_get_warehouses_by_nameService;
  public get ds_get_warehouses_by_name(): ExcelInventoryImport_ds_get_warehouses_by_nameService {
    if(!this._ds_get_warehouses_by_name) {
      this._ds_get_warehouses_by_name = this.injector.get(ExcelInventoryImport_ds_get_warehouses_by_nameService);
    }
    return this._ds_get_warehouses_by_name;
  }
  private _ds_inventory_import_grid: ExcelInventoryImport_ds_inventory_import_gridService;
  public get ds_inventory_import_grid(): ExcelInventoryImport_ds_inventory_import_gridService {
    if(!this._ds_inventory_import_grid) {
      this._ds_inventory_import_grid = this.injector.get(ExcelInventoryImport_ds_inventory_import_gridService);
    }
    return this._ds_inventory_import_grid;
  }
  private _ds_inventory_import_staging_grid: ExcelInventoryImport_ds_inventory_import_staging_gridService;
  public get ds_inventory_import_staging_grid(): ExcelInventoryImport_ds_inventory_import_staging_gridService {
    if(!this._ds_inventory_import_staging_grid) {
      this._ds_inventory_import_staging_grid = this.injector.get(ExcelInventoryImport_ds_inventory_import_staging_gridService);
    }
    return this._ds_inventory_import_staging_grid;
  }
  private _ds_material_statuses_dd: ExcelInventoryImport_ds_material_statuses_ddService;
  public get ds_material_statuses_dd(): ExcelInventoryImport_ds_material_statuses_ddService {
    if(!this._ds_material_statuses_dd) {
      this._ds_material_statuses_dd = this.injector.get(ExcelInventoryImport_ds_material_statuses_ddService);
    }
    return this._ds_material_statuses_dd;
  }
  private _ds_measurement_units_dd: ExcelInventoryImport_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): ExcelInventoryImport_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(ExcelInventoryImport_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_reasoncodes_dd: ExcelInventoryImport_ds_reasoncodes_ddService;
  public get ds_reasoncodes_dd(): ExcelInventoryImport_ds_reasoncodes_ddService {
    if(!this._ds_reasoncodes_dd) {
      this._ds_reasoncodes_dd = this.injector.get(ExcelInventoryImport_ds_reasoncodes_ddService);
    }
    return this._ds_reasoncodes_dd;
  }
}

