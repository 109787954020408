import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
  OnInit,
  OnDestroy,
  AfterViewInit,
  TemplateRef,
  Renderer2,
} from '@angular/core';
import { isNil } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';
import {
  WeekView,
  getWeekView,
  CalendarEvent,
  WeekDay,
  WeekViewHourColumn,
  dateToString,
  stringToDate,
} from './calendar-utils';

import { DateAdapter } from './date-adapter';
import { getWeekViewPeriod, trackByHourSegment, trackByHour, trackByWeekTimeEvent } from './util';
import { Styles } from 'src/app/models/style';

interface CalendarDayView extends WeekView {
  columns: any[];
}

@Component({
  selector: 'datex-calendar-day-view',
  templateUrl: 'calendar-day-view.component.html',
})
export class CalendarDayViewComponent
  implements OnChanges, OnInit, OnDestroy, AfterViewInit {

  @Input() get currentDate(): string { return dateToString(this.viewDate); }
  set currentDate(value: string) { this.viewDate = stringToDate(value); }

  @Input() pageSize: number = 10;
  @Input() pageSkip: number = 0;
  @Input() columnsTotalCount: number = 0;
  @Input() eventsTotalCount: number = 0;
  @Input() eventsFetchedCount: number = 0;
  @Input() selection: boolean = false;

  @Input() containerClass: string;

  @Output() pageChange: EventEmitter<void> = new EventEmitter();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();
  @Output() pageSkipChange: EventEmitter<number> = new EventEmitter();
  @Output() selectionChange: EventEmitter<any[]> = new EventEmitter();

  pagesCount = 0;
  currentPage = 1;
  currentPageFrom = 0;
  currentPageTo = 0;

  @Input() columns: any[] = [];
  @Input() matchEventToColumn: ((columnn: any, event: CalendarEvent) => boolean)

  @Output() hourSegmentEventDropped = new EventEmitter<{
    date: string,
    column: any,
    event: CalendarEvent
  }>();

  @Output() hourSegmentClicked = new EventEmitter<{
    date: string;
    column: any
    sourceEvent: MouseEvent;
  }>();

  view: CalendarDayView;

  @Input() hasUnscheduledEvents: boolean;
  @Input() unscheduledEventTemplate: TemplateRef<any>;
  @Input() unscheduledEventsTitle: string;
  @Input() unscheduledEvents: CalendarEvent[] = [];
  @Output() loadMoreUnscheduledEvents: EventEmitter<void> = new EventEmitter();
  @Input() unscheduledEventsPageSize: number = 10;
  @Output() unscheduledEventsPageSizeChange: EventEmitter<number> = new EventEmitter();
  @Input() unscheduledEventsTotalCount: number = 0;
  @Input() unscheduledEventsPageSkip: number = 0;
  @Output() unscheduledEventsPageSkipChange: EventEmitter<number> = new EventEmitter();
  @Output() unscheduledEventDropped = new EventEmitter<{
    event: CalendarEvent
  }>();

  constructor(
    protected cdr: ChangeDetectorRef,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    protected element: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {
    this.locale = locale;
  }

  trackByViewColumn = (index: number, row: any) => index;

  ngOnChanges(changes: SimpleChanges): void {

    const { isSameSecond } = this.dateAdapter;
    const viewDateChanged = changes['viewDate'] && !isSameSecond(changes['viewDate'].currentValue, changes['viewDate'].previousValue);
    if (viewDateChanged ||
      changes['dayStartHour'] ||
      changes['dayStartMinute'] ||
      changes['dayEndHour'] ||
      changes['dayEndMinute'] ||
      changes['hourSegments'] ||
      changes['hourDuration'] ||
      changes['hourSegmentHeight'] ||
      changes['events'] ||
      changes['minimumEventHeight'] ||
      changes['columns']) {
      this.refreshAll();
    }

    if (changes['events']) {
      // const warn = (...args) => console.warn('angular-calendar', ...args);
      // validateEvents(this.events, warn);

      if (this.selection) {
        this.allSelected = false;
        this.allSelectedChanged();
      }
    }

    if (changes['eventsTotalCount'] || changes['columnsTotalCount'] || changes['pageSize'] || changes['pageSkip']) {
      this.updatePagination();
    }
  }

  get ready(): boolean {
    return !!(this.viewDate && this.columns && ((this.hasUnscheduledEvents && this.unscheduledEvents) || !this.hasUnscheduledEvents));
  }

  get totalItemsText(): string {
    let result = `${this.events?.length}`;
    if (!(this.events?.length === this.eventsTotalCount && this.events?.length === this.eventsFetchedCount)) {
      if (this.eventsTotalCount > this.eventsFetchedCount) {
        result += ` of first ${this.eventsFetchedCount}`;
      }
      result += ` of ${this.eventsTotalCount}`;
    }
    result += ` calendar events`;
    return result;
  }

  get totalUnscheduledEventsText(): string {
    let result = `${this.unscheduledEvents?.length}`;
    result += ` items`;
    return result;
  }

  getContainerGridTemplateColumns(): string {
    if (this.hasUnscheduledEvents) {
      if (this.ready && this.element?.nativeElement?.offsetWidth) {
        const clms = this.pageSize < this.columnsTotalCount ? this.pageSize : this.columnsTotalCount;
        const lp_paddings = 80 + (1 + 8 * 2) * clms;
        const rp_paddings = (10 * 2) + 20 + 6;
        const clm_width = (this.element?.nativeElement?.offsetWidth - (lp_paddings + rp_paddings)) / (clms + 1);
        const lp_width = lp_paddings + clm_width * clms;
        const rp_width = rp_paddings + clm_width;
        return `minmax(50%, ${(lp_width / (lp_width + rp_width) * 100)}%) minmax(min(${(rp_width / (lp_width + rp_width) * 100)}%, 50%), 1fr)`;
      } else {
        return `70% 30%`;
      }
    } else {
      return `100%`;
    }
  }

  private currentDragEvent = null;
  onEventDragStart(dragEvent: DragEvent, event: CalendarEvent) {
    if (this.currentDragEvent) {
      console.log('previous drag event detected!', this.currentDragEvent);
    }
    // set drag data
    this.currentDragEvent = event;

    dragEvent.dataTransfer.effectAllowed = 'move';

    // hide original element
    const renderer = this.renderer;
    const element = dragEvent.target as Element;
    setTimeout(function () {
      renderer.setStyle(element, 'opacity', '0');
    }, 1);
  }

  onEventDragEnd(dragEvent: DragEvent) {
    // clear drag data
    this.currentDragEvent = null;

    // show back original element
    const renderer = this.renderer;
    const element = dragEvent.target as Element;
    setTimeout(function () {
      renderer.removeStyle(element, 'opacity');
    }, 1);
  }

  onEventDroppedInHourSegment(dragEvent: DragEvent, segment: any, columnIndex: number) {
    // remove segment highlight
    this.renderer.removeStyle(dragEvent.target, 'background-color');

    const { isSameSecond } = this.dateAdapter;
    if (this.currentDragEvent && !(this.matchEventToColumn(this.columns[columnIndex], this.currentDragEvent) &&
      isSameSecond(segment.date, this.currentDragEvent.start))) {
      this.hourSegmentEventDropped.emit({
        date: dateToString(segment.date),
        column: this.columns[columnIndex],
        event: this.currentDragEvent
      });
    }
  }

  onEventDroppedInUnscheduled(dragEvent: DragEvent) {
    if (this.currentDragEvent && !this.unscheduledEvents.find(item => item === this.currentDragEvent)) {
      this.unscheduledEventDropped.emit({
        event: this.currentDragEvent
      });
    }
  }

  onSegmentDragEnter(dragEvent: DragEvent) {
    if (this.currentDragEvent) {
      dragEvent.preventDefault(); // allow drop
      // add segment highlight
      this.renderer.setStyle(dragEvent.target, 'background-color', '#ededed');
    }
  }

  onSegmentDragOver(dragEvent: DragEvent) {
    if (this.currentDragEvent) {
      dragEvent.preventDefault(); // allow drop
    }
  }

  onSegmentDragLeave(dragEvent: DragEvent) {
    if (this.currentDragEvent) {
      // remove segment highlight
      this.renderer.removeStyle(dragEvent.target, 'background-color');
    }
  }

  onUnscheduledDragEnter(dragEvent: DragEvent) {
    if (this.currentDragEvent) {
      dragEvent.preventDefault(); // allow drop
    }
  }

  onUnscheduledDragOver(dragEvent: DragEvent) {
    if (this.currentDragEvent) {
      dragEvent.preventDefault(); // allow drop
    }
  }

  onUnscheduledDragLeave(dragEvent: DragEvent) {
  }

  onHourSegmentClicked(date: Date, columnIndex: number, event): void {
    this.hourSegmentClicked.emit({
      date: dateToString(date),
      column: this.columns[columnIndex],
      sourceEvent: event
    });
  }

  hasMoreUnscheduledEvents(): boolean {
    return this.unscheduledEvents.length < this.unscheduledEventsTotalCount;
  }

  onLoadMoreUnscheduledEventsClick() {
    this.unscheduledEventsPageSkip = this.unscheduledEventsPageSkip + this.unscheduledEventsPageSize;
    this.unscheduledEventsPageSkipChange.emit(this.unscheduledEventsPageSkip);
    this.loadMoreUnscheduledEvents.emit();
  }

  protected getWeekView(events: CalendarEvent[]) {
    //prepare arguments for getWeekView
    const args = {
      events,
      viewDate: this.viewDate,
      weekStartsOn: null, //this.weekStartsOn,
      excluded: [],//this.excludeDays,
      precision: this.precision,
      absolutePositionedEvents: true,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute,
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute,
      },
      segmentHeight: this.hourSegmentHeight,
      weekendDays: [], //this.weekendDays,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        null, //this.weekStartsOn,
        [], //this.excludeDays,
        1 //this.daysInWeek
      ),
    };

    // get period (ignore hourColumns...)
    const { period } = getWeekView(this.dateAdapter, args);
    // create new view with period and empty hourColumns
    const view: CalendarDayView = {
      period,
      allDayEventRows: [],
      hourColumns: [],
      columns: [...this.columns],
    };

    view.columns.forEach((viewColumn, columnIndex) => {
      // get events for column
      const events = args.events.filter(
        (event) => this.matchEventToColumn(viewColumn, event)
      );
      // create view with filtered events 
      const newView = getWeekView(this.dateAdapter, {
        ...args,
        events,
      });
      // get created newView
      view.hourColumns.push(newView.hourColumns[0]);
    });

    return view;
  }

  protected refreshAll(): void {
    if (this.ready) {
      this.view = this.getWeekView(this.events);
    }
  }


  // #region properties copy from calendar-week-view.component.ts

  /**
   * The current view date
   */
  @Input() viewDate: Date;

  /**
   * An array of events to display on view
   * The schema is available here: https://github.com/mattlewis92/calendar-utils/blob/c51689985f59a271940e30bc4e2c4e1fee3fcb5c/src/calendarUtils.ts#L49-L63
   */
  @Input() events: CalendarEvent[] = [];

  /**
   * An array of day indexes (0 = sunday, 1 = monday etc) that will be hidden on the view
   */
  // @Input() excludeDays: number[] = [];

  /**
   * An observable that when emitted on will re-render the current view
   */
  @Input() refresh: Subject<any>;

  /**
   * The locale used to format dates
   */
  @Input() locale: string;

  /**
   * The start number of the week.
   * This is ignored when the `daysInWeek` input is also set as the `viewDate` will be used as the start of the week instead.
   * Note, you should also pass this to the calendar title pipe so it shows the same days: {{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}
   * If using the moment date adapter this option won't do anything and you'll need to set it globally like so:
   * ```
   * moment.updateLocale('en', {
   *   week: {
   *     dow: 1, // set start of week to monday instead
   *     doy: 0,
   *   },
   * });
   * ```
   */
  // @Input() weekStartsOn: number;

  /**
   * A custom template to use to replace the header
   */
  // TODO: not included
  @Input() headerTemplate: TemplateRef<any>;

  /**
   * A custom template to use for week view events
   */
  @Input() eventTemplate: TemplateRef<any>;

  /**
   * The precision to display events.
   * `days` will round event start and end dates to the nearest day and `minutes` will not do this rounding
   */
  @Input() precision: 'days' | 'minutes' = 'days';

  /**
   * An array of day indexes (0 = sunday, 1 = monday etc) that indicate which days are weekends
   */
  // @Input() weekendDays: number[];

  /**
   * Whether to snap events to a grid when dragging
   */
  //@Input() snapDraggedEvents: boolean = true;

  /**
   * The number of segments in an hour. Must divide equally into 60.
   */
  @Input() hourSegments: number = 2;

  /**
   * The duration of each segment group in minutes
   */
  @Input() hourDuration: number;

  /**
   * The height in pixels of each hour segment
   */
  @Input() hourSegmentHeight: number = 30;

  /**
   * The minimum height in pixels of each event
   */
  @Input() minimumEventHeight: number = 30;

  /**
   * The day start hours in 24 hour time. Must be 0-23
   */
  @Input() dayStartHour: number = 0;

  /**
   * The day start minutes. Must be 0-59
   */
  @Input() dayStartMinute: number = 0;

  /**
   * The day end hours in 24 hour time. Must be 0-23
   */
  @Input() dayEndHour: number = 23;

  /**
   * The day end minutes. Must be 0-59
   */
  @Input() dayEndMinute: number = 59;

  /**
   * A custom template to use to replace the hour segment
   */
  @Input() hourSegmentTemplate: TemplateRef<any>;

  /**
   * The grid size to snap resizing and dragging of hourly events to
   */
  //@Input() eventSnapSize: number;

  /**
   * A custom template to use for the all day events label text
   */
  // @Input() allDayEventsLabelTemplate: TemplateRef<any>;

  /**
   * The number of days in a week. Can be used to create a shorter or longer week view.
   * The first day of the week will always be the `viewDate` and `weekStartsOn` if set will be ignored
   */
  // @Input() daysInWeek: number;

  /**
   * A custom template to use for the current time marker
   */
  @Input() currentTimeMarkerTemplate: TemplateRef<any>;

  /**
   * Allow you to customise where events can be dragged and resized to.
   * Return true to allow dragging and resizing to the new location, or false to prevent it
   */
  // @Input() validateEventTimesChanged: (
  //   event: CalendarEventTimesChangedEvent
  // ) => boolean;

  /**
   * Called when a header week day is clicked. Adding a `cssClass` property on `$event.day` will add that class to the header element
   */

  //TODO: not attached
  @Output() dayHeaderClicked = new EventEmitter<{
    day: WeekDay;
    sourceEvent: MouseEvent;
  }>();

  /**
   * Called when an event title is clicked
   */
  // @Output() eventClicked = new EventEmitter<{
  //   event: CalendarEvent;
  //   sourceEvent: MouseEvent | KeyboardEvent;
  // }>();

  /**
   * Called when an event is resized or dragged and dropped
   */
  // @Output() eventTimesChanged =
  //   new EventEmitter<CalendarEventTimesChangedEvent>();

  /**
   * An output that will be called before the view is rendered for the current week.
   * If you add the `cssClass` property to a day in the header it will add that class to the cell element in the template
   */
  // @Output() beforeViewRender =
  //   new EventEmitter<CalendarWeekViewBeforeRenderEvent>();

  /**
   * Called when an hour segment is clicked
   */
  // @Output() hourSegmentClicked = new EventEmitter<{
  //   date: Date;
  //   sourceEvent: MouseEvent;
  // }>();

  /**
   * @hidden
   */
  // days: WeekDay[];


  /**
   * @hidden
   */
  refreshSubscription: Subscription;



  /**
   * @hidden
   */
  // eventDragEnterByType = {
  //   allDay: 0,
  //   time: 0,
  // };

  /**
   * @hidden
   */
  // dragActive = false;

  /**
   * @hidden
   */
  // dragAlreadyMoved = false;

  /**
   * @hidden
   */
  // validateDrag: ValidateDrag;

  /**
   * @hidden
   */
  // validateResize: (args: any) => boolean;

  /**
   * @hidden
   */
  //dayColumnWidth: number;

  /**
   * @hidden
   */
  calendarId = Symbol('angular calendar week view id');

  /**
   * @hidden
   */
  // lastDraggedEvent: CalendarEvent;

  /**
   * @hidden
   */
  rtl = false;

  /**
   * @hidden
   */
  // trackByWeekDayHeaderDate = trackByWeekDayHeaderDate;

  /**
   * @hidden
   */
  trackByHourSegment = trackByHourSegment;

  /**
   * @hidden
   */
  trackByHour = trackByHour;


  /**
   * @hidden
   */
  trackByWeekTimeEvent = trackByWeekTimeEvent;

  /**
   * @hidden
   */
  // private lastDragEnterDate: Date;


  /**
   * @hidden
   */
  trackByHourColumn = (index: number, column: WeekViewHourColumn) =>
    column.hours[0] ? column.hours[0].segments[0].date.toISOString() : column;

  /**
   * @hidden
   */
  // trackById = (index: number, row: WeekViewAllDayEventRow) => row.id;

  // #endregion

  // #region functions copy from calendar-week-view.component.ts
  /**
   * @hidden
   */
  ngOnInit(): void {
    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe(() => {
        this.refreshAll();
        this.cdr.markForCheck();
      });
    }
  }


  /**
   * @hidden
   */
  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  /**
   * @hidden
   */
  ngAfterViewInit() {
    this.rtl =
      typeof window !== 'undefined' &&
      getComputedStyle(this.element.nativeElement).direction === 'rtl';
    this.cdr.detectChanges();
  }

  //#endregion

  //#region Selection

  selectedEvents = [];
  hasSelectedEvents() {
    return this.selectedEvents.length > 0;
  }
  get selectedEventsCount() {
    return this.selectedEvents.length;
  }
  getSelectedEvents() {
    return [...this.selectedEvents];
  }
  allSelected = false;
  allSelectedChanged() {
    if (this.allSelected === true) {
      this.events.forEach(row => {
        row.selected = true;
      });
    } else if (this.allSelected === false) {
      this.events.forEach(row => {
        row.selected = false;
      });
    }

    this.updateSelection();
  }

  updateAllSelected() {
    if (this.events.length) {
      const allSelected = this.events.every(r => r.selected === true);
      const allUnSelected = this.events.every(r => r.selected !== true);
      if (allSelected) {
        this.allSelected = true;
      } else if (allUnSelected) {
        this.allSelected = false;
      } else {
        this.allSelected = undefined;
      }
    } else {
      this.allSelected = false;
    }

    this.updateSelection();
  }

  updateSelection() {
    const selectedEvents = this.events.filter(r => r.selected === true);
    this.selectedEvents = selectedEvents;
    this.selectionChange.emit(this.selectedEvents);
  }

  // #endregion

  // #region Pagination
  goToPage(page: number) {
    if (page >= 1 && page <= this.pagesCount && page !== this.currentPage) {
      this.currentPage = page;
      this.updateCurrentPageFromTo();

      const pageSkip = (this.currentPage - 1) * this.pageSize;
      this.pageSkipChange.emit(pageSkip);
      this.pageChange.emit();
    }
  }

  updatePagination() {
    if (!isNil(this.pageSize) &&
      !isNil(this.columnsTotalCount) &&
      !isNil(this.pageSkip) &&
      this.pageSize > 0) {
      this.pagesCount = Math.ceil(this.columnsTotalCount / this.pageSize);
      this.currentPage = (this.pageSkip / this.pageSize) + 1;
    } else {
      this.pagesCount = 0;
      this.currentPage = 1;
    }
    this.updateCurrentPageFromTo();
  }

  updateCurrentPageFromTo() {
    if (this.pagesCount > 0) {
      this.currentPageFrom = (this.currentPage * this.pageSize) - this.pageSize + 1;
      if (this.currentPage !== this.pagesCount) {
        this.currentPageTo = this.currentPage * this.pageSize;
      } else {
        this.currentPageTo = this.columnsTotalCount;
      }
    } else {
      this.currentPageFrom = 0;
      this.currentPageTo = 0;
    }
  }

  isPositive(x: number) {
    return x !== null && x !== undefined && x > 0;
  }
  //#endregion

}
