import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookupService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { chainhead: number, lotId: number, packagingId: number, serialnumberIds: number[], actualTargetLicenseplateId: number }): 
  Promise<{ result: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, ChainHead?: number, GrossWeight?: number, LotId?: number, NetWeight?: number, SerialNumberId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.chainhead)) {
      missingRequiredInParams.push('\'chainhead\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.serialnumberIds)) {
      missingRequiredInParams.push('\'serialnumberIds\'');
    }
    if (isNil(inParams.actualTargetLicenseplateId)) {
      missingRequiredInParams.push('\'actualTargetLicenseplateId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { chainhead: number, lotId: number, packagingId: number, serialnumberIds: number[], actualTargetLicenseplateId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, ChainHead?: number, GrossWeight?: number, LotId?: number, NetWeight?: number, SerialNumberId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.chainhead)) {
      missingRequiredInParams.push('\'chainhead\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.serialnumberIds)) {
      missingRequiredInParams.push('\'serialnumberIds\'');
    }
    if (isNil(inParams.actualTargetLicenseplateId)) {
      missingRequiredInParams.push('\'actualTargetLicenseplateId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { chainhead: number, lotId: number, packagingId: number, serialnumberIds: number[], actualTargetLicenseplateId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, ChainHead?: number, GrossWeight?: number, LotId?: number, NetWeight?: number, SerialNumberId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.chainhead)) {
      missingRequiredInParams.push('\'chainhead\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.serialnumberIds)) {
      missingRequiredInParams.push('\'serialnumberIds\'');
    }
    if (isNil(inParams.actualTargetLicenseplateId)) {
      missingRequiredInParams.push('\'actualTargetLicenseplateId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
