import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { PalletTransactions_ds_base_order_editorService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_base_order_lines_gridService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_get_orders_by_project_ddService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_get_project_by_projectIdService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_get_shipments_by_project_ddService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_owners_ddService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_pallet_classes_ddService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_pallet_classes_gridService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_pallet_inventory_by_class_gridService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_pallet_inventory_by_project_gridService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_pallet_inventory_totalsService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_pallet_totals_widgetService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_pallet_transactions_gridService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_pallet_transactions_shipment_gridService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_pallet_transactions_widgetService } from './PalletTransactions.datasource.index';
import { PalletTransactions_ds_projects_ddService } from './PalletTransactions.datasource.index';

@Injectable({ providedIn: 'root' })
export class PalletTransactions_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public PalletTransactions: PalletTransactions_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_base_order_editor: PalletTransactions_ds_base_order_editorService;
  public get ds_base_order_editor(): PalletTransactions_ds_base_order_editorService {
    if(!this._ds_base_order_editor) {
      this._ds_base_order_editor = this.injector.get(PalletTransactions_ds_base_order_editorService);
    }
    return this._ds_base_order_editor;
  }
  private _ds_base_order_lines_grid: PalletTransactions_ds_base_order_lines_gridService;
  public get ds_base_order_lines_grid(): PalletTransactions_ds_base_order_lines_gridService {
    if(!this._ds_base_order_lines_grid) {
      this._ds_base_order_lines_grid = this.injector.get(PalletTransactions_ds_base_order_lines_gridService);
    }
    return this._ds_base_order_lines_grid;
  }
  private _ds_get_orders_by_project_dd: PalletTransactions_ds_get_orders_by_project_ddService;
  public get ds_get_orders_by_project_dd(): PalletTransactions_ds_get_orders_by_project_ddService {
    if(!this._ds_get_orders_by_project_dd) {
      this._ds_get_orders_by_project_dd = this.injector.get(PalletTransactions_ds_get_orders_by_project_ddService);
    }
    return this._ds_get_orders_by_project_dd;
  }
  private _ds_get_project_by_projectId: PalletTransactions_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): PalletTransactions_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(PalletTransactions_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_shipments_by_project_dd: PalletTransactions_ds_get_shipments_by_project_ddService;
  public get ds_get_shipments_by_project_dd(): PalletTransactions_ds_get_shipments_by_project_ddService {
    if(!this._ds_get_shipments_by_project_dd) {
      this._ds_get_shipments_by_project_dd = this.injector.get(PalletTransactions_ds_get_shipments_by_project_ddService);
    }
    return this._ds_get_shipments_by_project_dd;
  }
  private _ds_owners_dd: PalletTransactions_ds_owners_ddService;
  public get ds_owners_dd(): PalletTransactions_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(PalletTransactions_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_pallet_classes_dd: PalletTransactions_ds_pallet_classes_ddService;
  public get ds_pallet_classes_dd(): PalletTransactions_ds_pallet_classes_ddService {
    if(!this._ds_pallet_classes_dd) {
      this._ds_pallet_classes_dd = this.injector.get(PalletTransactions_ds_pallet_classes_ddService);
    }
    return this._ds_pallet_classes_dd;
  }
  private _ds_pallet_classes_grid: PalletTransactions_ds_pallet_classes_gridService;
  public get ds_pallet_classes_grid(): PalletTransactions_ds_pallet_classes_gridService {
    if(!this._ds_pallet_classes_grid) {
      this._ds_pallet_classes_grid = this.injector.get(PalletTransactions_ds_pallet_classes_gridService);
    }
    return this._ds_pallet_classes_grid;
  }
  private _ds_pallet_inventory_by_class_grid: PalletTransactions_ds_pallet_inventory_by_class_gridService;
  public get ds_pallet_inventory_by_class_grid(): PalletTransactions_ds_pallet_inventory_by_class_gridService {
    if(!this._ds_pallet_inventory_by_class_grid) {
      this._ds_pallet_inventory_by_class_grid = this.injector.get(PalletTransactions_ds_pallet_inventory_by_class_gridService);
    }
    return this._ds_pallet_inventory_by_class_grid;
  }
  private _ds_pallet_inventory_by_project_grid: PalletTransactions_ds_pallet_inventory_by_project_gridService;
  public get ds_pallet_inventory_by_project_grid(): PalletTransactions_ds_pallet_inventory_by_project_gridService {
    if(!this._ds_pallet_inventory_by_project_grid) {
      this._ds_pallet_inventory_by_project_grid = this.injector.get(PalletTransactions_ds_pallet_inventory_by_project_gridService);
    }
    return this._ds_pallet_inventory_by_project_grid;
  }
  private _ds_pallet_inventory_totals: PalletTransactions_ds_pallet_inventory_totalsService;
  public get ds_pallet_inventory_totals(): PalletTransactions_ds_pallet_inventory_totalsService {
    if(!this._ds_pallet_inventory_totals) {
      this._ds_pallet_inventory_totals = this.injector.get(PalletTransactions_ds_pallet_inventory_totalsService);
    }
    return this._ds_pallet_inventory_totals;
  }
  private _ds_pallet_totals_widget: PalletTransactions_ds_pallet_totals_widgetService;
  public get ds_pallet_totals_widget(): PalletTransactions_ds_pallet_totals_widgetService {
    if(!this._ds_pallet_totals_widget) {
      this._ds_pallet_totals_widget = this.injector.get(PalletTransactions_ds_pallet_totals_widgetService);
    }
    return this._ds_pallet_totals_widget;
  }
  private _ds_pallet_transactions_grid: PalletTransactions_ds_pallet_transactions_gridService;
  public get ds_pallet_transactions_grid(): PalletTransactions_ds_pallet_transactions_gridService {
    if(!this._ds_pallet_transactions_grid) {
      this._ds_pallet_transactions_grid = this.injector.get(PalletTransactions_ds_pallet_transactions_gridService);
    }
    return this._ds_pallet_transactions_grid;
  }
  private _ds_pallet_transactions_shipment_grid: PalletTransactions_ds_pallet_transactions_shipment_gridService;
  public get ds_pallet_transactions_shipment_grid(): PalletTransactions_ds_pallet_transactions_shipment_gridService {
    if(!this._ds_pallet_transactions_shipment_grid) {
      this._ds_pallet_transactions_shipment_grid = this.injector.get(PalletTransactions_ds_pallet_transactions_shipment_gridService);
    }
    return this._ds_pallet_transactions_shipment_grid;
  }
  private _ds_pallet_transactions_widget: PalletTransactions_ds_pallet_transactions_widgetService;
  public get ds_pallet_transactions_widget(): PalletTransactions_ds_pallet_transactions_widgetService {
    if(!this._ds_pallet_transactions_widget) {
      this._ds_pallet_transactions_widget = this.injector.get(PalletTransactions_ds_pallet_transactions_widgetService);
    }
    return this._ds_pallet_transactions_widget;
  }
  private _ds_projects_dd: PalletTransactions_ds_projects_ddService;
  public get ds_projects_dd(): PalletTransactions_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(PalletTransactions_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
}

