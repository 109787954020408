<div class="pie-container">
  <div data-cy="widget-title" class="pie-title">Progress</div>
  <div *ngIf="initialized && !$hasMissingRequiredInParams" 
    class="pie-content">
    <apx-chart #pieChart
               [chart]="chart"
               [series]="series"
               [labels]="labels"
               [dataLabels]="dataLabels"
               [theme]="theme"
               [legend]="legend"
               [tooltip]="tooltip"
               [stroke]="stroke">
    </apx-chart>
  </div>
  <div *ngIf="$hasMissingRequiredInParams"
       class="missing-params">
    <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
  </div>
</div>