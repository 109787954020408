import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_inventory_tasks_gridComponent } from './FootPrintManager.inventory_tasks_grid.component';
import { FootPrintManager_child_serialnumbers_gridComponent } from './FootPrintManager.child_serialnumbers_grid.component';
import { Inventory_serialnumber_net_weight_widgetComponent } from './Inventory.serialnumber_net_weight_widget.component';
import { Inventory_serialnumber_gross_weight_widgetComponent } from './Inventory.serialnumber_gross_weight_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_inventory_tasks_gridComponent),
    forwardRef(() => FootPrintManager_child_serialnumbers_gridComponent),
    forwardRef(() => Inventory_serialnumber_net_weight_widgetComponent),
    forwardRef(() => Inventory_serialnumber_gross_weight_widgetComponent),
  ],
  selector: 'FootPrintManager-single_serialnumber_hub',
  templateUrl: './FootPrintManager.single_serialnumber_hub.component.html'
})
export class FootPrintManager_single_serialnumber_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { serialnumberId: number } = { serialnumberId: null };
  //#region Inputs
  @Input('serialnumberId') set $inParams_serialnumberId(v: number) {
    this.inParams.serialnumberId = v;
  }
  get $inParams_serialnumberId(): number {
    return this.inParams.serialnumberId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, 'Edit', 'icon-ic_fluent_edit_20_regular')
    )
  };

  actionbar = {
      custom_fields: new ToolModel(new ButtonModel('custom_fields', new ButtonStyles(null, null), false, 'Custom fields', 'icon-ic_fluent_edit_20_regular')
    )
  };

 filters = {
    location_path: new FieldModel(new LabelModel(null, null)
, new ControlContainerStyles(null, null), 'Location path', false)
,
  };


  filtersets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
      child_serialnumbers: new TabItemModel(
        this.rootTabGroup, 
        'Child serial numbers', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_tasks_inventory_tasks_grid_inParams_serialnumberId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.serialnumberId;
      
      return expr;
    }
  
    get $tabs_child_serialnumbers_child_serialnumbers_grid_inParams_chainHead(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.serialnumberId;
      
      return expr;
    }
  
    get $tabs_child_serialnumbers_child_serialnumbers_grid_inParams_archived(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_tasks', { read: FootPrintManager_inventory_tasks_gridComponent }) $tabs_tasks: FootPrintManager_inventory_tasks_gridComponent;
      @ViewChild('$tabs_child_serialnumbers', { read: FootPrintManager_child_serialnumbers_gridComponent }) $tabs_child_serialnumbers: FootPrintManager_child_serialnumbers_gridComponent;
    //#endregion tabs children
    widgets = {
      serialnumber_net_weight_widget: new WidgetModel(),
      serialnumber_gross_weight_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_serialnumber_net_weight_widget_inParams_serialnumberId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.serialnumberId;
      
      return expr;
    }
  
    get $widgets_serialnumber_gross_weight_widget_inParams_serialnumberId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.serialnumberId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_serialnumber_net_weight_widget', { read:  Inventory_serialnumber_net_weight_widgetComponent }) $widgets_serialnumber_net_weight_widget: Inventory_serialnumber_net_weight_widgetComponent;
      @ViewChild('$widgets_serialnumber_gross_weight_widget', { read:  Inventory_serialnumber_gross_weight_widgetComponent }) $widgets_serialnumber_gross_weight_widget: Inventory_serialnumber_gross_weight_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.tasks,
      this.tabs.child_serialnumbers,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.serialnumberId)) {
        this.$missingRequiredInParams.push('serialnumberId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single Serialnumber Hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_serialnumber_net_weight_widget') {
      if (!isNil(this.$widgets_serialnumber_net_weight_widget) && !this.widgets.serialnumber_net_weight_widget.hidden) {
        this.$widgets_serialnumber_net_weight_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_serialnumber_gross_weight_widget') {
      if (!isNil(this.$widgets_serialnumber_gross_weight_widget) && !this.widgets.serialnumber_gross_weight_widget.hidden) {
        this.$widgets_serialnumber_gross_weight_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_tasks') {
      if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
        this.$tabs_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_child_serialnumbers') {
      if (!isNil(this.$tabs_child_serialnumbers) && !this.tabs.child_serialnumbers.hidden) {
        this.$tabs_child_serialnumbers.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_serialnumber_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const serialnumbers: number[] = [];
  serialnumbers.push($hub.inParams.serialnumberId)
  
  const serialnumber = (await $datasources.SerialNumbers.ds_get_serialnumber_by_serialnumberId.get({ serialnumberId: $hub.inParams.serialnumberId })).result;
  if ($utils.isDefined(serialnumber)) {
  
      $hub.title = `Serialnumber ${serialnumber.LookupCode}`;
  
  
  
      var path = '';
      const parentPath = (await $flows.SerialNumbers.get_serialnumber_parent_path({ serialnumberIds: serialnumbers }))
      if ($utils.isDefined(parentPath) && parentPath.serialInfo[0].serialPath.length > 0) {
          path = `Parent Path ${parentPath.serialInfo[0].serialPath}`;
      }
      const title = `${serialnumber.LookupCode}   ${path}`;
  
  
      const description = `Status ${serialnumber.Status.Name}    Archived ${serialnumber.Archived}`;
      $hub.vars.hubDescription = description;
      $hub.vars.hubTitle = title;
  
  }
  
  // Display the location path
  const locationPath = (await $datasources.SerialNumbers.ds_get_serialnumber_location_path_by_serialnumberId.get({ serialnumberId: $hub.inParams.serialnumberId })).result;
  if ($utils.isDefined(locationPath)) {
  
      if ($utils.isDefined(locationPath.LicensePlate?.Id)) {
          $hub.filters.location_path.hidden = false;
          const path = `LicensePlate: ${locationPath.LicensePlate.LookupCode}  Location: ${locationPath.LicensePlate.Location.Name}  Warehouse: ${locationPath.LicensePlate.Location.Warehouse.Name}`
          $hub.filters.location_path.label = path;
      }
      else {
          $hub.filters.location_path.hidden = true;
      }
  
  }
  else {
      $hub.filters.location_path.hidden = true;
  }
  
  
  // Hide the serialnumbers tab
  const serialChildren = (await $datasources.SerialNumbers.ds_get_serialnumbers_by_chainHead.get({ chainHead: $hub.inParams.serialnumberId })).result;
  if ($utils.isDefined(serialChildren)) {
  
      $hub.tabs.child_serialnumbers.hidden = false;
  
  } else {
      $hub.tabs.child_serialnumbers.hidden = true;
  }
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_serialnumber_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.openserialnumber_editorDialog({serialnumberId: $hub.inParams.serialnumberId},'flyout');
  const userConfirmed = dialogResult.confirm;
  
    if (userConfirmed) {
  
        await $hub.on_init();
        await $hub.refresh();
    }
  
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $hub: FootPrintManager_single_serialnumber_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'SerialNumber', entityKeys: [{name: 'Id', value: $hub.inParams.serialnumberId}]}, 'flyout', EModalSize.Large);
  }
  //#endregion private flows
}
